angular.module('app.queja', [
    'ui.router',
    'toastr'
])

    .config(
        ['$stateProvider', '$urlRouterProvider',
            function ($stateProvider, $urlRouterProvider) {
                $stateProvider
                    .state('index.queja', {
                        abstract: true,
                        url: 'queja',
                        template: '<div ui-view></div>',
                        resolve: {
                        },
                        controller: ['$scope', 'municipioService', 'fincaService', 'toastr',
                            function ($scope, municipioService, fincaService, toastr) {
                                $scope.module = 'Quejas';

                            }]
                    })
                    .state('index.queja.lista', {
                        url: '',
                        templateUrl: 'app/queja/queja.list.tpl.html',
                        resolve: {
                            dataTipoQueja: ['tipoQuejaService',
                                function (tipoQuejaService) {
                                    return tipoQuejaService.listaActiva();
                                }],
                            dataRegion: ['regionService',
                                function (regionService) {
                                    return regionService.listaActiva();
                                }]
                        },
                        controller: ['$scope', 'ngDialog', 'dataTipoQueja', 'dataRegion', 'gestionService', 'toastr', 'subregionService', '$filter',
                            function ($scope, ngDialog, dataTipoQueja, dataRegion, gestionService, toastr, subregionService, $filter) {

                                $scope.dataTipoQueja = dataTipoQueja.data;
                                $scope.dataRegion = dataRegion.data;

                                var now = new Date();
                                var begin = $scope.addDays(now, -7);

                                $scope.filtro = {
                                    fechaIni: begin,
                                    fechaFin: now
                                }
                                $scope.requeridoSubregion = false;

                                $scope.limpiarFiltro = function ( ) {
                                    $scope.filtro = {
                                        fechaIni: begin,
                                        fechaFin: now,
                                        personaId: null,
                                        subregionId: null
                                    }
                                    $scope.requeridoSubregion = false;
                                    $scope.gridOptions.data = [];
                                }                                

                                $scope.setRegion = function (item) {
                                    subregionService.listaPorRegion(item.regionId).then(function (res) {
                                        if (res.status == 'OK') {
                                            $scope.requeridoSubregion = true;
                                            $scope.dataSubregion = res.data;
                                        } else {
                                            toastr.error("Error al obtener datos: " + res.message);
                                        }
                                    }, function (error) {
                                        toastr.error("Error al obtener datos generales: " + error + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                                    });
                                }

                                $scope.mostrarDetalle = function (item) {
                                    if (item.tcTipoQueja.listar == 1 && item.detalle.length > 0) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptions.columnDefs = [
                                    { field: 'tcPersona.personaDesc', name: 'personaDesc', displayName: 'Nombre' },
                                    { field: 'tcPersona.correo', name: 'correo', displayName: 'Correo', width: '15%' },
                                    { field: 'tcPersona.telefono', name: 'telefono', displayName: 'Teléfono', width: '10%' },
                                    { field: 'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '10%' },
                                    { field: 'quejaDesc', name: 'quejaDesc', displayName: 'Queja' },
                                    { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    {
                                        name: 'Opciones', enableFiltering: false, width: '8%',
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + '<button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarDetalle(row.entity)" ng-click="grid.appScope.verDetalle(row.entity)" title="Clic para ver detalles"><i class="fa fa-list-ol text-primary" aria-hidden="true"></i></button></span></div>'
                                    }
                                ];

                                $scope.gridOptions.data = [];

                                $scope.submitForm = function ( isValid ) {
                                    if (!isValid) {
                                        return;
                                    }
                                    gestionService.getListaQueja($scope.filtro).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptions.data = res.data;
                                        } else {
                                            toastr.error("Error al obtener datos: " + res.message);
                                        }
                                    }, function (error) {
                                        toastr.error("Error al obtener datos generales: " + error + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                                    });
                                }

                                $scope.verDetalle = function ( item ) {
                                    var detalle = item.detalle;
                                    var strDetalle = '';
                                    for (var i = 0; i < detalle.length; i++) {
                                        var row = detalle[i];
                                        strDetalle += row.tcMotivoQueja.motivoQuejaDesc + '\n';
                                    }
                                    swal('Más de detalle de la queja', strDetalle);
                                }

                                $scope.descargarQuejas = function () {
                                    var xlsxData = [];
                                    var xlsxHeader = ['Fecha', 'Nombre', 'Correo', 'Teléfono', 'Subregión', 'Región', 'Descripción', 'Motivo'];
                                    xlsxData.push(xlsxHeader);
                                    var data = $scope.gridOptions.data;
                                    if (data.length <= 0) {
                                        toastr.error('No hay datos que descargar');
                                        return;
                                    }
                                    for (var i = 0; i < data.length; i++) {
                                        var row = data[i];
                                        var detalles = row.detalle;
                                        var xlsxRow = [];
                                        var fecha = $filter( 'date' )(new Date(row.fechaRegistro), 'dd/MM/yyyy HH:mm' );
                                        xlsxRow.push(fecha);
                                        xlsxRow.push(row.tcPersona.personaDesc);
                                        xlsxRow.push(row.tcPersona.correo);
                                        xlsxRow.push(row.tcPersona.telefono);
                                        xlsxRow.push(row.tcSubregion.subregionDesc);
                                        xlsxRow.push(row.tcSubregion.tcRegion.regionDesc);
                                        xlsxRow.push(row.quejaDesc);
                                        if (detalles == null) {
                                            detalles = [];
                                        }
                                        if (detalles.length > 0) {
                                            for (var j = 0; j < detalles.length; j++) {
                                                var detalle = detalles[j];
                                                xlsxRow = [];
                                                xlsxRow.push(fecha);
                                                xlsxRow.push(row.tcPersona.personaDesc);
                                                xlsxRow.push(row.tcPersona.correo);
                                                xlsxRow.push(row.tcPersona.telefono);
                                                xlsxRow.push(row.tcSubregion.subregionDesc);
                                                xlsxRow.push(row.tcSubregion.tcRegion.regionDesc);
                                                xlsxRow.push(row.quejaDesc);
                                                xlsxRow.push(detalle.tcMotivoQueja.motivoQuejaDesc);
                                                xlsxData.push(xlsxRow);
                                            }
                                        } else {
                                            xlsxRow.push('-');
                                            xlsxData.push(xlsxRow);
                                        }
                                    }
                                    var filename = "quejas.xlsx";
                                    var sheetName = "data";
                                    var wb = XLSX.utils.book_new();
                                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                                    XLSX.writeFile(wb, filename);
                                  }

                            }]
                    })
            }
        ]
    );