angular.module('app.subregionmunicipio', [
  'ui.router',
  'toastr',
  'app.subregionmunicipio.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.subregionmunicipio', {
          abstract: true,
          url: 'subregionmunicipio',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope', 'departamentoService', 'municipioService', 'subregionService',
            function (  $scope, departamentoService, municipioService, subregionService ) {
              $scope.module = 'Sub región';
              $scope.data = {};
              $scope.cargarListaDepartamento = function (item) {
                $scope.variable.departamentoId = null;
                $scope.data.municipioId = null;
                departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataDepartamento = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaMunicipio = function (item) {
                $scope.data.municipioId = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaSubregion = function (item) {
                $scope.data.subregionId = null;
                subregionService.listaPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataSubRegion = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

            }]
        })
        .state('index.subregionmunicipio.list', {
          url: '',
          templateUrl: 'app/subregionmunicipio/subregionmunicipio.list.tpl.html',
          resolve: {
            dataSubregionMunicipio: ['subregionmunicipioService',
              function ( subregionmunicipioService ){
                return subregionmunicipioService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataSubregionMunicipio',
            function (  $scope,   $state,   dataSubregionMunicipio) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcSubregion.tcRegion.regionDesc', name: 'regionDesc', displayName: 'Región' },
                { field:'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                { field:'tcMunicipio.tcDepartamento.departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento' },
                { field:'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataSubregionMunicipio.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit', { subregionMunicipioId: row.subregionMunicipioId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.subregionmunicipio.add', {
          url: '/add',
          templateUrl: 'app/subregionmunicipio/subregionmunicipio.add.tpl.html',
          resolve: {
            dataEstado: ['subregionmunicipioService',
              function ( subregionmunicipioService ){
                return subregionmunicipioService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataPais: ['paisService',
              function ( paisService ){
                return paisService.listaActiva();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'subregionmunicipioService', 'dataEstado', 'dataRegion', 'dataPais', 'dataDepartamento',
            function (  $scope,   $state,   toastr,   subregionmunicipioService, dataEstado, dataRegion, dataPais, dataDepartamento) {
              $scope.data = {
                tcMunicipio: {
                  municipioId: null
                },
                tcSubregion: {
                  subregionId: null
                }
              };
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataPais = dataPais.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  subregionmunicipioService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.subregionmunicipio.edit', {
          url: '/:subregionMunicipioId/edit',
          templateUrl: 'app/subregionmunicipio/subregionmunicipio.add.tpl.html',
          resolve: {
            dataSubregionMunicipio: ['subregionmunicipioService', '$stateParams',
              function ( subregionmunicipioService, $stateParams ){
                return subregionmunicipioService.get( $stateParams.subregionMunicipioId );
              }],
            dataEstado: ['subregionmunicipioService',
              function ( subregionmunicipioService ){
                return subregionmunicipioService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataPais: ['paisService',
              function ( paisService ){
                return paisService.listaActiva();
              }],
            dataDepartamento: ['departamentoService', 'dataSubregionMunicipio',
              function ( departamentoService, dataSubregionMunicipio ){
                return departamentoService.listaPorPais(dataSubregionMunicipio.data[0].tcMunicipio.tcDepartamento.tcPais.paisId);
              }],
            dataMunicipio: ['municipioService', 'dataSubregionMunicipio',
              function ( municipioService, dataSubregionMunicipio ) {
                return municipioService.listaPorDepartamento(dataSubregionMunicipio.data[0].tcMunicipio.tcDepartamento.departamentoId);
              }],
            dataSubRegion: ['subregionService', 'dataSubregionMunicipio',
              function ( subregionService, dataSubregionMunicipio ) {
                return subregionService.listaPorRegion(dataSubregionMunicipio.data[0].tcSubregion.tcRegion.regionId);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'subregionmunicipioService', 'dataSubregionMunicipio', 'dataEstado',
          'dataRegion', 'dataPais', 'dataDepartamento', 'dataMunicipio', 'dataSubRegion',
            function ( $scope, $state, toastr, subregionmunicipioService, dataSubregionMunicipio, dataEstado,
            dataRegion, dataPais, dataDepartamento, dataMunicipio, dataSubRegion) {
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataPais = dataPais.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.dataSubRegion = dataSubRegion.data;
              $scope.dataMunicipio = dataMunicipio.data;
              $scope.data = dataSubregionMunicipio.data[0];
              $scope.variable = {
                paisId : $scope.data.tcMunicipio.tcDepartamento.tcPais.paisId,
                departamentoId : $scope.data.tcMunicipio.tcDepartamento.departamentoId,
                regionId : $scope.data.tcSubregion.tcRegion.regionId
              }
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  subregionmunicipioService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
