angular.module('app.plan', [
  'ui.router',
  'toastr',
  'app.gestion.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.plan', {
            abstract: true,
            url: 'plan/:gestionId',
            params: {
            },
            views: {
              '':
              {
                templateUrl: 'app/gestion/gestion.tpl.html',
                resolve: {
                  dataGestion: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getConCronograma($base64.decode($stateParams.gestionId));
                    }],
                  dataTipoPropietario: ['tipopropietarioService', '$stateParams',
                    function (tipopropietarioService, $stateParams) {
                      return tipopropietarioService.listaActiva();
                    }],
                  dataTipoEntidad: ['tipoentidadService', '$stateParams',
                    function (tipoentidadService, $stateParams) {
                      return tipoentidadService.listaActiva();
                    }],
                  dataClaseDiametrica: ['clasediametricaService', '$stateParams',
                    function (clasediametricaService, $stateParams) {
                      return clasediametricaService.listaActiva();
                    }],
                  dataEspecie: ['dataGestion', 'especieService',
                    function (dataGestion, especieService) {
                      var ttGestion = dataGestion.data[0];
                      return especieService.getListaByBosque(ttGestion.tcTipoBosque.tipoBosqueId);
                    }],
                  dataSolicitud: ['dataGestion', 'solicitudService',
                    function (dataGestion, solicitudService) {
                      var ttGestion = dataGestion.data[0];
                      if (ttGestion.estadoId == 6) {
                        return solicitudService.getDataByGestion(ttGestion.gestionId);
                      }
                      var res = {
                        status: 'OK',
                        message: 'Datos cargados',
                        data: null
                      };
                      return res;
                    }]
                },
                controller: ['$scope', '$stateParams', 'dataGestion', 'dataTipoPropietario', 'dataTipoEntidad', '$state', 'toastr', 'utilsService', '$timeout',
                  'authService', 'dataClaseDiametrica', 'dataEspecie', 'dataSolicitud', 'gestionService',
                  function ($scope, $stateParams, dataGestion, dataTipoPropietario, dataTipoEntidad, $state, toastr, utilsService, $timeout,
                    authService, dataClaseDiametrica, dataEspecie, dataSolicitud, gestionService) {
                    $scope.removeStateToGo();
                    authService.removeLocalData('esVerificacion');
                    authService.removeLocalData('dataEspecie');
                    authService.saveLocalData('dataEspecie', dataEspecie.data);
                    $scope.module = 'Plan de Manejo';
                    $scope.data = dataGestion.data[0];
                    if (dataSolicitud.status == 'OK') {
                      if (dataSolicitud.data == null) {
                        $scope.dataSolicitud = undefined;
                      } else {
                        $scope.dataSolicitud = dataSolicitud.data[0];
                      }
                    } else {
                      $scope.dataSolicitud = undefined;
                    }
                    var perfiles = [3, 5, 7, 8, 16, 20, 35, 36];
                    var permitido = false;
                    var perfilId = null;
                    for (var i = 0; i < perfiles.length; i++) {
                      perfilId = perfiles[i];
                      if ($scope.loginData.perfilId == perfilId) {
                        permitido = true;
                        break;
                      }
                    }
                    if (!permitido) {
                      toastr.error('Su perfil no tiene permisos para esta opción', 'Acceso no autorizado');
                      $state.go('index.forbidden.show');
                      return;
                    }
                    if ($scope.data.estadoId > 4) {
                      if ($scope.data.estadoId != 6) {
                        permitido = false;
                        perfiles = [5, 7, 8, 16, 20, 36];
                        for (var i = 0; i < perfiles.length; i++) {
                          perfilId = perfiles[i];
                          if ($scope.loginData.perfilId == perfilId) {
                            permitido = true;
                            break;
                          }
                        }
                        if (!permitido) {
                          toastr.error('Este plan no permite realizar modificaciones', 'Acceso no autorizado');
                          $state.go('index.forbidden.show');
                          return;
                        }
                      }
                    }
                    $scope.globales.totalPaginas = 0;
                    $scope.hayCambiosGuardar = false;

                    var enableTabs = {
                      realizar: false,
                      biofisica: true,
                      finca: true,
                      inventario: true,
                      planificacion: true,
                      resumen: true,
                      proteccion: true,
                      cronograma: true,
                      anexo: true
                    }

                    $scope.titulos = {
                      rodal: 'rodal',
                      rodales: 'rodales',
                      Rodal: 'Rodal',
                      Rodales: 'Rodales'
                    }

                    if ($scope.data.tcTipoBosque.tipoBosqueId == 3) {
                      $scope.titulos = {
                        rodal: 'estrato',
                        rodales: 'estratos',
                        Rodal: 'Estrato',
                        Rodales: 'Estratos'
                      }
                    }

                    $scope.setTabs = function (step) {
                      var planTipoGestionId = $scope.data.tcPlanTipoGestion.planTipoGestionId;
                      if (planTipoGestionId != 4) {
                        switch (step) {
                          case 1:
                            enableTabs.realizar = false;
                            break;
                          case 2:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            break;
                          case 3:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            break;
                          case 4:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            break;
                          case 5:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.planificacion = false;
                            break;
                          case 6:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.planificacion = false;
                            enableTabs.resumen = false;
                            break;
                          case 7:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            enableTabs.proteccion = false;
                            break;
                          case 8:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                              if ($scope.data.ttPlanificacionGestion.hayReforestacion == 1) {
                                enableTabs.proteccion = false;
                              }
                            } else {
                              enableTabs.proteccion = false;
                            }
                            enableTabs.cronograma = false;
                            break;
                          case 9:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                              if ($scope.data.ttPlanificacionGestion.hayReforestacion == 1) {
                                enableTabs.proteccion = false;
                              }
                            } else {
                              enableTabs.proteccion = false;
                            }
                            enableTabs.cronograma = false;
                            enableTabs.anexo = false;
                            break;
                          default:
                            enableTabs.realizar = false;
                            break;
                        }
                      } else if (planTipoGestionId == 4) {
                        switch (step) {
                          case 1:
                            enableTabs.realizar = false;
                            break;
                          case 2:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            break;
                          case 3:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            break;
                          case 4:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            break;
                          case 5:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.planificacion = false;
                            break;
                          case 6:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.planificacion = false;
                            enableTabs.resumen = false;
                            break;
                          case 7:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            enableTabs.proteccion = false;
                            break;
                          case 8:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                              if ($scope.data.ttPlanificacionGestion.hayReforestacion == 1) {
                                enableTabs.proteccion = false;
                              }
                            } else {
                              enableTabs.proteccion = false;
                            }
                            enableTabs.cronograma = false;
                            break;
                          case 9:
                            enableTabs.realizar = false;
                            enableTabs.biofisica = false;
                            enableTabs.finca = false;
                            enableTabs.inventario = false;
                            enableTabs.resumen = false;
                            enableTabs.planificacion = false;
                            if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                              if ($scope.data.ttPlanificacionGestion.hayReforestacion == 1) {
                                enableTabs.proteccion = false;
                              }
                            } else {
                              enableTabs.proteccion = false;
                            }
                            enableTabs.cronograma = false;
                            enableTabs.anexo = false;
                            break;
                          default:
                            enableTabs.realizar = false;
                            break;
                        }
                      }
                      $scope.tabs = [
                        { name: 'General', icon: 'fa fa-info-circle', link: 'index.plan.realizar', visible: !enableTabs.realizar, disabled: enableTabs.realizar },
                        { name: 'Biofísicas', icon: 'fa fa-bars', link: 'index.plan.biofisica', visible: !enableTabs.biofisica, disabled: enableTabs.biofisica },
                        { name: 'Características de la finca', icon: 'fa fa-bar-map', link: 'index.plan.finca', visible: !enableTabs.finca, disabled: enableTabs.finca },
                        { name: 'Inventario forestal', icon: 'fa fa-tree', link: 'index.plan.inventario', visible: !enableTabs.inventario, disabled: enableTabs.inventario },
                        { name: 'Planificación del manejo', icon: 'fa fa-line-chart', link: 'index.plan.planificacion', visible: !enableTabs.planificacion, disabled: enableTabs.planificacion },
                        { name: 'Resumen', icon: 'fa fa-bar-chart', link: 'index.plan.resumen', visible: !enableTabs.resumen, disabled: enableTabs.resumen },
                        { name: 'Medidas de protección', icon: 'fa fa-recycle', link: 'index.plan.proteccion', visible: !enableTabs.proteccion, disabled: enableTabs.proteccion },
                        { name: 'Cronograma de actividades', icon: 'fa fa-calendar', link: 'index.plan.cronograma', visible: !enableTabs.cronograma, disabled: enableTabs.cronograma },
                        { name: 'Anexos (mapas y otros)', icon: 'fa fa-tags', link: 'index.plan.anexo', visible: !enableTabs.anexo, disabled: enableTabs.anexo }
                      ];
                    }

                    $scope.dataTipoPropietario = dataTipoPropietario.data;
                    $scope.dataTipoEntidad = dataTipoEntidad.data;
                    $scope.dataClaseDiametrica = dataClaseDiametrica.data;
                    authService.removeLocalData('dataClaseDiametrica');
                    authService.saveLocalData('dataClaseDiametrica', dataClaseDiametrica.data);

                    if ($scope.data.cronograma != undefined) {
                      if ($scope.data.cronograma != null) {
                        try {
                          $scope.data.cronograma = JSON.parse($scope.data.cronograma);
                        } catch (e) {
                          $scope.data.cronograma = null;
                        }
                      }
                    }

                    if ($scope.data.ttInventarioGestion != undefined) {
                      if ($scope.data.ttInventarioGestion != null) {
                        try {
                          if ($scope.data.ttInventarioGestion.data != undefined && $scope.data.ttInventarioGestion.data != null) {
                            $scope.data.ttInventarioGestion.data = JSON.parse($scope.data.ttInventarioGestion.data);
                          }
                        } catch (e) {
                          $scope.data.ttInventarioGestion.data = [];
                        }
                        try {
                          $scope.data.ttInventarioGestion.brinzal = JSON.parse($scope.data.ttInventarioGestion.brinzal);
                        } catch (e) {
                          $scope.data.ttInventarioGestion.brinzal = [];
                        }
                        try {
                          $scope.data.ttInventarioGestion.latizal = JSON.parse($scope.data.ttInventarioGestion.latizal);
                        } catch (e) {
                          $scope.data.ttInventarioGestion.latizal = [];
                        }
                        try {
                          if ($scope.data.rodal != undefined && $scope.data.rodal != null) {
                            var dataResumen = $scope.data.rodal;
                            for (i = 0; i < dataResumen.length; i++) {
                              for (j = 0; j < dataResumen[i].especies.length; j++) {
                                for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                                  dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                                }
                                if (dataResumen[i].especies[j].fuste != null) {
                                  dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                                  dataResumen[i].especies[j].mostrarFuste = 1;
                                }
                              }
                            }
                          }
                        } catch (e) {
                          $scope.data.rodal = [];
                        }
                        if ($scope.data.codigoClase == undefined || $scope.data.codigoClase == null) {
                          $scope.data.codigoClase = [];
                        }
                      }
                    }

                    $scope.textoImprimiendo = "Imprimir";
                    $scope.imprimiendo = false;

                    $scope.imprimirFormato = function () {
                      if ($scope.hayCambiosGuardar) {
                        toastr.error('Verifique los volúmenes de troza y leña, luego presione sobre el botón guardar');
                        return;
                      }
                      try {
                        $scope.imprimiendo = true;
                        $scope.textoImprimiendo = "Imprimiendo";
                        if (parseInt($scope.data.ultimoPanel) >= 8) {
                          gestionService.get($scope.data.gestionId).then(function (res) {
                            if (res.status == "OK") {
                              var data = res.data[0];
                              var dataResumen = data.rodal;
                              for (i = 0; i < dataResumen.length; i++) {
                                for (j = 0; j < dataResumen[i].especies.length; j++) {
                                  for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                                    dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                                  }
                                  if (dataResumen[i].especies[j].fuste != null) {
                                    dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                                  }
                                }
                              }
                              //generales
                              if (data.ttInventarioGestion == undefined || data.ttInventarioGestion == null) {
                                toastr.error("La información de inventario esta incompleto, favor de revisar");
                                return;
                              }
                              if (data.ttPlanificacionGestion == undefined || data.ttPlanificacionGestion == null) {
                                toastr.error("La información de planificación esta incompleto, favor de revisar");
                                return;
                              }
                              if (data.cronograma == undefined || data.cronograma == null) {
                                toastr.error("El cronograma esta incompleto, favor de revisar");
                                return;
                              }
                              try {
                                if (data.ttInventarioGestion.data != undefined && data.ttInventarioGestion.data != null) {
                                  data.ttInventarioGestion.data = JSON.parse(data.ttInventarioGestion.data);
                                }
                                try {
                                  data.ttInventarioGestion.brinzal = JSON.parse(data.ttInventarioGestion.brinzal);
                                } catch (e) {
                                  data.ttInventarioGestion.brinzal = [];
                                }
                                try {
                                  data.ttInventarioGestion.latizal = JSON.parse(data.ttInventarioGestion.latizal);
                                } catch (e) {
                                  data.ttInventarioGestion.latizal = [];
                                }
                                toastr.info('Espere mientras se imprimen los documentos', 'Imprimiendo');
                                utilsService.generarPlan($scope.dataEmpresa, data);
                                $timeout(function () {
                                  $scope.imprimiendo = false;
                                  $scope.textoImprimiendo = "Imprimir";
                                  if (data.tcSubregion.tcSubregional == null) {
                                    toastr.error('El director subregional de la subregión ' + data.tcSubregion.subregionDesc + ' no se encuentra configurado, no es posible imprimir la solicitud, comuniquese con la subregión para informar el inconveniente');
                                  } else {
                                    utilsService.generarSolicitudLicencia(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                  }
                                }, 3000);
                                if (data.ttResumenGestion.noTurnos > 1) {
                                  $timeout(function () {
                                    utilsService.generarPoa($scope.dataEmpresa, data, 1);
                                  }, 1000);
                                }
                              } catch (e) {
                                toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador. " + e.message);
                              }
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      } catch (e) {
                        toastr.error('Error al imprimir plan, asegúrese que el plan está debidamente finalizado. ' + e.message);
                      }
                    }

                    /*Establecer ultimo panel*/
                    switch (parseInt($scope.data.ultimoPanel)) {
                      case 1:
                        $scope.setTabs(1);
                        $state.go('^.realizar');
                        break;
                      case 2:
                        $scope.setTabs(2);
                        $state.go('^.biofisica');
                        break;
                      case 3:
                        $scope.setTabs(3);
                        $state.go('^.finca');
                        break;
                      case 4:
                        $scope.setTabs(4);
                        $state.go('^.inventario');
                        break;
                      case 5:
                        $scope.setTabs(5);
                        $state.go('^.planificacion');
                        break;
                      case 6:
                        $scope.setTabs(6);
                        $state.go('^.resumen');
                        break;
                      case 7:
                        $scope.setTabs(7);
                        $state.go('^.proteccion');
                        break;
                      case 8:
                        $scope.setTabs(8);
                        $state.go('^.cronograma');
                        break;
                      case 9:
                        $scope.setTabs(9);
                        $state.go('^.anexo');
                        break;
                      case 10:
                        $scope.setTabs(9);
                        $state.go('^.anexo');
                        break;
                      default:
                        $scope.setTabs(1);
                        $state.go('^.realizar');
                        break;
                    }

                  }
                ]
              }
            }
          })
          .state('index.plan.realizar', {
            url: '/realizar',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.general.tpl.html',
                resolve: {
                  dataIdioma: ['idiomaService', '$stateParams',
                    function (idiomaService, $stateParams) {
                      return idiomaService.listaActiva();
                    }],
                  dataCultura: ['culturaService', '$stateParams',
                    function (culturaService, $stateParams) {
                      return culturaService.listaActiva();
                    }],
                  dataEstadoCivil: ['estadocivilService', '$stateParams',
                    function (estadocivilService, $stateParams) {
                      return estadocivilService.listaActiva();
                    }],
                  dataTipoDocumento: ['tipodocumentoService', '$stateParams',
                    function (tipodocumentoService, $stateParams) {
                      return tipodocumentoService.listaActiva();
                    }],
                  dataPais: ['paisService', '$stateParams',
                    function (paisService, $stateParams) {
                      return paisService.listaActiva();
                    }],
                  dataDepartamento: ['departamentoService', '$stateParams',
                    function (departamentoService, $stateParams) {
                      return departamentoService.listaPorPais(1);
                    }],
                  dataTipoNotificacion: ['tiponotificacionService', '$stateParams',
                    function (tiponotificacionService, $stateParams) {
                      return tiponotificacionService.listaActiva();
                    }],
                  dataTipoPropiedad: ['tipopropiedadService',
                    function (tipopropiedadService) {
                      return tipopropiedadService.listaActivaLicencia();
                    }],
                  dataTipoRepresentacion: ['tiporepresentacionService',
                    function (tiporepresentacionService) {
                      return tiporepresentacionService.listaActiva();
                    }],
                  dataLibro: ['libroService',
                    function (libroService) {
                      return libroService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'personaService', 'dataIdioma',
                  'dataCultura', 'dataEstadoCivil', 'dataTipoDocumento', 'dataPais', 'dataDepartamento', 'dataTipoNotificacion', '$base64',
                  'fincaService', 'dataTipoPropiedad', 'municipioService', '$window', '$filter', 'dataTipoRepresentacion', 'dataLibro',
                  function ($scope, $state, toastr, gestionService, ngDialog, personaService, dataIdioma,
                    dataCultura, dataEstadoCivil, dataTipoDocumento, dataPais, dataDepartamento, dataTipoNotificacion, $base64,
                    fincaService, dataTipoPropiedad, municipioService, $window, $filter, dataTipoRepresentacion, dataLibro) {

                    $window.scrollTo(0, 0);

                    $scope.dataTipoPropiedad = dataTipoPropiedad.data;
                    $scope.dataFinca = [];
                    $scope.dataTipoRepresentacion = dataTipoRepresentacion.data;

                    $scope.removeStateToGo();
                    $scope.globales.crear = 1;
                    $scope.globales.solicitante = $scope.data.personaSolicita;
                    $scope.globales.tipo = "Elaboración de plan";
                    $scope.dataIdioma = dataIdioma.data;
                    $scope.dataCultura = dataCultura.data;
                    $scope.dataEstadoCivil = dataEstadoCivil.data;
                    $scope.dataTipoDocumento = dataTipoDocumento.data;
                    $scope.dataPais = dataPais.data;
                    $scope.dataDepartamento = dataDepartamento.data;
                    $scope.dataDepartamentoFinca = dataDepartamento.data;
                    $scope.dataTipoNotificacion = dataTipoNotificacion.data;
                    $scope.dataLibro = dataLibro.data;
                    $scope.dataSexo = [
                      { sexoId: 1, sexoDesc: 'Femenino' },
                      { sexoId: 2, sexoDesc: 'Masculino' }
                    ];
                    $scope.aplicaRepresentanteTexto = "NO";
                    $scope.panel = {
                      entidad: false,
                      solicitante: true,
                      notificacion: true,
                      finca: true,
                      openPropietario: true,
                      openSolicitante: false,
                      openNotificacion: false,
                      openFinca: false,
                      requeridoPropietario: false,
                      classGrupal: 'btn btn-default btn-xs',
                      aplicaRepresentante: 0,
                      editarPersona: false,
                      conteoPersonas: 0,
                      mujeres: 0,
                      hombres: 0
                    }

                    $scope.cargarListaMunicipioRepresentacion = function (item, borrar) {
                      if (borrar) {
                        if ($scope.data.ttRepresentanteGestion.tcMunicipio != null) {
                          $scope.data.ttRepresentanteGestion.tcMunicipio.municipioId = null;
                        }
                      }
                      municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataMunicipioRepresentante = res.data;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.validarPropietarioFinca = function (personaId) {
                      var hay = false;
                      for (var i = 0; i < $scope.data.fincas.length; i++) {
                        for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                          if ($scope.data.fincas[i].propietarios[j].tcPersona.personaId == personaId) {
                            hay = true;
                            break;
                          }
                        }
                        if (hay) {
                          break;
                        }
                      }
                      return hay;
                    }

                    $scope.setPropietarios = function () {
                      if ($scope.data.ttTipoPropietarioGestion == null) {
                        return;
                      }
                      if ($scope.data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                        var data = [
                          {
                            personaId: 1,
                            personaDesc: $scope.data.ttTipoPropietarioGestion.razonSocial
                          }
                        ];
                        $scope.dataPersona = data;
                      } else {
                        if ($scope.data.personas != undefined) {
                          if ($scope.data.personas.length > 0) {
                            var data = [];
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0)) {
                                data.push(angular.copy($scope.data.personas[i].tcPersona));
                              }
                            }
                            $scope.dataPersona = data;
                          }
                        }
                      }
                    }

                    $scope.data.usuarioModificaId = $scope.loginData.usuarioId;
                    $scope.mostrarProfesional = true;

                    /*Validaciones iniciales*/
                    if ($scope.data.ttTipoPropietarioGestion != null) {
                      $scope.panel.openSolicitante = true;
                      $scope.panel.solicitante = false;
                    }
                    if ($scope.data.personas != null) {
                      $scope.panel.openNotificacion = true;
                      $scope.panel.notificacion = false;
                    } else {
                      $scope.data.personas = [];
                    }

                    if ($scope.data.fincas == null) {
                      $scope.data.fincas = [];
                    }

                    var obligatorio = [];
                    for (var i = 0; i < $scope.dataTipoNotificacion.length; i++) {
                      if ($scope.dataTipoNotificacion[i].esObligatorio == 1) {
                        obligatorio.push($scope.dataTipoNotificacion[i]);
                      }
                    }

                    $scope.cargarListaMunicipioNotificacion = function (item, notificacion, limpiar) {
                      if (limpiar == null) {
                        notificacion.tcMunicipio = null;
                      }
                      municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                        if (res.status == "OK") {
                          notificacion.dataMunicipio = res.data;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    var tmp = $scope.data;
                    if ($scope.data.notificaciones != null) {
                      $scope.panel.openFinca = true;
                      $scope.panel.finca = false;
                      var encontrado = false;
                      for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                        notificacion = $scope.data.notificaciones[j];
                        /*Los datos ya guardados no traen la gestion, se debe agregar para poder actualizarse*/
                        if (notificacion.ttGestion == null) {
                          notificacion.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          }
                        }
                        if (notificacion.tcTipoNotificacion.esDireccion == 1) {
                          if (notificacion.tcMunicipio != null) {
                            notificacion.departamentoId = notificacion.tcMunicipio.tcDepartamento.departamentoId;
                            $scope.cargarListaMunicipioNotificacion({ departamentoId: notificacion.departamentoId }, notificacion, true);
                          }
                        }
                      }
                      for (var i = 0; i < obligatorio.length; i++) {
                        encontrado = false;
                        for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                          if ($scope.data.notificaciones[j].tcTipoNotificacion.tipoNotificacionId == obligatorio[i].tipoNotificacionId) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          var item = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            tcTipoNotificacion: obligatorio[i],
                            notificacionGestionDesc: null,
                            estadoId: 1
                          }
                          $scope.data.notificaciones.push(item);
                        }
                      }
                    } else {
                      $scope.data.notificaciones = [];
                      for (var i = 0; i < obligatorio.length; i++) {
                        if (obligatorio[i].esObligatorio == 1) {
                          var item = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            tcTipoNotificacion: obligatorio[i],
                            notificacionGestionDesc: null,
                            estadoId: 1
                          }
                          $scope.data.notificaciones.push(item);
                        }
                      }
                    }

                    $scope.setPropietarios();

                    $scope.setLibroFinca = function (item, finca) {
                      finca.tcLibro = item;
                      finca.tcMunicipio = null;
                    }

                    $scope.setDepartamentoFinca = function (item, finca, limpiar) {
                      if (limpiar == undefined) {
                        limpiar = true;
                      }
                      if (limpiar) {
                        finca.tcMunicipio = null;
                      }
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId != 3) {
                          finca.tcLibro = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              finca.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    }

                    //cargar municipio de las fincas ya cargadas
                    if ($scope.data.fincas != null) {
                      var encontrado = false;
                      for (var i = 0; i < $scope.data.fincas.length; i++) {
                        if ($scope.data.fincas[i].tcMunicipioEmite != null) {
                          $scope.setDepartamentoFinca({ departamentoId: $scope.data.fincas[i].tcMunicipioEmite.tcDepartamento.departamentoId }, $scope.data.fincas[i], false);
                        } else {
                          $scope.data.fincas[i].tcMunicipioEmite = {
                            municipioId: null,
                            tcDepartamento: {
                              departamentoId: null
                            }
                          }
                        }
                        var documentos = $scope.data.fincas[i].documentos;
                        if (documentos == null) {
                          documentos = [];
                        }
                        for (var j = 0; j < documentos.length; j++) {
                          doc = documentos[j];
                          if (doc.tcMunicipioEmite != null) {
                            $scope.setDepartamentoFinca({ departamentoId: doc.tcMunicipioEmite.tcDepartamento.departamentoId }, doc, false);
                          } else {
                            doc.tcMunicipioEmite = {
                              municipioId: null,
                              tcDepartamento: {
                                departamentoId: null
                              }
                            }
                          }
                          if (doc.fechaEmision == null) {
                            doc.fechaEmision = new Date();
                          } else {
                            doc.fechaEmision = new Date(doc.fechaEmision);
                          }
                        }
                        if ($scope.data.fincas[i].propietarios == null) {
                          $scope.data.fincas[i].propietarios = [];
                        }
                        $scope.data.fincas[i].personas = [];
                        for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                          var tcPersona = $scope.data.fincas[i].propietarios[j].tcPersona;
                          $scope.data.fincas[i].personas.push(tcPersona);
                        }
                        if ($scope.data.fincas[i].fechaEmision == null) {
                          $scope.data.fincas[i].fechaEmision = new Date();
                        } else {
                          $scope.data.fincas[i].fechaEmision = new Date($scope.data.fincas[i].fechaEmision);
                        }
                        if ($scope.data.fincas[i].tcTipoPropiedad != null) {
                          if ($scope.data.fincas[i].tcTipoPropiedad.tipoPropiedadId == 1 || $scope.data.fincas[i].tcTipoPropiedad.tipoPropiedadId == 3) {
                            $scope.data.fincas[i].mostrarNumero = true;
                          } else {
                            $scope.data.fincas[i].mostrarNumero = false;
                          }
                          if ($scope.data.fincas[i].tcTipoPropiedad.tipoPropiedadId == 1 || $scope.data.fincas[i].tcTipoPropiedad.tipoPropiedadId == 2) {
                            $scope.data.fincas[i].mostrarNotario = true;
                          } else {
                            $scope.data.fincas[i].mostrarNotario = false;
                          }
                        }
                      }
                    }

                    //validar si se selecciono alguna finca para mostrarla
                    if ($scope.globales.fincaId != null) {
                      var tmp = $scope.data;
                      var item = {
                        ttGestion: {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        },
                        tcFinca: {
                          fincaId: $scope.globales.fincaId
                        }
                      }
                      gestionService.agregarFincaGestion(item).then(function (response) {
                        if (response.status == 'OK') {
                          item = response.data[0];
                          item.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          };
                          item.fechaEmision = new Date();
                          $scope.data.fincas.push(item);
                          $scope.globales.fincaId = null;
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    if ($scope.data.representantes != null) {
                      if ($scope.data.representantes.length > 0) {
                        $scope.panel.aplicaRepresentante = 1;
                        $scope.aplicaRepresentanteTexto = "SI";
                        $scope.panel.classGrupal = 'btn btn-danger btn-xs';
                      }
                    }

                    if ($scope.data.ttRepresentanteGestion == null) {
                      $scope.data.ttRepresentanteGestion = {
                        numeroEscritura: 0
                      };
                    } else {
                      if ($scope.data.ttRepresentanteGestion.tcMunicipio != null) {
                        $scope.data.departamentoId = $scope.data.ttRepresentanteGestion.tcMunicipio.tcDepartamento.departamentoId;
                        $scope.cargarListaMunicipioRepresentacion({ departamentoId: $scope.data.ttRepresentanteGestion.tcMunicipio.tcDepartamento.departamentoId }, false);
                      }
                    }
                    /*Validaciones iniciales*/

                    /*panel propietario*/
                    $scope.setTipoPropietario = function (item) {
                      $scope.panel.openSolicitante = false;
                      $scope.panel.openNotificacion = false;
                      $scope.panel.openFinca = false;
                      if (item.tipoPropietarioId == 2) {
                        $scope.panel.requeridoPropietario = true;
                      } else {
                        $scope.panel.requeridoPropietario = false;
                      }
                      $scope.tcTipoPropietario = item;
                    }

                    $scope.getTotalHombres = function () {
                      $scope.panel.hombres = 0;
                      for (var i = 0; i < $scope.data.personas.length; i++) {
                        if ($scope.data.personas[i].tcPersona.tcSexo.sexoId == 2 && ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0))) {
                          $scope.panel.hombres++;
                        }
                      }
                      return $scope.panel.hombres;
                    }

                    $scope.getTotalMujeres = function () {
                      $scope.panel.mujeres = 0;
                      for (var i = 0; i < $scope.data.personas.length; i++) {
                        if ($scope.data.personas[i].tcPersona.tcSexo.sexoId == 1 && ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0))) {
                          $scope.panel.mujeres++;
                        }
                      }
                      return $scope.panel.mujeres;
                    }

                    $scope.getRepresentante = function () {
                      var nombre = "Aún no indicado";
                      for (var i = 0; i < $scope.data.personas.length; i++) {
                        if ($scope.data.personas[i].representanteLegal == 1) {
                          nombre = $scope.data.personas[i].personaDesc;
                          break;
                        }
                      }
                      return nombre;
                    }

                    $scope.getDpiRepresentante = function () {
                      var dpi = "";
                      if ($scope.data.personas.length > 0) {
                        if ($scope.data.representanteNombre != null && $scope.data.representanteDpi != null) {
                          dpi = $scope.data.representanteDpi;
                        }
                      }
                      return dpi;
                    }

                    $scope.agregarPersona = function (data, tipoPersona) {
                      gestionService.agregarPersona(data).then(function (response) {
                        if (response.status == 'OK') {
                          registro = response.data[0];
                          persona = registro.tcPersona;
                          persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                          var encontrado = false;
                          if (tipoPersona == 0) {
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].tcPersona.personaId == persona.personaId) {
                                break;
                              }
                            }
                            if (!encontrado) {
                              $scope.data.personas.push(registro);
                            }
                            $scope.setPropietarios();
                          } else {
                            for (var i = 0; i < $scope.data.representantes.length; i++) {
                              if ($scope.data.representantes[i].tcPersona.personaId == persona.personaId) {
                                break;
                              }
                            }
                            if (!encontrado) {
                              $scope.data.representantes.push(registro);
                            }
                            $scope.panel.aplicaRepresentante = 1;
                            $scope.aplicaRepresentanteTexto = "SI";
                            $scope.panel.classGrupal = 'btn btn-danger btn-xs';
                          }
                          toastr.success(response.message);
                          ngDialog.close();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.submitFormEntidad = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var ttTipoPropietarioGestion = tmp.ttTipoPropietarioGestion;
                        if (ttTipoPropietarioGestion.tcTipoEntidad != null) {
                          if (ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadId != 1) {
                            ttTipoPropietarioGestion.nombreComercial = 'N/A';
                          }
                        }
                        var data = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        gestionService.setTipoPropietario(data).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.panel.solicitante = true;
                            $scope.panel.openSolicitante = true;
                            $scope.data.ttTipoPropietarioGestion = response.data[0];
                            var encontrado = false;
                            var personas = [];
                            if ($scope.data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 1) {
                              if ($scope.data.personas != null) {
                                personas = $scope.data.personas;
                              }
                              for (var i = 0; i < personas.length; i++) {
                                if (personas[i].tcPersona.personaId == $scope.data.tcPersonaCrea.personaId) {
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                var tmp = $scope.data;
                                var data = {
                                  personaGestionId: 0,
                                  tcPersona: $scope.data.tcPersonaCrea,
                                  ttGestion: {
                                    gestionId: tmp.gestionId,
                                    tcTipoGestion: tmp.tcTipoGestion,
                                    estadoId: tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcTipoBosque: tmp.tcTipoBosque,
                                    tcElaborador: tmp.tcElaborador,
                                    ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                    personaAnulaId: $scope.loginData.personaId
                                  },
                                  representanteLegal: 0,
                                  soloRepresenta: 0
                                }
                                $scope.agregarPersona(data, 0);
                              }
                            } else {
                              if ($scope.data.representantes != null) {
                                personas = $scope.data.personas;
                              }
                              for (var i = 0; i < personas.length; i++) {
                                if (personas[i].tcPersona.personaId == $scope.data.tcPersonaCrea.personaId) {
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                var tmp = $scope.data;
                                var data = {
                                  personaGestionId: 0,
                                  tcPersona: $scope.data.tcPersonaCrea,
                                  ttGestion: {
                                    gestionId: tmp.gestionId,
                                    tcTipoGestion: tmp.tcTipoGestion,
                                    estadoId: tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcTipoBosque: tmp.tcTipoBosque,
                                    tcElaborador: tmp.tcElaborador,
                                    ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                    personaAnulaId: $scope.loginData.personaId
                                  },
                                  representanteLegal: 1,
                                  soloRepresenta: 1
                                }
                                $scope.agregarPersona(data, 1);
                              }
                            }
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /*fin panel propietario*/

                    /*panel solicitante*/

                    $scope.agregarRepresentante = function () {
                      var tmpItem = {
                        gestionId: $scope.data.gestionId,
                        nombreCompleto: null,
                        cui: null,
                        disabled: 0
                      }
                      $scope.data.representante.push(tmpItem);
                      $scope.panel.aplicaRepresentante = 1;
                      $scope.aplicaRepresentanteTexto = "SI";
                      $scope.panel.classGrupal = 'btn btn-danger btn-xs';
                    }

                    $scope.setRepresentante = function (item) {
                      var data = item;
                      data.representanteLegal = 1;
                      var tmp = $scope.data;
                      data.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      gestionService.agregarPersona(data).then(function (response) {
                        if (response.status == 'OK') {
                          registro = response.data[0];
                          persona = registro.tcPersona;
                          persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                          var encontrado = false;
                          for (var i = 0; i < $scope.data.representantes.length; i++) {
                            if ($scope.data.personas[i].representantes.personaId == persona.personaId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            $scope.data.representantes.push(registro);
                          }
                          toastr.success(response.message);
                          ngDialog.close();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.borrarRepresentante = function (item) {
                      $scope.data.representante.splice($scope.data.representante.indexOf(item), 1);
                      if ($scope.data.representante.length <= 0) {
                        $scope.panel.aplicaRepresentante = 0;
                        $scope.aplicaRepresentanteTexto = "NO";
                        $scope.panel.classGrupal = 'btn btn-default btn-xs';
                      }
                    }

                    $scope.borrarDetalle = function (item, tipo) {
                      if (tipo != 1) {
                        if ($scope.validarPropietarioFinca(item.tcPersona.personaId)) {
                          toastr.error('No es posible quitar a la persona debido a que ya se encuentra como propietario de una finca, debe primero quitarlo');
                          return;
                        }
                      }
                      var data = item;
                      var tmp = $scope.data;
                      data.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      data.representanteLegal = tipo;
                      gestionService.quitarPersona(data).then(function (response) {
                        if (response.status == 'OK') {
                          var upd = response.data[0];
                          if (tipo == 0) {
                            $scope.data.personas.splice($scope.data.personas.indexOf(item), 1);
                          } else {
                            $scope.data.representantes.splice($scope.data.representantes.indexOf(item), 1);
                            if ($scope.data.representantes.length <= 0) {
                              $scope.panel.aplicaRepresentante = 0;
                            }
                          }
                          if (tipo == 1) { //se esta quitando como representante
                            item.representanteLegal = 0;
                            item.soloRepresenta = 0;
                            var conteo = 0;
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].representanteLegal == 1) {
                                conteo++;
                                break;
                              }
                            }
                            if (conteo == 0) {
                              $scope.panel.aplicaRepresentante = 0;
                              $scope.aplicaRepresentanteTexto = "NO";
                              $scope.panel.classGrupal = 'btn btn-default btn-xs';
                            }
                          }
                          toastr.success(response.message);
                          $scope.setPropietarios();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });

                    }

                    $scope.buscarPersona = function (tipo) {
                      $scope.tipoPersona = tipo;
                      var continuar = true;
                      if (continuar) {
                        $scope.dataTipoBusqueda = [
                          { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                          { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                          { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                        ]
                        $scope.busqueda = {
                          tipoBusquedaId: 3,
                          tituloBusqueda: 'Ingrese el nombre de la persona',
                          placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                        }

                        ngDialog.open({
                          template: 'app/gestion/buscar.persona.tpl.html',
                          className: 'ngdialog-theme-flat',
                          appendClassName: 'ngdialog-flat',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueEdit',
                          name: 'dialogEditar',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr',
                            function ($scope, $state, toastr) {

                              $scope.panel.editarPersona = false;

                              $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                              $scope.gridPersona.columnDefs = [
                                { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                                { field: 'cui', name: 'cui', displayName: 'Identificación' },
                                { field: 'correo', name: 'correo', displayName: 'Correo' },
                                { field: 'nit', name: 'nit', displayName: 'NIT' },
                                {
                                  name: 'Seleccionar', enableFiltering: false,
                                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                    + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                                }
                              ];
                              $scope.gridPersona.data = [];

                              $scope.setTipoBusqueda = function (item) {
                                switch (parseInt(item.tipoBusquedaId)) {
                                  case 1:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                    $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                                    break;
                                  case 2:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                                    $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                                    break;
                                  case 3:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                                    $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                                    break;
                                  default:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                }
                              }

                              $scope.keyupFiltro = function (event) {
                                if (event.keyCode == 13) {
                                  $scope.submitBuscarPersona();
                                }
                              }

                              $scope.submitBuscarPersona = function () {
                                var isValid = true;
                                if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                                  isValid = null;
                                  toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                                }
                                if (isValid) {
                                  personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                                    if (response.status == 'OK') {
                                      toastr.success(response.message);
                                      $scope.gridPersona.data = response.data;
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              }

                              $scope.setPersona = function (item) {
                                var soloRepresenta = 1;
                                if ($scope.tipoPersona == 0) {
                                  for (var i = 0; i < $scope.data.personas.length; i++) {
                                    if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                      ngDialog.close();
                                      return;
                                    }
                                  }
                                  soloRepresenta = 0;
                                } else {
                                  for (var i = 0; i < $scope.data.representantes.length; i++) {
                                    if ($scope.data.representantes[i].tcPersona.personaId == item.personaId) {
                                      ngDialog.close();
                                      return;
                                    }
                                  }
                                  for (var i = 0; i < $scope.data.personas.length; i++) {
                                    if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                      soloRepresenta = 0;
                                      break;
                                    }
                                  }
                                }
                                if (item.tcCultura == null || item.tcIdioma == null || item.tcEstadoCivil == null || item.tcSexo == null) {
                                  $scope.panel.editarPersona = true;
                                  $scope.persona = item;
                                  toastr.error('Es necesario completar información de la persona seleccionada, hágalo en la parte inferior de esta pantalla');
                                } else {
                                  var tmp = $scope.data;
                                  var data = {
                                    personaGestionId: 0,
                                    tcPersona: item,
                                    ttGestion: {
                                      gestionId: tmp.gestionId,
                                      tcTipoGestion: tmp.tcTipoGestion,
                                      estadoId: tmp.estadoId,
                                      tcPersonaCrea: tmp.tcPersonaCrea,
                                      tcTipoBosque: tmp.tcTipoBosque,
                                      tcElaborador: tmp.tcElaborador,
                                      ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                      personaAnulaId: $scope.loginData.personaId
                                    },
                                    representanteLegal: $scope.tipoPersona,
                                    soloRepresenta: soloRepresenta
                                  }
                                  gestionService.agregarPersona(data).then(function (response) {
                                    if (response.status == 'OK') {
                                      registro = response.data[0];
                                      persona = registro.tcPersona;
                                      persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                      var encontrado = false;
                                      if ($scope.tipoPersona == 0) {
                                        for (var i = 0; i < $scope.data.personas.length; i++) {
                                          if ($scope.data.personas[i].tcPersona.personaId == persona.personaId) {
                                            break;
                                          }
                                        }
                                        if (!encontrado) {
                                          $scope.data.personas.push(registro);
                                        }
                                        $scope.setPropietarios();
                                      } else {
                                        for (var i = 0; i < $scope.data.representantes.length; i++) {
                                          if ($scope.data.representantes[i].tcPersona.personaId == persona.personaId) {
                                            break;
                                          }
                                        }
                                        if (!encontrado) {
                                          $scope.data.representantes.push(registro);
                                        }
                                        $scope.panel.aplicaRepresentante = 1;
                                        $scope.aplicaRepresentanteTexto = "SI";
                                        $scope.panel.classGrupal = 'btn btn-danger btn-xs';
                                      }
                                      toastr.success(response.message);
                                      ngDialog.close();
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              }

                              $scope.setIdioma = function (item) {

                              }

                              $scope.setSexo = function (item) {

                              }

                              $scope.setEstadoCivil = function (item) {

                              }

                              $scope.setCultura = function (item) {

                              }

                              $scope.submitEditarPersona = function (isValid) {
                                if (isValid) {
                                  var persona = $scope.persona;
                                  persona.personaDesc = $scope.capitalizeText(persona.personaDesc);
                                  if (persona.direccion != null) {
                                    persona.direccion = $scope.capitalizeText(persona.direccion);
                                  }
                                  personaService.edit(persona).then(function (response) {
                                    if (response.status == 'OK') {
                                      var tmp = $scope.data;
                                      var data = {
                                        personaGestionId: 0,
                                        tcPersona: persona,
                                        ttGestion: {
                                          gestionId: tmp.gestionId,
                                          tcTipoGestion: tmp.tcTipoGestion,
                                          estadoId: tmp.estadoId,
                                          tcPersonaCrea: tmp.tcPersonaCrea,
                                          tcTipoBosque: tmp.tcTipoBosque,
                                          tcElaborador: tmp.tcElaborador,
                                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                          personaAnulaId: $scope.loginData.personaId
                                        },
                                        representanteLegal: 0,
                                        soloRepresenta: 0
                                      }
                                      gestionService.agregarPersona(data).then(function (response) {
                                        if (response.status == 'OK') {
                                          registro = response.data[0];
                                          persona = registro.tcPersona;
                                          persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                          var encontrado = false;
                                          if ($scope.tipoPersona == 0) {
                                            for (var i = 0; i < $scope.data.personas.length; i++) {
                                              if ($scope.data.personas[i].tcPersona.personaId == persona.personaId) {
                                                break;
                                              }
                                            }
                                            if (!encontrado) {
                                              $scope.data.personas.push(registro);
                                            }
                                            $scope.setPropietarios();
                                          } else {
                                            for (var i = 0; i < $scope.data.representantes.length; i++) {
                                              if ($scope.data.representantes[i].tcPersona.personaId == persona.personaId) {
                                                break;
                                              }
                                            }
                                            if (!encontrado) {
                                              $scope.data.representantes.push(registro);
                                            }
                                            $scope.aplicaRepresentante = 1;
                                          }
                                          toastr.success(response.message);
                                          ngDialog.close();
                                        } else {
                                          toastr.error(response.message);
                                        }
                                      }, function (error) {
                                        toastr.error(error);
                                      });
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              }

                            }]
                        })
                      }
                    }

                    $scope.editarInformacionPersona = function (personaGestion) {
                      var persona = angular.copy(personaGestion.tcPersona);
                      $scope.tituloPersona = "Editar datos de la persona";
                      delete $scope.persona;
                      $scope.persona = persona;
                      $scope.persona.fechaNacimiento = new Date($scope.persona.fechaNacimiento);
                      ngDialog.open({
                        template: 'app/persona/persona.add.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                          dataOcupacion: ['ocupacionService',
                            function (ocupacionService) {
                              return ocupacionService.listaActiva();
                            }],
                          dataMunicipio: ['municipioService',
                            function (municipioService) {
                              return municipioService.listaPorDepartamento(persona.tcMunicipio.tcDepartamento.departamentoId);
                            }]
                        },
                        controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload', 'dataOcupacion', 'dataMunicipio',
                          function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload, dataOcupacion, dataMunicipio) {
                            $scope.dataOcupacion = dataOcupacion.data;
                            $scope.dataMunicipio = dataMunicipio.data;
                            $scope.direccion = {
                              paisId: 1,
                              departamentoId: persona.tcMunicipio.tcDepartamento.departamentoId
                            }

                            $scope.cargarListaDepartamento = function (item) {
                              $scope.direccion.departamentoId = null;
                              $scope.persona.municipioId = null;
                              departamentoService.listaPorPais(item.paisId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataDepartamento = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.cargarListaMunicipio = function (item) {
                              $scope.persona.municipioId = null;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipio = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.onFileSelect = function (file, tipoAnexoId) {
                              Upload.upload({
                                url: appSettings.urlServiceBase + 'file/base64/imagen',
                                method: 'POST',
                                file: file,
                                sendFieldsAs: 'form',
                                fields: {
                                  data: []
                                }
                              }).then(function (resp) {
                                var data = resp.data;
                                if (data.status == 'OK') {
                                  var obj = data.data[0];
                                  $scope.image = obj.data;
                                  $scope.persona.foto = obj.data;
                                } else {
                                  toastr.error(resp.data.message)
                                }
                              }, function (resp) {
                                toastr.error('Error status: ' + resp.status);
                              }, function (evt) {
                                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                $scope.avance = progressPercentage;
                              });
                            }

                            $scope.submitFormPersona = function (isValid) {
                              if (isValid) {
                                if ($scope.persona.foto == null) {
                                  $scope.validoFoto = 1;
                                }
                                if ($scope.validoFoto == 0 && isValid) {
                                  isValid = false;
                                  toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                                }
                              }
                              if (isValid) {
                                var item = $scope.persona;
                                personaService.edit(item).then(function (res) {
                                  if (res.status == 'OK') {
                                    personaGestion.tcPersona = item;
                                    ngDialog.close();
                                    toastr.success(res.message);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }

                            $scope.setIdioma = function (item) {
                              $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                            }

                            $scope.setSexo = function (item) {
                              $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                            }

                            $scope.setEstadoCivil = function (item) {
                              $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                            }

                            $scope.setCultura = function (item) {
                              $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                            }

                          }]
                      })
                    }

                    $scope.validarPanelSolicitante = function () {
                      var isValid = false;
                      var esJuridica = false;
                      if ($scope.data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                        esJuridica = true;
                      }
                      if (esJuridica) {
                        for (var i = 0; i < $scope.data.representantes.length; i++) {
                          if ($scope.data.tcPersonaCrea.personaId == $scope.data.representantes[i].tcPersona.personaId) {
                            isValid = true;
                            break;
                          }
                        }
                        if (!isValid) {
                          toastr.error("El usuario solicitante (" + $scope.data.tcPersonaCrea.personaDesc + ") debe estar involucrado en la gestión como representante. Favor de verificar");
                        }
                      } else {
                        for (var i = 0; i < $scope.data.personas.length; i++) {
                          if ($scope.data.tcPersonaCrea.personaId == $scope.data.personas[i].tcPersona.personaId) {
                            isValid = true;
                            break;
                          }
                        }
                        if (!isValid && $scope.data.personas.length > 0) {
                          for (var i = 0; i < $scope.data.representantes.length; i++) {
                            if ($scope.data.tcPersonaCrea.personaId == $scope.data.representantes[i].tcPersona.personaId) {
                              isValid = true;
                              break;
                            }
                          }
                        }
                        if (!isValid) {
                          toastr.error("El usuario solicitante (" + $scope.data.tcPersonaCrea.personaDesc + ") debe estar involucrado en la gestión. Favor de verificar");
                        }
                      }
                      if (isValid) {
                        if ($scope.panel.aplicaRepresentante == 1) {
                          var item = $scope.data.ttRepresentanteGestion;
                          if (item.tcTipoRepresentacion.tipoRepresentacionId == null) {
                            isValid = false;
                          }
                          if (item.numeroEscritura == null || item.numeroEscritura.length <= 0) {
                            isValid = false;
                          }
                          if (item.fecha == null || item.fecha.length <= 0) {
                            item = false;
                          }
                          if (item.numero == null || item.numero.length <= 0) {
                            isValid = false;
                          }
                          if (item.folio == null || item.folio <= 0) {
                            isValid = false;
                          }
                          if (item.libro == null || item.numero.libro <= 0) {
                            isValid = false;
                          }
                          if (item.tcMunicipio.municipioId == null) {
                            isValid = false;
                          }
                          if ((item.notario == null || item.notario <= 0) && item.tcTipoRepresentacion.tipoRepresentacionId != 6) {
                            isValid = false;
                          }
                          if (!isValid) {
                            toastr.error("Debe agregar los datos de representación");
                          }
                          if (isValid) {
                            var tmp = $scope.data;
                            item.ttGestion = {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              personaAnulaId: $scope.loginData.personaId,
                              tieneRepresentante: $scope.panel.aplicaRepresentante
                            };
                            gestionService.setRepresentanteLegal(item).then(function (res) {
                              if (res.status == "OK") {
                                $scope.data.ttRepresentanteGestion = res.data[0];
                                $scope.data.ttRepresentanteGestion.fecha = new Date($scope.data.ttRepresentanteGestion.fecha);
                                toastr.success(res.message);
                                $scope.panel.openNotificacion = true;
                                $scope.panel.notificacion = false;
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        } else {
                          $scope.panel.openNotificacion = true;
                          $scope.panel.notificacion = false;
                        }
                      }
                    }

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                    }

                    /*
                    tipo = 0, persona involucrada
                    tipo = 1, representante legal
                    */
                    $scope.agregarRegistroPersona = function (tipo) {
                      $scope.tipoPersona = tipo;
                      $scope.tituloPersona = "Agregar datos de la persona";
                      delete $scope.persona;
                      ngDialog.open({
                        template: 'app/persona/persona.add.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                          dataOcupacion: ['ocupacionService',
                            function (ocupacionService) {
                              return ocupacionService.listaActiva();
                            }]
                        },
                        controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload', 'dataOcupacion',
                          function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload, dataOcupacion) {
                            $scope.dataOcupacion = dataOcupacion.data;
                            $scope.persona = {
                              estadoId: 1
                            }
                            $scope.direccion = {
                              paisId: 1
                            }

                            $scope.cargarListaDepartamento = function (item) {
                              $scope.direccion.departamentoId = null;
                              $scope.persona.municipioId = null;
                              departamentoService.listaPorPais(item.paisId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataDepartamento = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.cargarListaMunicipio = function (item) {
                              $scope.persona.municipioId = null;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipio = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.onFileSelect = function (file, tipoAnexoId) {
                              Upload.upload({
                                url: appSettings.urlServiceBase + 'file/base64/imagen',
                                method: 'POST',
                                file: file,
                                sendFieldsAs: 'form',
                                fields: {
                                  data: []
                                }
                              }).then(function (resp) {
                                var data = resp.data;
                                if (data.status == 'OK') {
                                  var obj = data.data[0];
                                  $scope.image = obj.data;
                                  $scope.persona.foto = obj.data;
                                } else {
                                  toastr.error(resp.data.message)
                                }
                              }, function (resp) {
                                toastr.error('Error status: ' + resp.status);
                              }, function (evt) {
                                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                $scope.avance = progressPercentage;
                              });
                            }

                            $scope.submitFormPersona = function (isValid) {
                              if (isValid) {
                                if ($scope.persona.foto == null) {
                                  /*isValid = false;
                                  toastr.error('Debe indicar una foto');*/
                                  $scope.validoFoto = 1;
                                }
                                if ($scope.validoFoto == 0 && isValid) {
                                  isValid = false;
                                  toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                                }
                              }
                              if (isValid) {
                                var item = $scope.persona;
                                personaService.add(item).then(function (res) {
                                  if (res.status == 'OK') {
                                    item = res.data[0];
                                    var soloRepresenta = 1;
                                    if ($scope.tipoPersona == 0) {
                                      soloRepresenta = 0;
                                    } else {
                                      for (var i = 0; i < $scope.data.personas.length; i++) {
                                        if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                          soloRepresenta = 0;
                                          break;
                                        }
                                      }
                                    }
                                    var tmp = $scope.data;
                                    var data = {
                                      personaGestionId: 0,
                                      tcPersona: item,
                                      ttGestion: {
                                        gestionId: tmp.gestionId,
                                        tcTipoGestion: tmp.tcTipoGestion,
                                        estadoId: tmp.estadoId,
                                        tcPersonaCrea: tmp.tcPersonaCrea,
                                        tcTipoBosque: tmp.tcTipoBosque,
                                        tcElaborador: tmp.tcElaborador,
                                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                        personaAnulaId: $scope.loginData.personaId
                                      },
                                      representanteLegal: $scope.tipoPersona,
                                      soloRepresenta: soloRepresenta
                                    }
                                    gestionService.agregarPersona(data).then(function (response) {
                                      if (response.status == 'OK') {
                                        registro = response.data[0];
                                        persona = registro.tcPersona;
                                        registro.tcPersona = item;
                                        if (parseInt($scope.tipoPersona) == 1) {
                                          $scope.data.representantes.push(registro);
                                        } else {
                                          item.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                          $scope.data.personas.push(registro);
                                          $scope.setPropietarios();
                                        }
                                        toastr.success(res.message);
                                      } else {
                                        toastr.error(response.message);
                                      }
                                      ngDialog.close();
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }

                            $scope.setIdioma = function (item) {
                              $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                            }

                            $scope.setSexo = function (item) {
                              $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                            }

                            $scope.setEstadoCivil = function (item) {
                              $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                            }

                            $scope.setCultura = function (item) {
                              $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                            }

                          }]
                      })
                    }

                    /*fin panel solicitante*/

                    /*Panel notificacion*/
                    $scope.agregarNotificacion = function () {
                      ngDialog.open({
                        template: 'app/gestion/gestion.notificacion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {

                        },
                        controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                          function ($scope, $state, toastr, departamentoService, municipioService) {

                            $scope.direccion = {
                              paisId: 1
                            }

                            var tmp = $scope.data;
                            $scope.notificacion = {
                              ttGestion: {
                                gestionId: tmp.gestionId,
                                tcTipoGestion: tmp.tcTipoGestion,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: tmp.tcPersonaCrea,
                                tcTipoBosque: tmp.tcTipoBosque,
                                tcElaborador: tmp.tcElaborador,
                                personaAnulaId: $scope.loginData.personaId,
                                tieneRepresentante: $scope.panel.aplicaRepresentante
                              },
                              tcTipoNotificacion: null
                            }

                            $scope.cargarListaDepartamento = function (item) {
                              $scope.direccion.departamentoId = null;
                              $scope.notificacion.municipioId = null;
                              $scope.notificacion.paisDesc = item.paisDesc;
                              departamentoService.listaPorPais(item.paisId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataDepartamento = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.cargarListaMunicipio = function (item) {
                              $scope.notificacion.municipioId = null;
                              $scope.notificacion.departamentoDesc = item.departamentoDesc;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipio = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.setMunicipio = function (item) {
                              $scope.notificacion.tcMunicipio = item;
                            }

                            $scope.setTipoNotificacion = function (item) {
                              $scope.notificacion.tcTipoNotificacion = item;
                            }

                            $scope.submitFormNotificacionAdicional = function (isValid) {
                              if (isValid) {
                                if ($scope.data.notificaciones == undefined) {
                                  $scope.data.notificaciones = [];
                                }
                                var data = $scope.notificacion;
                                gestionService.agregarNotificacion(data).then(function (response) {
                                  if (response.status == 'OK') {
                                    $scope.data.notificaciones.push($scope.notificacion);
                                    toastr.success(response.message);
                                    ngDialog.close();
                                  } else {
                                    toastr.error(response.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }

                          }]
                      })
                    }

                    $scope.setMunicipioNotificacion = function (item, notificacion) {
                      notificacion.tcMunicipio = item;
                    }

                    $scope.capturarInformacionNotificacion = function (notificacion) {

                    }

                    $scope.agregarMunicipioNotificacion = function (notificacion) {
                      notificacion.tcMunicipio = {
                        municipioId: null
                      }
                      $scope.notificacion = notificacion;
                      ngDialog.open({
                        template: 'app/gestion/seleccionar.municipio.tpl.html',
                        className: 'ngdialog-theme-default',
                        appendClassName: 'ngdialog-default',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueMunicipio',
                        name: 'dialogMunicipio',
                        resolve: {

                        },
                        controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                          function ($scope, $state, toastr, departamentoService, municipioService) {

                            $scope.direccion = {
                              paisId: 1
                            }

                            $scope.cargarListaDepartamento = function (item) {
                              $scope.direccion.departamentoId = null;
                              $scope.notificacion.tcMunicipio.municipioId = null;
                              departamentoService.listaPorPais(item.paisId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataDepartamento = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.cargarListaMunicipio = function (item) {
                              $scope.notificacion.tcMunicipio.municipioId = null;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipio = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.setMunicipio = function (item) {
                              $scope.notificacion.tcMunicipio = item;
                            }

                            $scope.submitFormNotificacionMunicipio = function (isValid) {
                              if (isValid) {
                                for (var i = 0; i < $scope.data.notificaciones.length; i++) {
                                  if ($scope.data.notificaciones[i].notificacionGestionId == $scope.notificacion.notificacionGestionId) {
                                    $scope.data.notificaciones[i] = $scope.notificacion;
                                    break;
                                  }
                                }
                                ngDialog.close();
                              }
                            }

                          }]
                      })
                    }

                    $scope.mostrarSeleccionarMunicipio = function (value) {
                      if (value == 1) {
                        return true;
                      }
                      return false;
                    }

                    $scope.validarPanelNotificacion = function (isValid) {
                      if (isValid) {
                        for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                          if ($scope.data.notificaciones[j].tcTipoNotificacion.esObligatorio == 1) {
                            if ($scope.data.notificaciones[j].tcTipoNotificacion.esDireccion == 1 && $scope.data.notificaciones[j].tcMunicipio == null) {
                              isValid = false;
                              encontrado = false; //para que finalice la verificacion y muestre el mensaje de error
                            }
                            break;
                          }
                        }
                        if (!isValid) {
                          toastr.error("Se debe indicar todas las formas de notificación y seleccionar el municipio a las direcciones");
                        } else {
                          //enviar datos para actualizar o guardar
                          gestionService.actualizarDatosNotificacion($scope.data.notificaciones).then(function (response) {
                            if (response.status == 'OK') {
                              $scope.panel.finca = false;
                              $scope.panel.openFinca = true;
                              toastr.success("Continue seleccionando o agregando la finca");
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      } else {
                        toastr.error("Debe rellenar todos los campos y verificar que el formato (teléfono, correo, etc.) sea el correcto");
                      }
                    }

                    $scope.borrarDetalleNotificacion = function (item) {
                      var notificaciones = $scope.data.notificaciones;
                      var continuar = true;
                      if (item.tcTipoNotificacion.esObligatorio == 1) {
                        var conteo = 0;
                        for (var i = 0; i < notificaciones.length; i++) {
                          if (notificaciones[i].tcTipoNotificacion.tipoNotificacionId == item.tcTipoNotificacion.tipoNotificacionId) {
                            conteo++;
                          }
                        }
                        if (conteo < 2) {
                          continuar = false;
                          toastr.error('Este tipo de notificación es obligatorio, no es posible continuar');
                        }
                      }
                      if (continuar) {
                        var tmp = $scope.data;
                        item.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          personaAnulaId: $scope.loginData.personaId,
                          tieneRepresentante: $scope.panel.aplicaRepresentante
                        };
                        gestionService.quitarNotificacion(item).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.data.notificaciones.splice($scope.data.notificaciones.indexOf(item), 1);
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /*Fin panel notificacion*/

                    /*Panel finca*/
                    var minDate = new Date();
                    minDate.setDate(minDate.getDate() - 120);
                    var maxDate = new Date();

                    $scope.dateOptionsFinca = {
                      formatYear: 'yy',
                      startingDay: 0,
                      format: 'dd/MM/yyyy',
                      formatDateTime: 'dd/MM/yyyy HH:mm',
                      showMeridian: false,
                      minDate: null,
                      maxDate: maxDate
                    };

                    $scope.limpiarDatosFinca = function (finca) {
                      finca.tcTipoPropiedad = {
                        tipoPropiedadId: null
                      };
                      finca.mostrarNumero = false;
                      finca.mostrarNotario = false;
                    }

                    $scope.setTipoPropiedadFinca = function (item, finca) {
                      if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 3) {
                        finca.mostrarNumero = true;
                      } else {
                        finca.mostrarNumero = false;
                      }
                      if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 2) {
                        finca.mostrarNotario = true;
                      } else {
                        finca.mostrarNotario = false;
                      }
                      finca.tcTipoPropiedad.tipoPropiedadDesc = item.tipoPropiedadDesc;
                    }

                    $scope.getMostrarNumero = function (finca) {
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                          return true;
                        }
                      }
                      return false;
                    }

                    $scope.getNumeroRequerido = function (finca) {
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                          return true;
                        }
                      }
                      return false;
                    }

                    $scope.getMunicipioRequerido = function (finca) {
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                          return true;
                        }
                      }
                      return false;
                    }

                    $scope.getMostrarNotario = function (finca) {
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                          return true;
                        }
                      }
                      finca.libro = 0;
                      return false;
                    }

                    $scope.getTituloDocumento = function (finca) {
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1) {
                          return "Número de escritura";
                        }
                      }
                      return "No. de finca";
                    }

                    $scope.agregarRegistroFinca = function () {
                      $scope.guardarFinca = false;
                      $scope.setStateToGo("index.plan.realizar", $base64.encode($scope.data.gestionId));
                      $state.go('index.finca.add');
                    }

                    $scope.quitarFinca = function (item) {
                      //verificar si aun no se ha hecho una distribucion del uso actual de la finca
                      if ($scope.data.uso != undefined) {
                        if ($scope.data.uso != null) {
                          if ($scope.data.uso.length > 0) {
                            var usos = $scope.data.uso;
                            var totalUso = 0;
                            for (var i = 0; i < usos.length; i++) {
                              totalUso += usos[i].area;
                            }
                            var fincas = $scope.data.fincas;
                            var totalFinca = 0;
                            for (var i = 0; i < fincas.length; i++) {
                              totalFinca += fincas[i].tcFinca.area;
                            }
                            var resto = totalFinca - totalUso;
                            if (resto != item.tcFinca.area) {
                              toastr.error('No es posible eliminar la finca debido a que ya se encuentra distribuido en algún uso actual. Debe eliminar los usos actuales en la pestaña "Características de la finca"');
                              return;
                            }
                          }
                        }
                      }
                      item.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      }
                      gestionService.quitarFinca(item).then(function (res) {
                        if (res.status == "OK") {
                          $scope.data.fincas.splice($scope.data.fincas.indexOf(item), 1);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.agregarDocumentoFinca = function (finca) {
                      if (finca.documentos == null) {
                        finca.documentos = [];
                      }
                      finca.documentos.push({ documentoFincaId: 0 });
                    }

                    $scope.quitarDocumentoFinca = function (finca, item) {
                      if (item.documentoFincaId > 0) {
                        gestionService.quitarDocumentoFinca(item).then(function (res) {
                          if (res.status == "OK") {
                            finca.documentos.splice(finca.documentos.indexOf(item), 1);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        finca.documentos.splice(finca.documentos.indexOf(item), 1);
                      }
                    }

                    $scope.buscarFinca = function () {
                      ngDialog.open({
                        template: 'app/finca/finca.list.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {

                        },
                        controller: ['$scope', '$state', 'toastr', 'fincaService',
                          function ($scope, $state, toastr, fincaService) {
                            $scope.gridOptionsFinca = angular.copy($scope.gridOptionsSelection);
                            $scope.gridOptionsFinca.columnDefs = [
                              { field: 'fincaDesc', name: 'fincaDesc', displayName: 'Finca' },
                              { field: 'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '17%' },
                              { field: 'tcMunicipio.tcDepartamento.departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '12%' },
                              { field: 'direccion', name: 'direccion', displayName: 'Aldea/caserío/cantón' },
                              { field: 'gtmX', name: 'gtmX', displayName: 'GTM X', width: '8%' },
                              { field: 'gtmY', name: 'gtmY', displayName: 'GTM Y', width: '8%' },
                              { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                              {
                                name: 'Opciones', enableFiltering: false, width: '8%',
                                cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                  + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.selectRowFinca(row.entity)" title="Seleccionar"> <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </button></span></div>'
                              }
                            ];
                            $scope.gridOptionsFinca.data = $scope.dataFinca;

                            $scope.direccion = {
                              departamentoId: null,
                              municipioId: null
                            }

                            $scope.cargarListaMunicipioFinca = function (item) {
                              $scope.direccion.municipioId = null;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipioFinca = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.setMunicipioFinca = function (item) {
                              if (item) {
                                fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                                  if (res.status == "OK") {
                                    $scope.gridOptionsFinca.data = res.data;
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }

                          }]
                      })
                    }

                    $scope.onSelectPersona = function (item, finca) {
                      if (finca.propietarios == undefined || finca.propietarios == null) {
                        finca.propietarios = [];
                      }
                      if ($scope.data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                        if (finca.propietarios.length == 0) {
                          finca.propietarios.push(
                            {
                              propietarioFincaId: 1,
                              tcPersona: {
                                personaId: 1,
                                personaDesc: $scope.data.ttTipoPropietarioGestion.razonSocial
                              }
                            }
                          );
                        }
                        toastr.success('Propietario agregado');
                        return;
                      } else {
                        for (var i = 0; i < finca.propietarios.length; i++) {
                          if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                            toastr.success('Propietario agregado');
                            return;
                          }
                        }
                        finca.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        var data = {
                          ttFincaGestion: finca,
                          tcPersona: item
                        }
                        gestionService.agregarPropietarioFinca(data).then(function (response) {
                          if (response.status == 'OK') {
                            finca.propietarios.push(response.data[0]);
                          } else {
                            toastr.error(response.message);
                            finca.persona = finca.propietario;
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.onRemovePersona = function (item, finca) {
                      if ($scope.data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                        finca.propietarios.splice(finca.propietarios.indexOf(item), 1);
                      } else {
                        var tmp = $scope.data;
                        finca.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        var data = null;
                        for (var i = 0; i < finca.propietarios.length; i++) {
                          if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                            data = angular.copy(finca.propietarios[i]);
                            break;
                          }
                        }
                        data.ttFincaGestion = angular.copy(finca);
                        var tmpPersonas = data.personas;
                        delete data.personas;
                        delete data.propietarios;
                        gestionService.quitarPropietarioFinca(data).then(function (response) {
                          if (response.status == 'OK') {
                            finca.propietarios.splice(finca.propietarios.indexOf(data), 1);
                          } else {
                            toastr.error(response.message);
                            finca.personas = tmpPersonas;
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.selectRowFinca = function (row) {
                      var encontrado = false;
                      for (var i = 0; i < $scope.data.fincas.length; i++) {
                        if ($scope.data.fincas[i].tcFinca.fincaId == row.fincaId) {
                          encontrado = true;
                          break;
                        }
                        if ($scope.data.fincas[i].tcFinca.tcMunicipio.municipioId != row.tcMunicipio.municipioId) {
                          toastr.error('La finca seleccionada pertenece a otro municipio, no es posible continuar');
                          return;
                        }
                      }
                      if (!encontrado) {

                        var item = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          tcFinca: row
                        };
                        gestionService.agregarFincaGestion(item).then(function (response) {
                          if (response.status == 'OK') {
                            item = response.data[0];
                            item.ttGestion = {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            };
                            item.fechaEmision = new Date();
                            $scope.data.fincas.push(item);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                      ngDialog.close();
                    }

                    $scope.validarPanelFinca = function (isValid) {
                      if ($scope.data.fincas.length <= 0) {
                        toastr.error('Debe agregar al menos una finca para continuar');
                        return;
                      }
                      if (!isValid) {
                        toastr.error('Debe indicar todos los campos obligatorios, favor de verificar');
                      } else {
                        var dataFinca = angular.copy($scope.data.fincas);
                        var minimum = new Date();
                        if ($scope.dataSolicitud != undefined) {
                          minimum = new Date($scope.dataSolicitud.fechaRecepcion);
                        }
                        var tmp = $scope.data;
                        minimum.setDate(minimum.getDate() - 121);
                        for (var i = 0; i < dataFinca.length; i++) {
                          if (dataFinca[i].tcTipoPropiedad.tipoPropiedadId == 3) {
                            date = new Date(dataFinca[i].fechaEmision);
                            if (minimum > date) {
                              toastr.error('La emisión del documento de la finca ' + dataFinca[i].tcFinca.fincaDesc + ' con documento ' + dataFinca[i].tcTipoPropiedad.tipoPropiedadDesc + ' sobrepasa los 120 días, no es posible continuar');
                              return;
                            }
                          }
                          if (dataFinca[i].propietarios.length <= 0) {
                            toastr.error('Debe indicar al menos un propietario a la finca ' + dataFinca[i].tcFinca.fincaDesc);
                            return;
                          }
                          dataFinca[i].ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          }
                          if (dataFinca[i].tcTipoPropiedad.tipoPropiedadId == 3) {
                            dataFinca[i].tcMunicipioEmite = null;
                          } else {
                            dataFinca[i].tcLibro = null;
                          }
                          delete dataFinca[i].dataMunicipio;
                          delete dataFinca[i].dataLibro;
                          delete dataFinca[i].personas;
                          delete dataFinca[i].propietarios;
                        }
                        gestionService.actualizarFincas(dataFinca).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.data.tcSubregion = response.data[0];
                            //activar tab en caso de no estarlo
                            //mover a tab biofisica
                            $scope.setTabs(2);
                            $state.go('index.plan.biofisica');
                          } else {
                            if (response.status == 'warning') {
                              $scope.dataSubregion = response.data;
                              //significa que debe indicar la subregion debido a que el municipio es atendido por varios
                              ngDialog.open({
                                template: 'app/gestion/finca.region.tpl.html',
                                className: 'ngdialog-theme-flat',
                                appendClassName: 'ngdialog-flat',
                                closeByDocument: false,
                                closeByEscape: true,
                                closeByNavigation: true,
                                scope: $scope,
                                id: 'uniqueRegion',
                                name: 'dialogRegion',
                                resolve: {

                                },
                                controller: ['$scope', '$state', 'toastr', 'gestionService',
                                  function ($scope, $state, toastr, gestionService) {

                                    $scope.seleccionarSubregion = function (item) {
                                      var tmp = $scope.data;
                                      var ttGestion = {
                                        gestionId: tmp.gestionId,
                                        tcTipoGestion: tmp.tcTipoGestion,
                                        estadoId: tmp.estadoId,
                                        tcPersonaCrea: tmp.tcPersonaCrea,
                                        tcTipoBosque: tmp.tcTipoBosque,
                                        tcElaborador: tmp.tcElaborador,
                                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                        personaAnulaId: $scope.loginData.personaId,
                                        tcSubregion: item
                                      }
                                      gestionService.setSubregion(ttGestion).then(function (response) {
                                        if (response.status == 'OK') {
                                          $scope.data.tcSubregion = item;
                                          //activar tab en caso de no estarlo
                                          //mover a tab biofisica
                                          $scope.setTabs(2);
                                          $state.go('index.plan.biofisica');
                                          ngDialog.close();
                                        } else {
                                          toastr.error(response.message);
                                        }
                                      }, function (error) {
                                        toastr.error(error);
                                      });
                                    }

                                  }]
                              })
                            } else {
                              toastr.error(response.message);
                            }
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /*Fin panel finca*/

                  }]
              }
            }
          })
          .state('index.plan.biofisica', {
            url: '/biofisica',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.biofisica.tpl.html',
                resolve: {
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', '$window',
                  function ($scope, $state, toastr, gestionService, $window) {
                    $window.scrollTo(0, 0);

                    $scope.submitFormBiofisica = function (isValid) {
                      if (isValid) {
                        var item = $scope.data.ttBiofisicaGestion;
                        var tmp = $scope.data;
                        item.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        gestionService.agregarBiofisica(item).then(function (response) {
                          if (response.status == 'OK') {
                            //activar el siguiente tab en caso de no estarlo
                            //mover a tab características de la finca
                            $scope.setTabs(3);
                            $state.go('index.plan.finca');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                  }]
              }
            }
          })
          .state('index.plan.finca', {
            url: '/finca',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.finca.tpl.html',
                resolve: {
                  dataUsoFinca: ['usofincaService',
                    function (usofincaService) {
                      return usofincaService.listaActiva();
                    }],
                  dataCriterioProteccion: ['criterioproteccionService',
                    function (criterioproteccionService) {
                      return criterioproteccionService.listaActiva();
                    }],
                  dataTipoBosque: ['tipobosqueService',
                    function (tipobosqueService) {
                      return tipobosqueService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataUsoFinca', 'dataCriterioProteccion', 'Upload', 'dataTipoBosque', '$window',
                  function ($scope, $state, toastr, gestionService, dataUsoFinca, dataCriterioProteccion, Upload, dataTipoBosque, $window) {

                    $window.scrollTo(0, 0);

                    $scope.dataTipoBosque = dataTipoBosque.data;

                    var areaFinca = 0;
                    for (var i = 0; i < $scope.data.fincas.length; i++) {
                      areaFinca += parseFloat($scope.data.fincas[i].tcFinca.area);
                    }
                    $scope.data.areaFinca = parseFloat(areaFinca).toFixed(4);

                    $scope.dataUsoFinca = dataUsoFinca.data;
                    $scope.dataCriterioProteccion = dataCriterioProteccion.data;
                    $scope.uso = {
                      usoFincaId: null,
                      area: null
                    };

                    $scope.ttUsoFincaGestion = {
                      estadoId: 1
                    };

                    $scope.criterio = {
                      criterioProteccionId: null,
                      area: null
                    };

                    if ($scope.data.ttDivisionAreaGestion == null) {
                      $scope.data.ttDivisionAreaGestion = {
                        fueraBosque: 0,
                        areaFuera: 0,
                        areaProteccionFuera: 0,
                        areaIntervenirFuera: 0
                      }
                    }

                    $scope.panel = {
                      resumen: false,
                      superficie: true,
                      tiempo: true,
                      propuesta: true,
                      openResumen: true,
                      openSuperficie: false,
                      openTiempo: false,
                      openPropuesta: false,
                      requeridoPropietario: false,
                      classGrupal: 'btn btn-default btn-md glyphicon glyphicon-unchecked',
                      actualizarAreas: false,
                      editarPersona: false,
                      conteoPersonas: 0,
                      restanteFinca: areaFinca,
                      restanteProteccion: 0,
                      tituloMapaFinca: "Clic para agregar mapa de finca",
                      tituloMapaProteccion: "Clic para agregar mapa"
                    }

                    $scope.enableMapaFinca = function () {
                      var enabled = false;
                      for (var i = 0; i < $scope.data.anexo.length; i++) {
                        if ($scope.data.anexo[i].tcTipoAnexo.tipoAnexoId == 2) {
                          enabled = true;
                          $scope.panel.tituloMapaFinca = "Clic para cambiar mapa de finca";
                          break;
                        }
                      }
                      return enabled;
                    }

                    $scope.enableMapaProteccion = function () {
                      var enabled = false;
                      for (var i = 0; i < $scope.data.anexo.length; i++) {
                        if ($scope.data.anexo[i].tcTipoAnexo.tipoAnexoId == 3) {
                          enabled = true;
                          $scope.panel.tituloMapaProteccion = "Clic para cambiar mapa";
                          break;
                        }
                      }
                      return enabled;
                    }

                    $scope.abrirAnexo = function (anexoGestionId) {
                      gestionService.getAnexoGestion(anexoGestionId);
                    }

                    $scope.verMapa = function (tipoAnexoId) {
                      var anexoGestionId = 0;
                      for (var i = 0; i < $scope.data.anexo.length; i++) {
                        if ($scope.data.anexo[i].tcTipoAnexo.tipoAnexoId == tipoAnexoId && $scope.data.anexo[i].estadoId == 1) {
                          anexoGestionId = $scope.data.anexo[i].anexoGestionId;
                          break;
                        }
                      }
                      if (anexoGestionId > 0) {
                        $scope.abrirAnexo(anexoGestionId);
                      }
                    }

                    $scope.onFileSelect = function (file, tipoAnexoId) {
                      Upload.upload({
                        url: appSettings.urlServiceBase + 'file/upload',
                        method: 'POST',
                        file: file,
                        sendFieldsAs: 'form',
                        fields: {
                          data: []
                        }
                      }).then(function (resp) {
                        var result = resp.data;
                        if (result.status == 'OK') {
                          var item = result.data[0];
                          //send to database
                          var item = {
                            tcTipoAnexo: {
                              tipoAnexoId: tipoAnexoId
                            },
                            ruta: item.rutaArchivo,
                            nombre: item.nombre,
                            size: item.size,
                            extension: item.extension
                          };
                          var tmp = $scope.data;
                          item.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          };
                          gestionService.agregarAnexo(item).then(function (response) {
                            if (response.status == 'OK') {
                              var found = false;
                              if (tipoAnexoId > 1) {
                                for (var i = 0; i < $scope.data.anexo.length; i++) {
                                  if ($scope.data.anexo[i].tcTipoAnexo.tipoAnexoId == tipoAnexoId && $scope.data.anexo[i].estadoId == 1) {
                                    $scope.data.anexo[i] = response.data[0];
                                    found = true;
                                    break;
                                  }
                                }
                              }
                              if (!found) {
                                $scope.data.anexo.push(response.data[0]);
                              }
                              toastr.success(response.message);
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        } else {
                          toastr.error(resp.data.message)
                        }
                      }, function (resp) {
                        toastr.error('Error status: ' + resp.status);
                      }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.avance = progressPercentage;
                      });
                    }

                    $scope.setTotalRestante = function () {
                      var total = 0;
                      for (var i = 0; i < $scope.data.uso.length; i++) {
                        total = parseFloat(total) + parseFloat($scope.data.uso[i].area);
                      }
                      total = parseFloat($scope.data.areaFinca) - total;
                      $scope.panel.restanteFinca = parseFloat(total).toFixed(4);
                    }

                    $scope.setTotalRestanteProteccion = function () {
                      if ($scope.data.criterio == null) {
                        $scope.data.criterio = [];
                      }
                      var total = 0;
                      for (var i = 0; i < $scope.data.criterio.length; i++) {
                        total = parseFloat(total) + parseFloat($scope.data.criterio[i].area);
                      }
                      var totalProteccion = parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionFuera) + parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionBosque);
                      total = totalProteccion - total;
                      $scope.panel.restanteProteccion = parseFloat(total).toFixed(4);
                    }

                    /*Validaciones iniciales*/
                    $scope.setTotalRestante();
                    if (parseFloat($scope.panel.restanteFinca) <= 0) {
                      $scope.panel.openSuperficie = true;
                      $scope.panel.superficie = false;
                    }
                    $scope.setTotalRestanteProteccion();
                    if (parseFloat($scope.panel.restanteProteccion) <= 0) {
                      $scope.panel.openTiempo = true;
                      $scope.panel.tiempo = false;
                    }
                    if ($scope.data.ttResumenGestion == null) {
                      $scope.data.ttResumenGestion = {};
                    } else {
                      if (parseInt($scope.data.ttResumenGestion.anios) > 0 && parseInt($scope.data.ttResumenGestion.meses) > 0) {
                        $scope.panel.openPropuesta = true;
                        $scope.panel.propuesta = false;
                      }
                    }
                    /*Fin Validaciones iniciales*/

                    /*Panel resumen*/

                    $scope.gridOptionsClaseDesarrollo = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsClaseDesarrollo.columnDefs = [
                      { field: 'tcClaseDesarrollo.claseDesarrolloDesc', name: 'claseDesarrolloDesc', displayName: 'Clase de desarrollo', enableFiltering: false }
                    ];
                    if ($scope.data.ttInventarioGestion == undefined || $scope.data.ttInventarioGestion == null) {
                      $scope.data.ttInventarioGestion = {};
                    }

                    $scope.gridOptionsClaseDesarrollo.data = $scope.data.codigoClase;

                    $scope.gridOptionsCodigoEspecie = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsCodigoEspecie.columnDefs = [
                      { field: 'nombreCientifico', name: 'nombreCientifico', displayName: 'Especies existentes', enableFiltering: false }
                    ];
                    $scope.gridOptionsCodigoEspecie.data = $scope.data.especies;

                    $scope.setUsoFinca = function (item) {
                      $scope.ttUsoFincaGestion.tcUsoFinca = item;
                    }

                    if ($scope.data.uso == null) {
                      $scope.data.uso = [];
                    }

                    for (var i = 0; i < $scope.data.uso.length; i++) {
                      var row = $scope.data.uso[i];
                      row.area = parseFloat(row.area).toFixed(4);
                    }

                    $scope.agregarUsoFinca = function () {
                      if ($scope.uso.usoFincaId != null && $scope.uso.area != null) {
                        $scope.uso.area = parseFloat($scope.uso.area).toFixed(4);
                        if (parseInt($scope.uso.usoFincaId) == 1 && $scope.data.tcTipoGestion.tipoGestionId != 1) {
                          if ($scope.data.tcTipoGestion.tipoGestionId != 7) {
                            if (parseFloat($scope.data.area) > parseFloat($scope.uso.area)) {
                              toastr.error('El área forestal no puede ser menor al área a intervenir solicitada');
                              return;
                            }
                          }
                        }
                        if ($scope.uso.area > 0 && parseFloat($scope.uso.area) <= parseFloat($scope.panel.restanteFinca)) {
                          var tmp = $scope.data;
                          var item = angular.copy($scope.ttUsoFincaGestion);
                          item.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          };
                          item.area = $scope.uso.area;
                          var encontrado = false;
                          for (var i = 0; i < $scope.data.uso.length; i++) {
                            if ($scope.data.uso[i].tcUsoFinca.usoFincaId == item.tcUsoFinca.usoFincaId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (encontrado) {
                            toastr.error('El uso seleccionado ya se encuentra en la lista');
                          } else {
                            gestionService.agregarUsoFinca(item).then(function (response) {
                              if (response.status == 'OK') {
                                tmp = response.data[0];
                                if ($scope.data.uso == undefined || $scope.data.uso == null) {
                                  $scope.data.uso = [];
                                }
                                item.usoFincaGestionId = tmp.usoFincaGestionId;
                                $scope.data.uso.push(item);
                                $scope.uso = {
                                  usoFincaId: null,
                                  area: null
                                };
                                $scope.ttUsoFincaGestion = {
                                  estadoId: 1
                                }
                                $scope.setTotalRestante();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        } else {
                          toastr.error('Debe indicar un número válido o una cantidad menor a ' + $scope.panel.restanteFinca + ' para el área');
                        }
                      } else {
                        toastr.error('Debe seleccionar el uso e indicar el área');
                      }
                    }

                    $scope.getPorcentajeUso = function (item) {
                      if (parseFloat($scope.data.areaFinca) == 0) {
                        return 0;
                      }
                      var porcentaje = (parseFloat(item.area) * 100) / parseFloat($scope.data.areaFinca);
                      porcentaje = Math.round(porcentaje);
                      return porcentaje;
                    }

                    $scope.getTotalArea = function () {
                      var total = 0;
                      for (var i = 0; i < $scope.data.uso.length; i++) {
                        total = parseFloat(total) + parseFloat($scope.data.uso[i].area);
                      }
                      return parseFloat(total).toFixed(4);
                    }

                    $scope.getTotalPorcentaje = function () {
                      var total = 0;
                      for (var i = 0; i < $scope.data.uso.length; i++) {
                        total = parseFloat(total) + parseFloat($scope.data.uso[i].porcentaje);
                      }
                      total = Math.round(total);
                      return total;
                    }

                    $scope.borrarDetalleUso = function (item) {
                      var tmp = $scope.data;
                      item.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      gestionService.quitarUsoFinca(item).then(function (response) {
                        if (response.status == 'OK') {
                          $scope.data.uso.splice($scope.data.uso.indexOf(item), 1);
                          $scope.setTotalRestante();
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });

                    }

                    $scope.keyupAreaUso = function (event) {
                      if (event.keyCode == 13) {
                        $scope.agregarUsoFinca();
                      }
                    }

                    $scope.validarPanelResumen = function () {
                      if (parseFloat($scope.panel.restanteFinca) > 0) {
                        toastr.error('Debe indicar el 100 % del uso de la finca');
                        return;
                      }
                      var areaForestal = $scope.getAreaForestal();
                      if ($scope.data.ttDivisionAreaGestion.fueraBosque == 1) {
                        if ((areaForestal + $scope.data.ttDivisionAreaGestion.areaFuera) < $scope.data.area) {
                          toastr.error('El área forestal más el área a intervenir de fuera de bosque no puede ser menor al área total a intervenir del plan');
                          return;
                        }
                      }
                      var areaForestal = $scope.getAreaForestal();
                      var tmp = $scope.data;
                      var item = tmp.ttDivisionAreaGestion;
                      var ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        areaProteccion: 0,
                        areaProduccion: areaForestal,
                        areaForestal: areaForestal,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      item.areaBosque = areaForestal;
                      item.areaProduccion = areaForestal;
                      item.areaProteccionBosque = 0;
                      if (item.fueraBosque == 1) {
                        if ($scope.data.tcTipoGestion.tipoGestionId != 7) {
                          var area = $scope.data.area - item.areaIntervenirFuera;
                          item.areaIntervenirBosque = parseFloat(area).toFixed(4);
                        } else {
                          item.areaIntervenirBosque = 0;
                        }
                      } else {
                        item.areaIntervenirBosque = $scope.data.area;
                      }
                      item.ttGestion = ttGestion;
                      gestionService.setAreas(item).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success(response.message);
                          $scope.data.ttDivisionAreaGestion.areaBosque = areaForestal;
                          $scope.data.ttDivisionAreaGestion.areaProduccion = areaForestal;
                          $scope.data.ttDivisionAreaGestion.areaProteccionBosque = 0;
                          if ($scope.data.ttDivisionAreaGestion.fueraBosque == 1) {
                            if ($scope.data.tcTipoGestion.tipoGestionId != 7) {
                              var area = $scope.data.area - $scope.data.ttDivisionAreaGestion.areaIntervenirFuera;
                              $scope.data.ttDivisionAreaGestion.areaIntervenirBosque = parseFloat(area).toFixed(4);
                            } else {
                              $scope.data.ttDivisionAreaGestion.areaIntervenirBosque = 0;
                            }
                          } else {
                            $scope.data.ttDivisionAreaGestion.areaIntervenirBosque = $scope.data.area;
                          }
                          $scope.panel.openSuperficie = true;
                          $scope.panel.superficie = false;
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.verMapaFinca = function () {
                      $scope.verMapa(2);
                    }

                    /*Fin panel resumen*/

                    /*Panel superficie*/
                    $scope.ttCriterioProteccionGestion = {
                      estadoId: 1
                    };

                    $scope.setCriterioProteccion = function (item) {
                      $scope.ttCriterioProteccionGestion.tcCriterioProteccion = item;
                    }

                    $scope.agregarCriterioProteccion = function () {
                      $scope.setTotalRestanteProteccion();
                      if ($scope.criterio.criterioProteccionId != null && $scope.criterio.area != null) {
                        if ($scope.criterio.area > 0 && parseFloat($scope.criterio.area) <= parseFloat($scope.panel.restanteProteccion)) {
                          var item = angular.copy($scope.ttCriterioProteccionGestion);
                          var encontrado = false;
                          for (var i = 0; i < $scope.data.criterio.length; i++) {
                            if ($scope.data.criterio[i].tcCriterioProteccion.criterioProteccionId == item.tcCriterioProteccion.criterioProteccionId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (encontrado) {
                            toastr.error('El criterio de protección seleccionado ya se encuentra en la lista');
                          } else {
                            var tmp = $scope.data;
                            item.ttGestion = {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            };
                            item.area = $scope.criterio.area;
                            gestionService.agregarCriterioProteccion(item).then(function (response) {
                              if (response.status == 'OK') {
                                if ($scope.data.criterio == undefined || $scope.data.criterio == null) {
                                  $scope.data.criterio = [];
                                }
                                $scope.data.criterio.push(item);
                                $scope.criterio = {
                                  criterioProteccionId: null,
                                  area: null
                                };
                                $scope.ttCriterioProteccionGestion = {
                                  estadoId: 1
                                };
                                $scope.setTotalRestanteProteccion();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        } else {
                          toastr.error('Debe indicar un número válido o una cantidad menor a ' + $scope.panel.restanteProteccion + ' para el área de protección');
                        }
                      } else {
                        toastr.error('Debe seleccionar el criterio de protección e indicar el área');
                      }
                    }

                    $scope.getPorcentajeCriterio = function (item) {
                      if (parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionFuera) == 0 && parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionBosque) == 0) {
                        return 0;
                      }
                      var totalProteccion = parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionFuera) + parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionBosque);
                      var porcentaje = (parseFloat(item.area) * 100) / parseFloat(totalProteccion);
                      porcentaje = Math.round(porcentaje);
                      return porcentaje;
                    }

                    $scope.getTotalAreaCriterio = function () {
                      var total = 0;
                      if ($scope.data.criterio != null) {
                        for (var i = 0; i < $scope.data.criterio.length; i++) {
                          total = parseFloat(total) + parseFloat($scope.data.criterio[i].area);
                        }
                      }
                      return parseFloat(total).toFixed(4);
                    }

                    $scope.getTotalPorcentajeCriterio = function () {
                      if ($scope.data.criterio != null) {
                        var total = 0;
                        for (var i = 0; i < $scope.data.criterio.length; i++) {
                          total = parseFloat(total) + parseFloat($scope.data.criterio[i].porcentaje);
                        }
                        total = Math.round(total);
                        return total;
                      }
                      return 0;
                    }

                    $scope.borrarDetalleCriterio = function (item) {
                      var tmp = $scope.data;
                      item.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      gestionService.quitarCriterioProteccion(item).then(function (response) {
                        if (response.status == 'OK') {
                          $scope.data.criterio.splice($scope.data.criterio.indexOf(item), 1);
                          $scope.setTotalRestanteProteccion();
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });

                    }

                    $scope.keyupAreaCriterio = function (event) {
                      if (event.keyCode == 13) {
                        $scope.agregarCriterioProteccion();
                      }
                    }

                    $scope.getAreaForestal = function () {
                      for (var i = 0; i < $scope.data.uso.length; i++) {
                        if ($scope.data.uso[i].tcUsoFinca.usoFincaId == 1) {
                          return $scope.data.uso[i].area;
                        }
                      }
                      return 0;
                    }

                    $scope.validarArea = function () {
                      //area_reserva se convierte en area de produccion
                      if ($scope.data.ttDivisionAreaGestion.areaProteccionBosque != null) {
                        var areaForestal = parseFloat($scope.data.ttDivisionAreaGestion.areaBosque).toFixed(4);
                        var areaProteccion = parseFloat($scope.data.ttDivisionAreaGestion.areaProteccionBosque).toFixed(4);
                        var areaProduccion = parseFloat(areaForestal) - areaProteccion;
                        areaProduccion = parseFloat(areaProduccion).toFixed(4);
                        if (parseFloat(areaProteccion) > parseFloat(areaForestal)) {
                          toastr.error('El área de protección no puede ser mayor a la forestal');
                          $scope.data.ttDivisionAreaGestion.areaProteccionBosque = 0;
                          return;
                        }
                        if (parseFloat(areaProduccion) < parseFloat($scope.data.ttDivisionAreaGestion.areaIntervenirBosque)) {
                          toastr.error('El área de producción no puede ser menor al área a intervenir en bosque');
                          $scope.data.ttDivisionAreaGestion.areaProteccionBosque = 0;
                          return;
                        }
                        $scope.data.ttDivisionAreaGestion.areaProduccion = parseFloat(areaProduccion).toFixed(4);
                        if ($scope.data.ttDivisionAreaGestion.fueraBosque == 1) {
                          var total = parseFloat($scope.data.ttDivisionAreaGestion.areaIntervenirBosque) + parseFloat($scope.data.ttDivisionAreaGestion.areaIntervenirFuera);
                          if (total < parseFloat($scope.data.area)) {
                            toastr.error('El área con bosque a intervenir más el área de fuera bosque de bosque a intervenir, no puede ser menor al área total a intervenir');
                            return;
                          }
                        } else {
                          if (parseFloat($scope.data.ttDivisionAreaGestion.areaProduccion) < parseFloat($scope.data.area)) {
                            toastr.error('El área de producción no puede ser menor al área a intervenir');
                            return;
                          }
                        }
                        var tmp = $scope.data;
                        var item = tmp.ttDivisionAreaGestion;
                        var ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          areaProteccion: tmp.areaProteccion,
                          areaProduccion: tmp.areaProduccion,
                          areaForestal: areaForestal,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        item.ttGestion = ttGestion;
                        gestionService.setAreas(item).then(function (response) {
                          if (response.status == 'OK') {
                            toastr.success(response.message);
                            $scope.setTotalRestanteProteccion();
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.validarPanelSuperficie = function () {
                      if (parseFloat($scope.panel.restanteProteccion) > 0) {
                        toastr.error('Debe indicar el 100% de los criterios de protección');
                      } else {
                        var tmp = $scope.data;
                        var ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId,
                          tcTipoBosque: tmp.tcTipoBosque
                        };
                        gestionService.moverPanelSiguiente(ttGestion).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.setTabs(4);
                            $state.go('index.plan.inventario');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.verMapaProteccion = function () {
                      $scope.verMapa(3);
                    }

                    /*Fin panel superficie*/

                  }] //end controller
              }
            }
          })
          .state('index.plan.inventario', {
            url: '/inventario',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.inventario.tpl.html',
                resolve: {
                  dataOrigenCalculo: ['origencalculoService',
                    function (origencalculoService) {
                      return origencalculoService.listaActiva();
                    }],
                  dataTipoInventario: ['tipoinventarioService',
                    function (tipoinventarioService) {
                      return tipoinventarioService.listaActiva();
                    }],
                  dataTipoMuestreo: ['tipomuestreoService',
                    function (tipomuestreoService) {
                      return tipomuestreoService.listaActiva();
                    }],
                  dataFormaParcela: ['formaparcelaService',
                    function (formaparcelaService) {
                      return formaparcelaService.listaActiva();
                    }],
                  dataClaseDesarrollo: ['clasedesarrolloService',
                    function (clasedesarrolloService) {
                      return clasedesarrolloService.listaActiva();
                    }],
                  dataProducto: ['productoService',
                    function (productoService) {
                      return productoService.listaNoMaderable();
                    }],
                  dataUnidadMedida: ['unidadmedidaService',
                    function (unidadmedidaService) {
                      return unidadmedidaService.listaActiva();
                    }],
                  dataTStudent: ['tstudentService',
                    function (tstudentService) {
                      return tstudentService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataOrigenCalculo', 'dataTipoInventario', 'dataTipoMuestreo', 'dataFormaParcela', 'dataClaseDesarrollo', 'authService', 'dataProducto', 'dataUnidadMedida', 'dataTStudent',
                  function ($scope, $state, toastr, gestionService, dataOrigenCalculo, dataTipoInventario, dataTipoMuestreo, dataFormaParcela, dataClaseDesarrollo, authService, dataProducto, dataUnidadMedida, dataTStudent) {

                    authService.removeLocalData('origenCalculoId');
                    authService.removeLocalData('formaParcelaId');
                    authService.removeLocalData('tipoInventarioId');
                    authService.removeLocalData('tipoGestionId');

                    authService.saveLocalData('tipoGestionId', $scope.data.tcTipoGestion.tipoGestionId);

                    $scope.dataTipoInventario = dataTipoInventario.data;
                    $scope.dataOrigenCalculo = dataOrigenCalculo.data;
                    var tmpData = dataTipoMuestreo.data;
                    var tmpDataVisible = [];
                    if ($scope.data.tcTipoGestion.tipoGestionId == 1) {
                      tmpDataVisible = tmpData;
                    } else {
                      for (var i = 0; i < tmpData.length; i++) {
                        if (tmpData[i].ocultar != 1) {
                          tmpDataVisible.push(tmpData[i]);
                        }
                      }
                    }
                    $scope.dataTipoMuestreo = tmpDataVisible;
                    tmpData = dataClaseDesarrollo.data;
                    tmpDataVisible = [];
                    if (($scope.data.tcTipoGestion.tipoGestionId == 1 && $scope.data.ttDivisionAreaGestion.fueraBosque == 1)
                      || $scope.data.tcTipoGestion.tipoGestionId == 7) {
                      tmpDataVisible = tmpData;
                    } else {
                      for (var i = 0; i < tmpData.length; i++) {
                        if (tmpData[i].ocultar != 1) {
                          tmpDataVisible.push(tmpData[i]);
                        }
                      }
                    }
                    $scope.dataClaseDesarrollo = tmpDataVisible;
                    $scope.dataFormaParcela = dataFormaParcela.data;
                    $scope.dataProducto = dataProducto.data;
                    $scope.dataUnidadMedida = dataUnidadMedida.data;
                    $scope.dataTStudent = dataTStudent.data;
                    $scope.dataPrecisionEstratificado = [];
                    for (var i = 1; i < 16; i++) {
                      $scope.dataPrecisionEstratificado.push({ precisionId: i, precisionDesc: i });
                    }

                    $scope.dataCompensaPendiente = [
                      { compensaPendienteId: 0, compensaPendienteDesc: 'No' },
                      { compensaPendienteId: 1, compensaPendienteDesc: 'Si' }
                    ];

                    $scope.panel = {
                      resumen: true,
                      openResumen: false,
                      openRegresion: false,
                      regresion: true,
                      openCodigo: false,
                      codigo: true,
                      noMaderable: true
                    }

                    $scope.textoTamanioParcela = 'Tamaño de las parcelass (m²)';

                    $scope.conteoRodales = function () {
                      var data = $scope.data.rodal;
                      var rango = 4.99, start = 9.99, end = 9.99, marca = 0.0;
                      var etiqueta = "", etiquetaMarca = "";
                      var dataTmp = [];
                      for (var i = 0; i < 19; i++) {
                        start = end + 0.01;
                        start = Math.round(parseFloat(start));
                        end = start + rango;
                        if (end > 100) {
                          end = 1000;
                        }
                        end = parseFloat(end).toFixed(2);
                        marca = start + 2.49;
                        marca = parseFloat(marca).toFixed(2);
                        if (i > 17) {
                          etiqueta = ">=" + start;
                          etiquetaMarca = start;
                        } else {
                          etiqueta = start + " - " + end;
                          etiquetaMarca = marca;
                        }
                        var item = {
                          etiqueta: etiqueta,
                          etiquetaMarca: etiquetaMarca,
                          minimo: start,
                          maximo: end,
                          marcaClase: marca,
                          abr: 0,
                          conteoArbol: 0,
                          volumenRodal: 0
                        }
                        dataTmp.push(item);
                      }
                      var dataInventarioRodal = [];
                      var dataDistribucionRodal = [];
                      var item = null, itemDistribucion = null;
                      for (var i = 0; i < data.length; i++) {
                        var sumaVolumen = 0;
                        itemDistribucion = JSON.parse(JSON.stringify(angular.copy(data[i])));
                        var especiesStr = "";
                        for (var j = 0; j < data[i].especies.length; j++) {
                          if (j == 0) {
                            especiesStr = data[i].especies[j].tcEspecie.codigo;
                          } else {
                            especiesStr = especiesStr + ", " + data[i].especies[j].tcEspecie.codigo;
                          }
                          sumaVolumen = parseFloat(sumaVolumen) + parseFloat(data[i].especies[j].volh);
                          itemDistribucion.especies[j].distribucion = dataTmp;
                        }
                        item = {
                          correlativo: data[i].correlativo,
                          area: data[i].area,
                          especies: especiesStr,
                          totalParcelas: data[i].parcelas.length
                        }
                        dataInventarioRodal.push(item);
                        dataDistribucionRodal.push(itemDistribucion);
                      }
                      if (dataInventarioRodal.length > 0) {
                        var itemInventario = {
                          gestionId: $scope.data.gestionId,
                          distribucionRodal: dataDistribucionRodal
                        }
                        gestionService.actualizarInventario(itemInventario).then(function (response) {
                          if (response.status == 'OK') {
                            toastr.success(response.message);
                            $scope.data.ttInventarioGestion.inventarioRodal = dataInventarioRodal;
                            $scope.gridOptionsResultadoInventario.data = dataInventarioRodal;
                            $scope.data.ttInventarioGestion.distribucionRodal = dataDistribucionRodal;
                            $scope.visibleDistribucion = $scope.visibleDatosDistribucion();
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    /*Validaciones iniciales*/

                    $scope.visibleDatosDistribucion = function () {
                      if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != null) {
                        if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 1) {
                          $scope.panel.openDistribucion = true;
                          return true;
                        }
                      }
                      return false;
                    }

                    if ($scope.data.ttInventarioGestion != undefined) {
                      if ($scope.data.ttInventarioGestion.tcOrigenCalculo != null) {
                        authService.saveLocalData('origenCalculoId', $scope.data.ttInventarioGestion.tcOrigenCalculo.origenCalculoId);
                        if ($scope.data.ttInventarioGestion.tcOrigenCalculo.origenCalculoId == 1) {
                          $scope.disponibleIngreso = false;
                        } else {
                          $scope.disponibleIngreso = true;
                        }
                      }
                      if ($scope.data.ttInventarioGestion.tcTipoInventario != null) {
                        authService.saveLocalData('tipoInventarioId', $scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId);
                      }
                      if ($scope.data.ttInventarioGestion.tcFormaParcela != null) {
                        authService.saveLocalData('formaParcelaId', $scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId);
                      }
                      $scope.panel.openResumen = true;
                      $scope.panel.resumen = false;
                      $scope.panel.openRegresion = true;
                      $scope.panel.regresion = false;
                      $scope.panel.distribucion = false;
                      $scope.panel.openCodigo = true;
                      $scope.panel.codigo = false;
                      if ($scope.data.rodal != null) {
                        if ($scope.data.rodal.length > 0 && $scope.data.ttEstadisticoGestion != null) {
                          $scope.panel.openNoMaderable = true;
                          $scope.panel.noMaderable = false;
                        }
                      }
                      if ($scope.data.ttEstadisticoGestion != undefined) {
                        $scope.panel.openAnalisisEstadistico = true;
                        $scope.panel.openAnalisis = true;
                        if ($scope.data.ttEstadisticoGestion.precisionId != null) {
                          $scope.precisionId = $scope.data.ttEstadisticoGestion.precisionId;
                        }
                      }
                      if ($scope.data.ttInventarioGestion.distribucionRodal != undefined) {
                        $scope.panel.openDistribucion = true;
                        $scope.visibleDistribucion = $scope.visibleDatosDistribucion();
                      }
                      if ($scope.data.ttInventarioGestion.tcFormaParcela != null) {
                        if ($scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId == 4) {
                          $scope.compensaPendienteVisible = true;
                        } else {
                          $scope.compensaPendienteVisible = false;
                        }
                      }
                    }
                    /*Fin Validaciones iniciales*/

                    /*Panel informacion inventario*/
                    if ($scope.data.ttInventarioGestion == undefined) {
                      $scope.data.ttInventarioGestion = {};
                      $scope.data.ttInventarioGestion.totalRodales = 0;
                      $scope.data.ttInventarioGestion.areaMuestreada = 0;
                      $scope.data.ttInventarioGestion.tamanioParcela = 0;
                      $scope.data.ttInventarioGestion.diametro = 0;
                      $scope.data.ttInventarioGestion.intensidadMuestreo = 0;
                    } else {
                      if ($scope.data.ttInventarioGestion.totalRodales == undefined || $scope.data.ttInventarioGestion.totalRodales == null) {
                        $scope.data.ttInventarioGestion.totalRodales = 0;
                      }
                      if ($scope.data.ttInventarioGestion.areaMuestreada == undefined || $scope.data.ttInventarioGestion.areaMuestreada == null) {
                        $scope.data.ttInventarioGestion.areaMuestreada = 0;
                      }
                      if ($scope.data.ttInventarioGestion.tamanioParcela == undefined || $scope.data.ttInventarioGestion.tamanioParcela == null) {
                        $scope.data.ttInventarioGestion.tamanioParcela = 0;
                      }
                      if ($scope.data.ttInventarioGestion.diametro == undefined || $scope.data.ttInventarioGestion.diametro == null) {
                        $scope.data.ttInventarioGestion.diametro = 0;
                      }
                      if ($scope.data.ttInventarioGestion.intensidadMuestreo == undefined || $scope.data.ttInventarioGestion.intensidadMuestreo == null) {
                        $scope.data.ttInventarioGestion.intensidadMuestreo = 0;
                      }
                      if ($scope.data.ttInventarioGestion.tcTipoInventario != undefined) {
                        if ($scope.data.ttInventarioGestion.tcTipoInventario != null) {
                          if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 1) {
                            $scope.mostrarParaTipoInventario = false;
                          } else {
                            $scope.mostrarParaTipoInventario = true;
                          }
                        }
                      }
                    }
                    $scope.gridOptionsExcel = angular.copy($scope.gridOptionsSelection);
                    //$scope.gridOptionsExcel.enableFiltering = false;
                    if ($scope.data.ttInventarioGestion.data != undefined) {
                      if ($scope.data.ttInventarioGestion.data != null) {
                        $scope.gridOptionsExcel.columnDefs = [
                          { field: 'TURNO', name: 'TURNO', displayName: 'TURNO' },
                          { field: 'RODAL', name: 'RODAL', displayName: 'RODAL' },
                          { field: 'NO', name: 'NO', displayName: 'NO' },
                          { field: 'DAP', name: 'DAP', displayName: 'DAP', width: '15%' },
                          { field: 'ALTURA', name: 'ALTURA', displayName: 'ALTURA', width: '15%' },
                          { field: 'NOMBRE_CIENTIFICO', name: 'NOMBRE_CIENTIFICO', displayName: 'NOMBRE_CIENTIFICO', width: '20%' },
                          { field: 'VOLUMEN', name: 'VOLUMEN', displayName: 'VOLUMEN' },
                          { field: 'AREA_BASAL', name: 'AREA_BASAL', displayName: 'AREA_BASAL' },
                          { field: 'PARCELA', name: 'PARCELA', displayName: 'PARCELA' }
                        ];
                        $scope.gridOptionsExcel.data = $scope.data.ttInventarioGestion.data;
                      }
                    }



                    $scope.gridOptionsCodigoEspecie = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsCodigoEspecie.enableFiltering = false;
                    $scope.gridOptionsCodigoEspecie.columnDefs = [
                      { field: 'tcEspecie.codigo', name: 'codigo', displayName: 'Código' },
                      { field: 'tcEspecie.especieDesc', name: 'especieDesc', displayName: 'Nombre común' },
                      { field: 'tcEspecie.nombreCientifico', name: 'nombreCientifico', displayName: 'Nombre científico' },
                    ];
                    if ($scope.data.especies != undefined) {
                      if ($scope.data.especies != null) {
                        $scope.gridOptionsCodigoEspecie.data = $scope.data.especies;
                      }
                    }

                    if ($scope.data.ttInventarioGestion.rodal != undefined) {
                      if ($scope.data.ttInventarioGestion.rodal != null) {
                        $scope.data.ttInventarioGestion.rodal = $scope.data.ttInventarioGestion.rodal;
                      }
                    }

                    $scope.gridOptionsClaseDesarrollo = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsClaseDesarrollo.enableFiltering = false;
                    $scope.gridOptionsClaseDesarrollo.columnDefs = [
                      { field: 'tcClaseDesarrollo.claseDesarrolloDesc', name: 'claseDesarrolloDesc', displayName: 'Clase de desarrollo' },
                      { field: 'tcClaseDesarrollo.codigoClase', name: 'codigoClase', displayName: 'Código' }
                    ];
                    if ($scope.data.codigoClase == undefined) {
                      $scope.data.codigoClase = [];
                    } else {
                      if ($scope.data.codigoClase == null) {
                        $scope.data.codigoClase = [];
                      }
                    }
                    $scope.gridOptionsClaseDesarrollo.data = $scope.data.codigoClase;

                    $scope.nombreArchivo = "Haga clic para cargar el archivo";

                    $scope.setOrigenCalculo = function (item) {
                      authService.removeLocalData('origenCalculoId');
                      authService.saveLocalData('origenCalculoId', item.origenCalculoId);
                      if (item.origenCalculoId == 1) {
                        $scope.disponibleIngreso = false;
                      } else {
                        $scope.disponibleIngreso = true;
                      }
                    }

                    $scope.setTipoInventarioEncabezado = function (item) {
                      authService.removeLocalData('tipoInventarioId');
                      authService.saveLocalData('tipoInventarioId', item.tipoInventarioId);
                      if (item.tipoInventarioId == 1) {
                        $scope.mostrarParaTipoInventario = false;
                      } else {
                        $scope.mostrarParaTipoInventario = true;
                      }
                    }

                    $scope.setCompensaPendiente = function (item) {
                      authService.removeLocalData('compensaPendienteId');
                      authService.saveLocalData('compensaPendienteId', item.compensaPendienteId);
                      if ($scope.gridOptionsExcel.data != null) {
                        if ($scope.gridOptionsExcel.data.length > 0) {
                          $scope.gridOptionsExcel.data = [];
                        }
                      }
                    }

                    $scope.setFormaParcelaInventario = function (item) {
                      if (item.formaParcelaId == 4 && $scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 2) {
                        $scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId = null;
                        toastr.error('El tipo de inventario no permite esta opción, solo en muestreo');
                        return;
                      }
                      authService.removeLocalData('formaParcelaId');
                      authService.saveLocalData('formaParcelaId', item.formaParcelaId);
                      if (item.formaParcelaId == 4) {
                        $scope.compensaPendienteVisible = true;
                      } else {
                        $scope.compensaPendienteVisible = false;
                      }
                    }

                    $scope.encontrarRodales = function (data) {

                    }

                    $scope.onFileRead = function (data, id) {
                      var tmpData = [];
                      if (data.length <= 0) {
                        toastr.error('Datos no encontrados', 'El archivo seleccionado no tiene ninguna información, favor de revisar');
                        return;
                      }
                      if ($scope.data.rodal == null) {
                        toastr.error('Debe cargar primero la información del inventario para continuar');
                        return;
                      } else {
                        if ($scope.data.rodal.length <= 0) {
                          toastr.error('Debe cargar primero la información del inventario para continuar');
                          return;
                        }
                      }
                      var rodal = {
                        correlativo: -1
                      }
                      var i, j;
                      var encontrado = false;
                      var tmpRodal = $scope.data.rodal;
                      if (id == 1) {
                        for (j = 0; j < tmpRodal.length; j++) {
                          tmpRodal[j].latizal = [];
                        }
                      } else {
                        for (j = 0; j < tmpRodal.length; j++) {
                          tmpRodal[j].brinzal = [];
                        }
                      }
                      for (i = 0; i < data.length; i++) {
                        encontrado = false;
                        for (j = 0; j < tmpRodal.length; j++) {
                          if (tmpRodal[j].correlativo == data[i].NO_RODAL) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          toastr.error('El número de rodal de la especie ' + data[i].NOMBRE_CIENTIFICO + ' no se encuentra en el inventario presentando, favor de revisar');
                          return;
                        }
                        encontrado = false;
                        var rodal = {};
                        for (j = 0; j < tmpData.length; j++) {
                          if (tmpData[j].correlativo == data[i].NO_RODAL) {
                            encontrado = true;
                            rodal = tmpData[j];
                            break;
                          }
                        }
                        if (!encontrado) {
                          rodal = {
                            correlativo: data[i].NO_RODAL,
                            area: data[i].AREA_RODAL,
                            parcela: [],
                            nparcelas: 0,
                            especie: [],
                            tamanioParcela: 0
                          }
                          tmpData.push(rodal);
                        }
                        encontrado = false;
                        var parcela = {};
                        for (j = 0; j < rodal.parcela.length; j++) {
                          if (rodal.parcela[j].noParcela == data[i].NO_PARCELA) {
                            encontrado = true;
                            parcela = rodal.parcela[j];
                            break;
                          }
                        }
                        if (!encontrado) {
                          parcela = {
                            noParcela: data[i].NO_PARCELA,
                            tamanioParcela: data[i].TAMANIO_PARCELA,
                            nespecie: 0,
                            especie: []
                          }
                          rodal.parcela.push(parcela);
                          rodal.nparcelas++;
                        }
                        encontrado = false;
                        var especie = {};
                        for (j = 0; j < parcela.especie.length; j++) {
                          if (parcela.especie[j].especieId == data[i].ESPECIE_ID) {
                            encontrado = true;
                            especie = parcela.especie[j];
                            break;
                          }
                        }
                        if (!encontrado) {
                          especie = {
                            especieId: data[i].ESPECIE_ID,
                            nombreCientifico: data[i].NOMBRE_CIENTIFICO,
                            individuos: data[i].INDIVIDUOS,
                            codigo: data[i].CODIGO
                          }
                          parcela.especie.push(especie);
                          parcela.nespecie++;
                        } else {
                          especie.individuos = parseInt(especie.individuos) + parseInt(data[i].INDIVIDUOS);
                        }
                      }
                      var k, l;
                      //obtener especies por rodal
                      var tmp = $scope.data;
                      var dataRegeneracion = [];
                      for (i = 0; i < tmpData.length; i++) {
                        var tmpEspecie = [];
                        var rodalDefinicion = null;
                        for (j = 0; j < tmpRodal.length; j++) {
                          if (tmpData[i].correlativo == tmpRodal[j].correlativo) {
                            rodalDefinicion = {
                              ttGestion: {
                                gestionId: tmp.gestionId,
                                tcTipoGestion: tmp.tcTipoGestion,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: tmp.tcPersonaCrea,
                                tcTipoBosque: tmp.tcTipoBosque,
                                tcElaborador: tmp.tcElaborador,
                                ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                personaAnulaId: $scope.loginData.personaId
                              },
                              rodalGestionId: tmpRodal[j].rodalGestionId,
                              correlativo: tmpRodal[j].correlativo,
                              area: tmpRodal[j].area,
                              areaMuestreada: tmpRodal[j].areaMuestreada
                            }
                          }
                          break;
                        }
                        for (j = 0; j < tmpData[i].parcela.length; j++) {
                          for (k = 0; k < tmpData[i].parcela[j].especie.length; k++) {
                            encontrado = false;
                            for (l = 0; l < tmpEspecie.length; l++) {
                              if (tmpEspecie[l].tcEspecie.especieId == tmpData[i].parcela[j].especie[k].especieId) {
                                encontrado = true;
                                especie = tmpEspecie[l];
                                break;
                              }
                            }
                            if (!encontrado) {
                              especie = {
                                tcEspecie: {
                                  especieId: tmpData[i].parcela[j].especie[k].especieId,
                                  nombreCientifico: tmpData[i].parcela[j].especie[k].nombreCientifico,
                                  codigo: tmpData[i].parcela[j].especie[k].codigo
                                },
                                no: tmpEspecie.length + 1,
                                individuos: tmpData[i].parcela[j].especie[k].individuos,
                                ttRodalGestion: rodalDefinicion
                              }
                              tmpEspecie.push(especie);
                            } else {
                              especie.individuos = parseInt(especie.individuos) + parseInt(tmpData[i].parcela[j].especie[k].individuos);
                            }
                          }
                          tmpData[i].tamanioParcela = parseFloat(tmpData[i].tamanioParcela) + parseFloat(tmpData[i].parcela[j].tamanioParcela);
                        }
                        tmpData[i].especie = tmpEspecie;
                      }
                      //obtener densidades
                      for (i = 0; i < tmpData.length; i++) {
                        for (j = 0; j < tmpData[i].especie.length; j++) {
                          value = (parseInt(tmpData[i].especie[j].individuos) * 10000) / parseFloat(tmpData[i].tamanioParcela);
                          value = parseFloat(value).toFixed(2);
                          tmpData[i].especie[j].densidadh = value;
                          value = value * parseFloat(tmpData[i].area);
                          value = parseFloat(value).toFixed(2);
                          tmpData[i].especie[j].densidadr = value;
                        }
                      }
                      if (id == 1) {
                        for (var i = 0; i < tmpRodal.length; i++) {
                          for (var j = 0; j < tmpData.length; j++) {
                            if (tmpRodal[i].correlativo == tmpData[j].correlativo) {
                              tmpRodal[i].latizal = tmpData[j].especie;
                              tmpData[j].rodalGestionId = tmpRodal[i].rodalGestionId;
                              delete tmpData[j].especie;
                              break;
                            }
                          }
                        }
                        var rodal = angular.copy(tmpRodal);
                        for (var i = 0; i < rodal.length; i++) {
                          for (var j = 0; j < rodal[i].especies.length; j++) {
                            if (rodal[i].especies[j].fuste != null) {
                              delete rodal[i].especies[j].fuste;
                            }
                            delete rodal[i].especies[j].distribucion;
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodal: rodal
                        }
                        gestionService.agregarDatosLatizal(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.data.ttInventarioGestion.latizal = tmpData;
                            swal('Latizal', 'Archivo cargado con éxito, recuerde presionar sobre botón guardar al agregar todos los datos de regeneración');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        for (var i = 0; i < tmpRodal.length; i++) {
                          for (var j = 0; j < tmpData.length; j++) {
                            if (tmpRodal[i].correlativo == tmpData[j].correlativo) {
                              tmpRodal[i].brinzal = tmpData[j].especie;
                              tmpData[j].rodalGestionId = tmpRodal[i].rodalGestionId;
                              delete tmpData[j].especie;
                              break;
                            }
                          }
                        }
                        var rodal = angular.copy(tmpRodal);
                        for (var i = 0; i < rodal.length; i++) {
                          for (var j = 0; j < rodal[i].especies.length; j++) {
                            if (rodal[i].especies[j].fuste != null) {
                              delete rodal[i].especies[j].fuste;
                            }
                            delete rodal[i].especies[j].distribucion;
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodal: rodal
                        }
                        gestionService.agregarDatosBrinzal(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.data.ttInventarioGestion.brinzal = tmpData;
                            swal('Brinzal', 'Archivo cargado con éxito, recuerde presionar sobre botón guardar al agregar todos los datos de regeneración');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.obtenerCodigosEspecie = function (data) {
                      var no = 1, id = 0;
                      var especie = "-----";
                      var dataEspecie = [];
                      for (var i = 0; i < data.length; i++) {
                        data[i].RODAL = parseInt(data[i].RODAL);
                        if (data[i].NOMBRE_CIENTIFICO != especie) {
                          var row = {
                            correlativo: no,
                            tcEspecie: {
                              especieId: data[i].ESPECIE_ID,
                              nombreCientifico: data[i].NOMBRE_CIENTIFICO,
                              codigo: data[i].CODIGO
                            },
                            ttGestion: {
                              gestionId: $scope.data.gestionId
                            }
                          }
                          var encontrado = false;
                          for (var j = 0; j < dataEspecie.length; j++) {
                            if (dataEspecie[j].tcEspecie.especieId == row.tcEspecie.especieId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            dataEspecie.push(row);
                            no++;
                          }
                          especie = row.tcEspecie.nombreCientifico;
                        }
                      }
                      return dataEspecie;
                    }

                    $scope.generarBoleta = function () {
                      var data = $scope.gridOptionsExcel.data;
                      if (data == null) {
                        toastr.error('No se ha proporcionado ninguna información');
                        return;
                      }
                      try {
                        var createXLSLFormatObj = [];
                        /* XLS Head Columns */
                        var xlsHeader = ["TURNO", "RODAL", "AREA_RODAL", "PARCELA", "TAMANIO_PARCELA", "NO", "DAP", "ALTURA", "NOMBRE_CIENTIFICO", "VOLUMEN", "AREA_BASAL", "CALIDAD_FUSTE", "X", "Y"];
                        /* XLS Rows Data */
                        var xlsRows = [];
                        for (var i = 0; i < data.length; i++) {
                          var item = {
                            TURNO: data[i].TURNO,
                            RODAL: data[i].RODAL,
                            AREA_RODAL: data[i].AREA_RODAL,
                            PARCELA: data[i].PARCELA,
                            TAMANIO_PARCELA: data[i].TAMANIO_PARCELA,
                            NO: data[i].NO,
                            DAP: data[i].DAP,
                            ALTURA: data[i].ALTURA,
                            NOMBRE_CIENTIFICO: data[i].NOMBRE_CIENTIFICO,
                            VOLUMEN: data[i].VOLUMEN,
                            AREA_BASAL: data[i].AREA_BASAL,
                            CALIDAD_FUSTE: data[i].CALIDAD_FUSTE,
                            X: data[i].X == null ? '' : data[i].X,
                            Y: data[i].Y == null ? '' : data[i].Y
                          }
                          xlsRows.push(item);
                        }
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(xlsRows, function (index, value) {
                          var innerRowData = [];
                          $("tbody").append('<tr><td>' + value.TURNO + '</td><td>' + value.RODAL + '</td><td>' + value.AREA_RODAL + '</td><td>' + value.PARCELA + '</td><td>' + value.TAMANIO_PARCELA + '</td><td>' + value.NO + '</td><td>' + value.DAP + '</td><td>' + value.ALTURA + '</td><td>' + value.NOMBRE_CIENTIFICO + '</td><td>' + value.VOLUMEN + '</td><td>' + value.AREA_BASAL + '</td><td>' + value.CALIDAD_FUSTE + '</td><td>' + value.X + '</td><td>' + value.Y + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });


                        /* File Name */
                        var filename = "boleta.xlsx";

                        /* Sheet Name */
                        var ws_name = "data";

                        if (typeof console !== 'undefined') console.log(new Date());
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

                        /* Add worksheet to workbook */
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);

                        /* Write workbook and Download */
                        if (typeof console !== 'undefined') console.log(new Date());
                        XLSX.writeFile(wb, filename);
                        if (typeof console !== 'undefined') console.log(new Date());

                      } catch (e) {
                        toastr.error(e.message);
                      }
                    }

                    var rodalParcela = [];
                    $scope.obtenerResumenInventario = function (data) {
                      var dataResumen = [];
                      var rodalId = 0;
                      var tcTipoInventario = null, tcTipoInventarioOrg = null;
                      var diametroMinimo = 0;
                      if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != null) {
                        if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 3) {
                          tcTipoInventario = $scope.data.ttInventarioGestion.tcTipoInventario;
                        }
                      }
                      var formaParcelaId = 0;
                      if ($scope.data.ttInventarioGestion.tcFormaParcela != null) {
                        formaParcelaId = $scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId;
                      }
                      tcTipoInventarioOrg = tcTipoInventario;
                      var tmp = $scope.data;
                      var i, j, k;
                      var totalParcelas = 0, parcelaAnt = 0, tamanioParcela;
                      rodalParcela = [];
                      var bndSaneamiento = false;
                      if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                        bndSaneamiento = true;
                      }
                      for (i = 0; i < data.length; i++) {
                        if (i == 0) {
                          diametroMinimo = parseFloat(data[i].DAP);
                        }
                        if (parseFloat(data[i].DAP) < parseFloat(diametroMinimo)) {
                          diametroMinimo = parseFloat(data[i].DAP);
                        }
                        var row = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion
                          },
                          correlativo: data[i].RODAL,
                          area: data[i].AREA_RODAL,
                          areaMuestreada: 0,
                          edad: 0,
                          incremento: 0,
                          pendientePromedio: 0,
                          tcClaseDesarrollo: null,
                          tamanioParcela: data[i].TAMANIO_PARCELA == null ? 0 : data[i].TAMANIO_PARCELA,
                          turno: data[i].TURNO,
                          totalParcelas: data[i].PARCELA == null ? 0 : data[i].PARCELA,
                          especies: [],
                          parcelas: []
                        }
                        var tmpRodal = angular.copy(row);
                        var encontrado = false;
                        if (data[i].PARCELA == undefined || data[i].PARCELA == null) {
                          tcTipoInventario = {
                            tipoInventarioId: 1
                          }
                        } else {
                          tcTipoInventario = {
                            tipoInventarioId: 2
                          }
                        }
                        var altura = data[i].ALTURA;
                        var dap = data[i].DAP;
                        var volumen = 0;
                        var areaBasal = 0;
                        var conteoArbol = 1;
                        var arbolh = 0;
                        var arbolr = 0;
                        var abh = 0;
                        var abr = 0;
                        var volh = 0;
                        var volr = 0;
                        if (formaParcelaId == 4) { //tamaño variable
                          volumen = parseFloat(data[i].VOLUMEN_RODAL);
                          areaBasal = parseFloat(data[i].AB_RODAL);
                          conteoArbol = parseFloat(data[i].ARBOLES_RODAL);
                          arbolh = parseFloat(data[i].ARBOLES_HA);
                          arbolr = parseFloat(data[i].ARBOLES_RODAL);
                          abh = parseFloat(data[i].AB_CORREGIDO);
                          abr = parseFloat(data[i].AB_RODAL);
                          volh = parseFloat(data[i].VOL_HA);
                          volr = parseFloat(data[i].VOLUMEN_RODAL);
                        } else {
                          volumen = parseFloat(data[i].VOLUMEN);
                          areaBasal = parseFloat(data[i].AREA_BASAL);
                        }
                        var especie = {
                          ttRodalGestion: tmpRodal,
                          tcEspecie: {
                            especieId: data[i].ESPECIE_ID,
                            codigo: data[i].CODIGO,
                            nombreCientifico: data[i].NOMBRE_CIENTIFICO
                          },
                          tcTipoInventario: tcTipoInventario,
                          sumaAltura: altura,
                          sumaDap: dap,
                          sumaVolumen: volumen,
                          conteoArbol: conteoArbol,
                          arbolh: arbolh,
                          abh: abh,
                          volh: volh,
                          arbolr: arbolr,
                          abr: abr,
                          volr: volr,
                          sumaAb: areaBasal,
                          volumenTotalr: volr,
                          parcela: data[i].PARCELA == null ? 0 : data[i].PARCELA,
                          distribucion: []
                        }
                        if (data[i].RODAL != rodalId) {
                          rodalId = row.correlativo;
                        }
                        //buscar rodal
                        for (j = 0; j < dataResumen.length; j++) {
                          if (dataResumen[j].correlativo == row.correlativo) {
                            encontrado = true;
                            row = dataResumen[j];
                            tmpRodal = angular.copy(row);
                            delete tmpRodal.especies;
                            delete tmpRodal.parcelas;
                            break;
                          }
                        }
                        //si el rodal se encuentra
                        if (encontrado) {
                          //buscar especie
                          encontrado = false;
                          for (j = 0; j < row.especies.length; j++) {
                            if (row.especies[j].tcEspecie.especieId == especie.tcEspecie.especieId
                              && row.especies[j].tcTipoInventario.tipoInventarioId == especie.tcTipoInventario.tipoInventarioId) {
                              especie = row.especies[j];
                              especie.ttRodalGestion = tmpRodal;
                              especie.sumaDap = parseFloat(especie.sumaDap) + parseFloat(data[i].DAP);
                              especie.sumaAltura = parseFloat(especie.sumaAltura) + parseFloat(data[i].ALTURA);
                              especie.sumaAb = parseFloat(especie.sumaAb) + parseFloat(areaBasal);
                              especie.sumaVolumen = parseFloat(especie.sumaVolumen) + parseFloat(volumen);
                              if (formaParcelaId == 4) { //tamaño variable
                                especie.arbolr = parseInt(especie.arbolr) + arbolr;
                                especie.arbolh = parseFloat(especie.arbolh) + parseFloat(arbolh);
                                especie.abh = parseFloat(especie.abh) + parseFloat(abh);
                                especie.abr = parseFloat(especie.abr) + parseFloat(abr);
                                especie.volh = parseFloat(especie.volh) + parseFloat(volh);
                                especie.volr = parseFloat(especie.volr) + parseFloat(volr);
                                especie.conteoArbol = parseFloat(especie.conteoArbol) + conteoArbol;
                              } else {
                                especie.conteoArbol = parseInt(especie.conteoArbol) + 1;
                              }
                              encontrado = true;
                              break;
                            }
                          }
                          var cd = null;
                          for (j = 0; j < $scope.dataClaseDiametrica.length; j++) {
                            if (parseFloat(data[i].DAP) >= $scope.dataClaseDiametrica[j].minimo && parseFloat(data[i].DAP) <= $scope.dataClaseDiametrica[j].maximo) {
                              cd = $scope.dataClaseDiametrica[j];
                              break;
                            }
                          }
                          var ok = false;
                          var okFuste = false;
                          for (j = 0; j < especie.distribucion.length; j++) {
                            if (especie.distribucion[j].tcClaseDiametrica.claseDiametricaId == cd.claseDiametricaId) {
                              especie.distribucion[j].areaBasal = parseFloat(especie.distribucion[j].areaBasal) + parseFloat(areaBasal);
                              especie.distribucion[j].volumen = parseFloat(especie.distribucion[j].volumen) + parseFloat(volumen);
                              especie.distribucion[j].areaBasal = parseFloat(especie.distribucion[j].areaBasal).toFixed(4);
                              especie.distribucion[j].volumen = parseFloat(especie.distribucion[j].volumen).toFixed(4);
                              if (formaParcelaId == 4) {
                                especie.distribucion[j].arbolr = parseInt(especie.distribucion[j].arbolr) + arbolr;
                                especie.distribucion[j].arbolh = parseFloat(especie.distribucion[j].arbolh) + parseFloat(arbolh);
                                especie.distribucion[j].abh = parseFloat(especie.distribucion[j].abh) + parseFloat(abh);
                                especie.distribucion[j].abr = parseFloat(especie.distribucion[j].abr) + parseFloat(abr);
                                especie.distribucion[j].volh = parseFloat(especie.distribucion[j].volh) + parseFloat(volh);
                                especie.distribucion[j].volr = parseFloat(especie.distribucion[j].volr) + parseFloat(volr);
                                especie.distribucion[j].conteoArbol = parseFloat(especie.distribucion[j].conteoArbol) + parseFloat(conteoArbol);
                              } else {
                                especie.distribucion[j].conteoArbol += 1;
                              }
                              ok = true;
                              var fuste = especie.distribucion[j].fuste;
                              okFuste = false;
                              if (!bndSaneamiento) {
                                for (k = 0; k < fuste.length; k++) {
                                  if (fuste[k].calidadFusteId == data[i].CALIDAD_FUSTE) {
                                    fuste[k].conteoArbol += 1;
                                    fuste[k].areaBasal = parseFloat(fuste[k].areaBasal) + parseFloat(areaBasal);
                                    fuste[k].areaBasal = parseFloat(fuste[k].areaBasal).toFixed(4);
                                    fuste[k].volumen = parseFloat(fuste[k].volumen) + parseFloat(volumen);
                                    fuste[k].volumen = parseFloat(fuste[k].volumen).toFixed(4);
                                    if (formaParcelaId == 4) {
                                      fuste[k].arbolr = parseInt(fuste[k].arbolr) + arbolr;
                                      fuste[k].arbolh = parseFloat(fuste[k].arbolh) + parseFloat(arbolh);
                                      fuste[k].abh = parseFloat(fuste[k].abh) + parseFloat(abh);
                                      fuste[k].abr = parseFloat(fuste[k].abr) + parseFloat(abr);
                                      fuste[k].volh = parseFloat(fuste[k].volh) + parseFloat(volh);
                                      fuste[k].volr = parseFloat(fuste[k].volr) + parseFloat(volr);
                                    }
                                    okFuste = true;
                                    break;
                                  }
                                }
                                if (!okFuste) {
                                  especie.distribucion[j].fuste.push(
                                    {
                                      calidadFusteId: data[i].CALIDAD_FUSTE,
                                      conteoArbol: 1,
                                      areaBasal: parseFloat(areaBasal).toFixed(4),
                                      volumen: parseFloat(volumen).toFixed(4),
                                      arbolh: arbolh,
                                      abh: abh,
                                      volh: volh,
                                      arbolr: arbolr,
                                      abr: abr,
                                      volr: volr
                                    }
                                  );
                                }
                              }
                              if (bndSaneamiento) {
                                var soloFuste = fuste.fuste;
                                var bndAgregar = true;
                                okFuste = false, okGorgojo = false, okAgente = false;
                                if (data[i].FASE_GORGOJO > 0) {
                                  okGorgojo = true;
                                } else {
                                  if (data[i].AFECCION_AGENTE > -1) {
                                    okAgente = true;
                                  } else {
                                    okFuste = true;
                                  }
                                }
                                if (okFuste) {
                                  okFuste = false;
                                  for (k = 0; k < soloFuste.length; k++) {
                                    if (soloFuste[k].calidadFusteId == data[i].CALIDAD_FUSTE) {
                                      soloFuste[k].conteoArbol += 1;
                                      soloFuste[k].areaBasal = parseFloat(soloFuste[k].areaBasal) + parseFloat(areaBasal);
                                      soloFuste[k].areaBasal = parseFloat(soloFuste[k].areaBasal).toFixed(4);
                                      soloFuste[k].volumen = parseFloat(soloFuste[k].volumen) + parseFloat(volumen);
                                      soloFuste[k].volumen = parseFloat(soloFuste[k].volumen).toFixed(4);
                                      if (formaParcelaId == 4) {
                                        soloFuste[k].arbolr = parseInt(soloFuste[k].arbolr) + arbolr;
                                        soloFuste[k].arbolh = parseFloat(soloFuste[k].arbolh) + parseFloat(arbolh);
                                        soloFuste[k].abh = parseFloat(soloFuste[k].abh) + parseFloat(abh);
                                        soloFuste[k].abr = parseFloat(soloFuste[k].abr) + parseFloat(abr);
                                        soloFuste[k].volh = parseFloat(soloFuste[k].volh) + parseFloat(volh);
                                        soloFuste[k].volr = parseFloat(soloFuste[k].volr) + parseFloat(volr);
                                      }
                                      okFuste = true;
                                      bndAgregar = false;
                                      break;
                                    }
                                  }
                                }
                                if (bndAgregar && okGorgojo) {
                                  var soloGorgojo = fuste.gorgojo;
                                  for (k = 0; k < soloGorgojo.length; k++) {
                                    if (soloGorgojo[k].faseGorgojo == data[i].FASE_GORGOJO) {
                                      soloGorgojo[k].conteoArbol += 1;
                                      soloGorgojo[k].areaBasal = parseFloat(soloGorgojo[k].areaBasal) + parseFloat(areaBasal);
                                      soloGorgojo[k].areaBasal = parseFloat(soloGorgojo[k].areaBasal).toFixed(4);
                                      soloGorgojo[k].volumen = parseFloat(soloGorgojo[k].volumen) + parseFloat(volumen);
                                      soloGorgojo[k].volumen = parseFloat(soloGorgojo[k].volumen).toFixed(4);
                                      if (formaParcelaId == 4) {
                                        soloGorgojo[k].arbolr = parseInt(soloGorgojo[k].arbolr) + arbolr;
                                        soloGorgojo[k].arbolh = parseFloat(soloGorgojo[k].arbolh) + parseFloat(arbolh);
                                        soloGorgojo[k].abh = parseFloat(soloGorgojo[k].abh) + parseFloat(abh);
                                        soloGorgojo[k].abr = parseFloat(soloGorgojo[k].abr) + parseFloat(abr);
                                        soloGorgojo[k].volh = parseFloat(soloGorgojo[k].volh) + parseFloat(volh);
                                        soloGorgojo[k].volr = parseFloat(soloGorgojo[k].volr) + parseFloat(volr);
                                      }
                                      okGorgojo = true;
                                      bndAgregar = false;
                                      break;
                                    }
                                  }
                                }
                                if (bndAgregar && okAgente) {
                                  var soloAgente = fuste.agente;
                                  for (k = 0; k < soloAgente.length; k++) {
                                    if (soloAgente[k].afeccionAgente == data[i].AFECCION_AGENTE) {
                                      soloAgente[k].conteoArbol += 1;
                                      soloAgente[k].areaBasal = parseFloat(soloAgente[k].areaBasal) + parseFloat(areaBasal);
                                      soloAgente[k].areaBasal = parseFloat(soloAgente[k].areaBasal).toFixed(4);
                                      soloAgente[k].volumen = parseFloat(soloAgente[k].volumen) + parseFloat(volumen);
                                      soloAgente[k].volumen = parseFloat(soloAgente[k].volumen).toFixed(4);
                                      if (formaParcelaId == 4) {
                                        soloAgente[k].arbolr = parseInt(soloAgente[k].arbolr) + arbolr;
                                        soloAgente[k].arbolh = parseFloat(soloAgente[k].arbolh) + parseFloat(arbolh);
                                        soloAgente[k].abh = parseFloat(soloAgente[k].abh) + parseFloat(abh);
                                        soloAgente[k].abr = parseFloat(soloAgente[k].abr) + parseFloat(abr);
                                        soloAgente[k].volh = parseFloat(soloAgente[k].volh) + parseFloat(volh);
                                        soloAgente[k].volr = parseFloat(soloAgente[k].volr) + parseFloat(volr);
                                      }
                                      okAgente = true;
                                      bndAgregar = false;
                                      break;
                                    }
                                  }
                                }
                                //validar si es gorgojo o agente
                                if (bndAgregar) {
                                  if (okAgente) {
                                    fuste.gorgojo.push(
                                      {
                                        afeccionAgente: data[i].AFECCION_AGENTE,
                                        conteoArbol: 1,
                                        areaBasal: parseFloat(areaBasal).toFixed(4),
                                        volumen: parseFloat(volumen).toFixed(4),
                                        arbolh: arbolh,
                                        abh: abh,
                                        volh: volh,
                                        arbolr: arbolr,
                                        abr: abr,
                                        volr: volr
                                      }
                                    );
                                  }
                                  if (okGorgojo) {
                                    fuste.gorgojo.push(
                                      {
                                        faseGorgojo: data[i].FASE_GORGOJO,
                                        conteoArbol: 1,
                                        areaBasal: parseFloat(areaBasal).toFixed(4),
                                        volumen: parseFloat(volumen).toFixed(4),
                                        arbolh: arbolh,
                                        abh: abh,
                                        volh: volh,
                                        arbolr: arbolr,
                                        abr: abr,
                                        volr: volr
                                      }
                                    );
                                  }
                                  if (okFuste) {
                                    fuste.fuste.push(
                                      {
                                        calidadFusteId: data[i].CALIDAD_FUSTE,
                                        conteoArbol: 1,
                                        areaBasal: parseFloat(areaBasal).toFixed(4),
                                        volumen: parseFloat(volumen).toFixed(4),
                                        arbolh: arbolh,
                                        abh: abh,
                                        volh: volh,
                                        arbolr: arbolr,
                                        abr: abr,
                                        volr: volr
                                      }
                                    );
                                  }
                                }
                              }
                              break;
                            }
                          }
                          if (!ok) {
                            var fusteRow = {
                              calidadFusteId: data[i].CALIDAD_FUSTE,
                              conteoArbol: 1,
                              areaBasal: parseFloat(areaBasal).toFixed(4),
                              volumen: parseFloat(volumen).toFixed(4),
                              arbolh: arbolh,
                              abh: abh,
                              volh: volh,
                              arbolr: arbolr,
                              abr: abr,
                              volr: volr
                            };
                            var hayFase = false;
                            if (bndSaneamiento) {
                              fusteRow = {
                                gorgojo: [],
                                agente: [],
                                fuste: []
                              };
                              if (data[i].FASE_GORGOJO == 0) {
                                if (data[i].AFECCION_AGENTE > -1) {
                                  hayFase = true;
                                  fusteRow.gorgojo.push(
                                    {
                                      afeccionAgente: data[i].AFECCION_AGENTE,
                                      conteoArbol: 1,
                                      areaBasal: parseFloat(areaBasal).toFixed(4),
                                      volumen: parseFloat(volumen).toFixed(4),
                                      arbolh: arbolh,
                                      abh: abh,
                                      volh: volh,
                                      arbolr: arbolr,
                                      abr: abr,
                                      volr: volr
                                    }
                                  );
                                }
                              } else {
                                hayFase = true;
                                fusteRow.gorgojo.push(
                                  {
                                    faseGorgojo: data[i].FASE_GORGOJO,
                                    conteoArbol: 1,
                                    areaBasal: parseFloat(areaBasal).toFixed(4),
                                    volumen: parseFloat(volumen).toFixed(4),
                                    arbolh: arbolh,
                                    abh: abh,
                                    volh: volh,
                                    arbolr: arbolr,
                                    abr: abr,
                                    volr: volr
                                  }
                                );
                              }
                              if (!hayFase) {
                                fusteRow.fuste.push(
                                  {
                                    calidadFusteId: data[i].CALIDAD_FUSTE,
                                    conteoArbol: 1,
                                    areaBasal: parseFloat(areaBasal).toFixed(4),
                                    volumen: parseFloat(volumen).toFixed(4),
                                    arbolh: arbolh,
                                    abh: abh,
                                    volh: volh,
                                    arbolr: arbolr,
                                    abr: abr,
                                    volr: volr
                                  }
                                );
                              }
                            }
                            var rowTmp = [];
                            if (bndSaneamiento) {
                              rowTmp = fusteRow;
                            } else {
                              rowTmp = [fusteRow];
                            }
                            var tmpDistribucion = {
                              diametricaEspecieId: 0,
                              estadoId: 1,
                              tcClaseDiametrica: cd,
                              conteoArbol: 1,
                              areaBasal: parseFloat(areaBasal).toFixed(4),
                              volumen: parseFloat(volumen).toFixed(4),
                              arbolrExtraer: 0,
                              abrExtraer: 0,
                              volrExtraer: 0,
                              arbolh: arbolh,
                              abh: abh,
                              volh: volh,
                              arbolr: arbolr,
                              abr: abr,
                              volr: volr,
                              fuste: rowTmp
                            }
                            especie.distribucion.push(tmpDistribucion);
                          }
                          if (!encontrado) {
                            row.especies.push(especie);
                          }
                          //buscar los datos parcelas
                          if (tcTipoInventario == null || tcTipoInventario.tipoInventarioId != 1) {
                            if ((data[i].PARCELA != null && data[i].TAMANIO_PARCELA != null) || (data[i].PARCELA != null && formaParcelaId == 4)) {
                              encontrado = false;
                              rodalParcela = row.parcelas;
                              for (j = 0; j < rodalParcela.length; j++) {
                                if (rodalParcela[j].parcela == data[i].PARCELA) {
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                rodalParcela.push(
                                  {
                                    parcela: data[i].PARCELA,
                                    tamanioParcela: data[i].TAMANIO_PARCELA == null ? 0 : data[i].TAMANIO_PARCELA,
                                    ttRodalGestion: tmpRodal
                                  }
                                );
                              }
                              row.totalParcelas = rodalParcela[j].parcela.length;
                              if (parseFloat(row.tamanioParcela) <= 0) {
                                if (formaParcelaId == 4) {
                                  row.tamanioParcela = 0;
                                } else {
                                  row.tamanioParcela = parseFloat(data[i].TAMANIO_PARCELA);
                                }
                              }
                            } else {
                              if (parseFloat(row.tamanioParcela) == null) {
                                row.totalParcelas = 0;
                                row.tamanioParcela = 0;
                              }
                            }
                          } else {
                            if (parseFloat(row.tamanioParcela) == null) {
                              row.totalParcelas = 0;
                              row.tamanioParcela = 0;
                            }
                          }
                        } else { //si el rodal es nuevo
                          var cd = null;
                          for (j = 0; j < $scope.dataClaseDiametrica.length; j++) {
                            if (parseFloat(data[i].DAP) >= $scope.dataClaseDiametrica[j].minimo && parseFloat(data[i].DAP) <= $scope.dataClaseDiametrica[j].maximo) {
                              cd = $scope.dataClaseDiametrica[j];
                              break;
                            }
                          }
                          var fusteRow = {
                            calidadFusteId: data[i].CALIDAD_FUSTE,
                            conteoArbol: 1,
                            areaBasal: parseFloat(areaBasal).toFixed(4),
                            volumen: parseFloat(volumen).toFixed(4),
                            arbolh: arbolh,
                            abh: abh,
                            volh: volh,
                            arbolr: arbolr,
                            abr: abr,
                            volr: volr
                          };
                          if (bndSaneamiento) {
                            fusteRow = {
                              gorgojo: [],
                              agente: [],
                              fuste: []
                            };
                            var hayFase = false;
                            if (data[i].FASE_GORGOJO == 0) {
                              if (data[i].AFECCION_AGENTE > -1) {
                                hayFase = true;
                                fusteRow.gorgojo.push(
                                  {
                                    afeccionAgente: data[i].AFECCION_AGENTE,
                                    conteoArbol: 1,
                                    areaBasal: parseFloat(areaBasal).toFixed(4),
                                    volumen: parseFloat(volumen).toFixed(4),
                                    arbolh: arbolh,
                                    abh: abh,
                                    volh: volh,
                                    arbolr: arbolr,
                                    abr: abr,
                                    volr: volr
                                  }
                                );
                              }
                            } else {
                              hayFase = true;
                              fusteRow.gorgojo.push(
                                {
                                  faseGorgojo: data[i].FASE_GORGOJO,
                                  conteoArbol: 1,
                                  areaBasal: parseFloat(areaBasal).toFixed(4),
                                  volumen: parseFloat(volumen).toFixed(4),
                                  arbolh: arbolh,
                                  abh: abh,
                                  volh: volh,
                                  arbolr: arbolr,
                                  abr: abr,
                                  volr: volr
                                }
                              );
                            }
                            if (!hayFase) {
                              fusteRow.fuste.push(
                                {
                                  calidadFusteId: data[i].CALIDAD_FUSTE,
                                  conteoArbol: 1,
                                  areaBasal: parseFloat(areaBasal).toFixed(4),
                                  volumen: parseFloat(volumen).toFixed(4),
                                  arbolh: arbolh,
                                  abh: abh,
                                  volh: volh,
                                  arbolr: arbolr,
                                  abr: abr,
                                  volr: volr
                                }
                              );
                            }
                          }
                          var rowTmp = [];
                          if (bndSaneamiento) {
                            rowTmp = fusteRow;
                          } else {
                            rowTmp = [fusteRow];
                          }
                          var tmpDistribucion = {
                            diametricaEspecieId: 0,
                            estadoId: 1,
                            tcClaseDiametrica: cd,
                            tcTratamientoSilvicultural: null,
                            conteoArbol: 1,
                            areaBasal: parseFloat(areaBasal).toFixed(4),
                            volumen: parseFloat(volumen).toFixed(4),
                            arbolrExtraer: 0,
                            abrExtraer: 0,
                            volrExtraer: 0,
                            arbolh: arbolh,
                            abh: abh,
                            volh: volh,
                            arbolr: arbolr,
                            abr: abr,
                            volr: volr,
                            fuste: rowTmp
                          }
                          especie.distribucion.push(tmpDistribucion);
                          row.especies.push(especie);
                          if (tcTipoInventario == null || tcTipoInventario.tipoInventarioId != 1) {
                            if ((data[i].PARCELA != null && data[i].TAMANIO_PARCELA != null)
                              || (data[i].PARCELA != null && formaParcelaId == 4)) {
                              itemParcela = {
                                ttRodalGestion: tmpRodal,
                                parcela: data[i].PARCELA,
                                tamanioParcela: data[i].TAMANIO_PARCELA == null ? 0 : data[i].TAMANIO_PARCELA
                              }
                              row.parcelas.push(itemParcela);
                            }
                          }
                          dataResumen.push(row); //agregar rodal
                        }
                      } //fin lectura de informacion
                      //obtener promedios por rodal
                      var valor = 0;
                      $scope.data.ttInventarioGestion.areaMuestreada = 0;
                      tcTipoInventario = $scope.data.ttInventarioGestion.tcTipoInventario;
                      for (i = 0; i < dataResumen.length; i++) {
                        if (tcTipoInventario == null || tcTipoInventario.tipoInventarioId != 1) {
                          if (dataResumen[i].totalParcelas == undefined) {
                            dataResumen[i].totalParcelas = 0;
                          }
                          if (dataResumen[i].parcelas != null) {
                            dataResumen[i].totalParcelas = dataResumen[i].parcelas.length;
                          }
                          if (parseInt(dataResumen[i].totalParcelas) > 0 && parseInt(dataResumen[i].tamanioParcela) <= 0) {
                            if (formaParcelaId == 4) {
                              dataResumen[i].tamanioParcela = 0;
                            } else {
                              dataResumen[i].tamanioParcela = dataResumen[i].parcelas[0].tamanioParcela;
                            }
                          }
                          if (dataResumen[i].tamanioParcela > 0 && dataResumen[i].totalParcelas > 0) {
                            //dataResumen[i].tamanioParcela = 1;
                            //area muestreada rodal/ha = (numero de parcelas * area de la parcela)/10000
                            dataResumen[i].areaMuestreada = (dataResumen[i].totalParcelas * parseFloat(dataResumen[i].tamanioParcela)) / 10000;
                            //se suma el area muestreada de cada rodal
                            $scope.data.ttInventarioGestion.areaMuestreada = parseFloat($scope.data.ttInventarioGestion.areaMuestreada) + dataResumen[i].areaMuestreada;
                          } else {
                            dataResumen[i].areaMuestreada = dataResumen[i].area;
                          }
                        } else {
                          dataResumen[i].areaMuestreada = dataResumen[i].area;
                        }
                        for (var j = 0; j < dataResumen[i].especies.length; j++) {
                          dataResumen[i].especies[j].sumaAb = parseFloat(dataResumen[i].especies[j].sumaAb).toFixed(2);
                          dataResumen[i].especies[j].sumaVolumen = parseFloat(dataResumen[i].especies[j].sumaVolumen).toFixed(2);
                          valor = parseFloat(dataResumen[i].especies[j].sumaAltura) / parseInt(dataResumen[i].especies[j].conteoArbol);
                          dataResumen[i].especies[j].alturaPromedio = parseFloat(valor).toFixed(2);
                          valor = parseFloat(dataResumen[i].especies[j].sumaDap) / parseInt(dataResumen[i].especies[j].conteoArbol);
                          dataResumen[i].especies[j].dapPromedio = parseFloat(valor).toFixed(2);
                          if ((parseInt(dataResumen[i].totalParcelas) > 0 && parseInt(dataResumen[i].tamanioParcela) > 0)
                            || (parseInt(dataResumen[i].totalParcelas) > 0 && formaParcelaId == 4)) {
                            if (formaParcelaId != 4) {
                              //Arb/hectarea = conteo de arboles de la de la especie por el rodal * 1 / area muestrada
                              valor = dataResumen[i].especies[j].conteoArbol / dataResumen[i].areaMuestreada;
                              dataResumen[i].especies[j].arbolh = Math.round(valor);
                              //Ab/hectarea = Suma del ab de los arboles de la de la especie por el rodal * 1 / area muestrada
                              valor = dataResumen[i].especies[j].sumaAb / dataResumen[i].areaMuestreada;
                              dataResumen[i].especies[j].abh = parseFloat(valor).toFixed(2);
                              valor = dataResumen[i].especies[j].sumaVolumen / dataResumen[i].areaMuestreada;
                              dataResumen[i].especies[j].volh = parseFloat(valor).toFixed(2);
                            }
                          } else {
                            valor = parseFloat(dataResumen[i].especies[j].sumaAb).toFixed(2);
                            dataResumen[i].especies[j].sumaAb = valor;
                            valor = parseFloat(dataResumen[i].especies[j].sumaVolumen).toFixed(2);
                            dataResumen[i].especies[j].volr = valor;
                            dataResumen[i].especies[j].sumaVolumen = parseFloat(valor).toFixed(2);
                          }
                        }
                      }
                      $scope.data.ttInventarioGestion.diametro = diametroMinimo;
                      return dataResumen;
                    }

                    $scope.continuarCargarDatosInventario = function () {
                      var data = $scope.gridOptionsExcel.data;
                      var dataEspecie = $scope.obtenerCodigosEspecie(data);
                      var dataResumen = $scope.obtenerResumenInventario(data);
                      var formaParcelaId = 0;
                      if ($scope.data.ttInventarioGestion.tcFormaParcela != null) {
                        formaParcelaId = $scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId;
                      }
                      var conteo = 0, conteoParcela = 0, areaMuestreada = 0;
                      var i, j, k, v;
                      for (i = 0; i < dataEspecie.length; i++) {
                        for (j = 0; j < dataResumen.length; j++) {
                          if (dataResumen[j].correlativo == dataEspecie[i].correlativo) {
                            dataResumen[j].area == dataEspecie[i].area;
                            break;
                          }
                        }
                      }
                      var areaMuestreo = 0;
                      for (i = 0; i < dataResumen.length; i++) {
                        if (dataResumen[i].parcelas == null) {
                          dataResumen[i].parcelas = [];
                        }
                        if (dataResumen[i].totalParcelas == undefined) {
                          dataResumen[i].totalParcelas = dataResumen[i].parcelas.length;
                        }
                        if (formaParcelaId == 4) { //tamaño variable
                          areaMuestreada = 0;
                        } else {
                          v = parseInt(dataResumen[i].tamanioParcela) * parseInt(dataResumen[i].totalParcelas);
                          areaMuestreada += v;
                        }
                        conteoParcela += parseInt(dataResumen[i].totalParcelas);
                        conteo++;
                        for (j = 0; j < dataResumen[i].especies.length; j++) {
                          for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                            dataResumen[i].especies[j].distribucion[k].fuste = JSON.stringify(dataResumen[i].especies[j].distribucion[k].fuste);
                          }
                        }
                        if (conteoParcela > 0) {
                          areaMuestreo += parseFloat(dataResumen[i].area);
                        }
                      }
                      $scope.data.ttInventarioGestion.areaMuestreada = parseFloat(areaMuestreada).toFixed(2);
                      var tmpTP = areaMuestreada / conteoParcela;
                      $scope.data.ttInventarioGestion.tamanioParcela = parseFloat(tmpTP).toFixed(2);
                      $scope.data.ttInventarioGestion.totalRodales = conteo;
                      if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 1) {
                        if (formaParcelaId == 4) {
                          $scope.data.ttInventarioGestion.intensidadMuestreo = 0;
                        } else {
                          areaMuestreada = areaMuestreada / 10000;
                          areaMuestreada = areaMuestreada / parseFloat(areaMuestreo);
                          areaMuestreada = areaMuestreada * 100;
                          $scope.data.ttInventarioGestion.intensidadMuestreo = parseFloat(areaMuestreada).toFixed(2);
                        }
                      }
                      if ($scope.data.ttInventarioGestion.tamanioParcela == null || isNaN($scope.data.ttInventarioGestion.tamanioParcela)) {
                        $scope.data.ttInventarioGestion.tamanioParcela = 0;
                      }
                      var tmp = $scope.data;
                      var ttInventarioGestion = angular.copy(tmp.ttInventarioGestion);
                      ttInventarioGestion.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      ttInventarioGestion.data = JSON.stringify(data);
                      ttInventarioGestion.brinzal = JSON.stringify(ttInventarioGestion.brinzal);
                      ttInventarioGestion.latizal = JSON.stringify(ttInventarioGestion.latizal);
                      if (ttInventarioGestion.areaMuestreada == null) {
                        ttInventarioGestion.areaMuestreada = 0;
                      }
                      if (ttInventarioGestion.intensidadMuestreo == null) {
                        ttInventarioGestion.intensidadMuestreo = 0;
                      }
                      if (formaParcelaId == 4) {
                        var bndSaneamiento = false;
                        if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                          bndSaneamiento = true;
                        }
                        //redondear datos
                        for (var i = 0; i < dataResumen.length; i++) {
                          dataResumen[i].tamanioParcela = 0;
                          var especies = dataResumen[i].especies;
                          for (var j = 0; j < especies.length; j++) {
                            especies[j].conteoArbol = parseInt(especies[j].conteoArbol);
                            especies[j].arbolh = parseInt(especies[j].arbolh);
                            especies[j].arbolr = parseInt(especies[j].arbolr);
                            especies[j].abh = parseFloat(especies[j].abh).toFixed(4);
                            especies[j].abr = parseFloat(especies[j].abr).toFixed(4);
                            especies[j].volh = parseFloat(especies[j].volh).toFixed(4);
                            especies[j].volr = parseFloat(especies[j].volr).toFixed(4);
                            var distribuciones = especies[j].distribucion;
                            for (var k = 0; k < distribuciones.length; k++) {
                              distribuciones[k].conteoArbol = parseInt(distribuciones[k].conteoArbol);
                              distribuciones[k].arbolh = parseInt(distribuciones[k].arbolh);
                              distribuciones[k].arbolr = parseInt(distribuciones[k].arbolr);
                              distribuciones[k].abh = parseFloat(distribuciones[k].abh).toFixed(4);
                              distribuciones[k].abr = parseFloat(distribuciones[k].abr).toFixed(4);
                              distribuciones[k].volh = parseFloat(distribuciones[k].volh).toFixed(4);
                              distribuciones[k].volr = parseFloat(distribuciones[k].volr).toFixed(4);
                              var fuste = distribuciones[k].fuste;
                              if (bndSaneamiento) {
                                var soloFuste = fuste.fuste;
                                for (var l = 0; l < fuste.length; l++) {
                                  soloFuste[l].conteoArbol = parseInt(soloFuste[l].conteoArbol);
                                  soloFuste[l].arbolh = parseInt(soloFuste[l].arbolh);
                                  soloFuste[l].arbolr = parseInt(soloFuste[l].arbolr);
                                  soloFuste[l].abh = parseFloat(soloFuste[l].abh).toFixed(4);
                                  soloFuste[l].abr = parseFloat(soloFuste[l].abr).toFixed(4);
                                  soloFuste[l].volh = parseFloat(soloFuste[l].volh).toFixed(4);
                                  soloFuste[l].volr = parseFloat(soloFuste[l].volr).toFixed(4);
                                }
                                var soloGorgojo = fuste.gorgojo;
                                for (var l = 0; l < fuste.length; l++) {
                                  soloGorgojo[l].conteoArbol = parseInt(soloGorgojo[l].conteoArbol);
                                  soloGorgojo[l].arbolh = parseInt(soloGorgojo[l].arbolh);
                                  soloGorgojo[l].arbolr = parseInt(soloGorgojo[l].arbolr);
                                  soloGorgojo[l].abh = parseFloat(soloGorgojo[l].abh).toFixed(4);
                                  soloGorgojo[l].abr = parseFloat(soloGorgojo[l].abr).toFixed(4);
                                  soloGorgojo[l].volh = parseFloat(soloGorgojo[l].volh).toFixed(4);
                                  soloGorgojo[l].volr = parseFloat(soloGorgojo[l].volr).toFixed(4);
                                }
                                var soloAgente = fuste.agente;
                                for (var l = 0; l < fuste.length; l++) {
                                  soloAgente[l].conteoArbol = parseInt(soloAgente[l].conteoArbol);
                                  soloAgente[l].arbolh = parseInt(fuste[l].arbolh);
                                  soloAgente[l].arbolr = parseInt(soloAgente[l].arbolr);
                                  soloAgente[l].abh = parseFloat(soloAgente[l].abh).toFixed(4);
                                  soloAgente[l].abr = parseFloat(soloAgente[l].abr).toFixed(4);
                                  soloAgente[l].volh = parseFloat(soloAgente[l].volh).toFixed(4);
                                  soloAgente[l].volr = parseFloat(soloAgente[l].volr).toFixed(4);
                                }
                              } else {
                                for (var l = 0; l < fuste.length; l++) {
                                  fuste[l].conteoArbol = parseInt(fuste[l].conteoArbol);
                                  fuste[l].arbolh = parseInt(fuste[l].arbolh);
                                  fuste[l].arbolr = parseInt(fuste[l].arbolr);
                                  fuste[l].abh = parseFloat(fuste[l].abh).toFixed(4);
                                  fuste[l].abr = parseFloat(fuste[l].abr).toFixed(4);
                                  fuste[l].volh = parseFloat(fuste[l].volh).toFixed(4);
                                  fuste[l].volr = parseFloat(fuste[l].volr).toFixed(4);
                                }
                              }
                            }
                          }
                          var parcelas = dataResumen[i].parcelas;
                          for (var j = 0; j < parcelas.length; j++) {
                            parcelas[j].tamanioParcela = 0;
                          }
                        }
                      }
                      var objInventario = {
                        ttGestion: {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        },
                        ttInventarioGestion: ttInventarioGestion,
                        rodal: dataResumen,
                        especie: dataEspecie
                      }
                      gestionService.agregarDatosInventario(objInventario).then(function (response) {
                        if (response.status == 'OK') {
                          $state.reload();
                          $scope.panel.openResumen = true;
                          $scope.panel.resumen = false;
                          $scope.panel.regresion = false;
                          objInventario = response.data[0];
                          ttInventarioGestion = objInventario.ttInventarioGestion;
                          tmp.rodal = objInventario.rodal;
                          tmp.ttInventarioGestion.data = JSON.parse(ttInventarioGestion.data);
                          ttInventarioGestion.brinzal = JSON.parse(ttInventarioGestion.brinzal);
                          ttInventarioGestion.latizal = JSON.parse(ttInventarioGestion.latizal);
                          $scope.panel.codigo = false;
                          $scope.gridOptionsCodigoEspecie.data = dataEspecie;
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.validarInformacionInventario = function (isValid) {
                      if (isValid) {
                        if ($scope.gridOptionsExcel.data.length <= 0) {
                          toastr.error('Debe cargar el archivo con los datos del inventario');
                          isValid = false;
                        }
                      }
                      if (isValid) {
                        if ($scope.data.ultimoPanel > 4) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro de guardar la información. Si ya ha planificado, esta acción borrará todos los datos vinculados y tendrá que volver a planificar en las pestañas de: Inventario forestal y Planificación del manejo?",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                $scope.continuarCargarDatosInventario();
                              }
                            });
                        } else {
                          $scope.continuarCargarDatosInventario();
                        }
                      }
                    }

                    $scope.validarInformacionRegeneracion = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var ttInventarioGestion = angular.copy(tmp.ttInventarioGestion);
                        delete ttInventarioGestion.data;
                        delete ttInventarioGestion.inventarioRodal;
                        delete ttInventarioGestion.distribucionRodal;
                        delete ttInventarioGestion.analisisEstadistico;
                        delete ttInventarioGestion.noMaderable;
                        ttInventarioGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        ttInventarioGestion.brinzal = JSON.stringify(ttInventarioGestion.brinzal);
                        ttInventarioGestion.latizal = JSON.stringify(ttInventarioGestion.latizal);
                        gestionService.actualizarRegeneracion(ttInventarioGestion).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.panel.openResumen = true;
                            $scope.panel.resumen = false;
                            $scope.panel.regresion = false;
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.setNombreArchivo = function (tmpName) {
                      $scope.nombreArchivo = tmpName;
                    }

                    $scope.setClaseDesarrollo = function (item, rodal) {
                      if (rodal.abr == 0) {
                        toastr.error('Primero agregue el área del rodal y la pendiente promedio');
                        rodal.tcClaseDesarrollo = null;
                        return;
                      }
                      if (item.claseDesarrolloId == 7 && rodal.abh >= 4) {
                        toastr.error('Para árboles fuera de bosque el área basal por hectarea debe ser menor a 4 m²');
                        rodal.tcClaseDesarrollo = null;
                        return;
                      }
                      if (item.claseDesarrolloId == 7
                        && $scope.data.tcTipoGestion.tipoGestionId == 1) {
                        if ($scope.data.estadoId == null) {
                          $scope.data.estadoId = 1;
                        }
                        if ($scope.data.rodal.length <= 1 && $scope.data.estadoId != 6) {
                          toastr.error('Su gestión es Licencia de Aprovechamiento Forestal con Fines de Producción y solo tiene un rodal, debe existir al menos un rodal con cobertura de bosque');
                          rodal.tcClaseDesarrollo = null;
                          return;
                        } else {
                          var fueraDeBosque = 0;
                          var rodales = $scope.data.rodales;
                          for (var i = 0; i < rodales.length; i++) {
                            if (rodales[i].tcClaseDesarrollo != null) {
                              if (rodales[i].tcClaseDesarrollo.claseDesarrolloId == 7) {
                                fueraDeBosque++;
                              }
                            }
                          }
                          if (fueraDeBosque >= rodales.length && $scope.data.estadoId != 6) {
                            toastr.error('Su gestión es Licencia de Aprovechamiento Forestal con Fines de Producción y solo tiene un rodal, debe existir al menos un rodal con cobertura de bosque');
                            rodal.tcClaseDesarrollo = null;
                            return;
                          }
                        }
                      }
                      if (rodal.tcTipoCobertura == null) {
                        rodal.tcTipoCobertura = {
                          tipoCoberturaId: 1
                        };
                      }
                      if (item.claseDesarrolloId == 7) {
                        rodal.tcTipoCobertura.tipoCoberturaId = 3;
                      } else {
                        rodal.tcTipoCobertura.tipoCoberturaId = 1;
                      }
                      rodal.tcClaseDesarrollo.claseDesarrolloDesc = item.claseDesarrolloDesc;
                      rodal.tcClaseDesarrollo.codigoClase = item.codigoClase;
                    }

                    $scope.setTipoInventarioEspecie = function (item, rodal, especie) {
                      if (rodal.area != null) {
                        if (rodal.area > 0) {
                          $scope.calcularPromedioEspecie(rodal, especie);
                        } else {
                          toastr.error('Debe indicar un área mayor a cero');
                          especie.tcTipoInventario = null;
                        }
                      } else {
                        toastr.error('Debe indicar primero el área del rodal');
                        especie.tcTipoInventario = null;
                      }
                    }

                    $scope.getAreaUsadoInventario = function () {
                      var total = 0;
                      for (var i = 0; i < $scope.data.rodal.length; i++) {
                        total += parseFloat($scope.data.rodal[i].area);
                      }
                      return total;
                    }

                    $scope.calcularPromedios = function (rodal) {
                      if (rodal.area == null) {
                        toastr.error('Debe indicar el área del rodal');
                        return;
                      }
                      for (var i = 0; i < rodal.especies.length; i++) {
                        if (rodal.especies[i].tcTipoInventario == null) {
                          return;
                        }
                      }
                      if (parseFloat(rodal.area) > 0) {
                        rodal.alturaPromedio = 0;
                        rodal.dapPromedio = 0;
                        rodal.conteoArbol = 0;
                        rodal.arbolh = 0;
                        rodal.abh = 0;
                        rodal.volh = 0;
                        rodal.arbolr = 0;
                        rodal.abr = 0;
                        rodal.volr = 0;
                        rodal.cantidad = 0;
                        var formaParcelaId = 0;
                        if ($scope.data.ttInventarioGestion.tcFormaParcela != null) {
                          formaParcelaId = $scope.data.ttInventarioGestion.tcFormaParcela.formaParcelaId;
                        }
                        for (var i = 0; i < rodal.especies.length; i++) {
                          rodal.especies[i].arbolr = rodal.especies[i].conteoArbol;
                          rodal.especies[i].abr = rodal.especies[i].sumaAb;
                          rodal.especies[i].volr = rodal.especies[i].sumaVolumen;
                          if (formaParcelaId != 4) {
                            var tipoInventarioId = parseInt(rodal.especies[i].tcTipoInventario.tipoInventarioId);
                            if (tipoInventarioId == 1) { //censo
                              valor = parseInt(rodal.especies[i].arbolr) / parseFloat(rodal.area);
                              rodal.especies[i].arbolh = Math.round(valor);
                              valor = parseFloat(rodal.especies[i].abr) / parseFloat(rodal.area);
                              rodal.especies[i].abh = parseFloat(valor).toFixed(2);
                              valor = parseFloat(rodal.especies[i].sumaVolumen) / parseFloat(rodal.area);
                              rodal.especies[i].volh = parseFloat(valor).toFixed(2);
                            } else {
                              valor = parseFloat(rodal.area) * parseInt(rodal.especies[i].arbolr);
                              valor = valor / parseFloat(rodal.areaMuestreada);
                              rodal.especies[i].arbolr = Math.round(valor);
                              valor = parseFloat(rodal.especies[i].abr) * parseFloat(rodal.area);
                              valor = valor / parseFloat(rodal.areaMuestreada);
                              rodal.especies[i].abr = parseFloat(valor).toFixed(2);
                              valor = parseFloat(rodal.especies[i].volr) * parseFloat(rodal.area);
                              valor = valor / parseFloat(rodal.areaMuestreada);
                              rodal.especies[i].volr = parseFloat(valor).toFixed(2);
                            }
                          }
                          rodal.alturaPromedio += parseFloat(rodal.especies[i].alturaPromedio);
                          rodal.dapPromedio += parseFloat(rodal.especies[i].dapPromedio);
                          rodal.conteoArbol += parseInt(rodal.especies[i].conteoArbol);
                          rodal.abh += parseFloat(rodal.especies[i].abh);
                          rodal.volh += parseFloat(rodal.especies[i].volh);
                          rodal.arbolh += parseInt(rodal.especies[i].arbolh);
                          rodal.cantidad++;
                          //datos para calidad de fuste
                          if (formaParcelaId != 4) {
                            var bndSaneamiento = false;
                            if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                              bndSaneamiento = true;
                            }
                            var totales = {
                              abr: 0,
                              volr: 0,
                              arbolr: 0
                            }
                            for (var j = 0; j < rodal.especies[i].distribucion.length; j++) {
                              if (tipoInventarioId == 2) {
                                valor = parseFloat(rodal.especies[i].distribucion[j].areaBasal).toFixed(4) / parseFloat(rodal.areaMuestreada);
                                valor = valor * parseFloat(rodal.area);
                                rodal.especies[i].distribucion[j].abr = parseFloat(valor).toFixed(4);
                                valor = parseFloat(rodal.especies[i].distribucion[j].volumen).toFixed(4) / parseFloat(rodal.areaMuestreada);
                                valor = valor * parseFloat(rodal.area);
                                rodal.especies[i].distribucion[j].volr = parseFloat(valor).toFixed(4);
                                valor = parseInt(rodal.especies[i].distribucion[j].conteoArbol) / parseFloat(rodal.areaMuestreada);
                                valor = valor * parseFloat(rodal.area);
                                rodal.especies[i].distribucion[j].arbolr = Math.round(valor);
                              } else {
                                rodal.especies[i].distribucion[j].areaBasal = parseFloat(rodal.especies[i].distribucion[j].areaBasal).toFixed(4);
                                rodal.especies[i].distribucion[j].volumen = parseFloat(rodal.especies[i].distribucion[j].volumen).toFixed(4);
                                rodal.especies[i].distribucion[j].arbolr = rodal.especies[i].distribucion[j].conteoArbol;
                                rodal.especies[i].distribucion[j].abr = rodal.especies[i].distribucion[j].areaBasal;
                                rodal.especies[i].distribucion[j].volr = rodal.especies[i].distribucion[j].volumen;
                              }
                              var tmpFuste = rodal.especies[i].distribucion[j].fuste;
                              if (bndSaneamiento) {
                                var soloFuste = tmpFuste.fuste;
                                for (var k = 0; k < soloFuste.length; k++) {
                                  var fuste = soloFuste[k];
                                  fuste.arbolr = fuste.conteoArbol;
                                  fuste.abr = fuste.areaBasal;
                                  fuste.volr = fuste.volumen;
                                  if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                    if (parseInt(fuste.conteoArbol) == 0) {
                                      fuste.abr = 0;
                                    }
                                    if (tipoInventarioId == 2) {
                                      valor = parseFloat(fuste.areaBasal).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.abr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(fuste.volumen).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.volr = parseFloat(valor).toFixed(2);
                                      valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.arbolr = Math.round(valor);
                                    }
                                  }
                                }
                                var soloGorgojo = tmpFuste.gorgojo;
                                for (var k = 0; k < soloGorgojo.length; k++) {
                                  var fuste = soloGorgojo[k];
                                  fuste.arbolr = fuste.conteoArbol;
                                  fuste.abr = fuste.areaBasal;
                                  fuste.volr = fuste.volumen;
                                  if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                    if (parseInt(fuste.conteoArbol) == 0) {
                                      fuste.abr = 0;
                                    }
                                    if (tipoInventarioId == 2) {
                                      valor = parseFloat(fuste.areaBasal).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.abr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(fuste.volumen).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.volr = parseFloat(valor).toFixed(2);
                                      valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.arbolr = Math.round(valor);
                                    }
                                  }
                                }
                                var soloAgente = tmpFuste.gorgojo;
                                for (var k = 0; k < soloAgente.length; k++) {
                                  var fuste = soloAgente[k];
                                  fuste.arbolr = fuste.conteoArbol;
                                  fuste.abr = fuste.areaBasal;
                                  fuste.volr = fuste.volumen;
                                  if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                    if (parseInt(fuste.conteoArbol) == 0) {
                                      fuste.abr = 0;
                                    }
                                    if (tipoInventarioId == 2) {
                                      valor = parseFloat(fuste.areaBasal).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.abr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(fuste.volumen).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.volr = parseFloat(valor).toFixed(2);
                                      valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.arbolr = Math.round(valor);
                                    }
                                  }
                                }
                              } else {
                                for (var k = 0; k < tmpFuste.length; k++) {
                                  var fuste = tmpFuste[k];
                                  fuste.arbolr = fuste.conteoArbol;
                                  fuste.abr = fuste.areaBasal;
                                  fuste.volr = fuste.volumen;
                                  if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                    if (parseInt(fuste.conteoArbol) == 0) {
                                      fuste.abr = 0;
                                    }
                                    if (tipoInventarioId == 2) {
                                      valor = parseFloat(fuste.areaBasal).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.abr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(fuste.volumen).toFixed(2) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.volr = parseFloat(valor).toFixed(2);
                                      valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      fuste.arbolr = Math.round(valor);
                                    }
                                  }
                                }
                              }
                              totales.arbolr += parseInt(rodal.especies[i].distribucion[j].arbolr);
                              totales.abr += parseFloat(rodal.especies[i].distribucion[j].abr);
                              totales.volr += parseFloat(rodal.especies[i].distribucion[j].volr);
                            }
                            rodal.especies[i].arbolr = parseInt(totales.arbolr);
                            rodal.especies[i].abr = parseFloat(totales.abr).toFixed(4);
                            rodal.especies[i].volr = parseFloat(totales.volr).toFixed(4);
                          }
                          rodal.arbolr += parseInt(rodal.especies[i].arbolr);
                          rodal.abr += parseFloat(rodal.especies[i].abr);
                          rodal.volr += parseFloat(rodal.especies[i].volr);
                        }
                        rodal.alturaPromedio = parseFloat(parseFloat(rodal.alturaPromedio) / rodal.cantidad).toFixed(2);
                        rodal.dapPromedio = parseFloat(parseFloat(rodal.dapPromedio) / rodal.cantidad).toFixed(2);
                        rodal.abh = parseFloat(rodal.abh).toFixed(4);
                        rodal.volh = parseFloat(rodal.volh).toFixed(4);
                        rodal.abr = parseFloat(rodal.abr).toFixed(4);
                        rodal.volr = parseFloat(rodal.volr).toFixed(4);
                      } else {
                        toastr.error('El área no puede ser menor o igual a cero');
                        rodal.area = null;
                      }
                    }

                    $scope.calcularPromedioEspecie = function (rodal, especie) {
                      if (especie.tcTipoInventario == null || rodal.area == null) {
                        return;
                      }
                      if (rodal.area != null) {
                        if (parseFloat(rodal.area) > 0) {
                          especie.arbolr = especie.conteoArbol;
                          especie.abr = especie.sumaAb;
                          especie.volr = especie.sumaVolumen;
                          var tipoInventarioId = especie.tcTipoInventario.tipoInventarioId;
                          var continuar = false;
                          if (tipoInventarioId == 1) { //censo
                            var valor = 0;
                            valor = parseInt(especie.arbolr) / parseFloat(rodal.area);
                            especie.arbolh = parseInt(valor);
                            valor = parseFloat(especie.abr) / parseFloat(rodal.area);
                            especie.abh = parseFloat(valor).toFixed(2);
                            valor = parseFloat(especie.volr) / parseFloat(rodal.area);
                            especie.volh = parseFloat(valor).toFixed(2);
                            continuar = true;
                          } else {
                            if (tipoInventarioId == 2) { //muestreo
                              var bndSaneamiento = false;
                              if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                                bndSaneamiento = true;
                              }
                              var valor = 0;
                              for (var i = 0; i < rodal.especies.length; i++) {
                                if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 3) {
                                  valor = parseInt(especie.arbolr) / parseFloat(rodal.area);
                                  especie.arbolh = parseInt(valor);
                                  valor = parseFloat(especie.abr) / parseFloat(rodal.area);
                                  especie.abh = parseFloat(valor).toFixed(2);
                                  valor = parseFloat(especie.volr) / parseFloat(rodal.area);
                                  especie.volh = parseFloat(valor).toFixed(2);
                                } else {
                                  valor = parseInt(especie.conteoArbol) * parseFloat(rodal.area);
                                  especie.arbolr = Math.round(valor);
                                  valor = parseFloat(especie.abh) * parseFloat(rodal.area);
                                  especie.abr = parseFloat(valor).toFixed(2);
                                  valor = parseFloat(especie.volh) * parseFloat(rodal.area);
                                  especie.volr = parseFloat(valor).toFixed(2);
                                  especie.volumenTotalr = especie.volr;
                                }
                                //datos para calidad de fuste
                                if (bndSaneamiento) {
                                  for (var j = 0; j < especie.distribucion.length; j++) {
                                    var tmpFuste = especie.distribucion[j].fuste;
                                    var soloFuste = tmpFuste.fuste;
                                    for (var k = 0; k < soloFuste.length; k++) {
                                      var fuste = soloFuste[k];
                                      if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                        if (parseInt(fuste.conteoArbol) == 0) {
                                          fuste.areaBasal = 0;
                                        }
                                        if (tipoInventarioId == 2) {
                                          valor = parseFloat(fuste.areaBasal) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.abr = parseFloat(valor).toFixed(2);
                                          valor = parseFloat(fuste.volumen) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.volr = parseFloat(valor);
                                          valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.arbolr = Math.round(valor);
                                        }
                                      }
                                    }
                                    var soloGorgojo = tmpFuste.gorgojo;
                                    for (var k = 0; k < soloGorgojo.length; k++) {
                                      var fuste = soloGorgojo[k];
                                      if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                        if (parseInt(fuste.conteoArbol) == 0) {
                                          fuste.areaBasal = 0;
                                        }
                                        if (tipoInventarioId == 2) {
                                          valor = parseFloat(fuste.areaBasal) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.abr = parseFloat(valor).toFixed(2);
                                          valor = parseFloat(fuste.volumen) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.volr = parseFloat(valor);
                                          valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.arbolr = Math.round(valor);
                                        }
                                      }
                                    }
                                    var soloAgente = tmpFuste.agente;
                                    for (var k = 0; k < soloAgente.length; k++) {
                                      var fuste = soloAgente[k];
                                      if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                        if (parseInt(fuste.conteoArbol) == 0) {
                                          fuste.areaBasal = 0;
                                        }
                                        if (tipoInventarioId == 2) {
                                          valor = parseFloat(fuste.areaBasal) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.abr = parseFloat(valor).toFixed(2);
                                          valor = parseFloat(fuste.volumen) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.volr = parseFloat(valor);
                                          valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.arbolr = Math.round(valor);
                                        }
                                      }
                                    }
                                  }
                                } else {
                                  for (var j = 0; j < especie.distribucion.length; j++) {
                                    for (var k = 0; k < especie.distribucion[j].fuste.length; k++) {
                                      var fuste = especie.distribucion[j].fuste[k];
                                      if (parseInt(fuste.conteoArbol) > 0) { //solo si tiene arboles se agrega
                                        if (parseInt(fuste.conteoArbol) == 0) {
                                          fuste.areaBasal = 0;
                                        }
                                        if (tipoInventarioId == 2) {
                                          valor = parseFloat(fuste.areaBasal) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.abr = parseFloat(valor).toFixed(2);
                                          valor = parseFloat(fuste.volumen) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.volr = parseFloat(valor);
                                          valor = parseInt(fuste.conteoArbol) / parseFloat(rodal.areaMuestreada);
                                          valor = valor * parseFloat(rodal.area);
                                          fuste.arbolr = Math.round(valor);
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              continuar = true;
                            } else {
                              toastr.error("Debe seleccionar un tipo de inventario válido para el rodal " + rodal.correlativo);
                              return;
                            }
                          }
                          if (continuar) { //calcular totales
                            rodal.alturaPromedio = 0;
                            rodal.dapPromedio = 0;
                            rodal.arbolh = 0;
                            rodal.abh = 0;
                            rodal.volh = 0;
                            rodal.arbolr = 0;
                            rodal.abr = 0;
                            rodal.volr = 0;
                            rodal.cantidad = 0;
                            for (var j = 0; j < rodal.especies.length; j++) {
                              rodal.alturaPromedio += parseFloat(rodal.especies[j].alturaPromedio);
                              rodal.dapPromedio += parseFloat(rodal.especies[j].dapPromedio);
                              rodal.arbolh += parseInt(rodal.especies[j].arbolh);
                              rodal.abh += parseFloat(rodal.especies[j].abh);
                              rodal.volh += parseFloat(rodal.especies[j].volh);
                              rodal.arbolr += parseInt(rodal.especies[j].arbolr);
                              rodal.abr += parseFloat(rodal.especies[j].abr);
                              rodal.volr += parseFloat(rodal.especies[j].volr);
                              rodal.conteoArbol += parseInt(rodal.especies[j].conteoArbol);
                              rodal.cantidad++;
                            }
                            rodal.alturaPromedio = parseFloat(parseFloat(rodal.alturaPromedio) / rodal.cantidad).toFixed(2);
                            rodal.dapPromedio = parseFloat(parseFloat(rodal.dapPromedio) / rodal.cantidad).toFixed(2);
                            rodal.abh = parseFloat(rodal.abh).toFixed(2);
                            rodal.volh = parseFloat(rodal.volh).toFixed(2);
                            rodal.abr = parseFloat(rodal.abr).toFixed(2);
                            rodal.volr = parseFloat(rodal.volr).toFixed(2);
                          }
                        } else {
                          toastr.error('El área no puede ser menor o igual a cero');
                          rodal.area = null;
                        }
                      }
                    }
                    /*Fin panel informacion inventario*/

                    /* Panel resumen - Inventario*/

                    $scope.setIncremento = function (rodal) {
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volr);
                      }
                      total = total / parseFloat(rodal.area);
                      total = total / parseInt(rodal.edad);
                      rodal.incremento = parseFloat(total).toFixed(2);
                    }

                    $scope.validarPanelResumenInventario = function (isValid) {
                      if (!isValid) {
                        toastr.error('Debe indicar todos los valores por rodal');
                        return;
                      }
                      var usado = $scope.getAreaUsadoInventario();
                      usado = parseFloat(usado).toFixed(4);
                      var disponible = parseFloat($scope.data.area).toFixed(4) - parseFloat(usado).toFixed(4);
                      if (disponible != 0) {
                        swal("Oops", 'La sumatoria de las áreas de los rodales no es igual al área solicitada \nsuma: ' + usado + '\nsolicitado: ' + $scope.data.area + '\nNo es posible continuar', "error");
                        return;
                      }
                      if ($scope.data.ttPlanificacionGestion != undefined) {
                        if ($scope.data.ttPlanificacionGestion != null) {
                          $scope.data.ttPlanificacionGestion = {
                            hayReforestacion: 1
                          };
                        }
                      }
                      var data = $scope.data.rodal;
                      var tmp = $scope.data;
                      var codigos = [];
                      var encontrado = false;
                      var incremento = 0;
                      for (var i = 0; i < data.length; i++) {
                        encontrado = false;
                        for (var j = 0; j < codigos.length; j++) {
                          if (codigos[j].tcClaseDesarrollo.claseDesarrolloId == data[i].tcClaseDesarrollo.claseDesarrolloId) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          codigos.push(
                            {
                              tcClaseDesarrollo: data[i].tcClaseDesarrollo,
                              ttGestion: {
                                gestionId: tmp.gestionId,
                                tcTipoGestion: tmp.tcTipoGestion,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: tmp.tcPersonaCrea,
                                tcTipoBosque: tmp.tcTipoBosque,
                                tcElaborador: tmp.tcElaborador,
                                ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                personaAnulaId: $scope.loginData.personaId
                              }
                            }
                          );
                        }
                        incremento += parseFloat(data[i].incremento);
                        data[i].totalParcelas = data[i].parcelas.length;
                        data[i].cantidadEspecie = data[i].especies.length;
                        for (j = 0; j < data[i].especies.length; j++) {
                          for (k = 0; k < data[i].especies[j].distribucion.length; k++) {
                            data[i].especies[j].distribucion[k].fuste = JSON.stringify(data[i].especies[j].distribucion[k].fuste);
                          }
                          data[i].especies[j].fuste = JSON.stringify(data[i].especies[j].fuste);
                        }
                      }
                      incremento = incremento / data.length;
                      var ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId,
                        codigoClase: codigos
                      }
                      gestionService.agregarClaseDesarrollo(ttGestion).then(function (response) {
                        if (response.status == 'OK') {
                          if ($scope.data.ttPlanificacionGestion == null) {
                            $scope.data.ttPlanificacionGestion = {
                              hayReforestacion: 1
                            };
                          }
                          $scope.data.ttPlanificacionGestion.incremento = parseFloat(incremento).toFixed(2);
                          $scope.data.codigoClase = codigos;
                          $scope.gridOptionsClaseDesarrollo.data = $scope.data.codigoClase;
                          var objInventario = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            rodal: data
                          }
                          gestionService.actualizarRodal(objInventario).then(function (response) {
                            if (response.status == 'OK') {
                              for (var i = 0; i < data.length; i++) {
                                for (j = 0; j < data[i].especies.length; j++) {
                                  for (k = 0; k < data[i].especies[j].distribucion.length; k++) {
                                    data[i].especies[j].distribucion[k].fuste = JSON.parse(data[i].especies[j].distribucion[k].fuste);
                                  }
                                  data[i].especies[j].fuste = JSON.parse(data[i].especies[j].fuste);
                                }
                              }
                              $scope.panel.openRegresion = true;
                              $scope.panel.regresion = false;
                              $scope.panel.openCodigo = true;
                              $scope.panel.codigo = false;
                              swal(response.message, 'Revise que los datos por hectarea y rodal estén calculados de lo contrario use el botón debajo de cada número de rodal para recalcular');
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                    /** Fin panel resumen */

                    /* Panel regresion */
                    $scope.generarAnalisisEstadistico = function () {
                      var dataAnalisis = [];
                      var data = $scope.data.ttInventarioGestion.data;
                      var i = 0, j = 0, k = 0;
                      var row, tmpData, parcela, valor, encontrado = false;
                      //obtener datos por rodal
                      var tmpRodal = $scope.data.rodal;
                      var contieneMuestreo = false;
                      var tipoInventarioId = 1;
                      for (i = 0; i < tmpRodal.length; i++) {
                        tipoInventarioId = 1;
                        contieneMuestreo = false;
                        for (j = 0; j < tmpRodal[i].especies.length; j++) {
                          if (tmpRodal[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                            tipoInventarioId = 2;
                            contieneMuestreo = true;
                            break;
                          }
                        }
                        if (contieneMuestreo) {
                          row = {
                            rodalGestionId: tmpRodal[i].rodalGestionId,
                            correlativo: tmpRodal[i].correlativo,
                            turno: tmpRodal[i].turno,
                            totalParcelas: tmpRodal[i].totalParcelas,
                            tamanioParcela: tmpRodal[i].tamanioParcela,
                            area: tmpRodal[i].area,
                            tipoInventarioId: tipoInventarioId,
                            parcela: []
                          }
                          tmpData = [];
                          for (j = 0; j < data.length; j++) {
                            if (tmpRodal[i].correlativo == data[j].RODAL) {
                              if (data[j].PARCELA != null && data[j].TAMANIO_PARCELA != null) {
                                //buscar primero la parcela
                                parcela = {
                                  noParcela: data[j].PARCELA,
                                  volumen: 0,
                                  tamanioParcela: data[j].TAMANIO_PARCELA,
                                  arboles: 0,
                                  volumenCuadrado: 0,
                                  volumenHectaria: 0,
                                  volumenHectaria2: 0
                                }
                                encontrado = false;
                                for (k = 0; k < row.parcela.length; k++) {
                                  if (row.parcela[k].noParcela == parcela.noParcela) {
                                    parcela = row.parcela[k];
                                    encontrado = true;
                                    break;
                                  }
                                }
                                valor = parseFloat(parcela.volumen) + parseFloat(data[j].VOLUMEN);
                                parcela.volumen = parseFloat(valor).toFixed(2);
                                parcela.arboles = parcela.arboles + 1;
                                if (!encontrado) {
                                  row.parcela.push(parcela);
                                }
                              }
                            } else {
                              tmpData.push(data[j]);
                            }
                          }
                          data = tmpData;
                          dataAnalisis.push(row);
                        }
                      }
                      var areaTotal = parseFloat($scope.data.area);
                      var totalParcelas = 0, valorG = 0;
                      if ($scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3
                        || $scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) { //estratificado
                        /*obtener la proporcion del area por estrato*/
                        for (i = 0; i < dataAnalisis.length; i++) {
                          dataAnalisis[i].nhj = (parseFloat(dataAnalisis[i].area) * 10000) / parseFloat(dataAnalisis[i].tamanioParcela);
                          dataAnalisis[i].pj = parseFloat(dataAnalisis[i].area) / areaTotal;
                          totalParcelas += dataAnalisis[i].totalParcelas;
                        }
                        totalParcelas = totalParcelas - 1;
                        if (totalParcelas <= 0) {
                          swal('El rodal ' + dataAnalisis[i].correlativo + ' debe tener más de una parcela');
                          return;
                        }
                        for (j = 0; j < $scope.dataTStudent.length; j++) {
                          if ($scope.dataTStudent[j].k == totalParcelas) {
                            valorG = parseFloat($scope.dataTStudent[j].value);
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          toastr.error("No hay un valor dentro de la tabla T-Student para un total de parcelas de: " + totalParcelas);
                          return;
                        }
                      }
                      //realizar calculos por rodal y parcela
                      var dataAnalisisRodal = [];
                      var parcelas = 0, arboles = 0, tamanioParcela = 0, totalH = 0, totalH2 = 0, total = 0, total2 = 0;
                      var desviacionEstandar, volh, volumenPromedio, coeficienteVariacion, errors, errorm, errorp, lcInferior, lcSuperior, vpEstrato;
                      var vXjxNj, vSjxPj, vSj2xPj;
                      var tmp = $scope.data;
                      for (i = 0; i < dataAnalisis.length; i++) {
                        parcelas = dataAnalisis[i].parcela.length;
                        arboles = 0;
                        tamanioParcela = 0;
                        total = 0;
                        total2 = 0;
                        totalH = 0;
                        totalH2 = 0;
                        for (j = 0; j < dataAnalisis[i].parcela.length; j++) {
                          valor = dataAnalisis[i].parcela[j].volumen * dataAnalisis[i].parcela[j].volumen;
                          dataAnalisis[i].parcela[j].volumenCuadrado = parseFloat(valor).toFixed(4);
                          valor = (dataAnalisis[i].parcela[j].volumen * 10000) / dataAnalisis[i].parcela[j].tamanioParcela;
                          dataAnalisis[i].parcela[j].volumenHectaria = parseFloat(valor).toFixed(4);
                          valor = parseFloat(dataAnalisis[i].parcela[j].volumenHectaria) * parseFloat(dataAnalisis[i].parcela[j].volumenHectaria);
                          dataAnalisis[i].parcela[j].volumenHectaria2 = parseFloat(valor).toFixed(4);
                          arboles += parseInt(dataAnalisis[i].parcela[j].arboles);
                          tamanioParcela = dataAnalisis[i].parcela[j].tamanioParcela;
                          total = total + parseFloat(dataAnalisis[i].parcela[j].volumen);
                          total2 = total2 + parseFloat(dataAnalisis[i].parcela[j].volumenCuadrado);
                          totalH = totalH + parseFloat(dataAnalisis[i].parcela[j].volumenHectaria);
                          totalH2 = totalH2 + parseFloat(dataAnalisis[i].parcela[j].volumenHectaria2);
                        }
                        dataAnalisis[i].arboles = arboles;
                        dataAnalisis[i].parcelas = parcelas;
                        dataAnalisis[i].tamanioParcela = tamanioParcela;
                        dataAnalisis[i].areaMuestreada = dataAnalisis[i].parcelas / tamanioParcela;
                        valor = (totalH * totalH) / parcelas;
                        valor = totalH2 - valor;
                        valor = valor / (parcelas - 1);
                        dataAnalisis[i].varianza = parseFloat(valor).toFixed(4);
                        //restar uno a parcelas para buscar el grado de libertad
                        parcelas = parcelas - 1;
                        dataAnalisis[i].valorG = 0;
                        encontrado = false;
                        if (parcelas <= 0) {
                          swal('El rodal ' + dataAnalisis[i].correlativo + ' debe tener más de una parcela');
                          return;
                        }
                        for (j = 0; j < $scope.dataTStudent.length; j++) {
                          if ($scope.dataTStudent[j].k == parcelas) {
                            dataAnalisis[i].valorG = parseFloat($scope.dataTStudent[j].value);
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          toastr.error("No hay un valor dentro de la tabla T-Student para un total de parcelas de: " + parcelas);
                          return;
                        }
                        desviacionEstandar = Math.pow(dataAnalisis[i].varianza, 0.5);
                        volh = totalH / parseInt(dataAnalisis[i].parcelas);
                        volumenPromedio = total / parseInt(dataAnalisis[i].parcelas);
                        coeficienteVariacion = (desviacionEstandar / volh) * 100;
                        errors = dataAnalisis[i].varianza / dataAnalisis[i].parcelas;
                        errors = Math.pow(errors, 0.5);
                        errorm = errors * dataAnalisis[i].valorG;
                        errorp = errorm / volh;
                        //dejar lo valores con solo dos decimales
                        volh = parseFloat(volh).toFixed(4);
                        volumenPromedio = parseFloat(volumenPromedio).toFixed(4);
                        desviacionEstandar = parseFloat(desviacionEstandar).toFixed(4);
                        coeficienteVariacion = parseFloat(coeficienteVariacion).toFixed(4);
                        errors = parseFloat(errors).toFixed(4);
                        errorm = parseFloat(errorm).toFixed(4);
                        errorp = parseFloat(errorp) * 100;
                        lcInferior = parseFloat(volh) - parseFloat(errorm);
                        lcSuperior = parseFloat(volh) + parseFloat(errorm);
                        lcInferior = parseFloat(lcInferior).toFixed(4);
                        lcSuperior = parseFloat(lcSuperior).toFixed(4);
                        vXjxNj = null;
                        vSjxPj = null;
                        vSj2xPj = null;
                        dataAnalisis[i].nhj = parseFloat(dataAnalisis[i].nhj).toFixed(2);
                        dataAnalisis[i].pj = parseFloat(dataAnalisis[i].pj).toFixed(2);
                        vXjxNj = volh * dataAnalisis[i].nhj;
                        vSjxPj = desviacionEstandar * dataAnalisis[i].pj;
                        vSj2xPj = (desviacionEstandar * desviacionEstandar) * dataAnalisis[i].pj;
                        row = {
                          ttRodalGestion: {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            rodalGestionId: dataAnalisis[i].rodalGestionId,
                            area: dataAnalisis[i].area,
                            correlativo: dataAnalisis[i].correlativo
                          },
                          nParcelas: dataAnalisis[i].parcelas,
                          volh: volh,
                          vpEstrato: volumenPromedio,
                          desviacionEstandar: desviacionEstandar,
                          coeficienteVariacion: coeficienteVariacion,
                          errors: errors,
                          errorm: errorm,
                          errorp: parseFloat(errorp).toFixed(2),
                          lcInferior: lcInferior,
                          lcSuperior: lcSuperior,
                          nhj: parseFloat(dataAnalisis[i].nhj).toFixed(2),
                          pj: parseFloat(dataAnalisis[i].pj).toFixed(2),
                          vXjxNj: parseFloat(vXjxNj).toFixed(2),
                          vSjxPj: parseFloat(vSjxPj).toFixed(2),
                          vSj2xPj: parseFloat(vSj2xPj).toFixed(2),
                        }
                        dataAnalisisRodal.push(row);
                      } // fin calculos por rodal
                      var totales = {};
                      if ($scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3
                        || $scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) { //estratificado
                        totales = {
                          area: 0,
                          Xj: 0,
                          Sj: 0,
                          nj: 0,
                          nhj: 0,
                          pj: 0,
                          vXjxNj: 0,
                          vSjxPj: 0,
                          vSj2xPj: 0,
                          segunArea: 0,
                          segunVariabilidad: 0
                        };
                        for (i = 0; i < dataAnalisisRodal.length; i++) {
                          totales.area += parseFloat(dataAnalisisRodal[i].ttRodalGestion.area);
                          totales.Xj += parseFloat(dataAnalisisRodal[i].volh);
                          totales.Sj += parseFloat(dataAnalisisRodal[i].desviacionEstandar);
                          totales.nj += parseFloat(dataAnalisisRodal[i].nParcelas);
                          totales.nhj += parseFloat(dataAnalisisRodal[i].nhj);
                          totales.pj += parseFloat(dataAnalisisRodal[i].pj);
                          totales.vXjxNj += parseFloat(dataAnalisisRodal[i].vXjxNj);
                          totales.vSjxPj += parseFloat(dataAnalisisRodal[i].vSjxPj);
                          totales.vSj2xPj += parseFloat(dataAnalisisRodal[i].vSj2xPj);
                        }
                        totales.area = parseFloat(totales.area).toFixed(2);
                        totales.Xj = parseFloat(totales.Xj).toFixed(2);
                        totales.Sj = parseFloat(totales.Sj).toFixed(2);
                        totales.nj = parseFloat(totales.nj).toFixed(2);
                        totales.nhj = parseFloat(totales.nhj).toFixed(2);
                        totales.pj = parseFloat(totales.pj).toFixed(2);
                        totales.vXjxNj = parseFloat(totales.vXjxNj).toFixed(2);
                        totales.vSjxPj = parseFloat(totales.vSjxPj).toFixed(2);
                        totales.vSj2xPj = parseFloat(totales.vSj2xPj).toFixed(2);
                        //calcular los datos del analisis estadistico estratificados
                        var analisisEstratificado = {
                          vpEstrato: null,
                          desviacionEstandar: null,
                          errors: null,
                          errorm: null,
                          errorp: null,
                          lcSuperior: null,
                          lcInferior: null,
                          nParcelas: null
                        }
                        var value = parseFloat(totales.vXjxNj) / parseFloat(totales.nhj);
                        analisisEstratificado.vpEstrato = parseFloat(value).toFixed(2);
                        value = (parseFloat(totales.vSjxPj) / parseFloat(analisisEstratificado.vpEstrato)) * 100;
                        analisisEstratificado.desviacionEstandar = parseFloat(value).toFixed(2);
                        value = (parseFloat(totales.vSjxPj) * parseFloat(totales.vSjxPj)) / parseFloat(totales.nj);
                        value = value - (parseFloat(totales.vSj2xPj) / parseFloat(totales.nhj));
                        value = Math.pow(value, 0.5);
                        analisisEstratificado.errors = parseFloat(value).toFixed(2);
                        value = parseFloat(analisisEstratificado.errors) * valorG;
                        analisisEstratificado.errorm = parseFloat(value).toFixed(2);
                        value = (parseFloat(analisisEstratificado.errorm) / parseFloat(analisisEstratificado.vpEstrato)) * 100;
                        analisisEstratificado.errorp = parseFloat(value).toFixed(2);
                        value = parseFloat(analisisEstratificado.vpEstrato) + parseFloat(analisisEstratificado.errorm);
                        analisisEstratificado.lcSuperior = parseFloat(value).toFixed(2);
                        value = analisisEstratificado.vpEstrato - analisisEstratificado.errorm;
                        analisisEstratificado.lcInferior = parseFloat(value).toFixed(2);
                        value = parseFloat(analisisEstratificado.vpEstrato) * (parseFloat($scope.precisionId) / 100);
                        value = value * value;
                        var tmp = valorG * valorG;
                        var tmp2 = tmp;
                        tmp = tmp * parseFloat(totales.vSj2xPj);
                        value = tmp / value;
                        tmp = parseFloat(totales.vSj2xPj) / parseFloat(totales.vXjxNj);
                        tmp = tmp2 * tmp;
                        value = value + tmp;
                        analisisEstratificado.nParcelas = Math.round(value);
                        for (i = 0; i < dataAnalisisRodal.length; i++) {
                          value = dataAnalisisRodal[i].pj * analisisEstratificado.nParcelas;
                          value = Math.round(value);
                          totales.segunArea += parseInt(value);
                          dataAnalisisRodal[i].segunArea = value;
                          value = parseFloat(dataAnalisisRodal[i].vSjxPj) / totales.vSjxPj;
                          value = analisisEstratificado.nParcelas * value;
                          value = Math.round(value);
                          dataAnalisisRodal[i].segunVariabilidad = value;
                          totales.segunVariabilidad += parseInt(value);
                        }
                      } else {
                        totales = {
                          area: 0,
                          nParcelas: 0,
                          volh: 0,
                          vpEstrato: 0,
                          desviacionEstandar: 0,
                          coeficienteVariacion: 0,
                          errors: 0,
                          errorm: 0,
                          errorp: 0,
                          lcInferior: 0,
                          lcSuperior: 0
                        }
                        for (i = 0; i < dataAnalisisRodal.length; i++) {
                          totales.area += parseFloat(dataAnalisisRodal[i].ttRodalGestion.area);
                          totales.nParcelas += parseInt(dataAnalisisRodal[i].nParcelas);
                          totales.volh += parseFloat(dataAnalisisRodal[i].volh);
                          totales.vpEstrato += parseFloat(dataAnalisisRodal[i].vpEstrato);
                          totales.desviacionEstandar += parseFloat(dataAnalisisRodal[i].desviacionEstandar);
                          totales.coeficienteVariacion += parseFloat(dataAnalisisRodal[i].coeficienteVariacion);
                          totales.errors += parseFloat(dataAnalisisRodal[i].errors);
                          totales.errorm += parseFloat(dataAnalisisRodal[i].errorm);
                          totales.errorp += parseFloat(dataAnalisisRodal[i].errorp);
                          totales.lcInferior += parseFloat(dataAnalisisRodal[i].lcInferior);
                          totales.lcSuperior += parseFloat(dataAnalisisRodal[i].lcSuperior);
                        }
                        totales.area = parseFloat(totales.area).toFixed(2);
                        totales.nParcelas = parseInt(totales.nParcelas);
                        totales.volh = parseFloat(totales.volh).toFixed(2);
                        totales.vpEstrato = parseFloat(totales.vpEstrato).toFixed(2);
                        totales.desviacionEstandar = parseFloat(totales.desviacionEstandar).toFixed(2);
                        totales.coeficienteVariacion = parseFloat(totales.coeficienteVariacion).toFixed(2);
                        totales.errors = parseFloat(totales.errors).toFixed(2);
                        totales.errorm = parseFloat(totales.errorm).toFixed(2);
                        totales.errorp = parseFloat(totales.errorp).toFixed(2);
                        totales.lcInferior = parseFloat(totales.lcInferior).toFixed(2);
                        totales.lcSuperior = parseFloat(totales.lcSuperior).toFixed(2);
                      }
                      if ($scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3
                        || $scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) {
                        //agregar los resumenes a la data a almacenar
                        var ttEstadisticoGestion = {
                          area: totales.area,
                          nhj: totales.nhj,
                          pj: totales.pj,
                          Sj: totales.Sj,
                          Xj: totales.Xj,
                          nj: totales.nj,
                          vSj2xPj: totales.vSj2xPj,
                          vSjxPj: totales.vSjxPj,
                          vXjxNj: totales.vXjxNj,
                          segunArea: totales.segunArea,
                          segunVariabilidad: totales.segunVariabilidad,
                          errorm: analisisEstratificado.errorm,
                          errorp: analisisEstratificado.errorp,
                          errors: analisisEstratificado.errors,
                          lcInferior: analisisEstratificado.lcInferior,
                          lcSuperior: analisisEstratificado.lcSuperior,
                          nParcelas: analisisEstratificado.nParcelas,
                          desviacionEstandar: analisisEstratificado.desviacionEstandar,
                          vpEstrato: analisisEstratificado.vpEstrato,
                          precisionId: $scope.precisionId
                        }
                        $scope.data.ttEstadisticoGestion = ttEstadisticoGestion;
                        for (i = 0; i < tmpRodal.length; i++) {
                          contieneMuestreo = false;
                          for (j = 0; j < tmpRodal[i].especies.length; j++) {
                            if (tmpRodal[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                              contieneMuestreo = true;
                              break;
                            }
                          }
                          if (contieneMuestreo) {
                            for (j = 0; j < dataAnalisisRodal.length; j++) {
                              if (tmpRodal[i].rodalGestionId == dataAnalisisRodal[j].ttRodalGestion.rodalGestionId) {
                                tmpRodal[i].ttEstadisticoRodal = dataAnalisisRodal[j];
                                break;
                              }
                            }
                          } else {
                            tmpRodal[i].ttEstadisticoRodal = null;
                          }
                        }
                      } else {
                        var ttEstadisticoGestion = {
                          area: totales.area,
                          coeficienteVariacion: totales.coeficienteVariacion,
                          errorm: totales.errorm,
                          errorp: totales.errorp,
                          errors: totales.errors,
                          lcInferior: totales.lcInferior,
                          lcSuperior: totales.lcSuperior,
                          nParcelas: totales.nParcelas,
                          vpEstrato: totales.vpEstrato
                        }
                        $scope.data.ttEstadisticoGestion = ttEstadisticoGestion;
                        for (i = 0; i < dataAnalisisRodal.length; i++) {
                          dataAnalisisRodal[i].nhj = 0;
                          dataAnalisisRodal[i].pj = 0;
                          dataAnalisisRodal[i].vSjxPj = 0;
                          dataAnalisisRodal[i].vSj2xPj = 0;
                          dataAnalisisRodal[i].vXjxNj = 0;
                        }
                        for (i = 0; i < tmpRodal.length; i++) {
                          contieneMuestreo = false;
                          for (j = 0; j < tmpRodal[i].especies.length; j++) {
                            if (tmpRodal[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                              contieneMuestreo = true;
                              break;
                            }
                          }
                          if (contieneMuestreo) {
                            for (j = 0; j < dataAnalisisRodal.length; j++) {
                              if (tmpRodal[i].rodalGestionId == dataAnalisisRodal[j].ttRodalGestion.rodalGestionId) {
                                tmpRodal[i].ttEstadisticoRodal = dataAnalisisRodal[j];
                                break;
                              }
                            }
                          } else {
                            tmpRodal[i].ttEstadisticoRodal = null;
                          }
                        }
                      }
                    } //generarAnalisisEstadistico

                    $scope.validarPanelRegresion = function () {
                      if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 1) {
                        if ($scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 1
                          || $scope.data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 2) {
                          $scope.generarAnalisisEstadistico();
                        }
                      }
                      if ($scope.data.ttInventarioGestion.regresion != undefined) {
                        if ($scope.data.ttInventarioGestion.regresion != null) {
                          var tmp = $scope.data;
                          var ttInventarioGestion = angular.copy(tmp.ttInventarioGestion);
                          delete ttInventarioGestion.data;
                          delete ttInventarioGestion.inventarioRodal;
                          delete ttInventarioGestion.distribucionRodal;
                          delete ttInventarioGestion.analisisEstadistico;
                          delete ttInventarioGestion.noMaderable;
                          delete ttInventarioGestion.latizal;
                          delete ttInventarioGestion.brinzal;
                          ttInventarioGestion.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          };
                          ttInventarioGestion.totalRodales = $scope.data.rodal.length;
                          var objInventario = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            ttInventarioGestion: ttInventarioGestion
                          }
                          gestionService.actualizarInventario(objInventario).then(function (response) {
                            if (response.status == 'OK') {
                              $scope.data.ttInventarioGestion.inventarioGestionId = response.data[0].inventarioGestionId;
                              $scope.panel.openDistribucion = true;
                              $scope.panel.openAnalisisEstadistico = true;
                              $scope.panel.regresion = false;
                              if ($scope.data.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 1) {
                                $scope.panel.openAnalisis = true;
                              }
                              $scope.panel.openCodigo = true;
                              $scope.panel.codigo = false;
                              $scope.data.ttInventarioGestion.totalRodales = ttInventarioGestion.totalRodales;
                              $scope.visibleDistribucion = $scope.visibleDatosDistribucion();
                              if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 3) {
                                $scope.panel.openNoMaderable = true;
                                $scope.panel.noMaderable = false;
                              }
                              toastr.success(response.message);
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                          //llamar a la funcion para realizar la distribucion diametrica
                        } else {
                          toastr.error('Debe rellenar todos los campos');
                        }
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }
                    /* Fin Panel regresion */

                    $scope.precisionId = null;
                    $scope.setPrecisionEstratificado = function (item) {
                      $scope.precisionId = item.precisionId;
                    }

                    $scope.calcularEstratificado = function () {
                      if ($scope.precisionId != null) {
                        $scope.generarAnalisisEstadistico();
                      } else {
                        toastr.error("Debe indicar la precision");
                      }
                    }

                    /*Panel distribucion*/

                    $scope.gridOptionsResultadoInventario = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsResultadoInventario.enableFiltering = false;
                    $scope.gridOptionsResultadoInventario.columnDefs = [
                      { field: 'correlativo', name: 'correlativo', displayName: $scope.titulos.Rodal + ' #', width: "20%" },
                      { field: 'especies', name: 'especies', displayName: 'Especies' },
                      { field: 'totalParcelas', name: 'totalParcelas', displayName: 'No. Parcelas', width: "20%" }
                    ];
                    var dataInventarioRodal = [];
                    if ($scope.data.rodal != null) {
                      var tmpRodal = $scope.data.rodal;
                      for (var i = 0; i < tmpRodal.length; i++) {
                        var especies = "";
                        for (var j = 0; j < tmpRodal[i].especies.length; j++) {
                          if (j == 0) {
                            especies = tmpRodal[i].especies[j].tcEspecie.nombreCientifico;
                          } else {
                            especies += ", " + tmpRodal[i].especies[j].tcEspecie.nombreCientifico;
                          }
                        }
                        item = {
                          correlativo: tmpRodal[i].correlativo,
                          area: tmpRodal[i].area,
                          especies: especies,
                          totalParcelas: tmpRodal[i].parcelas.length
                        }
                        dataInventarioRodal.push(item);
                      }
                    }
                    $scope.gridOptionsResultadoInventario.data = dataInventarioRodal;

                    $scope.validarPanelDistribucion = function () {
                      var itemInventario = {
                        gestionId: $scope.data.gestionId,
                        distribucionRodal: $scope.data.ttInventarioGestion.distribucionRodal
                      }
                      gestionService.actualizarInventario(itemInventario).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success(response.message);
                          $scope.panel.openAnalisis = true;
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.validarPanelAnalisisEstadistico = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var dataResumen = tmp.rodal;
                        var minimo = 15;
                        var mostrarMensaje = true;
                        if ($scope.data.tcTipoBosque.tipoBosqueId == 3) {
                          minimo = 20;
                        }
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              dataResumen[i].especies[j].distribucion[k].fuste = JSON.stringify(dataResumen[i].especies[j].distribucion[k].fuste);
                            }
                            dataResumen[i].especies[j].fuste = JSON.stringify(dataResumen[i].especies[j].fuste);
                          }
                          if (dataResumen[i].ttEstadisticoRodal != null) {
                            if (dataResumen[i].ttEstadisticoRodal.errorp > minimo) {
                              if (mostrarMensaje) {
                                swal('se ha detectado que tiene un error mayor a ' + minimo + ' por lo que será objeto de enmienda si no lo corrige');
                              }
                              mostrarMensaje = false;
                            }
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          ttEstadisticoGestion: tmp.ttEstadisticoGestion,
                          rodal: dataResumen
                        }
                        gestionService.actualizarEstadistico(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            for (i = 0; i < dataResumen.length; i++) {
                              for (j = 0; j < dataResumen[i].especies.length; j++) {
                                for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                                  dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                                }
                                dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                              }
                            }
                            $scope.panel.openAnalisis = true;
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error("Debe indicar todos los datos");
                      }
                    }

                    /* Fin panel distribucion*/

                    $scope.validarPanelAnalisisDescriptivo = function () {
                      var tmp = $scope.data;
                      var ttInventarioGestion = angular.copy(tmp.ttInventarioGestion);
                      delete ttInventarioGestion.data;
                      delete ttInventarioGestion.inventarioRodal;
                      delete ttInventarioGestion.distribucionRodal;
                      delete ttInventarioGestion.analisisEstadistico;
                      delete ttInventarioGestion.noMaderable;
                      delete ttInventarioGestion.latizal;
                      delete ttInventarioGestion.brinzal;
                      var objInventario = {
                        ttGestion: {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        },
                        ttInventarioGestion: ttInventarioGestion
                      }
                      gestionService.actualizarInventario(objInventario).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success(response.message);
                          $scope.panel.openNoMaderable = true;
                          $scope.panel.noMaderable = false;
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    /*Panel producto no maderables tab inventario*/

                    $scope.setProducto = function (item, row) {
                      row.tcProducto = item;
                    }

                    $scope.setUnidadMedidad = function (item, row) {
                      row.tcUnidadMedida = item;
                    }

                    //inventario
                    $scope.agregarProducto = function (rodal) {
                      if (rodal.productos == undefined || rodal.productos == null) {
                        rodal.productos = [];
                      }
                      var item = {
                        tcProducto: {
                          productoId: null
                        },
                        tcUnidadMedida: {
                          unidadMedidaId: null
                        },
                        cantidad: null
                      };
                      rodal.productos.push(item);
                    }

                    $scope.quitarProducto = function (rodal, item) {
                      rodal.productos.splice(rodal.productos.indexOf(item), 1);
                    }

                    $scope.validarPanelNoMaderable = function (isValid) {
                      if (isValid) {
                        if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 3
                          || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                          if ($scope.data.ttInventarioGestion.descripcionDanio == null
                            || $scope.data.ttInventarioGestion.descripcionCausa == null || $scope.data.ttInventarioGestion.analisisDescriptivo == null) {
                            toastr.error('Todos los campos son obligatorios');
                            return;
                          }
                          var tmp = $scope.data;
                          var ttInventarioGestion = angular.copy(tmp.ttInventarioGestion);
                          delete ttInventarioGestion.data;
                          delete ttInventarioGestion.inventarioRodal;
                          delete ttInventarioGestion.distribucionRodal;
                          delete ttInventarioGestion.analisisEstadistico;
                          delete ttInventarioGestion.noMaderable;
                          delete ttInventarioGestion.latizal;
                          delete ttInventarioGestion.brinzal;
                          var objInventario = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            ttInventarioGestion: ttInventarioGestion
                          }
                          gestionService.actualizarInventario(objInventario).then(function (response) {
                            if (response.status == 'OK') {
                              toastr.success(response.message);
                              $scope.setTabs(5);
                              $scope.data.ultimoPanel = 5;
                              $state.go('index.plan.planificacion');
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        } else {
                          var tmp = $scope.data;
                          var dataResumen = tmp.rodal;
                          for (i = 0; i < dataResumen.length; i++) {
                            for (j = 0; j < dataResumen[i].especies.length; j++) {
                              for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                                dataResumen[i].especies[j].distribucion[k].fuste = JSON.stringify(dataResumen[i].especies[j].distribucion[k].fuste);
                              }
                              dataResumen[i].especies[j].fuste = JSON.stringify(dataResumen[i].especies[j].fuste);
                            }
                          }
                          var objInventario = {
                            ttGestion: {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            },
                            rodal: dataResumen
                          }
                          gestionService.actualizarInventarioNoMaderable(objInventario).then(function (response) {
                            if (response.status == 'OK') {
                              for (i = 0; i < dataResumen.length; i++) {
                                for (j = 0; j < dataResumen[i].especies.length; j++) {
                                  for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                                    dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                                  }
                                  dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                                }
                              }
                              toastr.success(response.message);
                              //activar tab en caso de no estarlo
                              //mover a tab inventario
                              $scope.setTabs(5);
                              $scope.data.ultimoPanel = 5;
                              $state.go('index.plan.planificacion');
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      } else {
                        toastr.error("Debe ingresar todos los campos");
                      }
                    }
                    /*Fin productos no maderables*/

                  }] //end controller
              }
            }
          })
          .state('index.plan.planificacion', {
            url: '/planificacion',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.planificacion.tpl.html',
                resolve: {
                  dataCriterioCorta: ['criteriocortaService',
                    function (criteriocortaService) {
                      return criteriocortaService.listaActiva();
                    }],
                  dataTratamientoSilvicultural: ['tratamientosilviculturalService',
                    function (tratamientosilviculturalService) {
                      return tratamientosilviculturalService.listaActiva();
                    }],
                  dataSistemaRepoblacion: ['sistemarepoblacionService',
                    function (sistemarepoblacionService) {
                      return sistemarepoblacionService.listaActiva();
                    }],
                  dataMetodoSiembra: ['metodosiembraService',
                    function (metodosiembraService) {
                      return metodosiembraService.listaActiva();
                    }],
                  dataProducto: ['productoService',
                    function (productoService) {
                      return productoService.listaNoMaderable();
                    }],
                  dataUnidadMedida: ['unidadmedidaService',
                    function (unidadmedidaService) {
                      return unidadmedidaService.listaActiva();
                    }],
                  dataCalidadFuste: ['calidadfusteService',
                    function (calidadfusteService) {
                      return calidadfusteService.listaActiva();
                    }],
                  dataTipoCobertura: ['tipocoberturaService',
                    function (tipocoberturaService) {
                      return tipocoberturaService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataCriterioCorta', 'dataTratamientoSilvicultural', 'dataSistemaRepoblacion', 'dataMetodoSiembra', 'ngDialog', 'authService', 'dataProducto', 'dataUnidadMedida', 'dataCalidadFuste', 'dataTipoCobertura',
                  function ($scope, $state, toastr, gestionService, dataCriterioCorta, dataTratamientoSilvicultural, dataSistemaRepoblacion, dataMetodoSiembra, ngDialog, authService, dataProducto, dataUnidadMedida, dataCalidadFuste, dataTipoCobertura) {

                    $scope.dataCriterioCorta = dataCriterioCorta.data;
                    $scope.dataSistemaRepoblacion = dataSistemaRepoblacion.data;
                    $scope.dataMetodoSiembra = dataMetodoSiembra.data;
                    $scope.dataProducto = dataProducto.data;
                    $scope.dataUnidadMedida = dataUnidadMedida.data;
                    $scope.dataCalidadFuste = dataCalidadFuste.data;
                    $scope.dataTipoCobertura = dataTipoCobertura.data;
                    $scope.dataEspecie = authService.getLocalData('dataEspecie');
                    var tmpData = dataTratamientoSilvicultural.data;
                    var dataVisible = [];
                    $scope.visibleCriterio = true;
                    if ($scope.data.tcTipoGestion.tipoGestionId == 1
                      || $scope.data.tcTipoGestion.tipoGestionId == 4) {
                      if ($scope.data.ttPlanificacionGestion == undefined || $scope.data.ttPlanificacionGestion == null) {
                        $scope.data.ttPlanificacionGestion = {
                          incremento: 0,
                          hayReforestacion: 1
                        };
                      }
                      dataVisible = tmpData;
                    } else {
                      $scope.visibleCriterio = false;
                      if ($scope.data.ttPlanificacionGestion == null) {
                        $scope.data.ttPlanificacionGestion = {
                          tcCriterioCorta: {
                            criterioCortaId: 1
                          },
                          hayReforestacion: 1
                        };
                      } else {
                        if ($scope.data.ttPlanificacionGestion.tcCriterioCorta == null) {
                          $scope.data.ttPlanificacionGestion.tcCriterioCorta = {
                            criterioCortaId: 1
                          };
                        }
                      }
                      for (var i = 0; i < tmpData.length; i++) {
                        if (tmpData[i].ocultar != 1) {
                          dataVisible.push(tmpData[i]);
                        }
                      }
                    }
                    $scope.dataTratamientoSilvicultural = dataVisible;
                    $scope.panel = {
                      dos: true,
                      openDos: false,
                      openTres: false,
                      tres: true,
                      openCuatro: false,
                      cuatro: true,
                      openCinco: false,
                      cinco: true,
                      openSeis: false,
                      seis: true,
                      openSiete: false,
                      siete: true
                    }

                    if ($scope.data.ttPlanificacionGestion.incremento == null) {
                      var data = $scope.data.rodal;
                      var incremento = 0;
                      for (var i = 0; i < data.length; i++) {
                        incremento += parseFloat(data[i].incremento);
                      }
                      incremento = incremento / data.length;
                      $scope.data.ttPlanificacionGestion.incremento = parseFloat(incremento).toFixed(2);
                    }

                    $scope.visibleFormula = true;

                    $scope.calculaAreaGarantia = function () {
                      //el calculo de la garantia es sobre la licencia y no para el primer turno
                      var value = 0, existente = 0;
                      $scope.data.ttPlanificacionGestion.areaIntervenir = parseFloat($scope.data.area).toFixed(4);
                      var data = $scope.data.rodal;
                      var areaMonto = 0;
                      var abrExtraerPoa = 0, abrPoa;
                      var i = 0;
                      for (i = 0; i < data.length; i++) {
                        if (data[i].abrPoa == null) {
                          data[i].abrPoa = 0;
                          data[i].abrExtraerPoa = 0;
                        }
                        var abrExtraer = 0;
                        var abr = 0;
                        for (var j = 0; j < data[i].especies.length; j++) {
                          value += parseFloat(data[i].especies[j].abrExtraer);
                          existente += parseFloat(data[i].especies[j].abr);
                          abrExtraer += parseFloat(data[i].especies[j].abrExtraer);
                          abr += parseFloat(data[i].especies[j].abr);
                        }
                        abrPoa = abr;
                        abrExtraerPoa = abrExtraer;
                        abrExtraer = abrExtraer / abr;
                        abrExtraer = abrExtraer * parseFloat(data[i].area);
                        abrExtraer = parseFloat(abrExtraer).toFixed(4);
                        areaMonto = parseFloat(areaMonto) + parseFloat(abrExtraer);
                        data[i].abrPoa = abrPoa;
                        data[i].abrExtraerPoa = parseFloat(abrExtraerPoa).toFixed(4);
                      }
                      for (i = 0; i < data.length; i++) {
                        value = (data[i].abrExtraerPoa * 100) / data[i].abrPoa;
                        value = Math.round(value);
                        data[i].porcentaje = value;
                        data[i].areaGarantia = data[i].area;
                        if (data[i].tcTipoCobertura == null) {
                          data[i].tcTipoCobertura = {
                            tipoCoberturaId: 1
                          };
                        }
                        if (data[i].tcTipoCobertura.tipoCoberturaId == 1) {
                          if (value > 70) {
                            data[i].areaCompromiso = data[i].area;
                          } else {
                            value = data[i].abrExtraerPoa / data[i].abrPoa;
                            value = value * data[i].area;
                            if (parseFloat(value) > parseFloat(data[i].area)) {
                              value = data[i].area;
                            }
                            data[i].areaCompromiso = parseFloat(value).toFixed(4);
                          }
                        } else {
                          var areaIdeal = 20;
                          if ($scope.data.tcTipoBosque.tipoBosqueId == 3) {
                            areaIdeal = 25;
                          }
                          value = data[i].abrExtraerPoa / areaIdeal;
                          if (parseFloat(value) > parseFloat(data[i].area)) {
                            value = data[i].area;
                          }
                          data[i].areaCompromiso = parseFloat(value).toFixed(4);
                          data[i].areaGarantia = data[i].areaCompromiso;
                        }
                      }
                      if (value > existente) {
                        value = existente;
                      } else {
                        var diferencia = existente - value;
                        diferencia = Math.abs(diferencia);
                        if (diferencia >= 0 && diferencia <= 0.03) { //si la diferencia esta entre -0.03 y 0.03 igualar
                          value = existente;
                        }
                      }
                      $scope.abrPoa = abrPoa;
                      $scope.abrExtraerPoa = abrExtraerPoa;
                      $scope.data.ttPlanificacionGestion.areaExistente = parseFloat(existente).toFixed(4);
                      $scope.data.ttPlanificacionGestion.areaExtraer = parseFloat(value).toFixed(4);
                      if (value == existente) {
                        $scope.data.ttPlanificacionGestion.areaCompromiso = $scope.data.ttPlanificacionGestion.areaIntervenir;
                      } else {
                        $scope.data.ttPlanificacionGestion.areaCompromiso = parseFloat(areaMonto).toFixed(4);
                      }
                      if (abrPoa > 0) {
                        value = (abrExtraerPoa * 100) / abrPoa;
                        $scope.porcentajePoa = Math.round(value);
                      } else {
                        $scope.porcentajePoa = 0;
                      }
                    }

                    $scope.calcularFantantePorRodal = function () {
                      var rodalesRepoblacion = $scope.data.rodalesRepoblacion;
                      var rodales = $scope.data.rodal;
                      if (rodalesRepoblacion == null) {
                        rodalesRepoblacion = [];
                      }
                      if (rodalesRepoblacion.length > 0) {
                        var densidad = parseInt(appSettings.densidad);
                        if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                          for (i = 0; i < rodalesRepoblacion.length; i++) {
                            var rodal = rodalesRepoblacion[i];
                            rodal.obligatorio = false;
                            if (rodal.densidadInicial == null) {
                              rodal.densidadInicial = 0;
                            }
                            var falta = densidad - parseInt(rodal.densidadInicial);
                            rodalesRepoblacion.falta = falta;
                          }
                        } else {
                          var ab = 0, abe = 0;
                          var i, j;
                          for (i = 0; i < rodales.length; i++) {
                            var rodal = rodales[i];
                            if (rodal.tcTipoCobertura.tipoCoberturaId != 3) {
                              var encontrado = false;
                              for (j = 0; j < rodalesRepoblacion.length; j++) {
                                var rodalRepoblacion = rodalesRepoblacion[j];
                                if (rodal.correlativo == rodalRepoblacion.correlativo) {
                                  encontrado = true;
                                  rodalRepoblacion.obligatorio = true;
                                  rodalRepoblacion.turno = rodal.turno;
                                  rodalRepoblacion.area = rodal.area;
                                  if (rodal.tcTipoCobertura.tipoCoberturaId == 2) {
                                    rodalRepoblacion.area = rodal.areaGarantia;
                                  }
                                  break;
                                }
                              }
                              if (!encontrado) {
                                var rodalRepoblacion = {
                                  obligatorio: true,
                                  turno: rodal.turno,
                                  area: rodal.area,
                                  anioPlantacion: null,
                                  correlativo: rodal.correlativo
                                };
                                rodalesRepoblacion.push(rodalRepoblacion);
                              }
                            }
                          }
                          for (i = 0; i < rodalesRepoblacion.length; i++) {
                            var rodalRepoblacion = rodalesRepoblacion[i];
                            var encontrado = false;
                            for (j = 0; j < rodales.length; j++) {
                              var rodal = rodales[j];
                              if (rodalRepoblacion.correlativo == rodal.correlativo) {
                                encontrado = true;
                                break;
                              }
                            }
                            if (!encontrado) {
                              rodalRepoblacion.obligatorio = false;
                            }
                          }
                          for (i = 0; i < rodalesRepoblacion.length; i++) {
                            var rodalRepoblacion = rodalesRepoblacion[i];
                            if (rodalRepoblacion.obligatorio == null) {
                              rodalRepoblacion.obligatorio = false;
                            }
                            if (rodalRepoblacion.obligatorio) {
                              ab = 0;
                              abe = 0;
                              var rodal = {};
                              for (j = 0; j < rodales.length; j++) {
                                rodal = rodales[j];
                                if (rodalRepoblacion.correlativo == rodal.correlativo) {
                                  break;
                                }
                              }
                              for (j = 0; j < rodal.especies.length; j++) {
                                ab += rodal.especies[j].abr;
                                abe += rodal.especies[j].abrExtraer;
                              }
                              ab = parseFloat(ab).toFixed(2);
                              abe = parseFloat(abe).toFixed(2);
                              var falta = abe / ab;
                              falta = falta * densidad;
                              falta = Math.round(falta);
                              var suma = 0;
                              if (rodalRepoblacion.repoblacion == null) {
                                rodalRepoblacion.repoblacion = [];
                              }
                              for (j = 0; j < rodalRepoblacion.repoblacion.length; j++) {
                                if (rodalRepoblacion.repoblacion[j].densidadInicial == null) {
                                  rodalRepoblacion.repoblacion[j].densidadInicial = 0;
                                }
                                suma += parseInt(rodalRepoblacion.repoblacion[j].densidadInicial);
                              }
                              if (falta > suma) {
                                falta = falta - suma;
                              } else {
                                falta = 0;
                              }
                              rodalRepoblacion.falta = falta;
                            } else {
                              var falta = densidad - parseInt(rodal.densidadInicial);
                              rodalesRepoblacion.falta = falta;
                            }
                          }
                        }
                      }
                    }

                    /*Validaciones iniciales*/
                    if ($scope.data.ttPlanificacionGestion != undefined) {
                      if ($scope.data.ttPlanificacionGestion.tcCriterioCorta != null) {
                        if ($scope.data.ttPlanificacionGestion.tcCriterioCorta.criterioCortaId == 2) {
                          $scope.visibleFormula = false;
                        }
                      } else {
                        $scope.data.ttPlanificacionGestion = {
                          incremento: 0,
                          hayReforestacion: 1
                        };
                      }
                      $scope.panel.dos = false;
                      $scope.panel.openDos = true;
                      if ($scope.data.ttPlanificacionGestion != null) {
                        $scope.panel.tres = false;
                        $scope.panel.openTres = true;
                        $scope.panel.openCuatro = true;
                        $scope.panel.cuatro = false;
                      }
                      if ($scope.data.ttPlanificacionGestion.actividadAprovechamiento != null) {
                        $scope.panel.openSeis = true;
                        $scope.panel.openCinco = true;
                        $scope.panel.seis = false;
                      }
                      if ($scope.data.ttPlanificacionGestion.areaIntervenir > 0) {
                        $scope.panel.openSiete = true;
                        $scope.panel.siete = false;
                        $scope.calcularFantantePorRodal();
                      }
                      if ($scope.data.ttPlanificacionGestion.areaCompromiso > 0) {
                        $scope.calculaAreaGarantia();
                      }
                    }

                    /*Fin Validaciones iniciales*/
                    /* Panel uno */
                    $scope.setCriterioCorta = function (item) {
                      if (item.criterioCortaId == 2) {
                        $scope.visibleFormula = false;
                        $scope.data.ttPlanificacionGestion.formula = "N/A";
                      } else {
                        $scope.visibleFormula = true;
                        $scope.data.ttPlanificacionGestion.formula = null;
                      }
                      $scope.data.ttPlanificacionGestion.tcCriterioCorta = item;
                    }

                    $scope.validarPanelUno = function (isValid) {
                      var i, j, incremento = 0;
                      if (isValid) {
                        var data = $scope.data.rodal;
                        var dataPlanCorta = [];
                        var arboles = 0;
                        var volumenTotal = 0, volumenTotalr = 0;
                        for (i = 0; i < data.length; i++) {
                          row = data[i];
                          incremento += parseFloat(row.incremento);
                          for (j = 0; j < data[i].especies.length; j++) {
                            volumenTotalr = volumenTotalr + parseFloat(data[i].especies[j].volr);
                            arboles = arboles + parseFloat(data[i].especies[j].arbolr);
                            volumenTotal = volumenTotal + parseFloat(data[i].especies[j].volh);
                          }
                        }
                        incremento = incremento / data.length;
                        $scope.data.ttPlanificacionGestion.incremento = parseFloat(incremento).toFixed(2);
                        var tmp = $scope.data;
                        var ttPlanificacionGestion = angular.copy($scope.data.ttPlanificacionGestion);
                        ttPlanificacionGestion.hayReforestacion = 1;
                        ttPlanificacionGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        gestionService.agregarDatosPlanificacion(ttPlanificacionGestion).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.data.ttPlanificacionGestion = ttPlanificacionGestion;
                            $scope.panel.openDos = true;
                            $scope.panel.dos = false;
                            //distribucion por rodal
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /* Fin panel uno */

                    /* Panel dos */

                    $scope.setTipoCobertura = function (item, rodal) {
                      if ($scope.data.tcTipoGestion.tipoGestionId != 1 && item.tipoCoberturaId != 1) {
                        toastr.error('El tipo de licencia solicitada no permite este tipo de cobertura');
                        rodal.tcTipoCobertura.tipoCoberturaId = 1;
                        return;
                      }
                      if (item.tipoCoberturaId == 2 && parseFloat(rodal.abh) > 8) {
                        rodal.tcTipoCobertura = null;
                        toastr.error('Para la cobertura de árboles dispersos, el área basal no puede ser mayor a 8 m²/ha');
                        return;
                      }
                      if (item.tipoCoberturaId == 3 && rodal.tcClaseDesarrollo.claseDesarrolloId != 7) {
                        toastr.error('No es posible agregar este tipo de cobertura, cambie la clase de desarrollo en la pestaña de inventario');
                        rodal.tcTipoCobertura = null;
                        return;
                      }
                      rodal.tcTipoCobertura.tipoCoberturaDesc = item.tipoCoberturaDesc;
                    }

                    $scope.setPorcentajeTratamiento = function (especie) {
                      var porcentaje = parseInt(especie.porcentaje);
                      if (porcentaje <= 0 || porcentaje > 100) {
                        toastr.error('El rango aceptado para el porcentaje debe ser mayor a cero y menor o igual a cien');
                        especie.porcentaje = null;
                        return;
                      }
                      especie.mostrarPGeneral = 1;
                      especie.mostrarPFuste = 0;
                      for (var i = 0; i < especie.distribucion.length; i++) {
                        var row = especie.distribucion[i];
                        row.pintervenir = porcentaje;
                        var valor = 0;
                        valor = (row.volr * row.pintervenir) / 100;
                        row.volrExtraer = parseFloat(valor).toFixed(4);
                        valor = (row.abr * row.pintervenir) / 100;
                        row.abrExtraer = parseFloat(valor).toFixed(4);
                        valor = (row.arbolr * row.pintervenir) / 100;
                        row.arbolrExtraer = Math.ceil(valor);
                      }
                    }

                    $scope.openCalidadFusteGeneral = function (especie, rodal) {
                      for (var i = 0; i < especie.distribucion.length; i++) {
                        var row = especie.distribucion[i];
                        try {
                          row.fuste = JSON.parse(row.fuste);
                          row.fuste = JSON.parse(row.fuste);
                        } catch (e) { }
                      }
                      if (especie.calidadFuste == undefined) {
                        var dataFuste = [];
                        tmpData = $scope.dataCalidadFuste;
                        for (var i = 0; i < tmpData.length; i++) {
                          dataFuste.push(
                            {
                              calidadFusteId: tmpData[i].calidadFusteId,
                              calidadFusteDesc: tmpData[i].calidadFusteDesc,
                              conteoArbol: 0,
                              abr: 0,
                              volr: 0,
                              pintervenir: 0,
                              arbolrExtraer: 0,
                              volrExtraer: 0,
                              abrExtraer: 0
                            }
                          );
                        }
                        especie.calidadFuste = dataFuste;
                      }
                      $scope.rodalClase = rodal;
                      $scope.especieClase = especie;
                      ngDialog.open(
                        {
                          template: 'app/gestion/fuste.general.tpl.html',
                          className: 'ngdialog-theme-default',
                          appendClassName: 'ngdialog-default',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueClase',
                          name: 'dialogClase',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr',
                            function ($scope, $state, toastr) {

                              $scope.setFusteGeneral = function (isValid) {
                                var dataTmp = angular.copy($scope.especieClase.calidadFuste);
                                if (isValid) {
                                  var pintervenir = 0;
                                  for (i = 0; i < dataTmp.length; i++) {
                                    pintervenir = parseInt(dataTmp[i].pintervenir);
                                    if (pintervenir < 0 || pintervenir > 100) {
                                      toastr.error('Porcentaje incorrecto', 'El rango permitido para el porcentaje es de 1 a 100');
                                      return;
                                    }
                                  }
                                }
                                if (isValid) {
                                  //primero buscar el rodal
                                  var data = $scope.data.rodal;
                                  var i, j, k, l, m;
                                  var encontrado = false;
                                  for (i = 0; i < data.length; i++) { //recorrer los rodales
                                    if (data[i].correlativo == $scope.rodalClase.correlativo) { //identificar el rodal que se está trabajando
                                      for (j = 0; j < data[i].especies.length; j++) { //recorrer las especies del rodal
                                        if (data[i].especies[j].tcEspecie.especieId == $scope.especieClase.tcEspecie.especieId) { //identificar la especie que se esta trabajando
                                          data[i].especies[j].mostrarPGeneral = 0;
                                          data[i].especies[j].mostrarPFuste = 1;
                                          if (data[i].especies[j].fuste == undefined) {
                                            data[i].especies[j].fuste = [];
                                            for (m = 0; m < dataTmp.length; m++) {
                                              data[i].especies[j].fuste.push(
                                                {
                                                  calidadFusteId: dataTmp[m].calidadFusteId,
                                                  calidadFusteDesc: dataTmp[m].calidadFusteDesc,
                                                  conteoArbol: 0,
                                                  abr: 0,
                                                  volumenRodal: 0,
                                                  pintervenir: 0,
                                                  arbolExtraer: 0,
                                                  abExtraer: 0,
                                                  volumenExtraer: 0
                                                }
                                              );
                                            }
                                            data[i].especies[j].mostrarFuste = 1;
                                          }
                                          for (k = 0; k < data[i].especies[j].distribucion.length; k++) { //recorrer las clases diametrica
                                            var distribucion = data[i].especies[j].distribucion[k];
                                            distribucion.pintervenir = 0;
                                            distribucion.volrExtraer = 0;
                                            distribucion.abrExtraer = 0;
                                            distribucion.arbolrExtraer = 0;
                                            for (l = 0; l < distribucion.fuste.length; l++) {
                                              var row = distribucion.fuste[l];
                                              for (m = 0; m < dataTmp.length; m++) {
                                                if (distribucion.fuste[l].calidadFusteId == dataTmp[m].calidadFusteId) {
                                                  var porcentaje = parseInt(dataTmp[m].pintervenir);
                                                  row.calidadFusteDesc = dataTmp[m].calidadFusteDesc;
                                                  row.pintervenir = porcentaje;
                                                  var valor = 0;
                                                  valor = (row.volr * row.pintervenir) / 100;
                                                  row.volrExtraer = parseFloat(valor).toFixed(4);
                                                  valor = (row.abr * row.pintervenir) / 100;
                                                  row.abrExtraer = parseFloat(valor).toFixed(2);
                                                  valor = (row.arbolr * row.pintervenir) / 100;
                                                  row.arbolrExtraer = Math.ceil(valor);
                                                  distribucion.pintervenir = parseFloat(distribucion.pintervenir) + porcentaje;
                                                  distribucion.volrExtraer = parseFloat(distribucion.volrExtraer) + row.volrExtraer;
                                                  distribucion.abrExtraer = parseFloat(distribucion.abrExtraer) + row.abrExtraer;
                                                  distribucion.arbolrExtraer = parseInt(distribucion.arbolrExtraer) + row.arbolrExtraer;
                                                  break;
                                                }
                                              }
                                            }
                                            if (distribucion.pintervenir > 0) {
                                              var valor = parseInt(distribucion.arbolrExtraer) * 100;
                                              valor = valor / parseInt(distribucion.arbolr);
                                              distribucion.pintervenir = Math.round(valor);
                                              distribucion.volrExtraer = parseFloat(distribucion.volrExtraer).toFixed(4);
                                              distribucion.abrExtraer = parseFloat(distribucion.abrExtraer).toFixed(2);
                                            }
                                          }
                                          //luego de sumar por fuste para todas las clases diametricas
                                          for (m = 0; m < data[i].especies[j].fuste.length; m++) {
                                            var fuste = data[i].especies[j].fuste[m];
                                            fuste.conteoArbol = 0;
                                            fuste.areaBasal = 0;
                                            fuste.volumen = 0;
                                            fuste.arbolr = 0;
                                            fuste.volr = 0;
                                            fuste.abr = 0;
                                            fuste.pintervenir = 0;
                                            fuste.arbolrExtraer = 0;
                                            fuste.volrExtraer = 0;
                                            fuste.abrExtraer = 0;
                                            for (k = 0; k < data[i].especies[j].distribucion.length; k++) { //recorrer las clases diametrica
                                              var distribucion = data[i].especies[j].distribucion[k];
                                              for (l = 0; l < distribucion.fuste.length; l++) {
                                                var row = distribucion.fuste[l];
                                                if (parseInt(fuste.calidadFusteId) == parseInt(row.calidadFusteId)) {
                                                  fuste.conteoArbol = parseInt(fuste.conteoArbol) + parseInt(row.conteoArbol);
                                                  valor = parseFloat(fuste.areaBasal) + parseFloat(row.areaBasal);
                                                  fuste.areaBasal = parseFloat(valor).toFixed(2);
                                                  valor = parseFloat(fuste.volumen) + parseFloat(row.volumen);
                                                  fuste.volumen = parseFloat(valor).toFixed(4);
                                                  valor = parseFloat(fuste.abr) + parseFloat(row.abr);
                                                  fuste.abr = parseFloat(valor).toFixed(2);
                                                  valor = parseFloat(fuste.volr) + parseFloat(row.volr);
                                                  fuste.volr = parseFloat(valor).toFixed(4);
                                                  fuste.arbolr = parseInt(fuste.arbolr) + parseInt(row.arbolr);
                                                  fuste.arbolrExtraer = parseInt(fuste.arbolrExtraer) + parseInt(row.arbolrExtraer);
                                                  valor = parseFloat(fuste.volrExtraer) + parseFloat(row.volrExtraer);
                                                  fuste.volrExtraer = parseFloat(valor).toFixed(4);
                                                  valor = parseFloat(fuste.abrExtraer) + parseFloat(row.abrExtraer);
                                                  fuste.abrExtraer = parseFloat(valor).toFixed(2);
                                                }
                                              }
                                              data[i].especies[j].distribucion[k] = distribucion;
                                            }
                                            //calcular porcentaje
                                            if (fuste.arbolr > 0) {
                                              var valor = parseInt(fuste.arbolrExtraer) * 100;
                                              valor = valor / parseInt(fuste.arbolr);
                                              fuste.pintervenir = Math.round(valor);
                                            }
                                            data[i].especies[j].fuste[m] = fuste;
                                          }
                                          break;
                                        }
                                      }
                                      break;
                                    }
                                  }
                                  ngDialog.close(); //cerrar el modal
                                }
                              }

                            }
                          ]
                        }
                      );
                    }

                    $scope.openCalidadFuste = function (row, especie, rodal) {
                      if (row.fuste.length > 0) {
                        if (row.fuste[0].calidadFusteDesc == undefined) {
                          for (var i = 0; i < row.fuste.length; i++) {
                            for (var j = 0; j < $scope.dataCalidadFuste.length; j++) {
                              if ($scope.dataCalidadFuste[j].calidadFusteId == row.fuste[i].calidadFusteId) {
                                row.fuste[i].calidadFusteDesc = $scope.dataCalidadFuste[j].calidadFusteDesc;
                                row.fuste[i].pintervenir = 0;
                                break;
                              }
                            }
                          }
                        }
                      }
                      if (especie.calidadFuste == undefined) {
                        var dataFuste = [];
                        tmpData = $scope.dataCalidadFuste;
                        for (var i = 0; i < tmpData.length; i++) {
                          dataFuste.push(
                            {
                              calidadFusteId: tmpData[i].calidadFusteId,
                              calidadFusteDesc: tmpData[i].calidadFusteDesc,
                              pintervenir: 0
                            }
                          );
                        }
                        especie.calidadFuste = dataFuste;
                      }
                      $scope.clase = row;
                      $scope.especieClase = especie;
                      $scope.rodalClase = rodal;
                      ngDialog.open(
                        {
                          template: 'app/gestion/clase.fuste.tpl.html',
                          className: 'ngdialog-theme-flat',
                          appendClassName: 'ngdialog-custom',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueClase',
                          name: 'dialogClase',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr',
                            function ($scope, $state, toastr) {

                              $scope.setPorcentajeClase = function (row) {
                                if (row.pintervenir == undefined) {
                                  row.pintervenir = 0;
                                }
                                if (row.pintervenir == null) {
                                  row.pintervenir = 0;
                                }
                                if (row.pintervenir < 0 || row.pintervenir > 100) {
                                  row.pintervenir = null;
                                  return;
                                }
                                var valor = 0;
                                valor = (row.volr * row.pintervenir) / 100;
                                row.volrExtraer = parseFloat(valor).toFixed(4);
                                valor = (row.abr * row.pintervenir) / 100;
                                row.abrExtraer = parseFloat(valor).toFixed(2);
                                valor = (row.arbolr * row.pintervenir) / 100;
                                row.arbolrExtraer = Math.ceil(valor);
                              }

                              $scope.submitFormFuste = function (isValid) {
                                if (isValid) {
                                  //primero buscar el rodal
                                  var data = $scope.data.rodal;
                                  var dataTmp = angular.copy($scope.especieClase.calidadFuste);
                                  var i, j, k, l, m;
                                  var encontrado = false;
                                  var conteo = 0;
                                  for (i = 0; i < data.length; i++) { //recorrer los rodales
                                    if (data[i].correlativo == $scope.rodalClase.correlativo) { //identificar el rodal que se está trabajando
                                      for (j = 0; j < data[i].especies.length; j++) { //recorrer las especies del rodal
                                        if (data[i].especies[j].tcEspecie.especieId == $scope.especieClase.tcEspecie.especieId) { //identificar la especie que se esta trabajando
                                          if (data[i].especies[j].fuste == null) {
                                            data[i].especies[j].fuste = [];
                                            for (m = 0; m < dataTmp.length; m++) {
                                              data[i].especies[j].fuste.push(
                                                {
                                                  calidadFusteId: dataTmp[m].calidadFusteId,
                                                  calidadFusteDesc: dataTmp[m].calidadFusteDesc,
                                                  conteoArbol: 0,
                                                  abr: 0,
                                                  volr: 0,
                                                  pintervenir: 0,
                                                  arbolrExtraer: 0,
                                                  volrExtraer: 0,
                                                  abrExtraer: 0
                                                }
                                              );
                                            }
                                            data[i].especies[j].mostrarFuste = 1;
                                          }
                                          for (k = 0; k < data[i].especies[j].distribucion.length; k++) { //recorrer las clases diametrica
                                            var distribucion = data[i].especies[j].distribucion[k];
                                            if (distribucion.tcClaseDiametrica.claseDiametricaId == $scope.clase.tcClaseDiametrica.claseDiametricaId) {
                                              distribucion.pintervenir = 0;
                                              distribucion.volrExtraer = 0;
                                              distribucion.abrExtraer = 0;
                                              distribucion.arbolrExtraer = 0;
                                              conteo = 0;
                                              for (l = 0; l < distribucion.fuste.length; l++) {
                                                var row = distribucion.fuste[l];
                                                if (parseFloat(row.pintervenir) > 0) {
                                                  distribucion.pintervenir = parseFloat(distribucion.pintervenir) + parseInt(row.pintervenir);
                                                  distribucion.volrExtraer = parseFloat(distribucion.volrExtraer) + parseFloat(row.volrExtraer);
                                                  distribucion.abrExtraer = parseFloat(distribucion.abrExtraer) + parseFloat(row.abrExtraer);
                                                  distribucion.arbolrExtraer = parseInt(distribucion.arbolrExtraer) + parseInt(row.arbolrExtraer);
                                                }
                                              }
                                              if (distribucion.pintervenir > 0) {
                                                var valor = parseInt(distribucion.arbolrExtraer) * 100;
                                                valor = valor / parseInt(distribucion.arbolr);
                                                distribucion.pintervenir = Math.round(valor);
                                                distribucion.volrExtraer = parseFloat(distribucion.volrExtraer).toFixed(4);
                                                distribucion.abrExtraer = parseFloat(distribucion.abrExtraer).toFixed(2);
                                              }
                                              break;
                                            }
                                          }
                                          //luego de sumar por fuste para todas las clases diametricas
                                          for (m = 0; m < data[i].especies[j].fuste.length; m++) {
                                            data[i].especies[j].fuste[m].conteoArbol = 0;
                                            data[i].especies[j].fuste[m].arbolr = 0;
                                            data[i].especies[j].fuste[m].abr = 0;
                                            data[i].especies[j].fuste[m].volr = 0;
                                            data[i].especies[j].fuste[m].pintervenir = 0;
                                            data[i].especies[j].fuste[m].arbolrExtraer = 0;
                                            data[i].especies[j].fuste[m].volrExtraer = 0;
                                            data[i].especies[j].fuste[m].abrExtraer = 0;
                                            for (k = 0; k < data[i].especies[j].distribucion.length; k++) { //recorrer las clases diametrica
                                              var distribucion = data[i].especies[j].distribucion[k];
                                              for (l = 0; l < distribucion.fuste.length; l++) {
                                                var row = distribucion.fuste[l];
                                                if (data[i].especies[j].fuste[m].calidadFusteId == row.calidadFusteId) {
                                                  data[i].especies[j].fuste[m].conteoArbol = parseInt(data[i].especies[j].fuste[m].conteoArbol) + parseInt(row.conteoArbol);
                                                  valor = parseFloat(data[i].especies[j].fuste[m].areaBasal) + parseFloat(row.areaBasal);
                                                  data[i].especies[j].fuste[m].areaBasal = parseFloat(valor).toFixed(2);
                                                  valor = parseFloat(data[i].especies[j].fuste[m].volumen) + parseFloat(row.volumen);
                                                  data[i].especies[j].fuste[m].volumen = parseFloat(valor).toFixed(2);
                                                  data[i].especies[j].fuste[m].arbolr = parseInt(data[i].especies[j].fuste[m].arbolr) + parseInt(row.arbolr);
                                                  valor = parseFloat(data[i].especies[j].fuste[m].abr) + parseFloat(row.abr);
                                                  data[i].especies[j].fuste[m].abr = parseFloat(valor).toFixed(2);
                                                  valor = parseFloat(data[i].especies[j].fuste[m].volr) + parseFloat(row.volr);
                                                  data[i].especies[j].fuste[m].volr = parseFloat(valor).toFixed(2);
                                                  if (row.arbolrExtraer != null) {
                                                    data[i].especies[j].fuste[m].arbolrExtraer = parseInt(data[i].especies[j].fuste[m].arbolrExtraer) + parseInt(row.arbolrExtraer);
                                                    valor = parseFloat(data[i].especies[j].fuste[m].volrExtraer) + parseFloat(row.volrExtraer);
                                                    data[i].especies[j].fuste[m].volrExtraer = parseFloat(valor).toFixed(4);
                                                    valor = parseFloat(data[i].especies[j].fuste[m].abrExtraer) + parseFloat(row.abrExtraer);
                                                    data[i].especies[j].fuste[m].abrExtraer = parseFloat(valor).toFixed(2);
                                                  }
                                                }
                                              }
                                            }
                                            if (data[i].especies[j].fuste[m].arbolr > 0) {
                                              var valor = parseInt(data[i].especies[j].fuste[m].arbolrExtraer) * 100;
                                              valor = valor / parseInt(data[i].especies[j].fuste[m].arbolr);
                                              data[i].especies[j].fuste[m].pintervenir = Math.round(valor);
                                            }
                                          }
                                          break;
                                        }
                                      }
                                      break;
                                    }
                                  }
                                  ngDialog.close(); //cerrar el modal
                                }
                              }
                            }
                          ]
                        }
                      );
                    }

                    $scope.setCalidadFuste = function (item, especie) {
                    }

                    $scope.removeCalidadFuste = function (item, model, especie) {

                    }

                    $scope.clearCalidadFusteGeneral = function (especie, rodal) {
                      especie.mostrarPFuste = 1;
                      especie.mostrarPGeneral = 1;
                      especie.porcentaje = 0;
                      especie.tcTratamientoSilvicultural = null;
                      for (var i = 0; i < especie.distribucion.length; i++) {
                        for (var j = 0; j < especie.distribucion[i].fuste.length; j++) {
                          especie.distribucion[i].fuste[j].abrExtraer = 0;
                          especie.distribucion[i].fuste[j].volrExtraer = 0;
                          especie.distribucion[i].fuste[j].pintervenir = 0;
                          especie.distribucion[i].fuste[j].arbolrExtraer = 0;
                        }
                        especie.distribucion[i].abrExtraer = 0;
                        especie.distribucion[i].volrExtraer = 0;
                        especie.distribucion[i].pintervenir = 0;
                        especie.distribucion[i].arbolrExtraer = 0;
                        especie.distribucion[i].tcTratamientoSilvicultural = null;
                      }
                      if (especie.fuste != undefined) {
                        especie.fuste = undefined;
                      }
                    }

                    $scope.setTratamientoSilviculturalEspecie = function (rowItem, especie, rodal) {
                      if (rodal.tcTipoCobertura.tipoCoberturaId != 1 && rowItem.tratamientoSilviculturalId != 2) {
                        toastr.error('El tipo de cobertura del rodal solo permite tratamiento de tala rasa');
                        especie.tcTratamientoSilvicultural = {};
                        return;
                      }
                      if (($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) && rowItem.tratamientoSilviculturalId != 2) {
                        toastr.error('El tipo solicitud solo permite tratamiento de tala rasa');
                        especie.tcTratamientoSilvicultural = {};
                        return;
                      }
                      var item = angular.copy(rowItem);
                      for (var i = 0; i < especie.distribucion.length; i++) {
                        switch (parseInt(item.tratamientoSilviculturalId)) {
                          case 2:
                            especie.distribucion[i].pintervenir = 100;
                            especie.distribucion[i].arbolrExtraer = especie.distribucion[i].arbolr;
                            especie.distribucion[i].volrExtraer = especie.distribucion[i].volr;
                            especie.distribucion[i].abrExtraer = especie.distribucion[i].abr;
                            especie.mostrarFuste = 0;
                            especie.fuste = null;
                            break;
                          case 3:
                            especie.distribucion[i].pintervenir = 100;
                            especie.distribucion[i].arbolrExtraer = especie.distribucion[i].arbolr;
                            especie.distribucion[i].volrExtraer = especie.distribucion[i].volr;
                            especie.distribucion[i].abrExtraer = especie.distribucion[i].abr;
                            especie.mostrarFuste = 0;
                            especie.fuste = null;
                            break;
                          case 6:
                            especie.distribucion[i].pintervenir = 0;
                            especie.distribucion[i].arbolrExtraer = 0;
                            especie.distribucion[i].volrExtraer = 0;
                            especie.distribucion[i].abrExtraer = 0;
                            especie.mostrarFuste = 0;
                            especie.fuste = null;
                            break;
                        }
                        especie.distribucion[i].tcTratamientoSilvicultural = item;
                      }
                      if ($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                        var totalPlaga = {
                          gorgojo: {
                            arbolr: 0,
                            volr: 0,
                            abr: 0
                          },
                          agente: {
                            arbolr: 0,
                            volr: 0,
                            abr: 0
                          },
                          bueno: {
                            arbolr: 0,
                            volr: 0,
                            abr: 0
                          }
                        }
                        for (var i = 0; i < especie.distribucion.length; i++) {
                          var fuste = especie.distribucion[i];
                          var gorgojo = fuste.gorgojo;
                          for (var j = 0; j < gorgojo.length; j++) {
                            totalPlaga.gorgojo.arbolr += parseFloat(gorgojo[j].arbolr);
                            totalPlaga.gorgojo.volr += parseFloat(gorgojo[j].volr);
                            totalPlaga.gorgojo.abr += parseFloat(gorgojo[j].abr);
                          }
                          var agente = fuste.agente;
                          for (var j = 0; j < agente.length; j++) {
                            totalPlaga.agente.arbolr += parseFloat(agente[j].arbolr);
                            totalPlaga.agente.volr += parseFloat(agente[j].volr);
                            totalPlaga.agente.abr += parseFloat(agente[j].abr);
                          }
                          var bueno = fuste.fuste;
                          for (var j = 0; j < gorgojo.length; j++) {
                            totalPlaga.bueno.arbolr += parseFloat(bueno[j].arbolr);
                            totalPlaga.bueno.volr += parseFloat(bueno[j].volr);
                            totalPlaga.bueno.abr += parseFloat(bueno[j].abr);
                          }
                        }
                        especie.fuste = totalPlaga;
                      }
                      especie.tcTratamientoSilvicultural.tratamientoSilviculturalDesc = item.tratamientoSilviculturalDesc;
                      if (item.tratamientoSilviculturalId == 1) { //si es tala selectiva
                        especie.mostrarPGeneral = 1;
                        especie.mostrarPFuste = 1;
                      } else {
                        especie.mostrarPGeneral = 0;
                        especie.mostrarPFuste = 0;
                      }
                    }

                    $scope.setCortaFinal = function (especie) {
                      if (especie.bndCortaFinal == 1) {
                        for (var i = 0; i < especie.distribucion.length; i++) {
                          especie.distribucion[i].tratamientoSilviculturalId = 2;
                          especie.distribucion[i].tratamientoSilviculturalDesc = "Tala rasa";
                          especie.distribucion[i].pintervenir = 100;
                          especie.distribucion[i].arbolExtraer = especie.distribucion[i].conteoArbol;
                          especie.distribucion[i].volumenExtraer = especie.distribucion[i].volumenRodal;
                          especie.distribucion[i].abExtraer = especie.distribucion[i].abr;
                        }
                        if ($scope.data.ttInventarioGestion.tratamiento == undefined || $scope.data.ttInventarioGestion.tratamiento == null) {
                          $scope.data.ttInventarioGestion.tratamiento = [];
                        }
                        var encontrado = false;
                        for (var i = 0; i < $scope.data.ttInventarioGestion.tratamiento.length; i++) {
                          if ($scope.data.ttInventarioGestion.tratamiento[i].tratamientoSilviculturalId == 2) {
                            encontrado = true;
                          }
                        }
                        if (!encontrado) {
                          $scope.data.ttInventarioGestion.tratamiento.push({ tratamientoSilviculturalId: 2, tratamientoSilviculturalDesc: 'Tala rasa' });
                        }
                      }
                    }

                    $scope.getVolumenTotalClase = function (row, especie, rodal) {
                      if ($scope.data.tcTipoGestion.tipoGestionId != 1
                        && $scope.data.tcTipoGestion.tipoGestionId != 4) {
                        row.pintervenir = 100;
                        return;
                      }
                      if (row.tcTratamientoSilvicultural.tratamientoSilviculturalId == 6) {
                        row.pintervenir = 0;
                        return;
                      }
                      if (row.pintervenir <= 0 || row.pintervenir > 100) {
                        row.pintervenir = null;
                        return;
                      }
                      if (rodal.tcTipoCobertura.tipoCoberturaId != 1 && row.pintervenir < 100) {
                        toastr.error('El tipo de cobertura del rodal no permite extraer menos del 100 %');
                        row.pintervenir = 100;
                        return;
                      }
                      var valor = 0;
                      valor = (row.volr * row.pintervenir) / 100;
                      row.volrExtraer = parseFloat(valor).toFixed(4);
                      valor = (row.abr * row.pintervenir) / 100;
                      row.abrExtraer = parseFloat(valor).toFixed(4);
                      valor = (row.arbolr * row.pintervenir) / 100;
                      row.arbolrExtraer = Math.ceil(valor);
                    }

                    $scope.setTratamientoSilviculturalClase = function (item, row, especie, update, rodal) {
                      if (update == undefined) {
                        update = false;
                      }
                      if (rodal.tcTipoCobertura.tipoCoberturaId != 1 && item.tratamientoSilviculturalId != 2) {
                        toastr.error('El tipo de cobertura del rodal solo permite tratamiento de tala rasa');
                        row.tcTratamientoSilvicultural = {};
                        return;
                      }
                      if (($scope.data.tcTipoGestion.tipoGestionId == 2 || $scope.data.tcTipoGestion.tipoGestionId == 8) && item.tratamientoSilviculturalId != 2) {
                        toastr.error('El tipo solicitud solo permite tratamiento de tala rasa');
                        row.tcTratamientoSilvicultural = {};
                        return;
                      }
                      if (update) {
                        row.tcTratamientoSilvicultural.tratamientoSilviculturalDesc = item.tratamientoSilviculturalDesc;
                        if (item.tratamientoSilviculturalId == 2 || item.tratamientoSilviculturalId == 3) {
                          row.arbolrExtraer = row.arbolr;
                          row.volrExtraer = row.volr;
                          row.abrExtraer = row.abr;
                          row.pintervenir = 100;
                        }
                        if (item.tratamientoSilviculturalId == 6) {
                          row.arbolrExtraer = 0;
                          row.volrExtraer = 0;
                          row.abrExtraer = 0;
                          row.pintervenir = 0;
                        }
                      }
                    }

                    $scope.recalcularExtraccionProducto = function () {
                      var i, j, k, l;
                      var recalcular = false;
                      for (i = 0; i < $scope.data.rodal.length; i++) {
                        var rodal = $scope.data.rodal[i];
                        for (j = 0; j < rodal.especies.length; j++) {
                          var especie = rodal.especies[j];
                          if (especie.ptroza > 0) {
                            recalcular = true;
                            break;
                          }
                        }
                      }
                      if (recalcular) {
                        for (i = 0; i < $scope.data.rodal.length; i++) {
                          var rodal = $scope.data.rodal[i];
                          for (j = 0; j < rodal.especies.length; j++) {
                            var especie = rodal.especies[j];
                            if (especie.ptroza > 100) {
                              especie.ptroza = 100;
                            }
                            if (especie.ptroza < 0) {
                              especie.ptroza = 0;
                            }
                            var value = (especie.volrExtraer * especie.ptroza) / 100;
                            especie.troza = parseFloat(value).toFixed(4);
                            value = parseFloat(especie.volrExtraer) - parseFloat(especie.troza);
                            especie.lenia = parseFloat(value).toFixed(4);
                          }
                        }
                      }
                    }

                    $scope.validarPanelDistribucionPlanificacion = function (isValid) {
                      if (isValid) {
                        var i, j, k, l;
                        for (i = 0; i < $scope.data.rodal.length; i++) {
                          var rodal = $scope.data.rodal[i];
                          for (j = 0; j < rodal.especies.length; j++) {
                            var especie = rodal.especies[j];
                            especie.porcentaje = 0;
                            especie.arbolrExtraer = 0;
                            especie.volrExtraer = 0;
                            especie.abrExtraer = 0;
                            for (k = 0; k < especie.distribucion.length; k++) {
                              var distribucion = especie.distribucion[k];
                              especie.porcentaje += parseFloat(distribucion.pintervenir);
                              especie.arbolrExtraer += parseInt(distribucion.arbolrExtraer);
                              especie.volrExtraer += parseFloat(distribucion.volrExtraer);
                              especie.abrExtraer += parseFloat(distribucion.abrExtraer);
                              if (distribucion.tratamientoSilviculturalId != 2) { //tala rasa
                                if (distribucion.tratamientoSilviculturalId != 6) { //ninguna
                                  especie.tratamientoSilviculturalId = 1;
                                  especie.tratamientoSilviculturalDesc = "Tala selectiva";
                                } else {
                                  especie.tratamientoSilviculturalId = distribucion.tratamientoSilviculturalId;
                                  especie.tratamientoSilviculturalDesc = distribucion.tratamientoSilviculturalDesc;
                                }
                              }
                            }
                            var length = especie.distribucion.length;
                            if (length > 0) {
                              especie.porcentaje = parseFloat(especie.porcentaje / length).toFixed(2);
                            } else {
                              especie.porcentaje = 0;
                            }
                          }
                          for (j = 0; j < rodal.especies.length; j++) {
                            var especie = rodal.especies[j];
                            //por decimales, en tala rasa puede haber una viariacion de decimales. Igualar para cuadrar
                            if (especie.arbolrExtraer > especie.arbolr) {
                              especie.arbolrExtraer = especie.arbolr;
                            }
                            if (especie.volrExtraer > especie.volr || especie.arbolrExtraer == especie.arbolr) {
                              especie.volrExtraer = especie.volr;
                            }
                            if (especie.abrExtraer > especie.abr || especie.arbolrExtraer == especie.arbolr) {
                              especie.abrExtraer = especie.abr;
                            }
                            if (especie.volrExtraer == especie.volr) {
                              especie.arbolrExtraer = especie.arbolr;
                              especie.abrExtraer = especie.abr;
                            }
                            especie.porcentaje = parseFloat(especie.porcentaje).toFixed(2);
                            especie.volrExtraer = parseFloat(especie.volrExtraer).toFixed(4);
                            especie.abrExtraer = parseFloat(especie.abrExtraer).toFixed(4);
                            especie.arbolResidual = especie.arbolr - especie.arbolrExtraer;
                            var valor = especie.abr - especie.abrExtraer;
                            especie.abResidual = parseFloat(valor).toFixed(2);
                            valor = especie.volr - especie.volrExtraer;
                            especie.volResidual = parseFloat(valor).toFixed(2);
                          }
                        }
                        var tmp = $scope.data;
                        var rodal = angular.copy(tmp.rodal);
                        for (i = 0; i < rodal.length; i++) {
                          for (j = 0; j < rodal[i].especies.length; j++) {
                            if (rodal[i].especies[j].fuste != null) {
                              rodal[i].especies[j].fuste = JSON.stringify(rodal[i].especies[j].fuste);
                            }
                            for (k = 0; k < rodal[i].especies[j].distribucion.length; k++) {
                              rodal[i].especies[j].distribucion[k].fuste = JSON.stringify(rodal[i].especies[j].distribucion[k].fuste);
                            }
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodal: rodal
                        }
                        gestionService.actualizarRodalInventario(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            for (i = 0; i < rodal.length; i++) {
                              for (j = 0; j < rodal[i].especies.length; j++) {
                                if (rodal[i].especies[j].fuste != null) {
                                  rodal[i].especies[j].fuste = JSON.parse(rodal[i].especies[j].fuste);
                                }
                                for (k = 0; k < rodal[i].especies[j].distribucion.length; k++) {
                                  rodal[i].especies[j].distribucion[k].fuste = JSON.parse(rodal[i].especies[j].distribucion[k].fuste);
                                }
                              }
                            }
                            if ($scope.data.ultimoPanel > 5) {
                              $scope.recalcularExtraccionProducto();
                              swal('Ratifique los volúmenes de traza y leña en el siguiente bloque y presione sobre el botón guardar o puede ocacionarle una enmienda al no realizarlo');
                              $scope.hayCambiosGuardar = true;
                            } else {
                              toastr.success(response.message);
                            }
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });

                      } else {
                        toastr.error('Asegúrese de planificar todas las especies de todos los rodales, seleccionar el tratamiento por especie y el tipo de cobertura por rodal, no es posible continuar');
                      }
                    }

                    $scope.validarAnioSilvicultura = function (rodal) {
                      var isValid = true;
                      if (rodal.anio == undefined) {
                        isValid = false;
                      } else {
                        if (rodal.anio == null) {
                          isValid = false;
                        } else {
                          if (rodal.anio < (new Date()).getFullYear()) {
                            isValid = false;
                          }
                        }
                      }
                      if (isValid) {
                        var rodales = $scope.data.rodal;
                        for (var i = 0; i < rodales.length; i++) {
                          var item = rodales[i];
                          if (item.turno == rodal.turno) {
                            item.anio = rodal.anio;
                          } else {
                            var anio = parseInt(rodal.anio);
                            anio = anio + item.turno - 1;
                            item.anio = anio;
                          }
                        }
                      } else {
                        rodal.anio = null;
                        toastr.error('Debe indicar un año válido y mayor o igual al actual');
                      }
                    }

                    $scope.getVolumenTotal = function (especie) {
                      if (parseFloat(especie.porcentaje) <= 0 || parseFloat(especie.porcentaje) >= 100) {
                        especie.porcentaje = 100;
                        especie.arbolExtraer = especie.arbolr;
                        especie.abExtraer = especie.abh;
                      } else {
                        var tmp = (especie.porcentaje * especie.abh) / 100;
                        especie.abExtraer = parseFloat(tmp).toFixed(2);
                      }
                      var value = (especie.porcentaje * especie.volumenTotalr) / 100;
                      especie.volumenExtraer = parseFloat(value).toFixed(2);
                    }

                    $scope.getVolumenTroza = function (especie) {
                      if (especie.ptroza > 100) {
                        especie.ptroza = 100;
                      }
                      if (especie.ptroza < 0) {
                        especie.ptroza = 0;
                      }
                      var value = (especie.volrExtraer * especie.ptroza) / 100;
                      especie.troza = parseFloat(value).toFixed(4);
                      value = parseFloat(especie.volrExtraer) - parseFloat(especie.troza);
                      especie.lenia = parseFloat(value).toFixed(4);
                    }

                    $scope.setTotalesRodal = function (rodal) {
                      rodal.lenia = 0;
                      rodal.troza = 0;
                      rodal.arbolrExtraer = 0;
                      rodal.volrExtraer = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        rodal.lenia = parseFloat(rodal.lenia) + parseFloat(rodal.especies[i].lenia);
                        rodal.troza = parseFloat(rodal.troza) + parseFloat(rodal.especies[i].troza);
                        rodal.arbolrExtraer = parseInt(rodal.arbolrExtraer) + parseInt(rodal.especies[i].arbolrExtraer);
                        rodal.volrExtraer = parseFloat(rodal.volrExtraer) + parseFloat(rodal.especies[i].volrExtraer);
                      }
                      rodal.troza = parseFloat(rodal.troza).toFixed(4);
                      rodal.lenia = parseFloat(rodal.lenia).toFixed(4);
                      rodal.arbolrExtraer = parseInt(rodal.arbolrExtraer);
                      rodal.volrExtraer = parseFloat(rodal.volrExtraer).toFixed(4);
                    }

                    $scope.setTratamientoSilvicultural = function (item, especie) {
                      if ($scope.data.ttInventarioGestion.tratamiento == undefined || $scope.data.ttInventarioGestion.tratamiento == null) {
                        $scope.data.ttInventarioGestion.tratamiento = [];
                      }
                      var encontrado = false;
                      for (var i = 0; i < $scope.data.ttInventarioGestion.tratamiento.length; i++) {
                        if ($scope.data.ttInventarioGestion.tratamiento[i].tratamientoSilviculturalId == item.tratamientoSilviculturalId) {
                          encontrado = true;
                        }
                      }
                      if (!encontrado) {
                        $scope.data.ttInventarioGestion.tratamiento.push(item);
                      }
                      especie.tratamientoSilviculturalDesc = item.tratamientoSilviculturalDesc;
                      if (item.tratamientoSilviculturalId == 2) {
                        especie.porcentaje = 100;
                        $scope.getVolumenTotal(especie);
                      }
                    }

                    $scope.validarPlanCorta = function (isValid) {
                      if (!isValid) {
                        toastr.error('Debe rellenar todos los campos');
                        return;
                      }

                      var tmp = $scope.data;
                      var rodal = angular.copy(tmp.rodal);
                      var i = 0, j = 0;
                      for (i = 0; i < rodal.length; i++) {
                        if (rodal[i].anio < (new Date()).getFullYear()) {
                          toastr.error('El año del rodal ' + rodal[i].correlativo + ' no es válido');
                          return;
                        }
                      }
                      var remanente = 0;
                      for (i = 0; i < rodal.length; i++) {
                        remanente = 0;
                        for (j = 0; j < rodal[i].especies.length; j++) {
                          if (rodal[i].especies[j].porcentaje > 0 && rodal[i].especies[j].troza == 0 && rodal[i].especies[j].lenia == 0) {
                            toastr.error('Debe indicar el porcentaje de troza y leña en todas las especies y rodales');
                            return;
                          }
                          remanente += rodal[i].especies[j].arbolResidual;
                          if (rodal[i].especies[j].fuste != null) {
                            delete rodal[i].especies[j].fuste;
                          }
                          for (k = 0; k < rodal[i].especies[j].distribucion.length; k++) {
                            delete rodal[i].especies[j].distribucion[k].fuste;
                          }
                        }
                        remanente = remanente / rodal[i].area;
                        remanente = Math.round(remanente);
                        rodal[i].remanente = remanente;
                      }
                      var objInventario = {
                        ttGestion: {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        },
                        rodal: rodal,
                        ttPlanificacionGestion: tmp.ttPlanificacionGestion
                      }
                      gestionService.actualizarTroza(objInventario).then(function (response) {
                        if (response.status == 'OK') {
                          for (i = 0; i < tmp.rodal.length; i++) {
                            for (j = 0; j < rodal.length; j++) {
                              if (tmp.rodal[i].correlativo == rodal[j].correlativo) {
                                tmp.rodal[i].remanente = rodal[j].remanente;
                                break;
                              }
                            }
                          }
                          if ($scope.data.tcTipoGestion.tipoGestionId == 1 || $scope.data.tcTipoGestion.tipoGestionId == 4) {
                            $scope.panel.openTres = true;
                            $scope.panel.tres = false;
                          } else {
                            $scope.panel.openCinco = true;
                            $scope.panel.cinco = false;
                          }
                          $scope.hayCambiosGuardar = false;
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                    /*Fin panel dos */

                    /* Panel tres */
                    $scope.getRestoArbol = function (row) {
                      var value = parseInt(row.arbolr) - parseInt(row.arbolExtraer);
                      if (isNaN(value)) {
                        return 0;
                      } else {
                        return value;
                      }
                    }

                    $scope.getRestoVolumen = function (row) {
                      var value = parseFloat(row.volumenTotalr) - parseFloat(row.volumenExtraer);
                      if (isNaN(value)) {
                        return 0;
                      } else {
                        return parseFloat(value).toFixed(2);
                      }
                    }

                    $scope.validarPanelTres = function () {
                      $scope.panel.openCuatro = true;
                      $scope.panel.cuatro = false;
                    }
                    /* Fin panel tres */

                    /*Panel cuatro*/
                    $scope.getSumaProductos = function (row) {
                      var value = parseFloat(row.resina) + parseFloat(row.semilla) + parseFloat(row.goma) + parseFloat(row.otro);
                      if (isNaN(value)) {
                        return 0;
                      } else {
                        value = parseFloat(value).toFixed(2);
                        row.peso = value;
                        return value;
                      }
                    }

                    /*Panel producto no maderables tab planificacion*/

                    $scope.setProducto = function (item, row) {
                      row.productoDesc = item.productoDesc;
                    }

                    $scope.setUnidadMedidad = function (item, row) {
                      row.unidadMedidaDesc = item.unidadMedidaDesc;
                    }

                    $scope.agregarProducto = function (rodal) {
                      if (rodal.producto == undefined || rodal.producto == null) {
                        rodal.producto = [];
                      }
                      var item = {
                        productoId: null,
                        productoDesc: null,
                        unidadMedidaId: null,
                        unidadMedidaDesc: null,
                        cantidad: null
                      };
                      rodal.producto.push(item);
                    }

                    $scope.quitarProducto = function (rodal, item) {
                      rodal.producto.splice(rodal.producto.indexOf(item), 1);
                    }

                    /*Fin Panel producto no maderables tab planificacion*/

                    $scope.validarPanelCuatro = function (isValid) {
                      if (!isValid) {
                        toastr.error("Debe ingresar todos los campos");
                        return;
                      }
                      var tmp = $scope.data;
                      var dataResumen = angular.copy(tmp.rodal);
                      isValid = false;
                      for (var i = 0; i < dataResumen.length; i++) {
                        if (dataResumen[i].productos != null) {
                          if (dataResumen[i].productos.length > 0) {
                            isValid = true;
                            break;
                          }
                        }
                      }
                      if (!isValid) {
                        $scope.panel.openCinco = true;
                      } else {
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            if (dataResumen[i].especies[j].fuste != null) {
                              delete dataResumen[i].especies[j].fuste;
                            }
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              delete dataResumen[i].especies[j].distribucion[k].fuste;
                            }
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodal: dataResumen
                        }
                        gestionService.actualizarPlanificacionNoMaderable(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.panel.openCinco = true;
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /*Fin panel cuatro*/

                    /* Panel cinco */

                    $scope.dataHayReforestacion = [
                      { hayReforestacion: 0, hayReforestacionDesc: 'Pago al fondo forestal privativo' },
                      { hayReforestacion: 1, hayReforestacionDesc: 'Reforestación de área equivalente a la transformada' }
                    ];

                    $scope.setHayReforestacion = function ( item ) {
                      if (item.hayReforestacion == 0) {
                        if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                          if ($scope.data.area > 1) {
                            swal('El área a intervenir es mayor a una hectárea, es muy problable que sea una enmienda ya que por lo general no está permitido seleccionar pago al Fondo Forestal Privativo');
                          }
                        }
                      }
                    }

                    $scope.validarPanelCinco = function (isValid) {
                      if (!isValid) {
                        toastr.error('Debe indicar todos los campos');
                        return;
                      }
                      if ($scope.data.ttPlanificacionGestion.actividadAprovechamiento != null) {
                        $scope.calculaAreaGarantia();
                        var tmp = $scope.data;
                        var ttPlanificacionGestion = angular.copy($scope.data.ttPlanificacionGestion);
                        ttPlanificacionGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        gestionService.actualizarAreaPlanificacion(ttPlanificacionGestion).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.panel.openSeis = true;
                            $scope.panel.seis = false;
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe describir las actividades de aprovechamiento');
                      }
                      var crearRodales = false;
                      if ($scope.data.tcTipoGestion != 5) {
                        if ($scope.data.rodalesRepoblacion == null) {
                          crearRodales = true;
                          $scope.data.rodalesRepoblacion = [];
                        } else {
                          if ($scope.data.rodalesRepoblacion.length <= 0) {
                            crearRodales = true;
                          }
                        }
                      }
                      var rodales = $scope.data.rodal;
                      if (crearRodales) {                        
                        var rodalesRepoblacion = [];
                        for (var i = 0; i < rodales.length; i++) {
                          var rodal = rodales[i];
                          var rodalRepoblacion = {
                            obligatorio: true,
                            turno: rodal.turno,
                            area: rodal.area,
                            anioPlantacion: null,
                            correlativo: rodal.correlativo
                          };
                          if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                            rodalRepoblacion.obligatorio = false;
                          }
                          rodalesRepoblacion.push(rodalRepoblacion);
                        }
                        $scope.data.rodalesRepoblacion = rodalesRepoblacion;
                      } else { //solo actualizar si es obligatorio o no
                        rodalesRepoblacion = $scope.data.rodalesRepoblacion;
                        if (rodalesRepoblacion == null) {
                          rodalesRepoblacion = [];
                        }
                        if (rodales == null) {
                          rodales = [];
                        }
                        for (var i = 0; i < rodales.length; i++) {
                          var rodal = rodales[i];
                          for (var j = 0; j < rodalesRepoblacion.length; j++) {
                            var rodalRepoblacion = rodalesRepoblacion[j];
                            if (rodal.correlativo == rodalRepoblacion.correlativo) {
                              if (rodal.tcTipoCobertura.tipoCoberturaId == 3 && rodalRepoblacion.obligatorio) {
                                rodalRepoblacion.obligatorio = false;
                              }
                              break;
                            }
                          }
                        }
                      }
                    }

                    $scope.validarPanelSieteNada = function () {
                      if ($scope.data.ttPlanificacionGestion.actividadAprovechamiento != null && $scope.data.ttPlanificacionGestion.postAprovechamiento != null) {
                        $scope.calculaAreaGarantia();
                        var tmp = $scope.data;
                        var ttPlanificacionGestion = angular.copy($scope.data.ttPlanificacionGestion);
                        ttPlanificacionGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        };
                        gestionService.actualizarAreaPlanificacion(ttPlanificacionGestion).then(function (response) {
                          if (response.status == 'OK') {
                            toastr.success(response.message);
                            $scope.setTabs(6);
                            $state.go('index.plan.resumen');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe describir las actividades de aprovechamiento y post aprovechamiento');
                      }
                    }
                    /* Fin panel cinco */

                    /* Panel seis */
                    $scope.validarPanelSeis = function () {
                      if ($scope.dataEspecie == null) {
                        $scope.dataEspecie = authService.getLocalData('dataEspecie');
                      }
                      $scope.panel.openSiete = true;
                      $scope.panel.siete = false;                      
                      $scope.calcularFantantePorRodal();
                    }
                    /* Fin panel seis */

                    /* Panel siete*/
                    $scope.agregarSistemaRepoblacion = function () {
                      ngDialog.open({
                        template: 'app/gestion/sistema.repoblacion.tpl.html',
                        className: 'ngdialog-theme-plain',
                        appendClassName: 'ngdialog-custom',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {

                            if ($scope.data.repoblacion == undefined || $scope.data.repoblacion == null) {
                              $scope.data.repoblacion = [];
                            }

                            $scope.sistemaRepoblacion = {};

                            $scope.label = "Seleccione"

                            $scope.setSistemaRepoblacion = function (item) {
                              $scope.sistemaRepoblacion.sistemaRepoblacionDesc = item.sistemaRepoblacionDesc;
                              switch (parseInt(item.sistemaRepoblacionId)) {
                                case 1:
                                  $scope.label = "*Número de arboles padres/ha.";
                                  break;
                                case 2:
                                  $scope.label = "*Número de tocones";
                                  break;
                                case 3:
                                  $scope.label = "*Número de plantas";
                                  break;
                                case 4:
                                  $scope.label = "*Semilla/ha (lbs)";
                                  break;
                                default:
                                  $scope.label = "*Número de arboles padres/ha.";
                                  break;
                              }
                            }

                            $scope.submitFormSistema = function (isValid) {
                              if (isValid) {
                                for (var i = 0; i < $scope.data.repoblacion.length; i++) {
                                  if ($scope.data.repoblacion[i].sistemaRepoblacionId == $scope.sistemaRepoblacion.sistemaRepoblacionId) {
                                    toastr.error("El sistema de repoblación seleccionado ya se ha agregado, solo cambie su valor para actualizar");
                                    isValid = false;
                                  }
                                }
                              }
                              if (isValid) {
                                $scope.sistemaRepoblacion.gestionId = $scope.data.gestionId;
                                gestionService.agregarSistemaRepoblacion($scope.sistemaRepoblacion).then(function (response) {
                                  if (response.status == 'OK') {
                                    $scope.data.repoblacion.push($scope.sistemaRepoblacion);
                                    toastr.success(response.message);
                                    ngDialog.close();
                                  } else {
                                    toastr.error(response.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }
                          }]
                      })
                    }

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                    }

                    $scope.actualizarSistemaRepoblacion = function (item) {
                      gestionService.agregarSistemaRepoblacion(item).then(function (response) {
                        if (response.status != 'OK') {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.agregarRodalRecuperacion = function () {
                      $scope.data.rodalesRepoblacion.push({
                        turno: 1,
                        falta: appSettings.densidad,
                        obligatorio: false
                      });
                    }

                    $scope.quitarRodalRecuperacion = function ( rodal ) {
                      $scope.data.rodalesRepoblacion.splice($scope.data.rodalesRepoblacion.indexOf(rodal), 1);
                    }

                    $scope.agregarEspecie = function (rodal) {
                      if ($scope.dataEspecie == null) {
                        $scope.dataEspecie = authService.getLocalData('dataEspecie');
                      }
                      if (rodal.repoblacion == undefined || rodal.repoblacion == null) {
                        rodal.repoblacion = [];
                      }
                      var item = {
                        tcEspecie: {
                          especieId: null
                        },
                        tcMetodoSiembra: {
                          metodoSiembraId: null
                        },
                        densidadInicial: null,
                        tcSistemaRepoblacion: {
                          sistemaRepoblacionId: null
                        }
                      };
                      rodal.repoblacion.push(item);
                    }

                    $scope.quitarEspecie = function (rodal, item) {
                      var suma = parseFloat(item.densidadInicial);
                      var tcSistemaRepoblacion = item.tcSistemaRepoblacion;
                      rodal.repoblacion.splice(rodal.repoblacion.indexOf(item), 1);
                      var falta = parseInt(appSettings.densidad);
                      if ($scope.data.tcTipoGestion.tipoGestionId != 5) {
                        var ab = 0, abe = 0;
                        for (var j = 0; j < rodal.especies.length; j++) {
                          ab += rodal.especies[j].abr;
                          abe += rodal.especies[j].abrExtraer;
                        }
                        ab = parseFloat(ab).toFixed(2);
                        abe = parseFloat(abe).toFixed(2);
                        falta = abe / ab;
                        falta = falta * parseInt(appSettings.densidad);
                        falta = Math.round(falta);
                      }
                      var suma = 0;
                      for (var i = 0; i < rodal.repoblacion.length; i++) {
                        if (rodal.repoblacion[i].densidadInicial == null) {
                          rodal.repoblacion[i].densidadInicial = 0;
                        }
                        suma += parseInt(rodal.repoblacion[i].densidadInicial);
                      }
                      if (falta > suma) {
                        falta = falta - suma;
                      } else {
                        falta = 0;
                      }
                      rodal.falta = falta;
                      var conteo = 0;
                      for (var i = 0; i < $scope.data.sistemaRepoblacion.length; i++) {
                        if ($scope.data.sistemaRepoblacion[i].tcSistemaRepoblacion.sistemaRepoblacionId == tcSistemaRepoblacion.sistemaRepoblacionId) {
                          conteo++;
                        }
                      }
                      if (conteo == 0) {
                        $scope.data.sistemaRepoblacion.splice($scope.data.sistemaRepoblacion.indexOf(tcSistemaRepoblacion), 1);
                      }
                    }

                    //Repoblacion
                    $scope.setEspecieRepoblacion = function (item, especie) {
                      especie.tcEspecie.especieDesc = item.especieDesc;
                      especie.tcEspecie.codigo = item.codigo;
                      especie.tcEspecie.sinonimo = item.sinonimo;
                      especie.tcEspecie.tcTipoEspecie = item.tcTipoEspecie;
                      especie.tcEspecie.tcTipoMadera = item.tcTipoMadera;
                    }

                    $scope.setMetodoSiembra = function (item, especie) {
                      especie.tcMetodoSiembra.metodoSiembraDesc = item.metodoSiembraDesc;
                    }

                    $scope.getFaltanteDensidad = function (rodalRepoblacion, especie) {
                      if (rodalRepoblacion.correlativo == null) {
                        especie.densidadInicial = null;
                        toastr.error('Por favor indique un número de rodal válido');
                        return;
                      }
                      var densidad = parseInt(appSettings.densidad);
                      var faltaOriginal = 0;
                      if ($scope.data.tcTipoGestion.tipoGestionId != 5) {
                        var rodal = {};
                        var rodales = $scope.data.rodal;
                        for (var i = 0; i < rodales.length; i++) {
                          rodal = rodales[i];
                          if (parseInt(rodal.correlativo) == parseInt(rodalRepoblacion.correlativo)) {
                            break;
                          }
                        }
                        if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                          faltaOriginal = appSettings.densidad;
                        } else {
                          ab = 0;
                          abe = 0;
                          for (j = 0; j < rodal.especies.length; j++) {
                            ab += rodal.especies[j].abr;
                            abe += rodal.especies[j].abrExtraer;
                          }
                          ab = parseFloat(ab).toFixed(2);
                          abe = parseFloat(abe).toFixed(2);
                          if (parseFloat(abe) > parseFloat(ab)) {
                            swal('Su planificación no es correcta, si redujo área o área basal debe volver a planificar. Actualmente para éste rodal tiene: ' + ab + ' de área basal y está extrayendo ' + abe + '. Favor de revisar');
                          }
                          faltaOriginal = parseFloat(abe) / parseFloat(ab);
                          faltaOriginal = faltaOriginal * densidad;
                          faltaOriginal = Math.round(faltaOriginal);
                        }
                      } else {
                        faltaOriginal = appSettings.densidad;
                      }
                      lleva = 0;
                      for (var i = 0; i < rodalRepoblacion.repoblacion.length; i++) {
                        var row = rodalRepoblacion.repoblacion[i];
                        if (row.densidadInicial == null) {
                          row.densidadInicial = 0;
                        }
                        lleva += parseInt(row.densidadInicial);
                      }
                      faltaOriginal -= lleva;
                      rodalRepoblacion.falta = faltaOriginal;
                      if (parseInt(rodalRepoblacion.falta) <= 0) {
                        rodalRepoblacion.falta = 0;
                      }
                    }

                    $scope.setItemSistemaRepoblacion = function (item, especie) {
                      especie.tcSistemaRepoblacion = item;
                      if ($scope.data.sistemaRepoblacion == undefined || $scope.data.sistemaRepoblacion == null) {
                        $scope.data.sistemaRepoblacion = [];
                      }
                      var encontrado = false;
                      var i;
                      for (i = 0; i < $scope.data.sistemaRepoblacion.length; i++) {
                        if ($scope.data.sistemaRepoblacion[i].tcSistemaRepoblacion.sistemaRepoblacionId == item.sistemaRepoblacionId) {
                          encontrado = true;
                          break;
                        }
                      }
                      if (!encontrado) {
                        $scope.data.sistemaRepoblacion.push(
                          {
                            tcSistemaRepoblacion: item
                          }
                        );
                      }
                    }

                    $scope.calcularFaltante = function (rodalRepoblacion, actualizar) {
                      if (actualizar == null) actualizar = false;
                      if (rodalRepoblacion.falta == undefined || actualizar) {
                        if ($scope.data.tcTipoGestion.tipoGestionId == 5 || !rodalRepoblacion.obligatorio) {
                          return parseInt(appSettings.densidad);
                        }
                        var rodal = {};
                        var rodales = $scope.data.rodal;
                        for (var i = 0; i < rodales.length; i++) {
                          rodal = rodales[i];
                          if (parseInt(rodalRepoblacion.correlativo) == parseInt(rodal.correlativo)) {
                            break;
                          }
                        }
                        var ab = 0, abe = 0;
                        for (var j = 0; j < rodal.especies.length; j++) {
                          ab += rodal.especies[j].abr;
                          abe += rodal.especies[j].abrExtraer;
                        }
                        ab = parseFloat(ab).toFixed(2);
                        abe = parseFloat(abe).toFixed(2);
                        var falta = abe / ab;
                        falta = falta * appSettings.densidad;
                        falta = Math.ceil(falta);
                        return falta;
                      }
                      return rodalRepoblacion.falta;
                    }

                    $scope.validarPanelSiete = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var rodal = angular.copy($scope.data.rodal);
                        var year = (new Date()).getFullYear();
                        var sistemaRepoblacion = [];
                        var i, j, k;
                        for (i = 0; i < rodal.length; i++) {
                          var faltante = $scope.calcularFaltante(rodal[i], true);
                          for (j = 0; j < rodal[i].especies.length; j++) {
                            if (rodal[i].especies[j].fuste != null) {
                              delete rodal[i].especies[j].fuste;
                            }
                            delete rodal[i].especies[j].distribucion;
                          }
                          if (rodal[i].anioRepoblacion < year) {
                            toastr.error('El año para el rodal ' + rodal[i].correlativo + ' no es válido');
                            return;
                          }
                          if (rodal[i].falta > 0) {
                            toastr.error('La densidad faltante del rodal ' + rodal[i].correlativo + ' debe ser cero');
                            return;
                          }
                          var densidad = 0;
                          for (j = 0; j < rodal[i].repoblacion.length; j++) {
                            var tcSistemaRepoblacion = rodal[i].repoblacion[j].tcSistemaRepoblacion;
                            densidad += parseInt(rodal[i].repoblacion[j].densidad);
                            var ok = false;
                            for (k = 0; k < sistemaRepoblacion.length; k++) {
                              if (tcSistemaRepoblacion.sistemaRepoblacionId == sistemaRepoblacion[k].tcSistemaRepoblacion.sistemaRepoblacionId) {
                                ok = true;
                                break;
                              }
                            }
                            if (!ok) {
                              sistemaRepoblacion.push(
                                {
                                  tcSistemaRepoblacion: tcSistemaRepoblacion
                                }
                              );
                            }
                          }
                          if (faltante > densidad) {
                            toastr.error('La densidad faltante del rodal ' + rodal[i].correlativo + ' debe ser cero');
                            return;
                          }
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodal: rodal,
                          sistemaRepoblacion: sistemaRepoblacion,
                          ttPlanificacionGestion: tmp.ttPlanificacionGestion
                        }
                        gestionService.actualizarRepoblacionPlanificacion(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            tmp.sistemaRepoblacion = sistemaRepoblacion;
                            toastr.success(response.message);
                            $scope.setTabs(6);
                            $state.go('index.plan.resumen');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }
                    /*Fin panel siete*/

                    $scope.validarPanelRepoblacionCambioUso = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var rodalesRepoblacion = angular.copy($scope.data.rodalesRepoblacion);
                        if (rodalesRepoblacion == null) {
                          rodalesRepoblacion = [];
                        }
                        if (rodalesRepoblacion.length <= 0) {
                          toastr.error('Debe agregar al menos un rodal para la repoblación');
                          return;
                        }
                        var year = (new Date()).getFullYear();
                        var sistemaRepoblacion = [];
                        var i, j, k;
                        var area = 0;
                        var correlativosRodal = [];
                        var rodales = $scope.data.rodal;
                        var areaValidar = 0;
                        for (i = 0; i < rodales.length; i++) {
                          var rodal = rodales[i];
                          if (rodal.tcTipoCobertura.tipoCoberturaId == 2 || rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                            areaValidar += parseFloat(rodal.areaGarantia);
                          } else {
                            areaValidar += parseFloat(rodal.area);
                          }
                        }
                        for (i = 0; i < rodalesRepoblacion.length; i++) {
                          if (correlativosRodal.length > 0) {
                            for (var j = 0; j < correlativosRodal.length; j++) {
                              var correlativo = correlativosRodal[j];
                              if (parseInt(correlativo) == parseInt(rodalesRepoblacion[i].correlativo)) {
                                toastr.error('El correlativo de rodal ' + rodalesRepoblacion[i].correlativo + ' está repetido, favor de revisar');
                                return;
                              }
                            }
                          }
                          correlativosRodal.push(rodalesRepoblacion[i].correlativo);
                          rodalesRepoblacion[i].area = parseFloat(rodalesRepoblacion[i].area).toFixed(4);
                          area = parseFloat(area) + parseFloat(rodalesRepoblacion[i].area);
                          var faltante = $scope.calcularFaltante(rodalesRepoblacion[i], true);
                          if (rodalesRepoblacion[i].anioPlantacion < year) {
                            toastr.error('El año para el rodal ' + rodalesRepoblacion[i].correlativo + ' no es válido');
                            return;
                          }
                          if (rodalesRepoblacion[i].falta > 0) {
                            toastr.error('La densidad faltante del rodal ' + rodalesRepoblacion[i].correlativo + ' debe ser cero');
                            return;
                          }
                          var densidad = 0;
                          if (rodalesRepoblacion[i].repoblacion == null) {
                            rodalesRepoblacion[i].repoblacion = [];
                          }
                          for (j = 0; j < rodalesRepoblacion[i].repoblacion.length; j++) {
                            var tcSistemaRepoblacion = rodalesRepoblacion[i].repoblacion[j].tcSistemaRepoblacion;
                            var tcEspecie = rodalesRepoblacion[i].repoblacion[j].tcEspecie;
                            var veces = 0;
                            var especies = rodalesRepoblacion[i].repoblacion;
                            for (var k = 0; k < especies.length; k++) {
                              if (tcSistemaRepoblacion.sistemaRepoblacionId == especies[k].tcSistemaRepoblacion.sistemaRepoblacionId
                                && tcEspecie.especieId == especies[k].tcEspecie.especieId) {
                                  veces++;
                              }
                            }
                            if (veces > 1) {
                              toastr.error('No es posible agregar dos veces la misma especie y el mismo sistema de repoblación en el rodal ' + rodalesRepoblacion[i].correlativo);
                              return;
                            }
                            densidad += parseInt(rodalesRepoblacion[i].repoblacion[j].densidad);
                            var ok = false;
                            for (k = 0; k < sistemaRepoblacion.length; k++) {
                              if (tcSistemaRepoblacion.sistemaRepoblacionId == sistemaRepoblacion[k].tcSistemaRepoblacion.sistemaRepoblacionId) {
                                ok = true;
                                break;
                              }
                            }
                            if (!ok) {
                              sistemaRepoblacion.push(
                                {
                                  tcSistemaRepoblacion: tcSistemaRepoblacion
                                }
                              );
                            }
                          }
                          if (faltante > densidad) {
                            toastr.error('La densidad faltante del rodal ' + rodalesRepoblacion[i].correlativo + ' debe ser cero');
                            return;
                          }
                        }
                        areaValidar = parseFloat(areaValidar).toFixed(4);
                        area = parseFloat(area).toFixed(4);
                        if (area < areaValidar) {
                          toastr.error('La sumatoria de las (' + area + ', ' + areaValidar + ') áreas no es equivalente al compromiso, favor de revisar');
                          return;
                        }
                        var objInventario = {
                          ttGestion: {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          },
                          rodalesRepoblacion: rodalesRepoblacion,
                          sistemaRepoblacion: sistemaRepoblacion,
                          ttPlanificacionGestion: tmp.ttPlanificacionGestion
                        }
                        gestionService.actualizarRecuperacionPlanificacion(objInventario).then(function (response) {
                          if (response.status == 'OK') {
                            tmp.sistemaRepoblacion = sistemaRepoblacion;
                            toastr.success(response.message);
                            $scope.setTabs(6);
                            $state.go('index.plan.resumen');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }

                  }] //end controller
              }
            }
          })
          .state('index.plan.resumen', {
            url: '/resumen',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.resumen.tpl.html',
                resolve: {
                  dataSistemaCorta: ['sistemacortaService',
                    function (sistemacortaService) {
                      return sistemacortaService.listaActiva();
                    }],
                  dataTipoGarantia: ['tipogarantiaService',
                    function (tipogarantiaService) {
                      return tipogarantiaService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'Upload', 'dataSistemaCorta', 'dataTipoGarantia', 'catalogosService',
                  function ($scope, $state, toastr, gestionService, Upload, dataSistemaCorta, dataTipoGarantia, catalogosService) {

                    $scope.dataTipoGarantia = dataTipoGarantia.data;
                    $scope.dataSistemaCorta = dataSistemaCorta.data;
                    $scope.requiereRegente = false;
                    $scope.requiereGarantia = false;
                    $scope.dataRegente = [];
                    $scope.dataTipoFiador = [
                      { tipoFiadorId: 0, tipoFiadorDesc: 'Individual' },
                      { tipoFiadorId: 1, tipoFiadorDesc: 'Empresa' }
                    ];

                    $scope.validarRequeridoRegente = function () {
                      var tmpData = $scope.data.rodal;
                      var sumaTotalVolumen = 0;
                      for (var i = 0; i < tmpData.length; i++) {
                        if (tmpData[i].turno == 1) {
                          for (var j = 0; j < tmpData[i].especies.length; j++) {
                            sumaTotalVolumen = sumaTotalVolumen + parseFloat(tmpData[i].especies[j].volrExtraer);
                          }
                        }
                      }
                      if (sumaTotalVolumen > 100) {
                        $scope.requiereRegente = true;
                        if ($scope.dataRegente.length <= 0) {
                          catalogosService.getListaRegentePorArea(parseFloat($scope.data.area)).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataRegente = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    }

                    if ($scope.data.ttPlanificacionGestion.hayReforestacion == 0
                      || $scope.data.tcTipoGestion.tipoGestionId == 2
                      || $scope.data.tcTipoGestion.tipoGestionId == 3
                      || $scope.data.tcTipoGestion.tipoGestionId == 8) {
                      $scope.requiereGarantia = false;
                    } else {
                      $scope.requiereGarantia = true;
                    }

                    $scope.panel = {
                      tiempo: false,
                      propuesta: true,
                      openTiempo: true,
                      openPropuesta: false,
                      requeridoPropietario: false,
                      classGrupal: 'btn btn-default btn-md glyphicon glyphicon-unchecked',
                      actualizarAreas: false,
                      editarPersona: false,
                      conteoPersonas: 0,
                      restanteProteccion: 0,
                      tituloMapaFinca: "Clic para agregar mapa de finca",
                      tituloMapaProteccion: "Clic para agregar mapa"
                    }

                    /*Validaciones iniciales*/
                    if ($scope.data.ttResumenGestion == null) {
                      $scope.data.ttResumenGestion = {
                        tcRegente: {
                          personaId: 0,
                          nit: null
                        },
                        tcSistemaCorta: {
                          sistemaCortaId: 1
                        }
                      };
                    }
                    if ($scope.data.ttResumenGestion.tcRegente != null) {
                      $scope.data.ttResumenGestion.tcRegente.cui = '' + $scope.data.ttResumenGestion.tcRegente.cui;
                    }
                    if ($scope.data.ttPlanificacionGestion.hayReforestacion == 0 && $scope.data.ttResumenGestion.tcSistemaCorta == null) {
                      $scope.data.ttResumenGestion.tcSistemaCorta = $scope.dataSistemaCorta[0];
                      $scope.data.ttResumenGestion.edadRotacion = 0;
                    }
                    if (parseInt($scope.data.ttResumenGestion.anios) > 0 && parseInt($scope.data.ttResumenGestion.meses) > 0) {
                      $scope.panel.openPropuesta = true;
                      $scope.panel.propuesta = false;
                    }

                    $scope.tratamientoSilvicultural = [];
                    //obtener numero de rodales
                    var noTurnos = 0, volumenManejar = 0, volumenExtraer = 0, volumenRemanente = 0;
                    for (var i = 0; i < $scope.data.rodal.length; i++) {
                      if (parseInt($scope.data.rodal[i].turno) > noTurnos) {
                        noTurnos = parseInt($scope.data.rodal[i].turno);
                      }
                      var rodal = $scope.data.rodal[i];
                      for (var j = 0; j < rodal.especies.length; j++) {
                        var tcTratamientoSilvicultural = rodal.especies[j].tcTratamientoSilvicultural;
                        var encontrado = false;
                        for (var k = 0; k < $scope.tratamientoSilvicultural.length; k++) {
                          if (tcTratamientoSilvicultural.tratamientoSilviculturalId == $scope.tratamientoSilvicultural[k].tratamientoSilviculturalId) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          $scope.tratamientoSilvicultural.push(tcTratamientoSilvicultural);
                        }
                      }
                    }
                    $scope.data.ttResumenGestion.noTurnos = noTurnos;
                    if (noTurnos > 1) {
                      $scope.textoExtraccion = "Tiempo de ejecución de la extracción para el primer turno (meses)";
                    } else {
                      $scope.textoExtraccion = "Tiempo de ejecución de la extracción (meses)";
                    }
                    //validar si es necesario regente
                    $scope.validarRequeridoRegente();
                    /*Fin Validaciones iniciales*/

                    /*Panel tiempo*/
                    $scope.setSistemaCorta = function (item) {
                      $scope.data.ttResumenGestion.tcSistemaCorta = item;
                    }

                    $scope.setTipoGarantia = function (item) {
                      $scope.data.ttResumenGestion.tcTipoGarantia = item;
                      if (item.tipoGarantiaId != 1) {
                        $scope.data.ttResumenGestion.nombreFiador = null;
                        $scope.data.ttResumenGestion.dpiFiador = null;
                      }
                    }

                    $scope.setRegente = function (item) {
                      $scope.data.ttResumenGestion.tcRegente.personaDesc = item.nombre;
                      $scope.data.ttResumenGestion.tcRegente.rf = item.noRegistro;
                      if (item.categoriaProfesion.trim().toLowerCase() == 'profesional') {
                        $scope.data.ttResumenGestion.categoriaProfesionId = 2;
                      } else {
                        $scope.data.ttResumenGestion.categoriaProfesionId = 1;
                      }
                    }

                    $scope.validarPanelTiempo = function (isValid, formulario) {
                      if (isValid) {
                        if ($scope.requiereRegente && $scope.data.ttResumenGestion.tcRegente.cui == null) {
                          toastr.error('El regente es obligatorio');
                          return;
                        }
                        if ($scope.data.ttPlanificacionGestion.hayReforestacion == 1 && $scope.data.tcTipoGestion.tipoGestionId != 2 && $scope.data.tcTipoGestion.tipoGestionId != 3) {
                          if ($scope.data.ttResumenGestion.tcTipoGarantia.tipoGestionId == 1) {
                            if ($scope.data.ttResumenGestion.nombreFiador == null ||
                              $scope.data.ttResumenGestion.dpiFiador == null) {
                              toastr.error('Es obligatorio indicar los datos del fiador');
                              return;
                            }
                          }
                          if (parseInt($scope.data.ttResumenGestion.anios) <= 0 && parseInt($scope.data.ttResumenGestion.meses) <= 0
                            && parseInt($scope.data.ttResumenGestion.edadRotacion) <= 0) {
                            toastr.error('Los valores deben ser mayores a cero');
                            return;
                          }
                        } else {
                          if (parseInt($scope.data.ttResumenGestion.anios) <= 0 && parseInt($scope.data.ttResumenGestion.meses) <= 0) {
                            toastr.error('Los valores deben ser mayores a cero');
                            return;
                          }
                        }
                        if (parseInt($scope.data.ttResumenGestion.anios) < 5) {
                          toastr.error('La cantidad de años debe ser mayor o igual a cinco');
                          return;
                        }
                        var tmp = $scope.data;
                        var ttResumenGestion = tmp.ttResumenGestion;
                        if (!$scope.requiereRegente) {
                          ttResumenGestion.tcRegente = null;
                        }
                        if (!$scope.requiereGarantia) {
                          ttResumenGestion.tcTipoGarantia = null;
                        }
                        ttResumenGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        gestionService.agregarDatosResumen(ttResumenGestion).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.panel.openPropuesta = true;
                            $scope.panel.propuesta = false;
                            $scope.data.ttResumenGestion = response.data[0];
                            toastr.success(response.message);
                            if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                              if ($scope.data.ttPlanificacionGestion.hayReforestacion == 0) {
                                $scope.setTabs(8);
                                $state.go('index.plan.cronograma');
                              } else {
                                $scope.setTabs(7);
                                $state.go('index.plan.proteccion');
                              }
                            } else {
                              $scope.setTabs(7);
                              $state.go('index.plan.proteccion');
                            }
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    /*Fin panel tiempo*/

                  }] //end controller
              }
            }
          })
          .state('index.plan.proteccion', {
            url: '/proteccion',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.proteccion.tpl.html',
                resolve: {
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService',
                  function ($scope, $state, toastr, gestionService) {

                    $scope.visibleSegunArea = function (x) {
                      if ($scope.data.tcPlanTipoGestion.planTipoGestionId == 3 && x == 15 && $scope.data.area > 15 && $scope.data.area <= 45) {
                        return true;
                      }
                      if ($scope.data.tcPlanTipoGestion.planTipoGestionId == 3 && x == 45 && $scope.data.area > 45) {
                        return true;
                      }
                      return false;
                    }

                    if ($scope.data.ttProteccionGestion == undefined || $scope.data.ttProteccionGestion == null) {
                      $scope.data.ttProteccionGestion = {};
                    }

                    $scope.submitFormProteccion = function (isValid) {
                      if (isValid) {
                        var tmp = $scope.data;
                        var ttProteccionGestion = tmp.ttProteccionGestion;
                        ttProteccionGestion.ttGestion = {
                          gestionId: tmp.gestionId,
                          tcTipoGestion: tmp.tcTipoGestion,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          tcTipoBosque: tmp.tcTipoBosque,
                          tcElaborador: tmp.tcElaborador,
                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        gestionService.agregarMedidaProteccion(ttProteccionGestion).then(function (response) {
                          if (response.status == 'OK') {
                            //activar el siguiente tab en caso de no estarlo
                            //mover a tab resumen de plan
                            tmp.ttProteccionGestion = response.data[0];
                            toastr.success(response.message);
                            $scope.setTabs(8);
                            $state.go('index.plan.cronograma');
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                  }]
              }
            }
          })
          .state('index.plan.cronograma', {
            url: '/cronograma',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.cronograma.tpl.html',
                resolve: {
                  dataActividad: ['actividadService',
                    function (actividadService) {
                      return actividadService.listaJerarquica();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataActividad', 'utilsService', '$timeout',
                  function ($scope, $state, toastr, gestionService, dataActividad, utilsService, $timeout) {
                    var limite = parseInt($scope.data.ttResumenGestion.noTurnos) - 1;
                    var anios = parseInt($scope.data.ttResumenGestion.anios);
                    if ($scope.data.tcTipoGestion.tipoGestionId == 1 
                      || $scope.data.tcTipoGestion.tipoGestionId == 7) {
                        if (anios < 5) {
                          anios = 5;
                          swal('En la pestaña de Resumen debe indicar al menos cinco años de vigencia del plan');
                        }
                    }
                    //por defecto son cinco mas la cantidad de turnos menos uno
                    limite = anios + limite;
                    //si es cambio de uso es solo y no hay reforestación entonces solo un año
                    if ($scope.data.tcTipoGestion.tipoGestionId == 5) {
                      if ($scope.data.ttPlanificacionGestion.hayReforestacion == 0) {
                        limite = 1;
                      }
                    }
                    $scope.listYear = [];
                    for (var i = 0; i < limite; i++) {
                      $scope.listYear.push(
                        {
                          texto: "Año " + (i + 1),
                          valor: i + 1
                        }
                      );
                    }

                    $scope.datos = {
                      lista: []
                    };
                    $scope.numberYear = 1;
                    $scope.getMonthsOfYear = function (mostrar, esObligatorio) {
                      var data = [];
                      for (var i = 1; i <= 12; i++) {
                        var item = {
                          mes: i,
                          activo: 0,
                          mostrar: mostrar,
                          esObligatorio: esObligatorio
                        }
                        data.push(item);
                      }
                      return data;
                    }
                    if ($scope.data.cronograma != null) {
                      if ($scope.data.cronograma.length > limite) {
                        $scope.data.cronograma = [];
                      }
                    } else {
                      $scope.data.cronograma = [];
                    }
                    dataActividad = dataActividad.data;
                    var item = {};
                    var obligatorio = "";
                    for (var i = 0; i < limite; i++) {
                      if (i >= $scope.data.cronograma.length) {
                        var actividad = [];
                        for (var j = 0; j < dataActividad.length; j++) {
                          if (i == 0 && parseInt(dataActividad[j].esObligatorio) == 1) {
                            obligatorio = "* obligatorio";
                          } else {
                            obligatorio = "";
                          }
                          item = {
                            actividadDesc: dataActividad[j].actividadDesc,
                            obligatorio: obligatorio,
                            esObligatorio: dataActividad[j].esObligatorio,
                            mes: $scope.getMonthsOfYear(false, false)
                          }
                          actividad.push(item);
                          for (var k = 0; k < dataActividad[j].hijos.length; k++) {
                            if (i == 0 && parseInt(dataActividad[j].hijos[k].esObligatorio) == 1) {
                              obligatorio = "* obligatorio";
                            } else {
                              obligatorio = "";
                            }
                            item = {
                              actividadDesc: dataActividad[j].hijos[k].actividadDesc,
                              obligatorio: obligatorio,
                              mes: $scope.getMonthsOfYear(true, dataActividad[j].hijos[k].esObligatorio)
                            }
                            actividad.push(item);
                          }
                        }
                        item = {
                          anio: i + 1,
                          actividad: actividad
                        }
                        $scope.data.cronograma.push(item);
                      }
                    }
                    $scope.datos.lista = $scope.data.cronograma[$scope.numberYear - 1].actividad;

                    $scope.getClassButton = function (year) {
                      var strClass = "btn btn-default";
                      if ($scope.numberYear == year) {
                        strClass = "btn btn-success";
                      }
                      return strClass;
                    }

                    $scope.setAnio = function (number) {
                      $scope.numberYear = number;
                      $scope.datos.lista = $scope.data.cronograma[$scope.numberYear - 1].actividad;
                    }

                    $scope.setActivo = function (item, id) {
                      if (item.mes[id - 1].activo == 1) {
                        item.mes[id - 1].activo = 0;
                      } else {
                        item.mes[id - 1].activo = 1;
                      }
                    }

                    $scope.validarObligatorios = function () {
                      var data = $scope.data.cronograma[0].actividad;
                      var contar = 0;
                      var obligatorio = true;
                      for (var i = 0; i < data.length; i++) {
                        obligatorio = (data[i].mes[0].esObligatorio == 1);
                        if (obligatorio) {
                          for (var j = 0; j < data[i].mes.length; j++) {
                            if (data[i].mes[j].activo == 1) {
                              obligatorio = false;
                              break;
                            }
                          }
                        }
                        if (obligatorio) {
                          return true;
                        }
                      }
                      return false;
                    }

                    $scope.validarPlanificacionAnios = function () {
                      var data = $scope.data.cronograma;
                      var continuar = false;
                      for (var i = 0; i < data.length; i++) {
                        var anio = data[i];
                        continuar = false;
                        for (var j = 0; j < anio.actividad.length; j++) {
                          actividad = anio.actividad[j];
                          for (var k = 0; k < actividad.mes.length; k++) {
                            if (actividad.mes[k].activo == 1) {
                              continuar = true;
                              break;
                            }
                          }
                          if (continuar) {
                            break;
                          }
                        }
                        if (!continuar) {
                          return false;
                        }
                      }
                      return true;
                    }

                    $scope.getMostrar = function (item, id) {
                      if (item.mes[id - 1].mostrar == true) {
                        return true;
                      } else {
                        return false;
                      }
                    }

                    $scope.getClass = function (item, id) {
                      for (var i = 0; i < item.mes.length; i++) {
                        if (item.mes[i].mes == id) {
                          if (item.mes[i].activo == 1) {
                            return "btn btn-dark btn-lg";
                          } else {
                            return "btn btn-default btn-lg";
                          }
                        }
                      }
                      return "btn btn-default btn-lg";
                    }

                    $scope.validarCronograma = function () {
                      if ($scope.validarObligatorios()) {
                        toastr.error('Debe planificar al menos las actividades obligatorias');
                        return;
                      } else {
                        if ($scope.validarPlanificacionAnios() == false) {
                          toastr.error('Debe planificar todos los años del cronograma');
                          return;
                        }
                      }
                      var tmp = $scope.data;
                      var cronograma = JSON.stringify(tmp.cronograma);
                      var ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId,
                        cronograma: cronograma,
                        observacionesCronograma: tmp.observacionesCronograma
                      }
                      gestionService.actualizarCronograma(ttGestion).then(function (res) {
                        if (res.status == "OK") {
                          //activar el siguiente tab en caso de no estarlo
                          //mover a tab resumen de plan
                          $scope.setTabs(9);
                          $state.go('index.plan.anexo');
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.imprimiendoCronograma = false;
                    $scope.textoImprimiendoCronograma = "Imprimir";

                    $scope.imprimirCronograma = function () {
                      $scope.globales.totalPaginas = utilsService.getLastPage();
                      $scope.globales.totalPaginasPoa = utilsService.getLastPagePoa();
                      if ($scope.globales.totalPaginas <= 0) {
                        toastr.error("Debe imprimir primero el plan luego el cronograma");
                      } else {
                        toastr.info('Generando cronograma, por favor espere...');
                        $scope.imprimiendoCronograma = true;
                        $scope.textoImprimiendoCronograma = "Imprimiendo";
                        utilsService.generarCronograma($scope.dataEmpresa, $scope.data, $scope.globales.totalPaginas);
                        $timeout(function () {
                          $scope.imprimiendoCronograma = false;
                          $scope.textoImprimiendoCronograma = "Imprimir";
                        }, 1000);
                        if ($scope.data.ttResumenGestion.noTurnos > 1) {
                          $timeout(function () {
                            var totalPaginas = $scope.globales.totalPaginasPoa;                            
                            utilsService.generarCronograma($scope.dataEmpresa, $scope.data, totalPaginas, 0, 1);
                          }, 3000);
                        }
                      }
                    }

                  }]
              }
            }
          })
          .state('index.plan.anexo', {
            url: '/anexo',
            views: {
              '': {
                templateUrl: 'app/gestion/plan.anexo.tpl.html',
                resolve: {
                  dataAnexo: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getListaAnexoRequisito($base64.decode($stateParams.gestionId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataAnexo', "$filter", "Upload", 'catalogosService',
                  function ($scope, $state, toastr, gestionService, dataAnexo, $filter, Upload, catalogosService) {
                    if (dataAnexo.status == 'error') {
                      swal('No es posible hacer la carga de anexos debido a que el plan está incompleto, revise la pestaña general y datos de la finca');
                    }
                    if ($scope.data.anexo != undefined) {
                      if ($scope.data.anexo != null) {
                        for (var j = 0; j < $scope.data.anexo.length; j++) {
                          $scope.data.anexo[j].visible = true;
                          if ($scope.data.anexo[j].tcTipoAnexo.tipoAnexoId == 2 || $scope.data.anexo[j].tcTipoAnexo.tipoAnexoId == 3) {
                            $scope.data.anexo[j].visible = false;
                          }
                          if ($scope.data.anexo[j].anexoGestionId != 0) {
                            $scope.data.anexo[j].visibleAbrir = true;
                            $scope.data.anexo[j].classButton = "btn btn-primary";
                            $scope.data.anexo[j].texto = "Cambiar";
                          } else {
                            $scope.data.anexo[j].classButton = "btn btn-danger";
                            $scope.data.anexo[j].texto = "Agregar";
                          }
                        }
                        var encontrado = false;
                        for (var i = 0; i < dataAnexo.data.length; i++) {
                          encontrado = false;
                          for (var j = 0; j < $scope.data.anexo.length; j++) {
                            if (dataAnexo.data[i].tipoAnexoId == $scope.data.anexo[j].tcTipoAnexo.tipoAnexoId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            var item = {
                              anexoGestionId: 0,
                              estadoId: 1,
                              size: 0,
                              ruta: null,
                              nombre: null,
                              extension: null,
                              tcTipoAnexo: dataAnexo.data[i],
                              visible: true,
                              texto: "Agregar",
                              visibleAbrir: false,
                              classButton: "btn btn-danger"
                            }
                            $scope.data.anexo.push(item);
                          }
                        }
                      }
                    }

                    $scope.abrirAnexo = function (anexoGestionId) {
                      gestionService.getAnexoGestion(anexoGestionId);
                    }

                    $scope.onFileSelect = function (file, tipoAnexoId) {
                      Upload.upload({
                        url: appSettings.urlServiceBase + 'file/upload',
                        method: 'POST',
                        file: file,
                        sendFieldsAs: 'form',
                        fields: {
                          data: []
                        }
                      }).then(function (resp) {
                        var result = resp.data;
                        if (result.status == 'OK') {
                          var item = result.data[0];
                          //send to database
                          var item = {
                            tcTipoAnexo: {
                              tipoAnexoId: tipoAnexoId
                            },
                            ruta: item.rutaArchivo,
                            nombre: item.nombre,
                            size: item.size,
                            extension: item.extension
                          };
                          var tmp = $scope.data;
                          item.ttGestion = {
                            gestionId: tmp.gestionId,
                            tcTipoGestion: tmp.tcTipoGestion,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea,
                            tcTipoBosque: tmp.tcTipoBosque,
                            tcElaborador: tmp.tcElaborador,
                            ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          };
                          gestionService.agregarAnexo(item).then(function (response) {
                            if (response.status == 'OK') {
                              if (tipoAnexoId > 1) {
                                for (var i = 0; i < $scope.data.anexo.length; i++) {
                                  if ($scope.data.anexo[i].tcTipoAnexo.tipoAnexoId == tipoAnexoId) {
                                    tipoAnexoDesc = $scope.data.anexo[i].tcTipoAnexo.tipoAnexoDesc;
                                    $scope.data.anexo[i] = response.data[0];
                                    $scope.data.anexo[i].visibleAbrir = true;
                                    $scope.data.anexo[i].classButton = "btn btn-primary";
                                    $scope.data.anexo[i].texto = "Cambiar";
                                    $scope.data.anexo[i].visible = true;
                                    $scope.data.anexo[i].tcTipoAnexo.tipoAnexoDesc = tipoAnexoDesc;
                                    break;
                                  }
                                }
                              }
                              toastr.success(response.message);
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        } else {
                          toastr.error(resp.data.message)
                        }
                      }, function (resp) {
                        toastr.error('Error status: ' + resp.status);
                      }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.avance = progressPercentage;
                      });
                    }

                    $scope.finalizarElaboracion = function () {
                      for (var i = 0; i < $scope.data.anexo.length; i++) {
                        var obligatorio = false;
                        if ($scope.data.anexo[i].tcTipoAnexo.tcGrupoRequisito != null) {
                          obligatorio = ($scope.data.anexo[i].tcTipoAnexo.tcGrupoRequisito.obligatorio == 1);
                        }
                        if (!$scope.data.anexo[i].visibleAbrir && obligatorio) {
                          toastr.error('Debe anexar todos los archivos solicitados');
                          return;
                        }
                      }
                      swal({
                        title: "¿Está seguro de finalizar la elaboración del plan y notificar al solicitante que puede proceder a su revisión y/o envío al INAB?",
                        text: "Asegúrese que: \n1. Los datos presentados sean correctos \n2. haber anexado todos los documentos solicitados \nEl no hacerlo le ocasionará inconvenientes. "
                          + "\nEsta acción no hará que el plan llegue a la bandeja de l INAB, debe realizarlo el propietario",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var tmp = $scope.data;
                            var ttGestion = {
                              gestionId: tmp.gestionId,
                              tcTipoGestion: tmp.tcTipoGestion,
                              estadoId: tmp.estadoId,
                              tcPersonaCrea: tmp.tcPersonaCrea,
                              tcTipoBosque: tmp.tcTipoBosque,
                              tcElaborador: tmp.tcElaborador,
                              ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                              personaAnulaId: $scope.loginData.personaId
                            }
                            gestionService.finalizarElaboracion(ttGestion).then(function (response) {
                              if (response.status == 'OK') {
                                toastr.success(response.message);
                                $state.go("index.gestion.elaborado");
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                  }]
              }
            }
          })
      }
    ]
  );
