angular.module('app.manual', [
  'ui.router',
  'toastr',
  'app.manual.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.manual', {
          abstract: true,
          url: 'manual',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope', 'Upload', 'toastr', 'manualService',
            function (  $scope, Upload, toastr, manualService) {
              $scope.module = 'Guias para el usuario';
              $scope.abrirAnexo = function (manualId) {
                manualService.getFile( manualId );
              }
            }]
        })
        .state('index.manual.list', {
          url: '',
          templateUrl: 'app/manual/manual.list.tpl.html',
          resolve: {
            dataManual: ['manualService',
              function ( manualService ){
                return manualService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataManual', 'manualService',
            function (  $scope,   $state,   dataManual, manualService) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'manualDesc', name: 'manualDesc', displayName: 'Manual' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.abrirAnexo(row.entity.manualId)" title="Ver archivo"> Ver </button>'
                    + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"> Editar </button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataManual.data;

              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ manualId: row.manualId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.manual.add', {
          url: '/add',
          templateUrl: 'app/manual/manual.add.tpl.html',
          resolve: {
            dataEstado: ['manualService',
              function ( manualService ){
                return manualService.listStatus();
              }],
            dataTipo: ['manualService',
              function ( manualService ){
                return manualService.listaTipo();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'manualService', 'dataEstado', 'Upload', 'dataTipo',
            function (  $scope,   $state,   toastr,   manualService, dataEstado, Upload, dataTipo) {
              $scope.data = {
                manualId: null,
                rutaArchivo: null
              };
              $scope.dataTipo = dataTipo.data;
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;

              $scope.visibleAbrir = function () {
                if ($scope.data.rutaArchivo != undefined) {
                  if ($scope.data.rutaArchivo != null && $scope.data.manualId != null) {
                    return true;
                  }
                }
                return false;
              }

              $scope.setTipoArchivo = function ( item ) {
                if (item.tipoArchivoId == 2) {
                  $scope.data.extension = "video";
                }
              }

              $scope.onFileSelect = function (file, tipo) {
                Upload.upload({
                  url: appSettings.urlServiceBase + 'file/upload',
                  method: 'POST',
                  file: file,
                  sendFieldsAs: 'form',
                  fields: {
                    data: []
                  }
                }).then(function (resp) {
                  var data = resp.data;
                  if (data.status == 'OK') {
                    var obj = data.data[0];
                    $scope.data.rutaArchivo = obj.rutaArchivo;
                    $scope.data.extension = obj.extension;
                    toastr.success("Archivo cargado");
                  } else {
                    toastr.error(resp.data.message)
                  }
                }, function (resp) {
                  toastr.error('Error status: ' + resp.status);
                }, function (evt) {
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                  $scope.avance = progressPercentage;
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  if ($scope.data.rutaArchivo == undefined) {
                    isValid = false;
                  } else {
                    if ($scope.data.rutaArchivo == null) {
                      isValid = false;
                    }
                  }
                }
                if ( isValid ) {
                  manualService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.manual.edit', {
          url: '/:manualId/edit',
          templateUrl: 'app/manual/manual.add.tpl.html',
          resolve: {
            dataManual: ['manualService', '$stateParams',
              function ( manualService, $stateParams ){
                return manualService.get( $stateParams.manualId );
              }],
            dataEstado: ['manualService',
              function ( manualService ){
                return manualService.listStatus();
              }],
            dataTipo: ['manualService',
              function ( manualService ){
                return manualService.listaTipo();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'manualService', 'dataManual', 'dataEstado', 'Upload', 'dataTipo',
            function (  $scope,   $state,   toastr,   manualService,   dataManual, dataEstado, Upload, dataTipo) {
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipo = dataTipo.data;
              $scope.data = dataManual.data[0];

              $scope.visibleAbrir = function () {
                if ($scope.data.rutaArchivo != undefined) {
                  if ($scope.data.rutaArchivo != null) {
                    return true;
                  }
                }
                return false;
              }

              $scope.onFileSelect = function (file, tipo) {
                Upload.upload({
                  url: appSettings.urlServiceBase + 'file/upload',
                  method: 'POST',
                  file: file,
                  sendFieldsAs: 'form',
                  fields: {
                    data: []
                  }
                }).then(function (resp) {
                  var data = resp.data;
                  if (data.status == 'OK') {
                    var obj = data.data[0];
                    $scope.data.rutaArchivo = obj.rutaArchivo;
                    $scope.data.extension = obj.extension;
                    toastr.success("Archivo cargado");
                  } else {
                    toastr.error(resp.data.message)
                  }
                }, function (resp) {
                  toastr.error('Error status: ' + resp.status);
                }, function (evt) {
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                  $scope.avance = progressPercentage;
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  manualService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
