angular.module('app.exento.service', [

])

.factory('exentoService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    listaPorPersonaFecha: function ( filtro ) {
      return $http.post( appSettings.urlServiceBase + 'exento/persona/lista', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    listaPorPersona: function ( personaId, estadoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/persona/' + personaId + '/' + estadoId + '/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'exento/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'exento/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    actualizarDatosExento: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/actualizar/datos', data).
        then(function (res) {
          return res.data;
        });
    },
    getArchivoParaActualizacion : function( exentoId ) {
      $http.get(appSettings.urlServiceBase + 'exento/archivo/actualizacion/' + exentoId, {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    addParaActualizar: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'exento/add/actualizar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'exento/' + data.exentoId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId).then(function ( res ) {
         return res.data;
       });
    },
    buscarSolicitudPorRnf : function( codigo ) {
      return $http.get( appSettings.urlServiceBase + 'exento/codigo/' + codigo).then(function ( res ) {
         return res.data;
       });
    },
    getSaldo : function( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId + '/saldo').then(function ( res ) {
         return res.data;
       });
    },
    getListaCosecha : function( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId + '/cosecha').then(function ( res ) {
         return res.data;
       });
    },
    agregarPersona: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'exento/agregar/persona', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    quitarPersona: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/quitar/persona', data).
        then(function (res) {
          return res.data;
        });
    },
    setRepresentanteLegal: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/representante/legal', data).
        then(function (res) {
          return res.data;
        });
    },
    agregarNotificacion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/notificacion', data).
        then(function (res) {
          return res.data;
        });
    },
    quitarNotificacion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/quitar/notificacion', data).
        then(function (res) {
          return res.data;
        });
    },
    actualizarDatosNotificacion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/actualizar/notificacion', data).
        then(function (res) {
          return res.data;
        });
    },
    actualizarFincas: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/actualizar/fincas', data).
        then(function (res) {
          return res.data;
        });
    },
    agregarFincaExento: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/finca/exento', data).
        then(function (res) {
          return res.data;
        });
    },
    setSubregion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/subregion', data).
        then(function (res) {
          return res.data;
        });
    },
    agregarPropietarioFinca: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/propietario/finca', data).
        then(function (res) {
          return res.data;
        });
    },
    quitarPropietarioFinca: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/quitar/propietario/finca', data).
        then(function (res) {
          return res.data;
        });
    },
    quitarFincaExento: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/quitar/finca', data).
        then(function (res) {
          return res.data;
        });
    },
    finalizarElaboracion: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + data.exentoId + '/finalizar/elaboracion', data).
        then(function (res) {
          return res.data;
        }
      );
    },
    getListadoSubregion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/subregion/lista', data).
        then(function (res) {
          return res.data;
        });
    },
    getPorInvolucrado: function ( filtro ) {
      return $http.post(appSettings.urlServiceBase + "exento/por/involucrado", filtro).then(function (res) {
        return res.data;
      });
    },
    anularSolicitudCargaExento: function (exentoId, ttExento) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/anular/solicitud/carga', ttExento).
        then(function (res) {
          return res.data;
        });
    },
    agregarDatosJuridica: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'exento/agregar/juridica', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getListaVenta: function (exentoId) {
      return $http.get(appSettings.urlServiceBase + 'exento/' + exentoId + '/ventas').
        then(function (res) {
          return res.data;
        });
    },
    solicitarCargaInicial: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'exento/' + data.exentoId + '/solicitar/carga', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    datosParaVenta: function ( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/datos/' + exentoId + '/para/venta' ).
       then(function ( res ) {
         return res.data;
       });
    },
    solicitarVenta: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'exento/solicitar/venta', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getDatosVenta: function ( ventaExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/' + ventaExentoId ).
       then(function ( res ) {
         return res.data;
       });
    },
    getVentaActiva: function ( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId + '/venta/activa').
       then(function ( res ) {
         return res.data;
       });
    },
    getSolicitudVentaSubregion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/solicitud/venta/subregion', data).
        then(function (res) {
          return res.data;
        });
    },
    actualizarDatosVenta: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'exento/actualizar/venta', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    solicitarNuevaBoletaPago: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'exento/nueva/carga/pago', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    anularSolicitudVenta: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'exento/anular/solicitud/venta', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getArchivoActualizacion: function ( exentoId ) {
      $http.get(appSettings.urlServiceBase + 'exento/file/actualizacion/' + exentoId, {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    getDocumentoPago: function ( ventaExentoId ) {
      $http.get(appSettings.urlServiceBase + 'exento/file/' + ventaExentoId, {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    dataListaInformeVenta: function ( ventaExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/' + ventaExentoId + '/informes' ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarInforme: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/informe', data).
        then(function (res) {
          return res.data;
        });
    },
    getDataVentaParaInforme: function ( ventaExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/' + ventaExentoId + '/para/informe' ).
       then(function ( res ) {
         return res.data;
       });
    },
    getInformeVenta: function ( informeVentaId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/informe/' + informeVentaId ).
       then(function ( res ) {
         return res.data;
       });
    },
    actualizarInformeNotas: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/actualizar/informe/notas', data).
        then(function (res) {
          return res.data;
        });
    },
    actualizarInforme: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/actualizar/informe', data).
        then(function (res) {
          return res.data;
        });
    },
    agregarAnexoInforme: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/anexo/informe', data).
        then(function (res) {
          return res.data;
        });
    },
    getAnexoInforme : function( anexoInformeVentaId ) {
      $http.get(appSettings.urlServiceBase + 'exento/informe/anexo/' + anexoInformeVentaId, {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    quitarAnexoInforme : function( anexoInformeId ) {
      return $http.delete(appSettings.urlServiceBase + "exento/informe/quitar/anexo/" + anexoInformeId).then(function (res) {
        return res.data;
      });
    },
    enviarInforme: function (data) {
      return $http.put(appSettings.urlServiceBase + 'exento/enviar/informe', data).
        then(function (res) {
          return res.data;
        });
    },
    getListaInformeSubregion: function ( filtro ) {
      return $http.post( appSettings.urlServiceBase + 'exento/subregion/informes', filtro ).
       then(function ( res ) {
         return res.data;
       });
    },
    getReporteBalanceGeneral: function ( codigo ) {
      var params = {
        codigo: codigo
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/reporte/balance/codigo', config).
       then(function ( res ) {
         return res.data;
       });
    },
    getReporteGeneralVentaNota: function ( fechaIni, fechaFin, regionId, subregionId ) {
      var params = {
        fechaIni: fechaIni,
        fechaFin: fechaFin,
        regionId: regionId,
        subregionId: subregionId
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/reporte/venta/nota', config ).
       then(function ( res ) {
         return res.data;
       });
    },
    getReporteDetalleEspecie: function ( fechaIni, fechaFin, regionId, subregionId ) {
      var params = {
        fechaIni: fechaIni,
        fechaFin: fechaFin,
        regionId: regionId,
        subregionId: subregionId
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/reporte/venta/especie', config ).
       then(function ( res ) {
         return res.data;
       });
    },
    getListaActualizacion: function ( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId + '/obtener/lista/actualizacion').
       then(function ( res ) {
         return res.data;
       });
    },
    agregarActualizacion: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/agregar/actualizacion', data).
        then(function (res) {
          return res.data;
        });
    },
    anularActualizacion: function ( actualizacionExentoId ) {
      return $http.delete( appSettings.urlServiceBase + 'exento/actualizacion/' + actualizacionExentoId).
       then(function ( res ) {
         return res.data;
       });
    },
    getActualizacion: function ( actualizacionExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/actualizacion/' + actualizacionExentoId + '/data').
       then(function ( res ) {
         return res.data;
       });
    },
    cargarArchivoCertificado: function (data) {
      return $http.post(appSettings.urlServiceBase + 'exento/actualizacion/agregar/certificado', data).
        then(function (res) {
          return res.data;
        });
    },
    getCertificadoActualizacion : function( actualizacionExentoId ) {
      $http.get(appSettings.urlServiceBase + 'exento/actualizacion/' + actualizacionExentoId + '/obtener/certificado', {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    finalizarElaboracionActualizacion: function (data) {
      return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizacion/enviar', data).
        then(function (res) {
          return res.data;
        });
    },
    corregirErroresCargaSolicitud: function (exentoId) {
      return $http.put(appSettings.urlServiceBase + 'exento/corregir/carga/exento/' + exentoId).
        then(function (res) {
          return res.data;
        });
    },
    actualizarFinalizacionVentaActiva: function ( ttVentaExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/cambiar/finalizacion/venta', ttVentaExento).
        then(function (res) {
          return res.data;
        });
    },
    marcarInformeComoIncompleto: function ( ttInformeVenta ) {
      return $http.put(appSettings.urlServiceBase + 'exento/marcar/incompleto/informe/' + ttInformeVenta.informeVentaId, ttInformeVenta).
        then(function (res) {
          return res.data;
        });
    },
    actualizarVencimientoVentaActiva: function ( ttExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/cambiar/vigencia/venta', ttExento).
        then(function (res) {
          return res.data;
        });
    },
    getHistoricoPoligono: function ( fechaIni, fechaFin, regionId, subregionId ) {
      var params = {
        fechaIni: fechaIni,
        fechaFin: fechaFin,
        regionId: regionId,
        subregionId: subregionId
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/historico/poligono', config).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarRodalesActualizacion: function ( ttExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/agregar/rodales/actualizacion', ttExento).
        then(function (res) {
          return res.data;
        });
    },
    getInventario: function ( exentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/' + exentoId + '/inventario' ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregaEspecieVentaInforme: function ( ttEspecieVentaInforme ) {
      return $http.post(appSettings.urlServiceBase + 'exento/especieVentaInforme/add', ttEspecieVentaInforme).
        then(function (res) {
          return res.data;
        });
    },
    getEspecieVentaInforme : function( informeVentaId ) {
      return $http.get(appSettings.urlServiceBase + 'exento/especieVentaInforme/'+informeVentaId)
       .then(function (res) {
        return res.data
       });
    },
    actualizEspecieVentaInforme: function ( ttEspecieVentaInforme ) {
      return $http.put(appSettings.urlServiceBase + 'exento/especieVentaInforme/'+ ttEspecieVentaInforme.especieVentaInformeId , ttEspecieVentaInforme).
        then(function (res) {
          return res.data;
        });
    },
    actualizarEspeciesInformeExento: function (data ) {
      return $http.put(appSettings.urlServiceBase + 'exento/especies/informe', data).then(function (res) {
        return res.data;
      });
    },
    setMandatario: function ( ttPersonaExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/actualizar/mandatario', ttPersonaExento).then(function (res) {
        return res.data;
      });
    },
    getListaEnmiendas: function( exentoId ) {
      var params = {
        exentoId: exentoId
      }
      var config = {
        params: params
      }
      return $http.get(appSettings.urlServiceBase + 'exento/enmienda/lista', config).then(function (res) {
        return res.data
       });
    },
    getListaAnexosInforme: function( informeVentaId ) {
      var params = {
        informeVentaId: informeVentaId
      }
      var config = {
        params: params
      }
      return $http.get(appSettings.urlServiceBase + 'exento/informe/anexo/lista', config).then(function (res) {
        return res.data
       });
    },
    agregarPersonaActualizacion: function ( data, exentoId ) {
      return $http.post(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/agregar/persona', data).then(function (res) {
        return res.data;
      });
    },
    quitarPersonaActualizacion: function ( data, exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/quitar/persona', data).then(function (res) {
        return res.data;
      });
    },
    actualizacionNotificacion: function ( data, exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/notificacion', data).then(function (res) {
        return res.data;
      });
    },
    agregarNotificacionActualizacion: function ( data, exentoId ) {
      return $http.post(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/agregar/notificacion', data).then(function (res) {
        return res.data;
      });
    },
    quitarNotificacionActualizacion: function ( data, exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/quitar/notificacion', data).then(function (res) {
        return res.data;
      });
    },
    guardarRodalesActualizacion: function ( ttActualizacionExento, exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/rodales', ttActualizacionExento).
        then(function (res) {
          return res.data;
        });
    },
    agregarFincaActualizacion: function ( data, exentoId ) {
      return $http.post(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/agregar/finca', data).then(function (res) {
        return res.data;
      });
    },
    quitarFincaActualizacion: function ( data, exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/actualizacion/quitar/finca', data).then(function (res) {
        return res.data;
      });
    },
    getActualizacionDeTarea: function ( tareaExentoId ) {
      var params = {
        tareaExentoId: tareaExentoId
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/actualizacion/desde/tarea', config ).
       then(function ( res ) {
         return res.data;
       });
    },
    getInventarioActualizacion: function ( tareaExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/tarea/' + tareaExentoId + '/inventario' ).
       then(function ( res ) {
         return res.data;
       });
    },
    getListaNotasVenta: function ( ventaExentoId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/' + ventaExentoId + '/notas' ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarNotaElectronicaInforme: function ( ventaExentoId ) {
      return $http.post(appSettings.urlServiceBase + 'exento/venta/' + ventaExentoId + '/agregar/nota').then(function (res) {
        return res.data;
      });
    },
    getNotaInformeVenta: function ( notaInformeVentaId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/informe/nota/' + notaInformeVentaId ).
       then(function ( res ) {
         return res.data;
       });
    },
    actualizarNotaInformeVenta: function ( ttNotaInformeVenta ) {
      return $http.put(appSettings.urlServiceBase + 'exento/venta/actualizar/nota', ttNotaInformeVenta).then(function (res) {
        return res.data;
      });
    },
    actualizarVigenciaSolicitudActualizacion: function ( ttActualizacionExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/actualizacion/vigencia/certificado', ttActualizacionExento).then(function (res) {
        return res.data;
      });
    },
    anularNotaElectronica: function ( ttNotaInformeVenta ) {
      return $http.put(appSettings.urlServiceBase + 'exento/nota/electronica/anular', ttNotaInformeVenta).then(function (res) {
        return res.data;
      });
    },
    actualizarReporteVenta: function () {
      return $http.patch(appSettings.urlServiceBase + 'exento/reporte/venta/actualizar').then(function (res) {
        return res.data;
      });
    },
    getReporteBalanceEspecie: function ( codigo ) {
      var params = {
        codigo: codigo
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/reporte/balance/codigo/especie', config).
       then(function ( res ) {
         return res.data;
       });
    },
    getReporteNotasCodigo: function ( codigo ) {
      var params = {
        codigo: codigo
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/reporte/nota/codigo', config).
       then(function ( res ) {
         return res.data;
       });
    },
    setMandatariosActualizacion: function ( ttPersonasExento ) {
      return $http.put(appSettings.urlServiceBase + 'exento/actualizacion/mandatarios', ttPersonasExento).then(function (res) {
        return res.data;
      });
    },
    getPoligonoByCodigo: function ( codigo ) {
      var params = {        
        codigo: codigo
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'exento/poligono/codigo', config).
       then(function ( res ) {
         return res.data;
       });
    },
    quitarUltimoInformeComoFinal: function ( exentoId ) {
      return $http.put(appSettings.urlServiceBase + 'exento/' + exentoId + '/venta/informe/quitar/final').then(function (res) {
        return res.data;
      });
    }
  }

}]);
