angular.module('app.calidadfuste', [
  'ui.router',
  'toastr',
  'app.calidadfuste.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.calidadfuste', {
          abstract: true,
          url: 'calidadfuste',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Calidad de fuste';
            }]
        })
        .state('index.calidadfuste.list', {
          url: '',
          templateUrl: 'app/calidadfuste/calidadfuste.list.tpl.html',
          resolve: {
            dataCalidadFuste: ['calidadfusteService',
              function ( calidadfusteService ){
                return calidadfusteService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCalidadFuste',
            function (  $scope,   $state,   dataCalidadFuste) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'calidadFusteDesc', name: 'calidadFusteDesc', displayName: 'Calidad de fuste' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCalidadFuste.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ calidadFusteId: row.calidadFusteId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.calidadfuste.add', {
          url: '/add',
          templateUrl: 'app/calidadfuste/calidadfuste.add.tpl.html',
          resolve: {
            dataEstado: ['calidadfusteService',
              function ( calidadfusteService ){
                return calidadfusteService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'calidadfusteService', 'dataEstado',
            function (  $scope,   $state,   toastr,   calidadfusteService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  calidadfusteService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.calidadfuste.edit', {
          url: '/:calidadFusteId/edit',
          templateUrl: 'app/calidadfuste/calidadfuste.add.tpl.html',
          resolve: {
            dataCalidadFuste: ['calidadfusteService', '$stateParams',
              function ( calidadfusteService, $stateParams ){
                return calidadfusteService.get( $stateParams.calidadFusteId );
              }],
            dataEstado: ['calidadfusteService',
              function ( calidadfusteService ){
                return calidadfusteService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'calidadfusteService', 'dataCalidadFuste', 'dataEstado',
            function (  $scope,   $state,   toastr,   calidadfusteService,   dataCalidadFuste, dataEstado) {
              $scope.data = dataCalidadFuste.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  calidadfusteService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
