angular.module('app.utilsService', [

])

  .factory('utilsService', ['$filter', '$compile', '$templateCache', '$timeout', '$base64', 'toastr', 'Upload', 'tareaService', 'exentoService', '$state',
    function ($filter, $compile, $templateCache, $timeout, $base64, toastr, Upload, tareaService, exentoService, $state) {
      var lastPage = 0;
      var lastPagePoa = 0;
      var areaMinimaDistribucion = 15;
      var gblEsPrevia = false;
      var padLeft = function (str, n) {
        var tmp = "" + str;
        var pad = "";
        for (var i = 0; i < n; i++) {
          pad += "0";
        }
        var newStr = pad.substring(0, pad.length - tmp.length) + tmp;
        return tmp;
      }
      var formatNumber = {
        separador: ",", // separador para los miles
        sepDecimal: '.', // separador para los decimales
        formatear: function (num) {
          num += '';
          var splitStr = num.split('.');
          var splitLeft = splitStr[0];
          var splitRight = '';
          if (splitStr.length > 1) {
            if (splitStr[1].length < 2) {
              splitRight = this.sepDecimal + splitStr[1].padEnd(2, '0');
            } else {
              splitRight = this.sepDecimal + splitStr[1];
            }
          } else {
            splitRight = '.00';
          }
          var regx = /(\d+)(\d{3})/;
          while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
          }
          return this.simbol + splitLeft + splitRight;
        },
        new: function (num, simbol) {
          this.simbol = simbol || '';
          return this.formatear(num);
        }
      }

      var getMonth = function (month) {
        switch (month) {
          case 1:
            return "enero";
            break;
          case 2:
            return "febrero";
            break;
          case 3:
            return "marzo";
            break;
          case 4:
            return "abril";
            break;
          case 5:
            return "mayo";
            break;
          case 6:
            return "junio";
            break;
          case 7:
            return "julio";
            break;
          case 8:
            return "agosto";
            break;
          case 9:
            return "septiembre";
            break;
          case 10:
            return "octubre";
            break;
          case 11:
            return "noviembre";
            break;
          case 12:
            return "diciembre";
            break;
          default:
            return "undefined";
        }
      }

      var getNameDayOfWeek = function (day) {
        var dayName = "";
        switch (day) {
          case 0:
            dayName = "domingo";
            break;
          case 1:
            dayName = "lunes";
            break;
          case 2:
            dayName = "martes";
            break;
          case 3:
            dayName = "miércoles";
            break;
          case 4:
            dayName = "jueves";
            break;
          case 5:
            dayName = "viernes";
            break;
          case 6:
            dayName = "sábado";
            break;
          default:
            dayName = "";
            break;
        }
        return dayName;
      }

      function cryptText(salt, pText) {
        var text = pText.toString();
        return $base64.encode(text);
        /*var text = pText.toString();
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
        return text
          .split("")
          .map(textToChars)
          .map(applySaltToChar)
          .map(byteHex)
          .join("");*/
      }

      function decryptText(salt, encoded) {
        return $base64.decode(encoded);
        /*const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
          .match(/.{1,2}/g)
          .map((hex) => parseInt(hex, 16))
          .map(applySaltToChar)
          .map((charCode) => String.fromCharCode(charCode))
          .join("");*/
      }

      //fuente: https://desarrolloweb.com/articulos/1190.php
      function calculaEdad(fecha, inicio) {
        inicio = new Date(inicio);
        var arrayFecha = fecha.split("-")
        var dia, mes, anio;
        dia = parseInt(arrayFecha[2]);
        mes = parseInt(arrayFecha[1]);
        anio = parseInt(arrayFecha[0]);
        var edad = (inicio.getFullYear() - anio) - 1;
        if ((inicio.getMonth() + 1) - mes < 0)
          return edad;
        if ((inicio.getMonth() + 1) - mes > 0)
          return edad + 1;
        if (inicio.getDate() - dia >= 0)
          return edad + 1;
        return edad;
      }
      return {
        getMonthName: function (month) {
          return getMonth(month);
        },
        getLastPage: function () {
          return lastPage;
        },
        getLastPagePoa: function () {
          return lastPagePoa;
        },
        setEsPrevia: function (esPrevia) {
          gblEsPrevia = esPrevia;
        },
        getEsPrevia: function () {
          if (appSettings.isDevelopment) {
            return true;
          }
          return gblEsPrevia;
        },
        cifrarTexto: function (semilla, texto) {
          return cryptText(semilla, texto);
        },
        decifrarTexto: function (semilla, texto) {
          return decryptText(semilla, texto);
        },
        findById: function (a, id) {
          if (typeof a !== "undefined") {
            for (var i = 0; i < a.length; i++) {
              if (a[i].id == id) return a[i];
            }
          }
          return null;
        },

        findFieldById: function (a, field, id) {
          var obj = this.findById(a, id);
          return obj !== null ? obj[field] : null;
        },

        findByField: function (a, field, value) {
          if (typeof a !== "undefined") {
            for (var i = 0; i < a.length; i++) {
              if (a[i][field] == value) return a[i];
            }
          }
          return null;
        },

        filterByField: function (a, field, value) {
          var newArray = [];
          if (typeof a !== "undefined") {
            for (var i = 0; i < a.length; i++) {
              if (a[i][field] == value) newArray.push(a[i]);
            }
          }
          return newArray;
        },

        existInArray: function (a, field, value) {
          if (typeof a !== "undefined") {
            for (var i = a.length - 1; i >= 0; i--) {
              if (a[i][field] == value) {
                return true;
              }
            }
          }
          return false;
        },

        existInArrayTwoFields: function (a, fielda, valuea, fieldb, valueb) {
          if (typeof a !== "undefined") {
            for (var i = a.length - 1; i >= 0; i--) {
              if (a[i][fielda] == valuea && a[i][fieldb] == valueb) {
                return true;
              }
            }
          }
          return false;
        },
        existInArrayThreeFields: function (a, fielda, valuea, fieldb, valueb, fieldc, valuec) {
          if (typeof a !== "undefined") {
            for (var i = a.length - 1; i >= 0; i--) {
              if (a[i][fielda] == valuea && a[i][fieldb] == valueb && a[i][fieldc] == valuec) {
                return true;
              }
            }
          }
          return false;
        },

        compareAndDeleteRepeatedItems: function (a1, a2, field) {
          var newArray = [];
          for (var i = 0; i < a1.length; i++) {
            if (!this.existInArray(a2, field, a1[i][field])) {
              newArray.push(a1[i]);
            }
          }
          return newArray;
        },

        formatPadString: function (pad, str) {
          return pad.substring(0, pad.length - str.length) + str;
        },
        goTab: function (event) {
          event.preventDefault();
        },
        typeof: function (value, type) {
          return typeof value === type;
        },

        // Util for returning a random key from a collection that also isn't the current key
        newRandomKey: function (coll, key, currentKey) {
          var randKey;
          do {
            randKey = coll[Math.floor(coll.length * Math.random())][key];
          } while (randKey == currentKey);
          return randKey;
        },

        /*
          Funcion que abre una nueva ventana con el cotenido de una plantilla
          Esta funcion compila el html de la plantilla a html puro, es decir si tiene ng-repeat o cualquier directiva angular aqui se compila
          de otra forma se mostraria tal cual aparece el html
          Parametros:
          templateUrl: La url de la plantilla
          scope: El $scope del controlador, util para la compilacion
          title: El titulo de la ventana
        */
        openWindow: function (html, scope, title) {
          var markup = '<!DOCTYPE HTML>' +
            '<html>' +
            '<head>' +
            '<style type="text/css">' +
            'table { page-break-inside:auto }' +
            '  tr    { page-break-inside:avoid; page-break-after:auto }' +
            '  thead { display:table-header-group }' +
            '  tfoot { display:table-footer-group }' +
            'table, th, td {' +
            '  border: 1px solid;' +
            '}' +
            'th, td {' +
            '  padding: 1px;' +
            '}' +          
            'table {' +
            '  width: 100%;' +
            '}' + 
            '.centrar {' +
            '  text-align: center;' +
            '  clear:both;' +
            '}' +
            'centrar-reducir {' +
            '  text-align: center;' +
            '  width: 75%;' +
            '}' +
            '</style>' +
            '<title>' + title + '</title></head>' +
            '<link rel="stylesheet" href="bower_components/bootstrap/dist/css/bootstrap.min.css">' +
            '<link rel="stylesheet" href="css/main.css">' +
            '<body style="width: 850px; height: 1100px;">';

          var element = null;

          try {
            element = angular.element(html);
          } catch (error) {
            element = $compile('<div>' + $templateCache.get(html) + '</div>')(scope);
          }

          $timeout(function () {
            markup += '<div class="container">' + element.html() + '</div>';
            markup += "</body></html>";
            var newwindow = window.open("", title, "resizable,scrollbars,status");
            newwindow.document.write(markup);
            newwindow.document.close();
            $timeout(function () {
              newwindow.print();
              // newwindow.close();
            }, 4);
          }, 0);
        },
        reporteTabla: function (columns, rows, orientacion, tamanio, titulo, subtitulo, usuario, dataEmpresa, alinearDerecha, tipo, total, columnStyles, alinearCentro, margin, fontSize) {
          //orientacion puede ser 'l'=landscape, 'p'= portrait, tamaño puede ser 'letter' o 'legal'
          var value = 380;
          if (orientacion == 'p') {
            value = 380;
          } else {
            value = 830;
          }
          var headerImgData = 'data:image/png;base64,' + dataEmpresa.logo;
          var doc = new jsPDF(orientacion, 'pt', tamanio);
          var fechaHora = $filter('date')(new Date(), 'dd/MM/yyyy HH:mm');
          var totalPagesExp = "{total_pages_count_string}";
          var header = function (data) {
            doc.setFontSize(14);
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            doc.addImage(headerImgData, 'png', data.settings.margin.left, 10, 75, 70, 40);
            doc.text(titulo, data.settings.margin.left + 100, 50);
            doc.setFontSize(10);
            doc.text(subtitulo, data.settings.margin.left + 100, 60);
            //footer
            var str = "Página " + data.pageCount;
            // Total page number plugin only available in jspdf v1.0+<
            if (typeof doc.putTotalPages === 'function') {
              str = str + " de " + totalPagesExp;
            }
            doc.setFontSize(9);
            doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 30);
          };

          var alignCol = function (cell, data) {
            var col = data.column.index;
            for (var i = 0; i < alinearDerecha.length; i++) {
              if (col == alinearDerecha[i].columna) {
                cell.styles.halign = 'right';
              }
            }
            if (alinearCentro != null) {
              for (var i = 0; i < alinearCentro.length; i++) {
                if (col == alinearCentro[i].columna) {
                  cell.styles.halign = 'center';
                }
              }
            }
          };

          var alignColHeader = function (cell, data) {
            cell.styles.halign = 'center';
          };

          if (columnStyles == null) {
            columnStyles = {};
          }
          if (margin == null) {
            margin = { top: 90, left: 50, right: 50, button: 50 };
          }
          if (fontSize == null) {
            fontSize = {
              header: 12,
              detail: 11
            }
          }
          var options = {
            theme: 'striped',
            addPageContent: header,
            //addPageContent: footer,
            margin: margin,
            styles: {
              overflow: 'linebreak',
              font: 'times',
              fontSize: fontSize.detail,
              cellPadding: 3
            },
            headerStyles: {
              overflow: 'linebreak',
              font: 'times',
              fontSize: fontSize.header,
              cellPadding: 3
            },
            columnStyles: columnStyles,
            createdHeaderCell: function (cell, data) {
              alignColHeader(cell, data);
            },
            createdCell: function (cell, data) {
              alignCol(cell, data);
            }
          };
          doc.autoTable(columns, rows, options);
          doc.setFontSize(10);
          var moveX = 450;
          if (tamanio == 'legal') {
            var moveX = 750;
          }
          doc.text("Usuario: " + usuario, moveX, doc.autoTable.previous.finalY + 20);
          doc.text("Fecha: " + fechaHora, moveX, doc.autoTable.previous.finalY + 30);
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
          }
          doc.save('reporte.pdf');
        },
        gtmToLatLong: function (googleFormat, xKey, yKey, coordenadas) {
          var newCoordenadas = [];
          angular.forEach(coordenadas, function (utm) {
            // Guatemala
            var gtmSetup = "+proj=tmerc +lat_0=0 +lon_0=-90.5 +k=0.9998 +x_0=500000 +y_0=0 +ellps=WGS84 +units=m +no_defs";
            var gtm = proj4(gtmSetup).inverse([utm.lat, utm.lng]);
            gtm = gtm.reverse();
            // Decidir si se enviaran en formato [23.3422, 21.123] o {lat: 23.3422, lng: 21.123}
            newCoordenadas.push(googleFormat ? { lat: gtm[0], lng: gtm[1] } : gtm);
          });
          return newCoordenadas;
        },
        generarPlan: function (dataEmpresa, data) {
          if (data.notificaciones == null) {
            toastr.error('Debe completar el plan, no tienen datos de notificación', 'Plan incompleto');
            return;
          } else {
            if (data.notificaciones.length <= 0) {
              toastr.error('Debe completar el plan, no tienen datos de notificación', 'Plan incompleto');
              return;
            } else {
              for (var i = 0; i < data.notificaciones.length; i++) {
                if (data.notificaciones[i].notificacionGestionDesc == null || data.notificaciones[i].notificacionGestionDesc == '') {
                  toastr.error('Debe completar el plan, no tienen datos de notificación', 'Plan incompleto');
                  return;
                }
              }
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            if (data.representantes.length <= 0) {
              toastr.error('Debe agregar al representante legal');
              return;
            }
          } else {
            if (data.personas.length <= 0) {
              toastr.error('Debe agregar al menos un propietario');
              return;
            }
          }
          var titulos = {
            rodal: 'rodal',
            rodales: 'rodales',
            Rodal: 'Rodal',
            Rodales: 'Rodales'
          };
          if (data.tcTipoBosque.tipoBosqueId == 3) {
            titulos = {
              rodal: 'estrato',
              rodales: 'estratos',
              Rodal: 'Estrato',
              Rodales: 'Estratos',
              areaSolicitada: 'Área solicitada para aprovechar (ha)'
            }
          }
          switch (parseInt(data.tcTipoGestion.tipoGestionId)) {
            case 1:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
            case 2:
              titulos.areaSolicitada = 'Área intervenir por saneamiento (ha)';
            case 3:
              titulos.areaSolicitada = 'Área intervenir por salvamento (ha)';
              break;
            case 4:
              titulos.areaSolicitada = 'Área intervenir con fines científicos (ha)';
              break;
            case 5:
              titulos.areaSolicitada = 'Área intervenir por cambio de uso (ha)';
              break;
            case 7:
              titulos.areaSolicitada = 'Área intervenir con árboles fuera de bosque (ha)';
              break;
            default:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
          }
          var nombreSalida = 'plan.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var entidad = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadId == 1 ? data.ttTipoPropietarioGestion.nombreComercial : 'N/A', style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            entidad = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
          }
          body = [
            [
              { text: 'I. Datos del solicitante', style: 'tableSubHeader', colSpan: 5, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}
            ]
          ];
          var dataPersona = data.personas;
          if (dataPersona.length > 0) {
            body.push(
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Comunidad Lingüística', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Pueblo de Pertenencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Estado civil', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            );
          }
          for (i = 0; i < dataPersona.length; i++) {
            item = [
              { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcIdioma.idiomaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcCultura.culturaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcEstadoCivil.estadoCivilDesc, style: 'detalle', alignment: 'left' }
            ];
            body.push(item);
            if (dataPersona[i].tcPersona.tcSexo.sexoId == 1) {
              mujeres++;
            } else {
              hombres++;
            }
          }
          if (dataPersona.length > 0) {
            item = [
              { text: 'Hombres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: hombres, style: 'tableHeader', alignment: 'left' },
              { text: 'Mujeres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: mujeres, style: 'tableHeader', alignment: 'left' },
              { text: '', alignment: 'left' }
            ];
            body.push(item);
          }
          if (data.representantes.length > 0) {
            item = [
              { text: 'Representante legal', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Nombre completo', style: 'tableSubHeader', colSpan: 2, fillColor: '#CCCCCC', alignment: 'center' }, {},
              { text: 'Código único de Identificación -CUI-', style: 'tableSubHeader', colSpan: 3, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}
            ];
            body.push(item);
            for (var i = 0; i < data.representantes.length; i++) {
              item = [
                { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
            }
          }
          var solicitante = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 90, 85, 83, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'II. Datos de notificación', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {},
            ],
            [
              { text: 'Tipo de notificación:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Descripción:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]
          ];
          var lista = [];
          var i, j;
          var item = [];
          var encontrado = false;
          for (i = 0; i < data.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(data.notificaciones[i].tcTipoNotificacion);
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(data.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var notificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 365],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'III. Datos de la finca', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (finca.tcTipoPropiedad == null) {
              swal('Por favor verifique la información de las fincas en la pestaña general');
            }
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                propietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            var cantidadDocumentos = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' },
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' },
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            body.push(item);
            item = [
              { text: 'Colindancias', style: 'tableSubHeader', colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Norte', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.norte, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Sur', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.sur, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Este', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.este, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Oeste', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.oeste, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Área total (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.areaDocumento).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total (Según GPS)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.area).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var finca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'Elaborador del Plan de Manejo Forestal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.personaDesc, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Número de Registro en el RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.rfn, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Firma', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: '\n\n', style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var pieElaborador = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          body = [
            [
              { text: '\n\n\n', style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Firma del solicitante', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var pieFirma = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [525],
              body: body
            }
          }
          body = [
            [
              { text: 'IV. Descripción Biofísica del área', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center' },
            ],
            [
              { text: 'Ubicación altitudinal y topografía', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttBiofisicaGestion.elevacion, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Zona de vida', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttBiofisicaGestion.zonaVida, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Hidrografía', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttBiofisicaGestion.hidrografia, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Características del bosque', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttBiofisicaGestion.clima, style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var biofisica = {
            style: 'estiloTabla',
            pageBreak: 'before',
            table: {
              headerRows: 1,
              widths: [525],
              body: body
            }
          }
          //resumen
          body = [
            [
              { text: 'V. Características de la finca', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center', colSpan: 3 }, {}, {}
            ],
            [
              { text: '5.1 Uso actual de la finca', style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}
            ],
            [
              { text: 'Área total de la finca (ha. Según GPS)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(areaFinca).toFixed(4), style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ],
            [
              { text: titulos.areaSolicitada, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.area).toFixed(4), style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ],
            [
              { text: 'Uso', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área en (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Porcentaje (%)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var dataUso = data.uso;
          var sumaTotal = 0.0;
          var sumaPorcentaje = 0.0;
          var areaForestal = 0.0;
          var porcentaje = 0.0;
          for (i = 0; i < dataUso.length; i++) {
            porcentaje = (parseFloat(dataUso[i].area) * 100) / parseFloat(areaFinca);
            porcentaje = parseFloat(porcentaje).toFixed(2);
            item = [
              { text: dataUso[i].tcUsoFinca.usoFincaDesc, style: 'detalle', alignment: 'left' },
              { text: parseFloat(dataUso[i].area).toFixed(4), style: 'detalle', alignment: 'right' },
              { text: porcentaje, style: 'detalle', alignment: 'right' }
            ];
            if (dataUso[i].tcUsoFinca.usoFincaId == 1) {
              areaForestal = dataUso[i].area;
            }
            body.push(item);
            sumaTotal = parseFloat(sumaTotal) + parseFloat(dataUso[i].area);
            sumaPorcentaje = parseFloat(sumaPorcentaje) + parseFloat(porcentaje);
          }

          item = [
            { text: 'TOTAL:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
            { text: parseFloat(sumaTotal).toFixed(4), style: 'tableHeader', alignment: 'right' },
            { text: parseFloat(sumaPorcentaje).toFixed(2), style: 'tableHeader', alignment: 'right' }
          ];
          body.push(item);
          var subBody = [
            [
              { text: 'Clase de desarrollo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Código de especie existente', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]
          ];
          var bodyEspecie = [];
          var dataEspecie = data.especies;
          for (i = 0; i < dataEspecie.length; i++) {
            item = [
              { text: dataEspecie[i].tcEspecie.codigo, style: 'detalle', alignment: 'left' },
              { text: dataEspecie[i].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' }
            ];
            bodyEspecie.push(item);
          }
          var bodyClaseDesarrollo = [];
          var dataClaseDesarrollo = data.codigoClase;
          for (i = 0; i < dataClaseDesarrollo.length; i++) {
            item = [
              { text: dataClaseDesarrollo[i].tcClaseDesarrollo.codigoClase, style: 'detalle', alignment: 'left' },
              { text: dataClaseDesarrollo[i].tcClaseDesarrollo.claseDesarrolloDesc, style: 'detalle', alignment: 'left' }
            ];
            bodyClaseDesarrollo.push(item);
          }
          item = [
            {
              table: {
                body: bodyClaseDesarrollo
              },
              layout: 'noBorders'
            },
            {
              table: {
                body: bodyEspecie
              },
              layout: 'noBorders'
            }

          ];
          subBody.push(item);
          var tablaEspecieClase = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [258, 258],
              body: subBody
            },
            colSpan: 3
          }
          var copiaEspecieClase = angular.copy(tablaEspecieClase);
          copiaEspecieClase.table.body.splice(0, 0, [
            { text: 'Códigos utilizados para el cuadro de resumen del inventario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 2 }, {}
          ]);
          //agregar titulos
          tablaEspecieClase.table.body.splice(0, 0, [
            { text: '\n', style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
          ]);
          tablaEspecieClase.table.body.splice(0, 0, [
            { text: 'Tipo de bosque: ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
            { text: data.tcTipoBosque.tipoBosqueDesc, style: 'tableSubHeader', alignment: 'left' }
          ]);
          tablaEspecieClase.table.body.splice(0, 0, [
            { text: '5.2. Bosque de la finca', style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
          ]);
          var resumen = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [199, 154, 154],
              body: body
            }
          }
          /**tablas para pintar resumen de regeneracion natural*/
          var tbLatizal = {};
          var tbBrinzal = {};
          var tmpTotalReg = null;
          if (data.ttInventarioGestion.brinzal == null) {
            data.ttInventarioGestion.brinzal = [];
          }
          if (data.ttInventarioGestion.brinzal.length > 0) {
            body = [
              [
                { text: 'Resumen de la regeneración natural -Latizal-', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
              ],
              [
                { text: titulos.Rodal, style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'No.', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad por hectaria', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad por rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var tmpDataReg = data.rodal;
            for (i = 0; i < tmpDataReg.length; i++) {
              tmpTotalReg = {
                densidadh: 0,
                densidadr: 0
              }
              for (j = 0; j < tmpDataReg[i].brinzal.length; j++) {
                body.push(
                  [
                    { text: tmpDataReg[i].correlativo, style: 'detalle', alignment: 'center' },
                    { text: j + 1, style: 'detalle', alignment: 'center' },
                    { text: tmpDataReg[i].brinzal[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                    { text: tmpDataReg[i].brinzal[j].densidadh, style: 'detalle', alignment: 'right' },
                    { text: tmpDataReg[i].brinzal[j].densidadr, style: 'detalle', alignment: 'right' }
                  ]
                );
                tmpTotalReg.densidadh += parseFloat(tmpDataReg[i].brinzal[j].densidadh);
                tmpTotalReg.densidadr += parseFloat(tmpDataReg[i].brinzal[j].densidadr);
              }
              body.push(
                [
                  { text: "Total", style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                  { text: parseFloat(tmpTotalReg.densidadh).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(tmpTotalReg.densidadr).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );
            }
            if (data.ttInventarioGestion.brinzal.length <= 0) {
              body.push(
                [
                  { text: "Observaciones", fillColor: '#CCCCCC', style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: data.ttInventarioGestion.obsRegeneracion, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
                ]
              );
            }
            tbLatizal = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [45, 40, 200, 100, 100],
                body: body
              }
            }
          }
          if (data.ttInventarioGestion.latizal == null) {
            data.ttInventarioGestion.latizal = [];
          }
          if (data.ttInventarioGestion.latizal.length > 0) {
            body = [
              [
                { text: 'Resumen de la regeneración natural -Brinzal-', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
              ],
              [
                { text: titulos.Rodal, style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'No.', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad por hectaria', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad por rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var tmpDataReg = data.rodal;
            for (i = 0; i < tmpDataReg.length; i++) {
              tmpTotalReg = {
                densidadh: 0,
                densidadr: 0
              }
              for (j = 0; j < tmpDataReg[i].latizal.length; j++) {
                body.push(
                  [
                    { text: tmpDataReg[i].correlativo, style: 'detalle', alignment: 'center' },
                    { text: j + 1, style: 'detalle', alignment: 'center' },
                    { text: tmpDataReg[i].latizal[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                    { text: tmpDataReg[i].latizal[j].densidadh, style: 'detalle', alignment: 'right' },
                    { text: tmpDataReg[i].latizal[j].densidadr, style: 'detalle', alignment: 'right' }
                  ]
                );
                tmpTotalReg.densidadh += parseFloat(tmpDataReg[i].latizal[j].densidadh);
                tmpTotalReg.densidadr += parseFloat(tmpDataReg[i].latizal[j].densidadr);
              }
              body.push(
                [
                  { text: "Total", style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                  { text: parseFloat(tmpTotalReg.densidadh).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(tmpTotalReg.densidadr).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );
            }
            body.push(
              [
                { text: "Observaciones", fillColor: '#CCCCCC', style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: data.ttInventarioGestion.obsRegeneracion, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            );
            tbBrinzal = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [45, 40, 200, 100, 100],
                body: body
              }
            }
          }
          /*Fin resumen regeneracion*/
          body = [
            [
              { text: '5.2.1 División del bosque', style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Área con bosque (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.ttDivisionAreaGestion.areaBosque).toFixed(4), style: 'detalle', alignment: 'left' },
              { text: 'Área de protección (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.ttDivisionAreaGestion.areaProteccionBosque).toFixed(4), style: 'detalle', alignment: 'left' },
              { text: 'Área de producción (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.ttDivisionAreaGestion.areaProduccion).toFixed(4), style: 'detalle', alignment: 'left' },
              { text: 'Área a intervenir (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.ttDivisionAreaGestion.areaIntervenirBosque).toFixed(4), style: 'detalle', alignment: 'left' }
            ]
          ];
          if (data.ttDivisionAreaGestion.fueraBosque == 1) {
            body.push(
              [
                { text: '5.2.2 Árboles fuera de bosque', style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Área total (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(data.ttDivisionAreaGestion.areaFuera).toFixed(4), style: 'detalle', alignment: 'left' },
                { text: 'Área de protección (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(data.ttDivisionAreaGestion.areaProteccionFuera).toFixed(4), style: 'detalle', alignment: 'left' },
                { text: 'Área a intervenir (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left', colSpan: 2 }, {},
                { text: parseFloat(data.ttDivisionAreaGestion.areaIntervenirFuera).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
              ]
            );
          }
          var superficie = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [70, 45, 70, 45, 70, 45, 70, 46],
              body: body
            }
          }
          var proteccion = {};
          body = [
            [
              { text: 'Criterio de protección', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Porcentaje (%)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          hombres = 0.0;
          mujeres = 0.0;
          porcentaje = 0.0;
          areaProteccion = data.ttDivisionAreaGestion.areaProteccionBosque + data.ttDivisionAreaGestion.areaProteccionFuera;
          if (data.criterio != null) {
            var dataCriterioProteccion = data.criterio;
            if (dataCriterioProteccion.length > 0) {
              if (areaProteccion == 0) {
                swal('Debe proporcionar un área de protección en el plan de manejo, favor de revisar');
                areaProteccion = 1;
                return;
              }
              for (i = 0; i < dataCriterioProteccion.length; i++) {
                porcentaje = (parseFloat(dataCriterioProteccion[i].area) * 100) / parseFloat(areaProteccion);
                porcentaje = parseFloat(porcentaje).toFixed(2);
                item = [
                  { text: dataCriterioProteccion[i].tcCriterioProteccion.criterioProteccionDesc, style: 'detalle', alignment: 'left' },
                  { text: parseFloat(dataCriterioProteccion[i].area).toFixed(4), style: 'detalle', alignment: 'right' },
                  { text: porcentaje, style: 'detalle', alignment: 'right' }
                ];
                body.push(item);
                hombres = parseFloat(hombres) + parseFloat(dataCriterioProteccion[i].area);
                mujeres = parseFloat(mujeres) + parseFloat(porcentaje);
              }
              item = [
                { text: 'TOTAL:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: parseFloat(hombres).toFixed(4), style: 'tableHeader', alignment: 'right' },
                { text: parseFloat(mujeres).toFixed(2), style: 'tableHeader', alignment: 'right' }
              ];
              body.push(item);
              proteccion = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [196, 155, 155],
                  body: body
                }
              }
            }
          }
          /***
          * tab inventario
          ***/
          body = [
            [
              { text: 'VI. Inventario forestal', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
            ]
          ];
          if (data.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 1) {
            body.push([
              { text: 'Tipo de inventario', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcTipoInventario.tipoInventarioDesc, style: 'detalle', alignment: 'left', colSpan: 7 }, {}, {}, {}, {}, {}, {}
            ]);
          } else {
            body.push([
              { text: 'Tipo de inventario', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcTipoInventario.tipoInventarioDesc, style: 'detalle', alignment: 'left' },
              { text: 'Total de ' + titulos.rodales, style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.totalRodales, style: 'detalle', alignment: 'left' },
              { text: 'Diámetro mínimo de inventario', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.diametro, style: 'detalle', alignment: 'left' },
              { text: 'Área muestreada', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.ttInventarioGestion.areaMuestreada).toFixed(4), style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Intensidad de muestreo (%)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.intensidadMuestreo, style: 'detalle', alignment: 'left' },
              { text: 'Tamaño de las parcelas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tamanioParcela, style: 'detalle', alignment: 'left' },
              { text: 'Forma de las parcelas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcFormaParcela.formaParcelaDesc, style: 'detalle', alignment: 'left' },
              { text: 'Tipo de muestreo', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoDesc, style: 'detalle', alignment: 'left' }
            ]);
          }
          var informacionInventario = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [65, 50, 80, 35, 76, 40, 60, 55],
              body: body
            }
          }
          body = [
            [
              { text: 'Resumen del inventario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 16 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
              { text: 'Existencia / ha', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Existencia / ' + titulos.Rodal, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'No. De ' + titulos.rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Pendiente promedio (%)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Incremento (m³/ha/año)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Clase de Desarrollo', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Edad (años)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie (código)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Tipo de inventario', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura (m)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP (cm)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'arb/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'G/ ha(m²)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vol./ha. (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'arb/' + titulos.rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'G/ ' + titulos.rodal + ' (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vol./ ' + titulos.rodal + ' (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var dataInventario = data.rodal;
          var conteo = 0;
          var filas = [];
          var tablaFila = {};
          var subTotal = {};
          var itemTotal = {
            alturaPromedio: 0,
            dapPromedio: 0,
            arbolh: 0,
            abh: 0,
            volh: 0,
            arbolr: 0,
            abr: 0,
            volr: 0,
            cantidad: 0
          };
          var bndEstaMal = false;
          var mensajeMal = '';
          for (i = 0; i < dataInventario.length; i++) {
            filas = [];
            subTotal = {
              alturaPromedio: 0,
              dapPromedio: 0,
              arbolh: 0,
              abh: 0,
              volh: 0,
              arbolr: 0,
              abr: 0,
              volr: 0,
              cantidad: 0
            };
            bndEstaMal = true;
            mensajeMal = 'El resumen de inventario (pestaña Inventario Forestal) está mal, debe utilizar el botón para realizar el cálculo';
            for (j = 0; j < dataInventario[i].especies.length; j++) {
              dataInventario[i].especies[j].abh = parseFloat(dataInventario[i].especies[j].abh).toFixed(4);
              dataInventario[i].especies[j].volh = parseFloat(dataInventario[i].especies[j].volh).toFixed(4);
              dataInventario[i].especies[j].abr = parseFloat(dataInventario[i].especies[j].abr).toFixed(4);
              dataInventario[i].especies[j].volr = parseFloat(dataInventario[i].especies[j].volr).toFixed(4);
              subTotal.alturaPromedio += parseFloat(dataInventario[i].especies[j].alturaPromedio);
              subTotal.dapPromedio += parseFloat(dataInventario[i].especies[j].dapPromedio);
              subTotal.arbolh += parseInt(dataInventario[i].especies[j].arbolh);
              subTotal.abh += parseFloat(dataInventario[i].especies[j].abh);
              subTotal.volh += parseFloat(dataInventario[i].especies[j].volh);
              subTotal.arbolr += parseInt(dataInventario[i].especies[j].arbolr);
              subTotal.abr += parseFloat(dataInventario[i].especies[j].abr);
              subTotal.volr += parseFloat(dataInventario[i].especies[j].volr);
              subTotal.cantidad++;
              if (parseFloat(dataInventario[i].especies[j].volr) > 0 && parseFloat(dataInventario[i].especies[j].abr) > 0 && bndEstaMal) {
                bndEstaMal = false;
              }
              item = [
                { text: dataInventario[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: dataInventario[i].especies[j].tcTipoInventario.tipoInventarioDesc, style: 'small', alignment: 'left' },
                { text: dataInventario[i].especies[j].alturaPromedio, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].dapPromedio, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].arbolh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].abh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].volh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].arbolr, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].abr, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].volr, style: 'small', alignment: 'right' }
              ];
              filas.push(item);
            }
            itemTotal.alturaPromedio += parseFloat(subTotal.alturaPromedio);
            itemTotal.dapPromedio += parseFloat(subTotal.dapPromedio);
            itemTotal.arbolh += parseInt(subTotal.arbolh);
            itemTotal.abh += parseFloat(subTotal.abh);
            itemTotal.volh += parseFloat(subTotal.volh);
            itemTotal.arbolr += parseInt(subTotal.arbolr);
            itemTotal.abr += parseFloat(subTotal.abr);
            itemTotal.volr += parseFloat(subTotal.volr);
            itemTotal.cantidad += parseInt(subTotal.cantidad);
            dapPromedio = parseFloat(subTotal.dapPromedio) / subTotal.cantidad;
            item = [
              { text: "Subtotal", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(parseFloat(subTotal.alturaPromedio) / subTotal.cantidad).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(dapPromedio).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseInt(subTotal.arbolh), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.abh).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.volh).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseInt(subTotal.arbolr), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.abr).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.volr).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' }
            ];
            filas.push(item);
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 30, 21, 23, 26, 26, 27, 25, 26, 27],
                body: filas
              }, layout: 'noBorders', colSpan: 10
            }
            item = [
              { text: dataInventario[i].correlativo, style: 'small', alignment: 'left' },
              { text: parseFloat(dataInventario[i].area).toFixed(4), style: 'small', alignment: 'left' },
              { text: dataInventario[i].pendientePromedio, style: 'small', alignment: 'left' },
              { text: dataInventario[i].incremento, style: 'small', alignment: 'left' },
              { text: dataInventario[i].tcClaseDesarrollo.codigoClase, style: 'small', alignment: 'left' },
              { text: dataInventario[i].edad, style: 'small', alignment: 'left' },
              tablaFila, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ];
            body.push(item);
          }
          item = [
            { text: 'TOTAL GENERAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 13 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            { text: parseInt(itemTotal.arbolr), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.abr).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.volr).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
          ];
          body.push(item);
          var resumenInventario = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 3,
              widths: [18, 20, 22, 22, 20, 20, 48, 30, 21, 22, 25, 26, 25, 25, 25, 26],
              body: body
            }
          }
          body = [
            [{ text: 'Datos de regresión utilizados para la estimación de las alturas (r2 y cme):', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' }],
            [{ text: data.ttInventarioGestion.regresion, style: 'detalle', alignment: 'left' }]
          ];
          var regresion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [523],
              body: body
            }
          }
          body = [
            [{ text: 'Análisis descriptivo (Realizar un breve análisis de los resultados obtenidos en el inventario)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' }],
            [{ text: data.ttInventarioGestion.analisisDescriptivo, style: 'detalle', alignment: 'left' }]
          ];
          var analisisDescriptivo = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [523],
              body: body
            }
          }
          //no maderable inventario
          body = [
            [
              { text: 'Productos forestales no maderables', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 7 }, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
              { text: 'Tipos de productos', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: titulos.Rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Año', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Producto', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Unidad de medida', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var resumenNoMaderable = {};
          encontrado = false;
          var dataNoMaderable = data.rodal;
          for (i = 0; i < dataNoMaderable.length; i++) {
            if (dataNoMaderable[i].productos.length > 0) {
              encontrado = true;
              break;
            }
          }
          if (encontrado) {
            for (i = 0; i < dataNoMaderable.length; i++) {
              filas = [];
              for (j = 0; j < dataNoMaderable[i].productos.length; j++) {
                item = [
                  { text: dataNoMaderable[i].productos[j].tcProducto.productoDesc, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].productos[j].tcUnidadMedida.unidadMedidaDesc, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].productos[j].cantidad, style: 'small', alignment: 'left' }
                ];
                filas.push(item);
              }
              if (dataNoMaderable[i].productos.length > 0) {
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [85, 65, 45],
                    body: filas
                  }, layout: 'noBorders', colSpan: 3
                }
                item = [
                  { text: dataNoMaderable[i].turno, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].correlativo, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].anio, style: 'small', alignment: 'left' },
                  { text: parseFloat(dataNoMaderable[i].area).toFixed(4), style: 'small', alignment: 'left' },
                  tablaFila
                ];
                body.push(item);
              }
            }
            resumenNoMaderable = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 3,
                widths: [25, 25, 25, 25, 85, 65, 45],
                body: body
              }
            }
          }

          var inventarioRodal = {};
          var distribucionDiametrica = {};
          body = [
            [
              { text: titulos.Rodales + ', área (s) y especie (s)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 4 }, {}, {}, {}
            ],
            [
              { text: titulos.Rodal + ' #', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especies', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'No. Parcelas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (i = 0; i < dataNoMaderable.length; i++) {
            var especies = '';
            for (j = 0; j < dataNoMaderable[i].especies.length; j++) {
              if (j == 0) {
                especies = dataNoMaderable[i].especies[j].tcEspecie.nombreCientifico;
              } else {
                especies += '\n' + dataNoMaderable[i].especies[j].tcEspecie.nombreCientifico;
              }
            }
            item = [
              { text: dataNoMaderable[i].correlativo, style: 'detalle', alignment: 'center' },
              { text: parseFloat(dataNoMaderable[i].area).toFixed(4), style: 'detalle', alignment: 'center' },
              { text: especies, style: 'detalle', alignment: 'left' },
              { text: dataNoMaderable[i].totalParcelas, style: 'detalle', alignment: 'center' }
            ]
            body.push(item);
          }
          inventarioRodal = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [100, 100, 200, 100],
              body: body
            }
          };
          //inicio distribución diametrica
          body = [
            [
              { text: '*Distribución diamétrica por ' + titulos.rodal + ' e intervención', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
            ]
          ];
          if (data.tcTipoGestion.tipoGestionId == 1) {
            var dataDistribucionRodal = data.rodal;
            var item = null;
            var fuste = null;
            var volumenTotal = 0;
            for (i = 0; i < dataDistribucionRodal.length; i++) { //rodal
              if (dataDistribucionRodal[i].tcTipoCobertura == null) {
                toastr.error('El inventario y la planificación son incorrectos, revise la pestaña: Inventario forestal y sección -> Resumen del inventario, luego la pestaña Planificación del manejo');
                return;
              }
              item = [
                { text: titulos.Rodal + ' ' + dataDistribucionRodal[i].correlativo + ' -> Tipo de cobertura: ' + dataDistribucionRodal[i].tcTipoCobertura.tipoCoberturaDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ]
              body.push(item);
              for (j = 0; j < dataDistribucionRodal[i].especies.length; j++) { //especie
                var especie = dataDistribucionRodal[i].especies[j];
                item = [
                  { text: titulos.Rodal + ' ' + dataDistribucionRodal[i].correlativo + ' -> Especie: ' + especie.tcEspecie.nombreCientifico, style: 'detalle', fillColor: '#CCCCCC', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
                ]
                body.push(item);
                item = [
                  { text: 'Clase Diamétrica', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Marca de Clase', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Arb/' + titulos.rodal, style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'AB/' + titulos.rodal + ' (m²)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Vol./' + titulos.rodal + ' (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Tratamiento silvicultural', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  //{ text: 'Arboles/Rodal extraer', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'AB extraer (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen extraer (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                ];
                body.push(item);
                subTotal = {
                  arbolr: 0,
                  abr: 0,
                  volr: 0,
                  pintervenir: 0,
                  arbolrExtraer: 0,
                  volrExtraer: 0,
                  abrExtraer: 0
                };
                var soloTalaRasa = true, esSuma = true;
                var tope = especie.distribucion.length;
                tope--;
                var tratamientoSilviculturalDesc = '';
                for (k = 0; k < especie.distribucion.length; k++) {
                  if (especie.distribucion[k].tcTratamientoSilvicultural == null) {
                    toastr.error('El rodal: ' + dataDistribucionRodal[i].correlativo + ' y especie: ' + especie.tcEspecie.nombreCientifico + ' no se ha planificado de forma correcta, revise la pestaña de planificación');
                    return;
                  }
                  if (especie.distribucion[k].tcTratamientoSilvicultural.tratamientoSilviculturalId != 2) {
                    soloTalaRasa = false;
                  }
                  if (data.tcTipoGestion.tipoGestionId == 3) {
                    tratamientoSilviculturalDesc = 'Corta de salvamento';
                  } else {
                    tratamientoSilviculturalDesc = especie.distribucion[k].tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
                  }
                  especie.distribucion[k].abr = parseFloat(especie.distribucion[k].abr).toFixed(4);
                  especie.distribucion[k].volr = parseFloat(especie.distribucion[k].volr);
                  especie.distribucion[k].arbolrExtraer = parseFloat(especie.distribucion[k].arbolrExtraer).toFixed(4);
                  especie.distribucion[k].volrExtraer = parseFloat(especie.distribucion[k].volrExtraer).toFixed(4);
                  especie.distribucion[k].abrExtraer = parseFloat(especie.distribucion[k].abrExtraer).toFixed(4);
                  subTotal.arbolr += parseInt(especie.distribucion[k].arbolr);
                  subTotal.abr += parseFloat(especie.distribucion[k].abr);
                  subTotal.volr += parseFloat(especie.distribucion[k].volr);
                  subTotal.pintervenir += parseInt(especie.distribucion[k].pintervenir);
                  subTotal.arbolrExtraer += parseFloat(especie.distribucion[k].arbolrExtraer);
                  subTotal.volrExtraer += parseFloat(especie.distribucion[k].volrExtraer);
                  subTotal.abrExtraer += parseFloat(especie.distribucion[k].abrExtraer);
                  item = [
                    { text: especie.distribucion[k].tcClaseDiametrica.claseDiametricaDesc, style: 'detalle', alignment: 'center' },
                    { text: especie.distribucion[k].tcClaseDiametrica.marcaClase, style: 'detalle', alignment: 'right' },
                    { text: especie.distribucion[k].arbolr, style: 'detalle', alignment: 'right' },
                    { text: especie.distribucion[k].abr, style: 'detalle', alignment: 'right' },
                    { text: especie.distribucion[k].volr, style: 'detalle', alignment: 'right' },
                    { text: tratamientoSilviculturalDesc, style: 'detalle', alignment: 'left' },
                    //{ text: especie.distribucion[k].arbolrExtraer, style: 'detalle', alignment: 'right' },
                    { text: especie.distribucion[k].abrExtraer, style: 'detalle', alignment: 'right' },
                    { text: especie.distribucion[k].volrExtraer, style: 'detalle', alignment: 'right' }
                  ];
                  volumenTotal += parseFloat(especie.distribucion[k].volrExtraer);
                  if (soloTalaRasa && tope == k) {
                    var tmplas = angular.copy(subTotal);
                    var volex = parseFloat(subTotal.volrExtraer).toFixed(4);
                    var abex = parseFloat(subTotal.abrExtraer).toFixed(4);
                    var dvol = parseFloat(especie.volr) - volex;
                    var dab = parseFloat(especie.abr) - abex;
                    var nuevoValor = especie.distribucion[k].volrExtraer + dvol;
                    nuevoValor = parseFloat(nuevoValor).toFixed(4);
                    var sumar = false;
                    if (especie.distribucion[k].volrExtraer == especie.distribucion[k].volr) {
                      sumar = true;
                    }
                    especie.distribucion[k].volrExtraer = nuevoValor;
                    if (sumar) {
                      especie.distribucion[k].volr = nuevoValor;
                    }
                    nuevoValor = subTotal.volrExtraer + dvol;
                    nuevoValor = parseFloat(nuevoValor).toFixed(4);
                    subTotal.volrExtraer = nuevoValor;
                    if (sumar) {
                      subTotal.volr = nuevoValor;
                    }
                    nuevoValor = especie.distribucion[k].abrExtraer + dab;
                    nuevoValor = parseFloat(nuevoValor).toFixed(4);
                    sumar = false;
                    if (especie.distribucion[k].abrExtraer == especie.distribucion[k].abr) {
                      sumar = true;
                    }
                    especie.distribucion[k].abrExtraer = nuevoValor;
                    if (sumar) {
                      especie.distribucion[k].abr = nuevoValor;
                    }
                    nuevoValor = subTotal.abrExtraer + dab;
                    subTotal.abrExtraer = parseFloat(nuevoValor).toFixed(4);
                    if (sumar) {
                      subTotal.abr = nuevoValor;
                    }
                    item = [
                      { text: especie.distribucion[k].tcClaseDiametrica.claseDiametricaDesc, style: 'detalle', alignment: 'center' },
                      { text: especie.distribucion[k].tcClaseDiametrica.marcaClase, style: 'detalle', alignment: 'right' },
                      { text: especie.distribucion[k].arbolr, style: 'detalle', alignment: 'right' },
                      { text: especie.distribucion[k].abr, style: 'detalle', alignment: 'right' },
                      { text: especie.distribucion[k].volr, style: 'detalle', alignment: 'right' },
                      { text: tratamientoSilviculturalDesc, style: 'detalle', alignment: 'left' },
                      //{ text: especie.distribucion[k].arbolrExtraer, style: 'detalle', alignment: 'right' },
                      { text: especie.distribucion[k].abrExtraer, style: 'detalle', alignment: 'right' },
                      { text: especie.distribucion[k].volrExtraer, style: 'detalle', alignment: 'right' }
                    ];
                  }
                  body.push(item);
                }
                item = [
                  { text: 'TOTAL', fillColor: '#CCCCCC', style: 'detalle', alignment: 'center', colSpan: 2 }, {},
                  { text: subTotal.arbolr, style: 'detalle', alignment: 'right' },
                  { text: parseFloat(subTotal.abr).toFixed(4), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(subTotal.volr).toFixed(4), style: 'detalle', alignment: 'right' },
                  { text: 'TOTAL', fillColor: '#CCCCCC', style: 'detalle', alignment: 'left' },
                  //{ text: parseFloat(subTotal.arbolrExtraer).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(subTotal.abrExtraer).toFixed(4), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(subTotal.volrExtraer).toFixed(4), style: 'detalle', alignment: 'right' }
                ]
                body.push(item);
                if (especie.fuste != undefined) {
                  if (especie.fuste != null) {
                    filas = [];
                    item = [
                      { text: 'Calidad de fuste', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: 'Arb/' + titulos.rodal + ' (existente)', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: 'AB/' + titulos.rodal + ' (m²) (existente)', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: 'Vol/' + titulos.rodal + ' (m³) (existente)', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: '% Extraer', style: 'small', fillColor: '#CCCCCC', alignment: 'left' },
                      //{ text: '% Extraer	Arboles/Rodal extraer', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: 'AB extraer (m³)', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: 'Volumen extraer (m³)', style: 'small', fillColor: '#CCCCCC', alignment: 'left' }
                    ];
                    filas.push(item);
                    subTotal = {
                      arbolr: 0,
                      abr: 0,
                      volr: 0,
                      pintervenir: 0,
                      arbolrExtraer: 0,
                      volrExtraer: 0,
                      abrExtraer: 0,
                      cantidad: 0
                    };
                    for (l = 0; l < especie.fuste.length; l++) {
                      fuste = especie.fuste[l];
                      subTotal.arbolr += parseInt(fuste.arbolr);
                      subTotal.abr += parseFloat(fuste.abr);
                      subTotal.volr += parseFloat(fuste.volr);
                      subTotal.pintervenir += parseInt(fuste.pintervenir);
                      subTotal.arbolrExtraer += parseFloat(fuste.arbolrExtraer);
                      subTotal.volrExtraer += parseFloat(fuste.volrExtraer);
                      subTotal.abrExtraer += parseFloat(fuste.abrExtraer);
                      subTotal.cantidad++;
                      item = [
                        { text: fuste.calidadFusteDesc, style: 'small', alignment: 'left' },
                        { text: fuste.arbolr, style: 'small', alignment: 'left' },
                        { text: fuste.abr, style: 'small', alignment: 'right' },
                        { text: fuste.volr, style: 'small', alignment: 'right' },
                        { text: fuste.pintervenir, style: 'small', alignment: 'right' },
                        //{ text: fuste.arbolrExtraer, style: 'small', alignment: 'right' },
                        { text: fuste.abrExtraer, style: 'small', alignment: 'right' },
                        { text: fuste.volrExtraer, style: 'small', alignment: 'right' }
                      ];
                      filas.push(item);
                    }
                    subTotal.pintervenir = parseInt(subTotal.arbolrExtraer) / parseInt(subTotal.arbolr);
                    subTotal.pintervenir = subTotal.pintervenir * 100;
                    item = [
                      { text: 'TOTAL', fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: subTotal.arbolr, fillColor: '#CCCCCC', style: 'small', alignment: 'left' },
                      { text: parseFloat(subTotal.abr).toFixed(4), fillColor: '#CCCCCC', style: 'small', alignment: 'right' },
                      { text: parseFloat(subTotal.volr).toFixed(4), fillColor: '#CCCCCC', style: 'small', alignment: 'right' },
                      { text: Math.round(subTotal.pintervenir), fillColor: '#CCCCCC', style: 'small', alignment: 'right' },
                      //{ text: parseInt(subTotal.arbolrExtraer), fillColor: '#CCCCCC', style: 'small', alignment: 'right' },
                      { text: parseFloat(subTotal.abrExtraer).toFixed(4), fillColor: '#CCCCCC', style: 'small', alignment: 'right' },
                      { text: parseFloat(subTotal.volrExtraer).toFixed(4), fillColor: '#CCCCCC', style: 'small', alignment: 'right' }
                    ];
                    filas.push(item);
                    tablaFila = {
                      style: 'estiloTablaEstrecha',
                      table: {
                        headerRows: 1,
                        widths: [100, 60, 60, 60, 60, 55, 55],
                        body: filas
                      }, layout: 'noBorders', colSpan: 8
                    }
                    item = [
                      { text: 'Resumen por calidad de fuste para la especie: ' + especie.tcEspecie.nombreCientifico + ' en el rodal no. ' + dataDistribucionRodal[i].correlativo, style: 'detalle', fillColor: '#BDBDBD', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
                    ]
                    body.push(item);
                    item = [
                      tablaFila, {}, {}, {}, {}, {}, {}, {}
                    ]
                    body.push(item);
                    if (volumenTotal <= 0 && !bndEstaMal) {
                      bndEstaMal = true;
                      mensajeMal = 'La planificación por clase diamétrica (pestaña Planificación del manejo) está mal, debe planificar por especie y clase diamétrica';
                    }
                  }
                }
              }
            }
            distribucionDiametrica = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [50, 50, 45, 50, 50, 100, 55, 50],
                body: body
              }
            };
          } else {
            distribucionDiametrica = {};
          }
          //aplica solo para muestreo
          var analisisEstadistico = {};
          if (data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 1) {
            if (data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 1 || data.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 2) {
              body = [
                [
                  { text: 'Análisis estadístico del inventario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
                ],
                [
                  { text: titulos.Rodal, style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'No. Parcelas/' + titulos.rodal, style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Vol./ha (m³)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Media Vol./parcela (m³)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Desviación Estándar', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Coeficiente de variación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Error estándar de la media', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Error de muestreo', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Error de muestreo en %', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Intervalo confianza inferior', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Intervalo confianza superior', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
              var tmpEstadistico = data.rodal;
              var tmpTotal = {
                area: 0,
                parcelas: 0
              };
              var dataAnalisisEstadistico = data.rodal;
              for (i = 0; i < dataAnalisisEstadistico.length; i++) {
                var contieneMuestreo = false;
                for (j = 0; j < dataAnalisisEstadistico[i].especies.length; j++) {
                  if (dataAnalisisEstadistico[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                    contieneMuestreo = true;
                    break;
                  }
                }
                if (!contieneMuestreo) {
                  dataAnalisisEstadistico[i].ttEstadisticoRodal = null;
                }
                if (dataAnalisisEstadistico[i].ttEstadisticoRodal != null) {
                  item = [
                    { text: dataAnalisisEstadistico[i].correlativo, style: 'detalle', alignment: 'left' },
                    { text: parseFloat(dataAnalisisEstadistico[i].area).toFixed(4), style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].totalParcelas, style: 'detalle', alignment: 'left' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.volh, style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.vpEstrato).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.desviacionEstandar).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.coeficienteVariacion).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.errors).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.errorm).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.errorp, style: 'detalle', alignment: 'right' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.lcInferior).toFixed(2), style: 'detalle', alignment: 'center' },
                    { text: parseFloat(dataAnalisisEstadistico[i].ttEstadisticoRodal.lcSuperior).toFixed(2), style: 'detalle', alignment: 'center' }
                  ]
                  tmpTotal.area = tmpTotal.area + dataAnalisisEstadistico[i].area;
                  tmpTotal.parcelas = tmpTotal.parcelas + dataAnalisisEstadistico[i].totalParcelas;
                  body.push(item);
                }
              }
              item = [
                { text: "TOTAL", style: 'detalle', alignment: 'left', fillColor: '#CCCCCC' },
                { text: parseFloat(tmpTotal.area).toFixed(4), style: 'detalle', alignment: 'right' },
                { text: tmpTotal.parcelas, style: 'detalle', alignment: 'left' },
                { text: '', style: 'detalle', alignment: 'right', colSpan: 9, fillColor: '#CCCCCC' }, {}, {}, {}, {}, {}, {}, {}, {}
              ];
              body.push(item);
              analisisEstadistico = {
                style: 'estiloTabla',
                table: {
                  headerRows: 2,
                  widths: [30, 30, 30, 35, 37, 36, 35, 40, 35, 35, 40, 40],
                  body: body
                }
              };
            } else {
              body = [
                [
                  { text: 'ANÁLISIS ESTADÍSTICO ESTRATIFICADO DEL INVENTARIO', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
                ],
                [
                  { text: titulos.Rodal, style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Xj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'nj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Nhj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Pj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Xj * Nhj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sj * Pj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sj² * Pj', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Según área', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Según variabilidad', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
              var tmpTotal = {
                area: 0,
                parcelas: 0
              };
              var dataAnalisisEstadistico = data.rodal;
              for (i = 0; i < dataAnalisisEstadistico.length; i++) {
                if (dataAnalisisEstadistico[i].ttEstadisticoRodal != null) {
                  item = [
                    { text: dataAnalisisEstadistico[i].correlativo, style: 'detalle', alignment: 'left' },
                    { text: parseFloat(dataAnalisisEstadistico[i].area).toFixed(4), style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].volh, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.desviacionEstandar, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.nParcelas, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.nhj, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.pj, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.vXjxNj, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.vSjxPj, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.vSj2xPj, style: 'detalle', alignment: 'right' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.segunArea, style: 'detalle', alignment: 'center' },
                    { text: dataAnalisisEstadistico[i].ttEstadisticoRodal.segunVariabilidad, style: 'detalle', alignment: 'center' }
                  ]
                  body.push(item);
                }
              }
              var tmpTotal = data.ttEstadisticoGestion;
              item = [
                { text: "TOTAL", fillColor: '#CCCCCC', style: 'detalle', alignment: 'left' },
                { text: parseFloat(tmpTotal.area).toFixed(4), style: 'detalle', alignment: 'right' },
                { text: tmpTotal.Xj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.Sj, style: 'detalle', alignment: 'right' },
                { text: parseInt(tmpTotal.nj), style: 'detalle', alignment: 'right' },
                { text: tmpTotal.nhj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.Pj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.vXjxNj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.vSjxPj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.vSj2xPj, style: 'detalle', alignment: 'right' },
                { text: tmpTotal.segunArea, style: 'detalle', alignment: 'center' },
                { text: tmpTotal.segunVariabilidad, style: 'detalle', alignment: 'center' }
              ]
              body.push(item);
              item = [
                { text: '\nVariables estratificadas', fillColor: '#CCCCCC', style: 'tableSubHeader', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: '\nResultados', fillColor: '#CCCCCC', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Volumen promedio (m³/ha)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.volumenPromedio, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Desviación estándar (%)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.desviacionEstandar, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Error estándar (m³/ha)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.errors, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Error de muestreo (m³/ha)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.errorm, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Error de muestreo como porcentaje (%)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.errorp, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Limite de confianza superior (m³/ha)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.lcSuperior, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Limite de confianza inferior (m³/ha)", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.lcInferior, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: "Número total de parcelas a levantar", style: 'tableSubHeader', alignment: 'left', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
                { text: tmpTotal.nParcelas, style: 'tableSubHeader', alignment: 'right', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              analisisEstadistico = {
                style: 'estiloTabla',
                table: {
                  headerRows: 2,
                  widths: [30, 30, 30, 30, 42, 36, 35, 40, 35, 35, 40, 40],
                  body: body
                }
              };
            }
          }
          /*End tab inventario*/
          /**
          * tab resumen
          */
          var tratamientoSilvicultural = [];
          var strTSilvicultura = "";
          var dataTurnoUno = [];
          for (var i = 0; i < data.rodal.length; i++) {
            var rodal = data.rodal[i];
            if (rodal.turno == 1) {
              dataTurnoUno.push(rodal);
            }
            rodal.abrExtraerPoa = 0;
            rodal.abrPoa = 0;
            for (var j = 0; j < rodal.especies.length; j++) {
              var tcTratamientoSilvicultural = rodal.especies[j].tcTratamientoSilvicultural;
              if (tcTratamientoSilvicultural == null) {
                toastr.error('Debe realizar la planificación en la pestaña Planificación del manejo, sección Distribución diamétrica por rodal e intervención');
                return;
              }
              if (data.tcTipoGestion.tipoGestionId == 3) {
                tratamientoSilviculturalDesc = 'Corta de salvamento';
              } else {
                tratamientoSilviculturalDesc = tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
              }
              var encontrado = false;
              for (var k = 0; k < tratamientoSilvicultural.length; k++) {
                if (tcTratamientoSilvicultural.tratamientoSilviculturalId == tratamientoSilvicultural[k].tratamientoSilviculturalId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                if (strTSilvicultura == "") {
                  strTSilvicultura = tratamientoSilviculturalDesc;
                } else {
                  strTSilvicultura = strTSilvicultura + "\n" + tratamientoSilviculturalDesc;
                }
                tratamientoSilvicultural.push({ tratamientoSilviculturalId: tcTratamientoSilvicultural.tratamientoSilviculturalId });
              }
              var especie = rodal.especies[j];
              rodal.abrExtraerPoa += parseFloat(especie.abrExtraer);
              rodal.abrPoa += parseFloat(especie.abr);
            }
            value = (rodal.abrExtraerPoa * 100) / rodal.abrPoa;
            value = Math.round(value);
            rodal.porcentaje = value;
            rodal.areaGarantia = rodal.area;
            if (rodal.tcTipoCobertura.tipoCoberturaId == 1) {
              if (value > 70) {
                rodal.areaCompromiso = rodal.area;
              } else {
                value = rodal.abrExtraerPoa / rodal.abrPoa;
                value = value * rodal.area;
                rodal.areaCompromiso = parseFloat(value).toFixed(4);
              }
            } else {
              var areaIdeal = 20;
              if (data.tcTipoBosque.tipoBosqueId == 3) {
                areaIdeal = 25;
              }
              value = rodal.abrExtraerPoa / areaIdeal;
              rodal.areaCompromiso = parseFloat(value).toFixed(4);
              if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                rodal.areaGarantia = rodal.areaCompromiso;
              }
            }
          }
          dataTmp = data.rodal;
          var totalManejar = 0, totalExtraer = 0; totalRemanente = 0;
          for (i = 0; i < dataTmp.length; i++) {
            for (j = 0; j < dataTmp[i].especies.length; j++) {
              totalManejar += parseFloat(dataTmp[i].especies[j].volr);
              totalExtraer += parseFloat(dataTmp[i].especies[j].volrExtraer);
            }
          }
          totalRemanente = totalManejar - totalExtraer;
          totalRemanente = parseFloat(totalRemanente).toFixed(2);
          dataTmp = data.especies;
          var strEspecie = "";
          for (i = 0; i < dataTmp.length; i++) {
            if (i == 0) {
              strEspecie = dataTmp[i].tcEspecie.nombreCientifico;
            } else {
              strEspecie = strEspecie + "\n" + dataTmp[i].tcEspecie.nombreCientifico;
            }
          }
          dataTmp = data.sistemaRepoblacion;
          var strRepoblacion = "";
          for (i = 0; i < dataTmp.length; i++) {
            if (i == 0) {
              strRepoblacion = dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
            } else {
              strRepoblacion = strRepoblacion + "\n" + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
            }
          }
          dataTmp = data.rodal;
          if (data.rodalesRepoblacion == null) {
            data.rodalesRepoblacion = [];
          }
          if (data.rodalesRepoblacion.length > 0) {
            dataTmp = data.rodalesRepoblacion;
          }
          var strEspecieCompromiso = "";
          var tmpEspecie = [];
          for (i = 0; i < dataTmp.length; i++) {
            for (j = 0; j < dataTmp[i].repoblacion.length; j++) {
              //buscar que no este repetido
              encontrado = false;
              for (k = 0; k < tmpEspecie.length; k++) {
                if (tmpEspecie[k].especieId == dataTmp[i].repoblacion[j].tcEspecie.especieId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                if (strEspecieCompromiso.length <= 0) {
                  strEspecieCompromiso = dataTmp[i].repoblacion[j].tcEspecie.nombreCientifico;
                } else {
                  strEspecieCompromiso = strEspecieCompromiso + "\n" + dataTmp[i].repoblacion[j].tcEspecie.nombreCientifico;
                }
                tmpEspecie.push({ especieId: dataTmp[i].repoblacion[j].tcEspecie.especieId });
              }
            }
          }
          dataTmp = data.rodal;
          var areaTurno = 0, areaCompromiso = 0;
          for (var i = 0; i < dataTurnoUno.length; i++) {
            var rodal = dataTurnoUno[i];
            areaTurno += parseFloat(rodal.areaGarantia);
            areaCompromiso += parseFloat(rodal.areaCompromiso);
          }
          var textoVigencia = 'Vigencia del plan (años)';
          body = [
            [
              { text: 'VII. Resumen del plan de manejo forestal', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center', colSpan: 2 }, {}
            ],
            [
              { text: textoVigencia, style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttResumenGestion.anios, style: 'detalle', alignment: 'left' }
            ],
            [
              { text: 'Tiempo de ejecución de la extracción para el primer turno (meses)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttResumenGestion.meses, style: 'detalle', alignment: 'left' }
            ],
            [
              { text: 'Superficie a intervenir (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(data.area).toFixed(4), style: 'detalle', alignment: 'left' }
            ]
          ];
          if (data.tcTipoGestion.tipoGestionId == 1) {
            body.push(
              [
                { text: 'Sistema Silvicultural:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttResumenGestion.tcSistemaCorta.sistemaCortaDesc, style: 'detalle', alignment: 'left' }
              ]
            );
          }
          body.push(
            [
              { text: 'Numero de turnos:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttResumenGestion.noTurnos, style: 'detalle', alignment: 'left' }
            ]
          );
          body.push(
            [
              { text: 'Tratamiento (s) silvicultural (es)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strTSilvicultura, style: 'detalle', alignment: 'left' }
            ]
          );
          if (data.tcTipoGestion.tipoGestionId == 1) {
            body.push(
              [
                { text: 'Incremento anual del bosque (m³/ha/año)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.incremento, style: 'detalle', alignment: 'left' }
              ]
            );
          }
          body.push(
            [
              { text: 'Corta anual permisible (m³/año)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttPlanificacionGestion.cap, style: 'detalle', alignment: 'left' }
            ]
          );
          body.push(
            [
              { text: 'Volumen total del bosque a manejar (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(totalManejar).toFixed(4), style: 'detalle', alignment: 'left' }
            ]
          );
          body.push(
            [
              { text: 'Volumen a extraer (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(totalExtraer).toFixed(4), style: 'detalle', alignment: 'left' }
            ]
          );
          body.push(
            [
              { text: 'Volumen remanente (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(totalRemanente).toFixed(4), style: 'detalle', alignment: 'left' }
            ]
          );
          body.push(
            [
              { text: 'Especies a aprovechar (nombre científico)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strEspecie, style: 'detalle', alignment: 'left' }
            ]
          );
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            body.push(
              [
                { text: 'Sistema de repoblación forestal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: strRepoblacion, style: 'detalle', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: 'Especies del compromiso de repoblación', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: strEspecieCompromiso, style: 'detalle', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: 'Área (ha) de compromiso del primer turno', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(areaTurno).toFixed(4), style: 'detalle', alignment: 'left' }
              ]
            );
            if (data.ttResumenGestion.tcTipoGarantia != null) {
              body.push(
                [
                  { text: 'Área (ha) para estimar el monto de garantía del primer turno', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(areaCompromiso).toFixed(4), style: 'detalle', alignment: 'left' }
                ]
              );
              body.push(
                [
                  { text: 'Tipo de garantía del primer turno', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc, style: 'detalle', alignment: 'left' }
                ]
              );
            }
            body.push(
              [
                { text: 'Edad de rotación (años)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttResumenGestion.edadRotacion, style: 'detalle', alignment: 'left' }
              ]
            );
          } else {
            body.push(
              [
                { text: 'Tipo de garantía', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Pago al Fondo Forestal Privativo', style: 'detalle', alignment: 'left' }
              ]
            );
          }
          if (data.ttResumenGestion.tcRegente != null) {
            body.push(
              [
                { text: 'Regente propuesto', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttResumenGestion.tcRegente.personaDesc, style: 'detalle', alignment: 'left' }
              ]
            );
          }
          var resumenGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [250, 250],
              body: body
            }
          };
          if (data.tcTipoGestion.tipoGestionId == 1) {
            body = [
              [
                { text: 'VIII. Planificación del manejo', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: 'Crecimiento anual del bosque y posibilidad de corta', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: 'Criterio de regulación de la corta', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.tcCriterioCorta.criterioCortaDesc, style: 'detalle', alignment: 'left' },
                { text: 'Incremento anual (m³/ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.incremento, style: 'detalle', alignment: 'left' },
                { text: 'CAP m³:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.cap, style: 'detalle', alignment: 'left' },
                { text: 'Fórmula utilizada', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.formula, style: 'detalle', alignment: 'left' }
              ],
              [
                { text: 'Justificación técnica del manejo', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.justificacion, style: 'detalle', alignment: 'left', colSpan: 7 }, {}, {}, {}, {}, {}, {}
              ]
            ];
          } else {
            body = [
              [
                { text: 'VIII. Planificación del manejo', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: 'CAP m³:', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left', colSpan: 2 }, {},
                { text: data.ttPlanificacionGestion.cap, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: 'Fórmula utilizada', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left', colSpan: 2 }, {},
                { text: data.ttPlanificacionGestion.formula, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
              ],
              [
                { text: 'Justificación técnica del manejo', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttPlanificacionGestion.justificacion, style: 'detalle', alignment: 'left', colSpan: 7 }, {}, {}, {}, {}, {}, {}
              ]
            ];
          }
          var propuestaManejo = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [65, 50, 55, 30, 40, 30, 40, 150],
              body: body
            }
          }

          //silvicultura
          body = [
            [
              { text: 'Silvicultura', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Productos forestales maderables', style: 'tableSubHeader', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
              { text: 'Tipo de productos y volúmen a extraer', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: titulos.Rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Año aprovechamiento', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie (código)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Tratamiento silvicultural', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³/' + titulos.rodal + ')', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³/' + titulos.rodal + ')', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen total (m³/' + titulos.rodal + ')', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área basal / rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          itemTotal = {
            troza: 0,
            lenia: 0,
            otros: 0,
            volrExtraer: 0,
            abrExtraer: 0
          }
          var dataSilvicultura = data.rodal;
          for (i = 0; i < dataSilvicultura.length; i++) {
            filas = [];
            subTotal = {
              troza: 0,
              lenia: 0,
              otros: 0,
              volrExtraer: 0,
              abrExtraer: 0
            };
            for (j = 0; j < dataSilvicultura[i].especies.length; j++) {
              dataSilvicultura[i].especies[j].troza = parseFloat(dataSilvicultura[i].especies[j].troza).toFixed(4);
              dataSilvicultura[i].especies[j].lenia = parseFloat(dataSilvicultura[i].especies[j].lenia).toFixed(4);
              dataSilvicultura[i].especies[j].otros = parseFloat(dataSilvicultura[i].especies[j].otros).toFixed(4);
              dataSilvicultura[i].especies[j].volrExtraer = parseFloat(dataSilvicultura[i].especies[j].volrExtraer).toFixed(4);
              dataSilvicultura[i].especies[j].abrExtraer = parseFloat(dataSilvicultura[i].especies[j].abrExtraer).toFixed(4);
              subTotal.troza += parseFloat(dataSilvicultura[i].especies[j].troza);
              subTotal.lenia += parseFloat(dataSilvicultura[i].especies[j].lenia);
              subTotal.otros += parseFloat(dataSilvicultura[i].especies[j].otros);
              subTotal.volrExtraer += parseFloat(dataSilvicultura[i].especies[j].volrExtraer);
              subTotal.abrExtraer += parseFloat(dataSilvicultura[i].especies[j].abrExtraer);
              if (data.tcTipoGestion.tipoGestionId == 3) {
                tratamientoSilviculturalDesc = 'Corta de salvamento';
              } else {
                tratamientoSilviculturalDesc = dataSilvicultura[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
              }
              var volrExtraer = parseFloat(dataSilvicultura[i].especies[j].troza) + parseFloat(dataSilvicultura[i].especies[j].lenia);
              volrExtraer = parseFloat(volrExtraer).toFixed(4);
              var volrExtraerVerificar = parseFloat(dataSilvicultura[i].especies[j].volrExtraer).toFixed(4);
              if (volrExtraer != volrExtraerVerificar) {
                var diferencia = volrExtraerVerificar - volrExtraer;
                diferencia = Math.abs(diferencia);
                diferencia = parseFloat(diferencia).toFixed(4);
                if (diferencia > 0.0001 && (data.estadoId == 2 || data.estadoId == 3 || data.estadoId == 4 || data.estadoId == 6)) {
                  swal('El volumen de troza y leña a extraer en el rodal ' + dataSilvicultura[i].correlativo 
                  + ', especie ' + dataSilvicultura[i].especies[j].tcEspecie.nombreCientifico 
                  + ' no es correcta. Confirme el porcentaje de troza, verifique la sumatoria de troza y leña (pestaña planificación, sección Silvicultura) y presione en guardar');
                  return;
                }
              }
              item = [
                { text: dataSilvicultura[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: tratamientoSilviculturalDesc, style: 'small', alignment: 'left' },
                { text: dataSilvicultura[i].especies[j].troza, style: 'small', alignment: 'right' },
                { text: dataSilvicultura[i].especies[j].lenia, style: 'small', alignment: 'right' },
                { text: dataSilvicultura[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                { text: dataSilvicultura[i].especies[j].abrExtraer, style: 'small', alignment: 'right' }
              ];
              filas.push(item);
            }
            //subtotal
            item = [
              { text: 'SUBTOTAL', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(subTotal.troza).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.lenia).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.volrExtraer).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.abrExtraer).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' }
            ];
            filas.push(item);
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [80, 70, 45, 45, 55, 45],
                body: filas
              }, layout: 'noBorders', colSpan: 6
            }
            itemTotal.troza += subTotal.troza;
            itemTotal.lenia += subTotal.lenia;
            itemTotal.otros += subTotal.otros;
            itemTotal.volrExtraer += subTotal.volrExtraer;
            itemTotal.abrExtraer += subTotal.abrExtraer;
            item = [
              { text: dataSilvicultura[i].turno, style: 'small', alignment: 'left' },
              { text: dataSilvicultura[i].correlativo, style: 'small', alignment: 'left' },
              { text: dataSilvicultura[i].anio, style: 'small', alignment: 'left' },
              { text: parseFloat(dataSilvicultura[i].area).toFixed(4), style: 'small', alignment: 'left' },
              tablaFila
            ];
            body.push(item);
          }
          item = [
            { text: 'TOTAL GENERAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {},
            { text: parseFloat(itemTotal.troza).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.lenia).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.volrExtraer).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.abrExtraer).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
          ];
          body.push(item);
          var silvicultura = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 3,
              widths: [25, 25, 25, 25, 55, 95, 45, 45, 55, 45],
              body: body
            }
          }
          //plan de corta
          var planCorta = {};
          if (data.tcTipoGestion.tipoGestionId == 1) {
            body = [
              [
                { text: 'Plan de Corta', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
                { text: 'Volumen actual', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Volumen a extraer', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Volumen residual', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: titulos.Rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Año', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen m³/' + titulos.rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen m³/' + titulos.rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen m³/' + titulos.rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var dataPlanCorta = data.rodal;
            for (i = 0; i < dataPlanCorta.length; i++) {
              for (j = 0; j < dataPlanCorta[i].especies.length; j++) {
                item = [
                  { text: dataPlanCorta[i].turno, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].correlativo, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].anio, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].abr).toFixed(4), style: 'small', alignment: 'right' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].volr).toFixed(4), style: 'small', alignment: 'right' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].abrExtraer).toFixed(4), style: 'small', alignment: 'right' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].abResidual).toFixed(4), style: 'small', alignment: 'right' },
                  { text: parseFloat(dataPlanCorta[i].especies[j].volResidual).toFixed(4), style: 'small', alignment: 'right' }
                ];
                body.push(item);
              }
            }
            planCorta = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 3,
                widths: [25, 25, 30, 63, 50, 45, 55, 55, 45, 55],
                body: body
              }
            }
          }
          /* Productos forestales no maderables para la planificacion*/
          dataNoMaderable = data.rodal;
          encontrado = false;
          planificacionNoMaderable = {};
          for (i = 0; i < dataNoMaderable.length; i++) {
            if (dataNoMaderable[i].productos.length > 0) {
              encontrado = true;
              break;
            }
          }
          if (encontrado) {
            body = [
              [
                { text: 'Productos forestales no maderables', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center', colSpan: 7 }, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
                { text: 'Productos ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: titulos.Rodal, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Año', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Tipo de producto', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Unidad de Medida', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Cantidad', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            for (i = 0; i < dataNoMaderable.length; i++) {
              filas = [];
              for (j = 0; j < dataNoMaderable[i].productos.length; j++) {
                item = [
                  { text: dataNoMaderable[i].productos[j].tcProducto.productoDesc, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].productos[j].tcUnidadMedida.unidadMedidaDesc, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].productos[j].cantidad, style: 'small', alignment: 'right' }
                ];
                filas.push(item);
              }
              if (dataNoMaderable[i].productos.length > 0) {
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [120, 75, 75],
                    body: filas
                  }, layout: 'noBorders', colSpan: 3
                }
                item = [
                  { text: dataNoMaderable[i].turno, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].correlativo, style: 'small', alignment: 'left' },
                  { text: dataNoMaderable[i].anio, style: 'small', alignment: 'left' },
                  { text: parseFloat(dataNoMaderable[i].area).toFixed(4), style: 'small', alignment: 'left' },
                  tablaFila, {}, {}
                ];
                body.push(item);
              }
            }
            planificacionNoMaderable = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [40, 40, 50, 55, 120, 85, 80],
                body: body
              }
            }
          }
          /*Otras descripciones en planificacion*/
          body = [
            [
              { text: 'Descripción de las actividades de aprovechamiento', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
            ],
            [
              { text: data.ttPlanificacionGestion.actividadAprovechamiento, style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          if (data.tcTipoGestion.tipoGestionId == 5) {
            body.push(
              [
                { text: 'Actividades post aprovechamiento', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: data.ttPlanificacionGestion.postAprovechamiento, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          }
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            body.push(
              [
                { text: 'Recuperación de la masa forestal', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: 'Objetivos de la recuperación del bosque', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: data.ttPlanificacionGestion.masaForestal, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: 'Justificación de la especie a utilizar', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
              ]
            );
            body.push(
              [
                { text: data.ttPlanificacionGestion.justificacionEspecie, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          }
          var otrasPlanificacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [530],
              body: body
            }
          }
          var dataRepoblacion = {};
          var tbGarantia = {};

          if (data.sistemaRepoblacion.length > 0 && data.ttPlanificacionGestion.hayReforestacion == 1) {
            var colSpan = data.sistemaRepoblacion.length;
            body = [
              [
                { text: 'Sistema de repoblación forestal', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left', colSpan: 4 }, {}, {}, {}
              ]
            ];
            fila = [
              { text: '\n', style: 'tableSubHeader', alignment: 'left', colSpan: 4 }, {}, {}, {}
            ];
            //para titulo de sistemas utilizados
            fila = [
              { text: 'Sistemas de repoblación utilizados', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 4 }, {}, {}, {}
            ];
            body.push(fila);
            var repoblacion = data.sistemaRepoblacion;
            var tmpDatos = [];
            j = 0;
            for (i = 0; i < repoblacion.length; i++) {
              tmpDatos.push({ text: repoblacion[i].tcSistemaRepoblacion.sistemaRepoblacionDesc, style: 'detalle', alignment: 'left' });
              j++;
              if (j > 3) {
                body.push(tmpDatos);
                tmpDatos = [];
                j = 0;
              }
            }
            if (j > 0) {
              for (i = j; i < 4; i++) {
                tmpDatos.push({ text: '', style: 'detalle', alignment: 'left' });
              }
              body.push(tmpDatos);
            }
            dataRepoblacion = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [125, 125, 125, 125],
                body: body
              }
            }
          }
          /*Planificacion de la repoblacion forestal*/
          var dataPlanificacion = {};
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            var repoblacion = [];
            if (data.rodalesRepoblacion == null) {
              data.rodalesRepoblacion = [];
            }
            repoblacion = data.rodalesRepoblacion;
            if (data.tcTipoGestion.tipoGestionId == 5) {
              body = [
                [
                  { text: 'Planificación de la repoblación forestal', style: 'tableSubHeader', alignment: 'center', colSpan: 7 }, {}, {}, {}, {}, {}, {}
                ],
                [
                  { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: titulos.Rodal, style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Densidad inicial / ha', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Planificación de la repoblación forestal', style: 'tableSubHeader', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
                ],
                [
                  { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: titulos.Rodal, style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Manejo de Remanente', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Densidad inicial / ha', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            }
            if (repoblacion.length <= 0) {
              toastr.error('Debe indicar las especies por rodal con las que va a recuperar el área (Planificación de la repoblación forestal)');
              return;
            }
            itemTotal = {
              area: 0,
              arbolResidual: 0,
              total: 0
            }
            for (i = 0; i < repoblacion.length; i++) {
              if (repoblacion[i].obligatorio) {
                var rodalesPlan = data.rodal;
                for (j = 0; j < rodalesPlan.length; j++) {
                  var rodalPlan = rodalesPlan[j];
                  if (parseInt(repoblacion[i].correlativo) == parseInt(rodalPlan.correlativo)) {
                    repoblacion[i].remanente = rodalPlan.remanente;
                    repoblacion[i].areaGarantia = rodalPlan.areaGarantia;
                    repoblacion[i].areaCompromiso = rodalPlan.areaCompromiso;
                    break;
                  }
                }
              } else {
                repoblacion[i].remanente = 0;
                repoblacion[i].areaGarantia = repoblacion[i].area;
                repoblacion[i].areaCompromiso = repoblacion[i].area;
              }
              if (repoblacion[i].repoblacion.length > 0) {
                filas = [];
                for (j = 0; j < repoblacion[i].repoblacion.length; j++) {
                  item = [
                    { text: repoblacion[i].repoblacion[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: repoblacion[i].repoblacion[j].tcMetodoSiembra.metodoSiembraDesc, style: 'small', alignment: 'left' },
                    { text: repoblacion[i].repoblacion[j].densidadInicial, style: 'small', alignment: 'right' },
                    { text: repoblacion[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc, style: 'small', alignment: 'left' }
                  ];
                  filas.push(item);
                  itemTotal.total += parseInt(repoblacion[i].repoblacion[j].densidadInicial);
                }
                var area = repoblacion[i].area;
                if (data.tcTipoGestion.tipoGestionId == 5) {
                  tablaFila = {
                    style: 'estiloTablaEstrecha',
                    table: {
                      headerRows: 0,
                      widths: [95, 85, 55, 100],
                      body: filas
                    }, layout: 'noBorders', colSpan: 4
                  }
                  area = repoblacion[i].area;
                  item = [
                    { text: repoblacion[i].turno, style: 'small', alignment: 'left' },
                    { text: repoblacion[i].correlativo, style: 'small', alignment: 'left' },
                    { text: parseFloat(area).toFixed(4), style: 'small', alignment: 'right' },
                    tablaFila
                  ];
                } else {
                  tablaFila = {
                    style: 'estiloTablaEstrecha',
                    table: {
                      headerRows: 0,
                      widths: [80, 85, 43, 100],
                      body: filas
                    }, layout: 'noBorders', colSpan: 4
                  }
                  item = [
                    { text: repoblacion[i].turno, style: 'small', alignment: 'left' },
                    { text: repoblacion[i].correlativo, style: 'small', alignment: 'left' },
                    { text: parseFloat(area).toFixed(4), style: 'small', alignment: 'right' },
                    { text: repoblacion[i].remanente, style: 'small', alignment: 'right' },
                    tablaFila
                  ];
                  itemTotal.arbolResidual += parseInt(repoblacion[i].remanente);
                }
                body.push(item);
                itemTotal.area += parseFloat(area);
              }
            }
            if (data.tcTipoGestion.tipoGestionId == 5) {
              item = [
                { text: 'Totales', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              dataPlanificacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [40, 40, 40, 95, 80, 60, 120],
                  body: body
                }
              }
            } else {
              item = [
                { text: 'Totales', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
              ];
              body.push(item);
              dataPlanificacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 2,
                  widths: [40, 40, 40, 40, 75, 80, 50, 100],
                  body: body
                }
              }
            }
          }
          /*Actividades para el establecimiento y manejo del compromiso*/
          var tbEstablecimiento = {};
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            body = [
              [
                { text: 'Actividades para el establecimiento y manejo del compromiso', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
              ],
              [
                { text: data.ttPlanificacionGestion.establecimiento, style: 'tableSubHeader', alignment: 'left' }
              ]
            ];
            tbEstablecimiento = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [530],
                body: body
              }
            }
          }
          /*XII. medidas de prevencion contra incendios forestales*/
          var incendios = {};
          var bndIncendio = false;
          if (data.tcTipoGestion.tipoGestionId == 5) {
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              bndIncendio = true;
            }
          } else {
            bndIncendio = true;
          }
          if (bndIncendio) {
            body = [
              [
                { text: 'IX. Medidas de prevención contra incendios forestales y plagas forestales', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }
              ]
            ];
            body.push([
              { text: data.ttProteccionGestion.justificacion, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Líneas de control y rondas cortafuegos', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.cortafuego, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Vigilancia (puestos de control y recorridos por el área)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.vigilancia, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Manejo de combustibles', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.combustible, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Identificación de áreas críticas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.areaCritica, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Respuesta en caso de incendios forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.respuestaIf, style: 'detalle', alignment: 'left' }
            ]);
            if (data.area > 45) {
              body.push([
                { text: 'Áreas mayores a 45 hectáreas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
              ]);
              body.push([
                { text: data.ttProteccionGestion.rutaEscape, style: 'detalle', alignment: 'left' }
              ]);
            }
            body.push([
              { text: 'Medidas de prevención contra plagas forestales (describir brevemente)', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center' }
            ]);
            body.push([
              { text: 'Monitoreo para detección temprana de plagas y enfermedades forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.justificacionPf, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Control de plagas y enfermedades forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.monitoreoPlaga, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Programa Sanitario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.controlPlaga, style: 'detalle', alignment: 'left' }
            ]);
            body.push([
              { text: 'Monitoreos mensuales (describir actividades)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.justificacion, style: 'detalle', alignment: 'left' }
            ]);
            incendios = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [525],
                body: body
              }
            }
          }
          var content = [
            entidad, solicitante, notificacion, finca, pieElaborador, pieFirma,
            biofisica, resumen, tablaEspecieClase, superficie, proteccion, informacionInventario, resumenInventario,
            copiaEspecieClase, tbLatizal, tbBrinzal, regresion, inventarioRodal, analisisEstadistico, analisisDescriptivo,
            resumenNoMaderable, resumenGeneral, propuestaManejo, distribucionDiametrica, silvicultura, '\n', planCorta, planificacionNoMaderable, '\n', otrasPlanificacion, '\n',
            dataRepoblacion, '\n', dataPlanificacion, tbEstablecimiento, '\n', incendios
          ];
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          /*
          *Cinco años van en una pagina
          */
          var noTurnos = parseInt(data.ttResumenGestion.noTurnos);
          var anios = data.ttResumenGestion.anios;
          var paginasTurnos = parseInt(data.ttResumenGestion.noTurnos) / 5;
          paginasTurnos = Math.ceil(paginasTurnos);
          var paginasAnios = parseInt(data.ttResumenGestion.anios) / 5;
          paginasAnios = Math.ceil(paginasAnios);
          var addPages = 1;
          if (parseInt(noTurnos) > 1) {
            var diferencia = parseInt(data.ttResumenGestion.anios) - 5;
            if (diferencia <= 0) {
              addPages = parseInt(addPages) + parseInt(paginasTurnos);
            } else {
              if (parseInt(noTurnos) > parseInt(diferencia)) {
                addPages = parseInt(addPages) + parseInt(paginasTurnos);
              } else {
                addPages = parseInt(addPages) + parseInt(paginasAnios);
              }
            }
          } else {
            addPages = parseInt(paginasAnios);
          }
          addPages = addPages * 2;
          var watermark = {};
          if (bndEstaMal) {
            watermark = {
              text: mensajeMal, color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 60, 40, 20],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            header: function (currentPage, pageCount) {

              lastPage = pageCount;
              pageCount = pageCount + addPages;

              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 380,
                      text: '\nINSTITUTO NACIONAL DE BOSQUES\nPLAN DE MANEJO FORESTAL PARA ' + data.tcTipoGestion.tipoGestionDesc.toUpperCase() + '\nBOSQUE ' + data.tcTipoBosque.tipoBosqueDesc.toUpperCase(),
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                bold: true,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCronograma: function (dataEmpresa, data, startPage, startWith, totalPages) {
          var nombreSalida = 'cronograma.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var textoPago = "";
          var content = [];
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          if (startWith == undefined) {
            startWith = null;
          }
          if (startWith == null) {
            body = [
              [
                { text: 'XIII. TIEMPO DE EJECUCIÓN', style: 'detalle', color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }
              ]
            ];
          } else {
            body = [
              [
                { text: 'Cronograma de actividades POA, primer turno', style: 'detalle', color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }
              ]
            ];
          }
          var objTitulo = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [750],
              body: body
            }
          }
          content.push(objTitulo);
          const yearsPerPage = 5;
          var totalYears = data.cronograma.length;
          var paginas = 0;
          if (startWith == null) {
            var inferior = 0;
            while (inferior < totalYears) {
              paginas++;
              inferior += 5;
            }
          }
          if (paginas <= 0) {
            paginas = 1;
          }
          if (totalPages != undefined) {
            if (totalPages != null) {
              paginas = totalPages;
            }
          }
          var entidad = {};
          var i = 0, j = 0, k = 0;
          var item = {};
          var anio = [];
          var start = 0, stop = 0;
          var fila = [];
          var dataCronograma = data.cronograma;
          var objCronograma = {};
          var tmpData = [];
          var tmpYear = null;
          for (l = 0; l < paginas; l++) {
            entidad = {};
            i = 0;
            j = 0;
            anio = [];
            //construir de forma dinámica el titúlo del cronograma
            start = yearsPerPage * l;
            stop = yearsPerPage * (l + 1);
            if (startWith != null) {
              start = start + startWith;
              stop = stop + startWith;
            }
            anio.push(
              { text: 'Años de actividad', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            );
            for (i = start; i < stop; i++) {
              if (i < totalYears) {
                anio.push(
                  { text: 'Año ' + (i + 1), style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', bold: true, colSpan: 12 }
                );
              } else {
                anio.push(
                  { text: ' ----- ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 12 }
                );
              }
              //resto de columnas para los menses
              for (j = 0; j < 11; j++) {
                anio.push({});
              }
            }
            body = [
              [
                { text: 'Cronograma de Actividades ', style: 'detalle', colSpan: 61, fillColor: '#999999', alignment: 'center' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
                {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
              ]
            ];
            body.push(anio);
            fila = [];
            item = { text: 'MESES', style: 'small', alignment: 'center' };
            fila.push(item);
            for (i = 0; i < yearsPerPage; i++) {
              for (j = 0; j < 12; j++) {
                item = { text: j + 1, style: 'small', alignment: 'center' };
                fila.push(item);
              }
            }
            body.push(fila);
            var row = {};
            var actividad = null;
            var totalActivities = dataCronograma[0].actividad.length;
            var tmpDataYear = [];
            for (k = 0; k < yearsPerPage; k++) {
              if ((start + k) < totalYears) {
                tmpDataYear.push(
                  {
                    year: k,
                    data: dataCronograma[start + k].actividad
                  }
                );
              } else {
                tmpDataYear.push(
                  {
                    year: k,
                    data: null
                  }
                );
              }
            }
            for (k = 0; k < totalActivities; k++) {
              fila = [];
              //imprimir el titilo en la primera columna
              item = { text: tmpDataYear[0].data[k].actividadDesc, style: 'small', fillColor: '#CCCCCC', alignment: 'left' };
              fila.push(item);
              //validar columnas para cada anio
              for (i = 0; i < yearsPerPage; i++) {
                tmpYear = tmpDataYear[i].data;
                if (tmpYear == null) {
                  //pintar con otro color los doce meses
                  for (j = 0; j < 12; j++) {
                    item = { text: ".", style: 'small', color: '#999999', fillColor: '#999999' };
                    fila.push(item);
                  }
                } else {
                  for (j = 0; j < tmpYear[k].mes.length; j++) {
                    row = tmpYear[k].mes[j];
                    if (row.mostrar == false) {
                      item = { text: ".", style: 'small', color: '#CCCCCC', fillColor: '#CCCCCC', alignment: 'left' };
                    } else {
                      if (row.activo == 1) {
                        item = { text: ".", style: 'small', color: '#000000', fillColor: '#000000', alignment: 'left' };
                      } else {
                        item = { text: "", style: 'small', alignment: 'left' };
                      }
                    }
                    fila.push(item);
                  }
                }
              }
              body.push(fila);
            }
            objCronograma = null;
            if (l > 0) {
              objCronograma = {
                style: 'estiloTabla',
                pageBreak: 'before',
                table: {
                  headerRows: 3,
                  widths: [90, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                  body: body
                }
              }
            } else {
              objCronograma = {
                style: 'estiloTabla',
                table: {
                  headerRows: 3,
                  widths: [90, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                  body: body
                }
              }
            }
            content.push(objCronograma);
          }
          body = [
            [
              { text: 'Observaciones generales', style: 'detalle', fillColor: '#999999', alignment: 'left' }
            ],
            [
              { text: data.observacionesCronograma, style: 'detalle', alignment: 'left' }
            ]
          ];
          var objObservaciones = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [750],
              body: body
            }
          }
          content.push(objObservaciones);
          body = [
            [
              { text: 'Elaborador del Plan de Manejo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.personaDesc, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Registro ante el INAB', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.rfn, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Firma', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: '\n\n', style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var pieElaborador = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          content.push(pieElaborador);
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [10, 60, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            header: function (currentPage, pageCount) {

              currentPage = currentPage + startPage;
              pageCount = pageCount + startPage;

              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 580,
                      text: "\nINSTITUTO NACIONAL DE BOSQUES\nPLAN DE MANEJO FORESTAL CON FINES DE PRODUCCIÓN\nBOSQUE DE CONÍFERAS Y MIXTOS ",
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 7,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                bold: true,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black'
              },
              detalle: {
                fontSize: 7
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudLicencia: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'solicitud.pdf';
          var fecha = new Date();
          var representante = "";
          var textoPropietario = "";
          var i, j;
          var textoFinca = "", textoDireccion = "";
          var plural = false;
          if (data.fechaCierre == null) {
            data.fechaCierre = new Date();
          }
          var body = [];
          var tbSolicitante = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          var titulos = {
            areaSolicitada: 'Área solicitada para aprovechar (ha)'
          }
          switch (parseInt(data.tcTipoGestion.tipoGestionId)) {
            case 1:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
            case 2:
              titulos.areaSolicitada = 'Área intervenir por saneamiento (ha)';
            case 3:
              titulos.areaSolicitada = 'Área intervenir por salvamento (ha)';
              break;
            case 4:
              titulos.areaSolicitada = 'Área intervenir con fines científicos (ha)';
              break;
            case 5:
              titulos.areaSolicitada = 'Área intervenir por cambio de uso (ha)';
              break;
            case 7:
              titulos.areaSolicitada = 'Área intervenir con árboles fuera de bosque (ha)';
              break;
            default:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'II. DATOS GENERALES DEL PROPIETARIO/POSEEDOR:', style: 'tableSubHeader', colSpan: 4, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}
              ],
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadId == 1 ? data.ttTipoPropietarioGestion.nombreComercial : 'N/A', style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            tbSolicitante = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
          } else {
            body = [
              [
                { text: 'II. DATOS GENERALES DEL PROPIETARIO/POSEEDOR:', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'left' }, {}, {}
              ],
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Número de Identificación Tributaria (NIT)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var dataPersona = data.personas;
            for (i = 0; i < dataPersona.length; i++) {
              if (dataPersona[i].tcPersona.cui.toString() == dataPersona[i].tcPersona.nit) {
                dataPersona[i].tcPersona.nit = '-';
              }
              item = [
                { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
                { text: dataPersona[i].tcPersona.nit, style: 'detalle', alignment: 'left' }
              ];
              body.push(item);
            }
            tbSolicitante = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [210, 150, 150],
                body: body
              }
            }
          }
          var tbRepresentante = {};
          var hayRepresentante = false;
          if (data.representantes.length > 0) {
            body = [
              [
                { text: 'III. DATOS DE REPRESENTACIÓN LEGAL:', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {}
              ],
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código único de Identificación -CUI-', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            for (var i = 0; i < data.representantes.length; i++) {
              item = [
                { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left' }
              ];
              body.push(item);
            }
            tbRepresentante = {
              style: 'estiloTabla',
              table: {
                widths: [260, 250],
                body: body
              }
            }
            hayRepresentante = true;
          }
          var roman = 'III';
          if (hayRepresentante) {
            roman = 'IV';
          }
          body = [
            [
              { text: roman + '. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {},
            ],
            [
              { text: 'Tipo de notificación:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Descripción:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]
          ];
          var lista = [];
          var i, j;
          var item = [];
          var encontrado = false;
          for (i = 0; i < data.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(data.notificaciones[i].tcTipoNotificacion);
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(data.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 365],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'I. DATOS GENERALES DEL TERRENO O LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'left' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          var nombreFinca = '';
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#4D4A4C', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (i == 0) {
              nombreFinca = finca.tcFinca.fincaDesc;
            } else {
              nombreFinca += ', ' + finca.tcFinca.fincaDesc;
            }
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                propietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            var cantidad = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' },
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' },
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. De Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. De Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. De Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. De Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. De Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
            }
            body.push(item);
            item = [
              { text: 'Colindancias', style: 'tableSubHeader', colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Norte', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.norte, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Sur', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.sur, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Este', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.este, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Oeste', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.oeste, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Área total (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.areaDocumento).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total (Según GPS)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.area).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var tbFinca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          var representanteNombre = "";
          var row = null;
          var edad = null;
          var textoNotificacion = "EXPONGO";
          var textoExpongo = "";
          var textoComparecer = '; comparezco ante usted y ';
          if (data.representantes.length > 0) {
            if (data.representantes.length > 1) {
              textoNotificacion = ". Señalamos";
              textoExpongo = "EXPONEMOS";
              textoComparecer = '; comparecemos ante usted y ';
            } else {
              textoNotificacion = ". Señalo";
              textoExpongo = "EXPONGO";
            }
          } else {
            if (data.personas.length > 1) {
              textoNotificacion = ". Señalamos";
              textoExpongo = "EXPONEMOS";
              textoComparecer = '; comparecemos ante usted y ';
            } else {
              textoNotificacion = ". Señalo";
              textoExpongo = "EXPONGO";
            }
          }
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            //solo domicilio y oficina
            //si se agrega otro de tipo ubicacion geografica se debe agregar a la condicion
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              if (i == 0) {
                textoNotificacion += " lugar para recibir notificaciones en: " + row.notificacionGestionDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc;
              } else {
                textoNotificacion += ". " + row.notificacionGestionDesc + " municipio de " + row.tcMunicipio.municipioDesc + ", departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc;
              }
            }
          }
          var solicito = 'Solicito';
          var itemsPeticion = [
            { text: "Que se admita para su trámite el presente memorial, los documentos adjuntos y se inicie la formación del expediente administrativo respectivo.", style: 'izquierda' },
            { text: "Que se tenga como lugar para recibir citaciones y  notificaciones en el señalado.", style: 'izquierda' },
            { text: "Que satisfechos los requisitos de ley y técnicos, se apruebe el Plan de Manejo Forestal y se otorgue " + data.tcTipoGestion.tipoGestionDesc + " y Manejo, en finca cuyos derechos son acreditados con los documentos indicados.", style: 'izquierda' }
          ];

          if (data.representantes.length <= 0) {
            if (data.personas.length > 1) {
              solicito = 'Solicitamos';
            }
          }
          if (hayRepresentante) {
            roman = 'V';
          } else {
            roman = 'IV';
          }
          body = [
            [
              { text: roman + '. DETALLES DE INTERVENCION SOLICITADA', style: 'tableSubHeader', colSpan: 4, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {}, {}, {}
            ],
            [
              { text: 'Área total de la finca (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'izquierda' },
              { text: parseFloat(areaFinca).toFixed(4), style: 'tableSubHeader', alignment: 'izquierda' },
              { text: titulos.areaSolicitada, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'izquierda' },
              { text: parseFloat(data.area).toFixed(4), style: 'tableSubHeader', alignment: 'izquierda' }
            ]
          ];
          tbDivision = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [140, 100, 160, 100],
              body: body
            }
          }

          var tcFinca = data.fincas[0].tcFinca;

          var content = [
            {
              text: 'SOLICITUD DE LICENCIA DE APROVECHAMIENTO FORESTAL',
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: [
                'Tipo de licencia solicitada: ',
                {
                  text: data.tcTipoGestion.tipoGestionDesc,
                  style: 'izquierda',
                  bold: true
                }
              ],
              style: 'izquierda'
            }, '\n',
            {
              text: 'Fecha: ' + fecha.getDate() + ' de ' + getMonth(fecha.getMonth() + 1) + " de " + fecha.getFullYear(),
              style: 'derecha'
            }, '\n',
            {
              text: "Señor(a) Director Subregional " + data.tcSubregion.tcSubregional.personaDesc + " del Instituto Nacional de Bosques, con sede en el municipio de "
                + data.tcSubregion.tcMunicipio.municipioDesc + ", departamento de " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
                + '. Por este medio solicito ' + data.tcTipoGestion.tipoGestionDesc.toUpperCase()
                + ', para ejecutar en finca denominada ' + nombreFinca + ' ubicada en ' + tcFinca.direccion + ', municipio de ' + tcFinca.tcMunicipio.municipioDesc
                + ', departamento de ' + tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + '. Dicha petición se realiza de conformidad con la información que se especifican a continuación: ',
              style: 'izquierda'
            }, '\n', tbFinca, '\n', tbSolicitante, '\n', tbRepresentante, '\n', tbNotificacion, '\n', tbDivision,
            '\n',
            {
              text: (hayRepresentante ? 'VI' : 'V') + ". FUNDAMENTO DE DERECHO",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Constitución Política de la República de Guatemala',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Artículo 28. Derecho de Petición. "Los habitantes de la República de Guatemala tienen derecho a dirigir, individual o colectivamente, peticiones a la autoridad, la que está obligada a tramitarlas y deberá resolverlas conforme a la ley…"',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Decreto 101-96 Ley Forestal',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Artículo 48. Aprovechamientos y manejo sostenido del bosque. "El aprovechamiento y manejo sostenido del bosque estará dirigido mediante el Plan de Manejo aprobado por el INAB. Este es un instrumento fundamental en el monitoreo del aprovechamiento y de las técnicas silviculturales aplicadas a la masa forestal, comprenderá como mínimo:.."',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Artículo 49. Licencia. "La licencia será la autorización para implementar el Plan de Manejo…"',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Artículo 50. Solicitud de licencia. "La solicitud de aprovechamiento forestal se ajustará en lo que fuere aplicable a las disposiciones del Código Procesal Civil y Mercantil pero no será admitida si no va acompañada del Plan de Manejo y si no cumple con los requisitos técnicos que determine el reglamento…"',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: (hayRepresentante ? 'VII' : 'VI') + ". PETICIÓN ESPECÍFICA",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: '1.',
                  style: 'izquierda',
                  width: 20
                },
                {
                  text: 'Que se admita para su trámite el presente memorial, los documentos adjuntos y se inicie la formación del expediente administrativo respectivo',

                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: '2.',
                  style: 'izquierda',
                  width: 20
                },
                {
                  text: 'Que se tenga como lugar para recibir citaciones y notificaciones el señalado.',
                  style: 'izquierda'
                }
              ]
            }
          ];
          var n = '4';
          if (data.ttPlanificacionGestion.hayReforestacion == 1 && data.tcTipoGestion.tipoGestionId != 2 && data.tcTipoGestion.tipoGestionId != 3) {
            content.push(
              {
                columns: [
                  {
                    text: '',
                    width: 20
                  },
                  {
                    text: '3.',
                    style: 'izquierda',
                    width: 20
                  },
                  {
                    text: solicito + ' garantizar el cumplimiento de la obligación de repoblación forestal mediante: ' + data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc,
                    style: 'izquierda'
                  }
                ]
              }
            );
          } else {
            n = '3';
          }
          content.push(
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: n + '.',
                  style: 'izquierda',
                  width: 20
                },
                {
                  text: 'Que satisfechos los requisitos técnicos y legales, se apruebe el Plan de Manejo Forestal y se otorgue Licencia de Aprovechamiento Forestal en finca cuyos derechos son acreditados con los documentos indicados',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push('\n\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small',
              alignment: 'right'
            }
          );
          var textoFooter = 'Codigo: LI-RE-001\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              detalle: {
                fontSize: 10
              },
              tableSubHeader: {
                fontSize: 9,
                bold: true
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionCompletacion: function (data, datos, subregionDesc, nug, personaSolicita, personaDesc, dataEmpresa, esPoa) {
          var nombreSalida = 'notificacion.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var lista = [];
          for (var i = 0; i < data.length; i++) {
            if (data[i].marcado == 1) {
              lista.push({ text: data[i].requisitoDesc, style: 'izquierda' });
            }
          }
          var tramite = "el trámite en la gestión de una licencia forestal, siendo necesario completar los siguientes requisitos:";
          if (esPoa) {
            tramite = "para solicitar aprobación de Plan Operativo Anual de Licencia de Aprovechamiento Forestal No. " + datos.codigo + ", siendo necesario completar los siguientes requisitos para la admisión de documentación y continuar con el tramite:";
            subregionDesc = datos.ttSolicitud.ttGestion.tcMunicipio.municipioDesc + ", " + datos.ttSolicitud.ttGestion.tcMunicipio.tcDepartamento.departamentoDesc
              + ", " + fecha.getDate() + ' de ' + getMonth(mes) + ' de ' + fecha.getFullYear();
          } else {
            subregionDesc = "Dirección Subregional " + subregionDesc + ". " + fecha.getDate() + ' de ' + getMonth(mes) + ' de ' + fecha.getFullYear();
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "NOTIFICACIÓN  DE COMPLETACIÓN DE REQUISITOS EN GESTIÓN NUG: " + nug,
              style: 'centrar'
            }, '\n',
            {
              text: subregionDesc,
              style: 'derecha'
            },
            '\n', '\n',
            {
              text: "Por este medio, se hace constar que día de hoy, " + personaSolicita + "  presentó documentación para " + tramite,
              style: 'izquierda'
            }, '\n',
            {
              ol: [
                {
                  ol: lista
                }
              ]
            },
            '\n',
            {
              text: "Se le informa que al no presentar los documentos antes descritos, no se aceptará para su trámite la gestión solicitada. Así mismo, la gestión iniciada en el sistema quedara anulada después de 30 días hábiles.",
              style: 'izquierda'
            }, '\n', '\n', '\n', '\n',
            {
              text: "F _________________________________",
              style: 'centrar'
            },
            {
              text: personaDesc,
              style: 'centrar'
            },
            {
              text: "Secretaria (o)",
              style: 'centrar'
            }, '\n\n',
            {
              text: "Nombre: _________________________________",
              style: 'derecha'
            }, '\n\n',
            {
              text: "Firma: __________________________________",
              style: 'derecha'
            }, '\n\n',
            {
              text: "cc, Interasado",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nFecha y hora de impresión: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-002\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarConstanciaRecepcion: function (solicitud, subregionDesc, usuarioDesc, dataEmpresa) {
          var data = solicitud.ttGestion;
          var nombreSalida = 'constancia.pdf';
          var fechaRecepcion = new Date(solicitud.fechaRecepcion);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          var solicitanteNombre = "";
          var i, j;
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                solicitanteNombre = data.personas[i].tcPersona.personaDesc;
              } else {
                solicitanteNombre += '\n' + data.personas[i].tcPersona.personaDesc;
              }
            }
          }
          body = [
            [
              { text: 'I. DATOS GENERALES DEL EXPEDIENTE', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ],
            [
              { text: 'Lugar y Fecha', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.tcSubregion.tcMunicipio.municipioDesc + ', ' + fechaRecepcion.getDate() + ' de ' + getMonth(mes) + ' de ' + fechaRecepcion.getFullYear(), style: 'izquierda' }
            ],
            [
              { text: 'No. De Expediente', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.expediente, style: 'izquierda' }
            ],
            [
              { text: 'Número de Folios entregados (inclusive)', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: solicitud.folios, style: 'izquierda' }
            ],
            [
              { text: 'Nombre (s) del (os) solicitante (s):', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: solicitanteNombre, style: 'izquierda' }
            ]
          ];
          solicitanteNombre = "";
          if (data.representantes.length > 0) {
            var representante = "";
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representante = data.representantes[i].tcPersona.personaDesc;
              } else {
                representante += '\n' + data.representantes[i].tcPersona.personaDesc;
              }
            }
            if (data.representantes.length > 1) {
              body.push(
                [
                  { text: 'Representantes legales', style: 'izquierda', fillColor: '#CCCCCC' },
                  { text: representante, style: 'izquierda' }
                ]
              );
            } else {
              body.push(
                [
                  { text: 'Nombre del representante legal:', style: 'izquierda', fillColor: '#CCCCCC' },
                  { text: representante, style: 'izquierda' }
                ]
              );
            }
          }
          body.push(
            [
              { text: 'Datos de notificación', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000', colSpan: 2 }, {}
            ]
          );
          //obtener los tipos de notificacion
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < data.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: data.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(data.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [190, 310],
              body: body
            }
          }
          //tabla codigos
          body = [
            [
              { text: '1', style: 'centrar', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'APROVECHAMIENTO FORESTAL', style: 'izquierda', colSpan: 3, color: '#FFFFFF', fillColor: '#000000' }, {}, {}
            ],
            [
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción de la gestión', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción específica de la gestión', style: 'izquierda', fillColor: '#CCCCCC' }
            ],
            [
              { text: data.tcTipoGestion.tcModulo.codigo, style: 'centrar' },
              { text: data.tcTipoGestion.tcModulo.moduloDesc, style: 'izquierda' },
              { text: data.tcTipoGestion.codigo, style: 'izquierda' },
              { text: data.tcTipoGestion.tipoGestionDesc, style: 'izquierda' }
            ]
          ];
          var tablaCodigos = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 200, 50, 200],
              body: body
            }
          }
          //tabla documentos recibidos
          body = [
            [
              { text: 'III. DOCUMENTOS RECIBIDOS.', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ]
          ];
          for (var i = 0; i < solicitud.requisito.length; i++) {
            item = [
              { text: i + 1, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: solicitud.requisito[i].requisitoDesc, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaRequisito = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 480],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 60,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\nDIRECCIÓN SUBREGIONAL " + subregionDesc,
                  style: 'centrar'
                }
              ]
            },
            {
              text: "CONSTANCIA DE RECEPCIÓN DE EXPEDIENTE No. " + data.expediente,
              style: 'centrar'
            }, '\n',
            {
              text: "NUG: " + data.nug,
              style: 'derecha'
            },
            tablaGenerales, '\n', tablaCodigos, '\n', tablaRequisito, '\n',
            {
              text: "El solicitante debe considerar que la presente Constancia de Recepción de Expediente, no constituye aprobación alguna de la gestión solicitada; por lo que, el expediente administrativo entra a las fases de análisis e inspección de campo para luego emitir la resolución que en derecho corresponda, la cual deberá ser notificada en el momento oportuno.",
              style: 'izquierda'
            }, '\n', '\n', '\n',
            {
              columns: [
                {
                  width: 250,
                  text: 'F _________________________________\n' + solicitud.tcUsuarioRecibe.personaDesc + '\nSecretaria(o) I Subregional',
                  style: 'centrar'
                },
                {
                  width: 250,
                  text: 'F _________________________________\n' + solicitud.personaEntregaDesc + '\nPersona que Entrega el expediente',
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "cc, Interasado",
              style: 'small', alignment: 'left'
            },
            {
              text: 'Impreso por: ' + usuarioDesc + '\t' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-003\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  width: 250,
                  fontSize: 7
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                bold: true,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCaratulaRecepcion: function (solicitud, subregionDesc, usuarioDesc, dataEmpresa) {
          var data = solicitud.ttGestion;
          var nombreSalida = 'caratula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var item = {
            text: "INSTITUTO NACIONAL DE BOSQUES\nDIRECCIÓN SUBREGIONAL " + subregionDesc,
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "No. Expediente:",
            style: 'sencillo'
          };
          content.push(item);
          item = {
            text: data.expediente,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Tipo de Solicitud:",
            style: 'sencillo'
          };
          content.push(item);
          item = {
            text: data.tcTipoGestion.tipoGestionDesc,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Localización de la finca:",
            style: 'sencillo'
          };
          content.push(item);
          var finca = data.fincas[0].tcFinca;
          item = {
            text: finca.tcMunicipio.municipioDesc + ", " + finca.tcMunicipio.tcDepartamento.departamentoDesc,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Nombre(s) del(os) solicitante(s):",
            style: 'sencillo'
          };
          content.push(item);
          var solicitanteNombre = "";
          var i, j;
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                solicitanteNombre = data.personas[i].tcPersona.personaDesc;
              } else {
                solicitanteNombre += '\n' + data.personas[i].tcPersona.personaDesc;
              }
            }
          }
          item = {
            text: solicitanteNombre,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: 'Datos para notificación',
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          //obtener los tipos de notificacion
          var body = [];
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < data.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: data.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(data.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'sencillo', alignment: 'left' },
              { text: strDescripcion, style: 'negrilla', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 450],
              body: body
            }, layout: 'noBorders'
          };
          content.push(tablaGenerales);
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 13,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 14,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarResolucionAdmision: function (data, diaDesc, anioDesc, strDiaAdmision, strAnioAdmision, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var nombreSalida = 'adminsion.pdf';
          var fechaRecepcion = new Date(data.fechaRecepcion);
          var fechaAdmision = new Date(data.ttSeguimientoTarea.fechaAdmision);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          var mesAdmision = parseInt(fechaAdmision.getMonth()) + 1;
          var representante = "";
          var textoDocumento = "posesión";

          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.ttGestion.fincas.length; i++) {
            finca = data.ttGestion.fincas[i];
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ", acreditado mediante, ";
              textoDireccion = textoDireccion + ". " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca = "Finca " + finca.tcFinca.fincaDesc + " ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de "
                + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "; acreditado mediante, ";
              textoDireccion = finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + " de derechos posesorios número " + finca.numeroDocumento + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                break;
              case 2:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + " de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de "
                  + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              case 3:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " del  departamento de " + finca.tcLibro.libroDesc + " para lo cual presentó Plan de Manejo Forestal elaborado por el Elaborador de Planes de Manejo Forestal "
                  + data.ttGestion.tcElaborador.personaDesc + " inscrito en el Registro Nacional Forestal con  No. " + data.ttGestion.tcElaborador.rfn;
                break;
              case 4:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + " de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de "
                  + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          if (data.ttGestion.representantes.length > 0) {
            for (i = 0; i < data.ttGestion.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.ttGestion.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- " + data.ttGestion.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.ttGestion.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- " + data.ttGestion.representantes[i].tcPersona.cui;
              }
            }
          }
          var personas = data.ttGestion.personas;
          var nombres = '', soloNombres = '';
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            soloNombres = data.ttGestion.ttTipoPropietarioGestion.razonSocial;
            representante = "Se tiene a la vista para resolver la solicitud de admisión del expediente administrativo que contiene solicitud de "
              + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", presentado por " + soloNombres + "; representado legalmente por " + representanteNombre
              + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            if (data.ttGestion.representantes.length > 0) {
              for (i = 0; i < personas.length; i++) {
                if (i == 0) {
                  nombres = personas[i].tcPersona.personaDesc;
                } else {
                  nombres += ', ' + personas[i].tcPersona.personaDesc;
                }
              }
              soloNombres = nombres;
              representante = "Se tiene a la vista para resolver la solicitud de admisión del expediente administrativo que contiene solicitud de "
                + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", presentado por " + nombres + "; representado legalmente por " + representanteNombre
                + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
            } else {
              for (i = 0; i < personas.length; i++) {
                var persona = personas[i];
                if (i == 0) {
                  soloNombres = persona.tcPersona.personaDesc;
                  nombres = persona.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- " + persona.tcPersona.cui;
                } else {
                  soloNombres += ', ' + persona.tcPersona.personaDesc;
                  nombres += ', ' + persona.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- " + persona.tcPersona.cui;
                }
              }
              representante = "Se tiene a la vista para resolver la solicitud de admisión del expediente administrativo que contiene solicitud de "
                + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", presentado por " + nombres + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
            }
          }
          var planTexto = 'Plan de Manejo Forestal';
          if (data.ttGestion.tcTipoGestion.tipoGestionId == 5) {
            planTexto = 'Plan de aprovechamiento';
          }
          representante = representante + ". Para ejecutar el " + planTexto + " en el inmueble de su  " + textoDocumento + ": " + textoFinca;

          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN DE ADMISIÓN PARA TRÁMITE DE EXPEDIENTE ADMINISTRATIVO",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución No. " + data.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: "No. Expediente: " + data.ttGestion.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.ttGestion.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDiaAdmision + " DIAS DEL MES DE "
                + getMonth(mesAdmision).toUpperCase() + " DE " + strAnioAdmision,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que el Decreto No.101-96 del Congreso de la República de Guatemala, Ley Forestal, establece que el Instituto Nacional de Bosques –INAB-, es el órgano de dirección  y autoridad del Sector Publico Agrícola en materia forestal y que como tal es el único que puede otorgar, denegar, supervisar, prorrogar y cancelar Licencias Forestales que será la autorización para implementar el Plan de Manejo Forestal y que cualquier aprovechamiento forestal de madera u otros productos leñosos, excepto los de consumo familiar, los de plantaciones voluntarias y sistemas agroforestales plantados voluntariamente, podrá hacerse solamente mediante  Licencia  Forestal que el Instituto Nacional de Bosques, otorgará exclusivamente al propietario o poseedor legitimo del terreno o del área forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que la Resolución número JD.01.43.2005, de Junta Directiva del Instituto Nacional de Bosques,  Reglamento de la Ley Forestal,  preceptúa que, los expedientes de solicitud de Licencia serán presentados en las oficinas Subregionales del INAB, incluyendo la documentación siguiente: a) Solicitud que contenga como mínimo las generales del propietario del terreno, lugar, para recibir notificaciones, la solicitud en términos concretos y la firma debidamente autenticada; b) Certificación del Registro de la propiedad que acredite la propiedad del bien, indicando las anotaciones y gravámenes que contienen. En caso que la propiedad no esté inscrita en el Registro, se podrá aceptar, otro documento legal que acredite la propiedad; y, c) Plan de Manejo Forestal…",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que " + soloNombres + ", en fecha " + diaDesc.toLowerCase() + " dias del mes de " + getMonth(mes) + " de " + anioDesc.toLowerCase()
                + ", gestionó ante el Instituto Nacional de Bosques, solicitud aprobación de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + " en " + textoDireccion + ";  quien ha cumplido con los requisitos establecidos en la legislación forestal vigente.",
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: "Con base  a lo considerado y en lo preceptuado por los artículos  28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y  6 del Decreto No. 119-96 del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo; 1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 40, 41 Resolución de Junta Directiva del Instituto Nacional de Bosques JD.01.43.2005, Reglamento de la Ley Forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Admitir para su trámite la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + " realizada por " + soloNombres + ", en virtud de cumplir con los requisitos establecidos en la legislación forestal vigente.", style: 'izquierda' },
                    { text: "Trasládese el presente expediente administrativo, para su respectivo análisis legal y técnico de la documentación correspondiente.", style: 'izquierda' },
                    { text: "Notifíquese.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.ttGestion.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-066\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacion: function (data, usuarioDesc, dataEmpresa, esLicencia) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var strCorreo = data.ttGestion.tcElaborador.correo;
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttGestion.ttTipoPropietarioGestion.razonSocial;
            for (i = 0; i < data.ttGestion.representantes.length; i++) {
              item = data.ttGestion.representantes[i];
              strCorreo += "; " + item.tcPersona.correo;
            }
          } else {
            for (i = 0; i < data.ttGestion.personas.length; i++) {
              item = data.ttGestion.personas[i];
              if (i == 0) {
                solicitante = item.tcPersona.personaDesc;
              } else {
                solicitante += ", " + item.tcPersona.personaDesc;
              }
              strCorreo += "; " + item.tcPersona.correo;
            }
          }
          for (i = 0; i < data.ttGestion.notificaciones.length; i++) {
            var notificacion = data.ttGestion.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              strCorreo += "; " + notificacion.notificacionGestionDesc;
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "CÉDULA DE NOTIFICACIÓN",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          if (esLicencia) {
            var ttPadre = data.ttPadre;
            item = {
              text: "En el municipio de " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
                + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
                + data.ttGestion.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la " + (ttPadre.aprobado == 1 ? 'Licencia número ' : 'Resolución de Denegación de la Licencia número ') + data.ttPadre.ttSeguimientoTarea.numeroResolucion
                + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
              style: 'sencillo'
            };
          } else {
            if (data.tcTask.taskId == 47) {
              item = {
                text: "En el municipio de " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
                  + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
                  + data.ttGestion.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la Resolución " + data.ttPadre.ttSeguimientoTarea.numeroResolucion
                  + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
                style: 'sencillo'
              };
            } else {
              item = {
                text: "En el municipio de " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
                  + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
                  + data.ttGestion.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la Resolución número " + data.ttSeguimientoTarea.numeroResolucion
                  + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
                style: 'sencillo'
              };
            }
          }
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          if (esLicencia) {
            if (data.ttPadre.ttSeguimientoTarea.numero > 0) {
              content.push('\n\n\n\n');
              content.push(
                {
                  text: "F. _________________________________",
                  style: 'centrar'
                }
              );
              content.push(
                {
                  text: data.ttPadre.ttSeguimientoTarea.analisis,
                  style: 'centrar'
                }
              );
              content.push(
                {
                  text: 'No. Registro ' + data.ttPadre.ttSeguimientoTarea.enmienda,
                  style: 'centrar'
                }
              );
            }
            content.push(
              {
                text: '\n\nImpreso por: ' + usuarioDesc,
                style: 'small', alignment: 'right'
              }
            );
            content.push(
              {
                text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
                style: 'small', alignment: 'right'
              }
            );
            content.push('\n');
            if (data.ttSeguimientoTarea.fechaProvidencia == null) {
              data.ttSeguimientoTarea.fechaProvidencia = new Date();
            }
            fecha = new Date(data.ttSeguimientoTarea.fechaProvidencia);
            mes = parseInt(fecha.getMonth()) + 1;
            content.push(
              {
                text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
                style: 'small', alignment: 'left'
              }
            );
          }
          var textoFooter = 'Codigo: LI-RE-022\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionLicencia: function (tarea, usuarioDesc, dataEmpresa, sigla, esLicencia) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          if (esLicencia == null) {
            esLicencia = false;
          }
          var data = tarea.ttGestion;
          var nombreSalida = 'notificacion.pdf';
          var fecha = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var representante = "";
          var textoPropietario = "";
          var fechaDictamen = new Date(tarea.ttPadre.ttSeguimientoTarea.fechaProvidencia);
          var i, j;
          var textoFinca = "", textoDireccion = "";
          var plural = false;
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            textoDireccion = finca.tcFinca.fincaDesc + ', propiedad establecida mediante ' + finca.tcTipoPropiedad.tipoPropiedadDesc;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoDireccion += " de derechos posesorios número " + finca.numeroDocumento + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                break;
              case 2:
                textoDireccion += " de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              case 3:
                textoDireccion += ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc;
                break;
              case 4:
                textoDireccion += " de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
            textoDireccion += ", ubicada en el lugar denominado " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ". ";
            textoFinca += textoDireccion;
          }
          var textoLicencia = {
            text: "El Plan de Manejo fue autorizado con Licencia Forestal No. " + tarea.ttPadre.ttSeguimientoTarea.numeroResolucion
              + ", de fecha " + fechaDictamen.getDate() + " de " + getMonth(fechaDictamen.getMonth() + 1) + " del año " + fechaDictamen.getFullYear()
              + ", por la Región " + data.tcSubregion.tcRegion.regionDesc + " " + data.tcSubregion.tcMunicipio.municipioDesc
              + " de INAB y para continuar con el trámite para la obtención de la Resolución de la aprobación del Plan Operativo Anual respectivo. (Adjunto copia de la Licencia Forestal), le agradezco firmar y sellar de recibido la presente.",
            style: 'izquierda'
          };
          if (esLicencia) {
            var fechaLicencia = new Date(tarea.ttPadre.ttSeguimientoTarea.fechaAdmision);
            textoLicencia = {
              text: "El Plan de Manejo fue autorizado con Resolución número " + tarea.ttPadre.ttSeguimientoTarea.observaciones
                + ", de fecha " + fechaLicencia.getDate() + " de " + getMonth(fechaLicencia.getMonth() + 1) + " del año " + fechaLicencia.getFullYear()
                + ", por la Región " + data.tcSubregion.tcRegion.regionDesc + " " + data.tcSubregion.tcMunicipio.municipioDesc
                + " de INAB. Así mismo la " + data.tcTipoGestion.tipoGestionDesc + " número " + tarea.ttPadre.ttSeguimientoTarea.numeroResolucion + "; correspondiente al turno número " + tarea.ttPadre.ttSeguimientoTarea.area
                + " otorgada en fecha " + fechaDictamen.getDate() + " de " + getMonth(fechaDictamen.getMonth() + 1) + " del año " + fechaDictamen.getFullYear()
                + ". Le agradezco firmar y sellar de recibido la presente.",
              style: 'izquierda'
            };
          }
          var content = [
            {
              text: "AVISO DE LA LICENCIA A LA MUNICIPALIDAD ",
              style: 'centrar'
            }, '\n\n',
            {
              text: "Of. " + tarea.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fecha.getDate() + " de " + getMonth(fecha.getMonth() + 1) + " de " + fecha.getFullYear(),
              style: 'derecha'
            }, "\n",
            {
              text: 'Señor:',
              style: 'izquierda'
            },
            {
              text: 'Alcalde Municipal',
              style: 'izquierda'
            },
            {
              text: 'Municipalidad de ' + data.fincas[0].tcFinca.tcMunicipio.municipioDesc,
              style: 'izquierda'
            },
            {
              text: 'Su despacho',
              style: 'izquierda'
            }, "\n",
            {
              text: "Señor Alcalde",
              style: 'izquierda'
            }, '\n',
            {
              text: 'De manera y en cumplimiento al artículo 58 del Decreto Legislativo Número 101-96 "Ley Forestal" me dirijo a usted para hacer entrega de copia del Plan de Manejo Forestal aprobado, a ejecutarse en finca: '
                + textoFinca,
              style: 'izquierda'
            }, '\n', textoLicencia, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: "INAB " + data.tcSubregion.tcMunicipio.municipioDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-020\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaTraslado: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (data.personas.length > 1) {
            textoInicio = "presentan";
          }
          var solicitanteNombre = "";
          for (var i = 0; i < data.personas.length; i++) {
            solicitante = data.personas[i];
            if (i == 0) {
              solicitanteNombre = solicitante.tcPersona.personaDesc;
            } else {
              solicitanteNombre = solicitanteNombre + ", " + solicitante.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitanteNombre + ", " + textoInicio + " solicitud de licencia de aprovechamiento forestal para la ejecución del Plan de Manejo Forestal de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    { text: "Pase en forma simultánea el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica  y copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el plan de manejo presentado, realice inspección de campo y emita informe a la mayor brevedad.", style: 'izquierda' },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudAval: function (tarea, turno, usuarioDesc, dataEmpresa, sigla, dataPoa) {
          if (sigla == undefined) {
            sigla = '';
          }
          if (dataPoa == null) {
            dataPoa = {};
          }
          var data = tarea.ttGestion;
          var dataCompromisoTmp = angular.copy(data.rodal);
          var dataCompromiso = [];
          var i, j;
          var tratamiento = [];
          //obtener datos del turno
          if (turno > 1) {
            dataCompromiso = dataCompromisoTmp;
          } else {
            for (i = 0; i < dataCompromisoTmp.length; i++) {
              if (parseInt(dataCompromisoTmp[i].turno) == parseInt(turno)) {
                dataCompromiso.push(dataCompromisoTmp[i]);
              }
            }
          }

          var area = 0;
          var bndOK = false, bndFueraBosque = false;
          var abrTotal = 0, abrExtraerTotal = 0, sumaPorcentaje = 0, totalRodales = dataCompromiso.length;
          for (i = 0; i < dataCompromiso.length; i++) {
            for (j = 0; j < dataCompromiso[i].especies.length; j++) {
              bndOK = false;
              for (k = 0; k < tratamiento.length; k++) {
                if (tratamiento[k].tratamientoSilviculturalId == dataCompromiso[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId) {
                  bndOK = true;
                  break;
                }
              }
              if (!bndOK) {
                tratamiento.push(dataCompromiso[i].especies[j].tcTratamientoSilvicultural);
              }
            }
            var abrExtraer = 0;
            var abr = 0;
            for (j = 0; j < dataCompromiso[i].especies.length; j++) {//especie por rodal
              abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
              abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
            }
            dataCompromiso[i].abr = abr;
            dataCompromiso[i].abrExtraer = abrExtraer;
            value = (dataCompromiso[i].abrExtraer * 100) / dataCompromiso[i].abr;
            value = Math.round(value);
            sumaPorcentaje += value;
            abrTotal += abr;
            abrExtraerTotal += abrExtraer;
            if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3 || dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 2) {
              area += parseFloat(dataCompromiso[i].areaGarantia);
              bndFueraBosque = true;
            } else {
              area += parseFloat(dataCompromiso[i].area);
            }
          }
          area = parseFloat(area).toFixed(4);
          var porcentaje = 0;
          if (totalRodales > 0) {
            porcentaje = sumaPorcentaje / totalRodales;
            porcentaje = parseFloat(porcentaje).toFixed(2);
          }
          porcentaje = Math.round(porcentaje);
          var calculoMonto = 'área basal';
          if (porcentaje > appSettings.extraccionMinima) {
            calculoMonto = 'área por área';
            if (bndFueraBosque) {
              calculoMonto = 'área basal ideal (incluye árboles fuera de bosque)';
            }
          }
          var tratas = '';
          for (i = 0; i < tratamiento.length; i++) {
            if (tratamiento[i].tratamientoSilviculturalId != 6) {
              if (i == 0) {
                tratas = tratamiento[i].tratamientoSilviculturalDesc;
              } else {
                tratas += '\n' + tratamiento[i].tratamientoSilviculturalDesc;
              }
            }
          }
          var nombreSalida = 'aval.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\nSOLICITUD DE AVAL PARA GARANTIA FIDUCIARIA",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio: " + tarea.ttSeguimientoTarea.codigo,
              style: 'derecha'
            }, '\n',
            {
              text: sigla + " " + tarea.tcPersonaMonitoreo.personaDesc,
              style: 'izquierda'
            },
            {
              text: "Monitoreo Forestal y Obligaciones de Repoblación Forestal",
              style: 'izquierda'
            }, '\n',
            {
              text: "Por éste medio me dirijo a usted para informarle que en las oficinas de la subregión " + data.tcSubregion.subregionDesc + ", municipio de " + data.tcSubregion.tcMunicipio.municipioDesc + " departamento de " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + " se ha presentado solicitud de aprovechamiento forestal con fines comerciales en el cual el titular debe garantizar el compromiso de repoblación forestal con la información siguiente:",
              style: 'izquierda'
            }, '\n'
          ];
          var tcTipoGarantia = {};
          var tcRegente = {};
          var tcSubregional = tarea.tcPersonaAsignada;
          if (turno > 1) {
            tcTipoGarantia = dataPoa.tcTipoGarantia;
            tcRegente = dataPoa.tcRegente;
          } else {
            tcTipoGarantia = data.ttResumenGestion.tcTipoGarantia;
            tcRegente = data.ttResumenGestion.tcRegente;
          }
          var body = [
            [
              { text: 'Tipo de garantía que solicita', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: tcTipoGarantia.tipoGarantiaDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Expediente no.', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.expediente, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Turno solicitado', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: turno, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          ];
          body.push(
            [
              { text: 'Titular (es) de la licencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 4 }, {}, {}, {}
            ]
          );
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            body.push(
              [
                { text: 'Razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.razonSocial, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          } else {
            for (i = 0; i < data.personas.length; i++) {
              body.push(
                [
                  { text: 'Nombre:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: data.personas[i].tcPersona.personaDesc, style: 'tableSubHeader', alignment: 'left' },
                  { text: 'No. De DPI', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: data.personas[i].tcPersona.cui, style: 'tableSubHeader', alignment: 'left' }
                ]
              );
            }
          }
          if (data.representantes == null) {
            data.representantes = [];
          }
          if (data.representantes.length > 0) {
            body.push(
              [
                { text: 'Representante legal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 4 }, {}, {}, {}
              ]
            );
            for (i = 0; i < data.representantes.length; i++) {
              body.push(
                [
                  { text: 'Nombre:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: data.representantes[i].tcPersona.personaDesc, style: 'tableSubHeader', alignment: 'left' },
                  { text: 'No. De DPI', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: data.representantes[i].tcPersona.cui, style: 'tableSubHeader', alignment: 'left' }
                ]
              );
            }
          }
          var regenteDesc = 'No aplica';
          var rf = 'N/A';
          if (tarea.ttSeguimientoTarea.volumen > 100) {
            regenteDesc = tcRegente.personaDesc;
            rf = tcRegente.rf;
          }
          body.push(
            [
              { text: 'Regente Forestal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: regenteDesc, style: 'tableSubHeader', alignment: 'left' },
              { text: 'RFN', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: rf, style: 'tableSubHeader', alignment: 'left' }
            ]
          );
          var nombreFiador = '';
          var dpiFiador = '';
          var rnfEmpresa = '';
          if (turno > 1) {
            nombreFiador = dataPoa.nombreFiador;
            dpiFiador = dataPoa.dpiFiador;
            rnfEmpresa = dataPoa.rnf;
            if (dataPoa.tipoFiadorId == 1) {
              nombreFiador = dataPoa.nombreEmpresa + ' representado legalmente por ' + nombreFiador;
            }
          } else {
            nombreFiador = data.ttResumenGestion.nombreFiador;
            dpiFiador = data.ttResumenGestion.dpiFiador;
            if (data.ttResumenGestion.tipoFiadorId == 1) {
              nombreFiador = data.ttResumenGestion.nombreEmpresa + ' representado legalmente por ' + nombreFiador;
            }
            rnfEmpresa = data.ttResumenGestion.rnf;
          }
          if (tcTipoGarantia.tipoGarantiaId == 9) {
            body.push(
              [
                { text: 'Número de registro de la empresa (RNF)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: rnfEmpresa, style: 'tableSubHeader', alignment: 'left' },
                { text: 'Nombre de la empresa', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttResumenGestion.nombreEmpresa, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          } else {
            body.push(
              [
                { text: 'Fiador de la garantía', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: nombreFiador, style: 'tableSubHeader', alignment: 'left' },
                { text: 'CUI (DPI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: dpiFiador, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          }
          body.push(
            [
              { text: 'Datos de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 4 }, {}, {}, {}
            ]
          );
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            body.push(
              [
                { text: 'Nombre:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'Ubicación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ]
            );
          }
          body.push(
            [
              { text: 'Área de compromiso', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: area, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Tratamiento(s) aplicado(s)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: tratas, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Área para calcular la garantía', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(tarea.ttSeguimientoTarea.area).toFixed(4), style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Monto de la garantía', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Q. ' + formatNumber.new(tarea.ttSeguimientoTarea.monto), style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Volumen a extraer (m³)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(tarea.ttSeguimientoTarea.volumen).toFixed(4), style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Comentarios', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: tarea.observaciones, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          );
          var tbInfoGeneral = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [100, 150, 60, 150],
              body: body
            }
          };
          content.push(tbInfoGeneral);
          var extra = '';
          if (porcentaje > appSettings.extraccionMinima) {
            extra = ' (Porcentaje de área basal a extraer: ' + porcentaje + ' %)';
          }
          content.push(
            {
              text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + tarea.ttSeguimientoTarea.area + ' ha.' + extra,
              fillColor: '#CCCCCC', fontSize: 8, alignment: 'justify'
            }
          );
          if (tcTipoGarantia.tipoGarantiaId != 9) {
            content.push('\n');
            content.push({ text: 'Se anexa a la presente: a) Fotocopia legible de DPI del titular, b) Fotocopia legible de DPI del Fiador, c) Estado patrimonial del fiador propuesto', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' });
          }
          content.push('\n');
          content.push({ text: 'Por lo expuesto anteriormente, solicito a su persona dar su opinión al respecto para el otorgamiento de aval para dicha garantía.', style: 'izquierda' });
          content.push('\n');
          content.push({ text: 'Sin otro particular.', style: 'izquierda' });
          content.push({ text: 'Atentamente,', style: 'izquierda' });
          content.push('\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + tcSubregional.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            }
          );
          content.push(
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-011\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              tableSubHeader: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOpinionJuridica: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == null) {
            sigla = "";
          }
          if (tarea.ttSeguimientoTarea.numeroResolucion == undefined) {
            tarea.ttSeguimientoTarea.numeroResolucion = "";
          } else {
            if (tarea.ttSeguimientoTarea.numeroResolucion == null) {
              tarea.ttSeguimientoTarea.numeroResolucion = "";
            }
          }
          var nombreSalida = 'juridico.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          var i;
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (textoDireccion.length > 0) {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          var aprobado = "";
          var procede = "";
          if (tarea.aprobado == 1) {
            aprobado = "LLENA";
            procede = "PROCEDE";
          } else {
            aprobado = "NO LLENA";
            procede = "NO PROCEDE";
          }
          var observacionesTitulo = {};
          var observaciones = {};
          if (tarea.observaciones != null) {
            if (tarea.observaciones.length > 0) {
              observacionesTitulo = {
                text: "OBSERVACIONES",
                style: 'izquierda',
                bold: true
              };
              observaciones = {
                text: tarea.observaciones,
                style: 'izquierda'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Dictamen Jurídico No. " + tarea.ttSeguimientoTarea.numeroResolucion + (tarea.ttSeguimientoTarea.codigo.length == 0 ? "" : "/" + tarea.ttSeguimientoTarea.codigo),
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "En respuesta a providencia " + tarea.ttPadre.ttSeguimientoTarea.providencia + ". Le informo que se realizó el análisis de los documentos legales presentados en el expediente No. " + data.expediente + ", según solicitud  de " + data.tcTipoGestion.tipoGestionDesc + ". Solicitado por " + solicitante + ", en la finca " + textoDireccion,
              style: 'izquierda'
            }, '\n',
            {
              text: "Después de realizar el análisis de los  documentos legales para éste tipo de aprovechamiento y de la lectura del expediente sometido a la consideración de esta Delegación Jurídica se determina que:",
              style: 'izquierda'
            }, "\n",
            {
              text: "ANTECEDENTES",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.antecedente,
              style: 'izquierda'
            }, "\n",
            {
              text: "FUNDAMENTO LEGAL",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.fundamento,
              style: 'izquierda'
            }, "\n",
            {
              text: "ANÁLISIS",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.analisis,
              style: 'izquierda'
            }, "\n", observacionesTitulo, '\n', observaciones, '\n',
            {
              text: "Por lo anterior, ésta delegación jurídica considera que el expediente " + aprobado + " los requisitos legales para éste tipo de trámite por lo  que " + procede + " LA APROBACIÓN de la " + data.tcTipoGestion.tipoGestionDesc,
              style: 'izquierda'
            }, '\n\n',
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Delegado(a) IV Jurídico(a) Región " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: '\n\nNombre: ____________________________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nFecha:_____________ Firma: ________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-005\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaJuridica: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          var nombreSalida = 'enmienda.pdf';
          if (sigla == null) {
            sigla = "";
          }
          if (tarea.ttSeguimientoTarea.numeroResolucion == undefined) {
            tarea.ttSeguimientoTarea.numeroResolucion = "";
          } else {
            if (tarea.ttSeguimientoTarea.numeroResolucion == null) {
              tarea.ttSeguimientoTarea.numeroResolucion = "";
            }
          }
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var body = [];
          var tmpData = tarea.ttSeguimientoTarea.enmienda;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          var observacionesTitulo = {};
          var observaciones = {};
          if (tarea.observaciones != null) {
            if (tarea.observaciones.length > 0) {
              observacionesTitulo = {
                text: "Observaciones",
                style: 'izquierda',
                bold: true
              };
              observaciones = {
                text: tarea.observaciones,
                style: 'izquierda'
              };
            }
          }
          var tbEnmienda = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Dictamen No. " + tarea.ttSeguimientoTarea.numeroResolucion + (tarea.ttSeguimientoTarea.codigo.length == 0 ? "" : "/" + tarea.ttSeguimientoTarea.codigo),
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "En respuesta a la providencia No. " + tarea.ttPadre.ttSeguimientoTarea.providencia + " le informo que se realizó un  análisis de los documentos legales presentados en el expediente No. " + data.expediente
                + " según solicitud de " + data.tcTipoGestion.tipoGestionDesc + ", solicitado por " + solicitante
                + ", en la finca " + textoDireccion
                + ". \n\nDespués de realizar el análisis de los  documentos legales para éste tipo de aprovechamiento y de la lectura del expediente sometido a la consideración de esta Delegación Jurídica se determina que:",
              style: 'izquierda'
            }, "\n",
            {
              text: "ANTECEDENTES",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.antecedente,
              style: 'izquierda'
            }, "\n",
            {
              text: "FUNDAMENTO LEGAL",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.fundamento,
              style: 'izquierda'
            }, "\n",
            {
              text: "ANÁLISIS",
              style: 'centrar'
            }, "\n",
            {
              text: tarea.ttSeguimientoTarea.analisis,
              style: 'izquierda'
            }, "\n", observacionesTitulo, '\n', observaciones, '\n',
            {
              text: "Previo a continuar con el trámite administrativo del expediente el solicitante debe presentar lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmienda, '\n',
            {
              text: "Por lo anterior, solicito a su persona, requerir la información al solicitante. Para continuar con el trámite del expediente administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: sigla + ' ' + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Delegada(o) Jurídica Región " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'cc \t Archivo \n//\tExpediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-006\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOpinionJuridicaSuspension: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == null) {
            sigla = "";
          }
          if (tarea.ttSeguimientoTarea.numeroResolucion == undefined) {
            tarea.ttSeguimientoTarea.numeroResolucion = "";
          } else {
            if (tarea.ttSeguimientoTarea.numeroResolucion == null) {
              tarea.ttSeguimientoTarea.numeroResolucion = "";
            }
          }
          var nombreSalida = 'juridico.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          var i;
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (textoDireccion.length > 0) {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          var aprobado = "";
          var procede = "";
          if (tarea.aprobado == 1) {
            aprobado = "LLENA";
            procede = "PROCEDE";
          } else {
            aprobado = "NO LLENA";
            procede = "NO PROCEDE";
          }
          var observacionesTitulo = {};
          var observaciones = {};
          if (tarea.observaciones != null) {
            if (tarea.observaciones.length > 0) {
              observacionesTitulo = {
                text: "OBSERVACIONES",
                style: 'izquierda',
                bold: true
              };
              observaciones = {
                text: tarea.observaciones,
                style: 'izquierda'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Dictamen Jurídico No. " + tarea.ttSeguimientoTarea.numeroResolucion + (tarea.ttSeguimientoTarea.codigo.length == 0 ? "" : "/" + tarea.ttSeguimientoTarea.codigo),
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n\n',
            {
              text: [
                {
                  text: 'ASUNTO: ', fontSize: 12, bold: true
                },
                {
                  text: ' El direcctor subregional ' + data.tcSubregion.subregionDesc + ', ' + tarea.ttPadre.tcPersonaAsignada.personaDesc
                    + ', mediante providencia, solicita análisis jurídico con base a oficio de oposición presentado dentro del expediente ' + data.expediente, style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: 'OPINIÓN',
              bold: true,
              underline: true,
              style: 'izquierda'
            },
            {
              text: tarea.ttSeguimientoTarea.fundamento,
              style: 'izquierda'
            }, '\n', observacionesTitulo, '\n', observaciones, '\n\n\n\n',
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Delegada(o) Jurídica(o) Región " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: '\n\nNombre: ____________________________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nFecha:_____________ Firma: ________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-007\nVersión: 2';
          if (tarea.aprobado == 0) {
            textoFooter = 'Codigo: LI-RE-008\nVersión: 2';
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOpinionMonitoreo: function (tarea, usuarioDesc, dataEmpresa, turno, sigla) {
          var ttSolicitudPoa = null;
          if (turno > 1) {
            ttSolicitudPoa = tarea;
            tarea = ttSolicitudPoa.ttTarea;
          }
          var data = tarea.ttGestion;
          if (sigla == null) {
            sigla = "";
          }
          if (tarea.ttSeguimientoTarea.numeroResolucion == undefined) {
            tarea.ttSeguimientoTarea.numeroResolucion = "";
          } else {
            if (tarea.ttSeguimientoTarea.numeroResolucion == null) {
              tarea.ttSeguimientoTarea.numeroResolucion = "";
            }
          }
          var dataCompromisoTmp = angular.copy(data.rodal);
          var area = 0, areaCompromiso = 0;
          var hayFueraBosque = false;
          for (i = 0; i < dataCompromisoTmp.length; i++) {
            if (parseInt(dataCompromisoTmp[i].turno) == parseInt(turno)) {
              if (dataCompromisoTmp[i].tcTipoCobertura.tipoCoberturaId == 3) {
                area += parseFloat(dataCompromisoTmp[i].areaGarantia);
                areaCompromiso += parseFloat(dataCompromisoTmp[i].areaGarantia);
                hayFueraBosque = true;
              } else {
                areaCompromiso += parseFloat(dataCompromisoTmp[i].area);
                area += parseFloat(dataCompromisoTmp[i].area);
              }
            }
          }
          area = parseFloat(area).toFixed(4);
          areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
          var nombreSalida = 'monitoreo.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          var i;
          var representante = '';
          var bndSolicitante = 'es favorable';
          if (!tarea.ttSeguimientoTarea.fundamento.solicitante) {
            bndSolicitante = 'no es favorable';
          }
          var bndRegente = 'es favorable';
          if (!tarea.ttSeguimientoTarea.fundamento.regente) {
            bndRegente = 'no es favorable';
          }
          var bndFiador = 'es favorable';
          if (!tarea.ttSeguimientoTarea.fundamento.fiador) {
            bndFiador = 'no es favorable';
          }
          var bndPatrimonio = 'cubre';
          if (!tarea.ttSeguimientoTarea.fundamento.patrimonio) {
            bndPatrimonio = 'no cubre';
          }
          var bndRepresenta = ', el historial del representante legal es favorable';
          if (!tarea.ttSeguimientoTarea.fundamento.representante) {
            bndRepresenta = ', el historial del representante legal no es favorable';
          }
          var bndRepresentaFiador = ', el historial del representante legal del fiador es favorable';
          if (!tarea.ttSeguimientoTarea.fundamento.representantef) {
            bndRepresentaFiador = ', el historial del representante legal del fiador no es favorable';
          }
          if (data.tieneRepresentante == 1) {
            for (var i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representante = data.representantes[i].tcPersona.personaDesc;
              } else {
                representante += ', ' + data.representantes[i].tcPersona.personaDesc;
              }
            }
            representante = ', a través de su representante legal, ' + representante;
          } else {
            bndRepresenta = '';
          }
          var representanteFiador = "";
          if (turno > 1) {
            if (ttSolicitudPoa.tipoFiadorId == 1) {
              representanteFiador = ttSolicitudPoa.nombreEmpresa + " representada legalmente por " + ttSolicitudPoa.nombreFiador;
            } else {
              representanteFiador = ttSolicitudPoa.nombreFiador;
              bndRepresentaFiador = ' ';
            }
          } else {
            if (data.ttResumenGestion.tipoFiadorId == 1) {
              representanteFiador = data.ttResumenGestion.nombreEmpresa + " representada legalmente por " + data.ttResumenGestion.nombreFiador;
            } else {
              representanteFiador = data.ttResumenGestion.nombreFiador;
              bndRepresentaFiador = ' ';
            }
          }
          var calculoMonto = 'área por área';
          var resto = area - parseFloat(tarea.ttPadre.ttSeguimientoTarea.area);
          resto = Math.abs(resto);
          if (resto > 0 && !hayFueraBosque) {
            tarea.ttPadre.ttSeguimientoTarea.area = parseFloat(tarea.ttPadre.ttSeguimientoTarea.area);
            if (tarea.ttPadre.ttSeguimientoTarea.area > 1) {
              if (resto < 0.3) {
                area = parseFloat(tarea.ttPadre.ttSeguimientoTarea.area);
              }
            } else {
              if (resto < 0.1) {
                area = parseFloat(tarea.ttPadre.ttSeguimientoTarea.area);
              }
            }
          }
          if (area != parseFloat(areaCompromiso)) {
            calculoMonto = 'área basal';
          }
          if (hayFueraBosque) {
            calculoMonto = 'área basal ideal';
          }
          var textoEscritura = 'contrato de reforestación con garantía fiduciaria en documento privado con firmas legalizadas';
          if (area >= 3) {
            textoEscritura = 'escritura pública';
          }
          var solicitante = "";
          var personas = data.personas;
          if (personas == null) {
            personas = [];
          }
          for (i = 0; i < personas.length; i++) {
            item = personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion != null) {
            if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
              solicitante = data.ttTipoPropietarioGestion.razonSocial;
            }
          }
          var aprobado = "";
          if (tarea.aprobado == 1) {
            aprobado = "AVALA";
          } else {
            aprobado = "NO AVALA";
          }
          var regente = ', esta obligación no cuenta con regente forestal debido a que el aprovechamiento forestal es menor a 100 metros cúbicos';
          var tcTipoGarantia = null;
          var nombreEmpresa = null;
          var rnfEmpresa = null;
          if (turno > 1) {
            if (ttSolicitudPoa.tcRegente != null) {
              regente = ' y proponiendo como regente forestal a ' + ttSolicitudPoa.tcRegente.personaDesc;
              bndRegente = ', el historial del regente forestal propuesto ' + bndRegente + ', ';
            } else {
              bndRegente = ', ';
            }
            tcTipoGarantia = ttSolicitudPoa.tcTipoGarantia;
            nombreEmpresa = ttSolicitudPoa.nombreEmpresa;
            rnfEmpresa = ttSolicitudPoa.rnf;
          } else {
            if (data.ttResumenGestion.tcRegente != null) {
              regente = ' y proponiendo como regente forestal a ' + data.ttResumenGestion.tcRegente.personaDesc;
              bndRegente = ', el historial del regente forestal propuesto ' + bndRegente + ', ';
            } else {
              bndRegente = ', ';
            }
            tcTipoGarantia = data.ttResumenGestion.tcTipoGarantia;
            nombreEmpresa = data.ttResumenGestion.nombreEmpresa;
            rnfEmpresa = data.ttResumenGestion.rnf;
          }
          var historialFiador = 'el historial del fiador propuesto ' + bndFiador;
          var cubreMonto = ' y el estado patrimonial del fiador ' + bndPatrimonio + ' el monto de la garantía';
          var tipoGarantiaDesc = 'contrato de reforestación con garantía fiduciaria, siendo fiador (a) ' + representanteFiador;

          if (tcTipoGarantia.tipoGarantiaId == 9) {
            tipoGarantiaDesc = tcTipoGarantia.tipoGarantiaDesc + '; siendo la empresa forestal ' + nombreEmpresa
              + ' con registro en la categoría de Empresa Forestal y subcategoría de Repobladoras Forestales número ' + rnfEmpresa;
            textoEscritura = tcTipoGarantia.tipoGarantiaDesc;
            historialFiador = 'el historial de la empresa forestal ' + bndFiador + '.';
            cubreMonto = '';
          }
          var obs = {};
          var obsDetalle = {};
          if (tarea.observaciones != null) {
            if (tarea.observaciones.trim().length > 0) {
              obs = {
                text: "OBSERVACIONES",
                style: 'izquierda', bold: true
              };
              obsDetalle = {
                text: '\n' + tarea.observaciones,
                style: 'izquierda'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Guatemala, Guatemala, " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            },
            {
              text: "MF.AVAL." + tarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: 'Reciba un saludo y deseos de éxitos en sus actividades.',
              style: 'izquierda'
            }, '\n',
            {
              text: "Me dirijo a usted para dar respuesta al oficio número " + tarea.ttPadre.ttSeguimientoTarea.codigo + ". En el cual se solicita el aval de este departamento para que "
                + solicitante + representante + '; pueda garantizar una obligación de repoblación forestal mediante '
                + tipoGarantiaDesc + regente,
              style: 'izquierda'
            }, '\n',
            {
              text: [
                {
                  text: 'Se procedió a revisar en la bases de datos de Obligaciones de Repoblación Forestal, el historial de los señores antes mencionados; concluyendo que este departamento ',
                  style: 'izquierda'
                },
                {
                  text: aprobado, bold: true, style: 'izquierda'
                },
                {
                  text: ' la garantía solicitada mediante ' + textoEscritura + ', en un área de ' + areaCompromiso + ' ha. por un monto de Q. ' 
                  + formatNumber.new(tarea.ttPadre.ttSeguimientoTarea.monto)
                    + ', ya que el historial del solicitante ' + bndSolicitante + bndRepresenta + bndRegente + historialFiador
                    + bndRepresentaFiador + cubreMonto,
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + parseFloat(tarea.ttPadre.ttSeguimientoTarea.area).toFixed(4) 
              + ' ha.',
              style: 'izquierda'
            }, '\n',
            obs, obsDetalle, '\n',
            {
              text: 'Sin otro particular',
              style: 'izquierda'
            }, '\n\n\n\n\n',
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: tarea.tcPersonaAsignada.tcPuesto.puestoDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-012\nVersión: 2';
          if (tarea.aprobado != 1) {
            textoFooter = 'Codigo: LI-RE-013\nVersión: 2';
          }
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            }, 
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaMonitoreo: function (ttSolicitudPoa, usuarioDesc, dataEmpresa, turno, sigla) {
          var tarea = null;
          if (turno > 1) {
            tarea = ttSolicitudPoa.ttTarea;
          } else {
            tarea = ttSolicitudPoa;
          }
          var data = tarea.ttGestion;
          var nombreSalida = 'enmienda.pdf';
          if (sigla == null) {
            sigla = "";
          }
          if (tarea.ttSeguimientoTarea.numeroResolucion == undefined) {
            tarea.ttSeguimientoTarea.numeroResolucion = "";
          } else {
            if (tarea.ttSeguimientoTarea.numeroResolucion == null) {
              tarea.ttSeguimientoTarea.numeroResolucion = "";
            }
          }
          var representante = '';
          if (data.tieneRepresentante == 1) {
            for (var i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representante = data.representantes[i].tcPersona.personaDesc;
              } else {
                representante += ', ' + data.representantes[i].tcPersona.personaDesc;
              }
            }
            representante = ', a través de su representante legal, ' + representante;
          }
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          var body = [];
          var tmpData = tarea.ttSeguimientoTarea.enmienda;
          if (tmpData == null) {
            tmpData = [];
          }
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          var tbEnmienda = {};
          if (body.length > 0) {
            tbEnmienda = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          };
          var conAtencion = {};
          var tcRegente = null;
          var nombreFiador = '';
          if (ttSolicitudPoa.numero <= 1) {
            tcRegente = data.ttResumenGestion.tcRegente;
            nombreFiador = data.ttResumenGestion.nombreFiador;
          } else {
            tcRegente = ttSolicitudPoa.tcRegente;
            nombreFiador = ttSolicitudPoa.nombreFiador;
          }
          if (tcRegente != null) {
            conAtencion = {
              text: "En atención al oficio No. " + tarea.ttPadre.ttSeguimientoTarea.codigo + "; referente a solicitud de AVAL, para respaldar mediante Garantía Fiduciaria, el compromiso de repoblación forestal que actualmente se gestiona; según expediente " + data.expediente
                + ", a nombre de " + solicitante + representante
                + "; proponiendo como fiador a " + nombreFiador + ', y como regente forestal a ' + tcRegente.personaDesc + '. Luego del análisis de la documentación presentada, se establece que previo a seguir con el trámite correspondiente, deberá presentar las siguientes enmiendas o requerimientos que se describen a continuación:',
              style: 'izquierda'
            };
          } else {
            conAtencion = {
              text: "En atención al oficio No. " + tarea.ttPadre.ttSeguimientoTarea.codigo + "; referente a solicitud de AVAL, para respaldar mediante Garantía Fiduciaria, el compromiso de repoblación forestal que actualmente se gestiona; según expediente " + data.expediente
                + ", a nombre de " + solicitante + representante
                + "; proponiendo como fiador a " + nombreFiador + '. Luego del análisis de la documentación presentada, se establece que previo a seguir con el trámite correspondiente, deberá presentar las siguientes enmiendas o requerimientos que se describen a continuación:',
              style: 'izquierda'
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + tarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: "Guatemala, Guatemala, " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: 'Reciba un saludo y deseos de éxitos en sus actividades.',
              style: 'izquierda'
            }, '\n',
            conAtencion, '\n',
            tbEnmienda, '\n',
            {
              text: "Por lo anterior, solicito a su persona, requerir la información al solicitante. Para continuar con el trámite del expediente administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n',
            {
              text: sigla + ' ' + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: tarea.tcPersonaAsignada.tcPuesto.puestoDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'cc \t Archivo',
              style: 'small', alignment: 'left'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            }, 
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOpinionTecnica: function (tarea, usuarioDesc, dataEmpresa, sigla, enviarArchivo) {
          var data = tarea.ttGestion;
          if (sigla == null) {
            sigla = "";
          }
          if (enviarArchivo == null) {
            enviarArchivo = false;
          }
          var nombreSalida = 'tecnico.pdf';
          var fechaProvidencia = new Date();
          if (tarea.ttSeguimientoTarea.fechaProvidencia != null) {
            fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          }
          if (fechaProvidencia == null) {
            fechaProvidencia = new Date();
          }
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          //primero validad si fundamento es un json valido
          var isValid = true;
          var i, j;
          var aprobado = "";
          var evaluacionP1 = {};
          var dictamen = {};
          var textoDireccion = "";
          var direccion = "";
          var colindancias = "";
          var localizacion = "";
          var nombreFinca = "";
          var area = 0;
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (textoDireccion.length > 0) {
              textoDireccion = textoDireccion + "; finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
            area += parseFloat(finca.tcFinca.area);
            nombreFinca += "Finca " + (i + 1) + ":\n\t" + finca.tcFinca.fincaDesc + "\n";
            direccion += "Finca " + (i + 1) + ":\n\t" + finca.tcFinca.direccion + ", " + finca.tcFinca.tcMunicipio.municipioDesc + ", " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "\n";
            colindancias += "Finca " + (i + 1) + ": \n\tN: " + finca.tcFinca.norte + "\n\tS: " + finca.tcFinca.sur + "\n\tE: " + finca.tcFinca.este + "\n\tO: " + finca.tcFinca.oeste + "\n";
            localizacion += "Finca " + (i + 1) + ":\n\tX: " + finca.tcFinca.gtmX + ", Y: " + finca.tcFinca.gtmY + " (Coordenadas GTM)\n";
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          area = parseFloat(area).toFixed(4);
          var otros = 0;
          var otros = area - parseFloat(data.ttDivisionAreaGestion.areaBosque);
          otros = parseFloat(otros).toFixed(4);
          var general = null;
          if (data.ttDivisionAreaGestion.areaFuera > 0) {
            general = [
              { id: 1, tipodesc: "Tipo de plan", descripcion: "Plan de manejo forestal", isDisabled: true },
              { id: 2, tipodesc: "Nombre (s) del (los) solicitante (s)", descripcion: solicitante, isDisabled: true },
              { id: 3, tipodesc: "Nombre de la finca", descripcion: nombreFinca, isDisabled: true },
              { id: 4, tipodesc: "Área (ha) total de la finca", descripcion: area, isDisabled: true },
              { id: 5, tipodesc: "Área (ha) con bosque", descripcion: parseFloat(data.ttDivisionAreaGestion.areaBosque).toFixed(4), isDisabled: true },
              { id: 6, tipodesc: "Área (ha) de producción", descripcion: parseFloat(data.ttDivisionAreaGestion.areaProduccion).toFixed(4), isDisabled: true },
              { id: 7, tipodesc: "Área (ha) de protección", descripcion: parseFloat(data.ttDivisionAreaGestion.areaProteccionBosque).toFixed(4), isDisabled: true },
              { id: 8, tipodesc: "Área (ha) a intervenir con bosque", descripcion: parseFloat(data.ttDivisionAreaGestion.areaIntervenirBosque).toFixed(4), isDisabled: true },
              { id: 9, tipodesc: "Área (ha) otros usos", descripcion: otros, isDisabled: true },
              { id: 10, tipodesc: "Área (ha) con árboles fuera de bosque", descripcion: data.ttDivisionAreaGestion.areaFuera, isDisabled: true },
              { id: 11, tipodesc: "Área (ha) de protección con árboles fuera de bosque", descripcion: data.ttDivisionAreaGestion.areaProteccionFuera, isDisabled: true },
              { id: 12, tipodesc: "Área (ha) a intervenir con árboles fuera de bosque", descripcion: data.ttDivisionAreaGestion.areaIntervenirFuera, isDisabled: true },
              { id: 13, tipodesc: "Localización", descripcion: localizacion, isDisabled: true },
              { id: 14, tipodesc: "Zona de vida", descripcion: data.ttBiofisicaGestion.zonaVida, isDisabled: false },
              { id: 15, tipodesc: "Fuentes de agua", descripcion: tarea.ttSeguimientoTarea.observaciones, isDisabled: false },
              { id: 16, tipodesc: "Ubicación política", descripcion: direccion, isDisabled: true },
              { id: 17, tipodesc: "Colindancias", descripcion: colindancias, isDisabled: true }
            ];
          } else {
            general = [
              { id: 1, tipodesc: "Tipo de plan", descripcion: "Plan de manejo forestal", isDisabled: true },
              { id: 2, tipodesc: "Nombre (s) del (los) solicitante (s)", descripcion: solicitante, isDisabled: true },
              { id: 3, tipodesc: "Nombre de la finca", descripcion: nombreFinca, isDisabled: true },
              { id: 4, tipodesc: "Área (ha) total de la finca", descripcion: area, isDisabled: true },
              { id: 5, tipodesc: "Área (ha) con bosque", descripcion: parseFloat(data.ttDivisionAreaGestion.areaBosque).toFixed(4), isDisabled: true },
              { id: 6, tipodesc: "Área (ha) de producción", descripcion: parseFloat(data.ttDivisionAreaGestion.areaProduccion).toFixed(4), isDisabled: true },
              { id: 7, tipodesc: "Área (ha) de protección", descripcion: parseFloat(data.ttDivisionAreaGestion.areaProteccionBosque).toFixed(4), isDisabled: true },
              { id: 8, tipodesc: "Área (ha) a intervenir con bosque", descripcion: parseFloat(data.ttDivisionAreaGestion.areaIntervenirBosque).toFixed(4), isDisabled: true },
              { id: 9, tipodesc: "Área (ha) de otros usos", descripcion: otros, isDisabled: true },
              { id: 10, tipodesc: "Localización", descripcion: localizacion, isDisabled: true },
              { id: 11, tipodesc: "Zona de vida", descripcion: data.ttBiofisicaGestion.zonaVida, isDisabled: false },
              { id: 12, tipodesc: "Fuentes de agua", descripcion: tarea.ttSeguimientoTarea.observaciones, isDisabled: false },
              { id: 13, tipodesc: "Ubicación política", descripcion: direccion, isDisabled: true },
              { id: 14, tipodesc: "Colindancias", descripcion: colindancias, isDisabled: true }
            ];
          }
          if (tarea.aprobado == 1) {
            aprobado = "APROBAR";
            evaluacionP1 = {
              text: "3.1. En la evaluación de campo se verificaron las especies y variables dasometricas  presentadas en el plan de manejo,  se realizó un recorrido por los linderos, mojones y rodales,  corroborando coordenadas y las características. Dentro del recorrido se evaluó que la propuesta de apertura de caminos y ubicación de bacadillas estén acordes a las condiciones del lugar.",
              style: 'izquierda'
            };
            dictamen = {
              text: "Con base a la  revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se recomienda " + aprobado + " la ejecución del Plan de Manejo Forestal de la finca " + textoDireccion + ". Con un aprovechamiento de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            }
          } else {
            aprobado = "NO APROBAR";
            evaluacionP1 = {
              text: "3.1 En la evaluación de campo se verificaron las especies y variables dasometricas  presentadas en el plan de manejo,  se realizó un recorrido por los linderos, mojones y rodales,  corroborando coordenadas y las características. Dentro del recorrido se evaluó que la propuesta de apertura de caminos y ubicación de bacadillas estén acordes a las condiciones del lugar.",
              style: 'izquierda'
            };
            dictamen = {
              text: "Con base a la  revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se dictamina " + aprobado + " la ejecución del Plan de Manejo Forestal, presentado por " + data.solicitante + " según expediente No. " + data.expediente,
              style: 'izquierda'
            }
          }
          var item = null;
          var body = [
            [
              { text: 'I.', style: 'tableSubHeader', alignment: 'left' },
              { text: 'INFORMACIÓN GENERAL', style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ],
            [
              { text: '\n', style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
            ]
          ];
          for (i = 0; i < general.length; i++) {
            item = [
              { text: '1.' + (i + 1), style: 'izquierda' },
              { text: general[i].tipodesc, style: 'izquierda' },
              { text: general[i].descripcion, style: 'izquierda' },
            ];
            body.push(item);
          }
          var tbInfoGeneral = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [30, 150, 250],
              body: body
            }, layout: 'noBorders'
          };
          //para pintar tabla antecedentes
          body = [
            [
              { text: 'II.', style: 'tableSubHeader', alignment: 'left' },
              { text: 'ANTECEDENTES', style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: '\n', style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]
          ];
          var tmpData = [];
          if (tarea.ttSeguimientoTarea.antecedente != null) {
            tmpData = tarea.ttSeguimientoTarea.antecedente;
          }
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '2.' + (i + 1), style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          if (tmpData.length <= 0) {
            item = [
              { text: '2.1', style: 'izquierda' },
              { text: "Ninguno", style: 'izquierda' }
            ];
            body.push(item);
          }
          var tbAntecedente = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [30, 450],
              body: body
            }, layout: 'noBorders'
          };
          //evaluacion de campo
          body = [];
          var rowSpan = 7;
          if (data.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 1) {
            rowSpan = 6;
            item = [
              { text: 'Tipo de inventario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcTipoInventario.tipoInventarioDesc, style: 'tableSubHeader', alignment: 'center', colSpan: 3 }, {}, {}
            ];
            body.push(item);
          } else {
            item = [
              { text: 'Tipo de inventario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcTipoInventario.tipoInventarioDesc, style: 'tableSubHeader', alignment: 'left' },
              { text: 'Tamaño de las parcelas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tamanioParcela, style: 'tableSubHeader', alignment: 'right' }
            ];
            body.push(item);
            item = [
              { text: 'Forma de parcela', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.tcFormaParcela.formaParcelaDesc, style: 'tableSubHeader', alignment: 'left' },
              { text: 'Total de Rodales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.ttInventarioGestion.totalRodales, style: 'tableSubHeader', alignment: 'right' }
            ]
            body.push(item);
          }
          var tbTInventario = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [90, 125, 120, 125],
              body: body
            }
          };
          //Evaluación de características generales del área propuesta
          body = [
            [
              { text: 'Rodal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Variables evaluadas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Plan de manejo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Verificación de campo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]
          ];
          tmpData = data.rodal;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: tmpData[i].correlativo, style: 'tableSubHeader', alignment: 'left', rowSpan: rowSpan },
              { text: 'Área (ha)', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: tmpData[i].area, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.areav, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            item = [
              '', { text: 'Pendiente (%)', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: tmpData[i].pendientePromedio, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.pendientePromediov, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            item = [
              '', { text: 'Clase de desarrollo', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: tmpData[i].tcClaseDesarrollo.codigoClase, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.codigoClasev, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            item = [
              '', { text: 'Incremento (m³/ha/año)', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: tmpData[i].incremento, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.incrementov, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            item = [
              '', { text: 'Edad (Años)', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: tmpData[i].edad, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.edadv, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            var especies = '';
            for (var j = 0; j < tmpData[i].especies.length; j++) {
              var especie = tmpData[i].especies[j];
              especies += especie.tcEspecie.nombreCientifico + '\n';
            }
            item = [
              '', { text: 'Especies', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
              { text: especies, style: 'normal', alignment: 'left' },
              { text: tmpData[i].ttEvaluacionCampo.especiesv, style: 'normal', alignment: 'left' },
            ];
            body.push(item);
            if (data.ttInventarioGestion.tcTipoInventario.tipoInventarioId != 1) {
              item = [
                '', { text: 'Error de muestreo (%)', style: 'tableSubHeader', fillColor: '#F2F2F2', alignment: 'left', },
                { text: tmpData[i].ttEvaluacionCampo.errorp, style: 'normal', alignment: 'left' },
                { text: tmpData[i].ttEvaluacionCampo.errorpv, style: 'normal', alignment: 'left' },
              ];
              body.push(item);
            }
          }
          var tbCaracteristica = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [40, 135, 150, 150],
              body: body
            }
          };
          //dasometrica
          body = [
            [
              { text: '', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Datos del elaborador', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Datos de la evaluación técnica', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: '* Diferencia en volumen', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
            ],
            [
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'No. Arboles', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP promedio cm', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura promedio m', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Suma de Vol m³', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP promedio cm', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura promedio m', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Suma de Vol m³', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vol. m³', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '%', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          tmpData = data.rodal;
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < tmpData[i].especies.length; j++) {
              item = [
                { text: tmpData[i].correlativo, style: 'small', alignment: 'center' },
                { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.arboles, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.dapPromedio, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.alturaPromedio, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.volumen, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.dapPromedioVerificado, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.alturaPromedioVerificado, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.volumenVerificado, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.volumenDiferencia, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].ttEspecieEvaluada.volumenPorcentaje, style: 'small', alignment: 'right' }
              ];
              body.push(item);
            }
          }
          var tbDasometrica = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [30, 60, 40, 35, 35, 35, 35, 35, 35, 35, 35],
              body: body
            }
          };
          //CONCLUSIONES
          body = [
            [
              { text: 'IV.', style: 'tableSubHeader', alignment: 'left' },
              { text: 'CONCLUSIONES', style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: '\n' },
              { text: '\n' }
            ]
          ];
          tmpData = tarea.ttSeguimientoTarea.analisis;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '4.' + (i + 1), style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
            item = [
              { text: '\n' },
              { text: '\n' }
            ];
            body.push(item);
          }
          var tbConclusion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [30, 460],
              body: body
            }, layout: 'noBorders'
          };
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nDICTAMEN TÉCNICO DE EVALUACIÓN DE PLANES DE MANEJO FORESTAL",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Dictamen No. " + tarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.tcSubregion.tcSubregional.personaDesc + "\nDirector (a) Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio le informo de la inspección realizada en la finca " + textoDireccion
                + ". Propiedad de " + solicitante + ", quien(es) solicita(n) " + data.tcTipoGestion.tipoGestionDesc
                + ", para lo cual ha(n) presentado a la Dirección Subregional " + data.tcSubregion.subregionDesc
                + ", el expediente identificado con el número " + data.expediente + ", dentro del cual se encuentra el Plan de Manejo Forestal elaborado por "
                + data.tcElaborador.personaDesc + ", inscrito en el Registro Nacional Forestal como Elaborador de Plan de Manejo con número " + data.tcElaborador.rfn
                + " y para el efecto me permito presentar los resultados de la inspección de campo, de conformidad con la información siguiente:",
              style: 'izquierda'
            }, '\n',
            tbInfoGeneral, '\n', tbAntecedente, '\n',
            {
              text: "III.\t\tEVALUACIÓN DE CAMPO",
              style: 'izquierda', bold: true
            }, '\n', tbTInventario, '\n', evaluacionP1, '\n',
            {
              text: "3.2  Resumen de la evaluación de campo",
              style: 'izquierda'
            }, '\n',
            {
              text: "Evaluación de características generales del área propuesta",
              style: 'negrilla'
            }, '\n', tbCaracteristica, '\n',
            {
              text: "Evaluación de las características dasométrica de las especies propuestas a aprovechar",
              style: 'negrilla'
            }, '\n', tbDasometrica, '\n',
            {
              text: "* La base de comparación del % de diferencia en volumen serán los datos obtenidos por el técnico forestal",
              style: 'negrilla'
            }, '\n', tbConclusion, '\n'
          ];
          if (tarea.aprobado == 1) { //si es aprobado
            content.push({
              text: "Con base a la revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se recomienda APROBAR la ejecución del Plan de Manejo Forestal de la finca: "
                + textoDireccion + ". Con un aprovechamiento de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            });
            //tabla silvicultura
            body = [
              [
                { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
                { text: 'Tipo de productos y volúmen a extraer', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {}
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Año', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie (código)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Tratamiento silvicultural', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Arbol/rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            itemTotal = {
              troza: 0,
              lenia: 0,
              otros: 0,
              volrExtraer: 0,
              arbolrExtraer: 0
            }
            var dataSilvicultura = data.rodal;
            var tratamientoSilviculturalDesc = '';
            for (i = 0; i < dataSilvicultura.length; i++) {
              filas = [];
              subTotal = {
                troza: 0,
                lenia: 0,
                otros: 0,
                volrExtraer: 0,
                arbolrExtraer: 0
              };
              for (j = 0; j < dataSilvicultura[i].especies.length; j++) {
                if (data.tcTipoGestion.tipoGestionId == 3) {
                  tratamientoSilviculturalDesc = 'Corta de salvamento';
                } else {
                  tratamientoSilviculturalDesc = dataSilvicultura[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
                }
                dataSilvicultura[i].especies[j].troza = parseFloat(dataSilvicultura[i].especies[j].troza).toFixed(4);
                dataSilvicultura[i].especies[j].lenia = parseFloat(dataSilvicultura[i].especies[j].lenia).toFixed(4);
                dataSilvicultura[i].especies[j].otros = parseFloat(dataSilvicultura[i].especies[j].otros).toFixed(4);
                dataSilvicultura[i].especies[j].volrExtraer = parseFloat(dataSilvicultura[i].especies[j].volrExtraer).toFixed(4);
                subTotal.troza += parseFloat(dataSilvicultura[i].especies[j].troza);
                subTotal.lenia += parseFloat(dataSilvicultura[i].especies[j].lenia);
                subTotal.otros += parseFloat(dataSilvicultura[i].especies[j].otros);
                subTotal.volrExtraer += parseFloat(dataSilvicultura[i].especies[j].volrExtraer);
                subTotal.arbolrExtraer += parseInt(dataSilvicultura[i].especies[j].arbolrExtraer);
                var volrExtraer = parseFloat(dataSilvicultura[i].especies[j].troza) + parseFloat(dataSilvicultura[i].especies[j].lenia);
                volrExtraer = parseFloat(volrExtraer).toFixed(4);
                var volrExtraerVerificar = parseFloat(dataSilvicultura[i].especies[j].volrExtraer).toFixed(4);
                if (volrExtraer != volrExtraerVerificar && tarea.estadoId == 1) {
                  toastr.error('El volumen de troza y leña a extraer en el rodal ' + dataSilvicultura[i].correlativo 
                  + ', especie ' + dataSilvicultura[i].especies[j].tcEspecie.nombreCientifico 
                  + ' no es correcta. El elaborador debe confirmar el porcentaje de troza, verificar la sumatoria de troza y leña (pestaña planificación, sección Silvicultura) y presionar en guardar');
                  return;
                }
                item = [
                  { text: dataSilvicultura[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: tratamientoSilviculturalDesc, style: 'small', alignment: 'left' },
                  { text: dataSilvicultura[i].especies[j].troza, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].lenia, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].arbolrExtraer, style: 'small', alignment: 'right' }
                ];
                filas.push(item);
              }
              //subtotal
              item = [
                { text: 'SUBTOTAL', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: parseFloat(subTotal.troza).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(subTotal.lenia).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(subTotal.volrExtraer).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: subTotal.arbolrExtraer, style: 'small', fillColor: '#CCCCCC', alignment: 'right' }
              ];
              filas.push(item);
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [55, 60, 55, 50, 50, 55, 45],
                  body: filas
                }, layout: 'noBorders', colSpan: 6
              }
              itemTotal.troza += subTotal.troza;
              itemTotal.lenia += subTotal.lenia;
              itemTotal.otros += subTotal.otros;
              itemTotal.volrExtraer += subTotal.volrExtraer;
              itemTotal.arbolrExtraer += subTotal.arbolrExtraer;
              item = [
                { text: dataSilvicultura[i].turno, style: 'small', alignment: 'left' },
                { text: dataSilvicultura[i].correlativo, style: 'small', alignment: 'left' },
                { text: dataSilvicultura[i].anio, style: 'small', alignment: 'left' },
                { text: parseFloat(dataSilvicultura[i].area).toFixed(4), style: 'small', alignment: 'left' },
                tablaFila
              ];
              body.push(item);
            }
            item = [
              { text: 'TOTAL GENERAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {},
              { text: parseFloat(itemTotal.troza).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(itemTotal.lenia).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(itemTotal.volrExtraer).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: itemTotal.arbolrExtraer, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
            ];
            body.push(item);
            var silvicultura = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [25, 25, 25, 25, 55, 60, 55, 50, 55, 45],
                body: body
              }
            }
            content.push('\n');
            content.push(silvicultura);
            content.push('\n\n');
            content.push({
              text: "V.\t\tCOMPROMISO DE REPOBLACIÓN FORESTAL",
              style: 'izquierda', bold: true
            });
            content.push('\n');
            content.push({ text: " A continuación se detalla el compromiso de repoblación correspondiente al primer turno", style: 'izquierda' });
            content.push('\n');
            content.push({ text: "a. Descripción del área del compromiso", style: 'izquierda', bold: true });
            content.push('\n');
            var rodales = data.rodal;
            tmpData = data.rodalesRepoblacion;
            if (data.tcTipoGestion.tipoGestionId == 5) {
              body = [
                [
                  { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Rodal', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Densidad inicial', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Rodal', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Tipo cobertura', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Manejo de Remanente', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Densidad inicial', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            }
            itemTotal = {
              area: 0,
              arbolResidual: 0,
              total: 0
            }
            for (i = 0; i < tmpData.length; i++) {
              filas = [];
              for (j = 0; j < tmpData[i].repoblacion.length; j++) {
                item = [
                  { text: tmpData[i].repoblacion[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: tmpData[i].repoblacion[j].tcMetodoSiembra.metodoSiembraDesc, style: 'small', alignment: 'left' },
                  { text: tmpData[i].repoblacion[j].densidadInicial, style: 'small', alignment: 'right' },
                  { text: tmpData[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc, style: 'small', alignment: 'left' }
                ];
                filas.push(item);
                itemTotal.total += parseInt(tmpData[i].repoblacion[j].densidadInicial);
              }
              if (data.tcTipoGestion.tipoGestionId == 5) {
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [95, 75, 60, 90],
                    body: filas
                  }, layout: 'noBorders', colSpan: 4
                }
              } else {
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [75, 65, 35, 90],
                    body: filas
                  }, layout: 'noBorders', colSpan: 4
                }
              }
              if (filas.length <= 0) {
                if (data.tcTipoGestion.tipoGestionId == 5) {
                  tablaFila = { text: data.tcTipoGestion.tipoGestionDesc + ' con pago al Fondo Forestal Privativo', style: 'small', alignment: 'left', colSpan: 4 };
                } else {
                  tablaFila = { text: 'No tiene aprovechamiento', style: 'small', alignment: 'left', colSpan: 4 };
                }
              }
              var areaGarantia = 0;
              var rodalRepoblacion = tmpData[i];
              if (data.tcTipoGestion.tipoGestionId == 5) {
                areaGarantia = tmpData[i].area;
                item = [
                  { text: tmpData[i].turno, style: 'small', alignment: 'left' },
                  { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
                  { text: areaGarantia, style: 'small', alignment: 'right' },
                  tablaFila, {}, {}, {}
                ];
              } else {
                var rodal = null;
                var encontrado = false;
                var remanente = 0;
                for (j = 0; j < rodales.length; j++) {
                  rodal = rodales[j];
                  if (rodal.correlativo == rodalRepoblacion.correlativo) {
                    encontrado = true;
                    break;
                  }
                }
                var tipoCoberturaDesc = 'N/A';
                if (encontrado) {
                  if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                    areaGarantia = rodal.areaGarantia;
                  } else {
                    areaGarantia = tmpData[i].area;
                  }
                  remanente = rodal.remanente;
                  itemTotal.arbolResidual += parseInt(remanente);
                  tipoCoberturaDesc = rodal.tcTipoCobertura.tipoCoberturaDesc;
                }
                item = [
                  { text: tmpData[i].turno, style: 'small', alignment: 'left' },
                  { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
                  { text: tipoCoberturaDesc, style: 'small', alignment: 'left' },
                  { text: parseFloat(rodalRepoblacion.area).toFixed(4), style: 'small', alignment: 'right' },
                  { text: remanente, style: 'small', alignment: 'right' },
                  tablaFila, {}, {}, {}
                ];
              }
              body.push(item);
              itemTotal.area += parseFloat(rodalRepoblacion.area);
            }
            if (data.tcTipoGestion.tipoGestionId == 5) {
              item = [
                { text: 'Totales', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 4 }, {}, {}, {}
              ];
              body.push(item);
              var tbRepoblacionPlan = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [40, 40, 40, 90, 70, 70, 90],
                  body: body
                }
              }
            } else {
              item = [
                { text: 'Totales', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
              ];
              body.push(item);
              var tbRepoblacionPlan = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [20, 20, 60, 30, 40, 60, 70, 45, 90],
                  body: body
                }
              }
            }
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              content.push({
                text: "El área de compromiso corresponde al área manejada y su recuperación será en base al siguiente cuadro:",
                style: 'normal'
              });
              content.push('\n');
              content.push(tbRepoblacionPlan);
            } else {
              content.push({
                text: "El área de compromiso corresponde al área manejada y su recuperación será en base a: Pago al Fondo Forestal Privativo",
                style: 'normal'
              });
            }
            content.push('\n');
            content.push({
              text: "VI.\t\tVALOR DE LA MADERA EN PIE",
              style: 'izquierda', bold: true
            });
            var tmpData = data.rodal;
            var valorTurno = 0;
            for (i = 0; i < tmpData.length; i++) { //rodales
              if (tmpData[i].turno == 1) {
                for (j = 0; j < tmpData[i].especies.length; j++) {//especie por rodal
                  valor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
                  valor = parseFloat(valor).toFixed(2);
                  tmpData[i].especies[j].totalValor = valor;
                  valor = valor * 0.1;
                  valorTurno += valor;
                  valor = parseFloat(valor).toFixed(2);
                  tmpData[i].especies[j].totalPagar = valor;
                }
              }
            }
            valorTurno = parseFloat(valorTurno).toFixed(2);
            content.push('\n');
            body = [
              [
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var totales = {
              troza: 0,
              lenia: 0,
              volrExtraer: 0,
              trozaValor: 0,
              leniaValor: 0,
              totalValor: 0,
              totalPagar: 0
            }
            for (i = 0; i < tmpData.length; i++) {
              if (tmpData[i].turno == 1) {
                for (j = 0; j < tmpData[i].especies.length; j++) {
                  tmpData[i].especies[j].troza = parseFloat(tmpData[i].especies[j].troza).toFixed(4);
                  tmpData[i].especies[j].lenia = parseFloat(tmpData[i].especies[j].lenia).toFixed(4);
                  tmpData[i].especies[j].volrExtraer = parseFloat(tmpData[i].especies[j].volrExtraer).toFixed(4);
                  tmpData[i].especies[j].ttEspecieEvaluada.trozaValor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor).toFixed(2);
                  tmpData[i].especies[j].ttEspecieEvaluada.leniaValor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor).toFixed(2);
                  tmpData[i].especies[j].totalValor = parseFloat(tmpData[i].especies[j].totalValor).toFixed(2);
                  item = [
                    { text: tmpData[i].turno, style: 'small', alignment: 'left' },
                    { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
                    { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
                  ];
                  body.push(item);
                  totales.troza += parseFloat(tmpData[i].especies[j].troza);
                  totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
                  totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
                  totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
                  totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
                  totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
                  totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
                }
              }
            }
            item = [
              { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
            ];
            body.push(item);
            var tbValorMadera = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 50, 45, 45, 45, 45, 45, 45, 45],
                body: body
              }
            };
            content.push({
              text: "El titular de la licencia se obliga a pagar al Fondo Forestal Privativo del INAB por concepto de derecho de corta un valor de Q. "
                + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)) + " correspondiente al primer turno de acuerdo al cuadro siguiente:",
              style: 'normal'
            });
            content.push('\n');
            content.push(tbValorMadera);
            content.push('\n');
            //RECOMENDACIONES
            body = [
              [
                { text: 'VII.', style: 'tableSubHeader', alignment: 'left' },
                { text: 'RECOMENDACIONES', style: 'tableSubHeader', alignment: 'left' }
              ]
            ];
            tmpData = tarea.ttSeguimientoTarea.fundamento;
            for (i = 0; i < tmpData.length; i++) {
              item = [
                { text: '7.' + (i + 1), style: 'izquierda' },
                { text: tmpData[i].descripcion, style: 'izquierda' }
              ];
              body.push(item);
            }
            var tbRecomendacion = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                dontBreakRows: true,
                widths: [30, 460],
                body: body
              }, layout: 'noBorders'
            };
            content.push(tbRecomendacion);
            content.push('\n');
          } else {
            content.push({
              text: "Con base a la  revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se dictamina NO APROBAR la ejecución del Plan de Manejo Forestal, presentado por "
                + solicitante + " según expediente No. " + data.expediente,
              style: 'izquierda'
            });
          }
          content.push({ text: "Sin otro particular, ", style: 'izquierda' });
          content.push('\n');
          content.push({ text: "Atentamente, ", style: 'izquierda' });
          content.push('\n\n');
          content.push({
            text: tarea.tcPersonaAsignada.personaDesc,
            style: 'centrar'
          });
          content.push({
            text: "Técnico forestal Subregión " + data.tcSubregion.subregionDesc,
            style: 'centrar'
          });
          content.push('\n');
          content.push({
            text: '\nNombre: ____________________________________',
            style: 'small', alignment: 'right'
          });
          content.push({
            text: '\n\nFecha:_____________ Firma: ________________',
            style: 'small', alignment: 'right'
          });
          content.push({
            text: '\nImpreso por: ' + (sigla == "" ? "" : sigla + ' ') + usuarioDesc,
            style: 'small', alignment: 'right'
          });
          content.push({
            text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
            style: 'small', alignment: 'right'
          });
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-005\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify',
                color: 'black'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              negrilla: {
                fontSize: 10,
                bold: true,
                alignment: 'justify'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 8,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              normal: {
                fontSize: 10,
                color: 'black'
              }
            }
          };
          /*if (enviarArchivo) {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              console.log(blob);
            });
          } else {
            pdfMake.createPdf(docDefinition).download(nombreSalida);
          }*/
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaTecnica: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == null) {
            sigla = '';
          }
          var data = tarea.ttGestion;
          var nombreSalida = 'enmienda.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var body = [];
          var tmpData = tarea.ttSeguimientoTarea.enmienda;
          var i;
          if (tmpData == null) {
            tmpData = [];
          }
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          var textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (textoDireccion.length > 0) {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          if (data.personas.length > 1) {
            solicitante = 'de los (as) señores (as) ' + solicitante + '. Quienes solicitan ';
          } else {
            solicitante = 'del (a) señor (a) ' + solicitante + '. Quien solicita ';
          }
          var tbEnmienda = {};
          if (body.length > 0) {
            tbEnmienda = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          }
          if (data.tcSubregion.tcSubregional.sigla == null) {
            data.tcSubregion.tcSubregional.sigla = '';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\nDIRECCION SUBREGIONAL " + data.tcSubregion.subregionDesc + '\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS',
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + tarea.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.tcSubregion.tcSubregional.sigla + ' ' + data.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.tcSubregion.subregionDesc + "\nINAB, " + data.tcSubregion.tcMunicipio.municipioDesc + ", " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio le informo que luego de la revisión, análisis del expediente  administrativo No. " + data.expediente
                + " e inspección realizada en la finca propiedad " + solicitante + data.tcTipoGestion.tipoGestionDesc
                + " en la finca " + textoDireccion
                + ". El Plan de Manejo Forestal que contiene inventario fue elaborado por " + data.tcElaborador.personaDesc
                + ",  quien se encuentra inscrito en el Registro Nacional Forestal como Elaborador de Plan de Manejo con  No. " + data.tcElaborador.rfn
                + ";  siendo necesario realizar lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmienda, '\n',
            {
              text: "Por lo anterior, solicito a su persona, requerir la información al solicitante, previo continuar  con el trámite del expediente administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Técnico forestal",
              style: 'centrar'
            }, '\n\n\n',
            {
              text: 'cc \t Archivo \n//\tExpediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-004\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaSubregional: function (data, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'enmienda.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i;
          var solicitante = "";
          var correo = "";
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
              correo = item.tcPersona.correo;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
              correo += ', ' + item.tcPersona.correo;
            }
          }
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          correo += ', ' + data.ttGestion.tcElaborador.correo;
          tmpData = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          tbEnmiendaSubregional = {};
          if (body.length > 0) {
            tbEnmiendaSubregional = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + data.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", "
                + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: solicitante + "\nSolicitante (s) ",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Con atención a:\n" + data.ttGestion.tcElaborador.personaDesc + "\nElaborador de Plan de Manejo Forestal.",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo " + data.ttGestion.expediente,
              style: 'izquierda'
            }, '\n',
            {
              text: "De acuerdo a los análisis realizados, para continuar con el trámite de su expediente es necesario que presente lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmiendaSubregional, '\n',
            {
              text: "Si transcurrido un período de seis meses a partir de la presente notificación, no se cumple con la entrega de enmiendas del expediente, el mismo se archivará de conformidad con la Ley de lo Contencioso Administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: sigla + ' ' + data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director subregional " + data.ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'cc \t Archivo, expediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mesNotifica) + " de " + fecha.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-009\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOficioEntregaEnmiendaSubregional: function (data, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'oficio.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date();
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i;
          tmpData = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          tbEnmiendaSubregional = {};
          if (body.length > 0) {
            tbEnmiendaSubregional = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          }
          var content = [
            {
              text: "OFICIO DE ENTREGA DE ENMIENDAS",
              style: 'centrar'
            }, '\n\n',
            {
              text: data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n',
            {
              text: "Sr (a). " + data.ttGestion.tcSubregion.tcSubregional.personaDesc
                + "\nDirector Subregional\n Subregión " + data.ttGestion.tcSubregion.subregionDesc
                + " " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc
                + "\nInstituto Nacional de Bosques -INAB-",
              style: 'izquierda', bold: true
            }, '\n\n',
            {
              text: "Reciba un  cordial saludo, deseándole éxitos en las actividades a su cargo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "En respuesta al oficio " + data.ttSeguimientoTarea.codigo
                + ", donde se me indica que previo a continuar con el trámite administrativo del expediente "
                + data.ttGestion.expediente + " es necesario realizar correcciones y/o completar el mismo, por lo que hago entrega de lo requerido como se especifica a continuación:",
              style: 'izquierda'
            }, '\n', tbEnmiendaSubregional, '\n',
            {
              text: "Se adjunta la documentación solicitada, para que el expediente mencionado continúe con el trámite respectivo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Sin otro particular me suscribo,",
              style: 'izquierda'
            },
            {
              text: "Atentamente,",
              style: 'izquierda'
            }, '\n\n\n',
            {
              text: data.ttGestion.tcPersonaCrea.personaDesc,
              style: 'centrar'
            },
            {
              text: "Solicitante",
              style: 'centrar'
            }, '\n'
          ];
          var textoFooter = 'Codigo: LI-RE-010\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionEnmienda: function (data, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == null) {
            sigla = '';
          }
          var nombreSalida = 'enmienda.pdf';
          var fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var tbEnmienda = {};
          var literal = "a.)";
          var l = 1;
          var i = 0;
          var solicitante = "";
          var correo = '';
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
              correo = item.tcPersona.correo;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
              correo += ", " + item.tcPersona.correo;
            }
          }
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttGestion.ttTipoPropietarioGestion.razonSocial;
          }
          correo += ", " + data.ttGestion.tcElaborador.correo;
          var juridico = null, tecnico = null, monitoreo = null;
          var enmienda = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < enmienda.length; i++) {
            if (enmienda[i].codigo != null) {
              switch (l) {
                case 1:
                  literal = 'a.)';
                  break;
                case 2:
                  literal = 'b.)';
                  break;
                case 3:
                  literal = 'c.)';
                  break;
                case 4:
                  literal = 'd.)';
                  break;
              }
              switch (enmienda[i].taskId) {
                case 3:
                  juridico = enmienda[i].personaDesc;
                  break;
                case 4:
                  tecnico = enmienda[i].personaDesc;
                  break;
                case 14:
                  monitoreo = enmienda[i].personaDesc;
                  break;
              }
              body.push(
                [
                  { text: '\n', style: 'izquierda' }, {}, {}
                ],
                [
                  { text: literal + ' Oficio No. ' + enmienda[i].codigo + '\n', style: 'izquierda', colSpan: 3, bold: true }, {}, {}
                ]
              );
              var tmpData = enmienda[i].enmienda;
              for (j = 0; j < tmpData.length; j++) {
                item = [
                  { text: '', style: 'izquierda' },
                  { text: (j + 1) + ".", style: 'izquierda' },
                  { text: tmpData[j].descripcion, style: 'izquierda' }
                ];
                body.push(item);
              }
              l++;
            }
          }
          for (i = 0; i < enmienda.length; i++) {
            if (enmienda[i].codigo == null) {
              var tmpData = enmienda[i].enmienda;
              if (tmpData.length > 0) {
                body.push(
                  [
                    { text: '\n', style: 'izquierda' }, {}, {}
                  ],
                  [
                    { text: 'Considere a la lista anterior lo siguiente: \n', style: 'izquierda', colSpan: 3 }, {}, {}
                  ]
                );
                for (j = 0; j < tmpData.length; j++) {
                  item = [
                    { text: '', style: 'izquierda' },
                    { text: (j + 1) + ".", style: 'izquierda' },
                    { text: tmpData[j].descripcion, style: 'izquierda' }
                  ];
                  body.push(item);
                }
              }
            }
          }
          tbEnmienda = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var texto = {};
          if (juridico == null && tecnico == null && monitoreo != null) {
            texto = {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo No. " + data.ttGestion.expediente
                + ". Le informo que los documentos legales para la garantía, presentados con su solicitud fueron analizados por el Departamento de Monitoreo Forestal y Obligaciones de Repoblación Forestal",
              style: 'izquierda'
            }
          }

          if (juridico != null && tecnico == null && monitoreo == null) {
            texto = {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo No. " + data.ttGestion.expediente
                + ". Le informo que los documentos legales presentados con su solicitud fueron analizados por el asesor jurídico de la región " + juridico,
              style: 'izquierda'
            };
          }
          if (juridico == null && tecnico != null && monitoreo == null) {
            texto = {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo No. " + data.ttGestion.expediente
                + ". Le informo que el plan de manejo fue corroborado por el técnico forestal " + tecnico,
              style: 'izquierda'
            };
          }
          if (juridico != null && tecnico != null && monitoreo != null) {
            texto = {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo " + data.ttGestion.expediente
                + ". Le informo que los documentos legales presentados con su solicitud fueron analizados por el asesor jurídico de la región " + juridico
                + " y el plan de manejo fue corroborado por el técnico forestal " + tecnico + ' y los documentos de la garantía por el Departamento de Monitoreo Forestal y Obligaciones de Repoblación Forestal',
              style: 'izquierda'
            };
          }
          var kEnter = '\n';
          var conAtencion = {
            text: "Con atención a:\n" + data.ttGestion.tcElaborador.personaDesc + "\nElaborador de Plan de Manejo Forestal.",
            style: 'izquierda', bold: true
          }
          if (data.tcTask.taskId == 11) {
            kEnter = {};
            conAtencion = {};
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + data.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr (a). \n" + solicitante + "\nSolicitante ",
              style: 'izquierda', bold: true
            }, '\n', kEnter, conAtencion, texto, '\n',
            {
              text: "De acuerdo a los análisis realizados, para continuar con el trámite de su expediente es necesario que presente lo siguiente:",
              style: 'izquierda'
            }, tbEnmienda, '\n',
            {
              text: "Si transcurrido un período de seis meses a partir de la presente notificación, no se cumple con la entrega de enmiendas del expediente, el mismo se archivará de conformidad con la Ley de lo Contencioso Administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n',
            {
              text: sigla + ' ' + data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director subregional " + data.ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'cc \t Archivo, Expediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mesNotifica) + " de " + fecha.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-009\nVersión: 2';
          if (monitoreo != null) {
            textoFooter = 'Codigo: LI-RE-014\nVersión: 2';
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionEnmiendaRegional: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'enmienda.pdf';
          var fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var solicitante = "";
          var correo = '';
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
              correo = item.tcPersona.correo;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
              correo += ", " + item.tcPersona.correo;
            }
          }
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttGestion.ttTipoPropietarioGestion.razonSocial;
          }
          correo += ", " + data.ttGestion.tcElaborador.correo;
          var regional = '';
          var tmpData = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < tmpData.length; i++) {
            if (tmpData[i].codigo != null) {
              body.push(
                [
                  { text: 'Oficio No. ' + tmpData[i].codigo + '\n', style: 'izquierda', colSpan: 3, bold: true }, {}, {}
                ]
              );
              var enmienda = tmpData[i].enmienda;
              for (j = 0; j < enmienda.length; j++) {
                item = [
                  { text: '', style: 'izquierda' },
                  { text: (j + 1) + ".", style: 'izquierda' },
                  { text: enmienda[j].descripcion, style: 'izquierda' }
                ];
                body.push(item);
              }
              regional = tmpData[i].personaDesc;
            }
          }
          var enmienda = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < enmienda.length; i++) {
            if (enmienda[i].codigo == null) {
              var tmpData = enmienda[i].enmienda;
              if (tmpData.length > 0) {
                body.push(
                  [
                    { text: '\n', style: 'izquierda' }, {}, {}
                  ],
                  [
                    { text: 'Así mismo es necesario incluir \n', style: 'izquierda', colSpan: 3 }, {}, {}
                  ]
                );
                tmpData = enmienda[i].enmienda;
                for (j = 0; j < tmpData.length; j++) {
                  item = [
                    { text: '', style: 'izquierda' },
                    { text: (j + 1) + ".", style: 'izquierda' },
                    { text: tmpData[j].descripcion, style: 'izquierda' }
                  ];
                  body.push(item);
                }
              }
            }
          }
          tbEnmienda = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + data.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr (a). \n" + solicitante + "\nSolicitante ",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Con atención a:\n" + data.ttGestion.tcElaborador.personaDesc + "\nElaborador de Plan de Manejo Forestal.",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio estoy dando respuesta a la solicitud de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", contenida en el expediente administrativo " + data.ttGestion.expediente
                + ". Le informo que los documentos legales presentados con su solicitud fueron analizados por el Director Regional " + regional,
              style: 'izquierda'
            }, '\n',
            {
              text: "De acuerdo a los análisis realizados, para continuar con el trámite de su expediente es necesario que presente lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmienda,
            {
              text: "Si transcurrido un período de seis meses a partir de la presente notificación, no se cumple con la entrega de enmiendas del expediente, el mismo se archivará de conformidad con la Ley de lo Contencioso Administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director subregional " + data.ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n\n',
            {
              text: 'cc \t Archivo \n//\tExpediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al correo electrónico: ' + correo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mesNotifica) + " de " + fecha.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOficioEntregaEnmienda: function (ttEnmienda, usuarioDesc, dataEmpresa, sigla) {
          var data = ttEnmienda.ttTarea;
          if (sigla == null) {
            sigla = '';
          }
          var nombreSalida = 'oficio.pdf';
          var fechaProvidencia = new Date();
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var tbEnmienda = {};
          var literal = "a.)";
          var l = 1;
          var i = 0;
          var juridico = null, tecnico = null, monitoreo = null;
          var enmienda = data.ttSeguimientoTarea.enmienda;
          for (i = 0; i < enmienda.length; i++) {
            if (enmienda[i].codigo != null) {
              switch (l) {
                case 1:
                  literal = 'a.)';
                  break;
                case 2:
                  literal = 'b.)';
                  break;
                case 3:
                  literal = 'c.)';
                  break;
                case 4:
                  literal = 'd.)';
                  break;
              }
              switch (enmienda[i].taskId) {
                case 3:
                  juridico = enmienda[i].personaDesc;
                  break;
                case 4:
                  tecnico = enmienda[i].personaDesc;
                  break;
                case 14:
                  monitoreo = enmienda[i].personaDesc;
                  break;
              }
              body.push(
                [
                  { text: '\n', style: 'izquierda' }, {}, {}
                ],
                [
                  { text: literal + ' Oficio No. ' + enmienda[i].codigo + '\n', style: 'izquierda', colSpan: 3, bold: true }, {}, {}
                ]
              );
              var tmpData = enmienda[i].enmienda;
              for (j = 0; j < tmpData.length; j++) {
                item = [
                  { text: '', style: 'izquierda' },
                  { text: (j + 1) + ".", style: 'izquierda' },
                  { text: tmpData[j].descripcion, style: 'izquierda' }
                ];
                body.push(item);
              }
              l++;
            }
          }
          for (i = 0; i < enmienda.length; i++) {
            if (enmienda[i].codigo == null) {
              var tmpData = enmienda[i].enmienda;
              if (tmpData.length > 0) {
                body.push(
                  [
                    { text: '\n', style: 'izquierda' }, {}, {}
                  ],
                  [
                    { text: 'Se incluye así mismo los siguientes \n', style: 'izquierda', colSpan: 3 }, {}, {}
                  ]
                );
                for (j = 0; j < tmpData.length; j++) {
                  item = [
                    { text: '', style: 'izquierda' },
                    { text: (j + 1) + ".", style: 'izquierda' },
                    { text: tmpData[j].descripcion, style: 'izquierda' }
                  ];
                  body.push(item);
                }
              }
            }
          }
          tbEnmienda = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var observacion = {};
          var enterKey = {};
          var tituloObservacion = {};
          if (ttEnmienda.observaciones != null) {
            tituloObservacion = { text: 'Observaciones del elaborador', bold: true, style: 'izquierda' };
            observacion = { text: ttEnmienda.observaciones, style: 'izquierda' };
            enterKey = '\n';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO DE ENTREGA DE ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n',
            {
              text: "Sr (a). " + data.ttGestion.tcSubregion.tcSubregional.personaDesc
                + "\nDirector Subregional\n Subregión " + data.ttGestion.tcSubregion.subregionDesc
                + " " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc
                + "\nInstituto Nacional de Bosques -INAB-",
              style: 'izquierda', bold: true
            }, '\n\n',
            {
              text: "Reciba un  cordial saludo, deseándole éxitos en las actividades a su cargo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "En respuesta al oficio " + data.ttSeguimientoTarea.codigo
                + ", donde se me indica que previo a continuar con el trámite administrativo del expediente "
                + data.ttGestion.expediente + " es necesario realizar correcciones y/o completar el mismo, por lo que hago entrega de lo requerido como se especifica a continuación:",
              style: 'izquierda'
            }, tbEnmienda, enterKey, tituloObservacion, enterKey, observacion, enterKey, '\n',
            {
              text: "Se adjunta la documentación solicitada, para que el expediente mencionado continúe con el trámite respectivo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Sin otro particular me suscribo,",
              style: 'izquierda'
            },
            {
              text: "Atentamente,",
              style: 'izquierda'
            }, '\n\n\n',
            {
              text: data.ttGestion.tcPersonaCrea.personaDesc,
              style: 'centrar'
            },
            {
              text: "Solicitante",
              style: 'centrar'
            }, '\n'
          ];
          var textoFooter = 'Codigo: LI-RE-024\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarDictamenSubregional: function (data, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'dictamen.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var fechaRecepcion = new Date(data.ttSeguimientoTarea.fundamento.fechaRecepcion);
          var mesRecepcion = parseInt(fechaRecepcion.getMonth()) + 1;
          var aprobado = "NO";
          var acceder = "no acceder";
          if (data.aprobado == 1) {
            aprobado = "SI";
            acceder = "acceder";
          }
          var textoDireccion = "";
          var i;
          for (i = 0; i < data.ttGestion.fincas.length; i++) {
            finca = data.ttGestion.fincas[i];
            if (i > 0) {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion
                + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc
                + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion
                + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var solicitante = "";
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          if (data.ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttGestion.ttTipoPropietarioGestion.razonSocial;
          }
          var fechaResolucion = new Date(data.ttSeguimientoTarea.fundamento.juridico.fechaFinalizacion);
          var mesResolucion = parseInt(fechaResolucion.getMonth()) + 1;
          data.ttSeguimientoTarea.fundamento.juridico.fecha = fechaResolucion.getDate() + " de " + getMonth(mesResolucion) + " de " + fechaResolucion.getFullYear();
          fechaResolucion = new Date(data.ttSeguimientoTarea.fundamento.tecnico.fechaFinalizacion);
          mesResolucion = parseInt(fechaResolucion.getMonth()) + 1;
          data.ttSeguimientoTarea.fundamento.tecnico.fecha = fechaResolucion.getDate() + " de " + getMonth(mesResolucion) + " de " + fechaResolucion.getFullYear();
          var texto = "Con fecha " + fechaRecepcion.getDate() + " de " + getMonth(mesRecepcion) + " de "
            + fechaRecepcion.getFullYear() + ", " + solicitante + ", propietario(s) del inmueble, "
            + textoDireccion
            + ", presentó solicitud a efecto se le autorice implementar Plan de Manejo Forestal para " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ". El expediente de mérito contiene Dictamen Jurídico No. "
            + data.ttSeguimientoTarea.fundamento.juridico.codigo + " de fecha " + data.ttSeguimientoTarea.fundamento.juridico.fecha + " emitida por el Asesor(a) Jurídico (a) Regional "
            + data.ttSeguimientoTarea.fundamento.juridico.personaDesc + ", quien verificó la documentación legal presentada de acuerdo a la petición formulada y el Dictamen Técnico No. "
            + data.ttSeguimientoTarea.fundamento.tecnico.codigo + " de fecha " + data.ttSeguimientoTarea.fundamento.tecnico.fecha + " emitido por el (la) Técnico Forestal "
            + data.ttSeguimientoTarea.fundamento.tecnico.personaDesc + ", quien verificó las circunstancias consignadas en el Plan de Manejo Forestal";
          if (data.ttSeguimientoTarea.fundamento.monitoreo != undefined) {
            if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
              || data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7) {
              fechaResolucion = new Date(data.ttSeguimientoTarea.fundamento.monitoreo.fechaFinalizacion);
              mesResolucion = parseInt(fechaResolucion.getMonth()) + 1;
              data.ttSeguimientoTarea.fundamento.monitoreo.fecha = fechaResolucion.getDate() + " de " + getMonth(mesResolucion) + " de " + fechaResolucion.getFullYear();
              texto = texto + '. El Aval de Garantía No. MF.AVAL.' + data.ttSeguimientoTarea.fundamento.monitoreo.codigo
                + " de fecha " + data.ttSeguimientoTarea.fundamento.monitoreo.fecha + " emitida por "
                + data.ttSeguimientoTarea.fundamento.monitoreo.personaDesc;
            }
          }
          var textoReferencia = {};
          var textoGarantia = {};
          var tbRepoblacion = {};
          var tbVigencia = {};
          var enter = {};
          var textoVigencia = {};
          var exentoGarantia = false;
          if (data.aprobado == 1) {
            enter = '\n';
            textoReferencia = {
              text: "Para el monto de la garantía tómese de referencia el siguiente cuadro:\n\n",
              style: 'izquierda'
            };
            var tmpData = data.ttGestion.rodal;
            var dataCompromiso = [];
            var tmpRodalesRepoblacion = data.ttGestion.rodalesRepoblacion;
            if (tmpRodalesRepoblacion == null) {
              tmpRodalesRepoblacion = [];
            }
            var rodales = [];
            for (i = 0; i < tmpData.length; i++) {
              if (parseInt(tmpData[i].turno) == 1) {
                rodales.push(tmpData[i]);
              }
            }
            //obtener datos del primer turno
            if (tmpRodalesRepoblacion.length > 0) {
              for (i = 0; i < tmpRodalesRepoblacion.length; i++) {
                if (parseInt(tmpRodalesRepoblacion[i].turno) == 1) {
                  dataCompromiso.push(tmpRodalesRepoblacion[i]);
                }
              }
            } else {
              tmpData = rodales;
            }
            var montoUtilizar = parseFloat(data.ttSeguimientoTarea.fundamento.montoUtilizar);
            var areaMonto = 0;
            var montoCompromiso = 0;
            var volumenTotal = 0;
            var area = 0;
            var montoTotal = 0;
            var areaGarantia = 0;
            var areaTotal = 0;
            var calculoMonto = 'área por área';
            var body = [];
            var item = [
              { text: 'Área de compromiso', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
              { text: 'Especies de compromiso', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
              { text: 'Tipo de garantía', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
              { text: 'Área para garantía', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
              { text: 'Monto (Q.)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
            ];
            body.push(item);
            var hayFueraBosque = false;
            var hayReforestacion = true;
            var tipoGarantiaDesc = 'No se proporcionó una garantía';
            if (data.ttGestion.ttResumenGestion.tcTipoGarantia != null
              && data.ttGestion.ttPlanificacionGestion.hayReforestacion == 1) {
              tipoGarantiaDesc = data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
            } else {
              if (data.ttGestion.tcTipoGestion.tipoGestionId == 5) {
                tipoGarantiaDesc = 'Pago al Fondo Forestal Privativo';
                hayReforestacion = false;
                data.observaciones = null;
                textoReferencia = {};
                enter = '';
              } else {
                if (data.ttGestion.tcTipoGestion.tipoGestionId == 2
                  || data.ttGestion.tcTipoGestion.tipoGestionId == 3
                  || data.ttGestion.tcTipoGestion.tipoGestionId == 8) {
                  tipoGarantiaDesc = 'Exento de garantía';
                  exentoGarantia = true;
                }
              }
            }
            if (hayReforestacion) {
              dataCompromiso = [];
              for (i = 0; i < tmpData.length; i++) {
                if (parseInt(tmpData[i].turno) == 1) {
                  dataCompromiso.push(tmpData[i]);
                }
              }
              for (i = 0; i < dataCompromiso.length; i++) {
                if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                  hayFueraBosque = true;
                  dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                } else {
                  dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                }
                dataCompromiso[i].areaMonto = dataCompromiso[i].areaGarantia;
                if (hayFueraBosque) {
                  calculoMonto = 'área basal ideal';
                } else {
                  calculoMonto = 'área basal';
                }
              }
              if (data.ttGestion.rodalesRepoblacion == null) {
                data.ttGestion.rodalesRepoblacion = [];
              }
              if (data.ttGestion.rodalesRepoblacion.length > 0) {
                tmpRodalesRepoblacion = [];
                for (i = 0; i < data.ttGestion.rodalesRepoblacion.length; i++) {
                  var rodalRepoblacion = data.ttGestion.rodalesRepoblacion[i];
                  if (rodalRepoblacion.turno == 1) {
                    tmpRodalesRepoblacion.push(rodalRepoblacion);
                  }
                }
                for (i = 0; i < tmpRodalesRepoblacion.length; i++) {
                  var rodalRepoblacion = tmpRodalesRepoblacion[i];
                  areaCompromiso = parseFloat(tmpRodalesRepoblacion[i].area).toFixed(4);
                  var areaMonto = areaCompromiso;
                  if (data.ttGestion.tcTipoGestion.tipoGestionId != 5 && data.ttGestion.tcTipoGestion.tipoGestionId != 7) {
                    for (j = 0; j < dataCompromiso.length; j++) {
                      var rodal = dataCompromiso[j];
                      if (rodal.correlativo == rodalRepoblacion.correlativo) {
                        areaMonto = parseFloat(rodal.areaGarantia).toFixed(4);
                        break;
                      }
                    }
                  }
                  tmpRodalesRepoblacion[i].areaMonto = tmpRodalesRepoblacion[i].area;
                  var montoGarantia = parseFloat(areaMonto) * montoUtilizar;
                  montoGarantia = parseFloat(montoGarantia).toFixed(2);
                  tmpRodalesRepoblacion[i].montoCompromiso = montoGarantia;
                  montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                  areaGarantia += parseFloat(areaMonto);
                  areaTotal = areaTotal + tmpRodalesRepoblacion[i].area;
                  var strEspecies = '';
                  for (j = 0; j < tmpRodalesRepoblacion[i].repoblacion.length; j++) {
                    if (j == 0) {
                      strEspecies = tmpRodalesRepoblacion[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + tmpRodalesRepoblacion[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    } else {
                      strEspecies += '\n' + tmpRodalesRepoblacion[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + tmpRodalesRepoblacion[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    }
                  }
                  item = [
                    { text: areaCompromiso, style: 'small', alignment: 'right' },
                    { text: strEspecies, style: 'small', alignment: 'left' },
                    { text: tipoGarantiaDesc, style: 'small', alignment: 'left' },
                    { text: areaMonto, style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpRodalesRepoblacion[i].montoCompromiso), style: 'small', alignment: 'right' }
                  ];
                  body.push(item);
                }
              } else {
                for (i = 0; i < dataCompromiso.length; i++) {
                  if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                    hayFueraBosque = true;
                    dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                  } else {
                    dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                  }
                  dataCompromiso[i].areaMonto = dataCompromiso[i].areaGarantia;
                  if (hayFueraBosque) {
                    calculoMonto = 'área basal ideal';
                  } else {
                    calculoMonto = 'área basal';
                  }
                  var areaCompromiso = parseFloat(dataCompromiso[i].areaCompromiso).toFixed(4);
                  dataCompromiso[i].areaCompromiso = areaCompromiso;
                  var montoGarantia = parseFloat(dataCompromiso[i].areaMonto) * montoUtilizar;
                  montoGarantia = parseFloat(montoGarantia).toFixed(2);
                  dataCompromiso[i].montoCompromiso = montoGarantia;
                  montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                  areaGarantia += parseFloat(dataCompromiso[i].areaMonto);
                  areaTotal += parseFloat(dataCompromiso[i].areaCompromiso);
                  var strEspecies = '';
                  for (j = 0; j < dataCompromiso[i].repoblacion.length; j++) {
                    if (j == 0) {
                      strEspecies = dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico;
                    } else {
                      strEspecies += '\n' + dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico;
                    }
                  }
                  item = [
                    { text: areaCompromiso, style: 'small', alignment: 'right' },
                    { text: strEspecies, style: 'small', alignment: 'left' },
                    { text: tipoGarantiaDesc, style: 'small', alignment: 'left' },
                    { text: dataCompromiso[i].areaMonto, style: 'small', alignment: 'left' },
                    { text: formatNumber.new(dataCompromiso[i].montoCompromiso), style: 'small', alignment: 'right' }
                  ];
                  body.push(item);
                }
              }
              montoTotal = parseFloat(montoTotal).toFixed(2);
              areaGarantia = parseFloat(areaGarantia).toFixed(4);
              areaTotal = parseFloat(areaTotal).toFixed(4);
              item = [
                { text: areaTotal, style: 'small', alignment: 'right' },
                { text: 'TOTAL', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: areaGarantia, style: 'small', alignment: 'right' },
                { text: formatNumber.new(montoTotal), style: 'small', alignment: 'right' }
              ];
              body.push(item);
              tbRepoblacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [60, 170, 90, 65, 70],
                  body: body
                }
              };
              if (areaGarantia == areaTotal && data.ttGestion.tcTipoGestion.tipoGestionId != 7) {
                calculoMonto = 'área por área';
              }
              if (!exentoGarantia) {
                textoGarantia = {
                  text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + areaGarantia + ' ha.',
                  style: 'izquierda', bold: true
                };
              }
            } else {
              body = [];
              var item = [
                { text: 'Área de compromiso', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
                { text: 'Tipo de garantía', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
                { text: 'Monto', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
              ];
              body.push(item);
              var totalAreaMonto = 0;
              dataCompromiso = rodales;
              for (i = 0; i < dataCompromiso.length; i++) {
                totalAreaMonto += dataCompromiso[i].areaGarantia;
              }
              var totalMonto = totalAreaMonto * appSettings.montoFondo;
              totalMonto = parseFloat(totalMonto).toFixed(2);
              item = [
                { text: parseFloat(totalAreaMonto).toFixed(4), style: 'small', alignment: 'center' },
                { text: tipoGarantiaDesc, style: 'small', alignment: 'center' },
                { text: 'Q. ' + formatNumber.new(totalMonto), style: 'small', alignment: 'center' }
              ];
              body.push(item);
              tbRepoblacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [120, 250, 100],
                  body: body
                }
              };
            }
            if (data.observaciones != null) {
              if (data.observaciones.length > 0) {
                if (exentoGarantia) {
                  textoVigencia = {
                    text: 'La obligación de repoblación forestal se evaluará de la siguiente manera:\n\n',
                    style: 'izquierda'
                  };
                } else {
                  textoVigencia = {
                    text: 'El compromiso debe garantizarse mediante ' + tipoGarantiaDesc
                      + ', con una vigencia mínima de cuatro años y la misma debe garantizar el ESTABLECIMIENTO Y TRES AÑOS DE MANTENIMIENTO, de acuerdo al cuadro siguiente:\n\n',
                    style: 'izquierda'
                  };
                }
                body = [];
                var item = [
                  { text: 'Etapa', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Vigencia', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
                  { text: 'Monto (Q.)', fillColor: '#CCCCCC', style: 'small', alignment: 'center' }
                ];
                body.push(item);
                var jsonValid = false;
                try {
                  if (data.observaciones.length > 0) {
                    if (data.observaciones[0].etapaDesc != null) {
                      jsonValid = true;
                    }
                  }
                } catch (e) {
                  data.observaciones = JSON.stringify(data.observaciones);
                }
                if (!jsonValid) {
                  try {
                    var obj = JSON.parse(data.observaciones);
                    data.observaciones = obj;
                    jsonValid = true;
                  } catch (e) {
                    jsonValid = false;
                  }
                }
                if (jsonValid) {
                  for (i = 0; i < data.observaciones.length; i++) {
                    var row = data.observaciones[i];
                    item = [
                      { text: row.etapaDesc, style: 'small', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: row.vigencia, style: 'small', alignment: 'left' },
                      { text: formatNumber.new(row.monto), style: 'small', alignment: 'right' }
                    ];
                    body.push(item);
                  }
                }
                tbVigencia = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 1,
                    widths: [150, 200, 120],
                    body: body
                  }
                };
              }
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nDICTAMEN DEL DIRECTOR SUBREGIONAL",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "DICTAMEN No.: " + data.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: "EXPEDIENTE No.: " + data.ttGestion.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.ttGestion.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia
                + " DE " + getMonth(mes).toUpperCase() + " DEL " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitante + ", solicita(n) aprobación de " + data.ttGestion.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Pase a: " + data.tcPersonaTraslado.personaDesc + ", Director Regional " + data.ttGestion.tcSubregion.tcRegion.regionDesc + ", el presente dictamen para su conocimiento y efectos procedentes:",
              style: 'izquierda'
            }, '\n',
            {
              text: "DICTAMEN",
              style: 'centrar'
            }, '\n',
            {
              text: texto,
              style: 'izquierda'
            }, '\n',
            textoReferencia, tbRepoblacion, textoGarantia, enter, textoVigencia, tbVigencia, enter, '\n',
            {
              text: "Habiendo sido objeto de análisis la petición planteada, esta Dirección Subregional considera que la solicitud de " + solicitante + " "
                + aprobado + " cumple los requisitos técnicos como legales, en virtud de lo anterior, el suscrito RECOMIENDA " + acceder
                + " a lo solicitado.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente pase al Director Regional " + data.tcPersonaTraslado.personaDesc + " el presente expediente para la resolución correspondiente.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Consta de _____ folios inclusive",
              style: 'izquierda'
            }, '\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\nNombre:_________________________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nFecha:____________ Firma: ______________',
              style: 'small', alignment: 'right'
            },
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = 'Codigo: LI-RE-015\nVersión: 2';
          if (data.aprobado == 0) {
            textoFooter = 'Codigo: LI-RE-016\nVersión: 2';
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 10,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaRegional: function (data, usuarioDesc, dataEmpresa, sigla, tareaId) {
          var nombreSalida = 'enmienda.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var body = [];
          var i;
          var tmpData = data.ttSeguimientoTarea.enmienda;
          if (tmpData.length > 0) {
            for (i = 0; i < tmpData.length; i++) {
              item = [
                { text: '', style: 'izquierda' },
                { text: (i + 1) + ".", style: 'izquierda' },
                { text: tmpData[i].descripcion, style: 'izquierda' }
              ];
              body.push(item);
            }
          } else {
            item = [
              { text: 'Ninguna', style: 'izquierda', colSpan: 3 }, {}, {}
            ];
            body.push(item);
          }
          var solicitante = "";
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          var tbEnmienda = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "OFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + data.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: fechaProvidencia.getDate() + " de " + getMonth(mes) + " del " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: data.ttGestion.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional " + data.ttGestion.tcSubregion.subregionDesc
                + "\nINAB, " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio le informo que luego de la revisión y análisis del expediente  administrativo No. " + data.ttGestion.expediente
                + " enviado a esta dirección regional con dictamen subregional No. " + data.ttPadre.ttSeguimientoTarea.numeroResolucion
                + " en seguimiento a la solicitud de licencia de aprovechamiento forestal presentada por " + solicitante + ", es necesario incorporar lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmienda, '\n',
            {
              text: "Por lo anterior, devuelvo el expediente administrativo No. " + data.ttGestion.expediente + " para que lo descrito anteriormente sea incorporado al mismo para poder emitir la resolución correspondiente.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: sigla + " " + data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Regional \n Región " + data.ttGestion.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: '\n\nNombre: ____________________________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nFecha:_____________ Firma: ________________',
              style: 'small', alignment: 'right'
            }, '\n',
            {
              text: 'cc \t Archivo \n// \t Expediente',
              style: 'small', alignment: 'left'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          if (tareaId != null) {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              Upload.upload({
                url: appSettings.urlServiceBase + 'file/upload',
                method: 'POST',
                file: blob,
                sendFieldsAs: 'form'
              }).then(function (resp) {
                var data = resp.data;
                if (data.status == 'OK') {
                  var obj = data.data[0];
                  var documento = {
                    tareaId: tareaId,
                    tipoDocumentoTareaId: 3,
                    rutaArchivo: obj.rutaArchivo,
                    reemplazar: 1
                  }
                  tareaService.agregarDocumento(documento).then(function (res) {
                    if (res.status != "OK") {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error(resp.data.message)
                }
              }, function (resp) {
                toastr.error('Error status: ' + resp.status);
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var avance = progressPercentage;
              });
            });
          } else {
            pdfMake.createPdf(docDefinition).download(nombreSalida);
          }
        },
        generarOpinionRegional: function (tarea, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla, tareaId) {
          var nombreSalida = 'licencia.pdf';
          if (tarea.aprobado == 0) {
            nombreSalida = 'resolucion.pdf';
          }
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i;
          var solicitante = "";
          var sinDpi = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + item.tcPersona.cui;
              sinDpi = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + item.tcPersona.cui;
              sinDpi += ", " + item.tcPersona.personaDesc;
            }
          }
          solicitante += " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          var parrafoUno = {};
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                textoPropietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                textoPropietario += ', ' + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ", acreditado mediante ";
              textoDireccion = textoDireccion + ". " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca = "Finca " + finca.tcFinca.fincaDesc + " ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "; acreditado mediante ";
              textoDireccion = finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
            var documentos = finca.documentos;
            if (documentos.length == null) {
              documentos = [];
            }
            var strDocumentos = '';
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += '; número ' + documentoFinca.numeroDocumento + ' de fecha ' + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy')
                      + ", autorizada en el municipio " + documentoFinca.tcMunicipioEmite.municipioDesc
                      + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + documentoFinca.notario;
                  }
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                }
                break;
              case 2:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de "
                      + documentoFinca.tcMunicipioEmite.municipioDesc + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                      + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + documentoFinca.notario;
                  }
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                }
                break;
              case 3:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; " + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                      + documentoFinca.numeroDocumento + ", folio " + documentoFinca.folio + ", libro " + documentoFinca.libro + " de " + documentoFinca.tcLibro.libroDesc;
                  }
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                    + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc + strDocumentos;
                } else {
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc;
                }
                break;
              case 4:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          var tipoGestionDesc = data.tcTipoGestion.tipoGestionDesc;
          tipoGestionDesc = tipoGestionDesc.replace('aprovechamiento', 'manejo');
          if (data.tcTipoGestion.tipoGestionId == 5) {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan de aprovechamiento con fines');
          } else {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan');
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          if (data.representantes.length > 0) {
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              }
            }
            representante = "Se tiene a la vista para resolver la solicitud de "
              + (tarea.aprobado == 0 ? (' implementación de ' + tipoGestionDesc) : 'admisión') + " del expediente administrativo No. " + data.expediente + " que contiene solicitud de "
              + data.tcTipoGestion.tipoGestionDesc + ", presentado por " + solicitante + ". Representado legalmente por " + representanteNombre + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            representante = "Se tiene a la vista para resolver la solicitud de " + (tarea.aprobado == 0 ? (' implementación de ' + tipoGestionDesc) : 'admisión') + " del expediente administrativo No. " + data.expediente + " que contiene solicitud de "
              + data.tcTipoGestion.tipoGestionDesc + ", presentado por " + solicitante;
          }
          representante = representante + ", para ejecutar el Plan de Manejo Forestal en el inmueble de su  " + textoDocumento + ": " + textoFinca + ". Para lo cual presentó Plan de Manejo Forestal elaborado por el Elaborador de Planes de Manejo Forestal, " + data.tcElaborador.personaDesc + ", inscrito en el Registro Nacional Forestal con No. " + data.tcElaborador.rfn;
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            sinDpi = data.ttTipoPropietarioGestion.razonSocial;
          }
          var textoNumero = {
            text: "LICENCIA FORESTAL No. " + tarea.ttSeguimientoTarea.numeroResolucion,
            style: 'derecha'
          };
          if (tarea.aprobado == 0) {
            textoNumero = {
              text: "Resolución No. " + tarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            };
          }
          var dictamenTecnico = 'favorable';
          var dictamenJuridico = 'favorable';
          if (tarea.aprobado == 0) {
            dictamenTecnico = 'desfavorable';
            dictamenJuridico = 'desfavorable';
          }
          if (tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.aprobado != null) {
            if (tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.aprobado == 1) {
              dictamenTecnico = 'favorable';
            } else {
              dictamenTecnico = 'desfavorable';
            }
          }
          if (tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.aprobado != null) {
            if (tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.aprobado == 1) {
              dictamenJuridico = 'favorable';
            } else {
              dictamenJuridico = 'desfavorable';
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n', textoNumero, '\n\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN REGIONAL " + data.tcSubregion.tcRegion.regionDesc + ", CON SEDE EN EL DEPARTAMENTO DE "
                + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDiaResolucion + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnioResolucion,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que el Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal, establece que el Instituto Nacional de Bosques –INAB-, es el órgano de dirección  y autoridad del Sector Publico Agrícola en materia forestal y que como tal es el único que puede otorgar, denegar, supervisar, prorrogar y cancelar Licencias Forestales que será la autorización para implementar el Plan de Manejo Forestal y que cualquier aprovechamiento forestal de madera u otros productos leñosos, excepto los de consumo familiar, los de plantaciones voluntarias y sistemas agroforestales plantados voluntariamente, podrá hacerse solamente mediante  Licencia  Forestal que el Instituto Nacional de Bosques, otorgará exclusivamente al propietario o poseedor legitimo del terreno o del área forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que la Resolución número JD 01.43.2005, de Junta Directiva del Instituto Nacional de Bosques,  Reglamento de la Ley Forestal,  faculta a los Directores Regionales para otorgar o denegar las Licencias Forestales para ejecutar los Planes de Manejo Forestal, en las Direcciones Regionales en las diferentes regiones forestales del país.",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, "\n",
            {
              text: "Que " + sinDpi + " presentó solicitud para la ejecución del Plan de Manejo Forestal, en inmueble de su " + textoDocumento
                + " y que el mismo fue debidamente analizado y verificado en campo por el técnico forestal " + tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.personaDesc
                + " de la Dirección Subregional " + data.tcSubregion.subregionDesc + ", según dictamen técnico " + dictamenTecnico + " No. " + tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.codigo + ", con fecha "
                + $filter('date')(new Date(tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.fechaFinalizacion), 'dd/MM/yyyy') + " y dictamen jurídico " + dictamenJuridico + " No. " + tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.codigo
                + " de fecha " + $filter('date')(new Date(tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.fechaFinalizacion), 'dd/MM/yyyy') + " emitida por el asesor jurídico " + tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.personaDesc
                + ", quienes dictaminaron acerca de su implementación. Ambos dictamenes fueron avalados por el director subregional "
                + tarea.ttPadre.tcPersonaAsignada.personaDesc + " según dictamen subregional No " + tarea.ttPadre.ttSeguimientoTarea.numeroResolucion + " de fecha " + $filter('date')(new Date(tarea.ttPadre.fechaFinalizacion), 'dd/MM/yyyy'),
              style: 'izquierda'
            }
          ];
          content.push('\n');
          content.push(
            {
              text: "POR TANTO",
              style: 'centrar'
            }
          );
          content.push('\n');
          if (tarea.aprobado == 1) {
            content.push(
              {
                text: "Con base  a lo considerado y en lo preceptuado por los artículos  28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y  6 del Decreto No. 119-96 del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo; 1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50, 51, 52, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 2, 3, 4, 6 y 8 del Decreto No. 122-96 del Congreso de la República de Guatemala, Ley Reguladora del Registro, Autorización y Uso de Motosierras; 1, 40, 41, 47, 48,  51, 52, 55 y  56 Resolución de Junta Directiva del Instituto Nacional de Bosques JD.01.43.2005, Reglamento de la Ley Forestal; 1, 2, 3, 4, 5, 6, 8, 10, 11, 13, 14, 15, 16, 17, 18, 20, 21 de la Resolución de Junta Directiva del Instituto Nacional de Bosques JD.03.05.2020, Reglamento para el Transporte de Productos Forestales",
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "RESUELVE",
                style: 'centrar'
              }
            );
            content.push('\n');
            var planes = 'Plan Operativo Anual';
            if (parseInt(data.ttResumenGestion.noTurnos) > 1) {
              planes = 'Planes Operativos Anuales';
            }
            content.push(
              {
                ol: [
                  {
                    text: "Aprobar la solicitud presentada por " + sinDpi + " así como el " + tipoGestionDesc + ", que constará de " + data.ttResumenGestion.noTurnos
                      + " " + planes + ". Para ser ejecutado en inmueble de su " + textoDocumento + ". " + textoFinca + ". De acuerdo al siguiente cuadro: ",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            var body = [
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Año de operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Volumen en m³', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                '', '', '', '', '',
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                ''
              ]
            ];
            tmpData = data.rodal;
            var valorTurno = 0;
            var total = 0;
            var totales = {
              area: 0,
              lenia: 0,
              troza: 0,
              total: 0,
              areaAprobada: 0
            }
            for (i = 0; i < tmpData.length; i++) {
              row = tmpData[i];
              var bodyEspecie = [];
              for (j = 0; j < row.especies.length; j++) {
                total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
                itemEspecie = [
                  { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                  { text: parseFloat(row.especies[j].troza).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
                  { text: parseFloat(row.especies[j].lenia).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
                  { text: parseFloat(total).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
                ]
                totales.lenia += parseFloat(row.especies[j].lenia);
                totales.troza += parseFloat(row.especies[j].troza);
                totales.total += total;
                bodyEspecie.push(itemEspecie);
                if (tmpData[i].turno == 1) {
                  if (row.especies[j].ttEspecieEvaluada == null) {
                    swal('El dictamen técnico está incompleto o no existe, favor de verificar');
                    return;
                  }
                  valor = parseFloat(row.especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(row.especies[j].ttEspecieEvaluada.leniaValor);
                  valor = parseFloat(valor).toFixed(2);
                  tmpData[i].especies[j].totalValor = valor;
                  valor = valor * 0.1;
                  valorTurno += valor;
                  valor = parseFloat(valor).toFixed(2);
                  tmpData[i].especies[j].totalPagar = valor;
                }
              }
              var tbEspecie = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [95, 50, 50, 50],
                  body: bodyEspecie
                }, colSpan: 4, layout: 'noBorders'
              }
              var area = null;
              if (data.ttPlanificacionGestion.hayReforestacion == 1) {
                area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
              } else {
                area = 'Pago al Fondo Forestal Privativo';
              }
              item = [
                { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
                { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
                { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
                { text: row.area, style: 'tableRowSmall', alignment: 'right' },
                tbEspecie, {}, {}, {},
                { text: area, style: 'tableRowSmall', alignment: 'right' }
              ];
              body.push(item);
              if (data.ttPlanificacionGestion.hayReforestacion == 1) {
                totales.area += area;
              }
              totales.areaAprobada += row.area;
            }
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              totales.area = parseFloat(totales.area).toFixed(4);
            } else {
              totales.area = '--';
            }
            body.push(
              [
                { text: 'TOTALES', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: parseFloat(totales.areaAprobada).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: parseFloat(totales.troza).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(totales.lenia).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(totales.total).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: totales.area, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            );
            valorTurno = parseFloat(valorTurno).toFixed(2);
            var tbCompromiso = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 40, 30, 40, 100, 50, 50, 50, 50],
                body: body
              }
            };
            content.push(tbCompromiso);
            content.push('\n');
            var textoCompromiso = {
              text: "La presente autorización de Plan de Manejo Forestal tendrá una vigencia de " + data.ttResumenGestion.anios
                + " años, a partir de la fecha de su notificación, de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento. ",
              style: 'izquierda'
            };
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              textoCompromiso = {
                text: "La presente autorización de Plan de Manejo Forestal tendrá una vigencia de " + data.ttResumenGestion.anios
                  + " años, a partir de la fecha de su notificación, para establecer y darle mantenimiento al compromiso de repoblación según el Plan de Manejo Forestal aprobado y de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento. ",
                style: 'izquierda'
              };
            }
            content.push(
              {
                type: "none",
                ul: [
                  {
                    text: [
                      textoCompromiso,
                      {
                        text: 'La fecha de vencimiento de dicho plan es: ' + $filter('date')(new Date(tarea.ttSeguimientoTarea.fundamento.fechaFin), 'dd/MM/yyyy'),
                        style: 'izquierda',
                        bold: true
                      }
                    ]
                  }
                ]
              }
            );
            content.push('\n');
            tmpData = data.rodal;
            body = [
              [
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            totales = {
              troza: 0,
              lenia: 0,
              volrExtraer: 0,
              trozaValor: 0,
              leniaValor: 0,
              totalValor: 0,
              totalPagar: 0
            }
            for (i = 0; i < tmpData.length; i++) {
              if (tmpData[i].turno == 1) {
                var bodyEspecie = [];
                for (j = 0; j < tmpData[i].especies.length; j++) {
                  tmpData[i].especies[j].troza = parseFloat(tmpData[i].especies[j].troza).toFixed(4);
                  tmpData[i].especies[j].lenia = parseFloat(tmpData[i].especies[j].lenia).toFixed(4);
                  tmpData[i].especies[j].volrExtraer = parseFloat(tmpData[i].especies[j].volrExtraer).toFixed(4);
                  itemEspecie = [
                    { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
                  ];
                  bodyEspecie.push(itemEspecie);
                  totales.troza += parseFloat(tmpData[i].especies[j].troza);
                  totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
                  totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
                  totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
                  totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
                  totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
                  totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
                }
                var tbEspecie = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [48, 45, 45, 45, 45, 45, 45, 45],
                    body: bodyEspecie
                  }, colSpan: 8, layout: 'noBorders'
                }
                item = [
                  { text: tmpData[i].turno, style: 'small', alignment: 'left' },
                  { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
                  tbEspecie, {}, {}, {}, {}, {}, {}, {}
                ];
                body.push(item);
              }
            }
            item = [
              { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
            ];
            body.push(item);
            var tbValorMadera = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 50, 45, 45, 45, 45, 45, 45, 45],
                body: body
              }
            };
            content.push(
              {
                start: 2,
                ol: [
                  {
                    text: sinDpi + " previo a formalizar la Resolución de aprobación del Plan Operativo Anual correspondiente al primer turno, se obliga a cancelar a favor del Instituto Nacional Bosques la cantidad de Q. " + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)) + " correspondiente al 10% del  valor  de la madera en pie, de acuerdo al cuadro siguiente:",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(tbValorMadera);
            content.push('\n');
            if (tarea.ttSeguimientoTarea.aprobado == 1) {
              content.push(
                {
                  type: "none",
                  ul: [
                    {
                      text: [
                        {
                          text: "Con base al artículo 87 de la Ley Forestal, inciso b: ",
                          style: 'izquierda'
                        },
                        {
                          text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                          style: 'izquierda',
                          italics: true,
                          bold: true
                        },
                        {
                          text: ", se exceptúa de este pago.",
                          style: 'izquierda'
                        }
                      ]
                    }
                  ]
                }
              );
              content.push('\n');
            }
            content.push(
              {
                text: "El Titular de la Licencia Forestal  podrá convertir el volumen autorizado de troza a madera aserrada, block o flitch, tablón, tabla y regla. Así mismo podrá convertir a carbón el volumen autorizado en leña, para lo cual deberá aplicar los porcentajes de eficiencia determinado en la normativa forestal vigente.",
                style: 'izquierda'
              }
            );
            content.push('\n');
            var dataTmp = data.sistemaRepoblacion;
            var strRepoblacion = "";
            var strRepoblacionComa = '';
            var longitud = dataTmp.length;
            longitud = longitud - 1;
            for (i = 0; i <= longitud; i++) {
              if (i == 0) {
                strRepoblacion = dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                strRepoblacionComa = dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
              } else {
                strRepoblacion = strRepoblacion + "\n" + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                if (i == longitud) {
                  strRepoblacionComa = strRepoblacionComa + " y " + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                } else {
                  strRepoblacionComa = strRepoblacionComa + ", " + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                }
              }
            }
            var tmpRepoblacion = [];
            var encontrado = false;
            var k, l;
            var garantizar = '';
            if (data.ttResumenGestion.tcTipoGarantia == null) {
              garantizar = ', lo que debe garantizar mediante la presente resolución';
            } else {
              garantizar = ', lo que debe garantizar mediante ' + data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
            }
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              content.push(
                {
                  start: 3,
                  ol: [
                    {
                      text: sinDpi + " está(n) obligado(s) a cumplir con los compromisos de recuperación del bosque, establecidos en el Plan de Manejo Forestal sujeto a la presente aprobación, optando por el sistema de repoblación: "
                        + strRepoblacionComa + garantizar + ", cuyo monto corresponde a la recuperación del área intervenida en el primer año de implementación del Plan de Manejo Forestal de acuerdo al cuadro siguiente:",
                      style: 'izquierda'
                    }
                  ]
                }
              );
              content.push('\n');
            } else {
              var pago = appSettings.montoFondo * data.area;
              pago = parseFloat(pago).toFixed(2);
              content.push(
                {
                  start: 3,
                  ol: [
                    {
                      text: sinDpi + " está(n) obligado(s) a cancelar la cantidad de Q. " + formatNumber.new(pago) + ' al pago al Fondo Forestal Privativo del INAB con un monto, de conformidad con lo que se establece en el artículo 46 de la Ley Forestal decreto 101-96',
                      style: 'izquierda'
                    }
                  ]
                }
              );
            }
            body = [
              [
                { text: 'Año de operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', alignment: 'center' },
                { text: 'Documento de garantía', style: 'tableHeaderSmall', alignment: 'center' },
                { text: 'Monto total del compromiso en Q.', style: 'tableHeaderSmall', alignment: 'center' }
              ]
            ];

            var tmpData = data.rodal;
            var dataCompromiso = [];
            //obtener datos del primer turno
            for (i = 0; i < tmpData.length; i++) {
              if (parseInt(tmpData[i].turno) == 1) {
                dataCompromiso.push(tmpData[i]);
              }
            }
            var abrTotal = 0, abrExtraerTotal = 0;
            for (i = 0; i < dataCompromiso.length; i++) {
              var abrExtraer = 0;
              var abr = 0;
              for (j = 0; j < dataCompromiso[i].especies.length; j++) {
                abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
              }
              dataCompromiso[i].metodoCalculoDesc = calculoMonto;
              dataCompromiso[i].abr = abr;
              dataCompromiso[i].abrExtraer = abrExtraer;
              abrTotal += abr;
              abrExtraerTotal += abrExtraer;
            }
            var porcentaje = 0;
            porcentaje = abrExtraerTotal * 100;
            porcentaje = porcentaje / abrTotal;
            porcentaje = Math.round(porcentaje);
            var montoUtilizar = tarea.ttSeguimientoTarea.fundamento.montoUtilizar;
            var areaMonto = 0;
            var montoCompromiso = 0;
            var volumenTotal = 0;
            var area = 0;
            var montoTotal = 0;
            var areaGarantia = 0;
            var areaTotal = 0;
            var hayFueraBosque = false;
            var hayReforestacion = true;
            var tipoGarantiaDesc = 'No se proporcionó una garantía';
            var calculoMonto = 'área por área';
            if (data.ttResumenGestion.tcTipoGarantia != null && data.ttPlanificacionGestion.hayReforestacion == 1) {
              tipoGarantiaDesc = data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
            } else {
              if (data.tcTipoGestion.tipoGestionId == 5) {
                tipoGarantiaDesc = 'Pago al Fondo Forestal Privativo';
                hayReforestacion = false;
              }
            }
            var tbRepoblacion = {};
            if (hayReforestacion) {
              totalAreaCompromiso = 0;
              if (data.ttResumenGestion.tcTipoGarantia == null) {
                tipoGarantiaDesc = 'Exento de garantía';
                montoUtilizar = 0;
              } else {
                tipoGarantiaDesc = data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
              }
              if (data.tcTipoGestion.tipoGestionId == 5) {
                dataCompromiso = data.rodalesRepoblacion;
                for (i = 0; i < dataCompromiso.length; i++) {
                  areaCompromiso = parseFloat(dataCompromiso[i].area);
                  dataCompromiso[i].areaMonto = dataCompromiso[i].areaCompromiso;
                  var montoGarantia = parseFloat(dataCompromiso[i].area) * montoUtilizar;
                  montoGarantia = parseFloat(montoGarantia).toFixed(2);
                  dataCompromiso[i].montoCompromiso = montoGarantia;
                  montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                  areaGarantia += parseFloat(dataCompromiso[i].area);
                  totalAreaCompromiso += areaCompromiso;
                  areaTotal = areaTotal + dataCompromiso[i].areaGarantia;
                  var strEspecies = '';
                  for (j = 0; j < dataCompromiso[i].repoblacion.length; j++) {
                    if (j == 0) {
                      strEspecies = dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    } else {
                      strEspecies += '\n' + dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    }
                  }
                  item = [
                    { text: dataCompromiso[i].anio, style: 'tableRowSmall', alignment: 'left' },
                    { text: areaCompromiso, style: 'tableRowSmall', alignment: 'right' },
                    { text: strEspecies, style: 'tableRowSmall', alignment: 'left' },
                    { text: tipoGarantiaDesc, style: 'tableRowSmall', alignment: 'left' },
                    { text: formatNumber.new(dataCompromiso[i].montoCompromiso), style: 'tableRowSmall', alignment: 'right' }
                  ];
                  body.push(item);
                }
              } else {
                for (i = 0; i < dataCompromiso.length; i++) {
                  dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                  areaCompromiso = parseFloat(dataCompromiso[i].area);
                  if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                    hayFueraBosque = true;
                    calculoMonto = 'área basal ideal';
                    dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                    areaCompromiso = parseFloat(dataCompromiso[i].areaGarantia);
                  }
                  dataCompromiso[i].areaMonto = dataCompromiso[i].areaGarantia;
                  if (!hayFueraBosque) {
                    calculoMonto = 'área basal';
                  }
                  var montoGarantia = parseFloat(dataCompromiso[i].areaGarantia) * montoUtilizar;
                  montoGarantia = parseFloat(montoGarantia).toFixed(2);
                  dataCompromiso[i].montoCompromiso = montoGarantia;
                  montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                  areaGarantia += parseFloat(dataCompromiso[i].areaGarantia);
                  totalAreaCompromiso += areaCompromiso;
                  areaTotal = areaTotal + dataCompromiso[i].areaGarantia;
                  var strEspecies = '';
                  for (j = 0; j < dataCompromiso[i].repoblacion.length; j++) {
                    if (j == 0) {
                      strEspecies = dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    } else {
                      strEspecies += '\n' + dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    }
                  }
                  item = [
                    { text: dataCompromiso[i].anio, style: 'tableRowSmall', alignment: 'left' },
                    { text: areaCompromiso, style: 'tableRowSmall', alignment: 'right' },
                    { text: strEspecies, style: 'tableRowSmall', alignment: 'left' },
                    { text: tipoGarantiaDesc, style: 'tableRowSmall', alignment: 'left' },
                    { text: formatNumber.new(dataCompromiso[i].montoCompromiso), style: 'tableRowSmall', alignment: 'right' }
                  ];
                  body.push(item);
                }
              }
              montoTotal = parseFloat(montoTotal).toFixed(2);
              areaGarantia = parseFloat(areaGarantia).toFixed(4);
              areaTotal = parseFloat(areaTotal).toFixed(4);
              totalAreaCompromiso = parseFloat(totalAreaCompromiso).toFixed(4);
              item = [
                { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: totalAreaCompromiso, style: 'tableRowSmall', alignment: 'right' },
                { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: formatNumber.new(montoTotal), style: 'tableRowSmall', alignment: 'right' }
              ];
              body.push(item);
              tbRepoblacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [50, 50, 150, 120, 100],
                  body: body
                }
              };
              if (areaGarantia == totalAreaCompromiso && data.tcTipoGestion.tipoGestionId != 7) {
                calculoMonto = 'área por área';
              }
              content.push(tbRepoblacion);
              content.push(
                {
                  text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + areaGarantia + ' ha.',
                  style: 'izquierdaPequenio', bold: true
                }
              );
            }
            content.push('\n');
            content.push(
              {
                start: 4,
                ol: [
                  {
                    text: "Previo a otorgar la Resolución de aprobación del Plan Operativo Anual, el Titular de la Licencia Forestal se compromete a presentar el documento de pago del 10% del valor de la madera en pie, el documento de garantía y presentar por escrito la propuesta del Regente Forestal inscrito y activo en el Registro Nacional Forestal, siempre y cuando el volumen a extraer sea igual o mayor a 100 metros cúbicos. Siendo el regente responsable en dirigir y verificar el cumplimiento de las actividades contempladas en el Plan de Manejo Forestal presentado y aprobado, incluyendo la información sobre el uso de las Notas de Envío de bosque. Adicionalmente debe presentar constancia de inscripción ante el Registro Nacional Forestal, de las motosierras que se utilizarán en el aprovechamiento forestal.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 5,
                ol: [
                  {
                    text: "El ó la Titular de la Licencia Forestal y el Regente Forestal quedan obligados a cumplir con lo preceptuado en el Reglamento para el Transporte de Productos Forestales y su Procedencia Lícita y demás normas vigentes. Previo a iniciar las operaciones del aprovechamiento forestal, debe delimitar claramente el área a intervenir para el turno de operaciones y respetar los límites de dicha área de conformidad con el Plan de Manejo Forestal aprobado.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 6,
                ol: [
                  {
                    text: "El Instituto Nacional de Bosques mediante la Dirección Subregional " + data.tcSubregion.subregionDesc + " ubicada en el municipio de " + data.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", se encargará del monitoreo y supervisión de la ejecución del Plan de Manejo Forestal.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 7,
                ol: [
                  {
                    text: "Una vez pagado el derecho de corta correspondiente para el turno de operaciones y cumplidos los requisitos anteriores,  extiéndase la Resolución de aprobación del Plan Operativo Anual respectivo.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 8,
                ol: [
                  {
                    text: "Cualquier modificación, anomalía o incumplimiento que se detecte en la ejecución del Plan de Manejo Forestal sin la aprobación por parte del Instituto Nacional de Bosques, así como el mal uso que se le pueda dar a la Resolución de aprobación del Plan Operativo Anual, Notas de Envío de Bosque, o cualquier incumplimiento de la presente Licencia Forestal, será motivo para iniciar el trámite de Cancelación de la misma y presentar la denuncia ante el órgano Competente.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 9,
                ol: [
                  {
                    text: "La presente Licencia Forestal tendrá efecto a partir de cumplidos los requisitos anteriores; ningún aprovechamiento podrá realizarse hasta no haber sido aprobado el respectivo Plan Operativo",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 10,
                ol: [
                  {
                    text: "En caso de que dicho inmueble sea vendido o transferido a otra persona está también adquiere los derechos y obligaciones de la Licencia Forestal según artículo 49 párrafo tercero de la Ley Forestal; así mismo el propietario deberá informar por medio de un informe circunstanciado al INAB dentro de los 15 días hábiles de realizada la transacción o la suscripción del documento en el cual se transfiere la propiedad según artículo 42 del Reglamento de la Ley Forestal.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 11,
                ol: [
                  {
                    text: "Notifíquese  al interesado y a la Municipalidad de " + data.fincas[0].tcFinca.tcMunicipio.municipioDesc + ", departamento de " + data.fincas[0].tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ".",
                    style: 'izquierda'
                  }
                ]
              }
            );
          } else { //si no se aprueba
            content.push(
              {
                text: "Con base  a lo considerado y en lo preceptuado por los artículos  28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y  6 del Decreto No. 119-96  del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo;  1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50, 51, 52, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 40, 41, 47, 48,  51, 52, 55 y  56 Resolución de Junta Directiva del Instituto Nacional de Bosques No.JD.01.43.2005 Reglamento de la Ley Forestal.",
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "RESUELVE",
                style: 'centrar'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "Denegar  la solicitud presentada por " + sinDpi + " así como el " + tipoGestionDesc + ", para ser ejecutado en inmueble de su " + textoDocumento + ": " + textoFinca + ". Por las siguientes razones: ",
                style: 'izquierda'
              }
            );
            content.push('\n');
            var body = [];
            var tmpData = tarea.ttSeguimientoTarea.fundamento;
            for (i = 0; i < tmpData.length; i++) {
              item = [
                { text: '' },
                { text: (i + 1), style: 'izquierda' },
                { text: tmpData[i].descripcion, style: 'izquierda' }
              ];
              body.push(item);
            }
            var tbFundamento = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [20, 20, 450],
                body: body
              }, layout: 'noBorders'
            };
            content.push(tbFundamento);
            content.push('\n');
            content.push(
              {
                text: "Notifíquese al interesado.",
                style: 'izquierda'
              }
            );
          }
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + ' ' + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director Regional ",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Región " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }
          );
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11.5,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11.5,
                alignment: 'justify'
              },
              izquierdaPequenio: {
                fontSize: 9,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          if (tareaId != null) {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              Upload.upload({
                url: appSettings.urlServiceBase + 'file/upload',
                method: 'POST',
                file: blob,
                sendFieldsAs: 'form'
              }).then(function (resp) {
                var data = resp.data;
                if (data.status == 'OK') {
                  var obj = data.data[0];
                  var documento = {
                    tareaId: tareaId,
                    tipoDocumentoTareaId: 1,
                    rutaArchivo: obj.rutaArchivo,
                    reemplazar: 1
                  }
                  tareaService.agregarDocumento(documento).then(function (res) {
                    if (res.status != "OK") {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error(resp.data.message)
                }
              }, function (resp) {
                toastr.error('Error status: ' + resp.status);
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var avance = progressPercentage;
              });
            });
          } else {
            pdfMake.createPdf(docDefinition).download(nombreSalida);
          }
        },
        generarResolucionAprovechamiento: function (tarea, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla, tareaId) {
          var nombreSalida = 'resolucion.pdf';
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i;
          var solicitante = "";
          var sinDpi = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + item.tcPersona.cui;
              sinDpi = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + item.tcPersona.cui;
              sinDpi += ", " + item.tcPersona.personaDesc;
            }
          }
          solicitante += " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          var parrafoUno = {};
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                textoPropietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                textoPropietario += ', ' + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ", acreditado mediante ";
              textoDireccion = textoDireccion + ". " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca = "Finca " + finca.tcFinca.fincaDesc + " ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "; acreditado mediante ";
              textoDireccion = finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
            var documentos = finca.documentos;
            if (documentos.length == null) {
              documentos = [];
            }
            var strDocumentos = '';
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += '; número ' + documentoFinca.numeroDocumento + ' de fecha ' + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy')
                      + ", autorizada en el municipio " + documentoFinca.tcMunicipioEmite.municipioDesc
                      + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + documentoFinca.notario;
                  }
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                }
                break;
              case 2:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + documentoFinca.tcMunicipioEmite.municipioDesc
                      + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                      + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                  }
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                }
                break;
              case 3:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; " + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                      + documentoFinca.numeroDocumento + ", folio " + documentoFinca.folio + ", libro " + documentoFinca.libro + " de " + documentoFinca.tcLibro.libroDesc;
                  }
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc + strDocumentos;
                } else {
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc;
                }
                break;
              case 4:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          var tipoGestionDesc = data.tcTipoGestion.tipoGestionDesc;
          tipoGestionDesc = tipoGestionDesc.replace('aprovechamiento', 'manejo');
          if (data.tcTipoGestion.tipoGestionId == 5) {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan de aprovechamiento con fines');
          } else {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan');
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          if (data.representantes.length > 0) {
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              }
            }
            representante = "Se tiene a la vista para resolver la solicitud de "
              + (tarea.aprobado == 0 ? (' implementación de ' + tipoGestionDesc) : 'admisión') + " del expediente administrativo No. " + data.expediente + " que contiene solicitud de "
              + data.tcTipoGestion.tipoGestionDesc + ", presentado por " + solicitante + ". Representado legalmente por " + representanteNombre + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            representante = "Se tiene a la vista para resolver la solicitud de " + (tarea.aprobado == 0 ? (' implementación de ' + tipoGestionDesc) : 'admisión') + " del expediente administrativo No. " + data.expediente + " que contiene solicitud de "
              + data.tcTipoGestion.tipoGestionDesc + ", presentado por " + solicitante;
          }
          representante = representante + ", para ejecutar el Plan de Manejo Forestal en el inmueble de su  " + textoDocumento + ": " + textoFinca + ". Para lo cual presentó Plan de Manejo Forestal elaborado por el Elaborador de Planes de Manejo Forestal, " + data.tcElaborador.personaDesc + ", inscrito en el Registro Nacional Forestal con No. " + data.tcElaborador.rfn;
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            sinDpi = data.ttTipoPropietarioGestion.razonSocial;
          }
          var textoNumero = {
            text: "RESOLUCIÓN No. " + tarea.ttSeguimientoTarea.numeroResolucion,
            style: 'derecha'
          };
          var dictamenTecnico = 'favorable';
          var dictamenJuridico = 'favorable';
          if (tarea.aprobado == 0) {
            dictamenTecnico = 'desfavorable';
            dictamenJuridico = 'desfavorable';
          }
          if (tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.aprobado != null) {
            if (tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.aprobado == 1) {
              dictamenTecnico = 'favorable';
            } else {
              dictamenTecnico = 'desfavorable';
            }
          }
          if (tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.aprobado != null) {
            if (tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.aprobado == 1) {
              dictamenJuridico = 'favorable';
            } else {
              dictamenJuridico = 'desfavorable';
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: 'RESOLUCIÓN DE APROBACIÓN DEL PLAN DE MANEJO FORESTAL',
              style: 'centrar'
            }, '\n', textoNumero, '\n\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN REGIONAL " + data.tcSubregion.tcRegion.regionDesc + ", CON SEDE EN EL DEPARTAMENTO DE "
                + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDiaResolucion + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnioResolucion,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que el Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal, establece que el Instituto Nacional de Bosques –INAB-, es el órgano de dirección y autoridad del Sector Publico Agrícola en materia forestal y en su artículo cuarenta y ocho establece que el aprovechamiento y manejo sostenido del bosque estará dirigido mediante el Plan de Manejo aprobado por el INAB, el cual es un instrumento fundamental en el monitoreo del aprovechamiento y de las técnicas silviculturales aplicadas a la masa forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que la Resolución número JD 01.43.2005, de Junta Directiva del Instituto Nacional de Bosques, Reglamento de la Ley Forestal, faculta a los Directores Regionales para aprobar o denegar los Planes de Manejo Forestal, en las diferentes Direcciones Regionales del país, así mismo establece que el Plan de Manejo Forestal debe ser aprobado para poder extender al usuario la " + data.tcTipoGestion.tipoGestionDesc,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, "\n",
            {
              text: "Que " + sinDpi + " presentó solicitud para la ejecución del Plan de Manejo Forestal, en inmueble de su " + textoDocumento
                + " y que el mismo fue debidamente analizado y verificado en campo por el técnico forestal "
                + tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.personaDesc
                + " de la Dirección Subregional " + data.tcSubregion.subregionDesc + ", según dictamen técnico " + dictamenTecnico + " No. " + tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.codigo + ", con fecha "
                + $filter('date')(new Date(tarea.ttPadre.ttSeguimientoTarea.fundamento.tecnico.fechaFinalizacion), 'dd/MM/yyyy') + " y dictamen jurídico " + dictamenJuridico + " No. " + tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.codigo
                + " de fecha " + $filter('date')(new Date(tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.fechaFinalizacion), 'dd/MM/yyyy') + " emitida por el asesor jurídico " + tarea.ttPadre.ttSeguimientoTarea.fundamento.juridico.personaDesc
                + ", quienes dictaminaron acerca de su implementación. Ambos dictamenes fueron avalados por el director subregional "
                + tarea.ttPadre.tcPersonaAsignada.personaDesc + " según dictamen subregional No " + tarea.ttPadre.ttSeguimientoTarea.numeroResolucion + " de fecha " + $filter('date')(new Date(tarea.ttPadre.fechaFinalizacion), 'dd/MM/yyyy'),
              style: 'izquierda'
            }
          ];
          content.push('\n');
          content.push(
            {
              text: "POR TANTO",
              style: 'centrar'
            }
          );
          content.push('\n');
          if (tarea.aprobado == 1) {
            content.push(
              {
                text: "Con base  a lo considerado y en lo preceptuado por los artículos  28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y  6 del Decreto No. 119-96 del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo; 1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50, 51, 52, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 2, 3, 4, 6 y 8 del Decreto No. 122-96 del Congreso de la República de Guatemala, Ley Reguladora del Registro, Autorización y Uso de Motosierras; 1, 40, 41, 47, 48,  51, 52, 55 y  56 Resolución de Junta Directiva del Instituto Nacional de Bosques JD.01.43.2005, Reglamento de la Ley Forestal; 1, 2, 3, 4, 5, 6, 8, 10, 11, 13, 14, 15, 16, 17, 18, 20, 21 de la Resolución de Junta Directiva del Instituto Nacional de Bosques JD.03.05.2020, Reglamento para el Transporte de Productos Forestales",
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "RESUELVE",
                style: 'centrar'
              }
            );
            content.push('\n');
            var planes = 'Plan Operativo Anual';
            if (parseInt(data.ttResumenGestion.noTurnos) > 1) {
              planes = 'Planes Operativos Anuales';
            }
            content.push(
              {
                ol: [
                  {
                    text: "Aprobar el Plan de Manejo Forestal presentado por " + sinDpi + ", que constará de "
                      + data.ttResumenGestion.noTurnos + " turno(s) " + ". Para ser ejecutado en inmueble de su " + textoDocumento + ". "
                      + textoFinca + ", de acuerdo al siguiente cuadro: ",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            var body = [
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Año de operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Volumen en m³', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                '', '', '', '', '',
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                ''
              ]
            ];
            tmpData = data.rodal;
            var valorTurno = 0;
            var total = 0;
            var totales = {
              area: 0,
              lenia: 0,
              troza: 0,
              total: 0,
              areaAprobada: 0
            }
            for (i = 0; i < tmpData.length; i++) {
              row = tmpData[i];
              row.area = parseFloat(row.area).toFixed(4);
              var bodyEspecie = [];
              for (j = 0; j < row.especies.length; j++) {
                row.especies[j].troza = parseFloat(row.especies[j].troza).toFixed(4);
                row.especies[j].lenia = parseFloat(row.especies[j].lenia).toFixed(4);
                total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
                itemEspecie = [
                  { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                  { text: row.especies[j].troza, style: 'tableRowSmall', alignment: 'right' },
                  { text: row.especies[j].lenia, style: 'tableRowSmall', alignment: 'right' },
                  { text: parseFloat(total).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
                ]
                totales.lenia += parseFloat(row.especies[j].lenia);
                totales.troza += parseFloat(row.especies[j].troza);
                totales.total += total;
                bodyEspecie.push(itemEspecie);
                if (parseInt(row.turno) == 1) {
                  if (row.especies[j].ttEspecieEvaluada == null) {
                    swal('El dictamen técnico está incompleto o no existe, favor de verificar');
                    return;
                  }
                  valor = parseFloat(row.especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(row.especies[j].ttEspecieEvaluada.leniaValor);
                  valor = parseFloat(valor).toFixed(2);
                  row.especies[j].totalValor = valor;
                  if (row.valorMadera) {
                    valor = valor * 0.1;
                    valor = parseFloat(valor).toFixed(2);
                  } else {
                    valor = 0;
                  }
                  row.especies[j].totalPagar = valor;
                }
              }
              var tbEspecie = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [95, 50, 50, 50],
                  body: bodyEspecie
                }, colSpan: 4, layout: 'noBorders'
              }
              var area = null;
              if (data.ttPlanificacionGestion.hayReforestacion == 1) {
                area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
                area = parseFloat(area).toFixed(4);
              } else {
                area = 'Pago al Fondo Forestal Privativo';
              }
              item = [
                { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
                { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
                { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
                { text: row.area, style: 'tableRowSmall', alignment: 'right' },
                tbEspecie, {}, {}, {},
                { text: area, style: 'tableRowSmall', alignment: 'right' }
              ];
              body.push(item);
              if (data.ttPlanificacionGestion.hayReforestacion == 1) {
                totales.area += parseFloat(area);
              }
              totales.areaAprobada += parseFloat(row.area);
            }
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              totales.area = parseFloat(totales.area).toFixed(4);
            } else {
              totales.area = '--';
            }
            body.push(
              [
                { text: 'TOTALES', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: parseFloat(totales.areaAprobada).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: parseFloat(totales.troza).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(totales.lenia).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(totales.total).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
                { text: totales.area, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            );
            valorTurno = parseFloat(valorTurno).toFixed(2);
            var tbCompromiso = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 40, 40, 100, 50, 50, 50, 50],
                body: body
              }
            };
            content.push(tbCompromiso);
            content.push('\n');
            var textoCompromiso = {
              text: "La presente autorización de Plan de Manejo Forestal tendrá una vigencia de " + data.ttResumenGestion.anios
                + " años, a partir de la fecha de su notificación, de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento. ",
              style: 'izquierda'
            };
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              textoCompromiso = {
                text: "La presente autorización de Plan de Manejo Forestal tendrá una vigencia de " + data.ttResumenGestion.anios
                  + " años, a partir de la fecha de su notificación, para establecer y darle mantenimiento al compromiso de repoblación según el Plan de Manejo Forestal aprobado y de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento. ",
                style: 'izquierda'
              };
            }
            content.push(
              {
                type: "none",
                ul: [
                  {
                    text: [
                      textoCompromiso,
                      {
                        text: 'La fecha de vencimiento de dicho plan es: ' + $filter('date')(new Date(tarea.ttSeguimientoTarea.fundamento.fechaFin), 'dd/MM/yyyy'),
                        style: 'izquierda',
                        bold: true
                      }
                    ]
                  }
                ]
              }
            );
            content.push('\n');
            tmpData = data.rodal;
            body = [
              [
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
                { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            totales = {
              troza: 0,
              lenia: 0,
              volrExtraer: 0,
              trozaValor: 0,
              leniaValor: 0,
              totalValor: 0,
              totalPagar: 0
            }
            var totalRodalesTurno = 0, totalRodalesValorMadera = 0;
            var strRodales = '';
            for (i = 0; i < tmpData.length; i++) {
              if (tmpData[i].turno == 1) {
                totalRodalesTurno++;
                if (!tmpData[i].valorMadera) {
                  if (totalRodalesValorMadera == 0) {
                    strRodales = tmpData[i].correlativo;
                  } else {
                    strRodales += ', ' + tmpData[i].correlativo;
                  }
                  totalRodalesValorMadera++;
                }
                var bodyEspecie = [];
                for (j = 0; j < tmpData[i].especies.length; j++) {
                  tmpData[i].especies[j].totalPagar = parseFloat(tmpData[i].especies[j].totalPagar).toFixed(2);
                  tmpData[i].especies[j].troza = parseFloat(tmpData[i].especies[j].troza).toFixed(4);
                  tmpData[i].especies[j].lenia = parseFloat(tmpData[i].especies[j].lenia).toFixed(4);
                  tmpData[i].especies[j].volrExtraer = parseFloat(tmpData[i].especies[j].volrExtraer).toFixed(4);
                  tmpData[i].especies[j].totalValor = parseFloat(tmpData[i].especies[j].totalValor).toFixed(2);
                  itemEspecie = [
                    { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                    { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                    { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
                  ];
                  bodyEspecie.push(itemEspecie);
                  totales.troza += parseFloat(tmpData[i].especies[j].troza);
                  totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
                  totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
                  totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
                  totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
                  totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
                  if (tmpData[i].valorMadera) {
                    totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
                  }
                }
                var tbEspecie = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [48, 45, 45, 45, 45, 45, 45, 45],
                    body: bodyEspecie
                  }, colSpan: 8, layout: 'noBorders'
                }
                item = [
                  { text: tmpData[i].turno, style: 'small', alignment: 'left' },
                  { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
                  tbEspecie, {}, {}, {}, {}, {}, {}, {}
                ];
                body.push(item);
              }
            }
            item = [
              { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
              { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
              { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
            ];
            body.push(item);
            var tbValorMadera = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 50, 45, 45, 45, 45, 45, 45, 45],
                body: body
              }
            };
            content.push(
              {
                start: 2,
                ol: [
                  {
                    text: sinDpi + " previo a otorgarle la " + data.tcTipoGestion.tipoGestionDesc + " a cada turno de corta. Para el primer turno de corta, se obliga a cancelar a favor del Instituto Nacional Bosques la cantidad de Q. "
                      + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2))
                      + " correspondiente al 10% del valor de la madera en pie, de acuerdo al cuadro siguiente:",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(tbValorMadera);
            content.push('\n');
            var exonerarValorMadera = false;
            var textoExonerar = {
              type: "none",
              ul: [
                {
                  text: [
                    {
                      text: "De acuerdo con el artículo 87 de la Ley Forestal, inciso b: ",
                      style: 'izquierda'
                    },
                    {
                      text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                      style: 'izquierda',
                      italics: true,
                      bold: true
                    },
                    {
                      text: ", se exceptúa de este pago.",
                      style: 'izquierda'
                    }
                  ]
                }
              ]
            };
            if (tarea.ttSeguimientoTarea.aprobado == 1) {
              exonerarValorMadera = true;
            } else {
              if (totalRodalesTurno > totalRodalesValorMadera && totalRodalesValorMadera > 0) {
                exonerarValorMadera = true;
                textoExonerar = {
                  type: "none",
                  ul: [
                    {
                      text: [
                        {
                          text: "De acuerdo con el artículo 87 de la Ley Forestal, inciso b: ",
                          style: 'izquierda'
                        },
                        {
                          text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                          style: 'izquierda',
                          italics: true,
                          bold: true
                        },
                        {
                          text: ', se exceptúa de este pago a los rodales: ' + strRodales + '.',
                          style: 'izquierda'
                        }
                      ]
                    }
                  ]
                };
              }
            }
            if (exonerarValorMadera) {
              content.push(textoExonerar);
              content.push('\n');
            }
            content.push(
              {
                text: "Para los turnos subsiguientes, el cálculo del pago del 10 % del valor de la madera en pie se definirá por medio de la actualización anual, según el artículo 87 del Decreto Legislativo No. 101-96 Ley Forestal.",
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            var dataTmp = data.sistemaRepoblacion;
            var strRepoblacion = "";
            var strRepoblacionComa = '';
            var longitud = dataTmp.length;
            longitud = longitud - 1;
            for (i = 0; i <= longitud; i++) {
              if (i == 0) {
                strRepoblacion = dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                strRepoblacionComa = dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
              } else {
                strRepoblacion = strRepoblacion + "\n" + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                if (i == longitud) {
                  strRepoblacionComa = strRepoblacionComa + " y " + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                } else {
                  strRepoblacionComa = strRepoblacionComa + ", " + dataTmp[i].tcSistemaRepoblacion.sistemaRepoblacionDesc;
                }
              }
            }
            var tmpRepoblacion = [];
            var encontrado = false;
            var k, l;
            var garantizar = '';
            if (data.ttResumenGestion.tcTipoGarantia == null) {
              garantizar = ', lo que debe garantizar mediante la presente resolución';
            } else {
              garantizar = ', lo que debe garantizar mediante ' + data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
            }
            var tmpData = data.rodal;
            var dataCompromiso = [];
            var areaPagar = 0;
            //obtener datos del primer turno
            for (i = 0; i < tmpData.length; i++) {
              if (parseInt(tmpData[i].turno) == 1) {
                dataCompromiso.push(tmpData[i]);
                areaPagar += parseFloat(tmpData[i].area);
              }
            }
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              content.push(
                {
                  start: 3,
                  ol: [
                    {
                      text: sinDpi + " está(n) obligado(s) a cumplir con los compromisos de recuperación del bosque, establecidos en el Plan de Manejo Forestal sujeto a la presente aprobación, optando por el sistema de repoblación: "
                        + strRepoblacionComa + garantizar + ", cuyo monto corresponde a la recuperación del área intervenida del primer turno de implementación del Plan de Manejo Forestal de acuerdo al cuadro siguiente:",
                      style: 'izquierda'
                    }
                  ]
                }
              );
              content.push('\n');
            } else {
              var pago = appSettings.montoFondo * areaPagar;
              pago = parseFloat(pago).toFixed(2);
              content.push(
                {
                  start: 3,
                  ol: [
                    {
                      text: sinDpi + " está(n) obligado(s) a cancelar para el primer turno la cantidad de Q. " + formatNumber.new(pago) + ' al pago al Fondo Forestal Privativo del INAB con un monto de conformidad con lo que se establece en el artículo 46 de la Ley Forestal decreto 101-96',
                      style: 'izquierda'
                    }
                  ]
                }
              );
            }
            body = [
              [
                { text: 'Año de plantación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'tableHeaderSmall', alignment: 'center' },
                { text: 'Documento de garantía', style: 'tableHeaderSmall', alignment: 'center' },
                { text: 'Monto total del compromiso en Q.', style: 'tableHeaderSmall', alignment: 'center' }
              ]
            ];
            var abrTotal = 0, abrExtraerTotal = 0;
            for (i = 0; i < dataCompromiso.length; i++) {
              var abrExtraer = 0;
              var abr = 0;
              for (j = 0; j < dataCompromiso[i].especies.length; j++) {
                abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
              }
              dataCompromiso[i].metodoCalculoDesc = calculoMonto;
              dataCompromiso[i].abr = abr;
              dataCompromiso[i].abrExtraer = abrExtraer;
              abrTotal += abr;
              abrExtraerTotal += abrExtraer;
            }
            var porcentaje = 0;
            porcentaje = abrExtraerTotal * 100;
            porcentaje = porcentaje / abrTotal;
            porcentaje = Math.round(porcentaje);
            var montoUtilizar = tarea.ttSeguimientoTarea.fundamento.montoUtilizar;
            var areaMonto = 0;
            var montoCompromiso = 0;
            var volumenTotal = 0;
            var area = 0;
            var montoTotal = 0;
            var areaGarantia = 0;
            var areaTotal = 0;
            var hayFueraBosque = false;
            var hayReforestacion = true;
            var tipoGarantiaDesc = 'No se proporcionó una garantía';
            var calculoMonto = 'área por área';
            if (data.ttResumenGestion.tcTipoGarantia != null && data.ttPlanificacionGestion.hayReforestacion == 1) {
              tipoGarantiaDesc = data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
            } else {
              if (data.tcTipoGestion.tipoGestionId == 5) {
                tipoGarantiaDesc = 'Pago al Fondo Forestal Privativo';
                hayReforestacion = false;
              }
            }
            var tbRepoblacion = {};
            if (hayReforestacion) {
              totalAreaCompromiso = 0;
              if (data.ttResumenGestion.tcTipoGarantia == null) {
                tipoGarantiaDesc = 'Exento de garantía';
                montoUtilizar = 0;
              } else {
                tipoGarantiaDesc = data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc;
              }
              if (data.tcTipoGestion.tipoGestionId == 5) {
                var tmpDataRepoblacion = data.rodalesRepoblacion;
                dataCompromiso = [];
                for (i = 0; i < tmpDataRepoblacion.length; i++) {
                  var rodalRepoblacion = tmpDataRepoblacion[i];
                  if (rodalRepoblacion.turno == 1) {
                    dataCompromiso.push(rodalRepoblacion);
                  }
                }
                for (i = 0; i < dataCompromiso.length; i++) {
                  areaCompromiso = parseFloat(dataCompromiso[i].area).toFixed(4);
                  dataCompromiso[i].areaMonto = dataCompromiso[i].areaCompromiso;
                  var montoGarantia = parseFloat(dataCompromiso[i].area) * montoUtilizar;
                  montoGarantia = parseFloat(montoGarantia).toFixed(2);
                  dataCompromiso[i].montoCompromiso = montoGarantia;
                  montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                  areaGarantia += parseFloat(dataCompromiso[i].area);
                  totalAreaCompromiso += parseFloat(areaCompromiso);
                  areaTotal = areaTotal + dataCompromiso[i].areaGarantia;
                  var strEspecies = '';
                  for (j = 0; j < dataCompromiso[i].repoblacion.length; j++) {
                    if (j == 0) {
                      strEspecies = dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    } else {
                      strEspecies += '\n' + dataCompromiso[i].repoblacion[j].tcEspecie.nombreCientifico + '\n(' + dataCompromiso[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                    }
                  }
                  item = [
                    { text: dataCompromiso[i].anio == null ? dataCompromiso[i].anioPlantacion : dataCompromiso[i].anio, style: 'tableRowSmall', alignment: 'left' },
                    { text: areaCompromiso, style: 'tableRowSmall', alignment: 'right' },
                    { text: strEspecies, style: 'tableRowSmall', alignment: 'left' },
                    { text: tipoGarantiaDesc, style: 'tableRowSmall', alignment: 'left' },
                    { text: formatNumber.new(dataCompromiso[i].montoCompromiso), style: 'tableRowSmall', alignment: 'right' }
                  ];
                  body.push(item);
                }
              } else {
                var rodalesRepoblacion = data.rodalesRepoblacion;
                for (i = 0; i < rodalesRepoblacion.length; i++) {
                  var rodalRepoblacion = rodalesRepoblacion[i];
                  if (rodalRepoblacion.turno == 1) {
                    rodalRepoblacion.area = parseFloat(rodalRepoblacion.area).toFixed(4);
                    areaMonto = parseFloat(rodalRepoblacion.area);
                    if (data.tcTipoGestion.tipoGestionId != 7) {
                      for (j = 0; j < dataCompromiso.length; j++) {
                        var rodal = dataCompromiso[j];
                        if (rodal.correlativo == rodalRepoblacion.correlativo) {
                          if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                            hayFueraBosque = true;
                            calculoMonto = 'área basal ideal';
                          }
                          areaMonto = parseFloat(rodal.areaGarantia);
                        }
                      }
                    }
                    rodalRepoblacion.areaMonto = areaMonto;
                    if (!hayFueraBosque) {
                      calculoMonto = 'área basal';
                    }
                    var montoGarantia = parseFloat(areaMonto) * montoUtilizar;
                    montoGarantia = parseFloat(montoGarantia).toFixed(2);
                    rodalRepoblacion.montoCompromiso = montoGarantia;
                    montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                    areaGarantia += parseFloat(areaMonto);
                    totalAreaCompromiso += parseFloat(rodalRepoblacion.area);
                    areaTotal = areaTotal + parseFloat(areaMonto);
                    var strEspecies = '';
                    for (j = 0; j < rodalRepoblacion.repoblacion.length; j++) {
                      if (j == 0) {
                        strEspecies = rodalRepoblacion.repoblacion[j].tcEspecie.nombreCientifico + '\n(' + rodalRepoblacion.repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                      } else {
                        strEspecies += '\n' + rodalRepoblacion.repoblacion[j].tcEspecie.nombreCientifico + '\n(' + rodalRepoblacion.repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                      }
                    }
                    item = [
                      { text: rodalRepoblacion.anioPlantacion, style: 'tableRowSmall', alignment: 'left' },
                      { text: rodalRepoblacion.area, style: 'tableRowSmall', alignment: 'right' },
                      { text: strEspecies, style: 'tableRowSmall', alignment: 'left' },
                      { text: tipoGarantiaDesc, style: 'tableRowSmall', alignment: 'left' },
                      { text: formatNumber.new(rodalRepoblacion.montoCompromiso), style: 'tableRowSmall', alignment: 'right' }
                    ];
                    body.push(item);
                  }
                }
              }
              montoTotal = parseFloat(montoTotal).toFixed(2);
              areaGarantia = parseFloat(areaGarantia).toFixed(4);
              totalAreaCompromiso = parseFloat(totalAreaCompromiso).toFixed(4);
              item = [
                { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: totalAreaCompromiso, style: 'tableRowSmall', alignment: 'right' },
                { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: formatNumber.new(montoTotal), style: 'tableRowSmall', alignment: 'right' }
              ];
              body.push(item);
              tbRepoblacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [50, 50, 150, 120, 100],
                  body: body
                }
              };
              if (areaGarantia == totalAreaCompromiso && data.tcTipoGestion.tipoGestionId != 7) {
                calculoMonto = 'área por área';
              }
              content.push(tbRepoblacion);
              content.push(
                {
                  text: 'El monto de la garantía para el primer turno fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + areaGarantia + ' ha.',
                  style: 'izquierdaPequenio', bold: true
                }
              );
            }
            content.push('\n');
            content.push(
              {
                start: 4,
                ol: [
                  {
                    text: "Previo a otorgar la " + data.tcTipoGestion.tipoGestionDesc + ", el titular de la presente resolución se compromete a presentar el documento de pago del 10% del valor de la madera en pie, el documento de garantía y presentar por escrito la propuesta del Regente Forestal inscrito y activo en el Registro Nacional Forestal, siempre y cuando el volumen a extraer sea igual o mayor a 100 metros cúbicos. "
                      + "Siendo el regente responsable en dirigir y verificar el cumplimiento de las actividades contempladas en el Plan de Manejo Forestal presentado y aprobado, incluyendo la información sobre el uso de las Notas de Envío de bosque, según el turno correspondiente. "
                      + "Adicionalmente debe presentar constancia de inscripción ante el Registro Nacional Forestal de las motosierras que se utilizarán en el aprovechamiento forestal.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 5,
                ol: [
                  {
                    text: "El o los titulares de la Licencia Forestal y el Regente Forestal quedan obligados a cumplir con lo preceptuado en el Reglamento para el Transporte de Productos Forestales y su Procedencia Lícita y demás normas vigentes. Previo a iniciar las operaciones del aprovechamiento forestal, debe delimitar claramente el área a intervenir para el turno de operaciones y respetar los límites de dicha área de conformidad con el Plan de Manejo Forestal aprobado.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 6,
                ol: [
                  {
                    text: "Cualquier modificación, anomalía o incumplimiento que se detecte en la ejecución del Plan de Manejo Forestal sin la aprobación por parte del Instituto Nacional de Bosques, así como el mal uso que se le pueda dar a la presente Resolución, será motivo para iniciar el trámite de Cancelación de la misma y presentar la denuncia ante el órgano Competente.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 7,
                ol: [
                  {
                    text: "La presente resolución de aprobación de Plan de Manejo Forestal no faculta al titular realizar aprovechamiento alguno. Ningún aprovechamiento podrá realizarse hasta no haber sido aprobada la Licencia de Aprovechamiento Forestal, la cual será otorgada inmediatamente después de haber cumplido los requisitos anteriores.",
                    style: 'izquierda'
                  }
                ]
              }
            );
            content.push('\n');
            content.push(
              {
                start: 8,
                ol: [
                  {
                    text: "En caso de que dicho inmueble sea vendido o transferido a otra persona, el nuevo propietario deberá aceptar expresamente las obligaciones contraídas por el propietario original en relación al Plan de Manejo Forestal autorizado. El que transfiere dicha propiedad está obligado a dar aviso circunstanciado al INAB dentro de los 15 días hábiles de realizada la transacción o la suscripción del documento en el cual se transfiere la propiedad o los derechos.",
                    style: 'izquierda'
                  }
                ]
              }
            );
          } else { //si no se aprueba
            content.push(
              {
                text: "Con base a lo considerado y en lo preceptuado por los artículos  28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y  6 del Decreto No. 119-96  del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo;  1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50, 51, 52, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 40, 41, 47, 48,  51, 52, 55 y  56 Resolución de Junta Directiva del Instituto Nacional de Bosques No.JD.01.43.2005 Reglamento de la Ley Forestal.",
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "RESUELVE",
                style: 'centrar'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "Denegar  la solicitud presentada por " + sinDpi + " así como el " + tipoGestionDesc + ", para ser ejecutado en inmueble de su " + textoDocumento + ": " + textoFinca + ". Por las siguientes razones: ",
                style: 'izquierda'
              }
            );
            content.push('\n');
            var body = [];
            var tmpData = tarea.ttSeguimientoTarea.fundamento;
            for (i = 0; i < tmpData.length; i++) {
              item = [
                { text: '' },
                { text: (i + 1), style: 'izquierda' },
                { text: tmpData[i].descripcion, style: 'izquierda' }
              ];
              body.push(item);
            }
            var tbFundamento = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [20, 20, 450],
                body: body
              }, layout: 'noBorders'
            };
            content.push(tbFundamento);
            content.push('\n');
            content.push(
              {
                text: "Notifíquese al interesado.",
                style: 'izquierda'
              }
            );
          }
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + ' ' + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director Regional ",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Región " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }
          );
          var textoFooter = 'Codigo: LI-RE-074\nVersión: 2';
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11.5,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11.5,
                alignment: 'justify'
              },
              izquierdaPequenio: {
                fontSize: 9,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          if (tareaId != null) {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              Upload.upload({
                url: appSettings.urlServiceBase + 'file/upload',
                method: 'POST',
                file: blob,
                sendFieldsAs: 'form'
              }).then(function (resp) {
                var data = resp.data;
                if (data.status == 'OK') {
                  var obj = data.data[0];
                  var documento = {
                    tareaId: tareaId,
                    tipoDocumentoTareaId: 1,
                    rutaArchivo: obj.rutaArchivo,
                    reemplazar: 1
                  }
                  tareaService.agregarDocumento(documento).then(function (res) {
                    if (res.status != "OK") {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error(resp.data.message)
                }
              }, function (resp) {
                toastr.error('Error status: ' + resp.status);
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var avance = progressPercentage;
              });
            });
          } else {

            try {
              pdfMake.createPdf(docDefinition).download(nombreSalida);

            } catch (error) {
              console.log(error);
            }

          }
        },
        generarProvidenciaRegional: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla, tareaId, esLicencia) {
          var nombreSalida = 'providencia.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          if (esLicencia == null) {
            esLicencia = false;
          }
          var data = tarea.ttGestion;
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = '';
          var i = 0;
          angular.forEach(data.fincas, function (finca) {
            if (textoDireccion == '') {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          });
          var solicitante = "";
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          var resolucion = 'Licencia';
          var finalTexto = ' y se continué con el trámite correspondiente.';
          if (esLicencia) {
            finalTexto = '.';
          } else {
            if (tarea.aprobado == 0 || tarea.tcTask.taskId == 46) {
              resolucion = 'Resolución';
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN REGIONAL " + data.tcSubregion.tcRegion.regionDesc + ", CON SEDE EN EL DEPARTAMENTO DE "
                + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitante + ", presenta(n) " + data.tcTipoGestion.tipoGestionDesc + " para la ejecución del Plan de Manejo Forestal de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Pase atentamente a " + data.tcSubregion.tcSubregional.personaDesc + " Director de la Sub-Región " + data.tcSubregion.subregionDesc
                + " INAB " + data.tcSubregion.tcMunicipio.municipioDesc + ", para que se notifique la " + resolucion + "  No. "
                + tarea.ttSeguimientoTarea.numeroResolucion + " de fecha " + tarea.ttSeguimientoTarea.fechaProvidencia.getDate() + " de "
                + getMonth(tarea.ttSeguimientoTarea.fechaProvidencia.getMonth() + 1) + " del " + tarea.ttSeguimientoTarea.fechaProvidencia.getFullYear()
                + ",  al interesado" + finalTexto,
              style: 'izquierda'
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Regional " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            },
            {
              text: data.tcSubregion.tcRegion.tcMunicipio.municipioDesc + " – " + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nNombre:_________________________________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nFecha:____________ Firma: ______________',
              style: 'small', alignment: 'right'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          if (tareaId != null) {
            if (esLicencia) {
              tipoDocumentoTareaId = 5;
            } else {
              tipoDocumentoTareaId = 2;
            }
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              Upload.upload({
                url: appSettings.urlServiceBase + 'file/upload',
                method: 'POST',
                file: blob,
                sendFieldsAs: 'form'
              }).then(function (resp) {
                var data = resp.data;
                if (data.status == 'OK') {
                  var obj = data.data[0];
                  var documento = {
                    tareaId: tareaId,
                    tipoDocumentoTareaId: tipoDocumentoTareaId,
                    rutaArchivo: obj.rutaArchivo,
                    reemplazar: 1
                  }
                  tareaService.agregarDocumento(documento).then(function (res) {
                    if (res.status != "OK") {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error(resp.data.message)
                }
              }, function (resp) {
                toastr.error('Error status: ' + resp.status);
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var avance = progressPercentage;
              });
            });
          } else {
            pdfMake.createPdf(docDefinition).download(nombreSalida);
          }
        },
        generarCartaPoliza: function (tarea, strVigencia, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'poliza.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var data = tarea.ttGestion;
          var fecha = new Date();
          var representante = "";
          var textoPropietario = "";
          var i, j;
          var textoFinca = "", textoDireccion = "";
          var plural = false;
          textoPropietario = '';
          var ttTipoPropietarioGestion = data.ttTipoPropietarioGestion;
          var ttTareaPadre = tarea.ttPadre;
          if (ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            textoPropietario = ttTipoPropietarioGestion.razonSocial;
          } else {
            var personas = data.personas;
            for (let i = 0; i < personas.length; i++) {
              const element = personas[i];
              if (i == 0) {
                textoPropietario = element.tcPersona.personaDesc;
              } else {
                textoPropietario += ', ' + element.tcPersona.personaDesc;
              }
            }
            if (textoPropietario.indexOf(',') > 0) {
              textoPropietario = textoPropietario.substring(0, textoPropietario.lastIndexOf(',')) + ' y ' + textoPropietario.substring(textoPropietario.lastIndexOf(',') + 1);
            }
          }
          textoPropietario += ' (I) El cumplimiento de las obligaciones de repoblación forestal adquiridas ';
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              if (finca.tcTipoPropiedad.esPropiedad == 1) {
                textoPropietario += ' (II) las obligaciones de repoblación forestal se deberán llevar a cabo en el terreno de su propiedad finca ' + finca.tcFinca.fincaDesc;
              } else {
                textoPropietario += ' (II) las obligaciones de repoblación forestal se deberán llevar a cabo en el terreno de su posesión finca ' + finca.tcFinca.fincaDesc;
              }
            } else {
              if (finca.tcTipoPropiedad.esPropiedad == 1) {
                textoPropietario += '. Terreno de su propiedad finca ' + finca.tcFinca.fincaDesc;
              } else {
                textoPropietario += '. Terreno de su posesión finca ' + finca.tcFinca.fincaDesc;
              }
            }
            textoPropietario += ", ubicada en el lugar denominado " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de "
              + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ", la cual es acreditada mediante " + finca.tcTipoPropiedad.tipoPropiedadDesc;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoPropietario = textoPropietario + " de derechos posesorios número " + finca.numeroDocumento + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                break;
              case 2:
                textoPropietario = textoPropietario + " de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              case 3:
                textoPropietario = textoPropietario + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " del  departamento de " + finca.tcLibro.libroDesc;
                break;
              case 4:
                textoPropietario = textoPropietario + " de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
            textoPropietario += ' ; específicamente se deberá llevar a cabo en el área  que se ubica en las Coordenadas GTM X: ' + finca.tcFinca.gtmX + " Y: " + finca.tcFinca.gtmY;
            textoPropietario += ' con un área actual de ' + finca.tcFinca.area + ' ha.';
          }
          textoPropietario += " (III) Que las obligaciones de repoblación forestal se deberán llevar a cabo en estricta conformidad con el Plan de Manejo y/o en los Planes Operativos Anuales, especialmente en cuanto a establecimiento, densidad, medidas de protección y estado fitosanitario y mantenimiento en el área e inmueble precisados en los numerales II y III  anteriores, autorizados por el Instituto Nacional de Bosques -INAB- de la siguiente manera: "

          var content = [
            {
              text: 'Oficio ' + tarea.ttSeguimientoTarea.fundamento,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + getMonth(fecha.getMonth() + 1) + " " + fecha.getDate() + " de " + fecha.getFullYear(),
              style: 'derecha'
            }, '\n\n\n',
            {
              text: "A quien interese",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Para garantizar las obligaciones de repoblación forestal por un plazo del " + strVigencia.toUpperCase() 
              + ' adquiridas en la Resolución de Aprobación del Plan de Manejo Forestal número: '
              + ttTareaPadre.ttSeguimientoTarea.numeroResolucion + ', por ' + textoPropietario,
              style: 'izquierda'
            }, '\n\n'
          ];
          tmpData = [];
          if (data.tcTipoGestion.tipoGestionId == 5) {
            var body = [
              [
                { text: 'Planificación de la repoblación forestal', style: 'centrar', alignment: 'center', colSpan: 7 }, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad inicial / ha', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            tmpRodal = data.rodalesRepoblacion;
          } else {
            var body = [
              [
                { text: 'Planificación de la repoblación forestal', style: 'centrar', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
              ],
              [
                { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Rodal', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Manejo de Remanente', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Densidad inicial / ha', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            tmpRodal = data.rodal;
          }
          for (var i = 0; i < tmpRodal.length; i++) {
            if (parseInt(tmpRodal[i].turno) == 1) {
              tmpData.push(tmpRodal[i]);
            }
          }
          var rodales = tmpData;
          itemTotal = {
            area: 0,
            arbolResidual: 0,
            total: 0
          }
          var areaGarantiaTotal = 0;
          var rodalesRepoblacion = [];
          var tmpRodalesRepoblacion = data.rodalesRepoblacion;
          if (tmpRodalesRepoblacion == null) {
            tmpRodalesRepoblacion = [];
          }
          for (i = 0; i < tmpRodalesRepoblacion.length; i++) {
            var rodalRepoblacion = tmpRodalesRepoblacion[i];
            if (rodalRepoblacion.turno == 1) {
              rodalesRepoblacion.push(rodalRepoblacion);
            }
          }
          for (i = 0; i < rodalesRepoblacion.length; i++) {
            var rodalRepoblacion = rodalesRepoblacion[i];
            filas = [];
            for (j = 0; j < rodalRepoblacion.repoblacion.length; j++) {
              item = [
                { text: rodalRepoblacion.repoblacion[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.repoblacion[j].tcMetodoSiembra.metodoSiembraDesc, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.repoblacion[j].densidadInicial, style: 'small', alignment: 'right' },
                { text: rodalRepoblacion.repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc, style: 'small', alignment: 'left' }
              ];
              filas.push(item);
              itemTotal.total += parseInt(rodalRepoblacion.repoblacion[j].densidadInicial);
            }
            var encontrado = false;
            var rodal = null;
            for (j = 0; j < rodales.length; j++) {
              rodal = rodales[j];
              if (rodal.correlativo == rodalRepoblacion.correlativo) {
                encontrado = true;
                break;
              }
            }
            areaGarantia = rodalRepoblacion.area;
            var remanente = 0;
            if (encontrado) {
              areaGarantia = rodal.areaGarantia;
              if (rodal.tcTipoCobertura != null) {
                if (rodal.tcTipoCobertura.tipoCoberturaId != 1) {
                  mantenerTextoCalculo = true;
                  calculoMonto = 'área basal ideal';
                }
              }
              remanente = rodal.remanente;
            }
            areaGarantiaTotal += parseFloat(areaGarantia);
            var calculoMonto = 'área por área';
            var mantenerTextoCalculo = false;
            if (data.tcTipoGestion.tipoGestionId == 5) {
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [95, 85, 55, 100],
                  body: filas
                }, layout: 'noBorders', colSpan: 4
              }
              item = [
                { text: rodalRepoblacion.turno, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.correlativo, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.area, style: 'small', alignment: 'right' },
                tablaFila
              ];
            } else {
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [80, 85, 43, 100],
                  body: filas
                }, layout: 'noBorders', colSpan: 4
              }
              item = [
                { text: rodalRepoblacion.turno, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.correlativo, style: 'small', alignment: 'left' },
                { text: rodalRepoblacion.area, style: 'small', alignment: 'right' },
                { text: remanente, style: 'small', alignment: 'right' },
                tablaFila
              ];
              itemTotal.arbolResidual += parseInt(remanente);
            }
            body.push(item);
            itemTotal.area += parseFloat(rodalRepoblacion.area);
          }
          var tbRepoblacionPlan = {};
          if (data.tcTipoGestion.tipoGestionId == 5) {
            item = [
              { text: 'Total', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 4 }, {}, {}, {}
            ];
            body.push(item);
            tbRepoblacionPlan = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 40, 95, 80, 60, 110],
                body: body
              }
            }
          } else {
            item = [
              { text: 'Total', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
            ];
            body.push(item);
            tbRepoblacionPlan = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [30, 30, 30, 50, 75, 80, 50, 95],
                body: body
              }
            }
          }
          areaGarantia = parseFloat(areaGarantiaTotal).toFixed(4);
          itemTotal.area = parseFloat(itemTotal.area).toFixed(4);
          if (!mantenerTextoCalculo) {
            if (areaGarantia < itemTotal.area) {
              calculoMonto = 'área basal';
            }
          }
          content.push(tbRepoblacionPlan);
          content.push(
            {
              text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + areaGarantia + ' ha.',
              style: 'small', bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: "La presente fianza estará vigente del " + strVigencia.toLowerCase() + " y garantizar las siguientes etapas, periodos y montos.",
              style: 'izquierda', bold: true
            }
          );
          content.push('\n\n');
          body = [
            [
              { text: 'Etapa', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Monto', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Vigencia', style: 'centrar', fillColor: '#CCCCCC' }
            ]
          ];
          tmpData = tarea.ttPadre.ttSeguimientoTarea.analisis;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: tmpData[i].etapaDesc, style: 'small', alignment: 'left' },
              { text: 'Q. ' + formatNumber.new(tmpData[i].monto), style: 'small', alignment: 'right' },
              { text: tmpData[i].vigencia, style: 'small', alignment: 'left' }
            ];
            body.push(item);
          }
          var tbVigencia = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [130, 100, 250],
              body: body
            }
          };
          content.push(tbVigencia);
          content.push('\n\n');
          content.push(
            {
              text: "El beneficiario de la presente fianza, el Instituto Nacional de Bosques –INAB– podrá solicitar y exigir el pago, en forma directa a la afianzadora, cuando compruebe mediante informe técnico y/o acta administrativa interna, que el fiador ha incumplido cualesquiera de las obligaciones de la Repoblación Forestal contenidas en el Plan de Manejo y/o en los Planes Operativos Anuales, sin necesidad de substanciar ningún procedimiento administrativo y/o judicial,  bastando que el beneficiario solicite el pago por escrito mediante oficio y en forma fundamentada, acreditando el incumplimiento con certificación del acta administrativa y/o fotocopia del informe técnico del personal del –INAB-; y a su vez la afianzadora incurrirá en mora si no paga dentro de los siguientes plazos: a. de diez días, si en la presente fianza no existe reafianzamiento; y b. De treinta días, si en la presente fianza existe reafianzamiento. Este último plazo corre a partir de que el INAB solicite el pago en forma directa a la afianzadora conforme lo establecido.",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Si no reúne las características solicitadas, no será aceptada.",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          );
          content.push('\n\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            '\n'
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-021\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarPoa: function (dataEmpresa, data, turno) {
          var nombreSalida = 'poa.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var entidad = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nombreComercial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            entidad = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 90],
                body: body
              }
            }
          }
          body = [
            [
              { text: 'I. INFORMACIÓN GENERAL', style: 'tableSubHeader', colSpan: 5, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}
            ],
            [
              { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Comunidad Lingüística', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Pueblo de Pertenencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Estado civil', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var dataPersona = data.personas;
          for (i = 0; i < dataPersona.length; i++) {
            item = [
              { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcIdioma.idiomaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcCultura.culturaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcEstadoCivil.estadoCivilDesc, style: 'detalle', alignment: 'left' }
            ];
            body.push(item);
            if (dataPersona[i].tcPersona.tcSexo.sexoId == 1) {
              mujeres++;
            } else {
              hombres++;
            }
          }

          item = [
            { text: 'Hombres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
            { text: hombres, style: 'tableHeader', alignment: 'left' },
            { text: 'Mujeres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
            { text: mujeres, style: 'tableHeader', alignment: 'left' },
            { text: '', alignment: 'left' }
          ];
          body.push(item);
          if (data.representantes.length > 0) {
            item = [
              { text: 'Representante legal', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Nombre completo', style: 'tableSubHeader', colSpan: 2, fillColor: '#CCCCCC', alignment: 'center' }, {},
              { text: 'Código único de Identificación -CUI-', style: 'tableSubHeader', colSpan: 3, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}
            ];
            body.push(item);
            for (var i = 0; i < data.representantes.length; i++) {
              item = [
                { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
            }
          }
          var solicitante = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 90, 85, 83, 80],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'II. DATOS DE LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                propietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            if (documentos == null) {
              documentos = [];
            }
            var cantidadDocumentos = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' },
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' },
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            body.push(item);
            item = [
              { text: 'Colindancias', style: 'tableSubHeader', colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Norte', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.norte, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Sur', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.sur, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Este', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.este, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Oeste', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.oeste, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Área total de la finca (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.areaDocumento).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.area).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var finca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'Elaborador del Plan de Manejo Forestal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.personaDesc, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Número de Registro en el RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.tcElaborador.rfn, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Firma', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: '\n\n', style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var pieElaborador = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          body = [
            [
              { text: '\n\n\n', style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Firma del solicitante', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var pieFirma = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [525],
              body: body
            }
          }

          /***
          * tab inventario
          ***/
          body = [
            [
              { text: 'III. INVENTARIO FORESTAL', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 16 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
              { text: 'Hectarea', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Rodal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'No. De Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Pendiente promedio (%)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Incremento (m³/ha/año)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Clase de Desarrollo', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Edad (años)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Edad (años)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura (m)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP (cm)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'arb/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'G/ ha(m²)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vol./ha. (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'arb/rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'G/ rodal (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vol./ Rodal (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var dataInventario = [];
          for (i = 0; i < data.rodal.length; i++) {
            if (data.rodal[i].turno == turno) {
              dataInventario.push(data.rodal[i]);
            }
          }
          var conteo = 0;
          var filas = [];
          var tablaFila = {};
          var subTotal = {};
          var itemTotal = {
            alturaPromedio: 0,
            dapPromedio: 0,
            arbolh: 0,
            abh: 0,
            volh: 0,
            arbolr: 0,
            abr: 0,
            volr: 0,
            cantidad: 0
          };
          var finalizar = false;
          var bndEstaMal = false;
          var mensajeMal = '';
          for (i = 0; i < dataInventario.length; i++) {
            filas = [];
            subTotal = {
              alturaPromedio: 0,
              dapPromedio: 0,
              arbolh: 0,
              abh: 0,
              volh: 0,
              arbolr: 0,
              abr: 0,
              volr: 0,
              cantidad: 0
            };
            bndEstaMal = true;
            mensajeMal = 'El resumen de inventario (pestaña Inventario Forestal) está mal, debe utilizar el botón para realizar el cálculo';
            for (j = 0; j < dataInventario[i].especies.length; j++) {
              subTotal.alturaPromedio += parseFloat(dataInventario[i].especies[j].alturaPromedio);
              subTotal.dapPromedio += parseFloat(dataInventario[i].especies[j].dapPromedio);
              subTotal.arbolh += parseInt(dataInventario[i].especies[j].arbolh);
              subTotal.abh += parseFloat(dataInventario[i].especies[j].abh);
              subTotal.volh += parseFloat(dataInventario[i].especies[j].volh);
              subTotal.arbolr += parseInt(dataInventario[i].especies[j].arbolr);
              subTotal.abr += parseFloat(dataInventario[i].especies[j].abr);
              subTotal.volr += parseFloat(dataInventario[i].especies[j].volr);
              subTotal.cantidad++;
              if (parseFloat(dataInventario[i].especies[j].volr) > 0 && bndEstaMal) {
                bndEstaMal = false;
              }
              item = [
                { text: dataInventario[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: dataInventario[i].especies[j].tcTipoInventario.tipoInventarioDesc, style: 'small', alignment: 'left' },
                { text: dataInventario[i].especies[j].alturaPromedio, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].dapPromedio, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].arbolh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].abh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].volh, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].arbolr, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].abr, style: 'small', alignment: 'right' },
                { text: dataInventario[i].especies[j].volr, style: 'small', alignment: 'right' }
              ];
              filas.push(item);
            }
            itemTotal.alturaPromedio += parseFloat(subTotal.alturaPromedio);
            itemTotal.dapPromedio += parseFloat(subTotal.dapPromedio);
            itemTotal.arbolh += parseInt(subTotal.arbolh);
            itemTotal.abh += parseFloat(subTotal.abh);
            itemTotal.volh += parseFloat(subTotal.volh);
            itemTotal.arbolr += parseInt(subTotal.arbolr);
            itemTotal.abr += parseFloat(subTotal.abr);
            itemTotal.volr += parseFloat(subTotal.volr);
            itemTotal.cantidad += parseInt(subTotal.cantidad);
            dapPromedio = parseFloat(subTotal.dapPromedio) / subTotal.cantidad;
            item = [
              { text: "Subtotal", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(parseFloat(subTotal.alturaPromedio) / subTotal.cantidad).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(dapPromedio).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseInt(subTotal.arbolh), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.abh).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.volh).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseInt(subTotal.arbolr), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.abr).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(subTotal.volr).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' }
            ];
            filas.push(item);
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 35, 20, 20, 21, 21, 21, 21, 25, 25],
                body: filas
              }, layout: 'noBorders', colSpan: 10
            }
            item = [
              { text: dataInventario[i].correlativo, style: 'small', alignment: 'left' },
              { text: dataInventario[i].area, style: 'small', alignment: 'left' },
              { text: dataInventario[i].pendientePromedio, style: 'small', alignment: 'left' },
              { text: dataInventario[i].incremento, style: 'small', alignment: 'left' },
              { text: dataInventario[i].tcClaseDesarrollo.codigoClase, style: 'small', alignment: 'left' },
              { text: dataInventario[i].edad, style: 'small', alignment: 'left' },
              tablaFila
            ];
            body.push(item);
          }
          item = [
            { text: 'TOTAL GENERAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 13 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            { text: parseInt(itemTotal.arbolr), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.abr).toFixed(2), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.volr).toFixed(2), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
          ];
          body.push(item);
          var resumenInventario = {
            style: 'estiloTablaEstrecha',
            pageBreak: 'before',
            table: {
              headerRows: 3,
              widths: [20, 20, 25, 25, 30, 20, 48, 32, 20, 20, 20, 20, 20, 20, 25, 25],
              body: body
            }
          }
          /*End tab inventario*/

          //silvicultura
          body = [
            [
              { text: 'SILVICULTURA', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Productos forestales maderables', style: 'tableSubHeader', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
              { text: 'Tipo de productos y volúmen a extraer', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Año aprovechamiento', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie (código)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Tratamiento silvicultural', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Arbol/rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          itemTotal = {
            troza: 0,
            lenia: 0,
            otros: 0,
            volrExtraer: 0,
            arbolrExtraer: 0
          }
          var dataSilvicultura = dataInventario;
          finalizar = false;
          for (i = 0; i < dataSilvicultura.length; i++) {
            if (dataSilvicultura[i].turno == turno) {
              finalizar = true;
              filas = [];
              subTotal = {
                troza: 0,
                lenia: 0,
                otros: 0,
                volrExtraer: 0,
                arbolrExtraer: 0
              };
              for (j = 0; j < dataSilvicultura[i].especies.length; j++) {
                subTotal.troza += parseFloat(dataSilvicultura[i].especies[j].troza);
                subTotal.lenia += parseFloat(dataSilvicultura[i].especies[j].lenia);
                subTotal.otros += parseFloat(dataSilvicultura[i].especies[j].otros);
                subTotal.volrExtraer += parseFloat(dataSilvicultura[i].especies[j].volrExtraer);
                subTotal.arbolrExtraer += parseInt(dataSilvicultura[i].especies[j].arbolrExtraer);
                item = [
                  { text: dataSilvicultura[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: dataSilvicultura[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalDesc, style: 'small', alignment: 'left' },
                  { text: dataSilvicultura[i].especies[j].troza, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].lenia, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                  { text: dataSilvicultura[i].especies[j].arbolrExtraer, style: 'small', alignment: 'right' }
                ];
                filas.push(item);
              }
              //subtotal
              item = [
                { text: 'SUBTOTAL', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: parseFloat(subTotal.troza).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(subTotal.lenia).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: parseFloat(subTotal.volrExtraer).toFixed(2), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
                { text: subTotal.arbolrExtraer, style: 'small', fillColor: '#CCCCCC', alignment: 'right' }
              ];
              filas.push(item);
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [50, 100, 45, 45, 55, 45],
                  body: filas
                }, layout: 'noBorders', colSpan: 6
              }
              itemTotal.troza += subTotal.troza;
              itemTotal.lenia += subTotal.lenia;
              itemTotal.otros += subTotal.otros;
              itemTotal.volrExtraer += subTotal.volrExtraer;
              itemTotal.arbolrExtraer += subTotal.arbolrExtraer;
              item = [
                { text: dataSilvicultura[i].turno, style: 'small', alignment: 'left' },
                { text: dataSilvicultura[i].correlativo, style: 'small', alignment: 'left' },
                { text: dataSilvicultura[i].anio, style: 'small', alignment: 'left' },
                { text: parseFloat(dataSilvicultura[i].area).toFixed(4), style: 'small', alignment: 'left' },
                tablaFila
              ];
              body.push(item);
            } else {
              if (finalizar) {
                break;
              }
            }
          }
          item = [
            { text: 'TOTAL GENERAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 6 }, {}, {}, {}, {}, {},
            { text: parseFloat(itemTotal.troza).toFixed(2), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.lenia).toFixed(2), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: parseFloat(itemTotal.volrExtraer).toFixed(2), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
            { text: itemTotal.arbolrExtraer, style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
          ];
          body.push(item);
          var silvicultura = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 3,
              widths: [25, 25, 25, 25, 55, 95, 45, 45, 55, 45],
              body: body
            }
          }
          //plan de corta
          body = [
            [
              { text: 'PLAN DE CORTA', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center', colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: '', style: 'tableSubHeader', alignment: 'center', colSpan: 4 }, {}, {}, {},
              { text: 'Volumen actual', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen a extraer', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen residual', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Año', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen m³/Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen m³/Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área basal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen m³/Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var dataPlanCorta = dataInventario;
          finalizar = false;
          for (i = 0; i < dataPlanCorta.length; i++) {
            if (dataPlanCorta[i].turno == turno) {
              finalizar = true;
              for (j = 0; j < dataPlanCorta[i].especies.length; j++) {
                item = [
                  { text: dataPlanCorta[i].turno, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].correlativo, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].anio, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: dataPlanCorta[i].especies[j].abr, style: 'small', alignment: 'right' },
                  { text: dataPlanCorta[i].especies[j].volr, style: 'small', alignment: 'right' },
                  { text: dataPlanCorta[i].especies[j].abrExtraer, style: 'small', alignment: 'right' },
                  { text: dataPlanCorta[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                  { text: dataPlanCorta[i].especies[j].abResidual, style: 'small', alignment: 'right' },
                  { text: dataPlanCorta[i].especies[j].volResidual, style: 'small', alignment: 'right' }
                ];
                body.push(item);
              }
            } else {
              if (finalizar) {
                break;
              }
            }
          }
          var planCorta = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 3,
              widths: [25, 25, 30, 63, 50, 45, 55, 55, 45, 55],
              body: body
            }
          }
          /*Otras descripciones en planificacion*/
          body = [
            [
              { text: 'IV RECUPERACIÓN DE LA MASA FORESTAL', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
            ],
            [
              { text: 'Objetivos de la recuperación del bosque', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttPlanificacionGestion.masaForestal, style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: 'Justificación de la especie a utilizar', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ],
            [
              { text: data.ttPlanificacionGestion.justificacionEspecie, style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var otrasPlanificacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [530],
              body: body
            }
          }
          var dataRepoblacion = {};

          /*Planificacion de la repoblacion forestal*/
          body = [
            [
              { text: 'Planificación de la repoblación forestal', style: 'tableSubHeader', alignment: 'center', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Turno', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha)', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Manejo de Remanente', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Método de siembra', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Densidad inicial / ha', style: 'small', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Sistema de repoblación', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var repoblacion = [];
          if (data.rodalesRepoblacion == null) {
            data.rodalesRepoblacion = [];
          }
          if (data.rodalesRepoblacion.length > 0) {
            for (var i = 0; i < data.rodalesRepoblacion.length; i++) {
              var rodalRepoblacion = data.rodalesRepoblacion[i];
              if (rodalRepoblacion.turno == turno) {
                repoblacion.push(rodalRepoblacion);
              }
            }
          } else {
            repoblacion = dataInventario;
          }
          itemTotal = {
            area: 0,
            arbolResidual: 0,
            total: 0
          }
          for (i = 0; i < repoblacion.length; i++) {
            filas = [];
            for (j = 0; j < repoblacion[i].repoblacion.length; j++) {
              item = [
                { text: repoblacion[i].repoblacion[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: repoblacion[i].repoblacion[j].tcMetodoSiembra.metodoSiembraDesc, style: 'small', alignment: 'left' },
                { text: repoblacion[i].repoblacion[j].densidadInicial, style: 'small', alignment: 'right' },
                { text: repoblacion[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc, style: 'small', alignment: 'left' }
              ];
              filas.push(item);
              itemTotal.total += parseInt(repoblacion[i].repoblacion[j].densidadInicial);
            }
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [80, 85, 43, 100],
                body: filas
              }, layout: 'noBorders', colSpan: 4
            }
            item = [
              { text: repoblacion[i].turno, style: 'small', alignment: 'left' },
              { text: repoblacion[i].correlativo, style: 'small', alignment: 'left' },
              { text: parseFloat(repoblacion[i].area).toFixed(4), style: 'small', alignment: 'right' },
              { text: repoblacion[i].remanente, style: 'small', alignment: 'right' },
              tablaFila
            ];
            body.push(item);
            itemTotal.area += parseFloat(repoblacion[i].area);
            itemTotal.arbolResidual += parseInt(repoblacion[i].remanente);
          }
          item = [
            { text: 'Totales', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
            { text: parseFloat(itemTotal.area).toFixed(4), style: 'small', fillColor: '#CCCCCC', alignment: 'right' },
            { text: '', style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}
          ];
          body.push(item);
          var dataPlanificacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [40, 40, 40, 40, 75, 80, 50, 100],
              body: body
            }
          }
          /*Actividades para el establecimiento y manejo del compromiso*/
          body = [
            [
              { text: 'Actividades para el establecimiento y manejo del compromiso', style: 'tableSubHeader', fillColor: '#999999', alignment: 'left' }
            ],
            [
              { text: data.ttPlanificacionGestion.establecimiento, style: 'tableSubHeader', alignment: 'left' }
            ]
          ];
          var tbEstablecimiento = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [530],
              body: body
            }
          }
          /*XII. medidas de prevencion contra incendios forestales*/
          body = [
            [
              { text: 'IX. Medidas de prevención contra incendios forestales y plagas forestales', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }
            ]
          ];
          body.push([
            { text: data.ttProteccionGestion.justificacion, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Líneas de control y rondas cortafuegos', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.cortafuego, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Vigilancia (puestos de control y recorridos por el área)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.vigilancia, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Manejo de combustibles', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.combustible, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Identificación de áreas críticas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.areaCritica, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Respuesta en caso de incendios forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.respuestaIf, style: 'detalle', alignment: 'left' }
          ]);
          if (data.area > 45) {
            body.push([
              { text: 'Áreas mayores a 45 hectáreas', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]);
            body.push([
              { text: data.ttProteccionGestion.rutaEscape, style: 'detalle', alignment: 'left' }
            ]);
          }
          body.push([
            { text: 'Medidas de prevención contra plagas forestales (describir brevemente)', style: 'tableSubHeader', fillColor: '#999999', alignment: 'center' }
          ]);
          body.push([
            { text: 'Monitoreo para detección temprana de plagas y enfermedades forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.justificacionPf, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Control de plagas y enfermedades forestales', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.monitoreoPlaga, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Programa Sanitario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.controlPlaga, style: 'detalle', alignment: 'left' }
          ]);
          body.push([
            { text: 'Monitoreos mensuales (describir actividades)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
          ]);
          body.push([
            { text: data.ttProteccionGestion.justificacion, style: 'detalle', alignment: 'left' }
          ]);
          var incendios = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [525],
              body: body
            }
          }
          var content = [
            '\n',
            {
              text: 'Plan Operativo No.: ___________' + turno + '____________',
              style: 'derecha'
            }, '\n',
            {
              text: 'Licencia Forestal No.: _______________________',
              style: 'derecha'
            }, '\n',
            {
              text: 'Año de POA: _______________________',
              style: 'derecha'
            }, '\n',
            entidad, solicitante, finca, pieElaborador, pieFirma,
            resumenInventario, silvicultura, planCorta, otrasPlanificacion,
            dataRepoblacion, dataPlanificacion, tbEstablecimiento, incendios
          ];
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          /*
          *Cinco años van en una pagina, por lo que se resta un turno, ya despues es un turno un año
          */
          var noTurnos = parseInt(data.ttResumenGestion.noTurnos - 1);
          var inferior = 1;
          var addPages = 1;
          /*
          *Por cada cinco turnos se suma una pagina
          */
          while (inferior <= noTurnos) {
            addPages++;
            inferior += 5;
          }
          addPages = 2;
          var watermark = {};
          if (bndEstaMal) {
            watermark = {
              text: mensajeMal, color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 60, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            header: function (currentPage, pageCount) {

              lastPagePoa = pageCount;
              pageCount = pageCount + addPages;

              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 380,
                      text: "\nINSTITUTO NACIONAL DE BOSQUES\nPLAN DE MANEJO FORESTAL PARA " + data.tcTipoGestion.tipoGestionDesc.toUpperCase() + "\nBOSQUE " + data.tcTipoBosque.tipoBosqueDesc.toUpperCase(),
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                bold: true,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarEnmiendaTurno: function (data, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'enmienda.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaProvidencia = null;
          if (data.fechaProvidencia == null) {
            data.fechaProvidencia = new Date();
            fechaProvidencia = data.fechaProvidencia;
          } else {
            fechaProvidencia = new Date(data.fechaProvidencia);
          }
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var mesNotifica = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var tbEnmiendaSubregional = {};
          var ttGestion = data.ttGestion;
          var tcSubregion = ttGestion.tcSubregion;
          tmpData = data.ttSeguimientoTarea.enmienda;
          if (tmpData == null) {
            tmpData = [];
          }
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
          tbEnmiendaSubregional = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [20, 10, 450],
              body: body
            }, layout: 'noBorders'
          };
          var nombres = '', correos = '';
          var personas = ttGestion.personas;
          if (personas == null) personas = [];
          if (personas.length > 0) {
            for (let i = 0; i < personas.length; i++) {
              const persona = personas[i];
              if (i == 0) {
                nombres = persona.tcPersona.personaDesc;
                correos = persona.tcPersona.correo;
              } else {
                nombres += ', ' + persona.tcPersona.personaDesc;
                correos += ',' + persona.tcPersona.correo;
              }
            }
          } else {
            var representantes = ttGestion.representantes;
            for (let i = 0; i < representantes.length; i++) {
              const persona = representantes[i];
              if (i == 0) {
                correos = persona.tcPersona.correo;
                nombres = persona.tcPersona.personaDesc;
              } else {
                nombres += ', ' + persona.tcPersona.personaDesc;
                correos += ',' + persona.tcPersona.correo;
              }
            }
          }
          var notificaciones = ttGestion.notificaciones;
          for (let i = 0; i < notificaciones.length; i++) {
            const element = notificaciones[i];
            if (element.tcTipoNotificacion.tipoNotificacionId == 5) {
              correos += ',' + element.notificacionGestionDesc;
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + data.ttSeguimientoTarea.codigo,
              style: 'derecha'
            },
            {
              text: tcSubregion.tcMunicipio.municipioDesc + ", " + tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaProvidencia.getDate() + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr (a). \n" + nombres + "\nSolicitante ",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: 'Por este medio le informo que luego de la revisión de documentos presentados para la autorización de la Licencia de Aprovechamiento Forestal correspondiente al expediente número ' 
              + ttGestion.expediente + ', se le hace saber que es necesario atender lo siguiente:',
              style: 'izquierda'
            }, '\n', tbEnmiendaSubregional, '\n',
            {
              text: "Sin otro particular me suscribo de usted.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director subregional " + tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al correo electrónico: ' + correos + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mesNotifica) + " de " + fecha.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarPoaSubregional: function (poa, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var nombreSalida = 'poaresolucion.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", en las coordenadas GTM X = "
                + finca.tcFinca.gtmX + ", Y = " + finca.tcFinca.gtmY + " del municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            } else {
              textoFinca = "finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion
                + ", en las coordenadas GTM X = " + finca.tcFinca.gtmX + ", GTM Y = " + finca.tcFinca.gtmY + " del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            }
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento
                  + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", autorizada en el municipio "
                  + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                break;
              case 2:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el "
                  + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                  + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              case 3:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                  + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " del departamento de " + finca.tcLibro.libroDesc;
                break;
              case 4:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          var ttPadre = ttTarea.ttPadre;
          var representante = "El director subregional " + ttTarea.tcPersonaAsignada.personaDesc + " del Instituto Nacional de Bosques INAB, con base a la licencia número "
            + ttPadre.ttSeguimientoTarea.numeroResolucion + " de fecha " + $filter('date')(new Date(ttPadre.fechaFinalizacion), 'dd/MM/yyyy') + " emitida por "
            + ttPadre.tcPersonaAsignada.personaDesc + " Director Regional " + data.tcSubregion.tcRegion.regionDesc + ", con sede en el municipio de " + data.tcSubregion.tcRegion.tcMunicipio.municipioDesc
            + ", departamento de " + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc + "; otorgó Licencia de Aprovechamiento Forestal a favor de ";
          var afavor = "";
          if (data.representantes.length > 0) {
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              }
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            afavor = data.ttTipoPropietarioGestion.razonSocial;
          }
          if (data.representantes.length > 0) {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc;
              } else {
                afavor += ", " + data.personas[i].tcPersona.personaDesc;
              }
            }
            if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
              afavor = data.ttTipoPropietarioGestion.razonSocial;
            }
            representante = representante + afavor + ", representado legalmente por " + representanteNombre + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              } else {
                afavor += ". " + data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              }
            }
            representante = representante + afavor;
          }
          representante = representante + ". Para implementar el plan de manejo forestal elaborado por " + data.tcElaborador.personaDesc + " en la categoría de "
            + (data.categoriaProfesionId == 2 ? "Profesional" : "Técnico") + " con registro ante el INAB No. " + data.tcElaborador.rfn + ", en la " + textoFinca;
          var content = [
            {
              text: "RESOLUCIÓN DE APROBACIÓN DE PLAN OPERATIVO ANUAL",
              style: 'centrar'
            }, '\n',
            {
              text: "Plan Operativo Anual No. " + ttTarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            }, '\n\n',
            {
              text: "DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE "
                + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ". INSTITUTO NACIONAL DE BOSQUES –INAB–, " + strDiaResolucion + " DIAS DEL MES DE "
                + getMonth(mes).toUpperCase() + " DE " + strAnioResolucion,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n'
          ];
          var alineacion = 'right';
          if (data.ttPlanificacionGestion.hayReforestacion != 1) {
            alineacion = 'left';
          }
          var body = [
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Año operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Volumen m³/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              '', '', '', '', '',
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              ''
            ]
          ];
          var dataRodal = data.rodal;
          tmpData = [];
          for (i = 0; i < dataRodal.length; i++) {
            if (dataRodal[i].turno == poa.noPoa) {
              tmpData.push(dataRodal[i]);
            }
          }
          var valorTurno = 0;
          var total = 0;
          var volrExtraer = 0;
          var areaCompromiso = 0;
          var tVolumen = {
            area: 0,
            troza: 0,
            lenia: 0,
            total: 0,
            areaAprobada: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            row = tmpData[i];
            var bodyEspecie = [];
            for (j = 0; j < row.especies.length; j++) {
              row.especies[j].troza = parseFloat(row.especies[j].troza).toFixed(4);
              row.especies[j].lenia = parseFloat(row.especies[j].lenia).toFixed(4);
              total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
              volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              valor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalValor = valor;
              valor = valor * 0.1;
              valorTurno += valor;
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalPagar = valor;
              itemEspecie = [
                { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                { text: row.especies[j].troza, style: 'tableRowSmall', alignment: 'right' },
                { text: row.especies[j].lenia, style: 'tableRowSmall', alignment: 'right' },
                { text: parseFloat(total).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
              ]
              bodyEspecie.push(itemEspecie);
              tVolumen.troza += parseFloat(row.especies[j].troza);
              tVolumen.lenia += parseFloat(row.especies[j].lenia);
              tVolumen.total += parseFloat(total);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [95, 49, 49, 49],
                body: bodyEspecie
              }, colSpan: 4, layout: 'noBorders'
            }
            var area = null;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
              area = parseFloat(area).toFixed(4);
            } else {
              area = 'Pago al Fondo Forestal Privativo';
            }
            item = [
              { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
              { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
              { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
              { text: parseFloat(row.area).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
              tbEspecie, {}, {}, {},
              { text: area, style: 'tableRowSmall', alignment: alineacion }
            ];
            body.push(item);
            tVolumen.areaAprobada += row.area;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              if (row.tcTipoCobertura.tipoCoberturaId == 3) {
                tVolumen.area += row.areaGarantia;
              } else {
                tVolumen.area += row.area;
              }
            }
          }
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            tVolumen.area = parseFloat(tVolumen.area).toFixed(4);
          } else {
            tVolumen.area = '---';
          }
          item = [
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left', colSpan: 3 }, {}, {},
            { text: parseFloat(tVolumen.areaAprobada).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left' },
            { text: parseFloat(tVolumen.troza).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(tVolumen.lenia).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(volrExtraer).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: tVolumen.area, style: 'tableRowSmall', alignment: 'right' }
          ];
          body.push(item);
          valorTurno = parseFloat(valorTurno).toFixed(2);
          var tbCompromiso = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [25, 25, 40, 40, 100, 50, 50, 50, 50],
              body: body
            }
          };
          content.push(
            {
              text: "El volumen total autorizado a extraer durante la ejecución del presente POA es de "
                + parseFloat(volrExtraer).toFixed(4) + " metros cúbicos, a realizarse en los rodales siguientes:",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Distribución del aprovechamiento del bosque para el año de operaciones " + poa.anioOperacion,
              style: 'centrar'
            }
          );
          content.push(tbCompromiso);
          content.push('\n');
          body = [
            [
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            volrExtraer: 0,
            trozaValor: 0,
            leniaValor: 0,
            totalValor: 0,
            totalPagar: 0
          }
          var exonerarValorMadera = false;
          var strRodales = '';
          var totalRodalesTurno = 0, totalRodalesValorMadera = 0;
          for (i = 0; i < tmpData.length; i++) {
            totalRodalesTurno++;
            if (!tmpData[i].valorMadera) {
              if (strRodales.length > 0) {
                strRodales += ', ' + tmpData[i].correlativo;
              } else {
                strRodales = tmpData[i].correlativo;
              }
              exonerarValorMadera = true;
              totalRodalesValorMadera++;
            }
            var bodyEspecie = [];
            for (j = 0; j < tmpData[i].especies.length; j++) {
              itemEspecie = [
                { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
              ];
              bodyEspecie.push(itemEspecie);
              totales.troza += parseFloat(tmpData[i].especies[j].troza);
              totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
              totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
              totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
              totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 45, 45, 45, 45, 45, 45, 45],
                body: bodyEspecie
              }, colSpan: 8, layout: 'noBorders'
            }
            item = [
              { text: tmpData[i].turno, style: 'small', alignment: 'left' },
              { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
              tbEspecie, {}, {}, {}, {}, {}, {}, {}
            ];
            body.push(item);
            if (tmpData[i].tcTipoCobertura.tipoCoberturaId == 3) {
              areaCompromiso += parseFloat(tmpData[i].areaGarantia);
            } else {
              areaCompromiso += parseFloat(tmpData[i].area);
            }
          }
          item = [
            { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
            { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
          ];
          body.push(item);
          var tbValorMadera = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [25, 25, 50, 45, 45, 45, 45, 45, 45, 45],
              body: body
            }
          };
          if (ttTarea.ttSeguimientoTarea.aprobado == 1) {
            content.push({
              text: "El valor del pago al Fondo Forestal Privativo para el derecho de corta es de Q. " + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)) + " correspondiente al primer turno de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            });
          } else {
            content.push({
              text: "El titular de la licencia se obliga a pagar al Fondo Forestal Privativo del INAB por concepto de derecho de corta un valor de Q. " + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)) + " correspondiente al primer turno de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            });
          }
          content.push('\n');
          content.push(tbValorMadera);
          content.push('\n');
          content.push(
            {
              text: "El Titular del presente POA podrá convertir el volumen autorizado de troza a madera aserrada, block o flitch, tablón, tabla y regla. Así mismo podrá convertir a carbón el volumen autorizado de leña, para lo cual deberá aplicar los porcentajes de eficiencia determinado en la normativa forestal vigente. Según el  Acuerdo de Gerencia número 42-2003.",
              style: 'izquierda'
            }
          );
          if (ttTarea.ttSeguimientoTarea.aprobado != 1) {
            content.push('\n');
            content.push(
              {
                text: "El titular de la Licencia Forestal presentó documento que acredita haber realizado el pago correspondiente al impuesto del valor de la madera en píe de acuerdo al cuadro siguiente: ",
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          content.push(
            {
              text: "Impuesto del Valor de la Madera en Pie",
              style: 'izquierda',
              bold: true
            }
          );
          var fechaRecibo = poa.fechaRecibo;
          var strFechaRecibo = poa.fechaRecibo.toString();
          var position = strFechaRecibo.indexOf('-');
          if (position != 4) {
            poa.fechaRecibo = $filter('date')(new Date(poa.fechaRecibo), 'yyyy-MM-dd');
          }
          var strDatesRecibo = poa.fechaRecibo.toString().split('-');
          body = [
            [
              { text: 'Recibo serie: ' + poa.serie + ' Forma 63-A2', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: poa.recibo, style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Monto cancelado', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Q. ' + formatNumber.new(poa.monto), style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Fecha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDatesRecibo[2] + '/' + strDatesRecibo[1] + '/' + strDatesRecibo[0], style: 'izquierda', alignment: 'left', bold: true }
            ]
          ];
          var tbValorRecibo = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [150, 330],
              body: body
            }
          };
          var textoExonerar = {
            type: "none",
            ul: [
              {
                text: [
                  {
                    text: "De acuerdo con el artículo 87 de la Ley Forestal, inciso b: ",
                    style: 'izquierda'
                  },
                  {
                    text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                    style: 'izquierda',
                    italics: true,
                    bold: true
                  },
                  {
                    text: ", se exceptúa de este pago.",
                    style: 'izquierda'
                  }
                ]
              }
            ]
          };

          if (ttTarea.ttSeguimientoTarea.aprobado == 1) {
            exonerarValorMadera = true;
          } else {
            if (totalRodalesTurno > totalRodalesValorMadera) {
              if (totalRodalesValorMadera > 0) {
                exonerarValorMadera = true;
                textoExonerar = {
                  type: "none",
                  ul: [
                    {
                      text: [
                        {
                          text: "De acuerdo con el artículo 87 de la Ley Forestal, inciso b: ",
                          style: 'izquierda'
                        },
                        {
                          text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                          style: 'izquierda',
                          italics: true,
                          bold: true
                        },
                        {
                          text: ', se exceptúa de este pago a los rodales: ' + strRodales + '.',
                          style: 'izquierda'
                        }
                      ]
                    }
                  ]
                };
              }
            }
          }
          if (exonerarValorMadera) {
            content.push('\n');
            content.push(textoExonerar);
          } else {
            content.push(tbValorRecibo);
          }
          content.push('\n');
          var tipoGarantiaDesc = "Pago al Fondo Forestal Privativo";
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            if (poa.tcTipoGarantia == null) {
              tipoGarantiaDesc = 'Exento de garantía';
            } else {
              tipoGarantiaDesc = poa.tcTipoGarantia.tipoGarantiaDesc;
            }
          }
          body = [];
          content.push(
            {
              text: "El titular de la Licencia Forestal garantiza los compromisos adquiridos ante el INAB, mediante "
                + tipoGarantiaDesc + " de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            }
          );
          var especies = "";
          var modalidad = "";
          var log = [];
          var ids = [];
          var encontrado = false;
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            if (data.tcTipoGestion.tipoGestionId == 5) {
              if (data.rodalesRepoblacion == null) {
                data.rodalesRepoblacion = [];
              }
              if (data.rodalesRepoblacion.length > 0) {
                tmpData = data.rodalesRepoblacion;
              }
            }
          }
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < tmpData[i].repoblacion.length; j++) {
              if (i == 0 && j == 0) {
                especies = tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
              } else {
                encontrado = false;
                for (var n = 0; n < ids.length; n++) {
                  if (ids[n].especieId == tmpData[i].repoblacion[j].tcEspecie.especieId) {
                    encontrado = true;
                    break;
                  }
                }
                if (!encontrado) {
                  especies += "\n" + tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                  ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
                }
              }
              encontrado = false;
              for (var k = 0; k < log.length; k++) {
                if (log[k].sistemaRepoblacionId == tmpData[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                log.push(tmpData[i].repoblacion[j].tcSistemaRepoblacion);
              }
            }
          }
          areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            for (i = 0; i < log.length; i++) {
              if (i == 0) {
                modalidad = log[i].sistemaRepoblacionDesc;
              } else {
                modalidad += '\n' + log[i].sistemaRepoblacionDesc;
              }
            }
            content.push('\n');
            content.push(
              {
                text: "Compromiso de Repoblación Forestal",
                style: 'centrar',
                bold: true
              }
            );
            var serie = '';
            if (poa.numeroGarantia > 0) {
              if (poa.serieGarantia != null) {
                serie = ' (' + poa.serieGarantia + ' ' + poa.numeroGarantia + ')';
              }
            }
            if (poa.tcTipoGarantia == null) {
              var montoCompromiso = areaCompromiso * appSettings.montoCompromiso;
              montoCompromiso = parseFloat(montoCompromiso).toFixed(2);
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de repoblación forestal:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: 'Q. ' + formatNumber.new(montoCompromiso), style: 'izquierda', alignment: 'left' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Documento de garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: poa.tcTipoGarantia.tipoGarantiaDesc + serie, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área estimada para la garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de la garantía', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
                ]
              ];
            }
          } else {
            body = [
              [
                { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Recibo serie: ' + poa.serieGarantia + ' Forma 63-A2:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: poa.numeroGarantia, style: 'izquierda', alignment: 'left', bold: true }
              ],
              [
                { text: 'Área para estimar el pago:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Monto a pagar:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
              ]
            ];
          }
          var tbRepoblacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [200, 280],
              body: body
            }
          };
          content.push(tbRepoblacion);
          content.push('\n');
          content.push(
            {
              text: "El aprovechamiento forestal tendrá una vigencia de " + poa.noMeses + " meses a partir de la fecha de emisión de la presente resolución,  para la tala, extracción y transporte de los productos forestales  del  plan operativo anual de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento.",
              style: 'izquierda'
            }
          );
          content.push('\n');
          var strFechaIni = poa.fechaIni.toString();
          position = strFechaIni.indexOf('-');
          if (position != 4) {
            poa.fechaIni = $filter('date')(new Date(poa.fechaIni), 'yyyy-MM-dd');
            poa.fechaFin = $filter('date')(new Date(poa.fechaFin), 'yyyy-MM-dd');
          }
          var strDatesIni = poa.fechaIni.toString().split('-');
          var strDatesFin = poa.fechaFin.toString().split('-');
          content.push(
            {
              text: "Fecha de emisión: " + strDatesIni[2] + '/' + strDatesIni[1] + '/' + strDatesIni[0],
              style: 'izquierda',
              bold: true
            }
          );
          content.push(
            {
              text: "Fecha de vencimiento: " + strDatesFin[2] + '/' + strDatesFin[1] + '/' + strDatesFin[0],
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Todas las Licencia Forestales deben identificarse mediante un rótulo ubicado en un lugar visible, el cual debe contener como mínimo la información siguiente: Instituto Nacional de Bosques –INAB- siendo opcional colocar el logotipo respectivo, nombre del Titular, número de Licencia Forestal, el área a aprovechar y especies.",
              style: 'izquierda'
            }
          );
          content.push('\n');
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            content.push(
              {
                text: "El propietario del bosque se compromete a dar mantenimiento al área bajo compromiso, orientando sus acciones en una mejora continua a la calidad del bosque manejado, lo que garantizará su disponibilidad a futuro de acuerdo a la edad de rotación de la especie y los objetivos de manejo previamente establecidos. El cronograma de actividades del plan de manejo guiara la aplicación de actividades silviculturales.",
                style: 'izquierda'
              }
            );
          }
          if (poa.tcRegente != null) {
            content.push('\n');
            content.push(
              {
                text: "El Regente forestal corresponsable de la correcta ejecución del Plan de Manejo Forestal es " + poa.tcRegente.personaDesc + ", con Registro ante el INAB No. " + poa.tcRegente.rf,
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          content.push(
            {
              text: "Es responsabilidad del regente y del titular de la licencia forestal, hacer entrega de los informes trimestrales y finales adjuntando los duplicados de las notas de envío de productos forestales, utilizadas en el transporte de los productos forestales a extraer (art. 11 Reglamento de Transporte de Productos Forestales).",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "La cantidad de Notas de Envío de Bosque, Licencia o Concesión Forestal autorizadas para el transporte del producto Forestal es de: "
                + poa.cantidadEntregada + " Notas de Envío",
              style: 'izquierda'
            }
          );
          var cantidadEntregada = 0;
          var notas = poa.notas;
          if (notas.length > 0) {
            content.push('\n');
            content.push(
              {
                text: "De conformidad con lo establecido en el artículo 15 de la resolución de Junta Directiva No. JD.03.05.2020 \"Reglamento para el transporte de Productos Forestales\" se hace entrega de las notas de envío de bosque de Licencia o Concesión Forestal como se especifica en el siguiente cuadro:",
                style: 'izquierda'
              }
            );
            content.push('\n');
            var del = '';
            var al = '';
            for (var i = 0; i < notas.length; i++) {
              var nota = notas[i];
              if (i == 0) {
                del = 'LF-' + nota.inicio;
                al = 'LF-' + nota.fin;
              } else {
                del = del + '\nLF-' + nota.inicio;
                al = al + '\nLF-' + nota.fin;
              }
              cantidadEntregada += parseInt(nota.cantidad);
            }
            if (poa.fechaAutorizacion == null) {
              poa.fechaAutorizacion = new Date();
            }
            body = [
              [
                { text: 'Fecha de entrega', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Cantidad de NE autorizadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Cantidad de NE entregada', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Correlativos de las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Cantidad de NE pendientes por entregar *', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Número de factura para las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                '', '', '',
                { text: 'Del', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Al', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                '', ''
              ],
              [
                { text: $filter('date')(new Date(poa.fechaAutorizacion), 'dd/MM/yyyy'), style: 'medium', alignment: 'left' },
                { text: poa.totalNotas, style: 'medium', alignment: 'left' },
                { text: cantidadEntregada, style: 'medium', alignment: 'left' },
                { text: del, style: 'medium', alignment: 'left' },
                { text: al, style: 'medium', alignment: 'left' },
                { text: parseInt(poa.totalNotas) - parseInt(cantidadEntregada), style: 'medium', alignment: 'left' },
                { text: "Serie: " + poa.serieFactura + "\n No.: " + poa.noFactura, style: 'medium', alignment: 'left' }
              ]
            ];
            var tbNotasEnvio = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [60, 60, 60, 70, 70, 60, 60],
                body: body
              }
            };
            content.push('\n');
            content.push(tbNotasEnvio);
          }
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          var textoFooter = 'Codigo: LI-RE-025\nVersión: 2';
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            }, 
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        oficioSolicitudLicencia: function (poa, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var nombreSalida = 'oficio.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", en las coordenadas GTM X = "
                + finca.tcFinca.gtmX + ", Y = " + finca.tcFinca.gtmY + " del municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            } else {
              textoFinca = "finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion
                + ", en las coordenadas GTM X = " + finca.tcFinca.gtmX + ", GTM Y = " + finca.tcFinca.gtmY + " del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            }
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento
                  + " de fecha " + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", autorizada en el municipio "
                  + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                break;
              case 2:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el "
                  + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                  + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                break;
              case 3:
                textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                  + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " del departamento de " + finca.tcLibro.libroDesc;
                break;
              case 4:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          var representante = "El director subregional " + data.tcSubregion.tcSubregional.personaDesc + " del Instituto Nacional de Bosques INAB, con base a la licencia número "
            + ttTarea.ttSeguimientoTarea.enmienda + " de fecha " + $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd/MM/yyyy') + " emitida por "
            + ttTarea.tcPersonaTraslado.personaDesc + " Director Regional " + data.tcSubregion.tcRegion.regionDesc + ", con sede en el municipio de " + data.tcSubregion.tcRegion.tcMunicipio.municipioDesc
            + ", departamento de " + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc + "; otorgó Licencia de Aprovechamiento Forestal a favor de ";
          var afavor = "";
          if (data.representantes.length > 0) {
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              }
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            afavor = data.ttTipoPropietarioGestion.razonSocial;
          }
          if (data.representantes.length > 0) {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc;
              } else {
                afavor += ", " + data.personas[i].tcPersona.personaDesc;
              }
            }
            if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
              afavor = data.ttTipoPropietarioGestion.razonSocial;
            }
            representante = representante + afavor + ", representado legalmente por " + representanteNombre + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              } else {
                afavor += ". " + data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              }
            }
            representante = representante + afavor;
          }
          representante = representante + ". Para implementar el plan de manejo forestal elaborado por " + data.tcElaborador.personaDesc + " en la categoría de "
            + (data.categoriaProfesionId == 2 ? "Profesional" : "Técnico") + " con registro ante el INAB No. " + data.tcElaborador.rfn + ", en la " + textoFinca;
          var prefijoRegional = ttTarea.tcPersonaTraslado.prefijo;
          if (prefijoRegional == null) {
            prefijoRegional = '';
          } else {
            prefijoRegional += ' ';
          }
          var content = [
            {
              text: "NOTIFICACIÓN DE RECEPCIÓN DE REQUISITOS PARA LA EMISIÓN DE LA " + data.tcTipoGestion.tipoGestionDesc.toUpperCase(),
              style: 'centrar'
            }, '\n',
            {
              text: "Oficio No. " + ttTarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n',
            {
              text: prefijoRegional + ttTarea.tcPersonaTraslado.personaDesc,
              style: 'izquierda'
            },
            {
              text: "Director Regional " + data.tcSubregion.tcRegion.regionDesc,
              style: 'izquierda'
            },
            {
              text: "INAB " + data.tcSubregion.tcRegion.tcMunicipio.municipioDesc,
              style: 'izquierda'
            },
            {
              text: "Presente",
              style: 'izquierda'
            }, '\n',
            {
              text: "Estimado Director",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente me dirijo a usted para trasladarle los datos para la emisión de la " + data.tcTipoGestion.tipoGestionDesc
                + " correspondiente al turno número " + poa.noPoa + " autorizadas en la Resolución No. " + ttTarea.ttSeguimientoTarea.enmienda
                + " presentada por " + afavor + ", de la finca: " + textoFinca,
              style: 'izquierda'
            }, '\n'
          ];

          var alineacion = 'right';
          if (data.ttPlanificacionGestion.hayReforestacion != 1) {
            alineacion = 'left';
          }
          var body = [
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Año operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Volumen m³/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              '', '', '', '', '',
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              ''
            ]
          ];
          var dataRodal = data.rodal;
          tmpData = [];
          for (i = 0; i < dataRodal.length; i++) {
            if (dataRodal[i].turno == poa.noPoa) {
              tmpData.push(dataRodal[i]);
            }
          }
          var rodalesRepoblacion = data.rodalesRepoblacion;
          if (rodalesRepoblacion == null) {
            rodalesRepoblacion = [];
          }
          var tmpDataRepoblacion = [];
          if (rodalesRepoblacion.length > 0) {
            for (var i = 0; i < rodalesRepoblacion.length; i++) {
              var row = rodalesRepoblacion[i];
              if (row.turno == poa.noPoa) {
                tmpDataRepoblacion.push(row);
              }
            }
          }
          var valorTurno = 0;
          var total = 0;
          var volrExtraer = 0;
          var areaCompromiso = 0;
          var tVolumen = {
            area: 0,
            troza: 0,
            lenia: 0,
            total: 0,
            areaAprobada: 0
          }
          var strRodales = '';
          var totalRodalesTurno = 0, totalRodalesValorMadera = 0;
          var exonerarValorMadera = false;
          for (i = 0; i < tmpData.length; i++) {
            row = tmpData[i];
            var bodyEspecie = [];
            totalRodalesTurno++;
            if (!row.valorMadera) {
              if (strRodales.length > 0) {
                strRodales += ', ' + row.correlativo;
              } else {
                strRodales = row.correlativo;
              }
              totalRodalesValorMadera++;
            }
            for (j = 0; j < row.especies.length; j++) {
              row.especies[j].troza = parseFloat(row.especies[j].troza).toFixed(4);
              row.especies[j].lenia = parseFloat(row.especies[j].lenia).toFixed(4);
              total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
              total = parseFloat(total).toFixed(4);
              volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              valor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalValor = valor;
              valor = valor * 0.1;
              valorTurno += valor;
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalPagar = valor;
              itemEspecie = [
                { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                { text: row.especies[j].troza, style: 'tableRowSmall', alignment: 'right' },
                { text: row.especies[j].lenia, style: 'tableRowSmall', alignment: 'right' },
                { text: total, style: 'tableRowSmall', alignment: 'right' }
              ]
              bodyEspecie.push(itemEspecie);
              tVolumen.troza += parseFloat(row.especies[j].troza);
              tVolumen.lenia += parseFloat(row.especies[j].lenia);
              tVolumen.total += parseFloat(total);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [95, 49, 49, 49],
                body: bodyEspecie
              }, colSpan: 4, layout: 'noBorders'
            }
            var area = null;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
              area = parseFloat(area).toFixed(4);
            } else {
              area = 'Pago al Fondo Forestal Privativo';
            }
            item = [
              { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
              { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
              { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
              { text: parseFloat(row.area).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
              tbEspecie, {}, {}, {},
              { text: area, style: 'tableRowSmall', alignment: alineacion }
            ];
            body.push(item);
            tVolumen.areaAprobada += row.area;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              if (row.tcTipoCobertura.tipoCoberturaId == 3) {
                tVolumen.area += row.areaGarantia;
              } else {
                tVolumen.area += row.area;
              }
            }
          }
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            tVolumen.area = parseFloat(tVolumen.area).toFixed(4);
          } else {
            tVolumen.area = '---';
          }
          item = [
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left', colSpan: 3 }, {}, {},
            { text: parseFloat(tVolumen.areaAprobada).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left' },
            { text: parseFloat(tVolumen.troza).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(tVolumen.lenia).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(volrExtraer).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: tVolumen.area, style: 'tableRowSmall', alignment: 'right' }
          ];
          body.push(item);
          valorTurno = parseFloat(valorTurno).toFixed(2);
          var tbCompromiso = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [25, 25, 40, 40, 100, 50, 50, 50, 50],
              body: body
            }
          };
          content.push(
            {
              text: "El volumen total autorizado a extraer durante la ejecución del presente turno es de "
                + parseFloat(volrExtraer).toFixed(2) + " metros cúbicos, a realizarse en los rodales siguientes",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Distribución del aprovechamiento del bosque para el año de operaciones " + poa.anioOperacion,
              style: 'centrar'
            }
          );
          content.push(tbCompromiso);
          content.push('\n');
          body = [
            [
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            volrExtraer: 0,
            trozaValor: 0,
            leniaValor: 0,
            totalValor: 0,
            totalPagar: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            var bodyEspecie = [];
            for (j = 0; j < tmpData[i].especies.length; j++) {
              tmpData[i].especies[j].troza = parseFloat(tmpData[i].especies[j].troza).toFixed(4);
              tmpData[i].especies[j].lenia = parseFloat(tmpData[i].especies[j].lenia).toFixed(4);
              tmpData[i].especies[j].volrExtraer = parseFloat(tmpData[i].especies[j].volrExtraer).toFixed(4);
              itemEspecie = [
                { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
              ];
              bodyEspecie.push(itemEspecie);
              totales.troza += parseFloat(tmpData[i].especies[j].troza);
              totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
              totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
              totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
              totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 45, 45, 45, 45, 45, 45, 45],
                body: bodyEspecie
              }, colSpan: 8, layout: 'noBorders'
            }
            item = [
              { text: tmpData[i].turno, style: 'small', alignment: 'left' },
              { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
              tbEspecie, {}, {}, {}, {}, {}, {}, {}
            ];
            body.push(item);
          }
          item = [
            { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
            { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
          ];
          body.push(item);
          var tbValorMadera = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [25, 25, 50, 45, 45, 45, 45, 45, 45, 45],
              body: body
            }
          };
          if (totalRodalesTurno == totalRodalesValorMadera) {
            exonerarValorMadera = true;
          }
          if (ttTarea.ttSeguimientoTarea.aprobado == 1) {
            exonerarValorMadera = true;
          }
          if (exonerarValorMadera) {
            content.push({
              text: "El valor del pago al Fondo Forestal Privativo para el derecho de corta es de Q. " + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2))
                + " correspondiente al primer turno de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            });
          } else {
            content.push({
              text: "El titular de la licencia se obliga a pagar al Fondo Forestal Privativo del INAB por concepto de derecho de corta un valor de Q. "
                + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)) + " correspondiente al primer turno de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            });
          }
          content.push('\n');
          content.push(tbValorMadera);
          content.push('\n');
          if (!exonerarValorMadera) {
            content.push('\n');
            content.push({
              text: "El titular de la Licencia Forestal presentó documento que acredita haber realizado el pago correspondiente al impuesto del valor de la madera en píe de acuerdo al cuadro siguiente: ",
              style: 'izquierda'
            });
          }
          content.push('\n');
          content.push(
            {
              text: "Impuesto del Valor de la Madera en Pie",
              style: 'izquierda',
              bold: true
            }
          );
          var fechaRecibo = poa.fechaRecibo;
          var strFechaRecibo = poa.fechaRecibo.toString();
          var position = strFechaRecibo.indexOf('-');
          if (position != 4) {
            poa.fechaRecibo = $filter('date')(new Date(poa.fechaRecibo), 'yyyy-MM-dd');
          }
          var strDatesRecibo = poa.fechaRecibo.toString().split('-');
          body = [
            [
              { text: 'Recibo serie: ' + poa.serie + ' Forma 63-A2', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: poa.recibo, style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Monto cancelado', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Q. ' + formatNumber.new(poa.monto), style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Fecha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDatesRecibo[2] + '/' + strDatesRecibo[1] + '/' + strDatesRecibo[0], style: 'izquierda', alignment: 'left', bold: true }
            ]
          ];
          var tbValorRecibo = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [150, 330],
              body: body
            }
          };
          if (exonerarValorMadera) {
            strRodales += '';
            if (strRodales.length > 0) {
              strRodales = ' a los rodales: ' + strRodales;
            }
            content.push('\n');
            content.push(
              {
                type: "none",
                ul: [
                  {
                    text: [
                      {
                        text: "Con base al artículo 87 de la Ley Forestal, inciso b: ",
                        style: 'izquierda'
                      },
                      {
                        text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                        style: 'izquierda',
                        italics: true,
                        bold: true
                      },
                      {
                        text: ', se exceptúa de este pago' + strRodales + '.',
                        style: 'izquierda'
                      }
                    ]
                  }
                ]
              }
            );
          } else {
            content.push(tbValorRecibo);
          }
          content.push('\n');
          var tipoGarantiaDesc = "Pago al Fondo Forestal Privativo";
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            if (poa.tcTipoGarantia == null) {
              tipoGarantiaDesc = 'Exento de garantía';
            } else {
              tipoGarantiaDesc = poa.tcTipoGarantia.tipoGarantiaDesc;
            }
          }
          body = [];
          content.push(
            {
              text: "El titular de la Licencia Forestal garantiza los compromisos adquiridos ante el INAB, mediante "
                + tipoGarantiaDesc + " de acuerdo al cuadro siguiente:",
              style: 'izquierda'
            }
          );
          var especies = "";
          var modalidad = "";
          var log = [];
          var ids = [];
          var encontrado = false;
          var nuevosRodales = false;
          if (tmpDataRepoblacion.length > 0) {
            tmpData = tmpDataRepoblacion;
            nuevosRodales = true;
          }
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < tmpData[i].repoblacion.length; j++) {
              if (i == 0 && j == 0) {
                especies = tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
              } else {
                encontrado = false;
                for (var n = 0; n < ids.length; n++) {
                  if (ids[n].especieId == tmpData[i].repoblacion[j].tcEspecie.especieId) {
                    encontrado = true;
                    break;
                  }
                }
                if (!encontrado) {
                  especies += "\n" + tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                  ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
                }
              }
              encontrado = false;
              for (var k = 0; k < log.length; k++) {
                if (log[k].sistemaRepoblacionId == tmpData[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                log.push(tmpData[i].repoblacion[j].tcSistemaRepoblacion);
              }
            }
            if (nuevosRodales) {
              areaCompromiso += parseFloat(tmpData[i].area);
            } else {
              if (tmpData[i].tcTipoCobertura.tipoCoberturaId == 3) {
                areaCompromiso += parseFloat(tmpData[i].areaGarantia);
              } else {
                areaCompromiso += parseFloat(tmpData[i].area);
              }
            }
          }
          areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            for (i = 0; i < log.length; i++) {
              if (i == 0) {
                modalidad = log[i].sistemaRepoblacionDesc;
              } else {
                modalidad += '\n' + log[i].sistemaRepoblacionDesc;
              }
            }
            content.push('\n');
            content.push(
              {
                text: "COMPROMISO DE REPOBLACIÓN FORESTAL",
                style: 'centrar',
                bold: true
              }
            );
            var serie = '';
            if (poa.numeroGarantia > 0) {
              if (poa.serieGarantia != null) {
                serie = ' (' + poa.serieGarantia + ' ' + poa.numeroGarantia + ')';
              }
            }
            if (poa.tcTipoGarantia == null) {
              var montoCompromiso = areaCompromiso * appSettings.montoCompromiso;
              montoCompromiso = parseFloat(montoCompromiso).toFixed(2);
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de repoblación forestal:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: 'Q. ' + formatNumber.new(montoCompromiso), style: 'izquierda', alignment: 'left' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Documento de garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: poa.tcTipoGarantia.tipoGarantiaDesc + serie, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área estimada para la garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de la garantía', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
                ]
              ];
            }
          } else {
            body = [
              [
                { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Recibo serie: ' + poa.serieGarantia + ' Forma 63-A2:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: poa.numeroGarantia, style: 'izquierda', alignment: 'left', bold: true }
              ],
              [
                { text: 'Área para estimar el pago:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Monto a pagar:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
              ]
            ];
          }
          var tbRepoblacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [200, 280],
              body: body
            }
          };
          content.push(tbRepoblacion);
          content.push('\n');
          content.push(
            {
              text: "El aprovechamiento forestal tendrá una vigencia de " + poa.noMeses,
              style: 'izquierda'
            }
          );
          content.push('\n');
          var strFechaIni = poa.fechaIni.toString();
          position = strFechaIni.indexOf('-');
          if (position != 4) {
            poa.fechaIni = $filter('date')(new Date(poa.fechaIni), 'yyyy-MM-dd');
            poa.fechaFin = $filter('date')(new Date(poa.fechaFin), 'yyyy-MM-dd');
          }
          var strDatesIni = poa.fechaIni.toString().split('-');
          var strDatesFin = poa.fechaFin.toString().split('-');
          content.push(
            {
              text: "Fecha de emisión: " + strDatesIni[2] + '/' + strDatesIni[1] + '/' + strDatesIni[0],
              style: 'izquierda',
              bold: true
            }
          );
          content.push(
            {
              text: "Fecha de vencimiento: " + strDatesFin[2] + '/' + strDatesFin[1] + '/' + strDatesFin[0],
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: "La cantidad de Notas de Envío de Bosque, Licencia o Concesión Forestal autorizadas para el transporte del producto Forestal es de: "
                + poa.cantidadEntregada + " Notas de Envío de acuerdo al siguiente cuadro: ",
              style: 'izquierda'
            }
          );
          var cantidadEntregada = 0;
          var notas = poa.notas;
          if (notas.length > 0) {
            content.push('\n');
            var del = '';
            var al = '';
            for (var i = 0; i < notas.length; i++) {
              var nota = notas[i];
              if (i == 0) {
                del = 'LF-' + nota.inicio;
                al = 'LF-' + nota.fin;
              } else {
                del = del + '\nLF-' + nota.inicio;
                al = al + '\nLF-' + nota.fin;
              }
              cantidadEntregada += parseInt(nota.cantidad);
            }
            if (poa.fechaAutorizacion == null) {
              poa.fechaAutorizacion = new Date();
            }
            body = [
              [
                { text: 'Cantidad de NE autorizadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Cantidad de NE entregada', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Correlativos de las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Cantidad de NE pendientes por entregar *', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Número de factura para las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                '', '',
                { text: 'Del', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Al', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                '', ''
              ],
              [
                { text: poa.totalNotas, style: 'medium', alignment: 'left' },
                { text: cantidadEntregada, style: 'medium', alignment: 'left' },
                { text: del, style: 'medium', alignment: 'left' },
                { text: al, style: 'medium', alignment: 'left' },
                { text: parseInt(poa.totalNotas) - parseInt(cantidadEntregada), style: 'medium', alignment: 'left' },
                { text: "Serie: " + poa.serieFactura + "\n No.: " + poa.noFactura, style: 'medium', alignment: 'left' }
              ]
            ];
            var tbNotasEnvio = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [60, 60, 70, 70, 60, 120],
                body: body
              }
            };
            content.push('\n');
            content.push(tbNotasEnvio);
          }
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarLicenciaForestal: function (poa, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla, tareaId) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var nombreSalida = 'licencia.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "", textoDireccion = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
              if (!posesion && !propiedad) {
                textoDocumento = "propiedad";
                propiedad = true;
              } else {
                if (!propiedad) {
                  if (!posesion) {
                    textoDocumento = "propiedad";
                  } else {
                    textoDocumento = textoDocumento + " y propiedad";
                  }
                  propiedad = true;
                }
              }
            } else {
              if (!posesion && !propiedad) {
                textoDocumento = "posesión";
                posesion = true;
              } else {
                if (!posesion) {
                  if (!propiedad) {
                    textoDocumento = "posesión";
                  } else {
                    textoDocumento = textoDocumento + " y posesión";
                  }
                  posesion = true;
                }
              }
            }
            if (textoFinca.length > 0) {
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion + ", en las coordenadas GTM X = "
                + finca.tcFinca.gtmX + ", Y = " + finca.tcFinca.gtmY + " del municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            } else {
              textoFinca = "finca " + finca.tcFinca.fincaDesc + "  ubicada en el lugar conocido como " + finca.tcFinca.direccion
                + ", en las coordenadas GTM X = " + finca.tcFinca.gtmX + ", GTM Y = " + finca.tcFinca.gtmY + " del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + " inmueble acreditado mediante ";
            }
            var documentos = finca.documentos;
            if (documentos == null) {
              documentos = [];
            }
            var strDocumentos = '';
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += '; número ' + documentoFinca.numeroDocumento + ' de fecha ' + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy')
                      + ", autorizada en el municipio " + documentoFinca.tcMunicipioEmite.municipioDesc
                      + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + documentoFinca.notario;
                  }
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Testimonio de escritura pública de derechos posesorios número " + finca.numeroDocumento + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", autorizada en el municipio " + finca.tcMunicipioEmite.municipioDesc
                    + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el notario " + finca.notario;
                }
                break;
              case 2:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de "
                      + documentoFinca.tcMunicipioEmite.municipioDesc + " del departamento de " + documentoFinca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                      + $filter('date')(new Date(documentoFinca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + documentoFinca.notario;
                  }
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario + strDocumentos;
                } else {
                  textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio de " + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + " de fecha "
                    + $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy') + ", por el Notario " + finca.notario;
                }
                break;
              case 3:
                if (documentos.length > 0) {
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos += "; " + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                      + documentoFinca.numeroDocumento + ", folio " + documentoFinca.folio + ", libro " + documentoFinca.libro + " de " + documentoFinca.tcLibro.libroDesc;
                  }
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca "
                    + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc + strDocumentos;
                } else {
                  textoFinca = textoFinca + finca.tcTipoPropiedad.tipoPropiedadDesc + ", extendida por el Registro General de la Propiedad, inscrita con número de finca " + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc;
                }
                break;
              case 4:
                textoFinca = textoFinca + "Acta Notarial de Declaración Jurada de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
              default:
                break;
            }
          }
          var representanteNombre = "";
          var representante = "";
          var afavor = 'Se tiene a la vista para resolver la solicitud de ' + data.tcTipoGestion.tipoGestionDesc + ' según consta en el expediente administrativo No. '
            + data.expediente + ', solicitud presentada por ';
          if (data.representantes.length > 0) {
            for (i = 0; i < data.representantes.length; i++) {
              if (i == 0) {
                representanteNombre = data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              } else {
                representanteNombre = representanteNombre + ". " + data.representantes[i].tcPersona.personaDesc + " quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- " + data.representantes[i].tcPersona.cui;
              }
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            afavor = data.ttTipoPropietarioGestion.razonSocial;
          }
          if (data.representantes.length > 0) {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc;
              } else {
                afavor += ", " + data.personas[i].tcPersona.personaDesc;
              }
            }
            if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
              afavor = data.ttTipoPropietarioGestion.razonSocial;
            }
            representante = representante + afavor + ", representado legalmente por " + representanteNombre + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              } else {
                afavor += ". " + data.personas[i].tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + data.personas[i].tcPersona.cui + " extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-";
              }
            }
            representante = representante + afavor;
          }
          representante = representante + '. Para ejecutar el Plan de Manejo Forestal en el turno No. '
            + poa.noPoa + ', en la ' + textoFinca;
          var content = [
            {
              text: "LICENCIA DE APROVECHAMIENTO FORESTAL",
              style: 'centrar'
            }, '\n',
            {
              text: "LICENCIA FORESTAL No. " + ttTarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            }, '\n\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN REGIONAL " + data.tcSubregion.tcRegion.regionDesc + ", CON SEDE EN EL DEPARTAMENTO DE "
                + data.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ". " + strDiaResolucion + " DIAS DEL MES DE "
                + getMonth(mes).toUpperCase() + " DE " + strAnioResolucion,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal, establece que el Instituto Nacional de Bosques –INAB-, es el órgano de dirección y autoridad del Sector Publico Agrícola en materia forestal y que como tal es el único que puede otorgar, denegar, supervisar, prorrogar y cancelar Licencias Forestales que será la autorización para implementar el Plan de Manejo Forestal y que cualquier aprovechamiento forestal de madera u otros productos leñosos, excepto los de consumo familiar, los de plantaciones voluntarias y sistemas agroforestales plantados voluntariamente, podrá hacerse solamente mediante Licencia Forestal que el Instituto Nacional de Bosques, otorgará exclusivamente al propietario o poseedor legitimo del terreno o del área forestal.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que la Resolución número JD 01.43.2005, de Junta Directiva del Instituto Nacional de Bosques, Reglamento de la Ley Forestal, faculta a los Directores Regionales para otorgar o denegar las Licencias Forestales para ejecutar los Planes de Manejo Forestal, en las Direcciones Regionales en las diferentes regiones forestales del país.',
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Con base a lo considerado y en lo preceptuado por los artículos 28, 30, 126 y 154 de la Constitución Política de la República de Guatemala; 1, 2, 3, 4, 5 y 6 del Decreto No. 119-96 del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo; 1, 2, 3, 4, 5, 6, 9, 16, 48, 49, 50, 51, 52, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto No. 101-96 del Congreso de la República de Guatemala, Ley Forestal; 1, 2, 3, 4, 6 y 8 del Decreto No. 122-96 del Congreso de la República de Guatemala, Ley Reguladora del Registro, Autorización y Uso de Motosierras; 1, 40, 41, 47, 48, 51, 52, 55 y 56 Resolución de Junta Directiva del Instituto Nacional de Bosques JD.01.43.2005, Reglamento de la Ley Forestal; 1, 2, 3, 4, 5, 6, 8, 10, 11, 13, 14, 15, 16, 17, 18, 20, 21 de la Resolución de Junta Directiva del Instituto Nacional de Bosques JD.03.05.2020, Reglamento para el Transporte de Productos Forestales.',
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n'
          ];
          content.push(
            {
              start: 1,
              ol: [
                {
                  text: 'Aprobar la ' + data.tcTipoGestion.tipoGestionDesc + ' que corresponde al Plan Operativo Anual y el turno número '
                    + poa.noPoa + ' en el inmueble: ' + textoFinca,
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              start: 2,
              ol: [
                {
                  text: 'Aprobar las actividades de corta y extracción del producto forestal de acuerdo al cuadro siguiente:',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          var alineacion = 'right';
          if (data.ttPlanificacionGestion.hayReforestacion != 1) {
            alineacion = 'left';
          }
          var body = [
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Año operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Volumen m³/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              '', '', '', '', '',
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              ''
            ]
          ];
          var dataRodal = data.rodal;
          tmpData = [];
          for (i = 0; i < dataRodal.length; i++) {
            if (dataRodal[i].turno == poa.noPoa) {
              tmpData.push(dataRodal[i]);
            }
          }
          var valorTurno = 0;
          var total = 0;
          var volrExtraer = 0;
          var areaCompromiso = 0;
          var tVolumen = {
            area: 0,
            troza: 0,
            lenia: 0,
            total: 0,
            areaAprobada: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            row = tmpData[i];
            var bodyEspecie = [];
            for (j = 0; j < row.especies.length; j++) {
              row.especies[j].troza = parseFloat(row.especies[j].troza).toFixed(4);
              row.especies[j].lenia = parseFloat(row.especies[j].lenia).toFixed(4);
              total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
              total = parseFloat(total);
              volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              valor = parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalValor = valor;
              valor = valor * 0.1;
              valorTurno += valor;
              valor = parseFloat(valor).toFixed(2);
              tmpData[i].especies[j].totalPagar = valor;
              itemEspecie = [
                { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                { text: row.especies[j].troza, style: 'tableRowSmall', alignment: 'right' },
                { text: row.especies[j].lenia, style: 'tableRowSmall', alignment: 'right' },
                { text: parseFloat(total).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
              ]
              bodyEspecie.push(itemEspecie);
              tVolumen.troza += parseFloat(row.especies[j].troza);
              tVolumen.lenia += parseFloat(row.especies[j].lenia);
              tVolumen.total += parseFloat(total);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [95, 49, 49, 49],
                body: bodyEspecie
              }, colSpan: 4, layout: 'noBorders'
            }
            var area = null;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
              area = parseFloat(area).toFixed(4);
            } else {
              area = 'Pago al Fondo Forestal Privativo';
            }
            item = [
              { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
              { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
              { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
              { text: parseFloat(row.area).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
              tbEspecie, {}, {}, {},
              { text: area, style: 'tableRowSmall', alignment: alineacion }
            ];
            body.push(item);
            tVolumen.areaAprobada += row.area;
            if (data.ttPlanificacionGestion.hayReforestacion == 1) {
              if (row.tcTipoCobertura.tipoCoberturaId == 3) {
                tVolumen.area += row.areaGarantia;
              } else {
                tVolumen.area += row.area;
              }
            }
          }
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            tVolumen.area = parseFloat(tVolumen.area).toFixed(4);
          } else {
            tVolumen.area = '---';
          }
          item = [
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left', colSpan: 3 }, {}, {},
            { text: parseFloat(tVolumen.areaAprobada).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: 'TOTAL', style: 'tableRowSmall', alignment: 'left' },
            { text: parseFloat(tVolumen.troza).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(tVolumen.lenia).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: parseFloat(volrExtraer).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
            { text: tVolumen.area, style: 'tableRowSmall', alignment: 'right' }
          ];
          body.push(item);
          valorTurno = parseFloat(valorTurno).toFixed(2);
          var tbCompromiso = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [25, 25, 40, 40, 100, 50, 50, 50, 50],
              body: body
            }
          };
          content.push(tbCompromiso);
          content.push(
            {
              text: "El Titular del presente POA podrá convertir el volumen autorizado de troza a madera aserrada, block o flitch, tablón, tabla y regla. Así mismo podrá convertir a carbón el volumen autorizado de leña, para lo cual deberá aplicar los porcentajes de eficiencia determinado en la normativa forestal vigente. Según el  Acuerdo de Gerencia número 42-2003.",
              style: 'izquierda', fontSize: 9
            }
          );
          content.push('\n');
          var strFechaIni = poa.fechaIni.toString();
          position = strFechaIni.indexOf('-');
          if (position != 4) {
            poa.fechaIni = $filter('date')(new Date(poa.fechaIni), 'yyyy-MM-dd');
            poa.fechaFin = $filter('date')(new Date(poa.fechaFin), 'yyyy-MM-dd');
          }
          var strDatesIni = poa.fechaIni.toString().split('-');
          var strDatesFin = poa.fechaFin.toString().split('-');
          content.push(
            {
              start: 3,
              ol: [
                {
                  text: 'El aprovechamiento forestal tendrá una vigencia de ' + poa.noMeses
                    + ' meses a partir de la fecha de emisión de la presente resolución, para la tala, extracción y transporte de los productos forestales  del  plan operativo anual de conformidad con lo que establece la Ley Forestal Decreto 101-96 y su Reglamento.'
                    + '\n\nFecha de emisión: ' + strDatesIni[2] + '/' + strDatesIni[1] + '/' + strDatesIni[0]
                    + '\nFecha de vencimiento: ' + strDatesFin[2] + '/' + strDatesFin[1] + '/' + strDatesFin[0],
                  style: 'izquierda'
                }
              ]
            }
          );
          body = [
            [
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            volrExtraer: 0,
            trozaValor: 0,
            leniaValor: 0,
            totalValor: 0,
            totalPagar: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            var bodyEspecie = [];
            for (j = 0; j < tmpData[i].especies.length; j++) {
              itemEspecie = [
                { text: tmpData[i].especies[j].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: tmpData[i].especies[j].troza, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].lenia, style: 'small', alignment: 'right' },
                { text: tmpData[i].especies[j].volrExtraer, style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(tmpData[i].especies[j].totalPagar), style: 'small', alignment: 'right' }
              ];
              bodyEspecie.push(itemEspecie);
              totales.troza += parseFloat(tmpData[i].especies[j].troza);
              totales.lenia += parseFloat(tmpData[i].especies[j].lenia);
              totales.volrExtraer += parseFloat(tmpData[i].especies[j].volrExtraer);
              totales.trozaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.trozaValor);
              totales.leniaValor += parseFloat(tmpData[i].especies[j].ttEspecieEvaluada.leniaValor);
              totales.totalValor += parseFloat(tmpData[i].especies[j].totalValor);
              totales.totalPagar += parseFloat(tmpData[i].especies[j].totalPagar);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 45, 45, 45, 45, 45, 45, 45],
                body: bodyEspecie
              }, colSpan: 8, layout: 'noBorders'
            }
            item = [
              { text: tmpData[i].turno, style: 'small', alignment: 'left' },
              { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
              tbEspecie, {}, {}, {}, {}, {}, {}, {}
            ];
            body.push(item);
          }
          item = [
            { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
            { text: parseFloat(totales.troza).toFixed(2), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.lenia).toFixed(2), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.volrExtraer).toFixed(2), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
          ];
          body.push(item);
          var tbValorMadera = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [25, 25, 50, 45, 45, 45, 45, 45, 45, 45],
              body: body
            }
          };
          content.push('\n');
          content.push(
            {
              start: 4,
              ol: [
                {
                  text: 'El titular de la Licencia Forestal presentó documento que acredita haber realizado el pago correspondiente al impuesto del valor de la madera en píe de acuerdo al cuadro siguiente: ',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push(
            {
              text: "",
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: "Impuesto del Valor de la Madera en Pie",
              style: 'centrar',
              bold: true
            }
          );
          var fechaRecibo = poa.fechaRecibo;
          var strFechaRecibo = poa.fechaRecibo.toString();
          var position = strFechaRecibo.indexOf('-');
          if (position != 4) {
            poa.fechaRecibo = $filter('date')(new Date(poa.fechaRecibo), 'yyyy-MM-dd');
          }
          var strDatesRecibo = poa.fechaRecibo.toString().split('-');
          body = [
            [
              { text: 'Recibo serie: ' + poa.serie + ' Forma 63-A2', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: poa.recibo, style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Monto cancelado', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Q. ' + formatNumber.new(poa.monto), style: 'izquierda', alignment: 'left', bold: true }
            ],
            [
              { text: 'Fecha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDatesRecibo[2] + '/' + strDatesRecibo[1] + '/' + strDatesRecibo[0], style: 'izquierda', alignment: 'left', bold: true }
            ]
          ];
          var tbValorRecibo = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [150, 330],
              body: body
            }
          };
          if (ttTarea.ttSeguimientoTarea.aprobado == 1) {
            content.push('\n');
            content.push(
              {
                type: "none",
                ul: [
                  {
                    text: [
                      {
                        text: "Con base al artículo 87 de la Ley Forestal, inciso b: ",
                        style: 'izquierda'
                      },
                      {
                        text: '"Bosques que hayan sido manejados según el Plan de Manejo aprobado por INAB y que hayan cumplido con todos los compromisos establecidos en la licencia"',
                        style: 'izquierda',
                        italics: true,
                        bold: true
                      },
                      {
                        text: ", se exceptúa de este pago.",
                        style: 'izquierda'
                      }
                    ]
                  }
                ]
              }
            );
          } else {
            content.push(tbValorRecibo);
          }
          content.push('\n');
          var tipoGarantiaDesc = "Pago al Fondo Forestal Privativo";
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            if (poa.tcTipoGarantia == null) {
              tipoGarantiaDesc = 'Exento de garantía';
            } else {
              tipoGarantiaDesc = poa.tcTipoGarantia.tipoGarantiaDesc;
            }
          }
          body = [];
          content.push(
            {
              start: 5,
              ol: [
                {
                  text: 'El titular de la Licencia Forestal garantiza los compromisos adquiridos ante el INAB, mediante '
                    + tipoGarantiaDesc + ' de acuerdo al cuadro siguiente: ',
                  style: 'izquierda'
                }
              ]
            }
          );
          var especies = "";
          var modalidad = "";
          var log = [];
          var ids = [];
          var encontrado = false;
          var tmpRodalesRepoblacion = data.rodalesRepoblacion;
          if (tmpRodalesRepoblacion == null) {
            tmpRodalesRepoblacion = [];
          }
          var cambioUso = false;
          if (tmpRodalesRepoblacion.length > 0) {
            cambioUso = true;
            tmpData = [];
            for (i = 0; i < tmpRodalesRepoblacion.length; i++) {
              if (tmpRodalesRepoblacion[i].turno == poa.noPoa) {
                tmpData.push(tmpRodalesRepoblacion[i]);
              }
            }
          }
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < tmpData[i].repoblacion.length; j++) {
              if (i == 0 && j == 0) {
                especies = tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
              } else {
                encontrado = false;
                for (var n = 0; n < ids.length; n++) {
                  if (ids[n].especieId == tmpData[i].repoblacion[j].tcEspecie.especieId) {
                    encontrado = true;
                    break;
                  }
                }
                if (!encontrado) {
                  especies += "\n" + tmpData[i].repoblacion[j].tcEspecie.nombreCientifico;
                  ids.push({ especieId: tmpData[i].repoblacion[j].tcEspecie.especieId });
                }
              }
              encontrado = false;
              for (var k = 0; k < log.length; k++) {
                if (log[k].sistemaRepoblacionId == tmpData[i].repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                log.push(tmpData[i].repoblacion[j].tcSistemaRepoblacion);
              }
            }
            if (tmpData[i].areaGarantia == null) {
              areaCompromiso += parseFloat(tmpData[i].area);
            } else {
              if (tmpData[i].tcTipoCobertura.tipoCoberturaId == 3) {
                areaCompromiso += parseFloat(tmpData[i].areaGarantia);
              } else {
                areaCompromiso += parseFloat(tmpData[i].area);
              }
            }
          }
          areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            for (i = 0; i < log.length; i++) {
              if (i == 0) {
                modalidad = log[i].sistemaRepoblacionDesc;
              } else {
                modalidad += '\n' + log[i].sistemaRepoblacionDesc;
              }
            }
            content.push('\n');
            content.push(
              {
                text: "Compromiso de la repoblación forestal",
                style: 'centrar',
                bold: true
              }
            );
            var serie = '';
            if (poa.numeroGarantia > 0) {
              if (poa.serieGarantia != null) {
                serie = ' (' + poa.serieGarantia + ' ' + poa.numeroGarantia + ')';
              }
            }
            if (poa.tcTipoGarantia == null) {
              var montoCompromiso = areaCompromiso * appSettings.montoCompromiso;
              montoCompromiso = parseFloat(montoCompromiso).toFixed(2);
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de repoblación forestal:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: 'Q. ' + formatNumber.new(montoCompromiso), style: 'izquierda', alignment: 'left' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Modalidad de recuperación del área:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: modalidad, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Especies a manejar en el compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: especies, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Documento de garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: poa.tcTipoGarantia.tipoGarantiaDesc + serie, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área de compromiso:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: areaCompromiso, style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Área estimada para la garantía:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
                ],
                [
                  { text: 'Monto de la garantía', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
                ]
              ];
            }
          } else {
            body = [
              [
                { text: 'Fecha de emisión:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(poa.fechaGarantia), 'dd/MM/yyyy'), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Recibo serie: ' + poa.serieGarantia + ' Forma 63-A2:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: poa.numeroGarantia, style: 'izquierda', alignment: 'left', bold: true }
              ],
              [
                { text: 'Área para estimar el pago:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: parseFloat(poa.areaGarantia).toFixed(4), style: 'izquierda', alignment: 'left' }
              ],
              [
                { text: 'Monto a pagar:', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'left' },
                { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', alignment: 'left' }
              ]
            ];
          }
          var tbRepoblacion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [200, 280],
              body: body
            }
          };
          content.push(tbRepoblacion);
          if (poa.tcRegente != null) {
            content.push('\n');
            content.push(
              {
                start: 6,
                ol: [
                  {
                    text: 'El o los Titulares de la Licencia Forestal y el Regente Forestal ' + poa.tcRegente.personaDesc
                      + ' con Registro ante el INAB No. ' + poa.tcRegente.rf
                      + ', quedan obligados a cumplir con lo preceptuado en el Reglamento para el Transporte de Productos Forestales y su Procedencia Lícita y demás normas vigentes. Previo a iniciar las operaciones del aprovechamiento forestal, debe delimitar claramente el área a intervenir para el turno de operaciones y respetar los límites de dicha área de conformidad con el Plan de Manejo Forestal aprobado',
                    style: 'izquierda'
                  }
                ]
              }
            );
          }
          content.push('\n');
          content.push(
            {
              start: 7,
              ol: [
                {
                  text: 'Todas las Licencia Forestales deben identificarse mediante un rótulo ubicado en un lugar visible, el cual debe contener como mínimo la información siguiente: Instituto Nacional de Bosques –INAB- siendo opcional colocar el logotipo respectivo, nombre del Titular, número de Licencia Forestal, el área a aprovechar y especies.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          var numeral = 8;
          if (data.ttPlanificacionGestion.hayReforestacion == 1) {
            content.push(
              {
                start: numeral,
                ol: [
                  {
                    text: 'El propietario del bosque se compromete a dar mantenimiento al área bajo compromiso, orientando sus acciones en una mejora continua a la calidad del bosque manejado, lo que garantizará su disponibilidad a futuro de acuerdo a la edad de rotación de la especie y los objetivos de manejo previamente establecidos. El cronograma de actividades del plan de manejo guiara la aplicación de actividades silviculturales.',
                    style: 'izquierda'
                  }
                ]
              }
            );
            numeral++;
          }
          content.push('\n');
          content.push(
            {
              start: numeral,
              ol: [
                {
                  text: 'Es responsabilidad del regente y del titular de la licencia forestal, hacer entrega de los informes trimestrales y finales adjuntando los duplicados de las notas de envío de productos forestales, utilizadas en el transporte de los productos forestales a extraer (art. 11 Reglamento de Transporte de Productos Forestales).',
                  style: 'izquierda'
                }
              ]
            }
          );
          numeral++;
          content.push('\n');
          content.push(
            {
              start: numeral,
              ol: [
                {
                  text: 'La cantidad de Notas de Envío de Bosque, Licencia o Concesión Forestal autorizadas para el transporte del producto Forestal es de: '
                    + poa.cantidadEntregada + ' Notas de Envío',
                  style: 'izquierda'
                }
              ]
            }
          );
          numeral++;
          var cantidadEntregada = 0;
          var notas = poa.notas;
          if (notas.length > 0) {
            content.push('\n');
            content.push(
              {
                start: numeral,
                ol: [
                  {
                    text: 'De conformidad con lo establecido en el artículo 15 de la resolución de Junta Directiva No. JD.03.05.2020 "Reglamento para el transporte de Productos Forestales" se hace entrega de las notas de envío de bosque de Licencia o Concesión Forestal como se especifica en el siguiente cuadro:',
                    style: 'izquierda'
                  }
                ]
              }
            );
            numeral++;
            content.push('\n');
            var del = '';
            var al = '';
            for (var i = 0; i < notas.length; i++) {
              var nota = notas[i];
              if (i == 0) {
                del = 'LF-' + nota.inicio;
                al = 'LF-' + nota.fin;
              } else {
                del = del + '\nLF-' + nota.inicio;
                al = al + '\nLF-' + nota.fin;
              }
              cantidadEntregada += parseInt(nota.cantidad);
            }
            if (poa.fechaAutorizacion == null) {
              poa.fechaAutorizacion = new Date();
            }
            body = [
              [
                { text: 'Fecha de entrega', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Cantidad de NE autorizadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Cantidad de NE entregada', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
                { text: 'Correlativos de las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
                { text: 'Número de factura para las NE entregadas', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
              ],
              [
                '', '', '',
                { text: 'Del', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Al', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
                ''
              ],
              [
                { text: $filter('date')(new Date(poa.fechaAutorizacion), 'dd/MM/yyyy'), style: 'medium', alignment: 'left' },
                { text: poa.totalNotas, style: 'medium', alignment: 'left' },
                { text: cantidadEntregada, style: 'medium', alignment: 'left' },
                { text: del, style: 'medium', alignment: 'left' },
                { text: al, style: 'medium', alignment: 'left' },
                { text: "Serie: " + poa.serieFactura + "\n No.: " + poa.noFactura, style: 'medium', alignment: 'left' }
              ]
            ];
            var tbNotasEnvio = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 2,
                widths: [60, 60, 60, 70, 70, 120],
                body: body
              }
            };
            content.push('\n');
            content.push(tbNotasEnvio);
          }
          content.push('\n');
          var tcFinca = data.fincas[0].tcFinca;
          content.push(
            {
              start: numeral,
              ol: [
                {
                  text: "Notifíquese  al interesado y a la Municipalidad de " + tcFinca.tcMunicipio.municipioDesc + ", departamento de "
                    + tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ".",
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director regional " + data.tcSubregion.tcRegion.regionDesc,
              style: 'centrar'
            }
          );
          var textoFooter = 'Codigo: LI-RE-017\nVersión: 2';
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          if (tareaId != null) {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              Upload.upload({
                url: appSettings.urlServiceBase + 'file/upload',
                method: 'POST',
                file: blob,
                sendFieldsAs: 'form'
              }).then(function (resp) {
                var data = resp.data;
                if (data.status == 'OK') {
                  var obj = data.data[0];
                  var documento = {
                    tareaId: tareaId,
                    tipoDocumentoTareaId: 4,
                    rutaArchivo: obj.rutaArchivo,
                    reemplazar: 1
                  }
                  tareaService.agregarDocumento(documento).then(function (res) {
                    if (res.status != "OK") {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error(resp.data.message)
                }
              }, function (resp) {
                toastr.error('Error status: ' + resp.status);
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var avance = progressPercentage;
              });
            });
          } else {
            pdfMake.createPdf(docDefinition).download(nombreSalida);
          }
        },
        generarEntregaDocumentoPoa: function (poa, usuarioDesc, dataEmpresa, sigla, esPoa) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          if (esPoa == null) {
            esPoa = true;
          }
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var textoFinca = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i > 0) {
              textoFinca = textoFinca + ", " + finca.tcFinca.fincaDesc;
            } else {
              textoFinca = finca.tcFinca.fincaDesc;
            }
          }
          var afavor = "";
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            afavor = data.ttTipoPropietarioGestion.razonSocial;
          } else {
            for (i = 0; i < data.personas.length; i++) {
              if (i == 0) {
                afavor = data.personas[i].tcPersona.personaDesc;
              } else {
                afavor += + ", " + data.personas[i].tcPersona.personaDesc;
              }
            }
          }
          var content = [
            {
              text: "NOTIFICACIÓN DE DOCUMENTO DE GARANTÍA",
              style: 'centrar'
            }, '\n\n',
            {
              text: "Of. " + ttTarea.ttSeguimientoTarea.fundamento.oficiom,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n\n',
            {
              text: "Departamento de Monitoreo Forestal y Obligaciones de Repoblación Forestal",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Muy atentamente me dirijo a ustedes para saludarlos y desearles muchas bendiciones en todas las actividades que realicen.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Por este medio hago entrega el documento original de la garantía con las características siguientes:",
              style: 'izquierda'
            }, '\n'
          ];
          var dataCompromiso = data.rodal;
          var tmpData = [];
          for (var i = 0; i < dataCompromiso.length; i++) {
            if (parseInt(dataCompromiso[i].turno) == 1) {
              tmpData.push(dataCompromiso[i]);
            }
          }
          var strTratamiento = "";
          var idTratamiento = [];
          var encontrado = false;
          var areaCompromiso = 0;
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < tmpData[i].especies.length; j++) {
              encontrado = false;
              for (var k = 0; k < idTratamiento.length; k++) {
                if (idTratamiento[k].id == tmpData[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId) {
                  encontrado = true;
                }
              }
              if (!encontrado) {
                idTratamiento.push(
                  { id: tmpData[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId }
                );
                if (strTratamiento.length > 0) {
                  strTratamiento += ', ' + tmpData[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
                } else {
                  strTratamiento = tmpData[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalDesc;
                }
              }
            }
            areaCompromiso += parseFloat(tmpData[i].area);
          }
          areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
          var fechaIni = poa.fechaGarantia.toString();
          var posision = fechaIni.indexOf('-');
          if (posision == -1 || posision > 10 || fechaIni.indexOf('T') > 1) {
            poa.fechaIni = $filter('date')(new Date(poa.fechaGarantia), 'yyyy-MM-dd');
          }
          var strDatesFin = null;
          if (poa.fechaVencimiento == null) {
            fechaFin = poa.fechaFin.toString();
            posision = fechaFin.indexOf('-');
            if (posision == -1 || posision > 10 || fechaFin.indexOf('T') > 1) {
              poa.fechaFin = $filter('date')(new Date(poa.fechaFin), 'yyyy-MM-dd');
            }
            strDatesFin = poa.fechaFin.split('-');
          } else {
            fechaFin = poa.fechaVencimiento.toString();
            posision = fechaFin.indexOf('-');
            if (posision == -1 || posision > 10 || fechaFin.indexOf('T') > 1) {
              poa.fechaVencimiento = $filter('date')(new Date(poa.fechaVencimiento), 'yyyy-MM-dd');
            }
            strDatesFin = poa.fechaVencimiento.split('-');
          }
          var strDatesIni = poa.fechaIni.split('-');
          var serieGarantia = '';
          var strResolucion = 'Licencia Forestal número';
          if (!esPoa) {
            strResolucion = 'Número de Resolución';
          }
          if (poa.tcTipoGarantia == null) {
            body = [
              [
                { text: "c.", style: 'izquierda' },
                { text: 'Área de compromiso', style: 'izquierda' },
                { text: areaCompromiso, style: 'izquierda', bold: true }
              ],
              [
                { text: "d.", style: 'izquierda' },
                { text: 'Tratamiento silvicultural', style: 'izquierda' },
                { text: strTratamiento, style: 'izquierda', bold: true }
              ],
              [
                { text: "g.", style: 'izquierda' },
                { text: 'Período que cubre', style: 'izquierda' },
                { text: strDatesIni[2] + '/' + strDatesIni[1] + '/' + strDatesIni[0] + " al " + strDatesFin[2] + '/' + strDatesFin[1] + '/' + strDatesFin[0], style: 'izquierda', bold: true }
              ],
              [
                { text: "h.", style: 'izquierda' },
                { text: 'Licencia Forestal número', style: 'izquierda' },
                { text: ttTarea.ttSeguimientoTarea.enmienda, style: 'izquierda', bold: true }
              ],
              [
                { text: "i.", style: 'izquierda' },
                { text: strResolucion, style: 'izquierda' },
                { text: afavor, style: 'izquierda', bold: true }
              ]
            ];
          } else {
            if (poa.serieGarantia != null || poa.tcTipoGarantia.tipoGarantiaId == 2 || poa.tcTipoGarantia.tipoGarantiaId == 6) {
              serieGarantia = poa.serieGarantia + ' ';
            }
            body = [
              [
                { text: "a.", style: 'izquierda' },
                { text: 'Documento de garantía', style: 'izquierda' },
                { text: data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc, style: 'izquierda', bold: true }
              ],
              [
                { text: "b.", style: 'izquierda' },
                { text: 'Número', style: 'izquierda' },
                { text: serieGarantia + poa.numeroGarantia, style: 'izquierda', bold: true }
              ],
              [
                { text: "c.", style: 'izquierda' },
                { text: 'Área de compromiso', style: 'izquierda' },
                { text: areaCompromiso, style: 'izquierda', bold: true }
              ],
              [
                { text: "d.", style: 'izquierda' },
                { text: 'Tratamiento silvicultural', style: 'izquierda' },
                { text: strTratamiento, style: 'izquierda', bold: true }
              ],
              [
                { text: "e.", style: 'izquierda' },
                { text: 'Área para estimar el monto', style: 'izquierda' },
                { text: poa.areaGarantia, style: 'izquierda', bold: true }
              ],
              [
                { text: "f.", style: 'izquierda' },
                { text: 'Monto', style: 'izquierda' },
                { text: "Q. " + formatNumber.new(poa.montoGarantia), style: 'izquierda', bold: true }
              ],
              [
                { text: "g.", style: 'izquierda' },
                { text: 'Período que cubre', style: 'izquierda' },
                { text: strDatesIni[2] + '/' + strDatesIni[1] + '/' + strDatesIni[0] + " al " + strDatesFin[2] + '/' + strDatesFin[1] + '/' + strDatesFin[0], style: 'izquierda', bold: true }
              ],
              [
                { text: "h.", style: 'izquierda' },
                { text: strResolucion, style: 'izquierda' },
                { text: ttTarea.ttSeguimientoTarea.enmienda, style: 'izquierda', bold: true }
              ],
              [
                { text: "i.", style: 'izquierda' },
                { text: 'Titular (es) de la Licencia', style: 'izquierda' },
                { text: afavor, style: 'izquierda', bold: true }
              ]
            ];
          }
          var tbData = {
            style: 'estiloTablaEstrecha',
            table: {
              widths: [30, 170, 300],
              body: body
            },
            layout: 'noBorders'
          };
          content.push(tbData);
          content.push('\n');
          content.push(
            {
              text: "Traslado a usted el documento original de Garantía para su conocimiento y debido resguardo.",
              style: 'izquierda'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: "Sin otro particular.",
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n\n');
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          ); content.push(
            {
              text: "INAB " + data.tcSubregion.tcMunicipio.municipioDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionRegionalPoa: function (poa, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var textoFinca = "";
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i > 0) {
              textoFinca = textoFinca + ", " + finca.tcFinca.fincaDesc;
            } else {
              textoFinca = finca.tcFinca.fincaDesc;
            }
          }
          var afavor = "";
          for (i = 0; i < data.personas.length; i++) {
            if (i == 0) {
              afavor = data.personas[i].tcPersona.personaDesc;
            } else {
              afavor += + ", " + data.personas[i].tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            afavor = " " + data.ttTipoPropietarioGestion.razonSocial;
          } else {
            afavor = "l señor " + afavor;
          }
          var content = [
            {
              text: "NOTIFICACIÓN DE LA RESOLUCIÓN DEL POA A DIRECCION REGIONAL",
              style: 'centrar'
            }, '\n',
            {
              text: "Of. " + ttTarea.ttSeguimientoTarea.fundamento.oficior + "/INAB",
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: ttTarea.tcPersonaTraslado.personaDesc,
              style: 'izquierda'
            },
            {
              text: "Director Regional " + data.tcSubregion.tcRegion.regionDesc,
              style: 'izquierda'
            },
            {
              text: "INAB " + data.tcSubregion.tcRegion.tcMunicipio.municipioDesc,
              style: 'izquierda'
            },
            {
              text: "Presente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Estimado Director",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Atentamente me dirijo a usted para trasladarle adjunto copia de un Plan Operativo Anual de Manejo Forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Al respecto, le informo que la documentación que le envío consta de 01 Plan Operativo de Anual de Manejo Forestal a nombre de"
                + afavor + ", de la finca: " + textoFinca + ", con número de Licencia Forestal " + ttTarea.ttSeguimientoTarea.enmienda
                + ", la cual fue otorgada en el municipio de " + data.fincas[0].tcFinca.tcMunicipio.municipioDesc + ", del departamento de "
                + data.fincas[0].tcFinca.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda'
            }, '\n',
            {
              text: "Sin otro particular y poniéndome a sus ordenes para cualquier información que necesite, me suscribo de usted.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          ];
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "INAB " + data.tcSubregion.tcMunicipio.municipioDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionDipronaPoa: function (poa, usuarioDesc, dataEmpresa, sigla, esLicencia) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttTarea = null;
          if (esLicencia == null) {
            esLicencia = false;
          }
          var textoOficio = {};
          var textoDirijo = {};
          var textoConsta = '';
          var tipoNotificacionDesc = 'RESOLUCIÓN DEL PLAN OPERATIVO ANUAL (POA)';
          if (esLicencia) {
            ttTarea = poa;
            textoOficio = {
              text: "Of. " + ttTarea.ttSeguimientoTarea.numeroResolucion + "/INAB",
              style: 'derecha'
            };
            textoDirijo = {
              text: "Atentamente me dirijo a usted para trasladarle copia de la " + ttTarea.ttGestion.tcTipoGestion.tipoGestionDesc,
              style: 'izquierda'
            };
            textoConsta = 'la copia de la ' + ttTarea.ttGestion.tcTipoGestion.tipoGestionDesc + ' con número ' + ttTarea.ttPadre.ttSeguimientoTarea.numeroResolucion + ' correspondiente al turno número ' + ttTarea.ttPadre.ttSeguimientoTarea.area;
            tipoNotificacionDesc = ttTarea.ttGestion.tcTipoGestion.tipoGestionDesc.toUpperCase();
          } else {
            ttTarea = poa.ttTarea;
            textoOficio = {
              text: "Of. " + ttTarea.ttSeguimientoTarea.fundamento.oficiod + "/INAB",
              style: 'derecha'
            };
            textoDirijo = {
              text: "Atentamente me dirijo a usted para trasladarle copia del Plan Operativo Anual de Manejo Forestal.",
              style: 'izquierda'
            };
            textoConsta = 'la copia del Plan Operativo de Anual de Manejo Forestal con número ' + ttTarea.ttSeguimientoTarea.numeroResolucion;
          }
          var data = ttTarea.ttGestion;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var textoFinca = "";
          var tcFinca = data.fincas[0].tcFinca;
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i > 0) {
              textoFinca = textoFinca + ", " + finca.tcFinca.fincaDesc;
            } else {
              textoFinca = finca.tcFinca.fincaDesc;
            }
          }
          var afavor = "";
          for (i = 0; i < data.personas.length; i++) {
            if (i == 0) {
              afavor = data.personas[i].tcPersona.personaDesc;
            } else {
              afavor += + ", " + data.personas[i].tcPersona.personaDesc;
            }
          }
          var content = [
            {
              text: "NOTIFICACIÓN DE LA " + tipoNotificacionDesc + " A DIPRONA",
              style: 'centrar'
            }, '\n', textoOficio,
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: "Señor",
              style: 'izquierda'
            },
            {
              text: "Jefe DIPRONA PNC",
              style: 'izquierda'
            },
            {
              text: "Presente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Estimado Inspector",
              style: 'izquierda'
            }, '\n\n', textoDirijo, '\n',
            {
              text: "Al respecto, le informo que la documentación que le envío contiene " + textoConsta + ", la cual fue otorgada en la finca "
                + textoFinca + " ubicada en el municipio de " + tcFinca.tcMunicipio.municipioDesc
                + ", del departamento de " + tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + ". Propiedad de " + afavor,
              style: 'izquierda'
            }, '\n',
            {
              text: "Sin otro particular y poniéndome a sus ordenes para cualquier información que necesite, me suscribo de usted.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          ];
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          ); content.push(
            {
              text: "INAB " + data.tcSubregion.tcMunicipio.municipioDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacionPoa: function (poa, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var ttTarea = poa.ttTarea;
          var data = ttTarea.ttGestion;
          var strCorreo = data.tcElaborador.correo;
          for (i = 0; i < data.personas.length; i++) {
            item = data.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
            strCorreo += "; " + item.tcPersona.correo;
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = data.ttTipoPropietarioGestion.razonSocial;
          }
          for (i = 0; i < data.notificaciones.length; i++) {
            var notificacion = data.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              strCorreo += "; " + notificacion.notificacionGestionDesc;
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "CÉDULA DE NOTIFICACIÓN",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          item = {
            text: "En el municipio de " + data.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
              + data.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la resolución número " + ttTarea.ttSeguimientoTarea.numeroResolucion
              + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          if (poa.tcRegente != null) {
            content.push('\n\n\n\n');
            content.push(
              {
                text: "F. _________________________________",
                style: 'centrar'
              }
            );
            content.push(
              {
                text: poa.tcRegente.personaDesc,
                style: 'centrar'
              }
            );
            content.push(
              {
                text: 'No. Registro ' + poa.tcRegente.rf,
                style: 'centrar'
              }
            );
          }
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push('\n');
          if (ttTarea.ttSeguimientoTarea.fechaProvidencia == null) {
            ttTarea.ttSeguimientoTarea.fechaProvidencia = new Date();
          }
          fecha = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          mes = parseInt(fecha.getMonth()) + 1;
          content.push(
            {
              text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
              style: 'small', alignment: 'left'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudNotaEnvio: function (data, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var nombreSalida = 'solicitud.pdf';
          var fechaRegistro = new Date(data.fechaRegistro);
          var fecha = new Date();
          var mes = parseInt(fechaRegistro.getMonth()) + 1;
          var i, j;
          var texto = "";
          var tmpData = data.detalle;
          var length = tmpData.length;
          length = length - 1;
          for (i = 0; i < tmpData.length; i++) {
            if (i == 0) {
              texto = tmpData[i].cantidad + " unidades de notas de envío de " + tmpData[i].tcTipoNotaEnvio.tipoNotaEnvioDesc;
            } else {
              if (i == length) {
                texto += " y " + tmpData[i].cantidad + " unidades de notas de envío de " + tmpData[i].tcTipoNotaEnvio.tipoNotaEnvioDesc;
              } else {
                texto += ", " + tmpData[i].cantidad + " unidades de notas de envío de " + tmpData[i].tcTipoNotaEnvio.tipoNotaEnvioDesc;
              }
            }
          }
          var nombreCorto = data.tcPersonaAutoriza.personaDesc;
          var nombres = data.tcPersonaAutoriza.personaDesc.split(" ");
          switch (nombres.length) {
            case 1:
              nombreCorto = nombres[0];
              break;
            case 2:
              nombreCorto = nombres[0] + ' ' + nombres[1];
              break;
            case 3:
              nombreCorto = nombres[0] + ' ' + nombres[2];
              break;
            case 4:
              nombreCorto = nombres[0] + ' ' + nombres[2];
              break;
            case 5:
              nombreCorto = nombres[0] + ' ' + nombres[2];
              break;
            default:
              nombreCorto = nombres[0];
          }
          direccion = "";
          if (data.nivel == 1) {
            direccion = "Director Regional " + data.tcRegion.regionDesc;
          } else {
            direccion = "Director de Manejo y Restauración de Bosques";
          }
          var lugar;
          if (data.nivel == 1) {
            lugar = data.tcSubregion.tcMunicipio.municipioDesc;
          } else {
            lugar = data.tcRegion.tcMunicipio.municipioDesc
          }
          var content = [
            '\n\n\n\n',
            {
              text: lugar + ", " + fechaRegistro.getDate() + " de " + getMonth(mes) + " de " + fechaRegistro.getFullYear(),
              style: 'derecha'
            },
            {
              text: "Oficio No. " + data.referencia,
              style: 'derecha'
            }, '\n\n\n\n\n',
            {
              text: data.tcPersonaAutoriza.personaDesc,
              style: 'izquierda'
            },
            {
              text: direccion,
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Estimado " + (data.tcPersonaAutoriza.sigla == null ? '' : data.tcPersonaAutoriza.sigla) + " " + nombreCorto,
              style: 'izquierda'
            }, '\n',
            {
              text: "Reciba un cordial saludo, esperando que sus actividades se desarrollen satisfactoriamente.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Por medio de la presente me dirijo a usted con el propósito de solicitarle " + texto,
              style: 'izquierda'
            }, '\n',
            {
              text: "Sin otro particular y agradeciendo de antemano su atención a la presente, me suscribo de usted,",
              style: 'izquierda'
            }, '\n'
          ];
          content.push('\n\n\n\n\n\n\n');
          content.push(
            {
              text: sigla + " " + data.tcPersonaSolicita.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          if (data.nivel == 2) {
            content.push(
              {
                text: "Director regional " + data.tcRegion.regionDesc,
                style: 'centrar'
              }
            );
          } else {
            content.push(
              {
                text: "Director subregional " + data.tcSubregion.subregionDesc,
                style: 'centrar'
              }
            );
          }
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 11,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 11,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarConocimientoDespacho: function (data, usuarioDesc, dataEmpresa, sigla, esDelegado) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          if (esDelegado == undefined) {
            esDelegado = false;
          }
          var nombreSalida = 'conocimiento.pdf';
          if (data.fechaAutorizacion == null) {
            data.fechaAutorizacion = new Date();
          }
          var fechaAutorizacion = new Date(data.fechaAutorizacion);
          var fecha = new Date();
          var mes = parseInt(fechaAutorizacion.getMonth()) + 1;
          direccion = "";
          director = "";
          descripcion = "";
          if (data.nivel == 1) {
            if (esDelegado) {
              direccion = "Dirección Regional " + data.tcRegion.regionDesc
                + "\nCONOCIMIENTO DE DESPACHO DE Notas de Envío\nDE LA REGIÓN A LAS SUBREGIONES DE INAB";
              director = "Delegada(o) Administrativo " + data.tcRegion.regionDesc;
              descripcion = "Notas de Envío AUTORIZADAS A LA SUBREGIÓN " + data.tcSubregion.subregionDesc;
            } else {
              direccion = "Dirección Regional " + data.tcRegion.regionDesc
                + "\nCONOCIMIENTO DE AUTORIZACIÓN DE Notas de Envío\nDE LA REGIÓN A LAS SUBREGIONES DE INAB";
              director = "Director Regional " + data.tcRegion.regionDesc;
              descripcion = "Notas de Envío AUTORIZADAS A LA SUBREGIÓN " + data.tcSubregion.subregionDesc;
            }
          } else {
            direccion = "Dirección de Manejo y Restauración de Bosques\n"
              + "CONOCIMIENTO DE AUTORIZACIÓN DE Notas de Envío\nDE LA DMyRB AL ALMACEN CENTRAL DE INAB";
            director = "Director de Manejo y Restauración de Bosques";
            descripcion = "Notas de Envío AUTORIZADAS A LA REGIÓN " + data.tcRegion.regionDesc;
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "Instituto Nacional de Bosques INAB\n" + direccion,
                  style: 'izquierda',
                  margin: [20, 0, 10, 0]
                }
              ]
            },
            '\n\n',
            {
              text: 'No. ' + data.referencia,
              style: 'derecha'
            }, '\n',
            {
              text: "Fecha: " + fechaAutorizacion.getDate() + "/" + mes + "/" + fechaAutorizacion.getFullYear(),
              style: 'izquierda'
            },
            {
              text: "Referencia de la solicitud: " + data.referencia,
              style: 'izquierda'
            },
            {
              text: "Descripción: " + descripcion,
              style: 'izquierda'
            }, '\n'
          ];
          var detalle = data.detalle;
          var i, j;
          var body = [
            [
              { text: 'Tipo de Nota de Envío', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Cant. Solicitada', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Cant. Autorizada', fillColor: '#dddddd', style: 'tableHeaderSmall' }
            ]
          ];
          for (i = 0; i < detalle.length; i++) {
            body.push(
              [
                { text: detalle[i].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' },
                { text: detalle[i].cantidad, style: 'izquierda' },
                { text: detalle[i].cantidadAutorizada, style: 'izquierda' }
              ]
            );
          }
          var tbDetalle = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [290, 100, 100],
              body: body
            }
          }
          content.push(tbDetalle);
          content.push(
            {
              text: "Observaciones: " + (data.observaciones == null ? 'Ninguna' : data.observaciones),
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n\n');
          content.push(
            {
              text: sigla + " " + data.tcPersonaAutoriza.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: director,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "INAB " + (data.tcSubregion == null ? 'Guatemala' : data.tcRegion.tcMunicipio.municipioDesc),
              style: 'centrar'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "Nombre: ___________________________________________",
              style: 'derecha'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: "Firma: ____________________________________________",
              style: 'derecha'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarConocimientoNotaEnvio: function (data, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var nombreSalida = 'conocimiento.pdf';
          if (data.ttSolicitudNe.fechaAutorizacion == null) {
            data.ttSolicitudNe.fechaAutorizacion = new Date();
          }
          var fechaAutorizacion = new Date(data.ttSolicitudNe.fechaAutorizacion);
          var fecha = new Date();
          var mes = parseInt(fechaAutorizacion.getMonth()) + 1;
          direccion = "";
          director = "";
          descripcion = "";
          if (data.nivel == 1) {
            direccion = "Dirección Regional " + data.tcRegion.regionDesc
              + "\nCONOCIMIENTO DE DESPACHO DE Notas de Envío\nDE LA REGIÓN A LAS SUBREGIONES DE INAB";
            director = "Delegada(o) Administrativo Región " + data.tcRegion.regionDesc;
            descripcion = "Notas de Envío AUTORIZADAS A LA SUBREGIÓN " + data.tcSubregion.subregionDesc;
          } else {
            direccion = "Dirección de Manejo y Restauración de Bosques\n"
              + "CONOCIMIENTO DE DESPACHO DE Notas de Envío\nDEL ALMACEN CENTRAL A LAS REGIONES DE INAB";
            director = "Encargado del Almacen General";
            descripcion = "Notas de Envío AUTORIZADAS A LA REGIÓN " + data.tcRegion.regionDesc;
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "Instituto Nacional de Bosques INAB\n" + direccion,
                  style: 'izquierda',
                  margin: [20, 0, 10, 0]
                }
              ]
            },
            '\n\n',
            {
              text: data.referencia,
              style: 'derecha'
            }, '\n',
            {
              text: "Fecha: " + fechaAutorizacion.getDate() + "/" + mes + "/" + fechaAutorizacion.getFullYear(),
              style: 'izquierda'
            },
            {
              text: "Referencia de la solicitud: " + data.ttSolicitudNe.referencia,
              style: 'izquierda'
            },
            {
              text: "Descripción: " + descripcion,
              style: 'izquierda'
            }
          ];
          if (data.ttSolicitudNe.autorizadoDespacho == 1) {
            content.push(
              {
                text: 'Autorizado por: ' + data.ttSolicitudNe.tcPersonaAutoriza.personaDesc,
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          var detalle = data.detalle;
          var i, j;
          var body = [
            [
              { text: 'Tipo de Nota de Envío', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
              { text: 'Cantidad', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 3 }, {}, {},
              { text: 'Correlativos', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 2 }, {}
            ],
            [
              { text: '' },
              { text: 'Cajas', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Unidades por caja', fillColor: '#dddddd', style: 'small' },
              { text: 'Total', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Del', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Al', fillColor: '#dddddd', style: 'tableHeaderSmall' }
            ]
          ];
          for (i = 0; i < detalle.length; i++) {
            body.push(
              [
                { text: detalle[i].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' },
                { text: detalle[i].cajas, style: 'izquierda' },
                { text: detalle[i].unidades, style: 'izquierda' },
                { text: parseInt(detalle[i].cajas) * parseInt(detalle[i].unidades), style: 'izquierda' },
                { text: detalle[i].primero, style: 'izquierda' },
                { text: detalle[i].ultimo, style: 'izquierda' }
              ]
            );
          }
          var tbDetalle = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [160, 60, 60, 60, 60, 60],
              body: body
            }
          }
          content.push(tbDetalle);
          content.push(
            {
              text: "Observaciones: " + (data.ttSolicitudNe.observaciones == null ? 'Ninguna' : data.ttSolicitudNe.observaciones),
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n\n');
          var personaDesc = data.ttSolicitudNe.tcPersonaAutoriza.personaDesc;
          if (data.ttSolicitudNe.autorizadoDespacho == 1) {
            personaDesc = data.ttSolicitudNe.tcPersonaDespacha.personaDesc;
          }
          content.push(
            {
              text: sigla + " " + personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: director,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "INAB " + (data.tcSubregion == null ? 'Guatemala' : data.tcRegion.tcMunicipio.municipioDesc),
              style: 'centrar'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "Nombre: ___________________________________________",
              style: 'derecha'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: "Firma: ____________________________________________",
              style: 'derecha'
            }
          );
          content.push('\n\n');
          if (data.ttSolicitudNe.autorizadoDespacho == 1) {
            content.push(
              {
                text: 'Fecha de despacho: ' + $filter('date')(new Date(data.ttSolicitudNe.fechaDespacho), 'dd/MM/yyyy HH:mm'),
                style: 'small', alignment: 'right'
              }
            );
          }
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaTrasladoEnmienda: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (data.personas.length > 1) {
            textoInicio = "presentan";
          }
          var solicitanteNombre = "";
          for (var i = 0; i < data.personas.length; i++) {
            solicitante = data.personas[i];
            if (i == 0) {
              solicitanteNombre = solicitante.tcPersona.personaDesc;
            } else {
              solicitanteNombre = solicitanteNombre + ", " + solicitante.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          }
          var paso = null;
          var firmaJuridico = {};
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null && tarea.tcPersonaJuridico != null) {
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            firmaJuridico = {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            paso = { text: "Pase en forma simultánea el expediente original al Delegado Jurídico Regional, Licenciado (a) " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica y copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifiquen el cumplimiento de las enmiendas solicitadas y emita dictamen a la mayor brevedad.", style: 'izquierda' };
          } else {
            if (tarea.tcPersonaJuridico != null) {
              paso = { text: "Pase en el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que verifique el cumplimiento de las enmiendas solicitadas y emita dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaJuridico = {
                text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
            if (tarea.tcPersonaTraslado != null) {
              paso = { text: "Pase copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el cumplimiento de las enmiendas solicitadas y emita dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaTecnico = {
                text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitanteNombre + ", " + textoInicio + " enmiendas solicitadas en oficio " + tarea.ttSeguimientoTarea.antecedente + " según solicitud de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    paso,
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaJuridico,
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaTrasladoCancelarEnmienda: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (data.personas.length > 1) {
            textoInicio = "presentan";
          }
          var solicitanteNombre = "";
          for (var i = 0; i < data.personas.length; i++) {
            solicitante = data.personas[i];
            if (i == 0) {
              solicitanteNombre = solicitante.tcPersona.personaDesc;
            } else {
              solicitanteNombre = solicitanteNombre + ", " + solicitante.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          }
          var paso = null;
          var firmaJuridico = {};
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null && tarea.tcPersonaJuridico != null) {
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            firmaJuridico = {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            paso = { text: "Pase en forma simultánea el expediente original al Delegado Jurídico Regional, Licenciado (a) " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica y copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifiquen el cumplimiento de las enmiendas solicitadas y emita dictamen a la mayor brevedad.", style: 'izquierda' };
          } else {
            if (tarea.tcPersonaJuridico != null) {
              paso = { text: "Pase en el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que verifique el cumplimiento de la solicitud y emita dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaJuridico = {
                text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
            if (tarea.tcPersonaTraslado != null) {
              paso = { text: "Pase copia completa del expediente a: " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el cumplimiento de la solicitud y emita dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaTecnico = {
                text: '\n\nFecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitanteNombre + ", " + " presenta solicitud de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    paso,
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaJuridico,
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaRatificacion: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (data.personas.length > 1) {
            textoInicio = "presentan";
          }
          var solicitanteNombre = "";
          for (var i = 0; i < data.personas.length; i++) {
            solicitante = data.personas[i];
            if (i == 0) {
              solicitanteNombre = solicitante.tcPersona.personaDesc;
            } else {
              solicitanteNombre = solicitanteNombre + ", " + solicitante.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          }
          var paso = null;
          var firmaJuridico = {};
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null && tarea.tcPersonaJuridico != null) {
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            firmaJuridico = {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            paso = { text: "Pase en forma simultánea el expediente original al Delegado Jurídico Regional, Licenciado (a) " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica y copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que corroboren la información relacionada al expediente y emitan nuevo dictamen a la mayor brevedad.", style: 'izquierda' };
          } else {
            if (tarea.tcPersonaJuridico != null) {
              paso = { text: "Pase en el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que verifique, corrobore la información del expediente y emita dictamen nuevo a la mayor brevedad.", style: 'izquierda' };
              firmaJuridico = {
                text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
            if (tarea.tcPersonaTraslado != null) {
              paso = { text: "Pase copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique, corrobore la información del expediente y emita nuevo dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaTecnico = {
                text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
          }
          var observaciones = [];
          if (tarea.ttSeguimientoTarea.observaciones != null) {
            var dataObservaciones = tarea.ttSeguimientoTarea.observaciones;
            for (var i = 0; i < dataObservaciones.length; i++) {
              observaciones.push({ text: dataObservaciones[i].descripcion, style: 'izquierda' });
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 100,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 400,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitanteNombre + ", " + textoInicio + " solicitud de Licencia de aprovechamiento Forestal para la ejecución del  Plan de Manejo Forestal de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    paso,
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n'
          ];
          if (observaciones.length > 0) {
            content.push(
              {
                text: "Observaciones",
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                stack: [
                  {
                    ol: observaciones
                  }
                ]
              }
            );
            content.push('\n');
          }
          content.push(
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            }
          );
          content.push(firmaJuridico);
          content.push(firmaTecnico);
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaTrasladoSuspension: function (dataSuspension, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var tarea = dataSuspension.ttTarea;
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (dataSuspension.opositores.length > 1) {
            textoInicio = "presentan";
          }
          var opositorNombre = "";
          var conteo = dataSuspension.opositores.length;
          for (var i = 0; i < conteo; i++) {
            opositor = dataSuspension.opositores[i];
            if (i == 0) {
              opositorNombre = opositor.tcOpositor.opositorDesc;
            } else {
              if (conteo == (i - 1)) {
                opositorNombre = opositorNombre + " y " + opositor.tcOpositor.opositorDesc;
              } else {
                opositorNombre = opositorNombre + ", " + opositor.tcOpositor.opositorDesc;
              }
            }
          }
          var propietarioNombre = "";
          conteo = data.personas.length;
          for (var i = 0; i < conteo; i++) {
            persona = data.personas[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var firmaJuridico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Pase atentamente al licenciado " + tarea.tcPersonaTraslado.personaDesc + ", solicitándole revisión del documento referido; así mismo indicar el procedimiento a seguir", style: 'izquierda' };
            firmaJuridico = {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var fechaOposicion = new Date(dataSuspension.fechaIngreso);
          var mesOposicion = parseInt(fechaOposicion.getMonth()) + 1;
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: ' con fecha ' + fechaOposicion.getDate() + ' de ' + getMonth(mesOposicion) + ' del presente año, '
                        + opositorNombre + ", " + textoInicio + " oposición donde manifiestan que el inmueble: Finca " + textoDireccion
                        + ' no le pertenece a ' + propietarioNombre, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n', paso,
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaJuridico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarReporteExistencia: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'reporte.pdf';
          var fecha = new Date();
          direccion = "";
          switch (data[0].nivel) {
            case 1:
              direccion = "Dirección subregional " + data[0].tcSubregion.subregionDesc;
              break;
            case 2:
              direccion = "Dirección Regional " + data[0].tcRegion.regionDesc;
              break;
            case 3:
              direccion = "Dirección de Manejo y Restauración de Bosques";
              break;
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "Instituto Nacional de Bosques INAB\n" + direccion + "\nDetalle de existencias de notas de envío",
                  style: 'izquierda',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n\n'
          ];
          var i, j;
          var body = [
            [
              { text: 'Tipo de Nota de Envío', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
              { text: 'Cantidad ingresada', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 3 }, {}, {},
              { text: 'Correlativos', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 2 }, {},
              { text: 'Saldo', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
              { text: 'Cajas', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
              { text: 'Ultimo entregado', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 }
            ],
            [
              { text: '' },
              { text: 'Cajas', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Unidades por caja', fillColor: '#dddddd', style: 'small' },
              { text: 'Total', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Del', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Al', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: '' },
              { text: '' },
              { text: '' }
            ]
          ];
          for (var h = 0; h < data.length; h++) {
            var detalle = data[h].detalle;
            for (i = 0; i < detalle.length; i++) {
              var cajas = 1;
              if (parseInt(detalle[i].saldo) > parseInt(detalle[i].unidades)) {
                cajas = parseInt(detalle[i].saldo) / parseInt(detalle[i].unidades);
                cajas = Math.ceil(cajas);
              }
              body.push(
                [
                  { text: detalle[i].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' },
                  { text: detalle[i].cajas, style: 'izquierda' },
                  { text: detalle[i].unidades, style: 'izquierda' },
                  { text: parseInt(detalle[i].cajas) * parseInt(detalle[i].unidades), style: 'izquierda' },
                  { text: detalle[i].primero, style: 'izquierda' },
                  { text: detalle[i].ultimo, style: 'izquierda' },
                  { text: detalle[i].saldo, style: 'izquierda' },
                  { text: cajas, style: 'izquierda' },
                  { text: detalle[i].ultimoEntregado, style: 'izquierda' }
                ]
              );
            }
          }
          var tbDetalle = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [160, 40, 55, 55, 60, 60, 55, 50, 60],
              body: body
            }
          }
          content.push(tbDetalle);
          content.push('\n\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 10,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarReporteNotasCompradas: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'reporte.pdf';
          var fecha = new Date();
          direccion = "";
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "Instituto Nacional de Bosques INAB\nDetalle de existencias de notas de envío:\n" + data[0].detalle[0].tcTipoNotaEnvio.tipoNotaEnvioDesc,
                  style: 'izquierda',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n\n'
          ];
          var i, j;
          var body = [
            [
              { text: 'Fecha compra', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
              { text: 'Cantidad', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 3 }, {}, {},
              { text: 'Correlativos', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 2 }, {},
              { text: 'Saldo', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 }
            ],
            [
              { text: '' },
              { text: 'Cajas', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Unidades por caja', fillColor: '#dddddd', style: 'small' },
              { text: 'Total', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Del', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: 'Al', fillColor: '#dddddd', style: 'tableHeaderSmall' },
              { text: '' }
            ]
          ];
          var gblTotal = 0, gblSaldo = 0;
          for (var h = 0; h < data.length; h++) {
            var detalle = data[h].detalle;
            for (i = 0; i < detalle.length; i++) {
              var total = parseInt(detalle[i].cajas) * parseInt(detalle[i].unidades);
              body.push(
                [
                  { text: $filter('date')(new Date(data[h].fechaRegistro), 'dd/MM/yyyy'), style: 'izquierda' },
                  { text: detalle[i].cajas, style: 'izquierda' },
                  { text: detalle[i].unidades, style: 'izquierda' },
                  { text: total, style: 'izquierda' },
                  { text: detalle[i].primero, style: 'izquierda' },
                  { text: detalle[i].ultimo, style: 'izquierda' },
                  { text: detalle[i].saldo, style: 'izquierda' }
                ]
              );
              gblTotal += total;
              gblSaldo += parseInt(detalle[i].saldo);
            }
          }

          body.push(
            [
              { text: 'TOTAL', style: 'centrar', colSpan: 3 }, {}, {},
              { text: gblTotal, style: 'izquierda' },
              { text: 'TOTAL', style: 'centrar', colSpan: 2 }, {},
              { text: gblSaldo, style: 'izquierda' }
            ]
          );

          var tbDetalle = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [70, 50, 65, 65, 65, 65, 65],
              body: body
            }
          }
          content.push(tbDetalle);
          content.push('\n\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarLibroNotaEnvio: function (data, usuarioDesc, dataEmpresa, conLogo) {
          var nombreSalida = 'reporte.pdf';
          var fecha = new Date();
          direccion = "";
          if (conLogo == null) {
            conLogo = true;
          }
          var content = null;
          if (conLogo) {
            content = [
              {
                columns: [
                  {
                    width: 80,
                    image: 'data:image/png;base64,' + dataEmpresa.logo,
                    style: 'izquierda'
                  },
                  {
                    width: 350,
                    text: "\nInstituto Nacional de Bosques INAB"
                      + "\nControl de notas de envío",
                    style: 'izquierda',
                    margin: [20, 0, 10, 0]
                  }
                ]
              }
            ];
          } else {
            content = [
              {
                columns: [
                  {
                    width: 80,
                    text: '',
                    style: 'izquierda'
                  },
                  {
                    width: 350,
                    text: '\n\n\n\n\n',
                    style: 'izquierda',
                    margin: [20, 0, 10, 0]
                  }
                ]
              }
            ];
          }

          var h, i, j, k;
          content.push('\n');
          if (conLogo) {
            content.push(
              {
                text: 'Nota de envío de ' + data.tcTipoNotaEnvio.tipoNotaEnvioDesc,
                style: 'izquierda', bold: true
              }
            );
          }
          content.push(
            {
              text: 'Mes: ' + getMonth(data.mes).toUpperCase() + ' ' + data.anio,
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              columns: [
                {
                  width: 250,
                  text: 'Saldo del mes anterior',
                  style: 'izquierda'
                },
                {
                  width: 250,
                  text: 'Correlativos',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  width: 250,
                  text: data.saldoAnterior,
                  style: 'izquierda',
                  bold: true
                },
                {
                  width: 250,
                  text: data.correlativoAnterior,
                  style: 'izquierda',
                  bold: true
                }
              ]
            }
          );
          content.push('\n');
          var body = [
            [
              { text: 'Fecha', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Sede', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Correlativo', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Ingreso', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Egreso', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Saldo', fillColor: '#CCCCCC', style: 'tableHeader' }
            ]
          ];
          var detalle = data.detalle;
          var saldoTotal = data.saldoAnterior;
          for (i = 0; i < detalle.length; i++) {
            var saldo = parseInt(detalle[i].ttDetalleCuentaNe.cajas) * parseInt(detalle[i].ttDetalleCuentaNe.unidades);
            if (detalle[i].esIngreso == 1) {
              saldoTotal += saldo;
              body.push(
                [
                  { text: $filter('date')(new Date(detalle[i].fechaRegistro), 'dd/MM/yyyy'), style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: detalle[i].ttDetalleCuentaNe.primero + ' - ' + detalle[i].ttDetalleCuentaNe.ultimo, style: 'izquierda' },
                  { text: saldo, style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: saldoTotal, style: 'izquierda' }
                ]
              );
            } else {
              saldoTotal -= saldo;
              body.push(
                [
                  { text: $filter('date')(new Date(detalle[i].fechaRegistro), 'dd/MM/yyyy'), style: 'izquierda' },
                  { text: detalle[i].ttCuentaNe.tcSubregion.subregionDesc, style: 'izquierda' },
                  { text: detalle[i].ttDetalleCuentaNe.primero + ' - ' + detalle[i].ttDetalleCuentaNe.ultimo, style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: saldo, style: 'izquierda' },
                  { text: saldoTotal, style: 'izquierda' }
                ]
              );
            }
          }
          strPartes = data.fechaCorte.toString().split('-');
          body.push(
            [
              { text: 'Saldo al ' + strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0], style: 'derecha', colSpan: 5 }, {}, {}, {}, {},
              { text: saldoTotal, style: 'izquierda' }
            ]
          );
          var tbRegion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 110, 160, 90, 90, 90],
              body: body
            }
          }
          content.push(tbRegion);
          content.push('\n\n\n');
          content.push(
            {
              columns: [
                {
                  width: 330,
                  text: 'F _________________________________\n' + data.tcAdministrativo.personaDesc + '\n' 
                  + data.tcPuestoRealiza.puestoDesc + ' Región ' + data.tcRegion.regionDesc,
                  style: 'centrar'
                },
                {
                  width: 330,
                  text: 'Vo.Bo. _________________________________\n' + data.tcRegional.personaDesc + '\n' 
                  + data.tcPuestoVobo.puestoDesc + ' ' + data.tcRegion.regionDesc,
                  style: 'centrar'
                }
              ]
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          if (conLogo) {
            content.push(
              {
                text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
                style: 'small', alignment: 'right'
              }
            );
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarReporteExistenciaSubregion: function (data, usuarioDesc, dataEmpresa, perfilId) {
          var nombreSalida = 'reporte.pdf';
          var fecha = new Date();
          direccion = "";
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB"
                    + "\nDetalle de existencias de notas de envío",
                  style: 'izquierda',
                  margin: [20, 0, 10, 0]
                }
              ]
            }
          ];
          var h, i, j, k;
          for (h = 0; h < data.length; h++) {
            content.push('\n');
            content.push(
              {
                text: 'Existencia de la región: ' + data[h].tcRegion.regionDesc,
                style: 'izquierda', bold: true
              }
            );
            var body = [
              [
                { text: 'Tipo de Nota de Envío', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
                { text: 'Cantidad', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 3 }, {}, {},
                { text: 'Correlativos', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 2 }, {},
                { text: 'Saldo', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
                { text: 'Ultimo entregado', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 }
              ],
              [
                { text: '' },
                { text: 'Cajas', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                { text: 'Unidades por caja', fillColor: '#dddddd', style: 'small' },
                { text: 'Total', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                { text: 'Del', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                { text: 'Al', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                { text: '' },
                { text: '' }
              ]
            ];
            var detalle = data[h].detalle;
            if (detalle != null) {
              for (i = 0; i < detalle.length; i++) {
                body.push(
                  [
                    { text: detalle[i].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' },
                    { text: detalle[i].cajas, style: 'izquierda' },
                    { text: detalle[i].unidades, style: 'izquierda' },
                    { text: parseInt(detalle[i].cajas) * parseInt(detalle[i].unidades), style: 'izquierda' },
                    { text: detalle[i].primero, style: 'izquierda' },
                    { text: detalle[i].ultimo, style: 'izquierda' },
                    { text: detalle[i].saldo, style: 'izquierda' },
                    { text: detalle[i].ultimoEntregado, style: 'izquierda' }
                  ]
                );
              }
            }
            var tbRegion = {
              style: 'estiloTabla',
              table: {
                headerRows: 2,
                widths: [180, 50, 65, 65, 65, 65, 65, 65],
                body: body
              }
            }
            content.push(tbRegion);
            content.push('\n');
            var tcSubregion = {
              subregionId: 0
            };
            var bndAgregar = false;
            for (j = 0; j < data[h].hijos.length; j++) {
              var hijo = data[h].hijos[j];
              bndAgregar = false;
              var body = [];
              if (hijo.tcSubregion.subregionId != tcSubregion.subregionId) {
                content.push('\n');
                content.push(
                  {
                    text: 'Existencia de la subregión: ' + hijo.tcSubregion.subregionDesc,
                    style: 'izquierda', bold: true
                  }
                );
                body = [
                  [
                    { text: 'Tipo de Nota de Envío', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
                    { text: 'Cantidad', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 3 }, {}, {},
                    { text: 'Correlativos', fillColor: '#CCCCCC', style: 'tableHeader', colSpan: 2 }, {},
                    { text: 'Saldo', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 },
                    { text: 'Ultimo entregado', fillColor: '#CCCCCC', style: 'tableHeader', rowSpan: 2 }
                  ],
                  [
                    { text: '' },
                    { text: 'Cajas', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                    { text: 'Unidades por caja', fillColor: '#dddddd', style: 'small' },
                    { text: 'Total', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                    { text: 'Del', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                    { text: 'Al', fillColor: '#dddddd', style: 'tableHeaderSmall' },
                    { text: '' },
                    { text: '' }
                  ]
                ];
                bndAgregar = true;
                tcSubregion = hijo.tcSubregion;
              }
              var detalle = hijo.detalle;
              if (detalle != null) {
                for (k = 0; k < detalle.length; k++) {
                  body.push(
                    [
                      { text: detalle[k].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' },
                      { text: detalle[k].cajas, style: 'izquierda' },
                      { text: detalle[k].unidades, style: 'izquierda' },
                      { text: parseInt(detalle[k].cajas) * parseInt(detalle[k].unidades), style: 'izquierda' },
                      { text: detalle[k].primero, style: 'izquierda' },
                      { text: detalle[k].ultimo, style: 'izquierda' },
                      { text: detalle[k].saldo, style: 'izquierda' },
                      { text: detalle[k].ultimoEntregado, style: 'izquierda' }
                    ]
                  );
                }
                if (bndAgregar) {
                  var tbDetalle = {
                    style: 'estiloTabla',
                    table: {
                      headerRows: 2,
                      widths: [180, 50, 65, 65, 65, 65, 65, 65],
                      body: body
                    }
                  }
                  content.push(tbDetalle);
                  content.push('\n');
                }
              }
            }
          }
          content.push('\n\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 12,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 12,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 9,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarReporteMovimientoSubregion: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'reporte.pdf';
          var fecha = new Date();
          direccion = "";
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB"
                    + "\nDetalle de egresos de notas de envío"
                    + "\nSubregión: " + data[0].tcReferenciaNota.tcSubregion.subregionDesc,
                  style: 'izquierda', fontSize: 12,
                  margin: [20, 0, 10, 0]
                }
              ]
            }
          ];
          content.push('\n\n');
          var h, i, j, k;
          var body = [
            [
              { text: 'Fecha de entrega', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Factura', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Cantidad', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Rango inicial', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Rango final', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Titular / Representante', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Resolución', fillColor: '#dddddd', style: 'tableHeader' },
              { text: 'Tipo de nota', fillColor: '#dddddd', style: 'tableHeader' }
            ]
          ];
          for (i = 0; i < data.length; i++) {
            if (data[i].estadoId == 2) {
              var strPartes = data[i].fechaEntrega.toString().split('-');
              body.push(
                [
                  { text: strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0], style: 'izquierda' },
                  { text: 'Serie: ' + data[i].serieFactura + ' No.: ' + data[i].noFactura, style: 'izquierda' },
                  { text: data[i].cantidad, style: 'izquierda' },
                  { text: data[i].inicio, style: 'izquierda' },
                  { text: data[i].fin, style: 'izquierda' },
                  { text: data[i].tcReferenciaNota.referenciaNombre, style: 'izquierda' },
                  { text: data[i].tcReferenciaNota.referenciaNotaDesc, style: 'izquierda' },
                  { text: data[i].tcTipoNotaEnvio.tipoNotaEnvioDesc, style: 'izquierda' }
                ]
              );
            }
          }
          var tbRegion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [55, 60, 40, 50, 50, 160, 150, 80],
              body: body
            }
          }
          content.push(tbRegion);
          content.push('\n\n\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [30, 50, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 9,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarReporteLibroSubregion: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'libro.pdf';
          var fecha = new Date();
          direccion = "";
          var content = [];
          content.push('\n');
          content.push(
            {
              text: 'MES: ' + getMonth(data.mes).toUpperCase() + ' ' + data.anio,
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          var saldo = 0;
          var detalle = data.detalle;
          var correlativos = '-----';
          if (data.saldoAnterior > 0) {
            saldo = data.saldoAnterior;
            correlativos = data.correlativoAnterior;
          } else {
            for (var i = 0; i < detalle.length; i++) {
              if (detalle[i].esIngreso == 0) {
                saldo = detalle[i].thMovimientoNota.saldoAntes;
                break;
              }
            }
          }
          content.push(
            {
              columns: [
                {
                  width: 250,
                  text: 'Saldo del mes anterior',
                  style: 'izquierda'
                },
                {
                  width: 250,
                  text: 'Correlativos',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  width: 250,
                  text: saldo,
                  style: 'izquierda',
                  bold: true
                },
                {
                  width: 250,
                  text: correlativos,
                  style: 'izquierda',
                  bold: true
                }
              ]
            }
          );
          content.push('\n');
          var h, i, j, k;
          var body = [
            [
              { text: 'Fecha de entrega', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Factura', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Rango inicial', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Rango final', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Titular / Representante', fillColor: '#CCCCCC', style: 'tableHeader' },
              { text: 'Resolución', fillColor: '#dddddd', style: 'tableHeader' },
              { text: 'Ingreso', fillColor: '#dddddd', style: 'tableHeader' },
              { text: 'Egreso', fillColor: '#dddddd', style: 'tableHeader' },
              { text: 'Saldo', fillColor: '#dddddd', style: 'tableHeader' }
            ]
          ];
          var saldoTotal = saldo;
          var strPartes = null;
          for (i = 0; i < detalle.length; i++) {
            if (detalle[i].esIngreso == 1) {
              saldo = parseInt(detalle[i].ttDetalleCuentaNe.cajas) * parseInt(detalle[i].ttDetalleCuentaNe.unidades);
              saldoTotal += saldo;
              body.push(
                [
                  { text: $filter('date')(new Date(detalle[i].ttCuentaNe.fechaRegistro), 'dd/MM/yyyy'), style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: detalle[i].ttDetalleCuentaNe.primero, style: 'izquierda' },
                  { text: detalle[i].ttDetalleCuentaNe.ultimo, style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: parseInt(detalle[i].ttDetalleCuentaNe.cajas) * parseInt(detalle[i].ttDetalleCuentaNe.unidades), style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: saldoTotal, style: 'izquierda' }
                ]
              );
            } else {
              saldoTotal -= parseInt(detalle[i].thMovimientoNota.cantidad);
              strPartes = detalle[i].thMovimientoNota.fechaEntrega.toString().split('-');
              body.push(
                [
                  { text: strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0], style: 'izquierda' },
                  { text: 'Serie: ' + detalle[i].thMovimientoNota.serieFactura + ' No.: ' + detalle[i].thMovimientoNota.noFactura, style: 'izquierda' },
                  { text: detalle[i].thMovimientoNota.inicio, style: 'izquierda' },
                  { text: detalle[i].thMovimientoNota.fin, style: 'izquierda' },
                  { text: detalle[i].tcReferenciaNota.referenciaNombre, style: 'izquierda' },
                  { text: detalle[i].tcReferenciaNota.referenciaNotaDesc, style: 'izquierda' },
                  { text: '', style: 'izquierda' },
                  { text: detalle[i].thMovimientoNota.cantidad, style: 'izquierda' },
                  { text: saldoTotal, style: 'izquierda' }
                ]
              );
            }
          }
          strPartes = data.fechaCorte.toString().split('-');
          body.push(
            [
              { text: 'Saldo al ' + strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0], style: 'derecha', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {},
              { text: saldoTotal, style: 'izquierda' }
            ]
          );
          var tbRegion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [55, 60, 40, 40, 116, 110, 80, 50, 50],
              body: body
            }
          }
          content.push(tbRegion);
          content.push('\n\n\n');
          content.push(
            {
              columns: [
                {
                  width: 330,
                  text: 'F _________________________________\n' + data.tcSecretaria.personaDesc + '\n' 
                  + data.tcPuestoRealiza.puestoDesc + ' ' + data.tcSubregion.subregionDesc,
                  style: 'centrar'
                },
                {
                  width: 330,
                  text: 'Vo.Bo. _________________________________\n' + data.tcSubregional.personaDesc + '\n' 
                  + data.tcPuestoVobo.puestoDesc + ' ' + data.tcSubregion.subregionDesc,
                  style: 'centrar'
                }
              ]
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 100, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 9,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 12,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotaControlSemilla: function (data, strCantidad, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'nota.pdf';
          var fecha = new Date();
          direccion = "";
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: '',
                  style: 'izquierda'
                }
              ]
            }
          ];
          content.push('\n\n');

          var h, i, j, k;
          var bgeneral = [
            [
              { text: 'Información General (uso exclusivo del DCF y SF)\n', style: 'tableHeader', colSpan: 2 }, {}
            ],
            [
              { text: 'Categoría de la semilla: Autorizada', style: 'izquierda' },
              { text: 'Categoría de Fuente semillera: FS', style: 'izquierda' }
            ],
            [
              { text: 'Nombre común: ' + data.tcLoteSemilla.tcEspecie.especieDesc, style: 'izquierda' },
              { text: 'Nombre científico: ' + data.tcLoteSemilla.tcEspecie.nombreCientifico, style: 'izquierda' },
            ],
            [
              { text: 'Procedencia del lote:', style: 'izquierda' }, {}
            ],
            [
              { text: 'Fecha de emisión del certificado de Calidad:', style: 'izquierda' },
              { text: 'Análisis de Laboratorio No.', style: 'izquierda' },
            ],
            [
              { text: 'Número de semillas/Kg:', style: 'izquierda' },
              { text: 'Porcentaje de Germinación', style: 'izquierda' },
            ],
            [
              { text: 'Semilla viable/Kg:', style: 'izquierda' },
              { text: '', style: 'izquierda' },
            ]
          ];
          var tbGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 0,
              widths: [250, 250],
              body: bgeneral
            },
            layout: 'noBorders'
          }
          var body = [
            [
              tbGenerales
            ]
          ];
          var tabla = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [500],
              body: body
            }
          }
          content.push(tabla);
          var bcomerciante = [
            [
              { text: 'Nombre del Comerciante de Semillas\n', style: 'tableHeader', colSpan: 2 }, {}
            ],
            [
              { text: data.tcLoteSemilla.tcComercializador.comercializadorDesc, style: 'tableHeader', colSpan: 2 }, {}
            ],
            [
              { text: 'Codigo de la fuente: ' + data.tcLoteSemilla.tcFuenteSemillera.fuenteSemilleraDesc, style: 'izquierda' },
              { text: 'Correlativo de la Nota: ' + data.codigoCompleto, style: 'izquierda' },
            ]
          ];
          var tbComerciante = {
            style: 'estiloTabla',
            table: {
              headerRows: 0,
              widths: [250, 250],
              body: bcomerciante
            },
            layout: 'noBorders'
          }
          body = [
            [
              tbComerciante
            ]
          ];
          tabla = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [500],
              body: body
            }
          }
          content.push(tabla);
          var bventa = [
            [
              { text: 'Información de la Venta y del Comprador de plantas\n', style: 'tableHeader', colSpan: 2 }, {}
            ],
            [
              { text: 'Fecha: ' + $filter('date')(new Date(data.fechaEmision), 'dd/MM/yyyy'), style: 'izquierda' },
              { text: 'Venta en kilogramos: ' + data.cantidad, style: 'izquierda' },
            ],
            [
              { text: 'En letras: ' + strCantidad, style: 'izquierda', colSpan: 2 }, {}
            ],
            [
              { text: 'Comprador de plantas: ' + data.tcComprador.comercializadorDesc, style: 'izquierda', colSpan: 2 }, {}
            ],
            [
              { text: 'Correo electrónico: ' + data.tcComprador.correo, style: 'izquierda' },
              { text: 'Número de Teléfono: ' + data.tcComprador.telefono, style: 'izquierda' },
            ],
            [
              { text: 'Nombre del vivero:\n', style: 'izquierda', colSpan: 2 }, {}
            ],
            [
              { text: 'Dirección del vivero (especificando finca, aldea, caserío, municipio y departamento):\n', style: 'izquierda', colSpan: 2 }, {}
            ],
            [
              { text: 'Código expediente PROBOSQUE:', style: 'izquierda', colSpan: 2 }, {}
            ],
            [
              { text: 'Firma y número de DPI:\n\n', style: 'izquierda', colSpan: 2 }, {}
            ]
          ];
          var tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 0,
              widths: [250, 250],
              body: bventa
            },
            layout: 'noBorders'
          }
          body = [
            [
              tbVenta
            ]
          ];
          tabla = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [500],
              body: body
            }
          }
          content.push(tabla);
          content.push(
            {
              text: 'Nota: La liquidación y manejo de esta nota se hará automáticamente cada mes a través “formato para informe final de control de comercialización de Semillas forestales digital” al Departamento de Certificación de Fuentes Semilleras y Semillas Forestales mensualmente a partir del día en que fue autorizado el lote de semillas correspondiente a esta nota',
              fontSize: 8
            }
          );
          content.push('\n');
          content.push(
            { qr: 'http://manejoforestal.inab.gob.gt', alignment: 'right', fit: '80' }
          );
          content.push('\n\n\n');
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9
              },
              izquierdaNegrilla: {
                fontSize: 9,
                bold: true
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 9,
                alignment: 'center'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOficioTrasladoEnmienda: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var data = ttTarea.ttGestion;
          var nombreSalida = 'oficio.pdf';
          var fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var lista = [];
          var enmienda = ttTarea.ttSeguimientoTarea.enmienda;
          for (var i = 0; i < enmienda.length; i++) {
            lista.push({ text: enmienda[i].descripcion, style: 'izquierda' });
          }

          var content = [
            {
              text: 'ATENCIÓN A REQUERIMIENTOS DEL DIRECTOR REGIONAL',
              style: 'centrar'
            }, '\n',
            {
              text: "Of. " + ttTarea.ttSeguimientoTarea.providencia,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: ttTarea.tcPersonaTraslado.personaDesc,
              style: 'izquierda'
            },
            {
              text: "Director Regional " + data.tcSubregion.tcRegion.regionDesc,
              style: 'izquierda'
            },
            {
              text: "INAB " + data.tcSubregion.tcRegion.tcMunicipio.municipioDesc,
              style: 'izquierda'
            },
            {
              text: "Presente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: "Por este medio estoy dando respuesta a lo solicitado según oficio número "
                + ttTarea.ttSeguimientoTarea.fundamento + " trasladando la documentación generada al respecto, para continuar con la aprobación de la licencia forestal cuya solicitud se consigna en el expediente administrativo No " + data.expediente,
              style: 'izquierda'
            }, '\n',
            {
              text: "A continuación se detallan los aspectos atendidos por las partes involucradas",
              style: 'izquierda'
            }, '\n',
            {
              ol: [
                {
                  ol: lista
                }
              ]
            }, '\n',
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          ];
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          ); content.push(
            {
              text: "INAB " + data.tcSubregion.tcMunicipio.municipioDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeTrimestral: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'informe.pdf';
          var fecha = new Date();
          var fechaElaboracion = new Date();
          if (data.fechaElaboracion != null) {
            fechaElaboracion = new Date(data.fechaElaboracion);
          }
          var mes = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i, j;
          var personas = data.ttPoaGestion.ttGestion.personas;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = data.ttPoaGestion.ttGestion.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          var trimestre = '';
          switch (data.trimestre) {
            case 1: trimestre = 'Primer trimestre'; break;
            case 2: trimestre = 'Segundo trimestre'; break;
            case 3: trimestre = 'Tercer trimestre'; break;
            case 4: trimestre = 'Cuarto trimestre'; break;
          }
          body = [
            [
              { text: 'Código de licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Titular de la licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: titular, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Representante legal', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: representante, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Numero de turnos', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.ttPoaGestion.ttGestion.ttResumenGestion.noTurnos, style: 'izquierda' },
              { text: 'Turno reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.turno, style: 'izquierda' }
            ],
            [
              { text: 'Trimestre reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: trimestre, style: 'izquierda' },
              { text: 'Fecha de elaboración', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: $filter('date')(fechaElaboracion, 'dd/MM/yyyy'), style: 'izquierda' }
            ]
          ];
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [100, 140, 100, 140],
              body: body
            }
          }
          //tabla volumen
          var total = {
            saldoLenia: 0,
            saldoTroza: 0,
            saldoVolumen: 0,
            lenia: 0,
            troza: 0,
            volumen: 0,
            restoLenia: 0,
            restoTroza: 0,
            restoVolumen: 0
          };
          var filas = [];
          var especies = data.especies;
          for (var i = 0; i < especies.length; i++) {
            item = [
              { text: especies[i].tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
              { text: especies[i].saldoLenia, style: 'small', alignment: 'right' },
              { text: especies[i].saldoTroza, style: 'small', alignment: 'right' },
              { text: especies[i].saldoVolumen, style: 'small', alignment: 'right' },
              { text: especies[i].lenia, style: 'small', alignment: 'right' },
              { text: especies[i].troza, style: 'small', alignment: 'right' },
              { text: especies[i].volumen, style: 'small', alignment: 'right' },
              { text: especies[i].restoLenia, style: 'small', alignment: 'right' },
              { text: especies[i].restoTroza, style: 'small', alignment: 'right' },
              { text: especies[i].restoVolumen, style: 'small', alignment: 'right' }
            ];
            filas.push(item);
            total.saldoLenia += parseFloat(especies[i].saldoLenia);
            total.saldoTroza += parseFloat(especies[i].saldoTroza);
            total.saldoVolumen += parseFloat(especies[i].saldoVolumen);
            total.lenia += parseFloat(especies[i].lenia);
            total.troza += parseFloat(especies[i].troza);
            total.volumen += parseFloat(especies[i].volumen);
            total.restoLenia += parseFloat(especies[i].restoLenia);
            total.restoTroza += parseFloat(especies[i].restoTroza);
            total.restoVolumen += parseFloat(especies[i].restoVolumen);
          }
          tablaFila = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [85, 33, 33, 33, 33, 33, 33, 33, 33, 33],
              body: filas
            }, layout: 'noBorders', colSpan: 10
          }
          total.saldoLenia = parseFloat(total.saldoLenia).toFixed(2);
          total.saldoTroza = parseFloat(total.saldoTroza).toFixed(2);
          total.saldoVolumen = parseFloat(total.saldoVolumen).toFixed(2);
          total.lenia = parseFloat(total.lenia).toFixed(2);
          total.troza = parseFloat(total.troza).toFixed(2);
          total.volumen = parseFloat(total.volumen).toFixed(2);
          total.restoLenia = parseFloat(total.restoLenia).toFixed(2);
          total.restoTroza = parseFloat(total.restoTroza).toFixed(2);
          total.restoVolumen = parseFloat(total.restoVolumen).toFixed(2);
          body = [
            [
              { text: '', style: 'centrar', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen Autorizado m³', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Volumen Transportado m³', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Saldos m³', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Área (ha)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' }
            ],
            [
              { text: data.area, style: 'izquierda' },
              tablaFila, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              { text: 'TOTAL', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: total.saldoTroza, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.saldoLenia, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.saldoVolumen, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.troza, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.lenia, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.volumen, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.restoTroza, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.restoLenia, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: total.restoVolumen, style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' }
            ]
          ];
          var tablaVolumen = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 85, 33, 33, 33, 33, 33, 33, 33, 33, 33],
              body: body
            }
          }
          var n = '3';
          if (data.tieneMovimiento == 1) {
            n = '4';
          }

          body = [
            [
              { text: n + '.', style: 'izquierda', bold: 'true' },
              { text: 'Conclusiones', style: 'izquierda', bold: 'true' }
            ],
            [
              { text: '\n', style: 'izquierda', bold: 'true' },
              { text: '', style: 'izquierda', bold: 'true' }
            ]
          ];
          var tmpData = data.ttDescripcionInforme.conclusiones;
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: n + '.' + (i + 1), style: 'izquierda' },
              { text: tmpData[i].conclusion, style: 'izquierda' }
            ];
            body.push(item);
          }
          var tbConclusion = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              dontBreakRows: true,
              widths: [30, 460],
              body: body
            }, layout: 'noBorders'
          };

          var firmante = 'Regente forestal (' + (data.tcPersonaCrea.rf == null ? '' : data.tcPersonaCrea.rf) + ')';
          if (data.ttPoaGestion.tcRegente == null) {
            firmante = 'Titular de la licencia';
          }

          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB\nPRIMER INFORME TRIMESTRAL\nDE PLAN DE MANEJO FORESTAL",
                  style: 'centrar',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n',
            {
              text: '1. INFORMACION GENERAL',
              style: 'izquierda'
            }, '\n', tablaGeneral, '\n',
            {
              text: '2. DATOS GENERALES DEL PLAN OPERATIVO ANUAL APROBADO',
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: '\t2.1. APROVECHAMIENTO FORESTAL (Tala, extracción y transporte)',
              style: 'izquierda', bold: true
            }, tablaVolumen, '\n',
            {
              text: '\t2.2. Descripción del aprovechamiento forestal',
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: data.ttDescripcionInforme.descripcionAprovechamiento,
              style: 'izquierda'
            }, '\n'
          ];
          if (data.tieneMovimiento) {
            content.push('\n');
            content.push(
              {
                text: '3. SOBRE LA RECUPERACION DEL BOSQUE\nDescripción detallada de actividades previas a realizar',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\t3.1. ACTIVIDADES DE RECUPERACIÓN',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\ta). Ordenamiento y limpieza del área',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.ordenamiento,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\tb). Trazado, ahoyado y siembra',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.trazado,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\tc). Método e recuperación y especies a utilizar',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.recuperacion,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\t3.2. PROTECCION FORESTAL',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\ta). Protección contra incendios forestales',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.incendio,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\tb). Protección contra plagas y enfermedades forestales',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.plaga,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\tc). Protección de ríos, arroyos y otras corrientes de agua',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.proteccion,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '\t3.3. PROGRAMA SOCIAL FORESTAL',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.social,
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '3. OBSERVACIONES',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(
              {
                text: data.ttDescripcionInforme.observaciones,
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          content.push(tbConclusion);
          content.push('\n\n\n\n');
          content.push(
            {
              text: 'F _________________________________\n' + data.tcPersonaCrea.personaDesc + '\n' + firmante,
              style: 'centrar'
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc + '\n' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeNotasLicencia: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'anexo.pdf';
          var fecha = new Date();
          var fechaElaboracion = new Date();
          if (data.fechaElaboracion != null) {
            fechaElaboracion = new Date(data.fechaElaboracion);
          }
          var mes = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i, j;
          var personas = data.ttPoaGestion.ttGestion.personas;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = data.ttPoaGestion.ttGestion.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          var trimestre = '';
          switch (data.trimestre) {
            case 1: trimestre = 'Primer trimestre'; break;
            case 2: trimestre = 'Segundo trimestre'; break;
            case 3: trimestre = 'Tercer trimestre'; break;
            case 4: trimestre = 'Cuarto trimestre'; break;
          }
          var regenteDesc = '', codigoRegente = '';
          if (data.ttPoaGestion.tcRegente != null) {
            regenteDesc = data.ttPoaGestion.tcRegente.personaDesc;
            codigoRegente = data.ttPoaGestion.tcRegente.rf;
          }
          body = [
            [
              { text: 'Código de licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Titular de la licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: titular, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Representante legal', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: representante, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: regenteDesc, style: 'izquierda' },
              { text: 'RFN - Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: codigoRegente, style: 'izquierda' }
            ],
            [
              { text: 'Código POA', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion + '-' + data.turno, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Numero de turnos', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.ttPoaGestion.ttGestion.ttResumenGestion.noTurnos, style: 'izquierda' },
              { text: 'Turno reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.turno, style: 'izquierda' }
            ],
            [
              { text: 'Trimestre reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: trimestre, style: 'izquierda' },
              { text: 'Fecha de elaboración', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: $filter('date')(fechaElaboracion, 'dd/MM/yyyy'), style: 'izquierda' }
            ]
          ];
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [100, 140, 100, 140],
              body: body
            }
          }
          //tabla volumen
          var filas = [];
          var notas = data.notas;
          body = [
            [
              { text: 'No. Nota de envío', style: 'small', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Fecha', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Destino', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Municipio', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Detalle', style: 'izquierda', bold: 'true' }
            ]
          ];
          for (var h = 0; h < notas.length; h++) {
            if (notas[h].mostrarProducto == undefined) {
              notas[h].mostrarProducto = true;
            }
            if (notas[h].mostrarMotivo == undefined) {
              notas[h].mostrarMotivo = true;
            }
            if (notas[h].usoId == 1 && (notas[h].mostrarMotivo || notas[h].mostrarProducto)) {
              var especies = notas[i].especies;
              if (especies == null) {
                especies = [];
              }
              filas = [
                [
                  { text: 'Producto', style: 'small', fillColor: '#CCCCCC', bold: 'true' },
                  { text: 'Especie', style: 'small', fillColor: '#CCCCCC', bold: 'true' },
                  { text: 'Volumen', style: 'small', fillColor: '#CCCCCC', bold: 'true' }
                ]
              ];
              for (var i = 0; i < especies.length; i++) {
                item = [
                  { text: especies[i].tcProductoTransporte.productoTransporteDesc, style: 'small', alignment: 'left' },
                  { text: especies[i].tcEspecie.especieDesc, style: 'small', alignment: 'left' },
                  { text: parseFloat(especies[i].volumenReportar).toFixed(2), style: 'small', alignment: 'left' }
                ];
                filas.push(item);
              }
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [88, 88, 60],
                  body: filas
                }, layout: 'noBorders'
              }
              var strPartes = '';
              var fechaNota = notas[h].fechaNota.toString();
              var posision = fechaNota.indexOf('-');
              if (posision == -1 || posision > 10) {
                notas[h].fechaNota = $filter('date')(new Date(notas[h].fechaNota), 'yyyy-MM-dd');
              }
              var strPartes = notas[h].fechaNota.toString().split('-');
              item = [
                { text: notas[h].numero, style: 'small', alignment: 'right' },
                { text: strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0], style: 'small', alignment: 'left' },
                { text: notas[h].destino, style: 'small', alignment: 'left' },
                { text: notas[h].tcMunicipio.municipioDesc, style: 'small', alignment: 'left' },
                tablaFila
              ];
              body.push(item);
            }
          }
          tablaVolumen = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [30, 40, 90, 60, 250],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB\nAcuerdo de Gerencia G.001-2010\nINFORME USO NOTAS DE ENVIO BOSQUE DE LICENCIAS O CONCESIONES",
                  style: 'centrar',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n',
            tablaGeneral, '\n', tablaVolumen
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeNotaAnuladaLicencia: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'anexo.pdf';
          var fecha = new Date();
          var fechaElaboracion = new Date();
          if (data.fechaElaboracion != null) {
            fechaElaboracion = new Date(data.fechaElaboracion);
          }
          var mes = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i, j;
          var personas = data.ttPoaGestion.ttGestion.personas;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = data.ttPoaGestion.ttGestion.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          var trimestre = '';
          switch (data.trimestre) {
            case 1: trimestre = 'Primer trimestre'; break;
            case 2: trimestre = 'Segundo trimestre'; break;
            case 3: trimestre = 'Tercer trimestre'; break;
            case 4: trimestre = 'Cuarto trimestre'; break;
          }
          var regenteDesc = '', codigoRegente = '';
          if (data.ttPoaGestion.tcRegente != null) {
            regenteDesc = data.ttPoaGestion.tcRegente.personaDesc;
            codigoRegente = data.ttPoaGestion.tcRegente.rf;
          }
          body = [
            [
              { text: 'Código de licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Titular de la licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: titular, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Representante legal', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: representante, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: regenteDesc, style: 'izquierda' },
              { text: 'RFN - Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: codigoRegente, style: 'izquierda' }
            ],
            [
              { text: 'Código POA', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion + '-' + data.turno, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Numero de turnos', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.ttPoaGestion.ttGestion.ttResumenGestion.noTurnos, style: 'izquierda' },
              { text: 'Turno reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.turno, style: 'izquierda' }
            ],
            [
              { text: 'Trimestre reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: trimestre, style: 'izquierda' },
              { text: 'Fecha de elaboración', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: $filter('date')(fechaElaboracion, 'dd/MM/yyyy'), style: 'izquierda' }
            ]
          ];
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [100, 140, 100, 140],
              body: body
            }
          }
          //tabla volumen
          var filas = [];
          var notas = data.notas;
          body = [
            [
              { text: 'No. Nota de envío', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Motivo de anulación', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' }
            ]
          ];
          for (var h = 0; h < notas.length; h++) {
            if (notas[h].usoId == 0) {
              item = [
                { text: notas[h].numero, style: 'derecha', alignment: 'right' },
                { text: notas[h].motivo, style: 'izquierda', alignment: 'left' }
              ];
              body.push(item);
            }
          }
          tablaVolumen = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [100, 397],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB\nAcuerdo de Gerencia G.001-2010\nANULACIÓN NOTAS DE ENVIO BOSQUE",
                  style: 'centrar',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n',
            tablaGeneral, '\n', tablaVolumen
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeNotaExtraviadaLicencia: function (data, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'anexo.pdf';
          var fecha = new Date();
          var fechaElaboracion = new Date();
          if (data.fechaElaboracion != null) {
            fechaElaboracion = new Date(data.fechaElaboracion);
          }
          var mes = parseInt(fecha.getMonth()) + 1;
          var body = [];
          var i, j;
          var personas = data.ttPoaGestion.ttGestion.personas;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = data.ttPoaGestion.ttGestion.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          var trimestre = '';
          switch (data.trimestre) {
            case 1: trimestre = 'Primer trimestre'; break;
            case 2: trimestre = 'Segundo trimestre'; break;
            case 3: trimestre = 'Tercer trimestre'; break;
            case 4: trimestre = 'Cuarto trimestre'; break;
          }
          var regenteDesc = '', codigoRegente = '';
          if (data.ttPoaGestion.tcRegente != null) {
            regenteDesc = data.ttPoaGestion.tcRegente.personaDesc;
            codigoRegente = data.ttPoaGestion.tcRegente.rf;
          }
          body = [
            [
              { text: 'Código de licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Titular de la licencia', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: titular, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Representante legal', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: representante, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: regenteDesc, style: 'izquierda' },
              { text: 'RFN - Regente', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: codigoRegente, style: 'izquierda' }
            ],
            [
              { text: 'Código POA', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: data.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion + '-' + data.turno, style: 'izquierda', colSpan: 3 }, {}, {}
            ],
            [
              { text: 'Numero de turnos', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.ttPoaGestion.ttGestion.ttResumenGestion.noTurnos, style: 'izquierda' },
              { text: 'Turno reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: data.turno, style: 'izquierda' }
            ],
            [
              { text: 'Trimestre reportado', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: trimestre, style: 'izquierda' },
              { text: 'Fecha de elaboración', fillColor: '#CCCCCC', style: 'izquierda' },
              { text: $filter('date')(fechaElaboracion, 'dd/MM/yyyy'), style: 'izquierda' }
            ]
          ];
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [100, 140, 100, 140],
              body: body
            }
          }
          //tabla volumen
          var filas = [];
          var notas = data.notas;
          body = [
            [
              { text: 'No. Nota de envío', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' },
              { text: 'Motivo de extravío', style: 'izquierda', fillColor: '#CCCCCC', bold: 'true' }
            ]
          ];
          for (var h = 0; h < notas.length; h++) {
            if (notas[h].usoId == 2) {
              item = [
                { text: notas[h].numero, style: 'derecha', alignment: 'right' },
                { text: notas[h].motivo, style: 'izquierda', alignment: 'left' }
              ];
              body.push(item);
            }
          }
          tablaVolumen = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 0,
              widths: [100, 397],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\nInstituto Nacional de Bosques INAB\nAcuerdo de Gerencia G.001-2010\nNOTAS DE ENVIO BOSQUE - EXTRAVIADAS",
                  style: 'centrar',
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n',
            tablaGeneral, '\n', tablaVolumen
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaInforme: function (informe, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = informe.ttTarea.ttGestion;
          var tarea = informe.ttTarea;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var paso = null;
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Pase el informe trimestral al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el cumplimiento y emita dictamen a la mayor brevedad.", style: 'izquierda' };
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL INFORME",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: informe.tcPersonaCrea.personaDesc + ", presenta informe trimestral de licencia forestal No. " + informe.ttPoaGestion.ttTarea.ttSeguimientoTarea.numeroResolucion + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    paso,
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarDecisionSuspension: function (dataSuspension, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var tarea = dataSuspension.ttTarea;
          var data = tarea.ttPadre.ttGestion;
          var ttPadre = tarea.ttPadre;
          var ttSolicitud = dataSuspension.ttSolicitud;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var fechaSolicitud = new Date(ttSolicitud.fechaRecepcion);
          var mesSolicitud = parseInt(fechaSolicitud.getMonth()) + 1;
          var nombreSalida = 'resolucion.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presentó";
          if (dataSuspension.opositores.length > 1) {
            textoInicio = "presentaron";
          }
          var opositorNombre = "";
          var conteo = dataSuspension.opositores.length;
          for (var i = 0; i < conteo; i++) {
            opositor = dataSuspension.opositores[i];
            if (i == 0) {
              opositorNombre = opositor.tcOpositor.opositorDesc;
            } else {
              if (conteo == (i + 1)) {
                opositorNombre = opositorNombre + " y " + opositor.tcOpositor.opositorDesc;
              } else {
                opositorNombre = opositorNombre + ", " + opositor.tcOpositor.opositorDesc;
              }
            }
          }
          var propietarioNombre = "";
          conteo = data.personas.length;
          var presentoTitular = 'presentó';
          if (conteo > 1) {
            presentoTitular = 'presentaron';
          }
          for (var i = 0; i < conteo; i++) {
            persona = data.personas[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i + 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var suspende = ' se suspende el trámite, debido a que es procedente la oposición presentada';
          if (tarea.aprobado != 1) {
            suspende = ' no se suspende el trámite, debido a que no es procedente la oposición presentada';
          }
          var textoFinca = '';
          for (i = 0; i < data.fincas.length; i++) {
            if (i == 0) {
              textoFinca = "ubicada en el lugar denominado " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca += "., " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var paso = null;
          var fechaOposicion = new Date(dataSuspension.fechaIngreso);
          var mesOposicion = parseInt(fechaOposicion.getMonth()) + 1;
          var fechaDocumento = new Date(dataSuspension.fechaEmision);
          var fechaDictamen = new Date(ttPadre.fechaFinalizacion);
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN DE SUSPENSIÓN DEL TRÁMITE DE LA LICENCIA FORESTAL ",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución No. " + tarea.ttSeguimientoTarea.numeroResolucion,
              style: 'derecha'
            },
            {
              text: "No. Expediente: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc
                + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE "
                + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE "
                + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que ' + propietarioNombre + ', ' + presentoTitular + ' solicitud de aprovechamiento forestal mediante expediente de licencia de manejo forestal el día '
                + fechaSolicitud.getDate() + ' de ' + getMonth(mesSolicitud) + ' año ' + fechaSolicitud.getFullYear() + ', en el inmueble ' + textoFinca
                + ', y que habiendo cumplido con todos los requisitos establecidos en la normativa forestal vigente, se admitió para su trámite dicha solicitud',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que en el transcurso del trámite administrativo con fecha ' + fechaOposicion.getDate() + ' de ' + getMonth(mesOposicion) + ' de ' + fechaOposicion.getFullYear() + ', '
                + opositorNombre + ', ' + textoInicio + ' oposición de forma escrita argumentando que ellas son las propietarias legitimas del inmueble donde se pretende realizar el aprovechamiento forestal, '
                + 'lo cual documentaron presentando fotocopia simple de ' + dataSuspension.tcTipoPropiedad.tipoPropiedadDesc
                + ' número ' + dataSuspension.numeroDocumento + ', autorizada en ' + dataSuspension.tcMunicipio.municipioDesc
                + ' el ' + fechaDocumento.getDate() + ' de ' + getMonth(fechaDocumento.getMonth() + 1) + ' de ' + fechaDocumento.getFullYear()
                + ', ante el notario ' + dataSuspension.notario,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el dictamen Jurídico ' + ttPadre.ttSeguimientoTarea.numeroResolucion + ', emitido por el Lic. ' + ttPadre.tcPersonaAsignada.personaDesc
                + ', Delegado Jurídico de la Región ' + data.tcSubregion.tcRegion.regionDesc + ', con fecha ' + fechaDictamen.getDate()
                + ' de ' + getMonth(fechaDictamen.getMonth() + 1) + ' de ' + fechaDictamen.getFullYear()
                + ', establece que es procedente decretar la suspensión del trámite de licencia forestal que inicialmente fuera promovido por ' + propietarioNombre,
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: "Con base  a  lo artículos 2, 3, 12  y 28 de la Constitución de la República de Guatemala; 1, 2, 3, 4 y 5 del Decreto 119-96 Ley de lo Contencioso Administrativo; 48, 49, 50 del Decreto 101-96 Ley Forestal y literal “b” del Reglamento de la Ley Forestal, toda vez que no se tienen certeza jurídica sobre quién es el propietario del inmueble en el cual se pretende implementar el plan de manejo forestal y la autorización de la licencia correspondiente",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    {
                      text: 'Suspender el trámite del expediente de solicitud de licencia forestal, codificado con el número ' + data.expediente
                        + ', iniciado por ' + propietarioNombre + ', en virtud  de existir oposición, de acuerdo a memorial y documentación presentada por '
                        + opositorNombre, style: 'izquierda'
                    },
                    {
                      text: 'Al observarse en el expediente administrativo, los principios de: derecho de defensa, sencillez y eficacia del trámite, notificadas ambas partes, se le hace ver de manera puntual a '
                        + opositorNombre + ', que a partir de la suspensión tendrán el plazo de treinta (30) días para que acudan ante los tribunales de justicia a iniciar las diligencias correspondientes para solventar el asunto relacionado al inmueble, el cual se solicita licencia forestal, de la cual deberán presentar copia a esta oficina, copia que deberá llevar el sello de recibido del órgano jurisdiccional, caso contrario, si venciere dicho plazo sin que se compruebe haberse presentado la demanda correspondiente, se tendrá como renunciada la acción opositora y se continuará con el trámite de licencia sin más citarle ni oírle a la parte opositora.', style: 'izquierda'
                    },
                    { text: "Notifíquese a las partes", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacionSuspension: function (dataSuspension, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var ttTarea = dataSuspension.ttTarea;
          var ttPadre = ttTarea.ttPadre;
          var data = ttPadre.ttGestion;
          var strCorreo = data.tcElaborador.correo;
          var opositorNombre = "";
          var conteo = dataSuspension.opositores.length;
          for (var i = 0; i < conteo; i++) {
            opositor = dataSuspension.opositores[i];
            if (i == 0) {
              opositorNombre = opositor.tcOpositor.opositorDesc;
            } else {
              if (conteo == (i + 1)) {
                opositorNombre = opositorNombre + " y " + opositor.tcOpositor.opositorDesc;
              } else {
                opositorNombre = opositorNombre + ", " + opositor.tcOpositor.opositorDesc;
              }
            }
          }
          var propietarioNombre = "";
          conteo = data.personas.length;
          for (var i = 0; i < conteo; i++) {
            persona = data.personas[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i + 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "NOTIFÍQUESE",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          item = {
            text: 'En la sede Sub-regional ' + data.tcSubregion.subregionDesc + ", ubicada en el municipio de " + data.tcSubregion.tcMunicipio.municipioDesc
              + ", departamento de " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
              + ", el día ______ del mes _______________ del año _______ siendo las __________ horas, "
              + "la suscrita se da por enterada de la presente RESOLUCIÓN DE SUSPENSIÓN DEL TRÁMITE DE LA LICENCIA FORESTAL constituida en el expediente número " + data.expediente
              + ', la cual acepta; ratifica y firma',
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaReasignar: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var data = tarea.ttGestion;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.ttSeguimientoTarea.fechaProvidencia);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (data.personas.length > 1) {
            textoInicio = "presentan";
          }
          var solicitanteNombre = "";
          for (var i = 0; i < data.personas.length; i++) {
            solicitante = data.personas[i];
            if (i == 0) {
              solicitanteNombre = solicitante.tcPersona.personaDesc;
            } else {
              solicitanteNombre = solicitanteNombre + ", " + solicitante.tcPersona.personaDesc;
            }
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = data.ttTipoPropietarioGestion.razonSocial;
          }
          var paso = null;
          var firmaJuridico = {};
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null && tarea.tcPersonaJuridico != null) {
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            firmaJuridico = {
              text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
            paso = { text: "Pase en forma simultánea el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica  y copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el plan de manejo presentado, realice inspección de campo y emita informe a la mayor brevedad.", style: 'izquierda' };
          } else {
            if (tarea.tcPersonaJuridico != null) {
              paso = { text: "Pase en el expediente original al Delegado Jurídico Regional, Licenciado " + tarea.tcPersonaJuridico.personaDesc + " para que analice la documentación jurídica y emita dictamen a la mayor brevedad.", style: 'izquierda' };
              firmaJuridico = {
                text: '\n\nDelegado V Jurídico: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
            if (tarea.tcPersonaTraslado != null) {
              paso = { text: "Pase copia completa del expediente al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + " para que verifique el plan de manejo presentado, realice inspección de campo y emita informe a la mayor brevedad.", style: 'izquierda' };
              firmaTecnico = {
                text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
                style: 'small', alignment: 'right'
              };
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + data.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + data.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: solicitanteNombre + ", " + textoInicio + " solicitud de Licencia de aprovechamiento Forestal para la ejecución del  Plan de Manejo Forestal de " + data.tcTipoGestion.tipoGestionDesc + ", en finca " + textoDireccion, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.ttSeguimientoTarea.providencia,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Fórmese el expediente respectivo.", style: 'izquierda' },
                    paso,
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Consta de ______ folios inclusive.",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + data.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaJuridico,
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarResolucionArchivoAbandono: function (datos, strFechaResolucion, strFechaSolicitud, strFechaEnmienda, usuarioDesc, dataEmpresa, sigla) {
          var ttTarea = datos.ttTarea;
          var data = ttTarea.ttGestion;
          var ttEnmienda = datos.ttEnmienda;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'resolucion.pdf';
          var fecha = new Date();
          var solicitan = "solicita";
          var propietarios = '', representantes = '';
          var conteo = data.personas.length;
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 1) {
            if (data.personas.length > 1) {
              solicitan = "solicitan";
            }
            for (i = 0; i < conteo; i++) {
              var persona = data.personas[i];
              if (i == 0) {
                propietarios = persona.tcPersona.personaDesc;
              } else {
                if (conteo == (i + 1)) {
                  propietarios = propietarios + " y " + persona.tcPersona.personaDesc;
                } else {
                  propietarios = propietarios + " y " + persona.tcPersona.personaDesc;
                }
              }
            }
          } else {
            for (i = 0; i < data.representantes.length; i++) {
              var persona = data.representantes[i];
              if (i == 0) {
                representantes = persona.tcPersona.personaDesc;
              } else {
                if (conteo == (i + 1)) {
                  representantes = representantes + " y " + persona.tcPersona.personaDesc;
                } else {
                  representantes = representantes + " y " + persona.tcPersona.personaDesc;
                }
              }
            }
            propietarios = data.ttTipoPropietarioGestion.razonSocial;
            representantes = ' representado legalmente por ' + representantes;
          }
          var textoFinca = '';
          for (i = 0; i < data.fincas.length; i++) {
            finca = data.fincas[i];
            if (i == 0) {
              textoFinca = "ubicada en el lugar denominado " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca += "., " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var content = [
            {
              text: "RESOLUCIÓN DE ABANDONO Y ARCHIVO DEL EXPEDIENTE",
              style: 'centrar'
            }, '\n',
            {
              text: "Número de expediente: " + data.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "Instituto Nacional de Bosques, Subregión " + data.tcSubregion.subregionDesc
                + ", con sede en el municipio de " + data.tcSubregion.tcMunicipio.municipioDesc + " del departamento de "
                + data.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + strFechaResolucion,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: propietarios + representantes + ", " + solicitan + " aprobación del Plan de Manejo Forestal de " + data.tcTipoGestion.tipoGestionDesc
                        + ", en finca " + textoFinca, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: [
                {
                  text: 'RESOLUCIÓN ' + ttTarea.ttSeguimientoTarea.numeroResolucion,
                  fontSize: 12,
                  bold: true
                },
                {
                  text: '. Se tiene a la vista para resolver el expediente arriba identificado, promovido a instancia de  ' + propietarios + ' y,',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que ' + propietarios + ' con fecha ' + strFechaSolicitud + ', gestionó ante el Instituto Nacional de Bosques, solicitud de aprobación del Plan de Manejo de '
                + data.tcTipoGestion.tipoGestionDesc + ', en la finca ' + textoFinca,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Instituto Nacional de Bosques, con fecha ' + strFechaEnmienda + ', emitió oficio de enmiendas No. ' + ttEnmienda.ttTarea.ttSeguimientoTarea.codigo
                + ', por medio del cual se requirió al solicitante que previamente a dar trámite a su solicitud, que cumpliera con presentar los documentos que en dicho oficio se precisan.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que consta en el expediente, que desde la fecha de notificación del oficio relacionado, el solicitante no ha cumplido con presentar los documentos requeridos;',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que desde la fecha de requerimiento de la documentación pertinente al solicitante, han transcurrido más de seis meses y éste no ha cumplido y no ha gestionado absolutamente nada más dentro del expediente, razón que ha limitado impulsar de oficio la sustanciación del mismo; por lo que, es procedente ordenar el archivo de las actuaciones y así procede resolver.',
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: [
                {
                  text: "Esta Dirección Subregional, con fundamento en lo considerado, en las constancias de autos y en lo que al respecto establecen los artículos 1, 2, 3, 4 y 5 de la Ley de lo Contencioso Administrativo; 5, 6 y 9 de la Ley Forestal, al resolver: a) ",
                  style: 'izquierda'
                },
                {
                  text: "Ordena el archivo de las presentes actuaciones",
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: "; b) Notifíquese.",
                  style: 'izquierda'
                }
              ]
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + data.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacionArchivoAbandono: function (data, usuarioDesc, dataEmpresa, esLicencia) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var strCorreo = data.ttGestion.tcElaborador.correo;
          for (i = 0; i < data.ttGestion.personas.length; i++) {
            item = data.ttGestion.personas[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
            }
            strCorreo += "; " + item.tcPersona.correo;
          }
          for (i = 0; i < data.ttGestion.notificaciones.length; i++) {
            var notificacion = data.ttGestion.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              strCorreo += "; " + notificacion.notificacionGestionDesc;
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "CÉDULA DE NOTIFICACIÓN",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          item = {
            text: "En el municipio de " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
              + data.ttGestion.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante
              + ', el contenido íntegro de la Resolución de Archivo y Abandono de Expediente número ' + data.ttSeguimientoTarea.numeroResolucion
              + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          if (esLicencia) {
            content.push(
              {
                text: '\n\nImpreso por: ' + usuarioDesc,
                style: 'small', alignment: 'right'
              }
            );
            content.push(
              {
                text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
                style: 'small', alignment: 'right'
              }
            );
            content.push('\n');
            if (data.ttSeguimientoTarea.fechaProvidencia == null) {
              data.ttSeguimientoTarea.fechaProvidencia = new Date();
            }
            fecha = new Date(data.ttSeguimientoTarea.fechaProvidencia);
            mes = parseInt(fecha.getMonth()) + 1;
            content.push(
              {
                text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
                style: 'small', alignment: 'left'
              }
            );
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudExento: function (data, dataEmpresa) {
          var nombreSalida = 'solicitud.pdf';
          if (data.fechaSolicitud == null) {
            data.fechaSolicitud = new Date();
          } else {
            var parts = data.fechaSolicitud.toString().split('-');
            data.fechaSolicitud = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
          }
          var fecha = new Date(data.fechaSolicitud);
          var mes = parseInt(fecha.getMonth()) + 1;
          var dia = fecha.getDate();
          var anio = fecha.getFullYear();
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var actualizar = []
          var entidad = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          if (data.tcTipoPropietario.tipoPropietarioId == 2) {
            if (data.ttJuridicaExento == null) {
              toastr.error('No se agregaron datos de la entidad jurídica');
              return;
            }
            body = [
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.tcTipoEntidad.tipoEntidadDesc, style: 'detalle', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nitEntidad, style: 'detalle', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.razonSocial, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nombreComercial, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            entidad = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
          }
          body = [
            [
              { text: 'I. INFORMACIÓN GENERAL', style: 'tableSubHeader', colSpan: 5, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}
            ],
            [
              { text: '\nCódigo del RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '\n' + data.codigo, style: 'detalle', alignment: 'center', colSpan: 2, rowSpan: 2 }, {},
              { text: 'Área (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.area, style: 'detalle', alignment: 'left' }
            ],
            [
              { text: '' },
              { text: '' },
              { text: '' },
              { text: 'Longitud (m)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.longitud, style: 'detalle', alignment: 'left' }
            ]
          ];
          if (data.tcTipoPropietario.tipoPropietarioId == 1) {
            body.push(
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Comunidad Lingüística', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Pueblo de Pertenencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Estado civil', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            );
          }
          var dataPersona = data.propietarios;
          var nombres = '';
          for (i = 0; i < dataPersona.length; i++) {
            item = [
              { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcIdioma.idiomaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcCultura.culturaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcEstadoCivil.estadoCivilDesc, style: 'detalle', alignment: 'left' }
            ];
            body.push(item);
            if (dataPersona[i].tcPersona.tcSexo.sexoId == 1) {
              mujeres++;
            } else {
              hombres++;
            }
            if (i == 0) {
              nombres = dataPersona[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + dataPersona[i].tcPersona.cui;
            } else {
              nombres += ', ' + dataPersona[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + dataPersona[i].tcPersona.cui;
            }
          }
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;
          if (data.representantes.length > 0) {
            for (var i = 0; i < data.representantes.length; i++) {
              if (data.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                } else {
                  variosMandatarios = true;
                  mandatarios = data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                } else {
                  representantes = data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                }
              }
            }
            if (representantes.length > 0) {
              item = [
                { text: 'Representante legal', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre completo', style: 'tableSubHeader', colSpan: 2, fillColor: '#CCCCCC', alignment: 'center' }, {},
                { text: 'Código Único de Identificación -CUI-', style: 'tableSubHeader', colSpan: 3, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}
              ];
              body.push(item);
              for (var i = 0; i < data.representantes.length; i++) {
                if (!data.representantes[i].mandatario) {
                  item = [
                    { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                    { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
                  ];
                  body.push(item);
                }
              }
            }
            if (mandatarios.length > 0) {
              item = [
                { text: 'Mandatario', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'CUI', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'Fecha inicio', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'Vence', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
              ];
              body.push(item);
              for (var i = 0; i < data.representantes.length; i++) {                
                var mandatoInicio = '', mandatoFin = '';
                if (data.representantes[i].mandatario) {
                  if (data.representantes[i].mandatoInicio != null) {
                    var partes = data.representantes[i].mandatoInicio.toString().split('-');
                    if (partes.length > 2) {
                      mandatoInicio = partes[2] + '/' + partes[1] + '/' + partes[0];
                    } else {
                      mandatoInicio = $filter('date')(data.representantes[i].mandatoInicio, 'dd/MM/yyyy');
                    }
                  }
                  if (data.representantes[i].mandatoFin == null) {
                    mandatoFin = 'Indefinido';
                  } else {
                    var partes = data.representantes[i].mandatoFin.toString().split('-');
                    if (partes.length > 2) {
                      mandatoFin = partes[2] + '/' + partes[1] + '/' + partes[0];
                    } else {
                      mandatoFin = $filter('date')(data.representantes[i].mandatoFin, 'dd/MM/yyyy');
                    }
                  }
                  item = [
                    { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                    { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
                    { text: mandatoInicio, style: 'detalle', alignment: 'center' },
                    { text: mandatoFin, style: 'detalle', alignment: 'center', colSpan: 2 }, {}
                  ];
                  body.push(item);
                }
              }
            }
          }
          var razonSocial = '';
          if (data.tcTipoPropietario.tipoPropietarioId == 1) {
            item = [
              { text: 'Hombres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: hombres, style: 'tableHeader', alignment: 'left' },
              { text: 'Mujeres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: mujeres, style: 'tableHeader', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
          } else {
            razonSocial = data.ttJuridicaExento.razonSocial;
          }
          var solicitante = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 90, 85, 83, 80],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'II. DATOS DE LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (data.tcTipoPropietario.tipoPropietarioId == 1) {
              for (j = 0; j < finca.propietarios.length; j++) {
                if (j == 0) {
                  propietario = finca.propietarios[j].tcPersona.personaDesc;
                } else {
                  propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
                }
              }
            } else {
              propietario = razonSocial;
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'detalle', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'detalle', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'detalle', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            if (documentos == null) {
              documentos = [];
            }
            var cantidadDocumentos = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 4:
                item = null;
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            if (item != null) {
              body.push(item);
            }
            item = [
              { text: 'Área total de la finca (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.areaDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.area, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var finca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'III. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}
            ]
          ];
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " 
                + row.tcMunicipio.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
              ];
            } else {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc, style: 'detalle', alignment: 'left' }
              ];
            }
            body.push(item);
          }
          var pieElaborador = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          var solicito = {};
          var pie = [];
          var tbVolumen = {};
          var kEnter = {};
          var textoInicial = '';
          var textoSolicito = 'SOLICITO';
          if (data.tcTipoPropietario.tipoPropietarioId == 1) {
            if (dataPersona.length > 1) {
              nombres += '; todos extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              nombres += '; extendido por el Registro Nacional de las Personas -RENAP-';
            }
          } else {
            nombres = data.ttJuridicaExento.razonSocial;
          }
          if (mandatarios.length <= 0) {
            if (representantes.length > 0) {
              if (variosRepresentantes) {
                textoInicial = 'Nosotros: ' + representantes + '; todos extendidos por el Registro Nacional de las Personas -RENAP-. Representando legalmente a ' 
                + nombres + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + representantes + '; extendido por el Registro Nacional de las Personas -RENAP-. Representando legalmente a ' 
                + nombres + '. Encontrándome en libre ejercicio de mis derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            } else {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + nombres + '. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            }
          } else {
            if (representantes.length > 0) {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '. Representados legalmente por ' + representantes + '; todos extendidos por el Registro Nacional de las Personas -RENAP-. A través del mandatario ' 
                + mandatarios + ', todos extendidos por el Registro Nacional de las Personas -RENAP-. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                if (variosRepresentantes) {
                  textoInicial = 'YO: ' + nombres + '. Representado legalmente por ' + representantes + '; todos extendidos por el Registro Nacional de las Personas -RENAP-. A través del mandatario ' 
                  + mandatarios + ', extendido por el Registro Nacional de las Personas -RENAP-. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                  textoSolicito = 'SOLICITAMOS';
                } else {
                  textoInicial = 'YO: ' + nombres + '. Representado legalmente por ' + representantes + '; extendido por el Registro Nacional de las Personas -RENAP-. A través del mandatario ' 
                  + mandatarios + ', extendido por el Registro Nacional de las Personas -RENAP-. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                }
              }
            } else {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '; a través del mandatario ' + mandatarios + ', todos extendidos por el Registro Nacional de las Personas -RENAP-. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + nombres + '; a través del mandatario ' + mandatarios + ', extendido por el Registro Nacional de las Personas -RENAP-. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            }
          }
          actualizar = {
            text: textoInicial + '\n\n',
            style: 'izquierda'
          };
          if (data.actualizar == 1) {
            solicito = {
              text: textoSolicito + ': actualización de volumen, correspondiente al código del RNF: ' + data.codigo,
              style: 'izquierda'
            };
            var rodales = data.rodales;
            if (rodales != null) {
              var soloEnLinea = true, soloRodales = true;
              for (let i = 0; i < rodales.length; i++) {
                const element = rodales[i];
                if (element.enLinea == 0) {
                  soloEnLinea = false;
                } else {
                  soloRodales = false;
                }
              }
              if (soloEnLinea) {
                body = [
                  [
                    { text: 'Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                  ]
                ];
              } else {
                if (soloRodales) {
                  body = [
                    [
                      { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Área (ha.)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                    ]
                  ];
                } else {
                  body = [
                    [
                      { text: 'Rodal/Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Área (ha.)/Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                    ]
                  ];
                }
              }
              var filas = [];
              var tablaFila = {};
              for (var i = 0; i < rodales.length; i++) {
                var rodal = rodales[i];
                var especies = rodales[i].especies;
                filas = [
                  [
                    { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                    { text: 'Vol. / Ha. (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                    { text: 'Vol. / ' + (rodal.enLinea == 1 ? 'Línea' : ' Rodal (m³)'), style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
                  ]
                ];
                for (var j = 0; j < especies.length; j++) {
                  var especie = especies[j];
                  item = [
                    { text: especie.tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: especie.volumenHectaria, style: 'small', alignment: 'right' },
                    { text: especie.volumen, style: 'small', alignment: 'right' }
                  ];
                  filas.push(item);
                }
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 1,
                    widths: [190, 95, 95],
                    body: filas
                  }, layout: 'noBorders'
                }
                item = [
                  { text: rodal.correlativo, style: 'small', alignment: 'center' },
                  { text: rodal.area, style: 'small', alignment: 'center' },
                  tablaFila
                ];
                body.push(item);
              }
              tbVolumen = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [50, 50, 400],
                  body: body
                }
              }
              kEnter = '\n';
            }
          } else {
            pie.push({ text: textoSolicito + ': carga inicial, correspondiente al código del RNF ' + data.codigo, style: 'izquierda' })
          }
          var textFormula = {};
          var tituloFormula = {};
          var textReferenciaFormula = {};
          if (data.ttInformacionExento.calculado) {
            tituloFormula = {
              text: '\nEl cálculo del volumen fue realizado por el solicitante y las fórmulas utilizadas son:\n',
              style: 'izquierda'
            };
            textFormula = {
              text: data.ttInformacionExento.formula,
              style: 'izquierda'
            }
            textReferenciaFormula = {
              text: '\nReferencia de fórmulas utilizadas: ' + data.ttInformacionExento.referenciaFormula + '\n',
              style: 'izquierda'
            };
          }

          var content = [
            '\n',
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ', ' + getMonth(mes) + ' ' + dia + ' de ' + anio,
              style: 'derecha'
            }, '\n', solicitante, entidad, finca, pieElaborador, actualizar, pie, solicito, kEnter, tbVolumen, tituloFormula, textFormula, textReferenciaFormula,
            {
              text: '\n\nElaborado por: ' + data.tcPersonaCrea.tcUsuario.usuario,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'A nombre de: ' + data.tcPersonaCrea.personaDesc,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'derecha', alignment: 'right'
            }

          ];
          if (data.tcPersona == 0) { }
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 60, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            header: function (currentPage, pageCount) {
              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 380,
                      text: "\nMódulo Electrónico para la Autorización de Notas de Envío de Bosque de Exentos de Licencia Forestal\nSolicitud de carga inicial",
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaTrasladoExento: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presentó";
          if (ttExento.propietarios.length > 1) {
            textoInicio = "presentaron";
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var variosPropietarios = false;
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              variosPropietarios = true;
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Trasládese el expediente administrativo al Técnico Forestal " + tarea.tcPersonaAsignada.personaDesc + ", solicitándole revisión del documento referido; así mismo indicar el procedimiento a seguir", style: 'izquierda' };
            firmaTecnico = {
              text: 'Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var aTraves = '';
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;          
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              if (ttExento.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  variosMandatarios = true;
                  mandatarios += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  mandatarios = ttExento.representantes[i].tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  representantes = ttExento.representantes[i].tcPersona.personaDesc;
                }
              }
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }          
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            variosPropietarios = false;
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
            textoInicio = "presentó";
          }
          if (mandatarios.length > 0) {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              }
            } else {
              aTraves = propietarioNombre + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
            }
          } else {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ', ';
              }
            } else {
              aTraves = propietarioNombre;
            }
          }
          aTraves += ' ' + textoInicio + ' ';
          var ol = [];
          if (ttExento.actualizar == 1) {
            ol.push(
              { text: "Pase a " + tarea.tcPersonaTraslado.personaDesc + ", para que verifique la solicitud presentada, realice inspección de campo y emita dictamen técnico", style: 'izquierda' }
            );
          } else {
            ol.push(
              { text: "Pase a " + tarea.tcPersonaTraslado.personaDesc + ", para que verifique la solicitud presentada y emita dictamen técnico de volumen transportado", style: 'izquierda' }
            );
          }
          ol.push(
            {
              text: 'Verificar resoluciones en el Sistema de Manejo (Sistema de Notas de Envío Exentas de Licencia del INAB) y dentro del expediente físico del exento.',
              style: 'izquierda'
            }
          );
          ol.push(
            { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
          );
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: aTraves + 'la solicitud de carga inicial de volumen del Registro No. ' + ttExento.codigo, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: ol
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              izquierdaBold: {
                fontSize: 11,
                alignment: 'justify',
                bold: true
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaActualizarVolumen: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presenta";
          if (ttExento.propietarios.length > 1) {
            textoInicio = "presentan";
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Trasládese el expediente administrativo al Técnico Forestal " + tarea.tcPersonaAsignada.personaDesc + ", solicitándole revisión del documento referido; así mismo indicar el procedimiento a seguir", style: 'izquierda' };
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var aTraves = '';
          var nombreRepresentante = '';
          conteo = ttExento.representantes.length;
          for (i = 0; i < conteo; i++) {
            if ((i == conteo - 1) && i > 1) {
              nombreRepresentante += ' y ' + ttExento.representantes[i].tcPersona.personaDesc;
            } else {
              if (i == 0) {
                nombreRepresentante = ttExento.representantes[i].tcPersona.personaDesc;
              } else {
                nombreRepresentante += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
              }
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            if (ttExento.representantes.length > 1) {
              aTraves = ttExento.ttJuridicaExento.razonSocial + ', por medio de sus representantes legales ' + nombreRepresentante + ', presentaron ';
            } else {
              aTraves = ttExento.ttJuridicaExento.razonSocial + ', por medio de su representante legal ' + nombreRepresentante + ', presentó ';
            }
          } else {
            conteo = ttExento.propietarios.length;
            if (conteo > 1) {
              aTraves = propietarioNombre + ', presentaron ';
            } else {
              aTraves = propietarioNombre + ', presentó ';
            }
            if (ttExento.representantes.length > 0) {
              if (ttExento.representantes.length > 1) {
                aTraves += ', por medio de sus representantes legales ' + nombreRepresentante + ', presentaron ';
              } else {
                aTraves += ', por medio de su representante legal ' + nombreRepresentante + ', presentó ';
              }
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: aTraves + 'la solicitud de carga inicial de volumen del Registro No. ' + ttExento.codigo, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Pase a " + tarea.tcPersonaTraslado.personaDesc + ", para que verifique la solicitud presentada y emita dictamen técnico sobre la actualización de volumen por especie del exento", style: 'izquierda' },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttExento.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeCargaInicial: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'dictamen.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var prefijo = 'en la ';
          if (ttExento.tipoRegistroDesc.toLowerCase().trim().includes('cultivo de caf')) {
            prefijo = '';
          }
          var aTraves = '';
          if (ttExento.actualizar == 1) {
            aTraves = 'Por este medio informo sobre la verificación del expediente e inspección de campo ';
          } else {
            aTraves = 'Por este medio informo la cantidad de volumen transportado ';
          }
          aTraves += prefijo + ttExento.tipoRegistroDesc
            + ' inscrita en el Registro Nacional Forestal con el número ' + ttExento.codigo + ', con registro a nombre de ';
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            aTraves += ttExento.ttJuridicaExento.razonSocial;
          } else {
            aTraves += propietarioNombre;
          }
          var tbVenta = {};
          var ventas = ttExento.ventas;
          if (ventas == null) {
            ventas = [];
          }
          var deAcuerdo = {};
          var tbVolumen = {};
          var tbNotas = {};
          var kEnter = {};
          if (ttExento.actualizar == 1) {
            deAcuerdo = {
              text: '\nDe acuerdo a la verificación de campo se recomienda realizar la carga inicial del volumen en función de la siguiente tabla:\n',
              style: 'izquierda'
            };
            var rodales = ttExento.rodales;
            var soloEnLinea = true, soloRodales = true;
            for (let i = 0; i < rodales.length; i++) {
              const element = rodales[i];
              if (element.enLinea == 0) {
                soloEnLinea = false;
              } else {
                soloRodales = false;
              }
            }
            if (soloEnLinea) {
              body = [
                [
                  { text: 'Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            } else {
              if (soloRodales) {
                body = [
                  [
                    { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Área (ha.)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                  ]
                ];
              } else {
                body = [
                  [
                    { text: 'Rodal/Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Área (ha.)/Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                  ]
                ];
              }
            }
            var filas = [];
            var tablaFila = {};
            for (var i = 0; i < rodales.length; i++) {
              var especies = rodales[i].especies;
              filas = [
                [
                  { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: 'Vol.(m³) / Ha.', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                  { text: 'Vol.(m³) / Rodal / Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
                ]
              ];
              for (var j = 0; j < especies.length; j++) {
                var especie = especies[j];
                item = [
                  { text: especie.tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                  { text: especie.volumenHectaria, style: 'small', alignment: 'right' },
                  { text: especie.volumen, style: 'small', alignment: 'right' }
                ];
                filas.push(item);
              }
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [190, 95, 95],
                  body: filas
                }, layout: 'noBorders'
              }
              item = [
                { text: rodales[i].correlativo, style: 'small', alignment: 'center' },
                { text: rodales[i].area, style: 'small', alignment: 'center' },
                tablaFila
              ];
              body.push(item);
            }
            tbVolumen = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [50, 50, 400],
                body: body
              }
            }
          }
          var losDatos = {
            text: 'Los datos del presente dictamen fueron obtenidos de los informes de uso de notas de envío que constan en los expedientes de la subregión, según resoluciones autorizadas. El número de resoluciones autorizadas fueron verificadas en el Sistema de Manejo (Sistema de Información de autorizaciones de Notas de Envío Exentas de Licencia del INAB) y en los expedientes contenidos en los archivos de la subregión',
            style: 'izquierda'
          };
          var rotuloPendiente = {};
          if (ventas.length > 0) {
            var noHayMovimiento = true;
            for (i = 0; i < ventas.length; i++) {
              var venta = ventas[i];
              if (venta.cantidadNotas == null) {
                venta.cantidadNotas = 0;
              }
              if (venta.porcentajeReportado == null) {
                venta.porcentajeReportado = 0;
              }
              if (venta.autorizadoNotas == null) {
                venta.autorizadoNotas = 0;
              }
              if (parseInt(venta.estadoId) == 5 && parseInt(venta.autorizadoNotas) > 0) {
                noHayMovimiento = false;
              }
              if (venta.cantidadNotas > 0 || venta.porcentajeReportado > 0) {
                noHayMovimiento = false;
              }
            }
            aTraves += '; el volumen transportado es el siguiente:';
            var body = [
              [
                { text: 'No.', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Troza m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Leña m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen total transportado m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var haySinFinalizar = false;
            for (i = 0; i < ventas.length; i++) {
              var rangos = ventas[i].rangos;
              if (rangos == null) {
                rangos = [];
              }
              for (var j = 0; j < rangos.length; j++) {
                rangos[j].usados = [];
                rangos[j].anulados = [];
                rangos[j].extraviados = [];
                rangos[j].noUsados = [];
              }
              if (ventas[i].finalizada == null) {
                if (parseInt(ventas[i].estadoId) == 5) {
                  ventas[i].finalizada = true;
                } else {
                  ventas[i].finalizada = false;
                }
              }
              var informes = ventas[i].informes;
              if (informes == null) {
                informes = [];
              }
              if (!haySinFinalizar) {
                if (!ventas[i].finalizada) {
                  haySinFinalizar = true;
                }
                if (informes.length > 0 && ventas[i].finalizada) {
                  haySinFinalizar = true;
                  ventas[i].finalizada = false;
                }
              }
              for (var j = 0; j < informes.length; j++) {
                if (informes[j].notas == null) {
                  informes[j].notas = [];
                }
                var notas = informes[j].notas;
                for (var k = 0; k < notas.length; k++) {
                  if (notas[k].usoId == 1) { //usados
                    for (var l = 0; l < rangos.length; l++) {
                      var rango = rangos[l];
                      if (parseInt(notas[k].numero) >= parseInt(rango.inicio) && parseInt(notas[k].numero) <= parseInt(rango.fin)) {
                        rango.usados.push(parseInt(notas[k].numero));
                        break;
                      }
                    }
                  }
                  if (notas[k].usoId == 0) { //anulados
                    for (var l = 0; l < rangos.length; l++) {
                      var rango = rangos[l];
                      if (parseInt(notas[k].numero) >= parseInt(rango.inicio) && parseInt(notas[k].numero) <= parseInt(rango.fin)) {
                        rango.anulados.push(parseInt(notas[k].numero));
                        break;
                      }
                    }
                  }
                  if (notas[k].usoId == 2) { //extraviados
                    for (var l = 0; l < rangos.length; l++) {
                      var rango = rangos[l];
                      if (parseInt(notas[k].numero) >= parseInt(rango.inicio) && parseInt(notas[k].numero) <= parseInt(rango.fin)) {
                        rango.extraviados.push(parseInt(notas[k].numero));
                        break;
                      }
                    }
                  }
                  if (notas[k].usoId == -1) { //no usados
                    for (var l = 0; l < rangos.length; l++) {
                      var rango = rangos[l];
                      if (parseInt(notas[k].numero) >= parseInt(rango.inicio) && parseInt(notas[k].numero) <= parseInt(rango.fin)) {
                        rango.noUsados.push(parseInt(notas[k].numero));
                        break;
                      }
                    }
                  }
                }
              }
              for (var j = 0; j < rangos.length; j++) {
                var rango = rangos[j];
                for (var k = rango.inicio; k <= rango.fin; k++) {
                  var noEncontrado = true;
                  var numeros = rango.usados;
                  for (var l = 0; l < numeros.length; l++) {
                    if (k == numeros[l]) {
                      noEncontrado = false;
                      break;
                    }
                  }
                  if (noEncontrado) {
                    numeros = rango.anulados;
                    for (var l = 0; l < numeros.length; l++) {
                      if (k == numeros[l]) {
                        noEncontrado = false;
                        break;
                      }
                    }
                  }
                  if (noEncontrado) {
                    numeros = rango.extraviados;
                    for (var l = 0; l < numeros.length; l++) {
                      if (k == numeros[l]) {
                        noEncontrado = false;
                        break;
                      }
                    }
                  }
                  if (noEncontrado) {
                    rango.noUsados.push(k);
                  }
                }
              }
              var especies = ventas[i].saldos;
              var bodyEspecie = [];
              for (var k = 0; k < especies.length; k++) {
                if (especies[k].reservaTroza == null) {
                  especies[k].reservaTroza = 0;
                }
                if (especies[k].reservaLenia == null) {
                  especies[k].reservaLenia = 0;
                }
                var troza = especies[k].troza;
                var total = especies[k].total;
                var lenia = especies[k].lenia;
                if (especies[k].reservaTroza > 0 || especies[k].reservaLenia > 0) {
                  troza = especies[k].reservaTroza;
                  lenia = especies[k].reservaLenia;
                  total = troza + lenia;
                } else {
                  if (!ventas[i].finalizada) {
                    troza = 0;
                    lenia = 0;
                    total = 0;
                  }
                }
                if (noHayMovimiento) {
                  total = 0;
                  troza = 0;
                  lenia = 0;
                }
                bodyEspecie.push(
                  [
                    { text: especies[k].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                    { text: parseFloat(troza).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(lenia).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(total).toFixed(2), style: 'detalle', alignment: 'right' }
                  ]
                );
              }
              if (bodyEspecie.length <= 0) {
                bodyEspecie.push(
                  [
                    { text: '-', style: 'detalle', alignment: 'left' },
                    { text: '-', style: 'detalle', alignment: 'right' },
                    { text: '-', style: 'detalle', alignment: 'right' },
                    { text: '-', style: 'detalle', alignment: 'right' }
                  ]
                );
              }
              var tbEspecie = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 0,
                  widths: [110, 60, 60, 80],
                  body: bodyEspecie
                }, colSpan: 4, layout: 'noBorders'
              }
              item = [
                { text: i + 1, style: 'detalle', alignment: 'left' },
                { text: ventas[i].resolucion, style: 'detalle', alignment: 'left' },
                tbEspecie, {}, {}, {}
              ];
              body.push(item);
            }

            tbVenta = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [30, 115, 110, 60, 60, 80],
                body: body
              }
            }
            if (haySinFinalizar) {
              function obtenerTextoRangos(textoRetorno, correlativos) {
                if (textoRetorno.length > 0) {
                  textoRetorno += '\n' + correlativos[0] + ' - ';
                } else {
                  textoRetorno = correlativos[0] + ' - ';
                }
                var ultimo = correlativos[0];
                if (correlativos.length > 1) {
                  for (var j = 1; j < correlativos.length; j++) {
                    ultimo++;
                    if (correlativos[j] != ultimo) {
                      textoRetorno += correlativos[j - 1];
                      textoRetorno += '\n' + correlativos[j] + ' - ';
                      ultimo = correlativos[j];
                    }
                  }
                  ultimo = correlativos[correlativos.length - 1];
                  textoRetorno += ultimo;
                } else {
                  textoRetorno += ultimo;
                }
                return textoRetorno;
              }

              function quitarDuplicados(correlativos) {
                var sinDuplicar = [];
                for (var i = 0; i < correlativos.length; i++) {
                  correlativos[i] = parseInt(correlativos[i]);
                }
                for (var i = 0; i < correlativos.length; i++) {
                  var encontrado = false;
                  for (var j = 0; j < sinDuplicar.length; j++) {
                    if (correlativos[i] == sinDuplicar[j]) {
                      encontrado = true;
                      break;
                    }
                  }
                  if (!encontrado) {
                    sinDuplicar.push(parseInt(correlativos[i]));
                  }
                }
                return sinDuplicar;
              }

              function ordenarBurbuja(correlativos) {
                for (var i = 0; i < correlativos.length; i++) {
                  for (var j = 0; j < correlativos.length - i; j++) {
                    if (correlativos[j] > correlativos[j + 1]) {
                      var aux = parseInt(correlativos[j]);
                      correlativos[j] = parseInt(correlativos[j + 1]);
                      correlativos[j + 1] = aux;
                    }
                  }
                }
                return correlativos;
              }
              rotuloPendiente = {
                text: 'La tabla siguiente muestra el resumen de resoluciones de ventas con notas pendientes a reportar',
                style: 'izquierda'
              };
              var body = [
                [
                  { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Usados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Anulados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Extraviados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'No reportados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
              for (i = 0; i < ventas.length; i++) {
                if (!ventas[i].finalizada) {
                  var textoUsado = '';
                  var textoAnulados = '';
                  var textoExtraviados = '';
                  var textoNoUsados = '';
                  var partes = [];
                  if (ventas[i].fechaFinalizacion.toString().length <= 10) {
                    partes = ventas[i].fechaFinalizacion.toString().split('-');
                  }
                  var fechaFinalizacion = '';
                  if (partes.length > 2) {
                    fechaFinalizacion = '\nVence: ' + partes[2] + '/' + partes[1] + '/' + partes[0];
                  } else {
                    fechaFinalizacion = '\nVence: ' + $filter('date')(new Date(ventas[i].fechaFinalizacion), 'dd/MM/yyyy');
                  }
                  for (var j = 0; j < rangos.length; j++) {
                    var correlativos = quitarDuplicados(rangos[j].usados);
                    correlativos = ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoUsado = obtenerTextoRangos(textoUsado, correlativos);
                    } else {
                      if (textoUsado.length <= 0) {
                        textoUsado = '-';
                      }
                    }
                    correlativos = quitarDuplicados(rangos[j].anulados);
                    correlativos = ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoAnulados = obtenerTextoRangos(textoAnulados, correlativos);
                    } else {
                      if (textoAnulados.length <= 0) {
                        textoAnulados = '-';
                      }
                    }
                    correlativos = quitarDuplicados(rangos[j].extraviados);
                    correlativos = ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoExtraviados = obtenerTextoRangos(textoExtraviados, correlativos);
                    } else {
                      if (textoExtraviados.length <= 0) {
                        textoExtraviados = '-';
                      }
                    }
                    correlativos = quitarDuplicados(rangos[j].noUsados);
                    correlativos = ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoNoUsados = obtenerTextoRangos(textoNoUsados, correlativos);
                    } else {
                      if (textoNoUsados.length <= 0) {
                        textoNoUsados = '-';
                      }
                    }
                  }
                  body.push(
                    [
                      { text: ventas[i].resolucion + fechaFinalizacion, style: 'detalle', alignment: 'left' },
                      { text: textoUsado, style: 'detalle', alignment: 'center' },
                      { text: textoAnulados, style: 'detalle', alignment: 'center' },
                      { text: textoExtraviados, style: 'detalle', alignment: 'center' },
                      { text: textoNoUsados, style: 'detalle', alignment: 'center' }
                    ]
                  );
                }
              }
              tbNotas = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [110, 90, 90, 90, 90],
                  body: body
                }
              }
              kEnter = '\n';
            } else {
              rotuloPendiente = {
                text: '\nSegún la verificación del expediente, no existen notas de envió pendientes por reportar para el registro número ' + ttExento.codigo,
                style: 'izquierda'
              };
            }
          } else {
            prefijo = 'que la ';
            if (ttExento.tipoRegistroDesc.toLowerCase().trim().includes('cultivo de caf')) {
              prefijo = 'que ';
            }
            if (ttExento.actualizar == 1) {
              aTraves = 'Por este medio informo sobre la verificación del expediente e inspección de campo, ';
            } else {
              aTraves = 'Por este medio informo ';
            }
            aTraves += prefijo + ttExento.tipoRegistroDesc.trim()
              + ' inscrita en el Registro Nacional Forestal con el número ' + ttExento.codigo + ', a nombre de ';
            if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
              aTraves += ttExento.ttJuridicaExento.razonSocial;
            } else {
              aTraves += propietarioNombre;
            }
            aTraves += '; no tiene volumen transportado, no tiene resoluciones activas, y no existen notas de envió pendientes por reportar. Dicha información fue verificada en el Sistema de Manejo (Sistema de Información de autorizaciones de Notas de Envío Exentas de Licencia del INAB) y en los expedientes contenidos en los archivos de la Subregión.';
            losDatos = {};
          }
          var enterObservaciones = {};
          var tbObservaciones = {};
          if (tarea.observaciones != null) {
            if (tarea.observaciones.razon == null) {
              tarea.observaciones.razon = [];
            }
            if (tarea.observaciones.razon.length > 0) {
              enterObservaciones = '\n';
              body = [
                [
                  { text: 'Observaciones\n', style: 'detalle', bold: true, colSpan: 2 }, {},
                ]
              ];
              for (let i = 0; i < tarea.observaciones.razon.length; i++) {
                var row = tarea.observaciones.razon[i];
                body.push(
                  [
                    { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                    { text: row.descripcion, style: 'detalle', alignment: 'left' }
                  ]
                )
              }
              tbObservaciones = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [15, 485],
                  body: body
                }, layout: 'noBorders'
              }
            }
          }
          var content = [
            {
              text: "DICTAMEN TÉCNICO",
              style: 'centrar'
            }, '\n',
            {
              text: "Dictamen técnico número: " + tarea.codigo,
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaProvidencia.getDate() + " de " + getMonth(fechaProvidencia.getMonth() + 1) + " del año " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: ttExento.tcSubregion.tcSubregional.personaDesc + "\nDirector Subregional "
                + ttExento.tcSubregion.subregionDesc + "\nINAB, " + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n',
            tbVenta, '\n',
            losDatos, kEnter, rotuloPendiente, kEnter, tbNotas, kEnter, deAcuerdo, '\n', tbVolumen, tbObservaciones, enterObservaciones,
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Técnico forestal, subregión " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionCargaInicial: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          var correo = '';
          var esActualizar = false;

          if (ttExento.actualizar == 1) {
            esActualizar = true;
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 1) {
            for (i = 0; i < conteo; i++) {
              persona = ttExento.propietarios[i];
              if (i == 0) {
                propietarioNombre = persona.tcPersona.personaDesc;
                correo = persona.tcPersona.correo;
              } else {
                correo = correo + ", " + persona.tcPersona.correo;
              }
            }
          }
          conteo = ttExento.representantes.length;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.representantes[i];
            if (correo.length > 0) {
              correo = correo + ", " + persona.tcPersona.correo;
            } else {
              correo = persona.tcPersona.correo;
            }
          }
          conteo = ttExento.notificaciones.length;
          for (i = 0; i < conteo; i++) {
            notificacion = ttExento.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              if (correo.length > 0) {
                correo = correo + ", " + notificacion.notificacionExentoDesc;
              } else {
                correo = notificacion.notificacionExentoDesc;
              }
            }
          }
          var paso = null;
          var aTraves = 'Informamos que la carga inicial del Exento con código inscrita en el Registro Nacional Forestal con el número '
            + ttExento.codigo + ' (' + ttExento.tipoRegistroDesc + '), ha sido realizada exitosamente. A continuación le mostramos el resumen de la carga realizada: ';
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }
          var tbVenta = {};
          var tbObservaciones = {};
          var observaciones = [];
          var tmpData = ttExento.especies;
          if (tmpData == null) {
            tmpData = [];
          }
          if (tmpData.length > 0) {
            var body = [];
            if (esActualizar) {
              body = [
                [
                  { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen (m³) disponible', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            } else {
              body = [
                [
                  { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen (m³) según RNF', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen (m³) transportado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                  { text: 'Volumen (m³) disponible', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                ]
              ];
            }
            var totales = {
              volumen: 0,
              saldo: 0,
              transportado: 0
            }
            var hayNegativo = false;
            if (esActualizar) {
              for (var j = 0; j < tmpData.length; j++) {
                totales.saldo += parseFloat(tmpData[j].saldo);
                if (parseFloat(tmpData[j].saldo) < 0) {
                  hayNegativo = true;
                }
                body.push(
                  [
                    { text: tmpData[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                    { text: parseFloat(tmpData[j].saldo).toFixed(2), style: 'detalle', alignment: 'right' }
                  ]
                );
              }
              body.push(
                [
                  { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(totales.saldo).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );
              tbVenta = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [330, 150],
                  body: body
                }
              }
            } else {
              for (var j = 0; j < tmpData.length; j++) {
                var transportado = 0;
                transportado = parseFloat(tmpData[j].volumen) - parseFloat(tmpData[j].saldo);
                totales.volumen += parseFloat(tmpData[j].volumen);
                totales.saldo += parseFloat(tmpData[j].saldo);
                if (parseFloat(tmpData[j].saldo) < 0) {
                  hayNegativo = true;
                }
                totales.transportado += parseFloat(transportado);
                body.push(
                  [
                    { text: tmpData[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                    { text: parseFloat(tmpData[j].volumen).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(transportado).toFixed(2), style: 'detalle', alignment: 'right' },
                    { text: parseFloat(tmpData[j].saldo).toFixed(2), style: 'detalle', alignment: 'right' }
                  ]
                );
              }
              body.push(
                [
                  { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: parseFloat(totales.volumen).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(totales.transportado).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(totales.saldo).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );

              tbVenta = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [200, 90, 90, 90],
                  body: body
                }
              }
            }
          }
          if (tarea.observaciones == null) {
            tarea.observaciones = {
              observaciones: [],
              rangos: []
            };
          }
          var observaciones = tarea.observaciones.observaciones;
          var enterObservaciones = {};
          if (observaciones.length > 0) {
            enterObservaciones = '\n';
            body = [
              [
                { text: 'Observaciones\n', style: 'detalle', bold: true, colSpan: 2 }, {},
              ]
            ];
            for (let i = 0; i < observaciones.length; i++) {
              var row = observaciones[i];
              body.push(
                [
                  { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                  { text: row.observacion, style: 'detalle', alignment: 'left' }
                ]
              )
            }
            tbObservaciones = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [15, 485],
                body: body
              }, layout: 'noBorders'
            }
          }
          var rotuloPendiente = {};
          var kEnter = {};
          var rangos = tarea.observaciones.rangos;
          var hayRevalidar = false;
          if (rangos.length > 0) {
            rotuloPendiente = {
              text: 'La tabla siguiente muestra el resumen de resoluciones de ventas con notas pendientes a reportar',
              style: 'izquierda'
            };
            var body = [
              [
                { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Usados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Anulados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Extraviados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'No reportados', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            for (let i = 0; i < rangos.length; i++) {
              const rango = rangos[i];
              body.push(
                [
                  { text: rango.resolucion, style: 'detalle', alignment: 'left' },
                  { text: rango.usados, style: 'detalle', alignment: 'center' },
                  { text: rango.anulados, style: 'detalle', alignment: 'center' },
                  { text: rango.extraviados, style: 'detalle', alignment: 'center' },
                  { text: rango.pendientes, style: 'detalle', alignment: 'center' }
                ]
              );
              if (rango.pendientes.length > 0) {
                hayRevalidar = true;
              }
            }
            tbNotas = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [110, 85, 85, 85, 85],
                body: body
              }
            }
            kEnter = '\n';
          }
          if (!hayRevalidar) {
            tbNotas = {
              text: 'Según la revisión del expediente físico, no existen notas de envió pendientes por reportar',
              style: 'izquierda'
            };
            kEnter = '\n';
          }
          if (tarea.codigo == null) {
            tarea.codigo = 'XX-XXXX';
          }
          var textoInforme = 'Le informamos que puede realizar la solicitud de venta de notas de envío desde cualquier dispositivo accediendo al módulo de exentos con su cuenta y clave correspondiente';
          if (hayNegativo || (hayNegativo && tarea.esEnmienda == -1)) {
            textoInforme = 'Le informamos que antes de solicitar una nueva venta de notas de envío debe actualizar el volumen del certificado de inscripción en el Registro Nacional Forestal';
          } else {
            if (tarea.esEnmienda == -1) {
              textoInforme = '  ';
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nMódulo Electrónico para la Autorización de Notas de Envío Exentas de Licencia"
                    + "\nNotificación No. " + tarea.codigo,
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Fecha: " + fechaProvidencia.getDate() + " de " + getMonth(fechaProvidencia.getMonth() + 1) + " del año " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr(s)./Sra(s).: " + propietarioNombre,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n', tbVenta, '\n', rotuloPendiente, kEnter, tbNotas, kEnter, tbObservaciones, enterObservaciones, 
            {
              text: textoInforme,
              style: 'izquierda'
            },
            'Atentamente,',
            '\n\n\n\n',
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director(a) subregional\nSubregión " + ttExento.tcSubregion.subregionDesc + ', ' + ttExento.tcSubregion.alias,
              style: 'centrar'
            }, '\n\n',
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fechaProvidencia.getDay()) + " " + fechaProvidencia.getDate() + " de " + getMonth(fechaProvidencia.getMonth() + 1) + " de " + fechaProvidencia.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudVentaNotaExento: function (data, dataEmpresa) {
          var nombreSalida = 'solicitud.pdf';
          var fecha = new Date();
          var venta = data.ventas[0];
          var fechaRegistro = new Date(venta.fechaRegistro);
          var mes = parseInt(fechaRegistro.getMonth()) + 1;
          var dia = fechaRegistro.getDate();
          var anio = fechaRegistro.getFullYear();
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var entidad = {};
          var i = 0, j = 0, k = 0, hombres = 0, mujeres = 0, volumenTotal = 0;
          for (i = 0; i < venta.rodales.length; i++) {
            var especies = venta.rodales[i].especies;
            for (j = 0; j < especies.length; j++) {
              volumenTotal += parseFloat(especies[j].totalSolicitado);
            }
          }
          var metodos = '';
          var tmpMetodos = [];
          for (i = 0; i < venta.rodales.length; i++) {
            var rodal = venta.rodales[i];
            for (var j = 0; j < rodal.especies.length; j++) {
              var especie = rodal.especies[j];
              var encontrado = false;
              for (var k = 0; k < tmpMetodos.length; k++) {
                if (especie.tcTratamientoSilvicultural.tratamientoSilviculturalId == tmpMetodos[k].tratamientoSilviculturalId) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                tmpMetodos.push(especie.tcTratamientoSilvicultural);
              }
            }
          }
          for (i = 0; i < tmpMetodos.length; i++) {
            var row = tmpMetodos[i];
            if (i == 0) {
              metodos = row.tratamientoSilviculturalDesc;
            } else {
              metodos += ', ' + row.tratamientoSilviculturalDesc;
            }
          }
          var transporte = '';
          for (i = 0; i < venta.transporte.length; i++) {
            var row = venta.transporte[i];
            if (i == 0) {
              transporte = row.tcTipoTransporte.tipoTransporteDesc;
            } else {
              transporte += ', ' + row.tcTipoTransporte.tipoTransporteDesc;
            }
          }
          var textoEncontrando = 'Encontrándome', textoNuestro = 'mis', textoGenerales = '';
          var dpiEspacio = '';
          var dataPersona = data.propietarios;
          var nombres = '', conDpi = '';
          var i = 0;
          itemPersona = [
            [
              { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Código Único de Identificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var conteo = dataPersona.length;
          for (i = 0; i < dataPersona.length; i++) {
            persona = dataPersona[i];
            if (i == 0) {
              nombres = persona.tcPersona.personaDesc;
              conDpi = persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
            } else {
              dpiEspacio = persona.tcPersona.personaDesc + '\nCódigo Único de Identificación -CUI-:' + persona.tcPersona.cui;
              if (conteo == (i - 1)) {
                nombres = nombres + " y " + persona.tcPersona.personaDesc;
                conDpi = conDpi + ' y ' + persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
              } else {
                nombres = nombres + ", " + persona.tcPersona.personaDesc;
                conDpi = conDpi + ', ' + persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
              }
            }
            item = [
              { text: persona.tcPersona.personaDesc, style: 'izquierda' },
              { text: persona.tcPersona.cui, style: 'izquierda' }
            ];
            itemPersona.push(item);
          }
          if (conDpi.length > 0) {
            conDpi += ' extendido por el Registro Nacional de las Personas -RENAP-'
          }
          if (data.representantes == null) {
            data.representantes = [];
          }
          var mandatarios = '', representantes = '', mandatariosNombre = '', representantesNombre = '';
          var variosRepresentantes = false, variosMandatarios = false;
          if (data.representantes.length > 0) {
            for (var i = 0; i < data.representantes.length; i++) {
              persona = data.representantes[i];
              if (persona.mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = mandatariosNombre + ", " + persona.tcPersona.personaDesc;
                  variosMandatarios = true;
                } else {
                  mandatarios = persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = persona.tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = representantesNombre + ", " + persona.tcPersona.personaDesc;
                } else {
                  representantes = persona.tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = persona.tcPersona.personaDesc;
                }
              }
            }
          }
          if (representantes.length > 0) {
            if (variosRepresentantes) {
              representantes += '; extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              representantes += '; extendido por el Registro Nacional de las Personas -RENAP-';
            }
          }
          if (mandatarios.length > 0) {
            if (variosMandatarios) {
              mandatarios += '; extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              mandatarios += '; extendido por el Registro Nacional de las Personas -RENAP-';
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }
          var item = {};
          var itemRepresentante = {}, itemMandatario = {};
          if (data.tcTipoPropietario.tipoPropietarioId == 2) {
            nombres = data.ttJuridicaExento.razonSocial;
            textoGenerales = {
              text: 'Nombre: ' + data.ttJuridicaExento.razonSocial,
              style: 'izquierda'
            };
          } else {
            itemPersona.push(
              [
                {
                  text: '* Documentos extendidos por el Registro Nacional de Personas -RENAP',
                  fontSize: 8,
                  alignment: 'left',
                  bold: true,
                  colSpan: 2
                }, {}
              ]
            );
            textoGenerales = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [265, 250],
                body: itemPersona
              }
            }
          }
          if (representantes.length > 0) {
            itemRepresentante = {
              text: 'Representante(s) Legal(es): ' + representantesNombre,
              style: 'izquierda'
            }
          }
          if (mandatarios.length > 0) {
            itemMandatario = {
              text: 'Mandatario(s): ' + mandatariosNombre,
              style: 'izquierda'
            }
          }

          //datos de la finca en la solicitud
          body = [
            [
              { text: 'Código del RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.codigo, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área registrada (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.area, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (data.tcTipoPropietario.tipoPropietarioId == 2) {
              propietario = data.ttJuridicaExento.razonSocial;
            } else {
              for (j = 0; j < finca.propietarios.length; j++) {
                if (j == 0) {
                  propietario = finca.propietarios[j].tcPersona.personaDesc;
                } else {
                  propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
                }
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento que acredita la propiedad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 3 }, {}, {},
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 3, alignment: 'left' }, {}, {}
              ]
            );
            var documentos = finca.documentos;
            if (documentos == null) {
              documentos = [];
            }
            var cantidadDocumentos = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {},
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 4:
                item = null;
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            if (item != null) {
              body.push(item);
            }
            item = [
              { text: 'Área total de la finca (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.areaDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.area, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var tbFinca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [];
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            } else {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            }
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 365],
              body: body
            }
          }
          body = [
            [
              { text: '\n\n\n', style: 'tableSubHeader', alignment: 'left' }
            ],
            [
              { text: data.tcPersonaCrea.personaDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ],
            [
              { text: 'Solicitante', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var pieFirma = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [525],
              body: body
            }
          }
          var body = [
            [
              { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Número de Árboles', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Troza', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Leña', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Total', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            trozaSolicitada: 0,
            leniaSolicitada: 0,
            totalSolicitado: 0,
            arbolesSolicitado: 0
          }
          var tmpData = venta.rodales;
          for (i = 0; i < tmpData.length; i++) {
            var rodal = tmpData[i];
            for (var j = 0; j < rodal.especies.length; j++) {
              totales.trozaSolicitada += parseFloat(rodal.especies[j].trozaSolicitada);
              totales.leniaSolicitada += parseFloat(rodal.especies[j].leniaSolicitada);
              totales.totalSolicitado += parseFloat(rodal.especies[j].totalSolicitado);
              totales.arbolesSolicitado += parseInt(rodal.especies[j].arbolesSolicitado);
              body.push(
                [
                  { text: rodal.correlativo, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].arbolesSolicitado, style: 'detalle', alignment: 'right' },
                  { text: parseFloat(rodal.especies[j].trozaSolicitada).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(rodal.especies[j].leniaSolicitada).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(rodal.especies[j].totalSolicitado).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );
            }
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left', colSpan: 2 }, {},
              { text: totales.arbolesSolicitado, style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.trozaSolicitada).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.leniaSolicitada).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.totalSolicitado).toFixed(2), style: 'detalle', alignment: 'right' }
            ]
          );
          tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 155, 50, 80, 80, 80],
              body: body
            }
          }
          var textoRevalidar = {};
          if (venta.rangosRevalidar.length > 0) {
            textoRevalidar = {
              text: 'Notas de envió a revalidar: \n',
              style: 'izquierda'
            }
            var rangosRevalidar = venta.rangosRevalidar;
            for (let i = 0; i < rangosRevalidar.length; i++) {
              let rango = rangosRevalidar[i];
              textoRevalidar.text += '\n\t' + (i + 1) + '. Del ' + rango.inicio + ' al ' + rango.fin;
            }
            textoRevalidar.text += '\n\n';
          }
          if (venta.anio == null) {
            venta.anio = (new Date()).getFullYear();
          }
          if (venta.numero == undefined) {
            venta.numero = 0;
          }
          if (venta.numero == null) {
            venta.numero = 0;
          }
          volumenTotal = parseFloat(volumenTotal).toFixed(2);
          var objetoSolicito = {
            text: 'Se autorice la venta de ' + venta.cantidadNotas + ' Notas de Envío de Bosque Exento de Licencia Forestal para transportar un volumen aproximado de ­­­'
              + volumenTotal + ' m³ proveniente de la actividad silvicultural de ' + metodos +
              ', correspondiente a ' + data.tcCategoriaExento.categoriaExentoDesc + ' con registro número ' + data.codigo + ', de acuerdo al cuadro siguiente:',
            style: 'izquierda'
          }
          if (venta.paraRevalidar == 1) {
            objetoSolicito = {
              text: 'Se autorice la revalidación ' + (venta.cantidadNotas > 0 ? 'y venta de ' + venta.cantidadNotas + ' ' : '') + 'de Notas de Envío de Bosque Exento de Licencia Forestal para transportar un volumen aproximado de ­­­'
                + volumenTotal + ' m³ proveniente de la actividad silvicultural de ' + metodos +
                ', correspondiente a ' + data.tcCategoriaExento.categoriaExentoDesc + ' con registro número ' + data.codigo + ', de acuerdo al cuadro siguiente:',
              style: 'izquierda'
            }
          }
          var textoInicial = '';
          var textoSolicito = 'SOLICITO';
          if (mandatarios.length <= 0) {
            if (representantes.length > 0) {
              if (variosRepresentantes) {
                textoInicial = 'Nosotros: ' + representantes + '. Representando legalmente a ' 
                + nombres + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + representantes + '. Representando legalmente a ' 
                + nombres + '. Encontrándome en libre ejercicio de mis derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            } else {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + nombres + '. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            }
          } else {
            if (representantes.length > 0) {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '. Representados legalmente por ' + representantes + '. A través del mandatario ' 
                + mandatarios + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                if (variosRepresentantes) {
                  textoInicial = 'YO: ' + nombres + '. Representado legalmente por ' + representantes + '. A través del mandatario ' 
                  + mandatarios + '. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                  textoSolicito = 'SOLICITAMOS';
                } else {
                  textoInicial = 'YO: ' + nombres + '. Representado legalmente por ' + representantes + '. A través del mandatario ' 
                  + mandatarios + '. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                }
              }
            } else {
              if (dataPersona.length > 1) {
                textoInicial = 'Nosotros: ' + nombres + '; a través del mandatario ' + mandatarios + '. Encontrándonos en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
                textoSolicito = 'SOLICITAMOS';
              } else {
                textoInicial = 'YO: ' + nombres + '; a través del mandatario ' + mandatarios + '. Encontrándome en libre ejercicio de nuestros derechos civiles, ante el Instituto Nacional de Bosques.';
              }
            }
          }
          var content = [
            '\n',
            {
              text: 'Número de solicitud: ' + data.tcSubregion.codigo + '-' + venta.numero + '-' + data.tcSubtipoExento.codigoVenta + '-' + venta.anio,
              style: 'derecha'
            },
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ', ' + getMonth(mes) + ' ' + dia + ' de ' + anio,
              style: 'derecha'
            }, '\n',
            {
              text: 'I. Datos Generales',
              style: 'izquierda',
              bold: true
            }, textoGenerales,
            {
              text: 'Tipo de propietario: ' + data.tcTipoPropietario.tipoPropietarioDesc,
              style: 'izquierda'
            }, itemRepresentante, itemMandatario, '\n',
            {
              text: 'II. Datos de la Plantación Forestal o Sistema Agroforestal',
              style: 'izquierda',
              bold: true
            }, tbFinca,
            {
              text: 'III. Datos de notificación',
              style: 'izquierda',
              bold: true
            }, tbNotificacion,
            {
              text: textoInicial,
              style: 'izquierda'
            }, '\n',
            {
              text: textoSolicito,
              style: 'centrar'
            }, '\n',
            objetoSolicito, tbVenta, textoRevalidar,
            {
              text: 'Tipo de transporte a utilizar: ' + transporte + '. Los productos Forestales que serán utilizados con fines: ' + (venta.finComercial == 1 ? 'Comerciales. ' : 'Autoconsumo. ')
                + (venta.esPrimeraVenta == 1 ? 'No se han realizado otras solicitudes de venta de notas de envío' : 'Se han realizado solicitudes previas de venta de notas de envío'),
              style: 'izquierda'
            }, '\n',
            {
              text: '\n\nElaborado por: ' + venta.tcPersonaCrea.tcUsuario.usuario,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'A nombre de: ' + venta.tcPersonaCrea.personaDesc,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fechaRegistro, 'dd/MM/yyyy HH:mm'),
              style: 'derecha', alignment: 'right'
            }
          ];
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 70, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            header: function (currentPage, pageCount) {
              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 380,
                      text: "\nMódulo Electrónico para la Autorización de Notas de Envío de Bosque de Exentos de Licencia Forestal\nSolicitud de autorización de Notas de Envío Exentas de Licencia",
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaVentaNota: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          var ttVentaExento = tarea.ttVentaExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoInicio = "presentó";
          if (ttExento.propietarios.length > 1) {
            textoInicio = "presentaron";
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          var variosPropietarios = false;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              variosPropietarios = true;
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Trasládese el expediente administrativo al Técnico Forestal " + tarea.tcPersonaAsignada.personaDesc + ", solicitándole revisión del documento referido; así mismo indicar el procedimiento a seguir", style: 'izquierda' };
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var aTraves = '';
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;          
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              if (ttExento.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  variosMandatarios = true;
                  mandatarios += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  mandatarios = ttExento.representantes[i].tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  representantes = ttExento.representantes[i].tcPersona.personaDesc;
                }
              }
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }          
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            variosPropietarios = false;
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
            textoInicio = "presentó";
          }
          if (mandatarios.length > 0) {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              }
            } else {
              aTraves = propietarioNombre + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
            }
          } else {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ', ';
              }
            } else {
              aTraves = propietarioNombre;
            }
          }
          aTraves += textoInicio;
          var textoHacer = ". Para que realice inspección de campo";
          if (tarea.numero == 1) {
            textoHacer = ". Para que realice análisis de gabinete";
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: aTraves + ' la solicitud de notas de envío número ' + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-'
                        + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio
                        + ', para el transporte del producto forestal proveniente del registro número ' + ttExento.codigo, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    {
                      text: "Pase al Técnico Forestal " + tarea.tcPersonaTraslado.personaDesc + textoHacer + " y dictamine sobre la solicitud de notas de envío número "
                        + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio, style: 'izquierda'
                    },
                    {
                      text: "Verifique en el expediente el Registro No. " + ttExento.codigo + " y con base a los datos de volumen transportado, volumen existente y volumen solicitado dictamine sobre la solicitud No. "
                        + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio, style: 'izquierda'
                    },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeVentaNota: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          var ttVentaExento = tarea.ttVentaExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var rodales = ttExento.rodales;
          var rodalesVenta = ttVentaExento.rodales;
          for (var i = 0; i < rodalesVenta.length; i++) {
            for (var j = 0; j < rodales.length; j++) {
              if (rodalesVenta[i].correlativo == rodales[j].correlativo) {
                rodalesVenta[i].area = rodales[j].area;
                break;
              }
            }
          }
          var i;
          var nombreSalida = 'dictamen.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "", sinDpi = "";
          var i = 0;
          var dataPersona = ttExento.propietarios;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
              sinDpi = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
                sinDpi = sinDpi + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
                sinDpi = sinDpi + ", " + persona.tcPersona.personaDesc;
              }
            }
            propietarioNombre += ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación –CUI– '
              + persona.tcPersona.cui;
          }
          var mandatarios = '', representantes = '', mandatariosNombre = '', representantesNombre = '';
          var variosRepresentantes = false, variosMandatarios = false;
          if (ttExento.representantes.length > 0) {
            for (var i = 0; i < ttExento.representantes.length; i++) {
              persona = ttExento.representantes[i];
              if (persona.mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = mandatariosNombre + ", " + persona.tcPersona.personaDesc;
                  variosMandatarios = true;
                } else {
                  mandatarios = persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = persona.tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = representantesNombre + ", " + persona.tcPersona.personaDesc;
                } else {
                  representantes = persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = persona.tcPersona.personaDesc;
                }
              }
            }
          }
          if (representantes.length > 0) {
            if (variosRepresentantes) {
              representantes += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
            } else {
              representantes += '; extendido por el Registro Nacional de las Personas -RENAP-.';
            }
          }
          if (mandatarios.length > 0) {
            if (variosMandatarios) {
              mandatarios += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
            } else {
              mandatarios += '; extendido por el Registro Nacional de las Personas -RENAP-.';
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }
          if (dataPersona.length > 1) {
            propietarioNombre += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
          } else {
            propietarioNombre += '; extendido por el Registro Nacional de las Personas -RENAP-.';
          }
          var fincaDesc = '', ubicacion = '', municipioDesc = '', departamentoDesc = '', gtm = '';
          conteo = ttExento.fincas.length;
          for (i = 0; i < conteo; i++) {
            if (i == 0) {
              fincaDesc = ttExento.fincas[i].tcFinca.fincaDesc;
              ubicacion = ttExento.fincas[i].tcFinca.direccion;
              gtm = 'X=' + ttExento.fincas[i].tcFinca.gtmX + 'Y=' + ttExento.fincas[i].tcFinca.gtmY;
            } else {
              fincaDesc += ', ' + ttExento.fincas[i].tcFinca.fincaDesc;
              ubicacion += ', ' + ttExento.fincas[i].tcFinca.direccion;
              gtm += ', ' + 'X=' + ttExento.fincas[i].tcFinca.gtmX + 'Y=' + ttExento.fincas[i].tcFinca.gtmY;
            }
            municipioDesc = ttExento.fincas[i].tcFinca.tcMunicipio.municipioDesc;
            departamentoDesc = ttExento.fincas[i].tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            sinDpi = ttExento.ttJuridicaExento.razonSocial;
          }
          var paso = null;
          var aTraves = '';
          if (tarea.numero == 1) {
            aTraves = 'Por este medio le informo sobre la verificación del expediente ';
          } else {
            aTraves = 'Por este medio le informo sobre la verificación del expediente e inspección de campo ';
          }
          aTraves += 'en solicitud de autorización de Notas de envío número '
            + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio
            + ' realizada por ';
          var textoInicial = '';
          if (mandatarios.length <= 0) {
            if (representantes.length > 0) {
              textoInicial = sinDpi + ' representado legalmente por ' + representantes;
            } else {
              textoInicial = propietarioNombre;
            }
          } else {
            if (representantes.length > 0) {
              if (variosMandatarios) {
                textoInicial = sinDpi + ' representado legalmente por ' + representantesNombre + ' A través de los mandatarios ' + mandatarios;
              } else {
                textoInicial = sinDpi + ' representado legalmente por ' + representantesNombre + ' A través del mandatario ' + mandatarios;
              }
            } else {
              if (variosMandatarios) {
                textoInicial = sinDpi + ' a través de los mandatarios ' + mandatarios;
              } else {
                textoInicial = sinDpi + ' a través del mandatario ' + mandatarios;
              }
            }
          }
          aTraves += textoInicial;
          var tbVenta = {};
          var tmpData = ttExento.ventas;
          if (tmpData == null) {
            tmpData = [];
          }
          var body = [
            [
              { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Número de Árboles', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP (cm)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Troza', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Leña', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Total', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            total: 0,
            arbolesAutorizado: 0
          }
          var tmpData = ttVentaExento.rodales;
          var rodalesDesc = '', area = 0;
          for (i = 0; i < tmpData.length; i++) {
            var rodal = tmpData[i];
            if (i == 0) {
              rodalesDesc = rodal.correlativo;
            } else {
              rodalesDesc += ', ' + rodal.correlativo;
            }
            area += rodal.area;
            for (var j = 0; j < rodal.especies.length; j++) {
              totales.troza += parseFloat(rodal.especies[j].troza);
              totales.lenia += parseFloat(rodal.especies[j].lenia);
              totales.total += parseFloat(rodal.especies[j].total);
              totales.arbolesAutorizado += parseInt(rodal.especies[j].arbolesAutorizado);
              body.push(
                [
                  { text: rodal.correlativo, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].arbolesAutorizado, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].dap, style: 'detalle', alignment: 'left' },
                  { text: rodal.especies[j].altura, style: 'detalle', alignment: 'left' },
                  { text: parseFloat(rodal.especies[j].troza).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(rodal.especies[j].lenia).toFixed(2), style: 'detalle', alignment: 'right' },
                  { text: parseFloat(rodal.especies[j].total).toFixed(2), style: 'detalle', alignment: 'right' }
                ]
              );
            }
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 5 }, {}, {}, {}, {},
              { text: parseFloat(totales.troza).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.total).toFixed(2), style: 'detalle', alignment: 'right' }
            ]
          );
          tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [40, 150, 40, 40, 40, 40, 40, 40],
              body: body
            }
          }

          itemsDatos = [
            { text: "Registro No: " + ttExento.codigo, style: 'izquierda' },
            { text: "Categoría de registro: " + ttExento.tipoRegistroDesc, style: 'izquierda' },
            { text: "Titular: " + sinDpi, style: 'izquierda' }
          ];
          if (representantes.length > 0) {
            itemsDatos.push({ text: "Representante(s) Legal(es): " + representantesNombre, style: 'izquierda' });
          }
          if (mandatarios.length > 0) {
            itemsDatos.push({ text: "Mandatario(s): " + mandatariosNombre, style: 'izquierda' });
          }
          itemsDatos.push({ text: "Nombre de la finca: " + fincaDesc, style: 'izquierda' });
          itemsDatos.push({ text: "Ubicación de la finca: " + ubicacion, style: 'izquierda' });
          itemsDatos.push({ text: "Municipio: " + municipioDesc, style: 'izquierda' });
          itemsDatos.push({ text: "Departamento: " + departamentoDesc, style: 'izquierda' });
          itemsDatos.push({ text: "Área inscrita en el registro: " + ttExento.area, style: 'izquierda' });
          itemsDatos.push({ text: "Localización GTM: " + gtm, style: 'izquierda' });
          itemsDatos.push({ text: "Actividad Forestal: " + ttExento.tcCategoriaExento.categoriaExentoDesc, style: 'izquierda' });
          itemsDatos.push({ text: "Rodal (es) del aprovechamiento: " + rodalesDesc, style: 'izquierda' });
          itemsDatos.push();
          itemsDatos.push({ text: "Área del rodal (es) del aprovechamiento: " + parseFloat(area).toFixed(4), style: 'izquierda' });

          var datos = {
            ol: [
              {
                ol: itemsDatos
              }
            ]
          }

          var content = [
            {
              width: 420,
              text: "\n\nDICTAMEN TÉCNICO",
              style: 'centrar'
            }, '\n',
            {
              text: "Dictamen técnico número: " + tarea.codigo,
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaProvidencia.getDate() + " de " + getMonth(fechaProvidencia.getMonth() + 1) + " del año " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: tarea.tcPersonaTraslado.personaDesc + "\nDirector Subregional "
                + ttExento.tcSubregion.subregionDesc + "\nINAB, " + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n',
            {
              text: 'I. INFORMACIÓN GENERAL DEL EXENTO',
              style: 'izquierda', bold: true
            }, '\n', datos, '\n'
          ];
          content.push(
            {
              text: 'II. DICTAMEN TÉCNICO',
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'De acuerdo a la solicitud presentada y basado en el artículo número 53 de la Ley Forestal Decreto Legislativo 101-96 se dictamina lo siguiente:',
              style: 'izquierda'
            }
          );
          content.push('\n');
          var dataTmp = tarea.observaciones.razones;
          var lista = [];
          for (i = 0; i < dataTmp.length; i++) {
            lista.push({ text: dataTmp[i].descripcion, style: 'izquierda' });
          }
          if (tarea.aprobado == 1) {
            if (ttVentaExento.paraRevalidar == 1 || ttVentaExento.paraRevalidar == 2) {
              lista.push({ text: 'Se recomienda autorizar la revalidación de notas de envío para el transporte del producto forestal de acuerdo al cuadro siguiente:', style: 'izquierda' });
            } else {
              if (ttVentaExento.autorizadoNotas > 0) {
                lista.push({ text: 'Se recomienda autorizar la cantidad de ' + ttVentaExento.autorizadoNotas + ' notas de envío para el transporte del producto forestal de acuerdo al cuadro siguiente:', style: 'izquierda' });
              } else {
                lista.push({ text: 'En el siguiente cuadro se detalla el volumen por especie a transportar:', style: 'izquierda' });
              }
            }
          }
          content.push(
            {
              ol: lista
            }
          );                    
          if (tarea.aprobado == 1) {
            content.push('\n');
            content.push(tbVenta);
            if (ttVentaExento.rangosRevalidar != null) {
              if (ttVentaExento.rangosRevalidar.length > 0) {
                var rangosRevalidar = ttVentaExento.rangosRevalidar;
                var textoRevalidar = {
                  text: 'Notas de envió a revalidar: \n',
                  style: 'izquierda'
                }
                for (let i = 0; i < rangosRevalidar.length; i++) {
                  let rango = rangosRevalidar[i];
                  textoRevalidar.text += '\n\t' + (i + 1) + '. Del ' + rango.inicio + ' al ' + rango.fin;
                }
                textoRevalidar.text += '\n';
              }
            }
            content.push('\n');
            content.push(textoRevalidar);
          }
          var dataObservaciones = tarea.observaciones.observaciones;
          if (dataObservaciones != null) {
            var observaciones = [];
            if (dataObservaciones.length > 0) {
              for (i = 0; i < dataObservaciones.length; i++) {
                observaciones.push({ text: dataObservaciones[i].descripcion, style: 'izquierda' });
              }
              content.push('\n');
              content.push(
                {
                  text: 'III. Observaciones',
                  style: 'izquierda', bold: true
                }
              );
              content.push('\n');
              content.push(
                {
                  ol: observaciones
                }
              );              
            }
          }
          if (tarea.aprobado != 1) {
            content.push('\n');
            content.push(
              {
                text: 'Con base a la revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se recomienda DENEGAR  la venta de notas de envío de bosque exento de licencia forestal',
                style: 'izquierda'
              }
            );
          }
          content.push(
            '\nAtentamente,\n\n\n\n'
          );
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Técnico forestal, subregión " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionPagoVenta: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttVentaExento = ttTarea.ttVentaExento;
          var ttExento = ttTarea.ttExento;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var textoFinca = "";
          for (i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i > 0) {
              textoFinca = textoFinca + ", " + finca.tcFinca.fincaDesc + '. Área registrada ha.: ' + ttExento.area
                + ' Ubicación de finca: ' + finca.tcFinca.direccion + ' muncipio de ' + finca.tcFinca.tcMunicipio.municipioDesc
                + ' departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca = finca.tcFinca.fincaDesc + '. Área registrada ha.: ' + ttExento.area
                + ' Ubicación de finca: ' + finca.tcFinca.direccion + ' muncipio de ' + finca.tcFinca.tcMunicipio.municipioDesc
                + ' departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }
          var content = [
            {
              text: "Notificación de pago de Notas de Envío de Bosque Exentos de Licencia Forestal",
              style: 'centrar'
            }, '\n',
            {
              text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: 'Datos Generales de la solicitud',
              style: 'izquierda',
              bold: true
            },
            {
              text: "Número de Solicitud: " + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio,
              style: 'izquierda'
            },
            {
              text: "Solicitante: " + propietarioNombre,
              style: 'izquierda'
            },
            {
              text: "Código del RNF: " + ttExento.codigo,
              style: 'izquierda'
            },
            {
              text: "Nombre de la Finca: " + textoFinca,
              style: 'izquierda'
            }, '\n',
            {
              text: "NOTIFICACIÓN",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'Estimado (a): ' + propietarioNombre + '. A través del presente le notifico que de acuerdo a su solicitud número '
                + ttVentaExento.numero + '-' + ttVentaExento.anio + ', se aprobó la venta de ' + ttVentaExento.autorizadoNotas
                + ' Notas de Envío de Bosque Exentos de Licencia Forestal para el transporte de producto forestal. '
                + 'Previo a recoger las notas de envío autorizadas usted deberá cancelar al Fondo Forestal Privativo INAB, número de cuenta 3033330062 de Banrural, la cantidad de Q. '
                + formatNumber.new(parseFloat(ttVentaExento.pago).toFixed(2)),
              style: 'izquierda'
            }, '\n',
            {
              text: "El pago de las notas de envío podrá realizarse de la siguiente manera:",
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 20,
                  text: "",
                  style: 'derecha'
                },
                {
                  width: 10,
                  text: "*",
                  style: 'centrar'
                },
                {
                  width: 480,
                  text: "Cuando el monto a cancelar por el usuario no exceda QUINIENTOS QUETZALES EXACTOS (Q 500.00), el mismo podrá realizarse en efectivo en la oficina subregional correspondiente.",
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  width: 20,
                  text: "",
                  style: 'derecha'
                },
                {
                  width: 10,
                  text: "*",
                  style: 'centrar'
                },
                {
                  width: 480,
                  text: "Cuando el monto sea mayor a QUINIENTOS QUETZALES EXACTOS (Q 500.00) el usuario deberá hacer el pago, por medio de depósito monetario o transferencia bancaria; posteriormente deberá cargar la boleta de depósito o comprobante de transferencia bancaria al módulo de exentos.",
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          ];
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Nota',
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: 'Los usuarios que no hayan reportado los depósitos realizados al Fondo Forestal Privativo INAB en los 90 días calendario (3 meses), no podrán reclamar la devolución o tramite a la gestión que corresponda.',
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Comunicarse a oficina subregional correspondiente para programar la fecha y hora de entrega de resolución y notas de envío correspondientes.',
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarResolucionDenegacionVenta: function (data, diaDesc, anioDesc, strDiaAdmision, strAnioAdmision, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }

          var nombreSalida = 'resolucion.pdf';
          var fechaRecepcion = new Date(data.fechaRecepcion);
          var fechaAdmision = new Date(data.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          var mesAdmision = parseInt(fechaAdmision.getMonth()) + 1;
          var representante = "";
          var textoDocumento = "posesión";
          var i, j;
          var ttExento = data.ttExento;
          var ttVentaExento = data.ttVentaExento;
          var ttPadre = data.ttPadre;
          var textoDireccion = "";
          var considerando = '';
          for (var i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
            }
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = '', soloNombres = '', soloNombresRepresentante = '';
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc + ' quien se identifica con el documento personal de identificación DPI número '
                + persona.tcPersona.cui + ', extendido por el Registro Nacional de las Personas, República de Guatemala, Centro América';
              soloNombres = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc + ' quien se identifica con el documento personal de identificación DPI número '
                  + persona.tcPersona.cui + ', extendido por el Registro Nacional de las Personas, República de Guatemala, Centro América';
                soloNombres += ' y ' + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ". " + persona.tcPersona.personaDesc + ' quien se identifica con el documento personal de identificación DPI número '
                  + persona.tcPersona.cui + ', extendido por el Registro Nacional de las Personas, República de Guatemala, Centro América';
                soloNombres += ', ' + persona.tcPersona.personaDesc;
              }
            }
          }
          var representanteNombre = "";
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              persona = ttExento.representantes[i];
              if (i == 0) {
                representanteNombre = persona.tcPersona.personaDesc + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + persona.tcPersona.cui + ', extendido por el Registro Nacional de las Personas, República de Guatemala, Centro América';
                soloNombresRepresentante = persona.tcPersona.personaDesc;
              } else {
                representanteNombre = representanteNombre + ". " + persona.tcPersona.personaDesc
                  + " quien se identifica con Documento  Personal de Identificación –DPI- con Código Único de Identificación –CUI- "
                  + persona.tcPersona.cui + ', extendido por el Registro Nacional de las Personas, República de Guatemala, Centro América';
                soloNombresRepresentante += ', ' + persona.tcPersona.personaDesc;
              }
            }
          }
          if (ttExento.representantes.length > 0) {
            if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
              representante = "Se tiene a la vista para resolver la solicitud de venta de Notas de Envío de Bosque, Exentas de Licencia número "
                + ttVentaExento.numero + '-' + ttVentaExento.anio + " realizada por " + ttExento.ttJuridicaExento.razonSocial + ". Representado legalmente por " + representanteNombre;
              considerando = 'solicitada por ' + ttExento.ttJuridicaExento.razonSocial
                + '. Representado legalmente por ' + soloNombresRepresentante;
            } else {
              representante = "Se tiene a la vista para resolver la solicitud de venta de Notas de Envío de Bosque, Exentas de Licencia número "
                + ttVentaExento.numero + '-' + ttVentaExento.anio + " realizada por " + soloNombres + ". Representado legalmente por " + representanteNombre;
              considerando = 'solicitada por ' + soloNombres + '. Representado legalmente por ' + soloNombresRepresentante;
            }
          } else {
            representante = "Se tiene a la vista para resolver la solicitud de venta de Notas de Envío de Bosque, Exentas de Licencia número "
              + ttVentaExento.numero + '-' + ttVentaExento.anio + ' realizada por ' + propietarioNombre;
            considerando = 'solicitada por ' + soloNombres;
          }
          representante = representante + "; para el transporte del producto forestal proveniente del Registro número " + ttExento.codigo
            + ' en la finca denominada ' + textoDireccion;
          var razones = [];
          for (i = 0; i < data.observaciones.length; i++) {
            razones.push(
              { text: data.observaciones[i].descripcion, style: 'izquierda' }
            );
          }
          var detalle = [
            [
              {
                text: 'Denegar la solicitud de venta de Notas de Envío Exentas de Licencia número '
                  + ttVentaExento.numero + '-' + ttVentaExento.anio + ' ' + considerando + ', por las razones siguientes:', style: 'izquierda'
              },
              {
                ol: razones
              }
            ],
            { text: 'Notifíquese', style: 'izquierda' }
          ];
          var fechaTecnico = new Date(ttPadre.fechaFinalizacion);
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN DE DENEGATORIA DE SOLICITUD DE VENTA DE NOTAS DE ENVIO",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución número: " + data.codigo,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc
                + ", CON SEDE EN EL MUNICIPIO DE " + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", "
                + strDiaAdmision + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnioAdmision,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que el artículo quinto del Decreto Legislativo No. 101-96 Ley Forestal, establece que el Instituto Nacional de Bosques –INAB-, es el órgano de dirección y autoridad del Sector Publico Agrícola en materia forestal, estableciendo en el Artículo cincuenta y tres las actividades Exentas de Licencia Forestal.",
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'El informe técnico número ' + ttPadre.codigo + ' de fecha ' + diaDesc.toLowerCase()
                + ' de ' + getMonth(fechaTecnico.getMonth() + 1) + ' del ' + anioDesc.toLowerCase()
                + ', del Técnico Forestal ' + ttPadre.tcPersonaAsignada.personaDesc + ', quien después de realizar la evaluación y análisis correspondiente, dictaminó DENEGAR la solicitud de venta de Notas de Envío Exentas de Licencia número '
                + ttVentaExento.numero + '-' + ttVentaExento.anio + ' ' + considerando,
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: "Con base a lo considerado y en lo preceptuado por los artículos 28, 30, 125 y 126 de la Constitución Política de la República de Guatemala, en los artículos 1, 2, 3, 4, 5 y 6 de la Ley de lo Contencioso Administrativo; en los artículos 1, 2, 3, 4, 5, 6, 9, 16, 53, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto Legislativo 101-96 Ley Forestal; en los artículos 1, 40, 41, 47, 48, 51, 52, 55 y 56 del Reglamento de la Ley Forestal, en los artículos 1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 13, 14, 16, 17, 22, del Reglamento para el transporte de Productos Forestales y su procedencia lícita.",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              ol: detalle
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttExento.tcSubregion.tcSubregional.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = ""//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                bold: true,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacionVenta: function (data, usuarioDesc, dataEmpresa, esLicencia) {
          var nombreSalida = 'notificacion.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var strCorreo = '';
          var ttExento = data.ttExento;
          for (i = 0; i < ttExento.propietarias.length; i++) {
            item = ttExento.propietarias[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
              strCorreo = item.tcPersona.correo;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
              strCorreo += "; " + item.tcPersona.correo;
            }
          }
          for (i = 0; i < ttExento.notificaciones.length; i++) {
            var notificacion = ttExento.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              strCorreo += "; " + notificacion.notificacionGestionDesc;
            }
          }
          for (i = 0; i < ttExento.representantes.length; i++) {
            item = ttExento.representantes[i];
            strCorreo += "; " + item.tcPersona.correo;
          }
          var item = {
            text: "\n\nMódulo Electrónico para la Autorización de Notas de Envío Exentas de Licencia",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "AVISO" + data.anio + '-' + data.anio,
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          item = {
            text: "En el municipio de " + data.ttGestion.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + data.ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", del día ______/______/______ siendo las __________ horas,  constituidos en las oficinas de la Dirección Subregional "
              + data.ttGestion.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la resolución número " + data.ttSeguimientoTarea.numeroResolucion
              + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          if (esLicencia) {
            content.push(
              {
                text: '\n\nImpreso por: ' + usuarioDesc,
                style: 'small', alignment: 'right'
              }
            );
            content.push(
              {
                text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
                style: 'small', alignment: 'right'
              }
            );
            content.push('\n');
            if (data.ttSeguimientoTarea.fechaProvidencia == null) {
              data.ttSeguimientoTarea.fechaProvidencia = new Date();
            }
            fecha = new Date(data.ttSeguimientoTarea.fechaProvidencia);
            mes = parseInt(fecha.getMonth()) + 1;
            content.push(
              {
                text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
                style: 'small', alignment: 'left'
              }
            );
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarResolucionVenta: function (data, diaDesc, anioDesc, strDiaAdmision, strAnioAdmision, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var nombreSalida = 'resolucion.pdf';
          var fechaRecepcion = new Date(data.fechaRecepcion);
          var fechaFinalizacion = new Date(data.fechaFinalizacion);
          var fecha = new Date();
          var mesFinalizacion = parseInt(fechaFinalizacion.getMonth()) + 1;
          var representante = "";
          var textoDocumento = "posesión";
          var i, j;
          var ttExento = data.ttExento;
          var ttVentaExento = data.ttVentaExento;
          var ttPadre = data.ttPadre;
          var textoDireccion = "", textoFinca = '';
          var considerando = '';
          for (var i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
              textoFinca = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
              textoFinca = textoFinca + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", jurisdicción del municipio de "
                + finca.tcFinca.tcMunicipio.municipioDesc + " del departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc
                + ' localizada en las coordenadas GTM X=' + finca.tcFinca.gtmX + ', Y=' + finca.tcFinca.gtmY;
            }
            textoDireccion += ', acreditado mediante: ' + finca.tcTipoPropiedad.tipoPropiedadDesc;
            var documentos = finca.documentos;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                textoDireccion = textoDireccion + " de derechos posesorios número " + finca.numeroDocumento
                  + ", autorizada en el municipio "
                  + finca.tcMunicipioEmite.municipioDesc + " del departamento de " + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc
                  + ", por el notario " + finca.notario;
                break;
              case 2:
                textoDireccion = textoDireccion + " de derechos posesorios de bien inmueble, autorizada en el municipio de " 
                + finca.tcMunicipioEmite.municipioDesc + " del departamento de "
                  + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ", por el Notario " + finca.notario;
                break;
              case 3:
                if (documentos.length > 0) {
                  var strDocumentos = '';
                  for (j = 0; j < documentos.length; j++) {
                    var documentoFinca = documentos[j];
                    strDocumentos = strDocumentos + " y número de finca "
                    + documentoFinca.numeroDocumento + ", folio " + documentoFinca.folio + " libro, " + documentoFinca.libro + " de " + documentoFinca.tcLibro.libroDesc;
                  }
                  textoDireccion = textoDireccion + ", inscrita con número de finca "
                    + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc + strDocumentos;
                } else {
                  textoDireccion = textoDireccion + ", inscrita con número de finca "
                    + finca.numeroDocumento + ", folio " + finca.folio + ", libro " + finca.libro + " de " + finca.tcLibro.libroDesc;
                }
                break;
              case 4:
                textoDireccion = textoDireccion + ", la cual proviene del programa de incentivos para poseedores de pequeñas extensiones de tierras de vocación forestal o agroforestal";
                break;
              default:
                textoDireccion = textoDireccion + " de derechos posesorios de bien inmueble, autorizada en el municipio Guatemala, departamento de Guatemala, por el Notario " + finca.notario;
                break;
            }
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = '', soloNombres = '', soloNombresRepresentante = '';
          var i = 0;
          var dataPersona = ttExento.propietarios;
          var variosPropietarios = false;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc + ' quien se identifica con el Documento Personal de Identificación -DPI- y Código Único de Identificación '
                + persona.tcPersona.cui;
              soloNombres = persona.tcPersona.personaDesc;
            } else {
              variosPropietarios = true;
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc + ' quien se identifica con el Documento Personal de Identificación -DPI- y Código Único de Identificación '
                  + persona.tcPersona.cui;
                soloNombres += ' y ' + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc + ' quien se identifica con el Documento Personal de Identificación -DPI- y Código Único de Identificación '
                  + persona.tcPersona.cui;
                soloNombres += ', ' + persona.tcPersona.personaDesc;
              }
            }
          }
          var aTraves = '';
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;          
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              persona = ttExento.representantes[i];
              if (persona.mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = mandatariosNombre + ", " + persona.tcPersona.personaDesc;
                  variosMandatarios = true;
                } else {
                  mandatarios = persona.tcPersona.personaDesc + ' quien se identifica con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = persona.tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = representantesNombre + ", " + persona.tcPersona.personaDesc;
                } else {
                  representantes = persona.tcPersona.personaDesc + ' quien se identifica con Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = persona.tcPersona.personaDesc;
                }
              }
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 1) {
            if (dataPersona.length > 1) {
              propietarioNombre += '; todos extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              propietarioNombre += '; extendido por el Registro Nacional de las Personas -RENAP-';
            }
          } else {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }
          if (representantes.length > 0) {
            if (variosRepresentantes) {
              representantes + ' extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              representantes + ' extendido por el Registro Nacional de las Personas -RENAP-';
            }
          }
          if (mandatarios.length > 0) {
            if (variosMandatarios) {
              mandatarios + ' extendidos por el Registro Nacional de las Personas -RENAP-';
            } else {
              mandatarios + ' extendido por el Registro Nacional de las Personas -RENAP-';
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }          
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            variosPropietarios = false;
            soloNombres = ttExento.ttJuridicaExento.razonSocial;
          }
          if (mandatarios.length > 0) {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = soloNombres + ', representados legalmente por ' + representantes + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios;
              } else {
                aTraves = soloNombres + ', representado legalmente por ' + representantes + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios;
              }
            } else {
              aTraves = soloNombres + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios;
            }
          } else {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = soloNombres + ', representados legalmente por ' + representantes;
              } else {
                aTraves = soloNombres + ', representado legalmente por ' + representantes;
              }
            } else {
              aTraves = soloNombres;
            }
          }
          representante = "Se tiene a la vista resolver la solicitud de venta de Notas de Envío de Bosque Exentas de Licencia número "
            + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio 
            + " presentada por " + aTraves;
          representante = representante + "; para el transporte del producto forestal proveniente de la actividad forestal Exenta de Licencia: "
            + ttExento.tcCategoriaExento.categoriaExentoDesc + ", con inscripción en el Registro Nacional Forestal con el número "
            + ttExento.codigo + ' en la finca denominada ' + textoDireccion;
          var fechaTecnico = new Date(ttPadre.fechaFinalizacion);
          var body = [
            [
              { text: 'Rodal', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Área', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Nombre común', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Especie', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'No. Árboles', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Troza (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Leña (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Volumen Total (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            total: 0,
            arbolesAutorizado: 0
          }
          var tmpData = ttVentaExento.rodales;
          var rodales = ttExento.rodales;
          for (i = 0; i < tmpData.length; i++) {
            for (j = 0; j < rodales.length; j++) {
              if (rodales[j].correlativo == tmpData[i].correlativo) {
                tmpData[i].area = rodales[j].area;
                break;
              }
            }
          }
          var area = 0;
          var listaEspecie = [];
          var listaSinonimo = [];
          for (i = 0; i < tmpData.length; i++) {
            var rodal = tmpData[i];
            area += rodal.area;
            var bodyDetalle = [];
            for (var j = 0; j < rodal.especies.length; j++) {
              totales.troza += parseFloat(rodal.especies[j].troza);
              totales.lenia += parseFloat(rodal.especies[j].lenia);
              totales.total += parseFloat(rodal.especies[j].total);
              totales.arbolesAutorizado += parseInt(rodal.especies[j].arbolesAutorizado);
              bodyDetalle.push(
                [
                  { text: rodal.especies[j].tcEspecie.especieDesc, style: 'detalleIzquierda' },
                  { text: rodal.especies[j].tcEspecie.nombreCientifico, style: 'detalleIzquierda' },
                  { text: rodal.especies[j].arbolesAutorizado, style: 'detalleDerecha' },
                  { text: parseFloat(rodal.especies[j].troza).toFixed(2), style: 'detalleDerecha' },
                  { text: parseFloat(rodal.especies[j].lenia).toFixed(2), style: 'detalleDerecha' },
                  { text: parseFloat(rodal.especies[j].total).toFixed(2), style: 'detalleDerecha' }
                ]
              );
              var especieEncontrada = false;
              for (var k = 0; k < listaEspecie.length; k++) {
                if (rodal.especies[j].tcEspecie.especieId == listaEspecie[k].especieId) {
                  especieEncontrada = true;
                  break;
                }
              }
              if (!especieEncontrada) {
                listaEspecie.push(rodal.especies[j].tcEspecie);
              }
            }
            if (bodyDetalle.length > 0) {
              tbVentaDetalle = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [100, 100, 40, 40, 40, 45],
                  body: bodyDetalle
                }, colSpan: 6, layout: 'noBorders'
              }
              body.push(
                [
                  { text: rodal.correlativo, style: 'detalleIzquierda' },
                  { text: rodal.area, style: 'detalleIzquierda' },
                  tbVentaDetalle, {}, {}, {}, {}, {}
                ]
              );
            }
          }
          for (var i = 0; i < listaEspecie.length; i++) {
            var itemSinonimo = {
              text: [
                {
                  text: listaEspecie[i].nombreCientifico + ': ',
                  bold: true
                },
                listaEspecie[i].sinonimo
              ]
            }
            listaSinonimo.push(itemSinonimo);
          }
          var objetoSinonimo = {
            stack: [
              {
                type: 'upper-roman',
                ol: listaSinonimo
              }
            ],
            alignment: 'justify',
            fontSize: 9
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalleIzquierda', fillColor: '#CCCCCC', colSpan: 4 }, {}, {}, {},
              { text: totales.arbolesAutorizado, style: 'detalleDerecha' },
              { text: parseFloat(totales.troza).toFixed(2), style: 'detalleDerecha' },
              { text: parseFloat(totales.lenia).toFixed(2), style: 'detalleDerecha' },
              { text: parseFloat(totales.total).toFixed(2), style: 'detalleDerecha' }
            ]
          );
          tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 30, 100, 100, 40, 40, 40, 45],
              body: body
            }
          }
          var revalidadas = '';
          var body = [
            [
              { text: 'Control de notas de envío nuevas', style: 'centrar', bold: true, fillColor: '#CCCCCC', colSpan: 5 }, {}, {}, {}, {}
            ],
            [
              { text: 'Fecha', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Cantidad', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Del', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Al', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Factura', style: 'centrar', fillColor: '#CCCCCC' }
            ]
          ];
          var itemRevalidada = {};
          for (var i = 0; i < ttVentaExento.rangos.length; i++) {
            var rango = ttVentaExento.rangos[i];
            if (rango.esRevalidacion == 0) {
              body.push(
                [
                  { text: $filter('date')(fechaFinalizacion, 'dd/MM/yyyy'), style: 'centrar' },
                  { text: rango.cantidad, style: 'centrar' },
                  { text: rango.inicio, style: 'centrar' },
                  { text: rango.fin, style: 'centrar' },
                  { text: ttVentaExento.serieFactura + ttVentaExento.noFactura, style: 'centrar' }
                ]
              );
            } else {
              if (revalidadas.length > 0) {
                revalidadas += ', del ' + rango.inicio + ' al ' + rango.fin;
              } else {
                revalidadas = ' del ' + rango.inicio + ' al ' + rango.fin;
              }
            }
          }
          if (revalidadas.length > 0) {
            itemRevalidada = {
              columns: [
                {
                  width: 30,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'La presente resolución da validez también a las siguientes notas de envío: ' + revalidadas + ', autorizadas mediante resolución No. ' + data.observaciones.resolucionRevalidacion,
                  style: 'izquierda'
                }
              ]
            }
          }
          var tbNota = {
            style: 'estiloTabla',
            table: {
              headerRows: 2,
              widths: [85, 85, 90, 90, 115],
              body: body
            }
          }
          var enterObservaciones = {};
          var tbObservaciones = {};
          if (data.observaciones != null) {
            if (data.observaciones.observaciones != null && data.observaciones.observaciones.length > 0) {
              enterObservaciones = '\n';
              body = [
                [
                  { text: 'Observaciones\n', style: 'detalle', bold: true, colSpan: 2 }, {},
                ]
              ];
              for (let i = 0; i < data.observaciones.observaciones.length; i++) {
                var row = data.observaciones.observaciones[i];
                body.push(
                  [
                    { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                    { text: row.descripcion, style: 'detalle', alignment: 'left' }
                  ]
                )
              }
              tbObservaciones = {
                style: 'estiloTabla',
                table: {
                  headerRows: 1,
                  widths: [15, 485],
                  body: body
                }, layout: 'noBorders'
              }
            }
          }
          if (ttVentaExento.autorizadoNotas <= 0) {
            tbNota = {};
          }
          var fechaVencimiento = new Date(ttExento.fechaVencimiento);
          var textoAprobar = 'Aprobar la venta de ' + ttVentaExento.autorizadoNotas;
          if (ttVentaExento.paraRevalidar == 1) {
            textoAprobar = 'Aprobar la revalidación de ';
            tbNota = {};
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN DE VENTA DE NOTAS DE ENVIO DE BOSQUE DE EXENTO DE LICENCIA FORESTAL " + ttExento.codigo,
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución número: " + data.codigo,
              style: 'derecha',
              bold: true
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc
                + ", CON SEDE EN EL MUNICIPIO DE " + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", "
                + strDiaAdmision + " DIAS DEL MES DE " + getMonth(mesFinalizacion).toUpperCase() + " DE " + strAnioAdmision,
              style: 'izquierda'
            }, '\n',
            {
              text: representante,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'Que el Decreto del Congreso de la República de Guatemala número 101-96 Ley Forestal, en el artículo número cincuenta y tres establece: están Exentos de Licencia de Aprovechamiento Forestal... su literal a) El descombre, poda, tala y raleo en el cultivo de café, cardamomo, cacao y otros cultivos agrícolas similares.; b) La tala, poda y raleo de plantaciones voluntarias registradas en el INAB; c) La tala y raleo de plantaciones de árboles frutales; d) La poda y raleo de plantaciones obligatorias; y, e) La poda y raleo de sistemas agroforestales, y de conformidad con el artículo cinco de la Resolución de Junta Directiva JD.03.05.2020, reglamento para el transporte de productos forestales, estipula, “La función de la Nota de Envío es respaldar el transporte de los productos forestales, fuera de la finca, área de manejo, área de aprovechamiento o lugar de transformación”, siendo obligatorio exhibirla ante la autoridad competente que la requiera. Cada nota de envío, se utilizará para transportar por una sola vez el producto forestal indicado en la misma. Su reutilización queda expresamente prohibida.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'El dictamen técnico número ' + ttPadre.codigo + ' de fecha ' + diaDesc.toLowerCase()
                + ' de ' + getMonth(fechaTecnico.getMonth() + 1) + ' del ' + anioDesc.toLowerCase()
                + ', del Técnico Forestal ' + ttPadre.tcPersonaAsignada.personaDesc + ', quien después de realizar la evaluación y análisis correspondiente, dictaminó favorablemente la solicitud de venta de Notas de Envío Exentas de Licencia Forestal número '
                + ttExento.tcSubregion.codigo + '-' + ttVentaExento.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + ttVentaExento.anio
                + ', para el transporte del producto forestal proveniente de la actividad Exenta de Licencia: '
                + ttVentaExento.tcActividadForestal.actividadForestalDesc + ', con código de Registro ' + ttExento.codigo + '.',
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: "Con base a lo considerado y en lo preceptuado por los artículos 28, 30, 125 y 126 de la Constitución Política de la República de Guatemala, en los artículos 1, 2, 3, 4, 5 y 6 de la Ley de lo Contencioso Administrativo; en los artículos 1, 2, 3, 4, 5, 6, 9, 16, 53, 55, 56, 58, 67, 68, 70, 87, 88 y 97 del Decreto Legislativo 101-96 Ley Forestal; en los artículos 1,2,3,4,6 y 8 Decreto Legislativo 122-96 Ley Reguladora del Registro, Autorización y Uso de Motosierras; en los artículos 1, 40, 41, 47, 48, 51, 52, 55 y 56 del Reglamento de la Ley Forestal, en los artículos 1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 13, 14, 16, 17, 20, 22, del Reglamento para el transporte de Productos Forestales y su procedencia lícita.",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '1)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: textoAprobar + ' notas de envío de bosque de exento de licencia forestal a '
                    + soloNombres + ', para el transporte del producto forestal proveniente del ' + ttExento.tcCategoriaExento.categoriaExentoDesc
                    + ' con código del Registro ' + ttExento.codigo + ', ubicado en la finca denominada ' + textoFinca,
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '2)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'Las notas de envío autorizadas, deberán ser utilizadas para el transporte de las siguientes especies forestales:',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            tbVenta,
            {
              text: "A conveniencia del titular del aprovechamiento el producto consignado como troza puede convertirse y transportarse en madera aserrada, block o flitch, poste o trocilla, vigas, varillas. "
                + "Lo consignado como leña puede convertirse y trasportarse como carbón o chep. Para el control de notas de envío el INAB aplicará los descuentos correspondientes por pérdidas en la transformación del producto original, según el  Acuerdo de Gerencia vigente.",
              alignment: 'justify',
              fontSize: 9
            }, '\n',
            {
              text: 'Las especies autorizadas en la presente resolución pueden ser reconocidas con los siguientes sinónimos:',
              alignment: 'justify',
              fontSize: 9
            }, '\n', objetoSinonimo, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '3)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'La correcta utilización o manejo que se le proporcione tanto a la resolución como a las notas de envío autorizadas, quedará única y exclusivamente bajo la responsabilidad del titular.',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '4)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'El titular de esta resolución es el responsable de presentar informes de uso de notas de envío previo a realizar una nueva solicitud de notas de envío, informes trimestrales e informe final, según su obligatoriedad, de acuerdo a lo contemplado en el artículo 18 del reglamento de transporte de productos forestales. Cuando no exista utilización de notas de envío en el trimestre, el titular podrá informar en el sistema de exentos, que no hubo movimiento. El informe final deberá presentarlo dentro de los quince días siguientes a la finalización del aprovechamiento.',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '5)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'La presente resolución estará vigente desde la fecha de su notificación hasta ' + fechaVencimiento.getDate() + ' de ' + getMonth(fechaVencimiento.getMonth() + 1) + ' de ' + fechaVencimiento.getFullYear(),
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  width: 30,
                  text: '6)',
                  style: 'izquierda'
                },
                {
                  width: '*',
                  text: 'Notifíquese la presente resolución.',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            tbNota, '\n', itemRevalidada, enterObservaciones, tbObservaciones, '\n\n\n\n',
            {
              text: sigla + " " + data.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            }, '\n',
            {
              qr: appSettings.urlBase + 'validatex/' + cryptText(appSettings.seed, ttVentaExento.ventaExentoId),
              fit: '100',
              style: 'small',
              alignment: 'right'
            }, '\n',
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                alignment: 'center'
              },
              centrarTabla: {
                fontSize: 9,
                alignment: 'center',
                bold: true
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              detalleIzquierda: {
                fontSize: 8.5,
                alignment: 'justify'
              },
              detalleDerecha: {
                fontSize: 8.5,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15],
                fontSize: 9
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaResolucionVenta: function (data, usuario, dataEmpresa) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var ttExento = data.ttExento;
          var ttVentaExento = data.ttVentaExento;
          var strCorreo = ttExento.tcPersonaCrea.correo;
          var fechaFinalizacion = new Date(data.fechaFinalizacion);
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 1) {
            for (i = 0; i < ttExento.propietarios.length; i++) {
              item = ttExento.propietarios[i];
              if (i == 0) {
                solicitante = item.tcPersona.personaDesc;
              } else {
                solicitante += ", " + item.tcPersona.personaDesc;
              }
              strCorreo += "; " + item.tcPersona.correo;
            }
          } else {
            solicitante = ttExento.ttJuridicaExento.razonSocial;
          }
          for (i = 0; i < ttExento.notificaciones.length; i++) {
            var notificacion = ttExento.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              strCorreo += "; " + notificacion.notificacionExentoDesc;
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "CÉDULA DE NOTIFICACIÓN",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n');
          item = {
            text: "En el municipio de " + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", del departamento de " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc
              + ", del día ______/______/______ siendo las __________ horas,  constituidos en las Oficinas de la Dirección Subregional "
              + ttExento.tcSubregion.subregionDesc + ", NOTIFICO A: " + solicitante + ", el contenido íntegro de la resolución número " + ttVentaExento.resolucion
              + " de fecha " + $filter('date')(fechaFinalizacion, 'dd/MM/yyyy') + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\nSecretaria(o) I Subregional',
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          content.push(
            {
              text: '\n\nImpreso por: ' + usuario,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push('\n');
          fecha = fechaFinalizacion;
          mes = parseInt(fecha.getMonth()) + 1;
          content.push(
            {
              text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
              style: 'small', alignment: 'left'
            }
          );
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeExento: async function (ttInformeVenta, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'informe.pdf';
          if (ttInformeVenta.estadoTramiteId < 0) {
            toastr.error('Debe guardar los datos de la pestaña "Volumen" y "Conclusiones"');
            return;
          }
          var fecha = new Date();
          var fechaElaboracion = new Date();
          if (ttInformeVenta.fechaElaboracion != null) {
            fechaElaboracion = new Date(ttInformeVenta.fechaElaboracion);
          }
          var mesElaboracion = parseInt(fechaElaboracion.getMonth()) + 1;
          var body = [];
          var i, j;
          var personas = ttInformeVenta.ttVentaExento.ttExento.propietarios;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = ttInformeVenta.ttVentaExento.ttExento.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          body = [
            [
              { text: 'Código de RNF: ' + ttInformeVenta.ttVentaExento.ttExento.codigo, style: 'izquierda' },
              { text: 'Número de resolución: ' + ttInformeVenta.ttVentaExento.resolucion, style: 'izquierda' },
              { text: 'Área registrada: ' + ttInformeVenta.ttVentaExento.ttExento.area, style: 'izquierda' }
            ]
          ];
          var fincas = ttInformeVenta.ttVentaExento.ttExento.fincas;
          for (var i = 0; i < fincas.length; i++) {
            finca = fincas[i];
            body.push(
              [
                { text: '\nNombre finca: ' + finca.tcFinca.fincaDesc, style: 'izquierda' },
                { text: '\nUbicación: ' + finca.tcFinca.direccion, style: 'izquierda', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Departamento: ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda' },
                { text: 'Municipio: ' + finca.tcFinca.tcMunicipio.municipioDesc, style: 'izquierda' },
                { text: 'GTM X: ' + finca.tcFinca.gtmX + ', GTM Y: ' + finca.tcFinca.gtmY, style: 'izquierda' }
              ]
            );
          }
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [160, 160, 160],
              body: body
            }, layout: 'noBorders'
          }
          body = [];
          var conclusiones = ttInformeVenta.conclusiones;
          for (var i = 0; i < conclusiones.length; i++) {
            var row = conclusiones[i];
            body.push(
              [
                { text: '', style: 'izquierda' },
                { text: i + 1, style: 'izquierda' },
                { text: row.conclusion, style: 'izquierda' }
              ]
            );
          }
          var tbConclusion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [10, 20, 450],
              body: body
            }, layout: 'noBorders'
          }

          var tipoSierra = null;
          switch (ttInformeVenta.tipoSierraId) {
            case 1:
              tipoSierra = "Banda";
              break;
            case 2:
              tipoSierra = "Circular";
              ttInformeVenta.otraSierraId = null;
              break;
            case 3:
              tipoSierra = "Motosierra";
              ttInformeVenta.otraSierraId = null;
              break;
          }

          var tipoSierraSecu = null;
          if (ttInformeVenta.otraSierraId == 1) {
            tipoSierraSecu = "Con sierra Secundaria";
          }
          else if (ttInformeVenta.tipoSierraId == 2) {
            tipoSierraSecu = "Sin Sierra Secundaria";
          }

          var tbConclusion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [10, 20, 450],
              body: body
            }, layout: 'noBorders'
          }
          //tabla volumen
          body = [
            [
              { text: 'Número', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Fecha uso', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Destino', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Producto', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Especie', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Volumen transportado (m³)', style: 'centrar', fillColor: '#CCCCCC' },
              { text: 'Volumen total (m³)', style: 'centrar', fillColor: '#CCCCCC' }
            ]
          ];
          bodyAnulado = [
            [
              { text: 'No. nota de envío', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Motivo del extravío', style: 'izquierda', fillColor: '#CCCCCC' }
            ]
          ];
          bodyExtravio = [
            [
              { text: 'No. nota de envío', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Motivo del extravío', style: 'izquierda', fillColor: '#CCCCCC' }
            ]
          ];
          var notas = ttInformeVenta.notas;
          var lista = [];
          var anulados = 0, extraviados = 0;
          for (var i = 0; i < notas.length; i++) {
            var nota = notas[i];
            if (nota.usoId == 0) {
              bodyAnulado.push(
                [
                  { text: nota.numero, style: 'izquierda' },
                  { text: nota.motivo, style: 'izquierda' }
                ]
              );
              anulados++;
            }
            if (nota.usoId == 2) {
              bodyExtravio.push(
                [
                  { text: nota.numero, style: 'izquierda' },
                  { text: nota.motivo, style: 'izquierda' }
                ]
              );
              extraviados++;
            }
            var parts = ['aaaa', 'mm', 'dd'];
            if (nota.fechaNota != null) {
              var strFechaNota = nota.fechaNota.toString();
              parts = strFechaNota.split('-');
              if (parts.length > 3) {
                nota.fechaNota = $filter('date')(new Date(nota.fechaNota), 'yyyy-MM-dd');
              }
              parts = nota.fechaNota.toString().split('-');
            }
            var especies = notas[i].especies;
            var bodyDetalle = [];
            if (especies != null) {
              for (var j = 0; j < especies.length; j++) {
                var especie = especies[j];
                if (nota.usoId == 1) {
                  bodyDetalle.push(
                    [
                      { text: especie.tcProductoTransporte.productoTransporteDesc, style: 'izquierda' },
                      { text: especie.tcEspecie.nombreCientifico, style: 'izquierda' },
                      { text: parseFloat(especie.volumen).toFixed(2), style: 'derecha' },
                      { text: parseFloat(especie.volumenReportar).toFixed(2), style: 'derecha' }
                    ]
                  );
                }
                var encontrado = false;
                for (var k = 0; k < lista.length; k++) {
                  if (especie.tcEspecie.especieId == lista[k].tcEspecie.especieId) {
                    encontrado = true;
                    var item = lista[k];
                    break;
                  }
                }
                if (!encontrado) {
                  var item = {
                    tcEspecie: especie.tcEspecie,
                    volumen: parseFloat(especie.volumenReportar),
                    troza: 0,
                    lenia: 0
                  };
                }
                if (especie.tcProductoTransporte.productoTransporteId == 1 || especie.tcProductoTransporte.productoTransporteId == 2) {
                  item.troza += parseFloat(especie.volumenReportar);
                } else {
                  if (especie.tcProductoTransporte.productoTransporteId == 3 || especie.tcProductoTransporte.productoTransporteId == 4) {
                    item.lenia += parseFloat(especie.volumenReportar);
                  }
                }
                if (!encontrado) {
                  lista.push(item);
                }
              }
              if (especies.length > 0) {
                tbDetalle = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 0,
                    widths: [60, 75, 50, 50],
                    body: bodyDetalle
                  }, layout: 'noBorders', colSpan: 4
                }
                body.push(
                  [
                    { text: nota.numero, style: 'izquierda' },
                    { text: parts[2] + '/' + parts[1] + '/' + parts[0], style: 'izquierda' },
                    { text: nota.destino + '\n' + nota.tcMunicipio.municipioDesc + ', ' + nota.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda' },
                    tbDetalle, {}, {}
                  ]
                );
              }
            }
          }
          tbUsado = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [50, 50, 105, 65, 80, 45, 50],
              body: body
            }
          }
          tbAnulado = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [100, 380],
              body: bodyAnulado
            }
          }
          tbExtravio = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [100, 380],
              body: bodyExtravio
            }
          }
          body = [
            [
              { text: 'Especie', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'derecha', fillColor: '#CCCCCC', alignment: 'right' },
              { text: 'Leña (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: 'Troza utilizada en finca (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: 'Leña utilizada en finca (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' },
              { text: 'Total (m³)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'right' }
            ]
          ];
          var totales = {
            lenia: 0,
            troza: 0,
            trozaFinca: 0,
            leniaFinca: 0,
            volumen: 0
          };
          lista = ttInformeVenta.especies;
          if (lista == null) {
            lista = [];
          }
          for (var i = 0; i < lista.length; i++) {
            lista[i].lenia = parseFloat(lista[i].lenia).toFixed(2);
            lista[i].troza = parseFloat(lista[i].troza).toFixed(2);
            lista[i].trozaFinca = parseFloat(lista[i].trozaFinca).toFixed(2);
            lista[i].leniaFinca = parseFloat(lista[i].leniaFinca).toFixed(2);
            lista[i].volumen = parseFloat(lista[i].volumen).toFixed(2);
            body.push(
              [
                { text: lista[i].tcEspecie.nombreCientifico, style: 'izquierda' },
                { text: lista[i].troza, style: 'derecha' },
                { text: lista[i].lenia, style: 'derecha' },
                { text: lista[i].trozaFinca, style: 'derecha' },
                { text: lista[i].leniaFinca, style: 'derecha' },
                { text: lista[i].volumen, style: 'derecha' }
              ]
            );
            totales.lenia += parseFloat(lista[i].lenia);
            totales.troza += parseFloat(lista[i].troza);
            totales.trozaFinca += parseFloat(lista[i].trozaFinca);
            totales.leniaFinca += parseFloat(lista[i].leniaFinca);
            totales.volumen += parseFloat(lista[i].volumen);
          }
          totales.lenia = parseFloat(totales.lenia).toFixed(2);
          totales.troza = parseFloat(totales.troza).toFixed(2);
          totales.leniaFinca = parseFloat(totales.leniaFinca).toFixed(2);
          totales.trozaFinca = parseFloat(totales.trozaFinca).toFixed(2);
          totales.volumen = parseFloat(totales.volumen).toFixed(2);
          
          body.push(
            [
              { text: 'TOTAL', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: totales.troza, style: 'derecha', fillColor: '#CCCCCC' },
              { text: totales.lenia, style: 'derecha', fillColor: '#CCCCCC' },
              { text: totales.trozaFinca, style: 'derecha', fillColor: '#CCCCCC' },
              { text: totales.leniaFinca, style: 'derecha', fillColor: '#CCCCCC' },
              { text: totales.volumen, style: 'derecha', fillColor: '#CCCCCC' }
            ]
          );
          var tablaVolumen = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [130, 65, 65, 65, 65, 65],
              body: body
            }
          }
          var n = '3';
          if (ttInformeVenta.tieneMovimiento == 1) {
            n = '4';
          }
          var informe = 'trimestral';
          switch (ttInformeVenta.esFinal) {
            case 1: informe = 'final'; break;
            case 2: informe = 'avances'; break;
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\nInforme de uso de Notas de Envío de Bosque Exentos de Licencia Forestal",
                  style: 'centrar',
                  bold: true,
                  margin: [20, 0, 10, 0]
                }
              ]
            }, '\n',
            {
              text: 'Número de informe: ' + ttInformeVenta.correlativo + '-' + ttInformeVenta.ttVentaExento.ttExento.codigo,
              style: 'derecha', bold: true
            },
            {
              text: 'Fecha: ' + fechaElaboracion.getDate() + ' de ' + getMonth(mesElaboracion) + ' de ' + fechaElaboracion.getFullYear(),
              style: 'derecha', bold: true
            }, '\n\n',
            {
              text: 'Señor Director Subregional de la subregión ' + ttInformeVenta.ttVentaExento.ttExento.tcSubregion.subregionDesc,
              style: 'izquierda'
            }, '\n',
            {
              text: 'YO: ' + ttInformeVenta.tcPersonaCrea.personaDesc + '. Presento informe ' + informe + ' para su revisión y validación.',
              style: 'izquierda'
            }, '\n',
            {
              text: '1. Datos de la Plantación o Sistema Agroforestal: ' + ttInformeVenta.ttVentaExento.ttExento.tcCategoriaExento.categoriaExentoDesc,
              style: 'izquierda', bold: true
            }, '\n', tablaGeneral
          ];
          if (ttInformeVenta.tieneMovimiento) {
            content.push('\n');
            content.push(
              {
                text: '2. Informe de uso de Notas de Envío de Bosque Exentos de Licencia Forestal',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(tbConclusion);
            content.push('\n');
            content.push(
              {

                text: ' Rendimiento de madera aserrada: ' + ttInformeVenta.factorAserrada + ' %. Tipo de sierra: ' + (tipoSierra == null ? 'N/A' : tipoSierra) 
                + '. Sierra secundaria: ' + (tipoSierraSecu == null ? 'N/A' : tipoSierraSecu),
                fontSize: 9,
              }
            );
            content.push('\n');
            content.push(
              {
                text: '3. Descripción de las notas de envío utilizadas',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(tbUsado);
            content.push('\n');
            var numeral = 4;
            if (anulados > 0) {
              content.push(
                {
                  text: numeral + '. Descripción de notas de envío anuladas',
                  style: 'izquierda', bold: true
                }
              );
              content.push('\n');
              content.push(tbAnulado);
              content.push('\n');
              numeral = 5;
            }
            if (extraviados > 0) {
              content.push(
                {
                  text: numeral + '. Descripción de notas de envío extraviadas ',
                  style: 'izquierda', bold: true
                }
              );
              content.push('\n');
              content.push(tbExtravio);
              content.push('\n');
              numeral = 6;
            }
            content.push(
              {
                text: numeral + '. Detalle del volumen transportado por producto y por especie',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(tablaVolumen);
            content.push('\n');
          }
          content.push('\n');
          content.push(
            {
              text: 'Elaborado por: ' + ttInformeVenta.tcPersonaCrea.tcUsuario.usuario,
              style: 'derecha', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'A nombre de: ' + ttInformeVenta.tcPersonaCrea.personaDesc,
              style: 'derecha', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fechaElaboracion, 'dd/MM/yyyy HH:mm'),
              style: 'derecha', alignment: 'right'
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc + '\n' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaInformeVenta: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttInformeVenta = tarea.ttInformeVenta;
          var ttVentaExento = ttInformeVenta.ttVentaExento;
          var ttExento = ttVentaExento.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          var elSenor = 'el(la) señor(a) ';
          var variosPropietarios = false;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              variosPropietarios = true;
              elSenor = 'los(as) señores(as) ';
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          if (conteo > 1) {
            propietarioNombre = propietarioNombre.substring(0, propietarioNombre.lastIndexOf(',')) + ' y' + propietarioNombre.substring(propietarioNombre.lastIndexOf(',') + 1);
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
            elSenor = '';
          }
          var paso = null;
          var firmaTecnico = {};
          var informe = 'trimestral';
          switch (ttInformeVenta.esFinal) {
            case 1: informe = 'final'; break;
            case 2: informe = 'avances'; break;
          }

          var visita = ""
          if (ttInformeVenta.requiereVisita == 1) {
            visita = "inspección en campo y"
          }
          var textoEnmienda = '';
          if (tarea.esEnmienda == 1) {
            textoEnmienda = 'sobre las enmiendas ';
          }

          if (tarea.tcPersonaTraslado != null) {
            paso = {
              text: 'Trasládese el expediente administrativo al Técnico Forestal ' + tarea.tcPersonaTraslado.personaDesc
                + ', para que realice ' + visita + ' evaluación técnica ' + textoEnmienda + 'al informe ' + informe + ' sobre el uso de notas de envío exentas de licencia No. '
                + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion + ', la cual proviene de la resolución número '
                + ttVentaExento.resolucion + ', autorizadas al Registro ' + ttExento.codigo
                + ', presentado por ' + elSenor + propietarioNombre + '.', style: 'izquierda'
            };
            firmaTecnico = {
              text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var aTraves = '';
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;          
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              if (ttExento.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  variosMandatarios = true;
                  mandatarios += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  mandatarios = ttExento.representantes[i].tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  representantes = ttExento.representantes[i].tcPersona.personaDesc;
                }
              }
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }          
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            variosPropietarios = false;
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }
          if (mandatarios.length > 0) {
            if (representantes.length > 0) {
              aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ' a través de '
              + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
            } else {
              aTraves = propietarioNombre + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
            }
          } else {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ', ';
              }
            } else {
              aTraves = propietarioNombre;
            }
          }
          aTraves = aTraves + (variosPropietarios ? 'presentaron' : 'presentó');
          if (tarea.codigo == null) {
            tarea.codigo = '---';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: aTraves + ' el informe ' + informe + ' sobre el uso de notas de envío exentas de licencia número '
                        + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion + ', la cual proviene de la resolución número '
                        + ttVentaExento.resolucion + ' Registro ' + ttExento.codigo + '.', style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    {
                      text: 'Trasládese el expediente administrativo al Técnico Forestal ' + tarea.tcPersonaTraslado.personaDesc
                        + ', para que realice ' + visita + ' evaluación técnica ' + textoEnmienda + 'al informe ' + informe + ' sobre el uso de notas de envío exentas de licencia No. '
                        + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion + ', la cual proviene de la resolución número '
                        + ttVentaExento.resolucion + ', autorizadas al Registro ' + ttExento.codigo
                        + ', presentado por ' + elSenor + propietarioNombre + '.', style: 'izquierda'
                    },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarDictamenInformeVenta: function (tarea, usuarioDesc, dataEmpresa, sigla) {
          var ttInformeVenta = tarea.ttInformeVenta;
          var ttVentaExento = ttInformeVenta.ttVentaExento;
          var ttExento = ttVentaExento.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'dictamen.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "", sinDpi = "";
          var i = 0;
          var representado = "Representado";
          if (conteo > 1) {
            representado = "Representados"
          }
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              sinDpi = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                sinDpi = sinDpi + " y " + persona.tcPersona.personaDesc;
              } else {
                sinDpi = sinDpi + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          conteo = ttExento.representantes.length;
          var sinDpiRep = '';
          var i = 0;
          var hayRepresentante = false;
          for (i = 0; i < conteo; i++) {
            hayRepresentante = true;
            persona = ttExento.representantes[i];
            if (i == 0) {
              sinDpiRep = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                sinDpiRep = sinDpiRep + " y " + persona.tcPersona.personaDesc;
              } else {
                sinDpiRep = sinDpiRep + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var aTraves = 'Por este medio le informo sobre los resultados de la revisión del informe de uso de notas de envío, presentado por ';
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            aTraves += ttExento.ttJuridicaExento.razonSocial + '. Representado legalmente por ' + sinDpiRep;
          } else {
            if (hayRepresentante) {
              aTraves += sinDpi + '. ' + representado + ' legalmente por ' + sinDpiRep;
            } else {
              aTraves += sinDpi;
            }
          }
          aTraves += ', las cuales fueron autorizadas en resolución número ' + ttVentaExento.resolucion + ', para el transporte del producto de la actividad forestal '
            + ttExento.tcCategoriaExento.categoriaExentoDesc + ' con registro número ' + ttExento.codigo;
          var notas = ttInformeVenta.notas;
          var lista = ttInformeVenta.especies;
          body = [
            [
              { text: 'Especie', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Troza (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Leña (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Troza en finca (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Leña en finca (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Total (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' }
            ]
          ];
          var totales = {
            lenia: 0,
            troza: 0,
            leniaFinca: 0,
            trozaFinca: 0,
            volumen: 0
          };
          for (var i = 0; i < lista.length; i++) {
            lista[i].lenia = parseFloat(lista[i].lenia).toFixed(2);
            lista[i].troza = parseFloat(lista[i].troza).toFixed(2);
            lista[i].leniaFinca = parseFloat(lista[i].leniaFinca).toFixed(2);
            lista[i].trozaFinca = parseFloat(lista[i].trozaFinca).toFixed(2);
            lista[i].volumen = parseFloat(lista[i].volumen).toFixed(2);
            body.push(
              [
                { text: lista[i].tcEspecie.nombreCientifico, style: 'detalleIzquierda' },
                { text: lista[i].troza, style: 'detalleDerecha' },
                { text: lista[i].lenia, style: 'detalleDerecha' },
                { text: lista[i].trozaFinca, style: 'detalleDerecha' },
                { text: lista[i].leniaFinca, style: 'detalleDerecha' },
                { text: lista[i].volumen, style: 'detalleDerecha' }
              ]
            );
            totales.lenia += parseFloat(lista[i].lenia);
            totales.troza += parseFloat(lista[i].troza);
            totales.leniaFinca += parseFloat(lista[i].leniaFinca);
            totales.trozaFinca += parseFloat(lista[i].trozaFinca);
            totales.volumen += parseFloat(lista[i].volumen);
          }
          totales.lenia = parseFloat(totales.lenia).toFixed(2);
          totales.troza = parseFloat(totales.troza).toFixed(2);
          totales.leniaFinca = parseFloat(totales.leniaFinca).toFixed(2);
          totales.trozaFinca = parseFloat(totales.trozaFinca).toFixed(2);
          totales.volumen = parseFloat(totales.volumen).toFixed(2);
          body.push(
            [
              { text: 'TOTAL', style: 'detalleCentrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: totales.troza, style: 'detalleDerecha', fillColor: '#CCCCCC' },
              { text: totales.lenia, style: 'detalleDerecha', fillColor: '#CCCCCC' },
              { text: totales.trozaFinca, style: 'detalleDerecha', fillColor: '#CCCCCC' },
              { text: totales.leniaFinca, style: 'detalleDerecha', fillColor: '#CCCCCC' },
              { text: totales.volumen, style: 'detalleDerecha', fillColor: '#CCCCCC' }
            ]
          );
          var tablaVolumen = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [115, 65, 65, 65, 65, 65],
              body: body
            }
          }
          body = [];
          var conclusiones = ttInformeVenta.conclusiones;
          body = [];
          for (var i = 0; i < conclusiones.length; i++) {
            var row = conclusiones[i];
            body.push(
              [
                { text: '', style: 'izquierda' },
                { text: i + 1, style: 'izquierda' },
                { text: row.conclusion, style: 'izquierda' }
              ]
            );
          }
          var tbConclusion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [10, 10, 450],
              body: body
            }, layout: 'noBorders'
          }
          var informe = 'trimestral';
          switch (ttInformeVenta.esFinal) {
            case 1: informe = 'final'; break;
            case 2: informe = 'avances'; break;
          }
          var balance = tarea.observaciones.balance;
          body = [
            [
              { text: 'Especie', style: 'detalleCentrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Nombre común', style: 'detalleCentrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen carga inicial / actualización (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Volumen trasportado acumulado (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' },
              { text: 'Saldo en Volumen (m³)', style: 'detalleCentrar', fillColor: '#CCCCCC' }
            ]
          ];
          var total = {
            volumen: 0,
            transportado: 0,
            saldo: 0
          };
          for (var i = 0; i < balance.length; i++) {
            total.volumen += parseFloat(balance[i].volumen);
            total.saldo += parseFloat(balance[i].saldo);
            total.transportado += parseFloat(balance[i].reserva);
            body.push(
              [
                { text: balance[i].tcEspecie.nombreCientifico, style: 'detalleIzquierda' },
                { text: balance[i].tcEspecie.especieDesc, style: 'detalleIzquierda' },
                { text: parseFloat(balance[i].volumen).toFixed(2), style: 'detalleDerecha' },
                { text: balance[i].reserva, style: 'detalleDerecha' },
                { text: balance[i].saldo, style: 'detalleDerecha' }
              ]
            );
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalleCentrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: parseFloat(total.volumen).toFixed(2), style: 'detalleDerecha', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(total.transportado).toFixed(2), style: 'detalleDerecha', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(total.saldo).toFixed(2), style: 'detalleDerecha', fillColor: '#CCCCCC', alignment: 'right' }
            ]
          );
          var tbBalance = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [105, 120, 75, 75, 75],
              body: body
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nDictamen Técnico de Revisión de Uso de Notas de Envío",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Dictamen Técnico número: " + tarea.codigo,
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaProvidencia.getDate() + " de " + getMonth(fechaProvidencia.getMonth() + 1) + " del año " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Director Subregional "
                + ttExento.tcSubregion.subregionDesc + "\nINAB, " + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n',
            {
              text: 'I. Datos del informe presentado',
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: 'Numero de informe: ' + ttInformeVenta.correlativo,
              style: 'izquierda'
            },
            {
              text: 'Tipo de informe: ' + informe,
              style: 'izquierda'
            },
            {
              text: 'Periodo reportado: ' + $filter('date')(new Date(ttInformeVenta.fechaInicio), 'dd/MM/yyyy')
                + ' ' + $filter('date')(new Date(ttInformeVenta.fechaFinal), 'dd/MM/yyyy'),
              style: 'izquierda'
            },
            {
              text: 'Fecha del informe: ' + $filter('date')(new Date(ttInformeVenta.fechaElaboracion), 'dd/MM/yyyy'),
              style: 'izquierda'
            }, '\n',
            {
              text: 'II. Informe de Uso de notas de envió exentas de Licencia',
              style: 'izquierda', bold: true
            }, '\n',
            tbConclusion, '\n',
            {
              text: 'III. Detalle del volumen transportado por producto y por especie',
              style: 'izquierda', bold: true
            }, '\n',
            tablaVolumen, '\n',
            {
              text: 'IV. Balance general del volumen del registro No. ' + ttExento.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            tbBalance
          ];
          content.push('\n');
          content.push(
            {
              text: 'DICTAMEN TÉCNICO',
              style: 'centrar', bold: true
            }
          );
          content.push('\n');
          if (tarea.aprobado == 1) {
            content.push(
              {
                text: '1. Con base a la revisión del informe y copia de notas de envío presentados dictamino que los datos en las notas de envío fueron consignados correctamente y que el informe cumple con la normativa vigente, por lo cual recomiendo la aprobación del mismo',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '2. En el presente informe no se incurrió en las prohibiciones descritas en el artículo 20 del reglamento de transporte de productos forestales',
                style: 'izquierda'
              }
            );
          } else {
            content.push(
              {
                text: '1. Con base a la revisión del informe y copia de notas de envío presentados dictamino que los datos en las notas de envío NO fueron consignados correctamente y que el informe incumple con la normativa vigente, por lo cual recomiendo la no aprobación del mismo',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '2. En informe incurrió en las prohibiciones descritas en el artículo 20 del reglamento de transporte de productos forestales',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: '3. Las razones de la no aprobación se detallan a continuación: ',
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          if (tarea.aprobado == 0) {
            var dataTmp = tarea.observaciones.razon;
            for (i = 0; i < dataTmp.length; i++) {
              content.push(
                {
                  text: '3.' + (i + 1) + '. ' + dataTmp[i].descripcion,
                  style: 'izquierda'
                }
              );
            }
            content.push('\n');
          }
          content.push(
            'Atentamente,\n\n\n\n'
          );
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Técnico forestal, subregión " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              },
              detalleIzquierda: {
                fontSize: 9,
                alignment: 'justify'
              },
              detalleDerecha: {
                fontSize: 9,
                alignment: 'right'
              },
              detalleCentrar: {
                fontSize: 9,
                alignment: 'center'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotificacionInformeVenta: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined) {
            sigla = "";
          } else {
            if (sigla == null) {
              sigla = "";
            }
          }
          var ttInformeVenta = ttTarea.ttInformeVenta;
          var ttVentaExento = ttInformeVenta.ttVentaExento;
          var ttExento = ttVentaExento.ttExento;
          var nombreSalida = 'notificacion.pdf';
          var fechaProvidencia = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var i, j;
          var textoFinca = "";
          for (i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i > 0) {
              textoFinca = textoFinca + ", " + finca.tcFinca.fincaDesc + '. Área registrada ha.: ' + ttExento.area
                + ' Ubicación de finca: ' + finca.tcFinca.direccion + ' muncipio de ' + finca.tcFinca.tcMunicipio.municipioDesc
                + ' departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoFinca = finca.tcFinca.fincaDesc + '. Área registrada ha.: ' + ttExento.area
                + ' \nUbicación de finca: ' + finca.tcFinca.direccion + ' muncipio de ' + finca.tcFinca.tcMunicipio.municipioDesc
                + ' departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }

          var content = [
            {
              text: "Notificación sobre Informe de Notas de Envío de Bosque Exentos de Licencia Forestal",
              style: 'centrar'
            }, '\n',
            {
              text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaProvidencia.getDate() + " de " + getMonth(mes) + " de " + fechaProvidencia.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: 'Datos Generales',
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              text: "Número de informe: " + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion,
              style: 'izquierda'
            },
            {
              text: "Solicitante: " + propietarioNombre,
              style: 'izquierda'
            },
            {
              text: "Código del RNF: " + ttExento.codigo,
              style: 'izquierda'
            },
            {
              text: "Nombre de la Finca: " + textoFinca,
              style: 'izquierda'
            }, '\n',
            {
              text: "NOTIFICACIÓN",
              style: 'centrar',
              bold: true
            }, '\n'
          ];
          if (ttTarea.ttPadre.aprobado == 0) {
            content.push(
              {
                text: 'Estimado (a): ' + propietarioNombre + '. A través de la presente le notifico que el informe de uso de notas de envío número '
                  + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion + ', NO se aprobó debido a las razones siguientes: ',
                style: 'izquierda'
              }
            );
            content.push('\n');
            var data = ttTarea.ttPadre.observaciones.razon;
            var lista = [];
            for (var i = 0; i < data.length; i++) {
              lista.push({ text: data[i].descripcion, style: 'izquierda' });
            }
            content.push(
              {
                ol: lista
              }
            );
          } else {
            content.push(
              {
                text: 'Estimado (a): ' + propietarioNombre + '. A través de la presente le notifico que el informe de uso de notas de envío número '
                  + ttInformeVenta.correlativo + '-' + ttVentaExento.resolucion + ', ha sido aprobado por lo que puede continuar con el procedimiento que corresponda',
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          content.push(
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "F. _________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCaratulaTramiteExento: function (data, tipoCaratulaId, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'caratula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var fechaIngreso = new Date(data.fechaRecepcion);
          var ttExento = null;
          var textoGestion = 'Número de Gestión';
          var tipoSolicitud = 'Venta de notas de envío de bosque exento de licencia forestal';
          var noGestion = '';
          if (tipoCaratulaId == 3) {
            textoGestion = 'Número de Informe';
            noGestion = data.correlativo;
            ttExento = data.ttVentaExento.ttExento;
            tipoSolicitud = 'Informe parcial/trimestral/final';
          } else if (tipoCaratulaId == 2) {
            ttExento = data.ttExento;
            noGestion = ttExento.tcSubregion.codigo + '-' + data.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + data.anio;
          } else {
            ttExento = data;
            noGestion = '';
            textoGestion = '';
            tipoSolicitud = 'Carga Inicial del Exento de Licencia';
          }
          var content = [];
          var item = {
            text: "INSTITUTO NACIONAL DE BOSQUES\nDIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc,
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "No. Expediente:",
            style: 'sencillo'
          };
          content.push(item);
          item = {
            text: ttExento.expediente,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: textoGestion,
            style: 'sencillo'
          };
          content.push(item);
          item = {
            text: noGestion,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Tipo de Solicitud:",
            style: 'sencillo'
          };
          content.push(item);
          item = {
            text: tipoSolicitud,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Localización de la finca:",
            style: 'sencillo'
          };
          content.push(item);
          var finca = ttExento.fincas[0].tcFinca;
          item = {
            text: finca.tcMunicipio.municipioDesc + ", " + finca.tcMunicipio.tcDepartamento.departamentoDesc,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: "Nombre(s) del(os) solicitante(s):",
            style: 'sencillo'
          };
          content.push(item);
          var solicitanteNombre = "";
          var i, j;
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = ttExento.ttJuridicaExento.razonSocial;
          } else {
            for (i = 0; i < ttExento.propietarios.length; i++) {
              if (i == 0) {
                solicitanteNombre = ttExento.propietarios[i].tcPersona.personaDesc;
              } else {
                solicitanteNombre += '\n' + ttExento.propietarios[i].tcPersona.personaDesc;
              }
            }
          }
          item = {
            text: solicitanteNombre,
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          item = {
            text: 'Datos para notificación',
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          //obtener los tipos de notificacion
          var body = [];
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < ttExento.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(ttExento.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionExentoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionExentoDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionExentoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionExentoDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'sencillo', alignment: 'left' },
              { text: strDescripcion, style: 'negrilla', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 450],
              body: body
            }, layout: 'noBorders'
          };
          content.push(tablaGenerales);
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 13,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 14,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarConstanciaRecepcionExento: function (data, esInforme, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'constancia.pdf';
          var fechaRecepcion = new Date(data.fechaRecepcion);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          var solicitanteNombre = "";
          var i, j;
          var ttExento = null;
          var textoGestion = 'Gestión';
          var noGestion = '';
          if (esInforme) {
            textoGestion = 'Informe';
            noGestion = data.correlativo;
            ttExento = data.ttVentaExento.ttExento;
          } else {
            ttExento = data.ttExento;
            noGestion = ttExento.tcSubregion.codigo + '-' + data.numero + '-' + ttExento.tcSubtipoExento.codigoVenta + '-' + data.anio;
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitanteNombre = ttExento.ttJuridicaExento.razonSocial;
          } else {
            for (i = 0; i < ttExento.propietarios.length; i++) {
              if (i == 0) {
                solicitanteNombre = ttExento.propietarios[i].tcPersona.personaDesc;
              } else {
                solicitanteNombre += '\n' + ttExento.propietarios[i].tcPersona.personaDesc;
              }
            }
          }
          body = [
            [
              { text: 'I. DATOS GENERALES DEL EXPEDIENTE', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ],
            [
              { text: 'Lugar y Fecha', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ', ' + fechaRecepcion.getDate() + ' de ' + getMonth(mes) + ' de ' + fechaRecepcion.getFullYear(), style: 'izquierda' }
            ],
            [
              { text: 'No. de ' + textoGestion, style: 'izquierda', fillColor: '#CCCCCC' },
              { text: noGestion, style: 'izquierda' }
            ],
            [
              { text: 'Nombre(s) del(os) solicitante(s):', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: solicitanteNombre, style: 'izquierda' }
            ]
          ];
          solicitanteNombre = "";
          if (ttExento.representantes.length > 0) {
            var representante = '', mandatarios = '';
            var variosMandatarios = false, variosRepresentantes = false;
            for (i = 0; i < ttExento.representantes.length; i++) {
              var persona = ttExento.representantes[i];
              if (persona.mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += '\n' + persona.tcPersona.personaDesc;
                  variosMandatarios = true;
                } else {
                  mandatarios = persona.tcPersona.personaDesc;
                }
              } else {
                if (representante.length > 0) {
                  variosRepresentantes = true;
                  representante += '\n' + persona.tcPersona.personaDesc;
                } else {
                  representante = persona.tcPersona.personaDesc;
                }
              }
            }
            if (representante.length > 0) {
              if (variosRepresentantes) {
                body.push(
                  [
                    { text: 'Representantes legales', style: 'izquierda', fillColor: '#CCCCCC' },
                    { text: representante, style: 'izquierda' }
                  ]
                );
              } else {
                body.push(
                  [
                    { text: 'Nombre del representante legal:', style: 'izquierda', fillColor: '#CCCCCC' },
                    { text: representante, style: 'izquierda' }
                  ]
                );
              }
            }
            if (mandatarios.length > 0) {
              if (variosMandatarios) {
                body.push(
                  [
                    { text: 'Mandatarios', style: 'izquierda', fillColor: '#CCCCCC' },
                    { text: mandatarios, style: 'izquierda' }
                  ]
                );
              } else {
                body.push(
                  [
                    { text: 'Mandatario:', style: 'izquierda', fillColor: '#CCCCCC' },
                    { text: mandatarios, style: 'izquierda' }
                  ]
                );
              }
            }
          }
          body.push(
            [
              { text: 'II. Datos de notificación', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000', colSpan: 2 }, {}
            ]
          );
          //obtener los tipos de notificacion
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < ttExento.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: ttExento.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(ttExento.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionExentoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionExentoDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionExentoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionExentoDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [190, 310],
              body: body
            }
          }
          //tabla codigos
          body = [
            [
              { text: 'III.', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Notas de Envío', style: 'izquierda', colSpan: 3, color: '#FFFFFF', fillColor: '#000000' }, {}, {}
            ],
            [
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción de la gestión', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción específica de la gestión', style: 'izquierda', fillColor: '#CCCCCC' }
            ],
            [
              { text: ttExento.tcSubtipoExento.tcTipoExento.codigoVenta, style: 'centrar' },
              { text: ttExento.tcSubtipoExento.tcTipoExento.ventaDesc, style: 'izquierda' },
              { text: ttExento.tcSubtipoExento.codigoVenta, style: 'izquierda' },
              { text: ttExento.tcSubtipoExento.ventaDesc, style: 'izquierda' }
            ]
          ];
          var tablaCodigos = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 200, 50, 200],
              body: body
            }
          }
          //tabla documentos recibidos
          body = [
            [
              { text: 'IV. DOCUMENTOS RECIBIDOS.', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ]
          ];
          for (var i = 0; i < data.requisito.length; i++) {
            item = [
              { text: i + 1, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.requisito[i].requisitoDesc, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaRequisito = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 480],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 60,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            },
            {
              text: "CONSTANCIA DE RECEPCIÓN DE DOCUMENTOS",
              style: 'centrar'
            }, '\n',
            {
              text: "DIRECCIÓN SUBREGIONAL: " + ttExento.tcSubregion.subregionDesc,
              style: 'derecha'
            },
            {
              text: "EXPEDIENTE No. " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            tablaGenerales, '\n', tablaCodigos, '\n', tablaRequisito, '\n',
            {
              text: "La presente Constancia de Recepción de Expediente, no constituye aprobación alguna de la gestión solicitada; por lo que, el expediente administrativo entra a las fases de análisis para luego emitir la resolución que en derecho corresponda, la cual deberá ser notificada en el momento oportuno.",
              style: 'izquierda'
            }, '\n',
            {
              text: 'Impreso por: ' + usuarioDesc + '\t' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                bold: true,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCompletacionExento: function (data, dataEmpresa, personaDesc, esInforme) {
          var nombreSalida = 'notificacion.pdf';
          var ttVentaExento = null;
          if (esInforme) {
            ttVentaExento = data.ttVentaExento;
          } else {
            ttVentaExento = data;
          }
          var ttExento = ttVentaExento.ttExento;
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var body = [];
          for (var i = 0; i < data.requisito.length; i++) {
            item = [
              { text: i + 1, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.requisito[i].requisitoDesc, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaRequisito = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 480],
              body: body
            }
          }
          var tramite = "solicitud y documentación para venta de notas de envío";
          if (esInforme) {
            tramite = 'informe de uso de notas de envío';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "CONSTANCIA DE DOCUMENTOS FALTANTES",
              style: 'centrar'
            }, '\n',
            {
              text: 'DIRECCIÓN SUBREGIONAL: ' + ttExento.tcSubregion.subregionDesc,
              style: 'derecha'
            },
            {
              text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ', ' + fecha.getDate() + ' de ' + getMonth(mes) + ' de ' + fecha.getFullYear(),
              style: 'derecha'
            },
            '\n', '\n',
            {
              text: "Por este medio, se hace constar que día de hoy, " + data.tcPersonaCrea.personaDesc + " presentó "
                + tramite + ' y de acuerdo a la revisión de los mismos, se determina que no cumple con los requisitos para proceder a su recepción e iniciar su trámite, por lo que es necesario completar la documentación adicionando los siguientes requisitos:',
              style: 'izquierda'
            }, '\n', tablaRequisito,
            '\n',
            {
              text: "Por medio de la presente se deja constancia de la atención brindada al usuario donde se revisó la documentación presentada, sin embargo, la misma no fue recibida por estar incompleta.",
              style: 'izquierda'
            }, '\n', '\n', '\n', '\n',
            {
              text: "F _________________________________",
              style: 'centrar'
            },
            {
              text: personaDesc,
              style: 'centrar'
            },
            {
              text: "Secretaria (o)",
              style: 'centrar'
            }, '\n\n',
            {
              text: "Nombre: _________________________________",
              style: 'derecha'
            }, '\n\n',
            {
              text: "Firma: __________________________________",
              style: 'derecha'
            }, '\n\n',
            {
              text: "cc, Interasado",
              style: 'small', alignment: 'left'
            },
            {
              text: '\n\nFecha y hora de impresión: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudActualizacionExento: function (ttActualizacionExento, dataEmpresa) {
          var data = ttActualizacionExento.ttExento;
          var nombreSalida = 'actualizacion.pdf';
          var fecha = new Date();
          var fechaSolicitud = ttActualizacionExento.fechaSolicitud;
          var mes = parseInt(fechaSolicitud.getMonth()) + 1;
          var dia = fechaSolicitud.getDate();
          var anio = fechaSolicitud.getFullYear();
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var entidad = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nombreComercial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            entidad = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
            razonSocial = data.ttJuridicaExento.razonSocial;
          }
          body = [
            [
              { text: 'I. INFORMACIÓN GENERAL', style: 'tableSubHeader', colSpan: 5, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}
            ],
            [
              { text: 'Código del RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.codigo, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: data.area, style: 'detalle', alignment: 'left' }
            ]
          ];
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 1) {
            body.push(
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Comunidad Lingüística', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Pueblo de Pertenencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Estado civil', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            );
          }
          var dataPersona = data.propietarios;
          for (i = 0; i < dataPersona.length; i++) {
            item = [
              { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcIdioma.idiomaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcCultura.culturaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcEstadoCivil.estadoCivilDesc, style: 'detalle', alignment: 'left' }
            ];
            body.push(item);
            if (dataPersona[i].tcPersona.tcSexo.sexoId == 1) {
              mujeres++;
            } else {
              hombres++;
            }
          }
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 1) {
            item = [
              { text: 'Hombres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: hombres, style: 'tableHeader', alignment: 'left' },
              { text: 'Mujeres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: mujeres, style: 'tableHeader', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
          }
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;
          if (data.representantes.length > 0) {
            for (var i = 0; i < data.representantes.length; i++) {
              if (data.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                } else {
                  variosMandatarios = true;
                  mandatarios = data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                } else {
                  representantes = data.representantes[i].tcPersona.personaDesc + ' con Código Único de Identificación -CUI- ' + data.representantes[i].tcPersona.cui;
                }
              }
            }
            if (representantes.length > 0) {
              item = [
                { text: 'Representante legal', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre completo', style: 'tableSubHeader', colSpan: 2, fillColor: '#CCCCCC', alignment: 'center' }, {},
                { text: 'Código Único de Identificación -CUI-', style: 'tableSubHeader', colSpan: 3, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}
              ];
              body.push(item);
              for (var i = 0; i < data.representantes.length; i++) {
                if (!data.representantes[i].mandatario) {
                  item = [
                    { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                    { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
                  ];
                  body.push(item);
                }
              }
            }
            if (mandatarios.length > 0) {
              item = [
                { text: 'Mandatario', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'CUI', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'Fecha inicio', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }, 
                { text: 'Vence', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
              ];
              body.push(item);
              for (var i = 0; i < data.representantes.length; i++) {                
                var mandatoInicio = '', mandatoFin = '';
                if (data.representantes[i].mandatario) {
                  if (data.representantes[i].mandatoInicio != null) {
                    var partes = data.representantes[i].mandatoInicio.toString().split('-');
                    if (partes.length > 2) {
                      mandatoInicio = partes[2] + '/' + partes[1] + '/' + partes[0];
                    } else {
                      mandatoInicio = $filter('date')(data.representantes[i].mandatoInicio, 'dd/MM/yyyy');
                    }
                  }
                  if (data.representantes[i].mandatoFin == null) {
                    mandatoFin = 'Indefinido';
                  } else {
                    var partes = data.representantes[i].mandatoFin.toString().split('-');
                    if (partes.length > 2) {
                      mandatoFin = partes[2] + '/' + partes[1] + '/' + partes[0];
                    } else {
                      mandatoFin = $filter('date')(data.representantes[i].mandatoFin, 'dd/MM/yyyy');
                    }
                  }
                  item = [
                    { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                    { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
                    { text: mandatoInicio, style: 'detalle', alignment: 'center' },
                    { text: mandatoFin, style: 'detalle', alignment: 'center', colSpan: 2 }, {}
                  ];
                  body.push(item);
                }
              }
            }
          }
          var solicitante = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 90, 85, 83, 80],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'II. DATOS DE LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (data.tcTipoPropietario.tipoPropietarioId == 1) {
              for (j = 0; j < finca.propietarios.length; j++) {
                if (j == 0) {
                  propietario = finca.propietarios[j].tcPersona.personaDesc;
                } else {
                  propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
                }
              }
            } else {
              propietario = razonSocial;
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'detalle', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'detalle', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'detalle', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            if (documentos == null) {
              documentos = [];
            }
            var cantidadDocumentos = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidadDocumentos > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidadDocumentos; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidadDocumentos - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 4:
                item = null;
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            if (item != null) {
              body.push(item);
            }
            item = [
              { text: 'Área total de la finca (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.areaDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.area, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var finca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'III. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}
            ]
          ];
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            } else {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            }
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          var tbVolumen = {};
          var solicito = {
            text: 'SOLICITO: actualización de información en el sistema de Exentos de Licencia Forestal, correspondiente al código del RNF: ' + data.codigo,
            style: 'izquierda'
          };
          var kEnter = {};
          if (ttActualizacionExento.tipoActualizacionId == 2) {
            solicito = {
              text: 'SOLICITO: actualización de volumen en el sistema de Exentos de Licencia Forestal, correspondiente al código del RNF: ' + data.codigo,
              style: 'izquierda'
            };
            var rodales = ttActualizacionExento.rodales;
            if (rodales != null && ttActualizacionExento.tipoActualizacionId == 2) {
              var soloEnLinea = true, soloRodales = true;
              for (let i = 0; i < rodales.length; i++) {
                const element = rodales[i];
                if (element.enLinea == 0) {
                  soloEnLinea = false;
                } else {
                  soloRodales = false;
                }
              }
              if (soloEnLinea) {
                body = [
                  [
                    { text: 'Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                    { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                  ]
                ];
              } else {
                if (soloRodales) {
                  body = [
                    [
                      { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Área (ha.)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                    ]
                  ];
                } else {
                  body = [
                    [
                      { text: 'Rodal/Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Área (ha.)/Longitud (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                      { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
                    ]
                  ];
                }
              }
              var filas = [];
              var tablaFila = {};
              for (var i = 0; i < rodales.length; i++) {
                var especies = rodales[i].especies;
                filas = [
                  [
                    { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                    { text: 'Vol.(m³) / ha', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                    { text: 'Vol.(m³) / Rodal / Línea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
                  ]
                ];
                for (var j = 0; j < especies.length; j++) {
                  var especie = especies[j];
                  item = [
                    { text: especie.tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                    { text: especie.volumenHectariaSolicitado == null ? especie.volumenHectaria : especie.volumenHectariaSolicitado, style: 'small', alignment: 'right' },
                    { text: especie.volumenSolicitado == null ? especie.volumen : especie.volumenSolicitado, style: 'small', alignment: 'right' }
                  ];
                  filas.push(item);
                }
                tablaFila = {
                  style: 'estiloTablaEstrecha',
                  table: {
                    headerRows: 1,
                    widths: [190, 95, 95],
                    body: filas
                  }, layout: 'noBorders'
                }
                item = [
                  { text: rodales[i].correlativo, style: 'small', alignment: 'center' },
                  { text: rodales[i].area, style: 'small', alignment: 'center' },
                  tablaFila
                ];
                body.push(item);
              }
              tbVolumen = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [50, 50, 400],
                  body: body
                }
              }
              kEnter = '\n';
            }
          } else {
            solicito = {
              text: 'SOLICITO: actualización de información en el sistema de exentos de licencia forestal, según Constancia de Registro emitida con fecha ' 
              + $filter('date')(new Date(ttActualizacionExento.fechaActualizacion), 'dd/MM/yyyy') + ' y vigencia hasta el ' 
              + $filter('date')(new Date(ttActualizacionExento.fechaVencimiento), 'dd/MM/yyyy') + '.',
              style: 'izquierda'
            };
          }
          var textFormula = {};
          var tituloFormula = {};
          var textReferenciaFormula = {};
          if (ttActualizacionExento.calculado) {
            tituloFormula = {
              text: '\nEl cálculo del volumen fue realizado por el solicitante y las fórmulas utilizadas son:\n',
              style: 'izquierda'
            };
            textFormula = {
              text: ttActualizacionExento.formula,
              style: 'izquierda'
            }
            textReferenciaFormula = {
              text: '\nReferencia de fórmulas utilizadas: ' + ttActualizacionExento.referenciaFormula + '\n',
              style: 'izquierda'
            };
          }
          var content = [
            '\n',
            {
              text: data.tcSubregion.tcMunicipio.municipioDesc + ', ' + getMonth(mes) + ' ' + dia + ' de ' + anio,
              style: 'derecha'
            }, '\n', solicitante, entidad, finca, tbNotificacion, tbVolumen, tituloFormula, textFormula, textReferenciaFormula, kEnter, solicito,
            {
              text: '\n\nElaborado por: ' + data.tcPersonaCrea.tcUsuario.usuario,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'A nombre de: ' + data.tcPersonaCrea.personaDesc,
              style: 'derecha', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'derecha', alignment: 'right'
            }
          ];
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 60, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            header: function (currentPage, pageCount) {
              return [
                {
                  margin: [40, 10, 0, 0],
                  columns: [
                    {
                      width: 70,
                      image: 'data:image/png;base64,' + dataEmpresa.logo,
                      style: 'izquierda'
                    },
                    {
                      width: 380,
                      text: "\nMódulo Electrónico para la Autorización de Notas de Envío de Bosque de Exentos de Licencia Forestal\nSolicitud de actualización de exento",
                      style: 'centrar'
                    },
                    {
                      width: 80,
                      text: currentPage.toString() + '/' + pageCount,
                      style: 'derecha'
                    }
                  ]
                }
              ]
            },
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarFormularioAdhesion: function (data, dataEmpresa, strDia, strMes, strAnio) {
          var nombreSalida = 'adhesion.pdf';
          var fecha = new Date(data.fechaRegistro);
          var fechaNacimiento = new Date(data.fechaNacimiento);
          body = [
            [
              { text: 'I. Datos Generales', style: 'tableSubHeader', colSpan: 6, alignment: 'izquierda' }, {}, {}, {}, {}, {}
            ],
            [
              { text: '\n', style: 'tableSubHeader', colSpan: 6, alignment: 'izquierda' }, {}, {}, {}, {}, {}
            ],
            [
              {
                text: [
                  { text: 'Nombre completo: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.personaDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 6
              }, {}, {}, {}, {}, {}
            ],
            [
              {
                text: [
                  { text: 'Número Único de Identificación: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.cui, style: 'izquierda', alignment: 'left' }
                ], colSpan: 6
              }, {}, {}, {}, {}, {}
            ],
            [
              {
                text: [
                  { text: 'Ocupación: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.tcOcupacion.ocupacionDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 6
              }, {}, {}, {}, {}, {}
            ],
            [
              {
                text: [
                  { text: 'Fecha de nacimiento: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: fechaNacimiento.getDate() + '/' + (fechaNacimiento.getMonth() + 1) + '/' + fechaNacimiento.getFullYear(), style: 'izquierda', alignment: 'left' }
                ], colSpan: 2
              }, {},
              {
                text: [
                  { text: 'Sexo: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.tcSexo.sexoDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 2
              }, {},
              {
                text: [
                  { text: 'NIT: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.nit, style: 'izquierda', alignment: 'left' }
                ], colSpan: 2
              }, {}
            ],
            [
              {
                text: [
                  { text: 'Estado civil: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.tcEstadoCivil.estadoCivilDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 2
              }, {},
              {
                text: [
                  { text: 'Comunidad Lingüística: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.tcIdioma.idiomaDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 4
              }, {}, {}, {}
            ],
            [
              {
                text: [
                  { text: 'Pueblo de pertenencia: ', style: 'tableSubHeader', alignment: 'left' },
                  { text: data.tcCultura.culturaDesc, style: 'izquierda', alignment: 'left' }
                ], colSpan: 6
              }, {}, {}, {}, {}, {}
            ]
          ];
          tbDivision = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [85, 85, 75, 75, 75, 80],
              body: body
            },
            layout: 'noBorders'
          }

          var content = [
            {
              text: 'FORMULARIO DE ADHESIÓN AL MÓDULO DE EXENTOS DEL INSTITUTO NACIONAL DE BOSQUES',
              style: 'centrar',
              bold: true
            }, '\n', tbDivision, '\n',
            {
              text: "II. Acuerdo de adhesión",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              text: 'Los términos y condiciones del presente acuerdo en adelante simplemente llamado “El Acuerdo”, gobiernan y regulan las condiciones de acceso y uso del Módulo de Exentos, por mi persona. Este es un acuerdo entre mi persona y El Instituto Nacional de Bosques en adelante denominado “INAB”, el cual podrá ser sujeto a cambios por parte del INAB a su absoluta discreción en cualquier momento y sin necesidad de aviso previo. Desde ya declaro: a) ser mayor de edad y ser civilmente capaz para la suscripción del presente acuerdo; b) que he leído y comprendo el contenido íntegro de este acuerdo y que me obligo bajo los términos y condiciones acá establecidas; c) que por este medio celebro acuerdo al cual me adhiero de forma voluntaria y expresa con el INAB. El INAB se reserva en todo momento el derecho de aceptar o rechazar sin expresión de causa, el contenido y administración de la información proporcionada por mi persona, quedando a absoluta discreción por parte del INAB la confirmación de la misma',
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Uso del contenido',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Al aceptar este acuerdo acepto que estoy adhiriéndome a las políticas de creación, uso y administración del Módulo de Exentos',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Responsabilidad',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Yo el USUARIO, en adición a cualquier otra obligación que hubiere adquirido en este acuerdo, me comprometo a indemnizar, defender y mantener al INAB libre y a salvo de cualquier responsabilidad, daño o perjuicio y de todas las responsabilidades, pérdidas, penalidades y costos ocasionados por demandas legales o quejas que surjan como resultado relacionado con el uso de los Términos y Condiciones del Módulo de Exentos y resultado relacionado con el incumplimiento o violación ocasionadas por mi persona a cualquiera de las Políticas de Uso contenidas en los “Términos y Condiciones” en este acuerdo.',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Acuerdo de adhesión que contiene los términos y condiciones de uso del módulo de exentos',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Este Acuerdo de Adhesión que contiene los Términos y Condiciones de Uso del Módulo de Exentos, describe los derechos y responsabilidades, y establece los términos y condiciones bajo las cuales usted puede utilizar este Sistema. Por favor, lea atentamente este documento. El término “usted” como se utiliza aquí se refiere a todas las personas y/o entidades que acceden al módulo de exentos por cualquier razón. Al continuar utilizando el módulo de exentos, usted indica que acepta obligarse por los términos y condiciones de este Acuerdo. Si usted no acepta los términos y condiciones establecidas aquí, no podrá hacer uso del Módulo de Exentos. Es su responsabilidad revisar este Acuerdo periódicamente. El INAB se reserva el derecho de modificar este Acuerdo periódicamente sin necesidad de previo aviso y a su sola discreción en cualquier momento mediante la actualización del Sistema, y su uso continuado después de cualquier modificación constituirá su aceptación de dichas modificaciones. Antes de continuar, le recomendamos imprimir o guardar una copia de las condiciones universales contenidas en el presente acuerdo a efectos de control personal. Los Términos y condiciones de uso son los siguientes:',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Primera',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Que voluntariamente me adhiero al Módulo de Exentos del Instituto Nacional de Bosques, de acuerdo a la normativa forestal vigente aplicable',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Segunda',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Manifiesto que soy el único responsable por el uso que mi persona o un tercero dé al Usuario y contraseña que en este momento se genera a mi favor y que me permitirá acceder al Módulo de Exentos',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Tercera',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Que estoy de acuerdo en que se envíe a la dirección electrónica que proporcione para el efecto, todas las notificaciones de los procedimientos administrativos y resoluciones, en los que la señale como lugar para recibir notificaciones, inclusive las notificaciones de las resoluciones enumeradas en el Decreto 119-96 del Congreso de la República de Guatemala, Ley de lo Contencioso Administrativo; Decreto 101-96 del Congreso de la República de Guatemala, Ley Forestal y su Reglamento; Decreto Ley 107, Código Procesal Civil y Mercantil y demás legislación Forestal en que el INAB tenga competencia',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Cuarta',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Expresamente acepto que las notificaciones se tendrán por realizadas en la hora y día en que sean puestas en la pestaña de notificaciones de la página web del Módulo de Exentos y en la Bandeja de entrada del correo electrónico que proporcione como lugar para recibir notificaciones',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Quinta',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'El Usuario desde ya manifiesta que está enterado de que si ingresa una gestión al Módulo de Exentos dentro de la cual existen requisitos faltantes, si al término de treinta días calendario no ha presentado los mismos, la gestión será eliminada automáticamente, debiendo iniciar una nueva gestión en el Módulo',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Sexta',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Estoy enterado que el Módulo de Exentos  se implementará en forma gradual, de acuerdo a lo que disponga el INAB, por lo que estoy de acuerdo en que se podrá utilizar la misma dirección electrónica para recibir notificaciones, únicamente en el sitio web del Módulo de Exentos',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Septima',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'El Usuario desde ya manifiesta que la información otorgada es verídica, y por lo tanto autoriza al INAB, para que pueda corroborar la veracidad de toda la información proporcionada, por cualquier medio legal, siendo responsable de lo relativo a los delitos de perjurio y falsedad en caso se llegará a constatar que la información relacionada es parcial o totalmente falsa',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Octava',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Este es un servicio gratuito y exclusivo, que se otorga para todos los usuarios del INAB',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Novena',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'El plazo de este acuerdo de adhesión se interpreta como indefinido, hasta que el INAB decida darlo por terminado, en cualquier momento debiendo dar aviso previo al usuario para su conocimiento. El Usuario podrá dar por terminado el acuerdo con manifestación expresa y expresión de causa por cualquier medio electrónico o por escrito',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Décima',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'El Usuario y el INAB hacen constar que tratarán de encontrar una solución amigable a cualquier controversia que pudiere surgir relativa a la aplicación, interpretación, contravención, terminación o invalidez del presente acuerdo de adhesión. En el caso que las partes no pudieren llegar a una solución amigable dentro de los treinta (30) días siguientes a la notificación efectuada por una de las partes a la otra para tratar de solucionar la controversia, ésta se someterá a la jurisdicción de los Tribunales competentes',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Décima primera',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Este Acuerdo de Adhesión será regido e interpretado por las leyes de la República de Guatemala. El conocimiento y resolución de los conflictos que surjan con motivo de este Acuerdo de Adhesión serán competencia exclusiva de los Tribunales de Justicia del departamento de Guatemala, por lo que el Usuario declara: a) que renuncia al fuero de su domicilio y al de cualquier otra competencia que pudiere corresponderle y se somete a los tribunales competentes del Departamento de Guatemala o cualquier otro tribunal que el INAB elija con motivo de la interpretación y cumplimiento del presente Acuerdo de Adhesión',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Décima segunda',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'El Usuario acepta el contenido íntegro del presente Acuerdo de Adhesión al Módulo de Exentos y de todos los términos que en el constan, por lo que bien enterado, de su contenido, objeto, validez y efectos legales lo acepta y ratifica adhiriéndose a los términos pactados',
                  style: 'izquierda'
                }
              ]
            }
          ];

          content.push('\n');
          content.push(
            {
              text: 'En la ciudad de Guatemala, el ' + strDia + ' de ' + strMes + ' de ' + strAnio,
              style: 'izquierda'
            }
          );
          content.push('\n\n');
          var ahora = new Date();
          content.push(
            {
              text: 'Fecha y hora de impresión: ' + $filter('date')(ahora, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = ""//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              detalle: {
                fontSize: 10
              },
              tableSubHeader: {
                fontSize: 10,
                bold: true
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarFormularioCefe: function (data, datos, total, estado, logo) {
          var fecha = new Date();
          var nombreSalida = 'documento.pdf';
          var rango = '(0 – 70.90)';
          var estadoDesc = 'Malo';
          var color = '#f62100';
          switch (estado) {
            case 2:
              estadoDesc = 'Bueno';
              rango = '(70.91 – 100)';
              color = '#43b01d';
              break;
            default:
              estadoDesc = 'Regular';
              rango = '(0 – 70.90)';
              color = '#e9b247';
              break;
          }
          var body = [
            [
              { text: 'Resultado del Estado de Conservación de los Bosques: ' + estadoDesc + ' ' + rango, style: 'tableSubHeader', alignment: 'left', color: '#FFFFFF', fillColor: color, colSpan: 2 }, {}
            ],
            [
              {
                text: 'Aplica únicamente para la modalidad de:',
                style: 'izquierda',
                fillColor: '#CCCCCC',
                colSpan: 2
              }, {}
            ]
          ];
          switch (estado) {
            case 2:
              body.push(
                [
                  {
                    ol: [
                      {
                        ol: [
                          { text: 'Manejo de Bosque Natural con Fines de Protección', style: 'izquierda' }
                        ]
                      }
                    ], colSpan: 2
                  }, {}
                ]
              );
              break;
            default:
              body.push(
                [
                  {
                    ol: [
                      {
                        ol: [
                          {
                            text: 'Restauración de Tierras Forestales Degradadas',
                            style: 'izquierda'
                          }
                        ]
                      }
                    ], colSpan: 2
                  }, {}
                ]
              );
              break;
          }
          var tbResultado = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [250, 230],
              body: body
            }
          }
          body = [
            [
              { text: 'No.', fontSize: 9, alignment: 'center', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Variables e indicadores', fontSize: 9, alignment: 'center', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Valoración de campo', fontSize: 9, alignment: 'center', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Estado de conservación', fontSize: 9, alignment: 'center', color: '#FFFFFF', fillColor: '#000000' }
            ]
          ];
          for (var i = 0; i < data.length; i++) {
            var item = data[i];
            body.push(
              [
                { text: i + 1, style: 'tableSubHeader', alignment: 'left' },
                { text: item.descripcion, style: 'tableSubHeader', alignment: 'left' },
                { text: item.campo, style: 'tableSubHeader', alignment: 'right' },
                { text: item.estado, style: 'tableSubHeader', alignment: 'right' }
              ]
            );
          }
          body.push(
            [
              { text: 'Estado de conservación del bosque', style: 'tableSubHeader', alignment: 'right', fillColor: '#CCCCCC', colSpan: 3 }, {}, {},
              { text: total, style: 'tableSubHeader', alignment: 'right', fillColor: '#CCCCCC' }
            ]
          );
          tbDivision = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 250, 90, 100],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 60,
                  image: logo,
                  style: 'izquierda'
                },
                {
                  width: 390,
                  text: "\nINSTITUTO NACIONAL DE BOSQUES\nPROGRAMA DE INCENTIVOS PROBOSQUE",
                  style: 'centrar',
                  bold: true,
                  margin: [20, 0, 10, 0]
                }
              ]
            },
            {
              text: 'RESULTADO DE LA EVALUACIÓN DEL \nESTADO DE CONSERVACIÓN DE LOS BOSQUES ESTRATÉGICOS',
              style: 'centrar',
              bold: true
            }, '\n\n',
            {
              text: [
                {
                  text: "Ecosistema/s estratégico/s evaluados/s en el área: ",
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: datos.ecosistema,
                  style: 'izquierda', fontSize: 9
                }
              ]
            },
            {
              text: [
                {
                  text: "Ubicación: ",
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: datos.ubicacion,
                  style: 'izquierda', fontSize: 9
                }
              ]
            },
            {
              columns: [
                {
                  text: [
                    {
                      text: "Área del proyecto a incentivar: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: datos.area,
                      style: 'izquierda', fontSize: 9
                    }
                  ],
                  width: 300
                },
                {
                  text: [
                    {
                      text: "Fecha de evaluación: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: $filter('date')(datos.fechaEvaluacion, 'dd/MM/yyyy'),
                      style: 'izquierda', fontSize: 9
                    }
                  ]
                }
              ]
            }, '\n',
            tbDivision, '\n', tbResultado, '\n\n\n',
            {
              columns: [
                {
                  text: [
                    {
                      text: "Firma: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: '_________________________________',
                      style: 'izquierda', fontSize: 9
                    }
                  ], width: 250
                },
                {
                  text: [
                    {
                      text: "Fecha de entrega: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: '__________________________________',
                      style: 'izquierda', fontSize: 9
                    }
                  ], width: 250
                }
              ]
            },
            {
              columns: [
                {
                  text: [
                    {
                      text: "Elaborador del PMF: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: datos.elaborador,
                      style: 'izquierda', fontSize: 9
                    }
                  ],
                  width: 250
                },
                {
                  text: [
                    {
                      text: "Registro: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: datos.rnf,
                      style: 'izquierda', fontSize: 9
                    }
                  ],
                  width: 150
                }
              ]
            },
            {
              columns: [
                {
                  text: [
                    {
                      text: "Teléfono: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: datos.telefono,
                      style: 'izquierda', fontSize: 9
                    }
                  ],
                  width: 250
                },
                {
                  text: [
                    {
                      text: "correo electrónico: ",
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: datos.correo,
                      style: 'izquierda', fontSize: 9
                    }
                  ]
                }
              ]
            }, '\n\n',
            {
              text: "Firma propietario / representante: ________________________________________________________________________",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              text: 'Fecha y hora de impresión: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = ""//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              detalle: {
                fontSize: 10
              },
              tableSubHeader: {
                fontSize: 10,
                bold: true
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarSolicitudPoa: function (ttSolicitudPoa, usuarioDesc, dataEmpresa) {
          var data = ttSolicitudPoa.ttGestion;
          var ttGestion = data;
          var ttTarea = ttSolicitudPoa.ttTarea;
          var nombreSalida = 'solicitud.pdf';
          var fecha = new Date();
          var representante = "";
          var textoPropietario = "";
          var i, j;
          var textoFinca = "", textoDireccion = "";
          var plural = false;
          if (data.fechaCierre == null) {
            data.fechaCierre = new Date();
          }
          var body = [];
          var tbSolicitante = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          var titulos = {
            areaSolicitada: 'Área solicitada para aprovechar (ha)'
          }
          switch (parseInt(data.tcTipoGestion.tipoGestionId)) {
            case 1:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
            case 2:
              titulos.areaSolicitada = 'Área intervenir por saneamiento (ha)';
            case 3:
              titulos.areaSolicitada = 'Área intervenir por salvamento (ha)';
              break;
            case 4:
              titulos.areaSolicitada = 'Área intervenir con fines científicos (ha)';
              break;
            case 5:
              titulos.areaSolicitada = 'Área intervenir por cambio de uso (ha)';
              break;
            case 7:
              titulos.areaSolicitada = 'Área intervenir con árboles fuera de bosque (ha)';
              break;
            default:
              titulos.areaSolicitada = 'Área solicitada para aprovechar (ha)';
              break;
          }
          if (data.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'II. DATOS GENERALES DEL PROPIETARIO/POSEEDOR:', style: 'tableSubHeader', colSpan: 4, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}
              ],
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttTipoPropietarioGestion.nombreComercial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            tbSolicitante = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
          } else {
            body = [
              [
                { text: 'II. DATOS GENERALES DEL PROPIETARIO/POSEEDOR:', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'left' }, {}, {}
              ],
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Número de Identificación Tributaria (NIT)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var dataPersona = data.personas;
            for (i = 0; i < dataPersona.length; i++) {
              if (dataPersona[i].tcPersona.cui.toString() == dataPersona[i].tcPersona.nit) {
                dataPersona[i].tcPersona.nit = '-';
              }
              item = [
                { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
                { text: dataPersona[i].tcPersona.nit, style: 'detalle', alignment: 'left' }
              ];
              body.push(item);
            }
            tbSolicitante = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [210, 150, 150],
                body: body
              }
            }
          }
          var tbRepresentante = {};
          var hayRepresentante = false;
          if (data.representantes.length > 0) {
            body = [
              [
                { text: 'III. DATOS DE REPRESENTACIÓN LEGAL:', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {}
              ],
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código único de Identificación -CUI-', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            for (var i = 0; i < data.representantes.length; i++) {
              item = [
                { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
                { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left' }
              ];
              body.push(item);
            }
            tbRepresentante = {
              style: 'estiloTabla',
              table: {
                widths: [260, 250],
                body: body
              }
            }
            hayRepresentante = true;
          }
          var roman = 'III';
          if (hayRepresentante) {
            roman = 'IV';
          }
          body = [
            [
              { text: roman + '. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {},
            ],
            [
              { text: 'Tipo de notificación:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Descripción:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' }
            ]
          ];
          var lista = [];
          var i, j;
          var item = [];
          var encontrado = false;
          for (i = 0; i < data.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (data.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(data.notificaciones[i].tcTipoNotificacion);
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(data.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionGestionDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 365],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'I. DATOS GENERALES DEL TERRENO O LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'left' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          var nombreFinca = '';
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#4D4A4C', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            if (i == 0) {
              nombreFinca = finca.tcFinca.fincaDesc;
            } else {
              nombreFinca += ', ' + finca.tcFinca.fincaDesc;
            }
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                propietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            var documentos = finca.documentos;
            var cantidad = documentos.length;
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 2:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' },
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.notario, style: 'detalle', alignment: 'left' },
                      { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc + ', ' + doc.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'No. ', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.folio, style: 'detalle', alignment: 'left' },
                      { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.libro, style: 'detalle', alignment: 'left' }
                    ];
                    body.push(item);
                    item = [
                      { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                if (cantidad > 0) {
                  body.push(item);
                  for (var j = 0; j < cantidad; j++) {
                    var doc = documentos[j];
                    item = [
                      { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: $filter('date')(new Date(doc.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                      { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.numeroDocumento, style: 'detalle', alignment: 'left' },
                      { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                      { text: doc.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                    ];
                    if (j < (cantidad - 1)) {
                      body.push(item);
                    }
                  }
                }
                break;
            }
            body.push(item);
            item = [
              { text: 'Colindancias', style: 'tableSubHeader', colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Norte', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.norte, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Sur', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.sur, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Este', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.este, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Oeste', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.oeste, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            item = [
              { text: 'Área total (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.areaDocumento).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total (Según GPS)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.area).toFixed(4), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var tbFinca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          var representanteNombre = "";
          var row = null;
          var edad = null;
          var textoNotificacion = "EXPONGO";
          var textoExpongo = "";
          var textoComparecer = '; comparezco ante usted y ';
          if (data.representantes.length > 0) {
            if (data.representantes.length > 1) {
              textoNotificacion = ". Señalamos";
              textoExpongo = "EXPONEMOS";
              textoComparecer = '; comparecemos ante usted y ';
            } else {
              textoNotificacion = ". Señalo";
              textoExpongo = "EXPONGO";
            }
          } else {
            if (data.personas.length > 1) {
              textoNotificacion = ". Señalamos";
              textoExpongo = "EXPONEMOS";
              textoComparecer = '; comparecemos ante usted y ';
            } else {
              textoNotificacion = ". Señalo";
              textoExpongo = "EXPONGO";
            }
          }
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            //solo domicilio y oficina
            //si se agrega otro de tipo ubicacion geografica se debe agregar a la condicion
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              if (i == 0) {
                textoNotificacion += " lugar para recibir notificaciones en: " + row.notificacionGestionDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc;
              } else {
                textoNotificacion += ". " + row.notificacionGestionDesc + " municipio de " + row.tcMunicipio.municipioDesc + ", departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc;
              }
            }
          }
          var solicito = 'Solicito';
          var itemsPeticion = [
            { text: "Que se admita para su trámite el presente memorial, los documentos adjuntos y se inicie la formación del expediente administrativo respectivo.", style: 'izquierda' },
            { text: "Que se tenga como lugar para recibir citaciones y  notificaciones en el señalado.", style: 'izquierda' },
            { text: "Que satisfechos los requisitos de ley y técnicos, se apruebe el Plan de Manejo Forestal y se otorgue " + data.tcTipoGestion.tipoGestionDesc + " y Manejo, en finca cuyos derechos son acreditados con los documentos indicados.", style: 'izquierda' }
          ];

          if (data.representantes.length <= 0) {
            if (data.personas.length > 1) {
              solicito = 'Solicitamos';
            }
          }
          if (hayRepresentante) {
            roman = 'V';
          } else {
            roman = 'IV';
          }
          body = [
            [
              { text: roman + '. DETALLES DE INTERVENCION SOLICITADA', style: 'tableSubHeader', colSpan: 4, color: '#FFFFFF', fillColor: '#000000', alignment: 'izquierda' }, {}, {}, {}
            ],
            [
              { text: 'Área total de la finca (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'izquierda' },
              { text: parseFloat(areaFinca).toFixed(4), style: 'tableSubHeader', alignment: 'izquierda' },
              { text: titulos.areaSolicitada, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'izquierda' },
              { text: parseFloat(data.area).toFixed(4), style: 'tableSubHeader', alignment: 'izquierda' }
            ]
          ];
          tbDivision = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [140, 100, 160, 100],
              body: body
            }
          }

          var tcFinca = data.fincas[0].tcFinca;

          var content = [
            {
              text: 'SOLICITUD DE AUTORIZACIÓN DEL SUBSIGUIENTE PLAN OPERATIVO ANUAL',
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'Fecha: ' + fecha.getDate() + ' de ' + getMonth(fecha.getMonth() + 1) + " de " + fecha.getFullYear(),
              style: 'derecha'
            }, '\n',
            {
              text: 'Señor (a)',
              style: 'izquierda'
            },
            {
              text: 'Director Subregional',
              style: 'izquierda'
            },
            {
              text: 'Inab Subregión ' + ttGestion.tcSubregion.subregionDesc + ' ' + ttGestion.tcSubregion.alias,
              style: 'izquierda'
            }, '\n',
            {
              text: 'Por este medio solicito la autorización del ' + ttSolicitudPoa.numero + ' Plan Operativo Anual de la ' + data.tcTipoGestion.tipoGestionDesc.toUpperCase()
                + ', resolución número ' + ttTarea.ttSeguimientoTarea.numeroResolucion
                + '. Dicha petición se realiza de conformidad con la información que se especifican a continuación: ',
              style: 'izquierda'
            }, '\n', tbFinca, '\n', tbSolicitante, '\n', tbRepresentante, '\n', tbNotificacion, '\n', tbDivision,
            '\n',
            {
              text: (hayRepresentante ? 'VI' : 'V') + ". FUNDAMENTO DE DERECHO",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Constitución Política de la República de Guatemala',
                  style: 'izquierda',
                  bold: true
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: 'Artículo 28. Derecho de Petición. "Los habitantes de la República de Guatemala tienen derecho a dirigir, individual o colectivamente, peticiones a la autoridad, la que está obligada a tramitarlas y deberá resolverlas conforme a la ley…"',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            {
              text: (hayRepresentante ? 'VII' : 'VI') + ". PETICIÓN ESPECÍFICA",
              style: 'izquierda',
              bold: true
            }, '\n',
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: '1.',
                  width: 20
                },
                {
                  text: 'Que se admita para su trámite la presente solicitud',
                  style: 'izquierda'
                }
              ]
            },
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: '2.',
                  width: 20
                },
                {
                  text: 'Que se tenga como lugar para recibir citaciones y notificaciones el señalado.',
                  style: 'izquierda'
                }
              ]
            }
          ];
          var n = '4';
          if (data.ttPlanificacionGestion.hayReforestacion == 1 && data.tcTipoGestion.tipoGestionId != 2 && data.tcTipoGestion.tipoGestionId != 3) {
            content.push(
              {
                columns: [
                  {
                    text: '',
                    width: 20
                  },
                  {
                    text: '3.',
                    width: 20
                  },
                  {
                    text: solicito + ' garantizar el cumplimiento de la obligación de repoblación forestal mediante: ' + data.ttResumenGestion.tcTipoGarantia.tipoGarantiaDesc,
                    style: 'izquierda'
                  }
                ]
              }
            );
          } else {
            n = '3';
          }
          content.push(
            {
              columns: [
                {
                  text: '',
                  width: 20
                },
                {
                  text: n + '.',
                  width: 20
                },
                {
                  text: 'Que satisfechos los requisitos técnicos y legales, se apruebe el Plan Operativo Anual correspondiente al turno número '
                    + ttSolicitudPoa.numero + ' según Licencia Forestal número ' + ttTarea.ttSeguimientoTarea.numeroResolucion,
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push('\n\n\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small',
              alignment: 'right'
            }
          );
          var textoFooter = 'Codigo: LI-RE-026\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              detalle: {
                fontSize: 10
              },
              tableSubHeader: {
                fontSize: 9,
                bold: true
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarOficioRequisitoPoa: function (data, strDiaResolucion, strAnioResolucion, usuarioDesc, dataEmpresa, sigla, esPoa, noTurno) {
          if (esPoa == null) {
            esPoa = true;
          }
          var nombreSalida = 'oficio.pdf';
          var ttSolicitudPoa = null;
          var ttTarea = null;
          var ttGestion = null;
          var textoAutorizar = {};
          var tituloOficio = {};
          var fincas = data.ttGestion.fincas;
          var textoFinca = '';
          if (fincas == null) {
            fincas = [];
          }
          if (fincas.length > 0) {
            var finca = fincas[0];
            textoFinca = ", del inmueble ubicado en el municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
          }
          if (esPoa) {
            ttSolicitudPoa = data;
            ttTarea = ttSolicitudPoa.ttTarea;
            ttGestion = ttSolicitudPoa.ttGestion;
            noTurno = ttSolicitudPoa.numero;
            textoAutorizar = {
              text: 'Por este medio le informo que para que ésta Dirección Subregional, pueda autorizar el turno '
                + noTurno + 'o. Plan Operativo Anual, según Licencia de Aprovechamiento Forestal número '
                + ttTarea.ttPadre.ttSeguimientoTarea.numeroResolucion + textoFinca + '; es necesario que cumpla con los requisitos siguientes:',
              style: 'izquierda'
            };
            tituloOficio = {
              text: "OFICIO SUBREGIONAL DE REQUISITOS PARA LA AUTORIZACIÓN DEL SUBSIGUIENTE PLAN OPERATIVO ANUAL",
              style: 'centrar'
            };
          } else {
            ttTarea = data;
            ttGestion = data.ttGestion;
            textoAutorizar = {
              text: 'Por este medio le informo que para que ésta Dirección Subregional, pueda autorizar la ' + ttGestion.tcTipoGestion.tipoGestionDesc
                + ' correspondiente al turno número ' + noTurno + ', es necesario que cumpla con los requisitos siguientes:',
              style: 'izquierda'
            };
            tituloOficio = {
              text: "OFICIO SUBREGIONAL DE REQUISITOS PARA LA AUTORIZACIÓN DE " + ttGestion.tcTipoGestion.tipoGestionDesc.toUpperCase(),
              style: 'centrar'
            };
          }
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var i, j;
          var solicitante = "";
          var sinDpi = "";
          var correo = ttGestion.tcElaborador.correo.trim().toLowerCase();
          var correos = [correo];
          if (ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 1) {
            for (i = 0; i < ttGestion.personas.length; i++) {
              item = ttGestion.personas[i];
              correo = item.tcPersona.correo.trim().toLowerCase();
              var encontrado = false;
              for (j = 0; j < correos.length; j++) {
                var correoTmp = correos[j];
                if (correoTmp == correo) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                correos.push(correo);
              }
              if (i == 0) {
                solicitante = item.tcPersona.personaDesc;
              } else {
                solicitante += ", " + item.tcPersona.personaDesc;
              }
            }
          } else {
            solicitante = ttGestion.ttTipoPropietarioGestion.razonSocial;
          }
          var i, j;
          var representanteNombre = "";
          var tipoGestionDesc = ttGestion.tcTipoGestion.tipoGestionDesc;
          tipoGestionDesc = tipoGestionDesc.replace('aprovechamiento', 'manejo');
          if (ttGestion.tcTipoGestion.tipoGestionId == 5) {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan de aprovechamiento con fines');
          } else {
            tipoGestionDesc = tipoGestionDesc.replace('Licencia', 'plan');
          }
          if (ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = ttGestion.ttTipoPropietarioGestion.razonSocial;
          }
          if (ttGestion.representantes == null) {
            ttGestion.representantes = [];
          }
          if (ttGestion.representantes.length > 0) {
            for (i = 0; i < ttGestion.representantes.length; i++) {
              item = ttGestion.representantes[i];
              correo = item.tcPersona.correo.trim().toLowerCase();
              var encontrado = false;
              for (j = 0; j < correos.length; j++) {
                var correoTmp = correos[j];
                if (correoTmp == correo) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                correos.push(correo);
              }
              if (i == 0) {
                representanteNombre = ttGestion.representantes[i].tcPersona.personaDesc;
              } else {
                representanteNombre = representanteNombre + ", " + ttGestion.representantes[i].tcPersona.personaDesc;
              }
            }
          }
          var notificaciones = ttGestion.notificaciones;
          if (notificaciones == null) {
            notificaciones = [];
          }
          for (var i = 0; i < notificaciones.length; i++) {
            item = notificaciones[i];
            if (item.tcTipoNotificacion.tipoNotificacionId == 5) {
              correo = item.notificacionGestionDesc.trim().toLowerCase();
              var encontrado = false;
              for (j = 0; j < correos.length; j++) {
                var correoTmp = correos[j];
                if (correoTmp == correo) {
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                correos.push(correo);
              }
            }
          }
          var strCorreos = "";
          for (i = 0; i < correos.length; i++) {
            if (i == 0) {
              strCorreos = correos[i];
            } else {
              strCorreos += ', ' + correos[i];
            }
          }
          var content = [
            tituloOficio, '\n\n',
            {
              text: "Oficio No. " + ttTarea.ttSeguimientoTarea.codigo,
              style: 'derecha'
            }, '\n\n',
            {
              text: "DIRECCIÓN SUBREGIONAL " + ttGestion.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + ttGestion.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE "
                + ttGestion.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ". INSTITUTO NACIONAL DE BOSQUES –INAB–, " + strDiaResolucion + " DIAS DEL MES DE "
                + getMonth(mes).toUpperCase() + " DE " + strAnioResolucion,
              style: 'izquierda'
            }, '\n',
            {
              text: 'Señor (a): ' + solicitante,
              style: 'izquierda'
            }, '\n',
            textoAutorizar
          ];
          content.push('\n');
          valorTurno = 0;
          var rodales = ttGestion.rodal;
          var tmpData = [];
          for (var i = 0; i < rodales.length; i++) {
            var rodal = rodales[i];
            if (parseInt(rodal.turno) == noTurno) {
              tmpData.push(rodal);
            }
          }
          body = [
            [
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Volumen (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Volumen Total (m³)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Valor madera en pie (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: 'Valor Total (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '10% a pagar (Q)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          totales = {
            troza: 0,
            lenia: 0,
            volrExtraer: 0,
            trozaValor: 0,
            leniaValor: 0,
            totalValor: 0,
            totalPagar: 0
          }
          var exonerarValorMadera = false;
          var strRodales = '';
          for (i = 0; i < tmpData.length; i++) {
            var bodyEspecie = [];
            var especies = tmpData[i].especies;
            if (!tmpData[i].valorMadera) {
              if (strRodales.length > 0) {
                strRodales += ', ' + tmpData[i].correlativo;
              } else {
                strRodales = tmpData[i].correlativo;
              }
              exonerarValorMadera = true;
            }
            for (j = 0; j < especies.length; j++) {
              var especie = especies[j];
              especie.totalValor = parseFloat(especie.ttEspecieEvaluada.trozaValor) + parseFloat(especie.ttEspecieEvaluada.leniaValor);
              especie.totalValor = parseFloat(especie.totalValor).toFixed(2);
              especie.totalPagar = 0;
              if (tmpData[i].valorMadera) {
                especie.totalPagar = especie.totalValor * 0.1;
                especie.totalPagar = parseFloat(especie.totalPagar).toFixed(2);
                totales.totalPagar += parseFloat(especie.totalPagar);
              }
              itemEspecie = [
                { text: especie.tcEspecie.nombreCientifico, style: 'small', alignment: 'left' },
                { text: parseFloat(especie.troza).toFixed(4), style: 'small', alignment: 'right' },
                { text: parseFloat(especie.lenia).toFixed(4), style: 'small', alignment: 'right' },
                { text: parseFloat(especie.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
                { text: formatNumber.new(especie.ttEspecieEvaluada.trozaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(especie.ttEspecieEvaluada.leniaValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(especie.totalValor), style: 'small', alignment: 'right' },
                { text: formatNumber.new(especie.totalPagar), style: 'small', alignment: 'right' }
              ];
              bodyEspecie.push(itemEspecie);
              totales.troza += parseFloat(especie.troza);
              totales.lenia += parseFloat(especie.lenia);
              totales.volrExtraer += parseFloat(especie.volrExtraer);
              totales.trozaValor += parseFloat(especie.ttEspecieEvaluada.trozaValor);
              totales.leniaValor += parseFloat(especie.ttEspecieEvaluada.leniaValor);
              totales.totalValor += parseFloat(especie.totalValor);
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [48, 45, 45, 45, 45, 45, 45, 45],
                body: bodyEspecie
              }, colSpan: 8, layout: 'noBorders'
            }
            item = [
              { text: tmpData[i].turno, style: 'small', alignment: 'left' },
              { text: tmpData[i].correlativo, style: 'small', alignment: 'left' },
              tbEspecie, {}, {}, {}, {}, {}, {}, {}
            ];
            body.push(item);
          }
          item = [
            { text: "Total", style: 'small', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
            { text: parseFloat(totales.troza).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.lenia).toFixed(4), style: 'small', alignment: 'right' },
            { text: parseFloat(totales.volrExtraer).toFixed(4), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.trozaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.leniaValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalValor).toFixed(2)), style: 'small', alignment: 'right' },
            { text: formatNumber.new(parseFloat(totales.totalPagar).toFixed(2)), style: 'small', alignment: 'right' }
          ];
          body.push(item);
          var tbValorMadera = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [30, 30, 50, 45, 45, 45, 45, 45, 45, 45],
              body: body
            }
          };
          content.push(
            {
              ol: [
                {
                  text: [
                    {
                      text: 'Pago del valor de la madera en pié \n',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: "Deberá cancelar la cantidad de Q. " + formatNumber.new(parseFloat(totales.totalPagar).toFixed(2))
                        + " correspondiente al 10% del valor de la madera en pié, por el aprovechamiento del turno número "
                        + noTurno + ", de acuerdo al siguiente cuadro: ",
                      style: 'izquierda'
                    }
                  ]
                }
              ]
            }
          );
          content.push('\n');
          content.push(tbValorMadera);
          if (exonerarValorMadera) {
            content.push(
              {
                type: "none",
                ul: [
                  {
                    text: 'Se exceptúa de este pago a los rodales: ' + strRodales + '; de acuerdo con el artículo 87 de la Ley Forestal, inciso b.',
                    style: 'small'
                  }
                ]
              }
            );
          }
          content.push('\n');
          content.push(
            {
              type: "none",
              ul: [
                {
                  text: "Después de hacer el depósito a nombre del Fondo Forestal Privativo del INAB deberá entregar la constancia a la Dirección Subregional correspondiente para el trámite de la emisión del recibo 63-A de la Contraloría General de Cuentas de la Nación.",
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          var tipoGarantiaDesc = '';
          if (esPoa) {
            tcTipoGarantia = ttSolicitudPoa.tcTipoGarantia;
          } else {
            tcTipoGarantia = ttGestion.ttResumenGestion.tcTipoGarantia;
          }
          var start = 2;
          if (tcTipoGarantia != null) {
            content.push(
              {
                start: start,
                ol: [
                  {
                    text: [
                      {
                        text: 'Garantía de Repoblación Forestal',
                        style: 'izquierda',
                        bold: true
                      },
                      {
                        text: "\nDeberá presentar el documento de garantía de " + tcTipoGarantia.tipoGarantiaDesc
                          + " para garantizar el cumplimiento de la obligación de repoblación forestal de acuerdo al cuadro siguiente ",
                        style: 'izquierda'
                      }
                    ]
                  }
                ]
              }
            );
            content.push('\n');
            start++;
          }
          content.push('\n');
          body = [
            [
              { text: 'Año de plantación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Especie', style: 'tableHeaderSmall', alignment: 'center' },
              { text: 'Documento de garantía', style: 'tableHeaderSmall', alignment: 'center' },
              { text: 'Monto total del compromiso en Q.', style: 'tableHeaderSmall', alignment: 'center' }
            ]
          ];
          var tmpCompromiso = ttGestion.rodal;
          var abrTotal = 0, abrExtraerTotal = 0;
          dataCompromiso = [];
          for (let i = 0; i < tmpCompromiso.length; i++) {
            var tmpRow = tmpCompromiso[i];
            if (noTurno == tmpRow.turno) {
              dataCompromiso.push(tmpRow);
            }
          }
          for (i = 0; i < dataCompromiso.length; i++) {
            var abrExtraer = 0;
            var abr = 0;
            for (j = 0; j < dataCompromiso[i].especies.length; j++) {
              abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
              abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
            }
            dataCompromiso[i].metodoCalculoDesc = calculoMonto;
            dataCompromiso[i].abr = abr;
            dataCompromiso[i].abrExtraer = abrExtraer;
            abrTotal += abr;
            abrExtraerTotal += abrExtraer;
          }
          var porcentaje = 0;
          porcentaje = abrExtraerTotal * 100;
          porcentaje = porcentaje / abrTotal;
          porcentaje = Math.round(porcentaje);
          var montoUtilizar = ttTarea.ttSeguimientoTarea.monto;
          var areaMonto = 0;
          var montoCompromiso = 0;
          var volumenTotal = 0;
          var area = 0;
          var montoTotal = 0;
          var areaGarantia = 0;
          var areaTotal = 0;
          var hayFueraBosque = false;
          var hayReforestacion = true;
          var tipoGarantiaDesc = 'No se proporcionó una garantía';
          var calculoMonto = 'área por área';
          if (tcTipoGarantia == null) {
            if (ttGestion.ttPlanificacionGestion.hayReforestacion == 0) {
              tcTipoGarantia = {
                tipoGarantiaDesc: 'Pago al Fondo Forestal Privativo'
              };
            }
          } else {
            tipoGarantiaDesc = tcTipoGarantia.tipoGarantiaDesc;
          }
          var tbRepoblacion = {};
          totalAreaCompromiso = 0;
          var rodalesRepoblacion = ttGestion.rodalesRepoblacion;
          if (rodalesRepoblacion.length > 0) {
            for (i = 0; i < rodalesRepoblacion.length; i++) {
              var rodalRepoblacion = rodalesRepoblacion[i];
              if (rodalRepoblacion.turno == noTurno) {
                rodalRepoblacion.areaGarantia = rodalRepoblacion.area;
                if (ttGestion.tcTipoGestion.tipoGestionId == 7) {
                  hayFueraBosque = true;
                  calculoMonto = 'área basal ideal';
                } else {
                  if (ttGestion.tcTipoGestion.tipoGestionId != 5) {
                    for (j = 0; j < dataCompromiso.length; j++) {
                      var rodal = dataCompromiso[j];
                      if (rodal.correlativo == rodalRepoblacion.correlativo) {
                        if (rodal.tcTipoCobertura.tipoCoberturaId == 3) {
                          hayFueraBosque = true;
                          calculoMonto = 'área basal ideal';
                        }
                        rodalRepoblacion.areaGarantia = rodal.areaGarantia;
                        break;
                      }
                    }
                  }
                }
                if (!hayFueraBosque) {
                  calculoMonto = 'área basal';
                }
                areaGarantia = parseFloat(rodalRepoblacion.areaGarantia).toFixed(4);
                var montoGarantia = parseFloat(areaGarantia) * parseFloat(montoUtilizar);
                montoGarantia = parseFloat(montoGarantia).toFixed(2);
                rodalRepoblacion.montoCompromiso = montoGarantia;
                montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                totalAreaCompromiso += parseFloat(rodalRepoblacion.area);
                areaTotal = areaTotal + parseFloat(areaGarantia);
                var strEspecies = '';
                for (j = 0; j < rodalRepoblacion.repoblacion.length; j++) {
                  if (j == 0) {
                    strEspecies = rodalRepoblacion.repoblacion[j].tcEspecie.nombreCientifico + '\n(' + rodalRepoblacion.repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                  } else {
                    strEspecies += '\n' + rodalRepoblacion.repoblacion[j].tcEspecie.nombreCientifico + '\n(' + rodalRepoblacion.repoblacion[j].tcSistemaRepoblacion.sistemaRepoblacionDesc + ')';
                  }
                }
                item = [
                  { text: rodalRepoblacion.anioPlantacion, style: 'tableRowSmall', alignment: 'left' },
                  { text: parseFloat(rodalRepoblacion.area).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
                  { text: strEspecies, style: 'tableRowSmall', alignment: 'left' },
                  { text: tcTipoGarantia == null ? 'N/A' : tcTipoGarantia.tipoGarantiaDesc, style: 'tableRowSmall', alignment: 'left' },
                  { text: formatNumber.new(rodalRepoblacion.montoCompromiso), style: 'tableRowSmall', alignment: 'right' }
                ];
                body.push(item);
              }
            }
            montoTotal = parseFloat(montoTotal).toFixed(2);
            areaGarantia = parseFloat(areaTotal).toFixed(4);
            areaTotal = parseFloat(areaTotal).toFixed(4);
            totalAreaCompromiso = parseFloat(totalAreaCompromiso).toFixed(4);
            item = [
              { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: totalAreaCompromiso, style: 'tableRowSmall', alignment: 'right' },
              { text: 'TOTAL', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
              { text: formatNumber.new(montoTotal), style: 'tableRowSmall', alignment: 'right' }
            ];
            body.push(item);
            tbRepoblacion = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [50, 50, 150, 120, 100],
                body: body
              }
            };
            if (areaGarantia == totalAreaCompromiso) {
              calculoMonto = 'área por área';
            }
            content.push(tbRepoblacion);
            content.push(
              {
                text: 'El monto de la garantía fue calculado con base a ' + calculoMonto + ' lo que equivale a ' + areaGarantia + ' ha.',
                style: 'izquierdaPequenio', bold: true
              }
            );
          } else {
            if (ttGestion.ttPlanificacionGestion.hayReforestacion == 0) {
              body = [];
              var item = [
                { text: 'Área de compromiso', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
                { text: 'Tipo de garantía', fillColor: '#CCCCCC', style: 'small', alignment: 'center' },
                { text: 'Monto', style: 'small', fillColor: '#CCCCCC', alignment: 'center' }
              ];
              body.push(item);
              var totalAreaMonto = 0;
              dataCompromiso = rodales;
              for (i = 0; i < dataCompromiso.length; i++) {
                totalAreaMonto += dataCompromiso[i].areaGarantia;
              }
              var totalMonto = totalAreaMonto * appSettings.montoFondo;
              totalMonto = parseFloat(totalMonto).toFixed(2);
              item = [
                { text: parseFloat(totalAreaMonto).toFixed(4), style: 'small', alignment: 'center' },
                { text: tcTipoGarantia.tipoGarantiaDesc, style: 'small', alignment: 'center' },
                { text: 'Q. ' + formatNumber.new(totalMonto), style: 'small', alignment: 'center' }
              ];
              body.push(item);
              tbRepoblacion = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [120, 250, 100],
                  body: body
                }
              };
              content.push(tbRepoblacion);
            }
          }
          content.push('\n');
          var body = [
            [
              { text: 'Turno', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Rodal', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Año de operación', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Área aprobada (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Especie', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: 'Volumen m³/ha', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: 'Área de compromiso (ha)', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 }
            ],
            [
              '', '', '', '', '',
              { text: 'Troza', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Total', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              ''
            ]
          ];
          tmpData = ttGestion.rodal;
          var valorTurno = 0;
          var total = 0;
          var totales = {
            area: 0,
            lenia: 0,
            troza: 0,
            total: 0,
            areaAprobada: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            row = tmpData[i];
            var bodyEspecie = [];
            for (j = 0; j < row.especies.length; j++) {
              row.especies[j].troza = parseFloat(row.especies[j].troza).toFixed(4);
              row.especies[j].lenia = parseFloat(row.especies[j].lenia).toFixed(4);
              total = parseFloat(row.especies[j].troza) + parseFloat(row.especies[j].lenia);
              itemEspecie = [
                { text: row.especies[j].tcEspecie.nombreCientifico, style: 'tableRowSmall', alignment: 'left' },
                { text: row.especies[j].troza, style: 'tableRowSmall', alignment: 'right' },
                { text: row.especies[j].lenia, style: 'tableRowSmall', alignment: 'right' },
                { text: parseFloat(total).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
              ]
              totales.lenia += parseFloat(row.especies[j].lenia);
              totales.troza += parseFloat(row.especies[j].troza);
              totales.total += total;
              bodyEspecie.push(itemEspecie);
              if (tmpData[i].turno == 1) {
                if (row.especies[j].ttEspecieEvaluada == null) {
                  swal('El dictamen técnico está incompleto o no existe, favor de verificar');
                  return;
                }
                valor = parseFloat(row.especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(row.especies[j].ttEspecieEvaluada.leniaValor);
                valor = parseFloat(valor).toFixed(2);
                tmpData[i].especies[j].totalValor = valor;
                valor = valor * 0.1;
                valorTurno += valor;
                valor = parseFloat(valor).toFixed(2);
                tmpData[i].especies[j].totalPagar = valor;
              }
            }
            var tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [95, 50, 50, 50],
                body: bodyEspecie
              }, colSpan: 4, layout: 'noBorders'
            }
            var area = row.tcTipoCobertura.tipoCoberturaId == 3 ? row.areaGarantia : row.area;
            item = [
              { text: row.turno, style: 'tableRowSmall', alignment: 'left' },
              { text: row.correlativo, style: 'tableRowSmall', alignment: 'left' },
              { text: row.anio, style: 'tableRowSmall', alignment: 'left' },
              { text: parseFloat(row.area).toFixed(4), style: 'tableRowSmall', alignment: 'right' },
              tbEspecie, {}, {}, {},
              { text: parseFloat(area).toFixed(4), style: 'tableRowSmall', alignment: 'right' }
            ];
            body.push(item);
            totales.area += area;
            totales.areaAprobada += row.area;
          }
          body.push(
            [
              { text: 'TOTALES', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: parseFloat(totales.areaAprobada).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: '', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: parseFloat(totales.troza).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(totales.total).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' },
              { text: parseFloat(totales.area).toFixed(4), style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'right' }
            ]
          );
          valorTurno = parseFloat(valorTurno).toFixed(2);
          var tbCompromiso = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 2,
              widths: [30, 40, 30, 40, 100, 50, 50, 50, 50],
              body: body
            }
          };
          if (totales.total > 100) {
            content.push(
              {
                start: start,
                ol: [
                  {
                    text: [
                      {
                        text: 'Documento legal de contratación de Regencia Forestal',
                        style: 'izquierda',
                        bold: true
                      },
                      {
                        text: "\nDeberá presentar documento legal de contratación de Regencia Forestal, el cual debe estar vigente de conformidad con la vigencia del Plan de Manejo Forestal",
                        style: 'izquierda'
                      }
                    ]
                  }
                ]
              }
            );
            start++;
            content.push('\n');
          }
          content.push(
            {
              start: start,
              ol: [
                {
                  text: [
                    {
                      text: 'Fotocopia del Registro de inscripción de motosierras',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: "\nDeberá presentar fotocopia de la inscripción ante el Registro Nacional Forestal de la o las motosierras, que serán utilizadas en la fase de aprovechamiento",
                      style: 'izquierda'
                    }
                  ]
                }
              ]
            }
          );
          start++;
          content.push('\n');
          if (!esPoa) {
            content.push(
              {
                start: start,
                ol: [
                  {
                    text: [
                      {
                        text: 'Notas de Envío aprobadas: ' + ttTarea.ttSeguimientoTarea.numero,
                        style: 'izquierda',
                        bold: true
                      },
                      {
                        text: '\nPrevio a recoger las notas de envío autorizadas usted deberá cancelar al Fondo Forestal Privativo INAB, número de cuenta 3033330062 de Banrural, la cantidad de Q. '
                          + formatNumber.new(parseFloat(ttTarea.ttSeguimientoTarea.volumen).toFixed(2)),
                        style: 'izquierda'
                      }
                    ]
                  }
                ]
              }
            );
            start++;
            content.push('\n');
          }
          content.push(
            {
              text: "Especies, área y volumen del turno: " + noTurno,
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(tbCompromiso);
          content.push('\n');
          content.push(
            {
              text: "Todos los requisitos establecidos en el presente oficio deberán adjuntarse en el módulo de manejo forestal y deberán ser presentados físicamente en la Dirección Subregional correspondiente",
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + ' ' + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director subregional ",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Subregión " + ttGestion.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreos + " el día " + getNameDayOfWeek(fechaFinalizacion.getDay())
                + " " + fechaFinalizacion.getDate() + " de " + getMonth(mes) + " de " + fechaFinalizacion.getFullYear() + " a las " + $filter('date')(fechaFinalizacion, 'HH:mm') + " horas",
              style: 'small', alignment: 'left'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = 'Codigo: LI-RE-027\nVersión: 2';
          if (esPoa) {
            textoFooter = 'Codigo: LI-RE-019\nVersión: 2';
          } else {
            textoFooter = 'Codigo: LI-RE-076\nVersión: 2';
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 120, 50, 50],
            footer: {
              margin: [50, 10, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  width: 250,
                  fontSize: 7
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11.5,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11.5,
                alignment: 'justify'
              },
              izquierdaPequenio: {
                fontSize: 9,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarConstanciaRecepcionAvaluo: function (ttAvaluo, usuario, dataEmpresa) {
          var nombreSalida = 'constancia.pdf';
          var fechaRecepcion = new Date(ttAvaluo.fechaSolicitud);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          body = [
            [
              { text: 'I. DATOS GENERALES DEL EXPEDIENTE', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ],
            [
              { text: 'Lugar y Fecha', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttAvaluo.tcSubregion.tcMunicipio.municipioDesc + ', ' + fechaRecepcion.getDate() + ' de ' + getMonth(mes) + ' de ' + fechaRecepcion.getFullYear(), style: 'izquierda' }
            ],
            [
              { text: 'No. de expediente', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttAvaluo.expediente, style: 'izquierda' }
            ],
            [
              { text: 'Número de Folios entregados (inclusive)', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttAvaluo.folios, style: 'izquierda' }
            ],
            [
              { text: 'Nombre del propietario:', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttAvaluo.tcInstitucionDependencia.institucionDependenciaDesc, style: 'izquierda' }
            ],
            [
              { text: 'Nombre del solicitante:', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttAvaluo.personaDesc, style: 'izquierda' }
            ],
            [
              { text: 'II. Datos de notificación', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000', colSpan: 2 }, {}
            ]
          ];
          if (ttAvaluo.telefono != null) {
            if (ttAvaluo.telefono.length > 0) {
              body.push(
                [
                  { text: 'Teléfono:', style: 'izquierda', fillColor: '#CCCCCC' },
                  { text: ttAvaluo.telefono, style: 'izquierda' }
                ]
              );
            }
          }
          if (ttAvaluo.correo != null) {
            if (ttAvaluo.correo.length > 0) {
              body.push(
                [
                  { text: 'Correo electrónico:', style: 'izquierda', fillColor: '#CCCCCC' },
                  { text: ttAvaluo.correo, style: 'izquierda' }
                ]
              );
            }
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [190, 310],
              body: body
            }
          }
          //tabla codigos
          body = [
            [
              { text: 'III.', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Gestión Administrativa', style: 'izquierda', colSpan: 3, color: '#FFFFFF', fillColor: '#000000' }, {}, {}
            ],
            [
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción de la gestión', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción específica de la gestión', style: 'izquierda', fillColor: '#CCCCCC' }
            ],
            [
              { text: ttAvaluo.tcTipoGestion.tcModulo.codigo, style: 'centrar' },
              { text: ttAvaluo.tcTipoGestion.tcModulo.moduloDesc, style: 'izquierda' },
              { text: ttAvaluo.tcTipoGestion.codigo, style: 'izquierda' },
              { text: ttAvaluo.tcTipoGestion.tipoGestionDesc, style: 'izquierda' }
            ]
          ];
          var tablaCodigos = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 200, 50, 200],
              body: body
            }
          }
          //tabla documentos recibidos
          body = [
            [
              { text: 'IV. DOCUMENTOS RECIBIDOS.', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ],
            [
              { text: 1, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Oficio de caso o causa', style: 'izquierda', alignment: 'left' }
            ]
          ];
          var tablaRequisito = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 480],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 60,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            },
            {
              text: "CONSTANCIA DE RECEPCIÓN DE DOCUMENTOS",
              style: 'centrar'
            }, '\n',
            {
              text: "DIRECCIÓN SUBREGIONAL: " + ttAvaluo.tcSubregion.subregionDesc,
              style: 'derecha'
            },
            {
              text: "EXPEDIENTE No. " + ttAvaluo.expediente,
              style: 'derecha'
            }, '\n',
            tablaGenerales, '\n', tablaCodigos, '\n', tablaRequisito, '\n',
            {
              text: "El solicitante debe considerar que la presente no constituye un documento para iniciar con el trámite del expediente y toda vez que el mismo no contenga los requisitos legales, no podrá ser objeto de trámite correspondiente",
              style: 'izquierda'
            }, '\n',
            {
              text: 'Impreso por: ' + usuario + '\t' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                bold: true,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaAvaluo: function (ttTarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttAvaluo = ttTarea.ttAvaluo;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoTecnico = 'Técnico Forestal';
          var al = 'al';
          if (ttTarea.colaboradores == null) {
            ttTarea.colaboradores = [];
          }
          if (ttTarea.colaboradores.length > 0) {
            textoTecnico = 'Técnicos Forestales';
            al = 'a los';
          }
          var conteo = ttTarea.colaboradores.length;
          var tecnicoNombre = ttTarea.tcPersonaTraslado.personaDesc;
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttTarea.colaboradores[i];
            tecnicoNombre += ', ' + persona.tcPersonaAsignada.personaDesc;
          }
          var firmaTecnico = {};
          firmaTecnico = {
            text: '\n\n' + textoTecnico + ': Fecha:__________ Firma: __________',
            style: 'small', alignment: 'right'
          };
          if (ttTarea.codigo == null) {
            ttTarea.codigo = '---';
          }
          var abecedario = 'abcdefghijklmnopqrstuvwxyz';
          var posicionAbecedario = 0;
          var literal = abecedario[posicionAbecedario];
          var detalle = ttAvaluo.detalle;
          var peticion = 'acompañamiento para la identificación de volumetría, avalúo y licitud de tala';
          if (detalle != null) {
            peticion = '';
            for (var i = 0; i < detalle.length; i++) {
              var row = detalle[i];
              if (i == 0) {
                peticion = literal + ') ' + row.descripcion;
              } else {
                peticion += '; ' + literal + ') ' + row.descripcion;
              }
              posicionAbecedario++;
              literal = abecedario[posicionAbecedario];
            }
          }
          var asunto = {
            text: ttAvaluo.personaDesc + ' solicita al INAB: ' + peticion
              + ' en ' + ttAvaluo.direccion + ' de ' + ttAvaluo.tcMunicipio.municipioDesc + ', '
              + ttAvaluo.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda'
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttAvaluo.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttAvaluo.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttAvaluo.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttAvaluo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    }, asunto
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + ttTarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    {
                      text: 'Trasládese el expediente administrativo a ' + tecnicoNombre
                        + ', para que se sirva(n) atender lo requerido por el(la) ' + ttAvaluo.personaDesc, style: 'izquierda'
                    },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttAvaluo.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarDictamenAvaluo: function (ttTarea, usuario, esSubregional, dataEmpresa, sigla) {
          var ttTareaSubregional = null;
          var dictamenNumero = null;
          var tituloDictamen = 'DICTAMEN TÉCNICO DE CUANTIFICACIÓN DE DAÑOS';
          var nombreSalida = 'dictamen.pdf';
          var dirigido = null;
          var saludo = null;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          if (esSubregional) {
            ttTareaSubregional = ttTarea;
            ttTarea = ttTarea.ttPadre;
          }
          var ttAvaluo = ttTarea.ttAvaluo;
          var ttAvaluoInformacion = ttAvaluo.ttAvaluoInformacion;
          var colaboradores = ttTarea.tcPersonaAsignada.personaDesc;
          var los = 'el/la';
          var tecnicos = 'técnico forestal';
          var informaron = 'informa';
          var firma = ttTarea.tcPersonaAsignada.personaDesc;
          var puesto = "Técnico forestal, subregión ";
          for (i = 0; i < ttTarea.colaboradores.length; i++) {
            los = 'los';
            tecnicos = 'técnicos forestales';
            informaron = 'informaron';
            var row = ttTarea.colaboradores[i];
            colaboradores += ', ' + row.tcPersonaAsignada.personaDesc;
          }
          if (esSubregional) {
            tituloDictamen = 'INFORME SUBREGIONAL DE CUANTIFICACIÓN DE DAÑOS';
            dictamenNumero = 'Oficio número: ' + ttTareaSubregional.codigo;
            nombreSalida = 'oficio.pdf';
            firma = ttTareaSubregional.tcPersonaAsignada.personaDesc;
            puesto = 'Director subregional, subregión ';
            dirigido = {
              text: ttAvaluo.personaDesc
                + '\n' + ttAvaluo.tcInstitucionDependencia.institucionDependenciaDesc + "\n"
                + ttAvaluo.tcMunicipioDependencia.municipioDesc + ", "
                + ttAvaluo.tcMunicipioDependencia.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            };
            saludo = {
              text: 'Respetuosamente me dirijo a usted deseándole salud y bienestar personal en la dirección de sus actividades. Por este medio estoy dando respuesta a su solicitud de avalúo en aprovechamiento realizado en el lugar: '
                + ttAvaluo.direccion + ', ' + ttAvaluo.tcMunicipio.municipioDesc + ', ' + ttAvaluo.tcMunicipio.tcDepartamento.departamentoDesc
                + '. Le informo que la cuantificación de daños (avalúo) fue realizada por ' + los + ' ' + tecnicos + ': '
                + colaboradores + ' el cual mediante dictamen técnico número ' + ttTarea.codigo + ' ' + informaron + ' sobre el avalúo realizado:',
              style: 'izquierda'
            };
          } else {
            dictamenNumero = 'Dictamen número: ' + ttTarea.codigo;
            dirigido = {
              text: ttTarea.tcPersonaTraslado.personaDesc + "\nDirector Subregional "
                + ttAvaluo.tcSubregion.subregionDesc + "\nINAB, " + ttAvaluo.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttAvaluo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            };
            saludo = {
              text: 'Respetuosamente me dirijo a usted deseándole salud y bienestar personal en la dirección de sus actividades. Por este medio remito el dictamen técnico sobre la cuantificación de daños (avalúo) en aprovechamiento realizado en el lugar: ' + ttAvaluo.direccion + ', ' + ttAvaluo.tcMunicipio.municipioDesc + ', ' + ttAvaluo.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda'
            };
          }
          var especies = ttAvaluo.especies;
          var i;
          if (ttTarea.fechaFinalizacion == null) {
            ttTarea.fechaFinalizacion = new Date();
            fechaFinalizacion = new Date();
          } else {
            var fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          }
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var i = 0;
          var tbEspecie = {};
          var body = [
            [
              { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen troza (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen leña (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor troza (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor leña (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor total (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            valorTroza: 0,
            valorLenia: 0,
            valorTotal: 0
          }
          var listaTmp = [];
          var arboles = 0;
          var valorTotalMaterial = 0;
          for (i = 0; i < especies.length; i++) {
            var especie = especies[i];
            var total = parseFloat(especie.valorTroza) + parseFloat(especie.valorLenia);
            total = parseFloat(total).toFixed(2);
            body.push(
              [
                { text: especie.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                { text: especie.troza, style: 'detalle', alignment: 'right' },
                { text: especie.lenia, style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(especie.valorTroza), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(especie.valorLenia), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(total), style: 'detalle', alignment: 'right' }
              ]
            );
            totales.troza += parseFloat(especie.troza);
            totales.lenia += parseFloat(especie.lenia);
            totales.valorTroza += parseFloat(especie.valorTroza);
            totales.valorLenia += parseFloat(especie.valorLenia);
            totales.valorTotal += parseFloat(total);
            arboles += parseInt(especie.arboles);
            valorTotalMaterial += parseFloat(total);
            var encontrado = false;
            for (var j = 0; j < listaTmp.length; j++) {
              if (listaTmp[j].especieId == especie.tcEspecie.especieId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              listaTmp.push(especie.tcEspecie);
            }
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: parseFloat(totales.troza).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.valorTroza).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.valorLenia).toFixed(2), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.valorTotal).toFixed(2), style: 'detalle', alignment: 'right' }
            ]
          );
          tbEspecie = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 55, 55, 55, 55, 60],
              body: body
            }
          }
          if (ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId == 2) {
            var ttAvaluoMaderable = ttAvaluo.ttAvaluoMaderable;
            var total = parseFloat(ttAvaluoMaderable.costoTroza) + parseFloat(ttAvaluoMaderable.costoLenia);
            valorTotalMaterial = total;
            body = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen troza (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen leña (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Valor troza (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Valor leña (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Valor total (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ],
              [
                { text: 'Varias', style: 'detalle', alignment: 'left' },
                { text: ttAvaluoMaderable.troza, style: 'detalle', alignment: 'right' },
                { text: ttAvaluoMaderable.lenia, style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(ttAvaluoMaderable.costoTroza), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(ttAvaluoMaderable.costoLenia), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(parseFloat(total).toFixed(2)), style: 'detalle', alignment: 'right' }
              ],
              [
                { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: ttAvaluoMaderable.troza, style: 'detalle', alignment: 'right' },
                { text: ttAvaluoMaderable.lenia, style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(ttAvaluoMaderable.costoTroza), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(ttAvaluoMaderable.costoLenia), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(parseFloat(total).toFixed(2)), style: 'detalle', alignment: 'right' }
              ]
            ];
            tbEspecie = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [150, 55, 55, 55, 55, 60],
                body: body
              }
            }
          }
          var strEspecies = 'Varias';
          if (listaTmp.length > 0) {
            strEspecies = '';
            for (i = 0; i < listaTmp.length; i++) {
              var row = listaTmp[i];
              if (i == 0) {
                strEspecies = listaTmp[i].nombreCientifico;
              } else {
                strEspecies += ', ' + listaTmp[i].nombreCientifico;
              }
            }
          }
          var costos = ttAvaluo.costos;
          body = [
            [
              { text: 'Tipo de costo', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor total (Q.) / m³', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var total = 0;
          for (i = 0; i < costos.length; i++) {
            var costo = costos[i];
            body.push(
              [
                { text: costo.tcTipoCosto.tipoCostoDesc, style: 'detalle', alignment: 'left' },
                { text: ttAvaluoInformacion.volumen, style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(costo.monto), style: 'detalle', alignment: 'right' },
                { text: formatNumber.new(costo.total), style: 'detalle', alignment: 'right' }
              ]
            );
            total += parseFloat(costo.total);
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 3 }, {}, {},
              { text: formatNumber.new(parseFloat(total).toFixed(2)), style: 'detalle', alignment: 'right' }
            ]
          );
          var totalPagar = parseFloat(valorTotalMaterial) + parseFloat(total) + parseFloat(ttAvaluoInformacion.lesionEconomica);
          var tbCosto = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [210, 75, 75, 85],
              body: body
            }
          }
          body = [
            [
              { text: 'Volumen total dañado o talado m³', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área dañada o talada (ha)', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center' }
            ],
            [
              { text: parseFloat(ttAvaluoInformacion.volumen).toFixed(2), style: 'centrarGrande', alignment: 'center' },
              { text: parseFloat(ttAvaluoInformacion.area).toFixed(4), style: 'centrarGrande', alignment: 'center' }
            ],
            [
              { text: 'Valor del producto dañado o talado', style: 'centrar', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {}
            ],
            [
              { text: 'Q. ' + formatNumber.new(parseFloat(totalPagar).toFixed(2)), style: 'centrarGrande', alignment: 'center', colSpan: 2 }, {}
            ]
          ];
          var tbAvaluo = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [230, 230],
              body: body
            }
          }
          body = [
            [
              { text: 'Área talada (ha)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Monto reforestación / ha', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Valor total', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ],
            [
              { text: parseFloat(ttAvaluoInformacion.area).toFixed(4), style: 'detalle', alignment: 'center' },
              { text: 'Q. ' + formatNumber.new(parseFloat(ttAvaluoInformacion.montoLesion).toFixed(2)), style: 'detalle', alignment: 'center' },
              { text: 'Q. ' + formatNumber.new(parseFloat(ttAvaluoInformacion.lesionEconomica).toFixed(2)), style: 'detalle', alignment: 'center' }
            ]
          ];
          var tbLesion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [155, 150, 150],
              body: body
            }
          }
          var acompaniantes = '';
          var tbObservaciones = null;
          if (ttTarea.observaciones != null) {
            if (ttTarea.observaciones.acompaniantes != null) {
              var personas = ttTarea.observaciones.acompaniantes;
              for (i = 0; i < personas.length; i++) {
                var persona = personas[i];
                if (i == 0) {
                  acompaniantes = persona.personaDesc;
                } else {
                  acompaniantes += ', ' + persona.personaDesc;
                }
              }
            }
            var literales = 'abcdefghijklmnopqrstuvwxyz';
            if (ttTarea.observaciones.observaciones != null) {
              var detalle = ttTarea.observaciones.observaciones;
              body = [];
              if (detalle.length > 0) {
                for (i = 0; i < detalle.length; i++) {
                  var row = detalle[i];
                  body.push(
                    [
                      { text: literales[i] + ') ', style: 'izquierda', alignment: 'left' },
                      { text: row.descripcion, style: 'izquierda', alignment: 'justify' }
                    ]
                  );
                }
                tbObservaciones = {
                  style: 'estiloTabla',
                  table: {
                    headerRows: 0,
                    widths: [20, 440],
                    body: body
                  },
                  layout: 'noBorders'
                }
              }
            }
          }
          if (acompaniantes.length > 0) {
            acompaniantes = { text: "Nombre de los acompañantes al avalúo: " + acompaniantes, style: 'izquierda' };
          } else {
            acompaniantes = null;
          }
          var tbDetalle = null;
          if (ttAvaluo.detalle != null) {
            body = [];
            var detalle = ttAvaluo.detalle;
            for (i = 0; i < detalle.length; i++) {
              var row = detalle[i];
              body.push(
                [
                  { text: literales[i] + ') ', style: 'izquierda', alignment: 'left' },
                  { text: row.descripcion, style: 'izquierda', alignment: 'left', colSpan: 2 }, {}
                ],
                [
                  { text: '', style: 'izquierda', alignment: 'left' },
                  { text: 'R/', style: 'izquierda' },
                  { text: row.respuesta, style: 'izquierda', alignment: 'justify', bold: true }
                ]
              );
            }
            tbDetalle = {
              style: 'estiloTabla',
              table: {
                headerRows: 0,
                widths: [15, 15, 440],
                body: body
              },
              layout: 'noBorders'
            }
          }
          itemsDatos = [
            { text: "Número de causa o caso: " + ttAvaluo.oficioOrigen, style: 'izquierda' },
            { text: "Número de expediente: " + ttAvaluo.expediente, style: 'izquierda' },
            { text: "Lugar donde se realizó el avalúo: " + ttAvaluo.direccion + ', ' + ttAvaluo.tcMunicipio.municipioDesc + ', ' + ttAvaluo.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda' },
            { text: "Especies dañadas o taladas: " + strEspecies, style: 'izquierda' },
            { text: "Fecha de realización del avalúo: " + $filter('date')(new Date(ttAvaluoInformacion.fechaAvaluo), 'dd/MM/yyyy'), style: 'izquierda' },
            { text: "Localización geográfica donde se realizó el avalúo: GTM X=" + ttAvaluoInformacion.gtmX + ', GTM Y=' + ttAvaluoInformacion.gtmY, style: 'izquierda' },
            { text: "Nombre del evaluador o evaluadores: " + colaboradores, style: 'izquierda' }
          ];
          if (acompaniantes != null) {
            itemsDatos.push(acompaniantes);
          }

          var datos = {
            ol: [
              {
                ol: itemsDatos
              }
            ]
          }
          var content = [
            {
              width: 420,
              text: "\n\n" + tituloDictamen + "\nAVALÚO",
              style: 'centrar'
            }, '\n',
            {
              text: dictamenNumero,
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " del año " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            dirigido, '\n', saludo, '\n',
            {
              text: 'I. Datos generales del avalúo',
              style: 'izquierda', bold: true
            }, '\n', datos
          ];
          content.push('\n');
          content.push(
            {
              text: 'II. Avalúo de daños',
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          content.push(tbAvaluo);
          content.push('\n');
          var romano = 'III';
          if (tbDetalle != null) {
            content.push(
              {
                text: romano + '. Información específica solicitada',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(tbDetalle);
            content.push('\n');
            romano = 'IV';
          }
          if (tbObservaciones != null) {
            content.push(
              {
                text: romano + '. Observaciones',
                style: 'izquierda', bold: true
              }
            );
            content.push('\n');
            content.push(tbObservaciones);
            content.push('\n');
            romano = 'V';
          }
          content.push(
            'Atentamente,\n\n\n'
          );
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + firma,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: puesto + ttAvaluo.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: '\n\nImpreso por: ' + usuario,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );

          content.push(
            {
              text: romano + '. Anexos',
              style: 'izquierda',
              bold: true,
              pageBreak: "before"
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'AVALÚO REALIZADO CON BASE AL ARTÍCULO 90 DE LA LEY FORESTAL',
              style: 'centrar',
              bold: true
            }
          );
          content.push('\n');
          content.push({ text: 'ARTÍCULO 90.- Estimación de daños.  Para determinar el daño material se considerará:', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'a) El valor del material dañado, explotado o exportado ilícitamente o dejado de reforestar, los que tendrán una vinculación directa con los listados de costos publicados por el INAB;', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'b) Si el daño fue cometido en tierras nacionales o privadas;', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'c) La capacidad de producción y explotación forestal;', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'd) La gravedad del delito cometido;', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'e) Las lesiones económicas provocadas a la sociedad por la inversión de recursos en la lucha por mantener los recursos naturales; y,', style: 'detalle' });
          content.push('\n');
          content.push({ text: 'f) Otras circunstancias que a juicio del juez sirvan para determinar el daño ocasionado', style: 'detalle' });
          content.push('\n');
          content.push(
            {
              columns: [
                {
                  width: 30,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: '1. Valor del material dañado (Resolución JD.01.32.2022)',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(tbEspecie);
          content.push('\n');
          content.push(
            {
              columns: [
                {
                  width: 30,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: '2. Capacidad de producción y explotación forestal',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(tbCosto);
          content.push('\n');
          content.push(
            {
              columns: [
                {
                  width: 30,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: '3. Las lesiones económicas provocadas a la sociedad por la intervención de recursos en la lucha por mantener los recursos naturales',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(tbLesion);
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [70, 120, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              centrarGrande: {
                fontSize: 13,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarNotaEnvioConsumo: function (ttTarea, numero, totalEnLetras, dataEmpresa) {
          var nombreSalida = 'nota.pdf';
          var ttConsumo = ttTarea.ttConsumo;
          var ttTareaResolucion = ttTarea.ttPadre;
          var ttConsumoVenta = ttTarea.ttConsumoVenta;
          var correlativos = ttConsumoVenta.correlativos;
          var ttFincaConsumo = ttConsumo.ttFincaConsumo;
          var correlativo = {};
          for (var i = 0; i < correlativos.length; i++) {
            var row = correlativos[i];
            if (row.numero == numero) {
              correlativo = row;
              break;
            }
          }
          var body = [
            [
              { text: 'Especie', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Troza (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Leña (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Madera aserrada (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Carbón (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Total (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' }
            ]
          ];
          var especies = correlativo.especies;
          var total = 0, totalFactor = 0;
          var hayFactor = false;
          var valorFactor = 0;
          for (var i = 0; i < especies.length; i++) {
            var especie = especies[i];
            especie.troza = parseFloat(especie.troza).toFixed(4);
            especie.lenia = parseFloat(especie.lenia).toFixed(4);
            especie.aserrada = parseFloat(especie.aserrada).toFixed(4);
            especie.carbon = parseFloat(especie.carbon).toFixed(4);
            especie.total = parseFloat(especie.total).toFixed(4);
            var totalFila = parseFloat(especie.troza) + parseFloat(especie.lenia)
              + parseFloat(especie.aserrada) + parseFloat(especie.carbon);
            totalFila = parseFloat(totalFila).toFixed(4);
            var item = [
              { text: especie.tcEspecie.nombreCientifico, style: 'detalleIzquierda' },
              { text: especie.troza, style: 'detalleDerecha' },
              { text: especie.lenia, style: 'detalleDerecha' },
              { text: especie.aserrada, style: 'detalleDerecha' },
              { text: especie.carbon, style: 'detalleDerecha' },
              { text: totalFila, style: 'detalleDerecha' }
            ];
            body.push(item);
            if (parseFloat(especie.aserrada) > 0) {
              valorFactor = parseFloat(especie.aserrada) / parseFloat(especie.factorAserrada);
              valorFactor = parseFloat(valorFactor).toFixed(4);
              valorFactor = parseFloat(valorFactor) - parseFloat(especie.aserrada);
              valorFactor = parseFloat(valorFactor).toFixed(4);
              item = [
                { text: 'Residuos o leña (Producto de la transformación de la madera aserrada)', style: 'detalleIzquierda', colSpan: 3 }, {}, {},
                { text: valorFactor, style: 'detalleDerecha' },
                { text: '-', style: 'detalleDerecha' },
                { text: valorFactor, style: 'detalleDerecha' }
              ];
              body.push(item);
              hayFactor = true;
            }
            if (parseFloat(especie.carbon) > 0) {
              valorFactor = parseFloat(especie.carbon) / parseFloat(especie.factorCarbon);
              valorFactor = parseFloat(valorFactor).toFixed(4);
              valorFactor = parseFloat(valorFactor) - parseFloat(especie.carbon);
              valorFactor = parseFloat(valorFactor).toFixed(4);
              item = [
                { text: 'Pérdida (producto de la transformación del carbón)', style: 'detalleIzquierda', colSpan: 4 }, {}, {}, {},
                { text: valorFactor, style: 'detalleDerecha' },
                { text: valorFactor, style: 'detalleDerecha' }
              ];
              body.push(item);
            }
            totalFactor += parseFloat(especie.total);
          }
          totalFactor = parseFloat(totalFactor).toFixed(4);
          var item = [
            { text: 'TOTAL', style: 'centrarTabla', fillColor: '#CCCCCC', colSpan: 5 }, {}, {}, {}, {},
            { text: totalFactor, style: 'detalleDerecha' }
          ];
          body.push(item);
          tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 60, 60, 60, 60, 60],
              body: body
            }
          }
          var textoFactor = {}, kEnter = {};
          if (hayFactor) {
            textoFactor = {
              text: [
                {
                  text: '* La sumatoria de volumen incluye el factor de transformación de carbón y madera aserrada',
                  style: 'izquierda',
                  bold: true,
                  italics: true
                }
              ]
            };
            kEnter = '\n';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: [
                    {
                      text: "\nINSTITUTO NACIONAL DE BOSQUES\n", italics: true
                    },
                    {
                      text: '\nNOTA DE ENVÍO DE BOSQUE DE CONSUMO FAMILIAR', bold: true
                    }
                  ],
                  style: 'centrar'
                },
                {
                  width: 80,
                  style: 'izquierda',
                  text: '',
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            }, '\n',
            {
              text: "Correlativo: " + numero,
              style: 'derecha',
              bold: true
            },
            {
              text: "No. Resolución: " + (ttConsumoVenta.resolucion == null ? 'XXXXXX' : ttConsumoVenta.resolucion),
              style: 'derecha',
              bold: true
            }, '\n',
            {
              text: [
                {
                  text: 'Nombre del lugar de aprovechamiento forestal: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttFincaConsumo.tcFinca.fincaDesc,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              text: [
                {
                  text: 'Ubicación del aprovechamiento forestal: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttFincaConsumo.tcFinca.direccion,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              columns: [
                {
                  width: 250,
                  text: [
                    {
                      text: 'Municipio: ',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: ttFincaConsumo.tcFinca.tcMunicipio.municipioDesc,
                      style: 'izquierda'
                    }
                  ]
                },
                {
                  width: 175,
                  text: [
                    {
                      text: 'Departamento: ',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: ttFincaConsumo.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc,
                      style: 'izquierda'
                    }
                  ]
                },
                {
                  width: '*',
                  text: '',
                  style: 'izquierda'
                }
              ]
            },
            {
              text: [
                {
                  text: 'Nombre del titular del Consumo Familiar: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttConsumo.tcSolicitante.personaDesc,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            }, '\n',
            tbVenta,
            {
              text: [
                {
                  text: 'Volumen total a transportar: ',
                  style: 'izquierda'
                },
                {
                  text: totalEnLetras,
                  style: 'izquierda',
                  bold: true
                }
              ],
              style: 'izquierda'
            }, textoFactor, '\n',
            {
              text: "Producto forestal no comercializable",
              alignment: 'center', bold: true,
              fontSize: 12
            }, '\n'
          ];
          content.push(
            {
              text: [
                {
                  text: 'No. de placa del vehículo: ',
                  bold: true
                },
                {
                  text: correlativo.placa
                }
              ],
              style: 'izquierda'
            }
          );
          var lugarDestino = correlativo.direccion + ', ' + ttConsumo.ttConsumoInformacion.tcMunicipioDestino.municipioDesc + ', '
          + ttConsumo.ttConsumoInformacion.tcMunicipioDestino.tcDepartamento.departamentoDesc;
          if (correlativo.tcMunicipio != null) {
            lugarDestino = correlativo.direccion + ', ' + correlativo.tcMunicipio.municipioDesc + ', '
            + correlativo.tcMunicipio.tcDepartamento.departamentoDesc;
          }
          content.push(
            {
              text: [
                {
                  text: 'Lugar de destino: ',
                  bold: true
                },
                {
                  text: lugarDestino
                }
              ],
              style: 'izquierda'
            }
          );
          content.push(
            {
              text: [
                {
                  text: 'Fecha y hora de salida: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: $filter('date')(new Date(correlativo.fechaTransporte), 'dd/MM/yyyy HH:mm'),
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              qr: appSettings.urlBase + 'validaConsumoVenta/' + cryptText(appSettings.seed, ttConsumo.consumoId),
              fit: '100',
              style: 'small',
              alignment: 'center'
            }
          );
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia || appSettings.isDevelopment) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 40, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                alignment: 'center'
              },
              centrarTabla: {
                fontSize: 9,
                alignment: 'center',
                bold: true
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              detalleIzquierda: {
                fontSize: 8.5,
                alignment: 'justify'
              },
              detalleDerecha: {
                fontSize: 8.5,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15],
                fontSize: 9
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        solicitudConsumoFamiliar: function (ttConsumo, usuario, dataEmpresa) {
          if (ttConsumo.fechaSolicitud == null) {
            ttConsumo.fechaSolicitud = new Date();
          }
          var fechaSolicitud = new Date(ttConsumo.fechaSolicitud);
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "";
          finca = ttConsumo.ttFincaConsumo;
          if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
            if (!posesion && !propiedad) {
              textoDocumento = "propiedad";
              propiedad = true;
            } else {
              if (!propiedad) {
                if (!posesion) {
                  textoDocumento = "propiedad";
                } else {
                  textoDocumento = textoDocumento + " y propiedad";
                }
                propiedad = true;
              }
            }
          } else {
            if (!posesion && !propiedad) {
              textoDocumento = "posesión";
              posesion = true;
            } else {
              if (!posesion) {
                if (!propiedad) {
                  textoDocumento = "posesión";
                } else {
                  textoDocumento = textoDocumento + " y posesión";
                }
                posesion = true;
              }
            }
          }
          textoFinca = "terreno denominado " + finca.tcFinca.fincaDesc + "  ubicada en " + finca.tcFinca.direccion
            + " del municipio de "
            + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
          var complementoTitulo = '';
          var textoPrimerParrafo = '';
          var municipioDesc = '';
          var sigla = ttConsumo.tcPersonaDirigida.sigla;
          if (sigla == null) {
            sigla = '';
          } else {
            sigla += ' ';
          }
          if (ttConsumo.paraSubregion == 1) {
            textoPrimerParrafo = sigla + ttConsumo.tcPersonaDirigida.personaDesc + ", director(a) subregional del Instituto Nacional de Bosques INAB, con sede en el municipio de "
              + ttConsumo.tcSubregion.tcMunicipio.municipioDesc + ", departamento de " + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc;
            municipioDesc = ttConsumo.tcSubregion.tcMunicipio.municipioDesc;
          } else {
            textoPrimerParrafo = sigla + ttConsumo.tcPersonaDirigida.personaDesc + ", " + ttConsumo.tcPersonaDirigida.tcPuesto.puestoDesc.toLowerCase() + " del municipio de "
              + ttConsumo.tcMunicipio.municipioDesc + ", departamento de " + ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc;
            complementoTitulo = '\n-OFICINA DE GESTIÓN FORESTAL MUNICIPAL-';
            municipioDesc = ttConsumo.tcMunicipio.municipioDesc;
          }
          var strFechaNacimiento = $filter('date')(new Date(ttConsumo.tcSolicitante.fechaNacimiento), 'yyyy-MM-dd');
          var strFechaInicio = $filter('date')(new Date(ttConsumo.fechaSolicitud), 'yyyy-MM-dd');
          var edad = calculaEdad(strFechaNacimiento, strFechaInicio);
          textoPrimerParrafo += '. YO: ' + ttConsumo.tcSolicitante.personaDesc + ', de ' + edad + ' años de edad, guatemalteco, de profesión ' + ttConsumo.tcSolicitante.tcOcupacion.ocupacionDesc
            + '; me identifico con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- ' + ttConsumo.tcSolicitante.cui
            + ' extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-. Por este medio ';
          var primerParrafo = [
            {
              text: textoPrimerParrafo, style: 'izquierda'
            },
            {
              text: 'SOLICITO: ', fontSize: 11, bold: true
            },
            {
              text: 'Aprovechamiento Forestal de Consumo Familiar en el '
                + textoFinca + '. Dicha petición se realiza de conformidad con la información que se especifican a continuación:', style: 'izquierda'
            }
          ]
          var body = [
            [
              { text: 'I. DATOS DEL TERRENO', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          finca = ttConsumo.ttFincaConsumo;
          body.push(
            [
              { text: 'Nombre del terreno:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
              { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
              { text: 'Área:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
              { text: parseFloat(finca.tcFinca.area).toFixed(4), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
            ]
          );
          body.push([
            { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
            { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
            { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
            { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
          ]);
          body.push(
            [
              { text: 'Dirección (Aldea/caserío/cantón):', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
            ]
          );
          body.push(
            [
              { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
            ]
          );
          switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
            case 1:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.numeroDocumento, style: 'tableSubHeader', alignment: 'left' },
                { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.notario, style: 'tableSubHeader', alignment: 'left' }
              ];
              body.push(item);
              item = [
                { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ];
              break;
            case 2:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.notario, style: 'tableSubHeader', alignment: 'left' },
                { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
              break;
            case 3:
              item = [
                { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.numeroDocumento, style: 'tableSubHeader', alignment: 'left' },
                { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.folio, style: 'tableSubHeader', alignment: 'left' },
                { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.libro, style: 'tableSubHeader', alignment: 'left' }
              ];
              body.push(item);
              item = [
                { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcLibro.libroDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ];
              break;
            case 5:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'tableSubHeader', 
                  alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre de la comunidad o autoridad que la emite', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 3 }, {}, {},
                { text: finca.notario, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
              item = [
                { text: '* En el caso de comunidades y/o terrenos en colectividad, el solicitante además de los requisitos establecidos anteriormente, '
                  + 'debe presentar aval del órgano o autoridad superior. (Para el sistema agregar al mismo PDF del documento del terreno)', 
                  style: 'tableSubHeader', alignment: 'left', colSpan: 6, bold: true }, {}, {}, {}, {}, {}
              ];
              break;
            case 6:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'tableSubHeader', 
                  alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre del Alcalde o autoridad municipal designada', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 3 }, {}, {},
                { text: finca.notario, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              break;
            case 7:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'tableSubHeader', 
                  alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
              item = [
                { text: 'Nombre de la autoridad municipal', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left', colSpan: 3 }, {}, {},
                { text: finca.notario, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              break;
            default:
              item = [
                { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'tableSubHeader', alignment: 'left' },
                { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.numeroDocumento, style: 'tableSubHeader', alignment: 'left' },
                { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcMunicipioEmite.municipioDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
              break;
          }
          if (item != null) {
            body.push(item);
          }
          var tbFinca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [80, 70, 70, 80, 75, 75],
              body: body
            }
          }
          var totalArboles = 0;
          body = [
            [
              { text: 'II. ESPECIES A APROVECHAR', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}
            ],
            [
              { text: 'No.', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Nombre de la especie', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (i = 0; i < ttConsumo.especiesSolicitadas.length; i++) {
            row = ttConsumo.especiesSolicitadas[i];
            item = [
              { text: i + 1, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: row.cantidad, style: 'tableSubHeader', alignment: 'center' },
              { text: row.nombreEspecie, style: 'tableSubHeader', alignment: 'left' }
            ];
            body.push(item);
            totalArboles += parseInt(row.cantidad);
          }
          item = [
            { text: 'TOTAL:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
            { text: totalArboles, style: 'tableSubHeader', alignment: 'center' },
            { text: '', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'right' }
          ];
          body.push(item);
          var tbEspecie = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [50, 100, 330],
              body: body
            }
          }
          body = [
            [
              { text: 'III. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}
            ],
            [
              { text: 'No.', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Tipo de notificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Descripción', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (i = 0; i < ttConsumo.notificaciones.length; i++) {
            row = ttConsumo.notificaciones[i];
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              item = [
                { text: i + 1, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: row.notificacionConsumoDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            } else {
              item = [
                { text: i + 1, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: row.notificacionConsumoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            }
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [50, 160, 270],
              body: body
            }
          }
          body = [
            [
              { text: 'IV. BENEFICIADOS', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}
            ],
            [
              { text: 'Nombre', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: 'Edad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Parentesco', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (i = 0; i < ttConsumo.beneficiados.length; i++) {
            row = ttConsumo.beneficiados[i];
            item = [
              { text: row.nombreCompleto, style: 'tableSubHeader', alignment: 'left' },
              { text: row.edad, style: 'tableSubHeader', alignment: 'center' },
              { text: row.tcParentesco.parentescoDesc, style: 'tableSubHeader', alignment: 'left' }
            ];
            body.push(item);
          }
          var tbBeneficiario = {};
          if (ttConsumo.beneficiados.length > 0) {
            tbBeneficiario = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [240, 50, 190],
                body: body
              }
            }
          }
          var nombreSalida = 'solicitud.pdf';
          var content = [
            {
              text: 'SOLICITUD DE APROVECHAMIENTO FORESTAL DE CONSUMO FAMILIAR' + complementoTitulo,
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'Fecha: ' + fechaSolicitud.getDate() + ' de ' + getMonth(fechaSolicitud.getMonth() + 1) + " de " + fechaSolicitud.getFullYear(),
              style: 'derecha'
            }, '\n',
            {
              text: primerParrafo,
              style: 'izquierda'
            }, '\n', tbFinca, '\n', tbEspecie, '\n', tbNotificacion, '\n', tbBeneficiario, '\n',
            {
              text: [
                {
                  text: 'DECLARO BAJO JURAMENTO: ', fontSize: 12, bold: true
                },
                {
                  text: 'Que los datos consignados en la presente solicitud son verídicos. Así también los árboles que se extraerán con la resolución de aprovechamiento forestal de consumo familiar se encuentran dentro de los límites de mi propiedad, asumiendo el compromiso de salvaguardar las fuentes de agua y las especies protegidas en su caso, y dentro de las limitaciones que señale el INAB, que los árboles no están en colindancia con otras propiedades ni en áreas legalmente protegidas, comprometiéndome a cumplir con la reforestación, salvaguardar las fuentes de agua, velar por la protección de los bosques y denunciar cualquier aprovechamiento ilegal dentro del municipio, por el uso indebido o extralimitación del volumen de los productos forestales extraídos. El aprovechamiento es únicamente para mi consumo y no para comercializar con él y que el INMUEBLE NO ESTÁ EN LITIGIOS JUDICIALES, sometiéndome a lo que dicta la Ley de no ser así.', 
                  style: 'izquierda'
                }
              ]
            }, '\n\n\n',
            {
              text: "F. _________________________________",
              style: 'centrar'
            }, '\n',
            {
              text: 'Elaborado por: ' + ttConsumo.tcPersonaCrea.tcUsuario.usuario,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Impreso por: ' + usuario,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora de impresión: ' + $filter('date')(fechaSolicitud, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";
          var watermark = {};
          if (gblEsPrevia || appSettings.isDevelopment) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 40, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarInformeConsumo: function (ttTareaConsumo, usuarioDesc, dataEmpresa, sigla) {
          var ttConsumo = ttTareaConsumo.ttConsumo;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var especies = ttConsumo.especies;
          var strEspecies = '';
          for (var i = 0; i < especies.length; i++) {
            var especie = especies[i];
            if (i == 0) {
              strEspecies = especie.tcEspecie.nombreCientifico;
            } else {
              strEspecies += ', ' + especie.tcEspecie.nombreCientifico;
            }
          }
          var i;
          var nombreSalida = 'dictamen.pdf';
          if (ttTareaConsumo.fechaFinalizacion == null) {
            ttTareaConsumo.fechaFinalizacion = new Date();
          }
          var fechaFinalizacion = new Date(ttTareaConsumo.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var tcFinca = ttConsumo.ttFincaConsumo.tcFinca;
          var gtm = 'X=' + tcFinca.gtmX + ', Y=' + tcFinca.gtmY;
          var paso = null;
          var fechaSolicitud = new Date(ttConsumo.fechaSolicitud);
          var fechaEvaluacion = ttConsumo.ttConsumoInformacion.fechaEvaluacion;
          var partesFechaEvaluacion = fechaEvaluacion.toString().split('-');
          if (partesFechaEvaluacion.length < 3) {
            fechaEvaluacion = $filter('date')(new Date(fechaEvaluacion), 'yyyy-MM-dd');
            partesFechaEvaluacion = fechaEvaluacion.toString().split('-');
          }
          var aTraves = 'Por este medio me permito darle a conocer los resultados de la evaluación de campo realizada, según solicitud No. '
            + ttConsumo.expediente + ' de fecha ' + fechaSolicitud.getDate() + ' de ' + getMonth(fechaSolicitud.getMonth() + 1) +
            ' de ' + fechaSolicitud.getFullYear();
          var tbInventario = {};
          var tmpData = ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario;
          if (tmpData == null) {
            tmpData = [];
          }

          var body = [
            [
              { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Número de Árboles', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'DAP (cm)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Altura (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Troza', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Leña', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³) Total', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var totales = {
            troza: 0,
            lenia: 0,
            total: 0,
            totalArboles: 0
          }
          for (i = 0; i < tmpData.length; i++) {
            var row = tmpData[i];
            totales.troza += parseFloat(row.troza);
            totales.lenia += parseFloat(row.lenia);
            totales.total += parseFloat(row.volumen);
            totales.totalArboles += 1;
            body.push(
              [
                { text: row.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                { text: 1, style: 'detalle', alignment: 'left' },
                { text: row.dap, style: 'detalle', alignment: 'left' },
                { text: row.altura, style: 'detalle', alignment: 'left' },
                { text: parseFloat(row.troza).toFixed(4), style: 'detalle', alignment: 'right' },
                { text: parseFloat(row.lenia).toFixed(4), style: 'detalle', alignment: 'right' },
                { text: parseFloat(row.volumen).toFixed(4), style: 'detalle', alignment: 'right' }
              ]
            );
          }
          body.push(
            [
              { text: 'TOTAL', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center', colSpan: 4 }, {}, {}, {},
              { text: parseFloat(totales.troza).toFixed(4), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.lenia).toFixed(4), style: 'detalle', alignment: 'right' },
              { text: parseFloat(totales.total).toFixed(4), style: 'detalle', alignment: 'right' }
            ]
          );
          tbInventario = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [160, 45, 45, 45, 45, 45, 45],
              body: body
            }
          }

          itemsDatos = [
            { text: "Titular: " + ttConsumo.tcSolicitante.personaDesc, style: 'izquierda' },
            { text: "Nombre del terreno: " + tcFinca.fincaDesc, style: 'izquierda' },
            { text: "Ubicación del terreno: " + tcFinca.direccion, style: 'izquierda' },
            { text: "Municipio: " + tcFinca.tcMunicipio.municipioDesc, style: 'izquierda' },
            { text: "Departamento: " + tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda' },
            { text: "Área: " + tcFinca.area, style: 'izquierda' },
            { text: "Especies: " + strEspecies, style: 'izquierda' },
            { text: "Localización GTM: " + gtm, style: 'izquierda' }
          ];

          var datos = {
            ol: [
              {
                ol: itemsDatos
              }
            ]
          }
          var dirigido = {};
          var puesto = '';
          tcPersonaTraslado = ttTareaConsumo.tcPersonaTraslado;
          if (tcPersonaTraslado == null) {
            tcPersonaTraslado = ttConsumo.tcPersonaDirigida;
          }
          if (ttConsumo.paraSubregion == 1) {
            dirigido = {
              text: tcPersonaTraslado.personaDesc + "\nDirector Subregional "
                + ttConsumo.tcSubregion.subregionDesc + "\nINAB, " + ttConsumo.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }
            puesto = 'Técnico forestal, Subregión ' + ttConsumo.tcSubregion.alias + ' ' + ttConsumo.tcSubregion.subregionDesc;
          } else {
            dirigido = {
              text: tcPersonaTraslado.personaDesc + "\n " + tcPersonaTraslado.tcPuesto.puestoDesc + ' '
                + ttConsumo.tcMunicipio.municipioDesc + ", "
                + ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }
            puesto = 'Técnico Municipal, ' + ttConsumo.tcMunicipio.municipioDesc;
          }

          var content = [
            {
              width: 420,
              text: "\n\nDICTAMEN TÉCNICO DE EVALUACIÓN DE SOLICITUD PARA APROVECHAMIENTO FORESTAL DE CONSUMO FAMILIAR",
              style: 'centrar'
            }, '\n',
            {
              text: "Dictamen técnico número: " + (ttTareaConsumo.codigo == null ? 'XXX-XXXXX' : ttTareaConsumo.codigo),
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " del año " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            dirigido, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n',
            {
              text: 'I. INFORMACIÓN GENERAL',
              style: 'izquierda', bold: true
            }, '\n', datos, '\n'
          ];
          content.push(
            {
              text: 'II. INVENTARIO FORESTAL',
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          content.push(tbInventario);
          content.push('\n');
          content.push(
            {
              text: 'El porcentaje de rendimiento para madera aserrada con motosierra es del 39% (pérdida del 61%), según Acuerdo de Gerencia INAB 42-2003',
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'III. DICTAMEN TÉCNICO',
              style: 'izquierda', bold: true
            }
          );
          content.push('\n');
          var fechaEvaluacion = new Date(ttConsumo.ttConsumoInformacion.fechaEvaluacion);
          if (ttTareaConsumo.aprobado == 1) {
            content.push(
              {
                text: 'Con base en la evaluación de campo realizada el día ' + partesFechaEvaluacion[2] + ' de ' + getMonth(parseInt(partesFechaEvaluacion[1])) + ' de ' + partesFechaEvaluacion[0]
                  + ', a la solicitud presentada, se otorga Dictamen Estrictamente Técnico FAVORABLE. Recomendando OTORGAR Resolución de Aprovechamiento Forestal de Consumo Familiar a ' + ttConsumo.tcSolicitante.personaDesc,
                style: 'izquierda'
              }
            );
            if (ttConsumo.ttConsumoInformacion.transportar == 1) {
              content.push('\n');
              content.push(
                {
                  text: 'Se recomienda autorizar notas de envío de Consumo Familiar para el transporte del producto forestal autorizado dentro de la jurisdicción departamental',
                  style: 'izquierda'
                }
              );
            }
            content.push('\n');
            content.push(
              {
                text: 'Se establece que el solicitante deberá plantar un mínimo de ' + ttConsumo.ttConsumoInformacion.repoblacion + ' árboles por cumplimiento de repoblación forestal',
                style: 'izquierda'
              }
            );
          } else {
            content.push(
              {
                text: 'Con base en la evaluación de campo realizada el día ' + fechaEvaluacion.getDate() + ' de ' + getMonth(fechaEvaluacion.getMonth() + 1) + ' de ' + fechaEvaluacion.getFullYear()
                  + ', a la solicitud presentada, se otorga Dictamen Estrictamente Técnico NO FAVORABLE. Recomendando NO OTORGAR Resolución de Aprovechamiento Forestal de Consumo Familiar a ' + ttConsumo.tcSolicitante.personaDesc,
                style: 'izquierda'
              }
            );
          }
          var dataTmp = ttTareaConsumo.observaciones.razones;
          if (dataTmp.length > 0) {
            content.push('\n');
            content.push(
              {
                text: 'Razones para la no aprobación',
                style: 'izquierda', bold: true
              }
            );
            var lista = [];
            for (i = 0; i < dataTmp.length; i++) {
              lista.push({ text: dataTmp[i].descripcion, style: 'izquierda' });
            }
            content.push('\n');
            content.push(
              {
                ol: lista
              }
            );
          }
          dataTmp = ttTareaConsumo.observaciones.observaciones;
          if (dataTmp.length > 0) {
            content.push('\n');
            content.push(
              {
                text: 'Observaciones',
                style: 'izquierda', bold: true
              }
            );
            var lista = [];
            for (i = 0; i < dataTmp.length; i++) {
              lista.push({ text: dataTmp[i].descripcion, style: 'izquierda' });
            }
            content.push('\n');
            content.push(
              {
                ol: lista
              }
            );
          }
          if (ttTareaConsumo.observaciones.transportar == 1) {
            content.push('\n');
            content.push(
              {
                text: 'Se recomienda autorizar autorizar el uso de notas de envío de Consumo Familiar, para el transporte del producto forestal autorizado dentro de la jurisdicción departamental. Así mismo, se establece que el solicitante deberá plantar un mínimo de 16 árboles por cumplimiento de repoblación forestal',
                style: 'izquierda'
              }
            );
          }
          content.push(
            '\nAtentamente,\n\n\n\n'
          );
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTareaConsumo.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: puesto,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCaratulaConsumo: function (ttConsumo, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'caratula.pdf';
          var fecha = new Date();
          var fechaSolicitud = new Date(ttConsumo.fechaSolicitud);
          var mesSolicitud = fechaSolicitud.getMonth() + 1;
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var item = {};
          if (ttConsumo.paraSubregion == 1) {
            item = {
              text: "INSTITUTO NACIONAL DE BOSQUES\nDIRECCIÓN SUBREGIONAL " + ttConsumo.tcSubregion.subregionDesc.toUpperCase(),
              style: 'centrar'
            };
          } else {
            item = {
              text: "MUNICIPALIDAD DE " + ttConsumo.tcMunicipio.municipioDesc.toUpperCase(),
              style: 'centrar'
            };
          }
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: [
              {
                text: "No. Expediente: ",
                style: 'sencillo'
              },
              {
                text: ttConsumo.expediente,
                style: 'negrilla'
              }
            ]
          };
          content.push(item);
          content.push('\n');
          item = {
            text: [
              {
                text: "Tipo de Solicitud: ",
                style: 'sencillo'
              },
              {
                text: ttConsumo.tcTipoGestion.tipoGestionDesc,
                style: 'negrilla'
              }
            ]
          };
          content.push(item);
          content.push('\n');
          var finca = ttConsumo.ttFincaConsumo.tcFinca;
          item = {
            text: [
              {
                text: "Localización del terreno: ",
                style: 'sencillo'
              },
              {
                text: finca.tcMunicipio.municipioDesc + ", " + finca.tcMunicipio.tcDepartamento.departamentoDesc,
                style: 'negrilla'
              }
            ]
          };
          content.push(item);
          content.push('\n');
          item = {
            text: [
              {
                text: "Nombre del solicitante: ",
                style: 'sencillo'
              },
              {
                text: ttConsumo.tcSolicitante.personaDesc,
                style: 'negrilla'
              }
            ]
          };
          content.push(item);
          content.push('\n');
          item = {
            text: 'Datos para notificación',
            style: 'negrilla'
          };
          content.push(item);
          content.push('\n');
          //obtener los tipos de notificacion
          var body = [];
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < ttConsumo.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(ttConsumo.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionConsumoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionConsumoDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionConsumoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionConsumoDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'sencillo', alignment: 'left' },
              { text: strDescripcion, style: 'negrilla', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 450],
              body: body
            }, layout: 'noBorders'
          };
          content.push(tablaGenerales);
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [80, 50, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 10,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 14,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 15,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarProvidenciaConsumo: function (ttTareaConsumo, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttConsumo = ttTareaConsumo.ttConsumo;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaFinalizacion = new Date(ttTareaConsumo.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var firmaTecnico = {
            text: '\n\nTécnico Forestal: Fecha:__________ Firma: __________',
            style: 'small', alignment: 'right'
          };
          var tcFinca = ttConsumo.ttFincaConsumo.tcFinca;
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttConsumo.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttConsumo.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttConsumo.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: ttConsumo.tcSolicitante.personaDesc + ' presentó la solicitud de Aprovechamiento Forestal de Consumo Familiar en el terreno '
                        + tcFinca.fincaDesc + ' ubicado en ' + tcFinca.direccion + ', municipio de ' + tcFinca.tcMunicipio.municipioDesc
                        + ', departamento de ' + tcFinca.tcMunicipio.tcDepartamento.departamentoDesc,
                      style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + (ttTareaConsumo.codigo == null ? 'XXX-XXXXX' : ttTareaConsumo.codigo),
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    {
                      text: 'Fórmese el expediente respectivo', style: 'izquierda'
                    },
                    {
                      text: "Pase al Técnico Forestal " + ttTareaConsumo.tcPersonaTraslado.personaDesc + ", para que realice inspección de campo y dictamine a la brevedad sobre la solicitud presentada por "
                        + ttConsumo.tcSolicitante.personaDesc, style: 'izquierda'
                    },
                    { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
                  ]
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttTareaConsumo.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttConsumo.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        resolucionConsumoFamiliar: function (ttTareaConsumo, strDia, strAnio, usuario, dataEmpresa) {
          var ttConsumo = ttTareaConsumo.ttConsumo;
          if (ttConsumo.fechaSolicitud == null) {
            ttConsumo.fechaSolicitud = new Date();
          }
          var fechaSolicitud = new Date(ttConsumo.fechaSolicitud);
          var i, j;
          var propiedad = false, posesion = false;
          var textoFinca = "";
          finca = ttConsumo.ttFincaConsumo;
          if (finca.tcTipoPropiedad.tipoPropiedadId == 3) {
            if (!posesion && !propiedad) {
              textoDocumento = "propiedad";
              propiedad = true;
            } else {
              if (!propiedad) {
                if (!posesion) {
                  textoDocumento = "propiedad";
                } else {
                  textoDocumento = textoDocumento + " y propiedad";
                }
                propiedad = true;
              }
            }
          } else {
            if (!posesion && !propiedad) {
              textoDocumento = "posesión";
              posesion = true;
            } else {
              if (!posesion) {
                if (!propiedad) {
                  textoDocumento = "posesión";
                } else {
                  textoDocumento = textoDocumento + " y posesión";
                }
                posesion = true;
              }
            }
          }
          textoFinca = "terreno denominado " + finca.tcFinca.fincaDesc + "  ubicada en " + finca.tcFinca.direccion
            + " del municipio de "
            + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
          var tcFinca = finca.tcFinca;
          var complementoTitulo = '';
          var textoPrimerParrafo = '';
          var municipioDesc = '';
          var sigla = ttConsumo.tcPersonaDirigida.sigla;
          if (sigla == null) {
            sigla = '';
          } else {
            sigla += ' ';
          }
          if (ttConsumo.paraSubregion == 1) {
            textoPrimerParrafo = sigla + ttConsumo.tcPersonaDirigida.personaDesc + ", director subregional del Instituto Nacional de Bosques INAB, con sede en el municipio de "
              + ttConsumo.tcSubregion.tcRegion.tcMunicipio.municipioDesc + ", departamento de " + ttConsumo.tcSubregion.tcRegion.tcMunicipio.tcDepartamento.departamentoDesc;
            municipioDesc = ttConsumo.tcSubregion.tcRegion.tcMunicipio.municipioDesc;
          } else {
            textoPrimerParrafo = sigla + ttConsumo.tcPersonaDirigida.personaDesc + ", " + ttConsumo.tcPersonaDirigida.tcPuesto.puestoDesc.toLowerCase() + " del municipio de "
              + ttConsumo.tcMunicipio.municipioDesc + ", departamento de " + ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc;
            complementoTitulo = '\n-OFICINA DE GESTIÓN FORESTAL MUNICIPAL-';
            municipioDesc = ttConsumo.tcMunicipio.municipioDesc;
          }
          var strFechaNacimiento = $filter('date')(new Date(ttConsumo.tcSolicitante.fechaNacimiento), 'yyyy-MM-dd');
          var strFechaInicio = $filter('date')(new Date(ttConsumo.fechaSolicitud), 'yyyy-MM-dd');
          var edad = calculaEdad(strFechaNacimiento, strFechaInicio);
          var sexo = 'el señor ';
          if (ttConsumo.tcSolicitante.tcSexo.sexoId == 1) {
            sexo = 'la señora ';
          }
          textoPrimerParrafo = 'Se tiene a la vista para resolver el expediente No. ' + ttConsumo.expediente
            + ', referente a la solicitud de Aprovechamiento Forestal de Consumo Familiar. Presentada por ' + sexo
            + ttConsumo.tcSolicitante.personaDesc + ', quien se identifica con Documento Personal de Identificación –DPI- y Código Único de Identificación –CUI- '
            + ttConsumo.tcSolicitante.cui + ', extendida por el Registro Nacional de las Personas de la República de Guatemala –RENAP-. ';
          var finca = ttConsumo.ttFincaConsumo;
          var textoFinca = 'terreno de su ';
          var textoFincaSinDocumento = 'terreno de su ';
          var acreditada = '';
          var propiedad = '';
          switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
            case 1:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ' de derechos posesorios número ' + finca.numeroDocumento
                + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ', autorizada en el municipio '
                + finca.tcMunicipioEmite.municipioDesc + ' del departamento de ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc
                + ', por el notario ' + finca.notario;
              break;
            case 2:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ' de derechos posesorios de bien inmueble, autorizada en el municipio de '
                + finca.tcMunicipioEmite.municipioDesc + ' del departamento de ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc
                + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy') + ', por el Notario ' + finca.notario;
              break;
            case 3:
              textoFinca += 'propiedad ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ', extendida por el Registro General de la Propiedad, inscrita con número de finca '
                + finca.numeroDocumento + ', folio ' + finca.folio + ', libro ' + finca.libro + ' del departamento de ' + finca.tcLibro.libroDesc;
              break;
            case 4:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy')
              + ', emitida por la Autoridad Municipal ' + finca.notario;
              break;
            case 5:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ', emitido en ' + finca.tcMunicipioEmite.municipioDesc 
              + ' del departamento de ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ', de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy')
              + ' por la comunidad o autoridad ' + finca.notario;
              break;
            case 6:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ', autorizada en el municipio de ' + finca.tcMunicipioEmite.municipioDesc 
              + ' del departamento de ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy')
              + ' por el (la) autoridad municipal ' + finca.notario;
              break;
            case 7:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ', autorizada en el municipio de ' + finca.tcMunicipioEmite.municipioDesc 
              + ' del departamento de ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy')
              + ' por el (la) autoridad municipal ' + finca.notario;
              break;
            default:
              textoFinca += 'posesión ';
              acreditada = finca.tcTipoPropiedad.tipoPropiedadDesc + ' de fecha ' + $filter('date')(finca.fechaEmision, 'dd/MM/yyyy');
              break;
          }
          var textoFincaSinDocumento = textoFinca + ' denominada ' + finca.tcFinca.fincaDesc + '; ubicada en ' + finca.tcFinca.direccion + ', municipio de '
            + finca.tcFinca.tcMunicipio.municipioDesc + ', departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
          textoFinca += ' denominada ' + finca.tcFinca.fincaDesc + '; acreditado mediante ' + acreditada
            + '; ubicada en ' + finca.tcFinca.direccion + ', municipio de '
            + finca.tcFinca.tcMunicipio.municipioDesc + ', departamento de ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;

          var totalArboles = 0, totalTroza = 0, totalLenia = 0, total = 0;
          body = [
            [
              { text: 'II. Especies a aprovechar', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ],
            [
              { text: 'Nombre común', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Nombre científico', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Troza (m³)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Leña (m³)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen (m³)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
            ]
          ];
          for (i = 0; i < ttConsumo.especies.length; i++) {
            row = ttConsumo.especies[i];
            item = [
              { text: row.tcEspecie.especieDesc, style: 'tableSubHeader', alignment: 'left' },
              { text: row.tcEspecie.nombreCientifico, style: 'tableSubHeader', alignment: 'left' },
              { text: row.totalArboles, style: 'tableSubHeader', alignment: 'center' },
              { text: row.troza, style: 'tableSubHeader', alignment: 'center' },
              { text: row.lenia, style: 'tableSubHeader', alignment: 'center' },
              { text: row.volumen, style: 'tableSubHeader', alignment: 'center' }
            ];
            body.push(item);
            totalArboles += parseInt(row.totalArboles);
            totalTroza += parseFloat(row.troza);
            totalLenia += parseFloat(row.lenia);
            total += parseFloat(row.volumen);
          }
          item = [
            { text: 'TOTAL:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', colSpan: 2 }, {},
            { text: totalArboles, style: 'tableSubHeader', alignment: 'center' },
            { text: parseFloat(totalTroza).toFixed(4), style: 'tableSubHeader', alignment: 'center' },
            { text: parseFloat(totalLenia).toFixed(4), style: 'tableSubHeader', alignment: 'center' },
            { text: parseFloat(total).toFixed(4), style: 'tableSubHeader', alignment: 'center' }
          ];
          body.push(item);
          var tbEspecie = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [130, 100, 50, 50, 50, 60],
              body: body
            }
          }
          body = [
            [
              { text: 'III. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 3, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}
            ],
            [
              { text: 'No.', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Tipo de notificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Descripción', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var oficina = {};
          var fechaFinalizacion = new Date(ttTareaConsumo.fechaFinalizacion);
          if (ttConsumo.paraSubregion == 1) {
            oficina = {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttConsumo.tcSubregion.subregionDesc
                + ", CON SEDE EN EL MUNICIPIO DE " + ttConsumo.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE "
                + getMonth(fechaFinalizacion.getMonth() + 1).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            };
          } else {
            oficina = {
              text: "MUNICIPALIDAD DE " + ttConsumo.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE "
                + getMonth(fechaFinalizacion.getMonth() + 1).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            };
          }
          var nombreSalida = 'resolucion.pdf';
          var content = [
            {
              text: 'RESOLUCIÓN DE AUTORIZACIÓN PARA EL APROVECHAMIENTO FORESTAL DE CONSUMO FAMILIAR',
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'RESOLUCIÓN No. ' + (ttTareaConsumo.codigo == null ? 'XXXXXXX-XXXX' : ttTareaConsumo.codigo),
              style: 'derecha'
            }, '\n', oficina, '\n',
            {
              text: textoPrimerParrafo + ' En ' + textoFinca + '.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n'
          ];
          if (ttConsumo.paraSubregion == 1) {
            content.push(
              {
                text: 'Que de conformidad con el Decreto del Congreso de la República número 101-96, Ley Forestal, el Instituto Nacional de Bosques es una entidad estatal, ' 
                + 'autónoma, descentralizada, con personalidad jurídica, patrimonio propio e independencia administrativa, es el órgano de dirección y autoridad competente  ' 
                + 'del sector Público Agrícola, en materia forestal. Asimismo, que la resolución emitida por Junta Directiva de INAB JD.03.40.2023, Reglamento para el  ' 
                + 'Aprovechamiento Forestal de Consumo Familiar, establece que el objeto de la autorización para el Aprovechamiento Forestal de Consumo Familiar,  ' 
                + 'es respaldar a través del documento correspondiente, el aprovechamiento forestal con fines de satisfacer las necesidades domésticas de una familia.',
                style: 'izquierda'
              }
            );
            content.push('\n');
          } else {
            content.push(
              {
                text: 'Que el Decreto del Congreso de la República de Guatemala Número 12-2002, Código Municipal, establece que el Municipio como institución autónoma ' 
                + 'de derecho público, tiene personalidad jurídica y capacidad para adquirir derechos y contraer obligaciones; así mismo establece que entre las ' 
                + 'competencias del Municipio se encuentra la promoción, protección de los recursos naturales renovables y no renovables del Municipio y todas aquellas ' 
                + 'que por mandato de ley, le sea trasladada la titularidad de la competencia en el proceso de descentralización.',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "CONSIDERANDO",
                style: 'centrar'
              }
            );
            content.push('\n');
            content.push(
              {
                text: 'Que de conformidad con el Decreto del Congreso de la República número 101-96, Ley Forestal, en sus artículos 8 y 58, regula que las Municipalidades ' 
                + 'serán las encargadas de apoyar al Instituto Nacional de Bosques en la aplicación de la Ley Forestal, en ningún caso serán instancias de decisión a excepción ' 
                + 'de las disposiciones legalmente establecidas. Asimismo, que la resolución emitida por Junta Directiva de INAB JD.03.40.2023, Reglamento para el ' 
                + 'Aprovechamiento Forestal de Consumo Familiar, establece que el objeto de la autorización para el Aprovechamiento Forestal de Consumo Familiar, es respaldar ' 
                + 'a través del documento correspondiente, el aprovechamiento forestal con fines de satisfacer las necesidades domésticas de una familia.',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(
              {
                text: "CONSIDERANDO",
                style: 'centrar'
              }
            );
            content.push('\n');
            var strPartes = ttConsumo.ttConsumoInformacion.tcConvenioMunicipal.fechaSuscripcion.toString().split('-');
            content.push(
              {
                text: 'Que la municipalidad de ' + ttConsumo.tcMunicipio.municipioDesc + ', del departamento de ' + ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc 
                  + ' y el Instituto Nacional de Bosques suscribieron ' + ttConsumo.ttConsumoInformacion.tcConvenioMunicipal.tipoConvenioDesc 
                  + ' identificado como No. ' + ttConsumo.ttConsumoInformacion.tcConvenioMunicipal.convenioMunicipalDesc
                  + ' de fecha ' + strPartes[2] + '/' + strPartes[1] + '/' + strPartes[0]
                  + ' conviniendo que la municipalidad participe directamente en el otorgamiento de autorizaciones de Aprovechamiento Forestal de Consumo Familiar, ' 
                  + 'a través de una Resolución que debe emitirse para el efecto.',
                style: 'izquierda'
              }
            );
            content.push('\n');
          }
          var aprobacion = 'APROBACIÓN';
          var ttPadre = ttTareaConsumo.ttPadre;
          if (ttPadre.aprobado == 0) {
            aprobacion = 'NO APROBACIÓN';
          }
          var fechaDictamen = new Date(ttPadre.fechaFinalizacion);
          content.push(
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }
          );
          content.push('\n');
          content.push(
            {
              text: [
                {
                  text: 'Que con fecha ' + fechaSolicitud.getDate() + ' de ' + getMonth(fechaSolicitud.getMonth() + 1) + ' de ' + fechaSolicitud.getFullYear()
                    + ', ' + sexo + ttConsumo.tcSolicitante.personaDesc + ' presentó solicitud para Aprovechamiento Forestal de Consumo Familiar, en ' + textoFincaSinDocumento
                    + ', el cual cumple con los requisitos establecidos, expediente que fue analizado y verificado en campo por el Técnico Forestal '
                    + ttPadre.tcPersonaAsignada.personaDesc + ' recomendando ',
                  style: 'izquierda'
                },
                {
                  text: aprobacion,
                  bold: true
                },
                {
                  text: ' mediante dictamen número ' + ttPadre.codigo + ' de fecha ' + fechaDictamen.getDate() + ' de ' + getMonth(fechaDictamen.getMonth() + 1) + ' de ' + fechaDictamen.getFullYear() + '.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              text: "POR TANTO",
              style: 'centrar'
            }
          );
          content.push('\n');
          if (ttConsumo.paraSubregion == 1) {
            content.push(
              {
                text: 'Con base a lo considerado y preceptuado por los artículos 28, 30, 125, 126 de la Constitución Política de la República de Guatemala; 1, 2, 3 y 4 de la ley ' 
                + 'de lo contencioso administrativo 1, 2, 3, 4, 5, 6, 49 y 67 del Decreto del Congreso de la Republica 101-96 Ley Forestal; 1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, ' 
                + '15, 16, 17, 18, 19 de la resolución emitida por la Junta Directiva No. JD.03.40.2023, Reglamento para el Aprovechamiento de Consumo Familiar.',
                style: 'izquierda'
              }
            );
          } else {
            content.push(
              {
                text: 'Con base a lo considerado y preceptuado por los artículos 28, 30, 125, 126 de la Constitución Política de la República de Guatemala; artículos: 1, 2, 3 y 4 ' 
                + 'de la ley de lo contencioso administrativo; artículos: 1, 2, 9, 35 literales d), e), i), k), s), t), y); 42, 52, 53, 68 literales k), I); 70 literal d); ' 
                + '90 y 143 del Decreto del Congreso de la República Número 12-2002, Código Municipal; 5, 6, 8, 58 y 67 del Decreto del Congreso de la Republica 101-96 ' 
                + 'Ley Forestal; 1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 de la resolución emitida por la Junta Directiva No. JD.03.40.2023, ' 
                + 'Reglamento para el Aprovechamiento de Consumo Familiar.',
                style: 'izquierda'
              }
            );
          }
          content.push('\n');
          content.push(
            {
              text: "RESUELVE",
              style: 'centrar'
            }
          );
          content.push('\n');
          var resuelve = 'APROBAR';
          if (ttTareaConsumo.aprobado == 0) {
            resuelve = 'NO APROBAR';
          }
          content.push(
            {
              ol: [
                {
                  text: resuelve + ' la solicitud para el Aprovechamiento Forestal de Consumo Familiar ' + sexo + ttConsumo.tcSolicitante.personaDesc
                    + ', en ' + textoFincaSinDocumento,
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              start: 2,
              ol: [
                {
                  text: 'Las actividades a realizar se determinan de conformidad con el cuadro siguiente:',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(tbEspecie);
          var uso = '';
          switch (ttConsumo.ttConsumoInformacion.usoProductoId) {
            case 1:
              uso = 'combustible';
              break;
            case 2:
              uso = 'construcción de vivienda';
              break;
            case 3:
              uso = 'combustible y construcción de vivienda';
              break;
            default:
              uso = 'combustible';
              break;
          }
          content.push(
            {
              text: 'El uso final del producto es: ' + uso,
              style: 'izquierda',
              alignment: 'center',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              start: 3,
              ol: [
                {
                  text: 'El titular queda facultado si lo prefiere, convertir su volumen autorizado en pie, en productos como, Troza, Poste, Trocilla, a Block o flitch, ' 
                  + 'debiendo aplicar el 50% de eficiencia, a tablón el 45%, a tabla y regla el 39 % y a carbón el 60 %, el resto de producto deberá cargarse a leña, lepa y aserrín.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              start: 4,
              ol: [
                {
                  text: 'El titular es responsable de la correcta utilización de la presente resolución y el cumplimiento de lo establecido en el artículo 18 del reglamento para el Aprovechamiento forestal de Consumo Familiar. Asimismo, se establece que el solicitante deberá plantar un mínimo de '
                    + ttConsumo.ttConsumoInformacion.repoblacion + ' árboles por cumplimiento de la repoblación forestal.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              start: 5,
              ol: [
                {
                  text: 'La presente resolución tendrá vigencia de ' + ttConsumo.ttConsumoInformacion.diasVigencia + ' días calendario, a partir de la fecha de su emisión.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              start: 6,
              ol: [
                {
                  text: 'Notifíquese.',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'La resolución podrá ser accedida escaneando el siguiente código QR:',
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + ' ' + ttTareaConsumo.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          if (ttConsumo.paraSubregion == 1) {
            content.push(
              {
                text: "Director Subregional ",
                style: 'centrar'
              }
            );
            content.push(
              {
                text: "Subregión " + ttConsumo.tcSubregion.subregionDesc,
                style: 'centrar'
              }
            );
          } else {
            content.push(
              {
                text: ttTareaConsumo.tcPersonaAsignada.tcPuesto.puestoDesc,
                style: 'centrar'
              }
            );
            content.push(
              {
                text: "Municipalidad de " + ttConsumo.tcMunicipio.municipioDesc,
                style: 'centrar'
              }
            );
          }
          content.push('\n');
          content.push(
            {
              qr: appSettings.urlBase + 'validaConsumo/' + cryptText(appSettings.seed, ttConsumo.consumoId),
              fit: '100',
              style: 'small',
              alignment: 'center'
            }
          );
          var watermark = {};
          if (gblEsPrevia || appSettings.isDevelopment) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarCedulaNotificacionConsumo: function (ttTareaConsumo, usuarioDesc, dataEmpresa) {
          var nombreSalida = 'cedula.pdf';
          var fecha = new Date();
          var mes = parseInt(fecha.getMonth()) + 1;
          var content = [];
          var solicitante = "";
          var ttConsumo = ttTareaConsumo.ttConsumo;
          var strCorreo = ttConsumo.tcSolicitante.correo;
          for (i = 0; i < ttConsumo.notificaciones.length; i++) {
            var notificacion = ttConsumo.notificaciones[i];
            if (strCorreo == null) {
              if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
                strCorreo = notificacion.notificacionConsumoDesc;
              }
            } else {
              if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
                strCorreo += "; " + notificacion.notificacionConsumoDesc;
              }
            }
          }
          var item = {
            text: "\n\n",
            style: 'centrar'
          };
          content.push(item);
          content.push('\n\n\n');
          item = {
            text: "CÉDULA DE NOTIFICACIÓN",
            style: 'centrar'
          };
          var municipioDesc = '', departamentoDesc = '', oficina = '';
          if (ttConsumo.paraSubregion == 1) {
            municipioDesc = ttConsumo.tcSubregion.tcMunicipio.municipioDesc;
            departamentoDesc = ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc;
            oficina = 'Dirección Subregional ' + ttConsumo.tcSubregion.subregionDesc;
          } else {
            municipioDesc = ttConsumo.tcMunicipio.municipioDesc;
            departamentoDesc = ttConsumo.tcMunicipio.tcDepartamento.departamentoDesc;
            oficina = 'Municipalidad de ' + ttConsumo.tcMunicipio.municipioDesc;
          }
          content.push(item);
          content.push('\n\n');
          item = {
            text: "En el municipio de " + municipioDesc + ", del departamento de " + departamentoDesc + ", del día ______/______/______ siendo las __________ horas, constituidos en las oficinas de la "
              + oficina + ", NOTIFICO A: " + ttConsumo.tcSolicitante.personaDesc + ", el contenido íntegro de la resolución número " + (ttTareaConsumo.codigo == null ? 'XXXXX-XXXX' : ttTareaConsumo.codigo)
              + ", por cédula de notificación entregada a: ____________________________________ quien recibe y firma de conformidad la presente cédula de notificación.",
            style: 'sencillo'
          };
          content.push(item);
          content.push('\n\n\n\n');
          var puestoEntrega = 'Secretaria(o) I Subregional';
          if (ttConsumo.paraSubregion == 0) {
            puestoEntrega = 'Tecnico Municipal';
          }
          item = {
            columns: [
              {
                width: 250,
                text: 'F _________________________________\n\n' + puestoEntrega,
                style: 'centrar'
              },
              {
                width: 250,
                text: 'F _________________________________\n\nNombre quien recibe',
                style: 'centrar'
              }
            ]
          };
          content.push(item);
          content.push(
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          if (strCorreo != null) {
            content.push('\n');
            if (ttTareaConsumo.fechaFinalizacion == null) {
              ttTareaConsumo.fechaFinalizacion = new Date();
            } else {
              ttTareaConsumo.fechaFinalizacion = new Date(ttTareaConsumo.fechaFinalizacion);
            }
            fecha = ttTareaConsumo.fechaFinalizacion;
            mes = parseInt(fecha.getMonth()) + 1;
            content.push(
              {
                text: 'El presente documento fue notificado vía electrónico al correo: ' + strCorreo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " + getMonth(mes) + " de " + fecha.getFullYear() + " a las " + $filter('date')(fecha, 'HH:mm') + " horas",
                style: 'small', alignment: 'left'
              }
            );
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              sencillo: {
                fontSize: 12,
                alignment: 'left'
              },
              negrilla: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        notificacionPagoVentaConsumo: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == null) {
            sigla = "";
          }
          var ttConsumoVenta = ttTarea.ttConsumoVenta;
          var ttConsumo = ttTarea.ttConsumo;
          var nombreSalida = 'notificacion.pdf';
          var fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var i, j;
          var content = [
            {
              text: "Notificación de pago de Notas de Envío de Consumos Familires",
              style: 'centrar'
            }, '\n',
            {
              text: ttConsumo.tcSubregion.tcMunicipio.municipioDesc + ", " + fechaFinalizacion.getDate() + " de " + getMonth(mes) + " de " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n\n\n',
            {
              text: "Sr./Sra. " + ttConsumo.tcSolicitante.personaDesc,
              style: 'izquierda'
            },
            {
              text: "Presente",
              style: 'izquierda'
            }, '\n',
            {
              text: "NOTIFICACIÓN",
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'Estimado (a): ' + ttConsumo.tcSolicitante.personaDesc + '. A través del presente le notifico que de acuerdo a su solicitud número '
                + ttConsumoVenta.correlativo + '-' + ttConsumoVenta.anio + ', se aprobó la venta de ' + ttConsumoVenta.notas
                + ' Notas de Envío de Consumo Familiar para el transporte de producto forestal. '
                + 'Previo a recoger las notas de envío autorizadas usted deberá cancelar al Fondo Forestal Privativo INAB, número de cuenta 3033330062 de Banrural, la cantidad de Q. '
                + formatNumber.new(parseFloat(ttConsumoVenta.pago).toFixed(2)),
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente,",
              style: 'izquierda'
            }
          ];
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: "Director subregional " + ttConsumo.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push('\n\n');
          content.push(
            {
              text: 'Nota',
              style: 'centrar',
              bold: true
            }
          );
          content.push(
            {
              text: 'Los usuarios que no hayan reportado los depósitos realizados al Fondo Forestal Privativo INAB en los 60 días calendario (2 meses), no podrán reclamar la devolución o tramite a la gestión que corresponda.',
              style: 'izquierda'
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Comunicarse a oficina subregional correspondiente para programar la fecha y hora de entrega de resolución y notas de envío correspondientes.',
              style: 'izquierda',
              bold: true
            }
          );
          content.push('\n');
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        resolucionVentaConsumoFamiliar: function (ttTareaConsumo, strDia, strAnio, usuario, dataEmpresa, sigla) {
          var ttConsumo = ttTareaConsumo.ttConsumo;
          var ttConsumoVenta = ttTareaConsumo.ttConsumoVenta;
          var ttPadre = ttTareaConsumo.ttTarea;
          if (ttConsumoVenta.fechaAprobacion == null) {
            ttConsumoVenta.fechaAprobacion = new Date();
          }
          var ttTareaResolucion = ttTareaConsumo.ttPadre;
          var fechaResolucion = new Date(ttTareaResolucion.fechaFinalizacion);
          var fechaAprobacion = new Date(ttConsumo.fechaAprobacion);
          var i, j;
          var textoFinca = "";
          var textoPrimerParrafo = '';
          var sigla = ttConsumo.tcPersonaDirigida.sigla;
          if (sigla == null) {
            sigla = '';
          } else {
            sigla += ' ';
          }
          var textoEmitada = '';
          if (ttConsumo.paraSubregion == 1) {
            textoEmitada = 'la oficina subregional ' + ttConsumo.tcSubregion.subregionDesc;
          } else {
            textoEmitada = 'la municipalidad de ' + ttConsumo.tcMunicipio.municipioDesc;
          }
          var strFechaNacimiento = $filter('date')(new Date(ttConsumo.tcSolicitante.fechaNacimiento), 'yyyy-MM-dd');
          var strFechaInicio = $filter('date')(new Date(ttConsumo.fechaSolicitud), 'yyyy-MM-dd');
          var edad = calculaEdad(strFechaNacimiento, strFechaInicio);
          textoPrimerParrafo = 'Se tiene a la vista la solicitud de ' + ttConsumo.tcSolicitante.personaDesc
            + ', para resolver la autorización de Notas de Envío para el transporte del producto forestal proveniente de la resolución de Consumo Familiar número '
            + ttTareaResolucion.codigo + ', emitida por ' + textoEmitada + ' en fecha ' + fechaResolucion.getDate() + ' de ' + getMonth(fechaResolucion.getMonth() + 1)
            + ' de ' + fechaResolucion.getFullYear() + '. La cual consta en expediente administrativo número ' + ttConsumo.expediente + '.';
          var inicio = 'XXX', fin = 'XXX';
          if (ttConsumoVenta.estadoId == 4) {
            var correlativos = ttConsumoVenta.correlativos;
            inicio = 0;
            fin = 0;
            for (var i = 0; i < correlativos.length; i++) {
              var row = correlativos[i];
              if (i == 0) {
                inicio = row.numero;
                fin = row.numero;
              }
              if (row.numero < inicio) {
                inicio = row.numero;
              }
              if (row.numero > fin) {
                fin = row.numero;
              }
            }
          }
          body = [
            [
              { text: 'Cantidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Del', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Al', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Factura', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
            ],
            [
              { text: ttConsumoVenta.notas, style: 'tableSubHeader', alignment: 'center' },
              { text: inicio, style: 'tableSubHeader', alignment: 'center' },
              { text: fin, style: 'tableSubHeader', alignment: 'center' },
              { text: ttConsumoVenta.serieFactura + ttConsumoVenta.numeroFactura, style: 'tableSubHeader', alignment: 'center' },
            ]
          ];
          var tbNotas = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [100, 110, 110, 150],
              body: body
            }
          }
          var fechaFinalizacion = new Date(ttTareaConsumo.fechaFinalizacion);
          var oficina = {
            text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttConsumo.tcSubregion.subregionDesc
              + ", CON SEDE EN EL MUNICIPIO DE " + ttConsumo.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
              + " DEL DEPARTAMENTO DE " + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
              + ", " + strDia + " DIAS DEL MES DE "
              + getMonth(fechaFinalizacion.getMonth() + 1).toUpperCase() + " DE " + strAnio,
            style: 'izquierda'
          };
          var nombreSalida = 'resolucion.pdf';
          var content = [
            {
              text: 'RESOLUCIÓN DE AUTORIZACIÓN DE VENTA DE NOTAS DE ENVÍO DE CONSUMO FAMILIAR',
              style: 'centrar',
              bold: true
            }, '\n',
            {
              text: 'RESOLUCIÓN No. ' + (ttTareaConsumo.codigo == null ? 'XXXXXXX-XXXX' : ttTareaConsumo.codigo),
              style: 'derecha'
            }, '\n', oficina, '\n',
            {
              text: textoPrimerParrafo,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Reglamento de Transporte de Productos Forestales y su Procedencia Lícita, en su artículo 4, establece la Nota de Envío Es el documento ' 
              + 'emitido por el INAB que ampara el transporte de los productos forestales y su procedencia, la cual podrá denominarse indistintamente Nota de Envió. ' 
              + 'Así mismo el Reglamento de Consumos Familiares vigente establece en el artículo 13 El Director Subregional del INAB, podrá otorgar las Notas de ' 
              + 'Envío necesarias, las cuales se deben utilizar dentro de la jurisdicción departamental donde se realizó el Aprovechamiento Forestal de Consumo Familiar.',
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Con base a lo considerado y preceptuado por los artículos: 3, 5, 6, 48, 49, 50 y 62 del Decreto del Congreso de la Republica 101-96 Ley Forestal; ' 
              + 'artículos: 1, 2, 3, y 4 del Decreto Número 119-96 del Congreso de La Republica, Ley de lo Contencioso Administrativo; artículos: 1, 2, 3, 4, 5, 6, y 15 del ' 
              + 'Reglamento para el Transporte de Productos Forestales y su Procedencia Lícita; artículos: 13 y 14 de la resolución emitida por la Junta Directiva ' 
              + 'No. JD.03.40.2023, Reglamento para el Aprovechamiento de Consumo Familiar.',
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              text: 'Autorizar a ' + ttConsumo.tcSolicitante.personaDesc + ', ' + ttConsumoVenta.notas +
               ' Notas de envío de Consumo Familiar, para el transporte del producto forestal proveniente de la resolución de consumo Familiar número '
                + ttTareaResolucion.codigo + ', según correlativo (s) siguiente (s).',
              style: 'izquierda'
            }, '\n', tbNotas, '\n\n\n\n',
            {
              text: "Director Subregional ",
              style: 'centrar'
            },
            {
              text: "Subregión " + ttConsumo.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          ];
          var watermark = {};
          if (gblEsPrevia || appSettings.isDevelopment) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              medium: {
                fontSize: 8,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 9,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableRowSmall: {
                bold: true,
                fontSize: 9,
                color: 'black'
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        constanciaRecepcionConsumo: function (ttConsumo, usuario, dataEmpresa) {
          var nombreSalida = 'constancia.pdf';
          var fechaRecepcion = new Date(ttConsumo.fechaSolicitud);
          var fecha = new Date();
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          body = [
            [
              { text: 'I. DATOS GENERALES DEL EXPEDIENTE', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ],
            [
              { text: 'Lugar y Fecha', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttConsumo.tcSubregion.tcMunicipio.municipioDesc + ', ' + fechaRecepcion.getDate() + ' de ' + getMonth(mes) + ' de ' + fechaRecepcion.getFullYear(), style: 'izquierda' }
            ],
            [
              { text: 'No. de expediente', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttConsumo.expediente, style: 'izquierda' }
            ],
            [
              { text: 'Nombre del solicitante:', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: ttConsumo.tcSolicitante.personaDesc, style: 'izquierda' }
            ],
            [
              { text: 'II. Datos de notificación', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000', colSpan: 2 }, {}
            ]
          ];
          //obtener los tipos de notificacion
          var lista = [];
          var item = [];
          var encontrado = false;
          for (i = 0; i < ttConsumo.notificaciones.length; i++) {
            encontrado = false;
            for (j = 0; j < lista.length; j++) {
              if (ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                encontrado = true;
                break;
              }
            }
            if (!encontrado) {
              lista.push(
                {
                  tipoNotificacionId: ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionId,
                  tipoNotificacionDesc: ttConsumo.notificaciones[i].tcTipoNotificacion.tipoNotificacionDesc
                }
              );
            }
          }
          var strDescripcion = "";
          var tmpDataNotificacion = angular.copy(ttConsumo.notificaciones);
          var tmpData = tmpDataNotificacion;
          for (j = 0; j < lista.length; j++) {
            strDescripcion = "";
            tmpDataNotificacion = angular.copy(tmpData);
            tmpData = [];
            for (i = 0; i < tmpDataNotificacion.length; i++) {
              if (tmpDataNotificacion[i].tcTipoNotificacion.tipoNotificacionId == lista[j].tipoNotificacionId) {
                if (strDescripcion.length <= 0) {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = tmpDataNotificacion[i].notificacionConsumoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = tmpDataNotificacion[i].notificacionConsumoDesc;
                  }
                } else {
                  if (tmpDataNotificacion[i].tcMunicipio != null) {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionConsumoDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.municipioDesc + ', ' + tmpDataNotificacion[i].tcMunicipio.tcDepartamento.departamentoDesc;
                  } else {
                    strDescripcion = strDescripcion + "\n" + tmpDataNotificacion[i].notificacionConsumoDesc;
                  }
                }
              } else {
                //se agrega a la lista quitando las que ya se tomaron en cuenta
                tmpData.push(tmpDataNotificacion[i]);
              }
            }
            item = [
              { text: lista[j].tipoNotificacionDesc, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: strDescripcion, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaGenerales = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [190, 310],
              body: body
            }
          }
          //tabla codigos
          body = [
            [
              { text: 'III.', style: 'izquierda', color: '#FFFFFF', fillColor: '#000000' },
              { text: 'Gestión Administrativa', style: 'izquierda', colSpan: 3, color: '#FFFFFF', fillColor: '#000000' }, {}, {}
            ],
            [
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción de la gestión', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'COD', style: 'izquierda', fillColor: '#CCCCCC' },
              { text: 'Descripción específica de la gestión', style: 'izquierda', fillColor: '#CCCCCC' }
            ],
            [
              { text: ttConsumo.tcTipoGestion.tcModulo.codigo, style: 'centrar' },
              { text: ttConsumo.tcTipoGestion.tcModulo.moduloDesc, style: 'izquierda' },
              { text: ttConsumo.tcTipoGestion.codigo, style: 'izquierda' },
              { text: ttConsumo.tcTipoGestion.tipoGestionDesc, style: 'izquierda' }
            ]
          ];
          var tablaCodigos = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [30, 200, 50, 200],
              body: body
            }
          }
          body = [
            [
              { text: 'IV. DOCUMENTOS RECIBIDOS.', style: 'izquierda', colSpan: 2, color: '#FFFFFF', fillColor: '#000000' }, {}
            ]
          ];
          for (var i = 0; i < ttConsumo.anexos.length; i++) {
            item = [
              { text: i + 1, style: 'izquierda', fillColor: '#CCCCCC', alignment: 'left' },
              { text: ttConsumo.anexos[i].tcRequisito.requisitoDesc, style: 'izquierda', alignment: 'left' }
            ];
            body.push(item);
          }
          var tablaRequisito = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [20, 480],
              body: body
            }
          }

          var content = [
            {
              columns: [
                {
                  width: 60,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: "\n\n",
                  style: 'centrar'
                }
              ]
            },
            {
              text: "CONSTANCIA DE RECEPCIÓN DE DOCUMENTOS",
              style: 'centrar'
            }, '\n',
            {
              text: "DIRECCIÓN SUBREGIONAL: " + ttConsumo.tcSubregion.subregionDesc,
              style: 'derecha'
            },
            {
              text: "EXPEDIENTE No. " + ttConsumo.expediente,
              style: 'derecha'
            }, '\n',
            tablaGenerales, '\n', tablaCodigos, '\n', tablaRequisito, '\n',
            {
              text: "El solicitante debe considerar que la presente no constituye un documento para iniciar con el trámite del expediente y toda vez que el mismo no contenga los requisitos legales, no podrá ser objeto de trámite correspondiente",
              style: 'izquierda'
            }, '\n',
            {
              text: 'Impreso por: ' + usuario + '\t' + 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [50, 20, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 9,
                  alignment: 'center'
                }
              ]
            },
            content: content,
            styles: {
              centrar: {
                fontSize: 9,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 9,
                bold: true,
                alignment: 'left'
              },
              derecha: {
                fontSize: 9,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        resolucionAdmisionConsumo: function (ttTarea, strDiaAdmision, strAnioAdmision, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var nombreSalida = 'adminsion.pdf';
          var ttConsumo = ttTarea.ttConsumo;
          var fechaRecepcion = new Date(ttConsumo.fechaSolicitud);
          if (ttTarea.fechaFinalizacion == null) {
            ttTarea.fechaFinalizacion = new Date();
          } else {
            ttTarea.fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          }
          var fechaAdmision = ttTarea.fechaFinalizacion;
          var mes = parseInt(fechaRecepcion.getMonth()) + 1;
          var mesAdmision = parseInt(fechaAdmision.getMonth()) + 1;
          var fecha = new Date();
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN DE ADMISIÓN PARA TRÁMITE DE EXPEDIENTE ADMINISTRATIVO",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución No. " + (ttTarea.observaciones == null ? 'XXXXX' : ttTarea.observaciones),
              style: 'derecha'
            },
            {
              text: "No. Expediente: " + ttConsumo.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttConsumo.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE " + ttConsumo.tcSubregion.tcMunicipio.municipioDesc.toUpperCase()
                + " DEL DEPARTAMENTO DE " + ttConsumo.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDiaAdmision + " DIAS DEL MES DE "
                + getMonth(mesAdmision).toUpperCase() + " DE " + strAnioAdmision,
              style: 'izquierda'
            }, '\n',
            {
              text: 'Se tiene a la vista la solicitud presentada por: '
                + ttConsumo.tcSolicitante.personaDesc + ', quien se identifica con el Documento de Identificación Personal -DPI- y Código Único de Identificación -CUI- '
                + ttConsumo.tcSolicitante.cui + ' extendido por el Registro Nacional de las Personas de la República de Guatemala, quien ha solicitado Resolución de Aprovechamiento de Consumo Familiar',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: "Que " + ttConsumo.tcSolicitante.personaDesc + ", con fecha " + fechaRecepcion.getDate() + " dias del mes de " + getMonth(mes) + " de " + fechaRecepcion.getFullYear()
                + ", gestionó ante el Instituto Nacional de Bosques, Resolución de Aprovechamiento Forestal de Consumo Familiar. Habiendo cumplido con los requisitos de Ley establecidos.",
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: "Con base a lo considerado y preceptuado en los artículos 1, 2, 4, 49 del Decreto  Legislativo 101-96 Ley Forestal, artículo 53 del Reglamento de la Ley Forestal vigente, artículos 1, 2, 3, 4, 5, 7, 9, 10 del Reglamento de Consumo Familiar resolución de Junta Directiva JD.03.37.2015. Esta Dirección Subregional...",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: [
                    { text: "Admitir para su trámite la solicitud presentada por: " + ttConsumo.tcSolicitante.personaDesc + ", en virtud de cumplir con los requisitos establecidos en la legislación forestal vigente.", style: 'izquierda' },
                    { text: "Trasládese el presente expediente administrativo, para su respectivo análisis técnico de la documentación correspondiente.", style: 'izquierda' },
                    { text: "Notifíquese.", style: 'izquierda' }
                  ]
                }
              ]
            }, '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttConsumo.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n',
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = ""//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        enmiendaTecnicaExento: function (ttTareaExento, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == null) {
            sigla = '';
          }
          var nombreSalida = 'enmienda.pdf';
          var fechaFinalizacion = new Date(ttTareaExento.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var body = [];
          var tmpData = ttTareaExento.observaciones.razon;
          var ttExento = ttTareaExento.ttExento;
          var personas = ttExento.propietarios;
          var titular = '';
          for (var i = 0; i < personas.length; i++) {
            titular += personas[i].tcPersona.personaDesc + '\n';
          }
          var representantes = ttExento.representantes;
          var representante = '';
          for (var i = 0; i < representantes.length; i++) {
            representante += representantes[i].tcPersona.personaDesc + '\n';
          }
          body = [
            [
              { text: 'Código de RNF: ' + ttExento.codigo, style: 'izquierda' },
              { text: 'Número de resolución: ' + ttExento.resolucion, style: 'izquierda' },
              { text: 'Área registrada: ' + ttExento.area, style: 'izquierda' }
            ]
          ];
          var fincas = ttExento.fincas;
          for (var i = 0; i < fincas.length; i++) {
            finca = fincas[i];
            body.push(
              [
                { text: '\nNombre finca: ' + finca.tcFinca.fincaDesc, style: 'izquierda' },
                { text: '\nUbicación: ' + finca.tcFinca.direccion, style: 'izquierda', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Departamento: ' + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'izquierda' },
                { text: 'Municipio: ' + finca.tcFinca.tcMunicipio.municipioDesc, style: 'izquierda' },
                { text: 'GTM X: ' + finca.tcFinca.gtmX + ', GTM Y: ' + finca.tcFinca.gtmY, style: 'izquierda' }
              ]
            );
          }
          var tablaGeneral = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [160, 160, 160],
              body: body
            }, layout: 'noBorders'
          }
          body = [];
          if (tmpData == null) {
            tmpData = [];
          }
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }          
          var tbEnmienda = {};
          if (body.length > 0) {
            tbEnmienda = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 0,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          }
          if (ttTareaExento.tcPersonaTraslado == null) {
            ttTareaExento.tcPersonaTraslado = {
              personaDesc: '',
              sigla: ''
            };
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\nDIRECCION SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + '\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS',
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + ttTareaExento.codigo,
              style: 'derecha'
            },
            {
              text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ", " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", " + getMonth(mes) + " " + fechaFinalizacion.getDate() + " de " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: ttTareaExento.tcPersonaTraslado.sigla + ' ' + ttTareaExento.tcPersonaTraslado.personaDesc + "\nDirector Subregional " + ttExento.tcSubregion.subregionDesc + "\nINAB, " 
              + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio le informo que luego de la revisión y análisis del expediente administrativo No. " + ttExento.expediente
                + " y número de registro " + ttExento.codigo
                + ",  que se encuentra inscrito en el Registro Nacional Forestal; es necesario realizar lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmienda, '\n',
            {
              text: "Por lo anterior, solicito a su persona, requerir la información al solicitante, previo continuar  con el trámite del expediente administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n',
            {
              text: ttTareaExento.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Técnico forestal",
              style: 'centrar'
            }, '\n',
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = '';
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        generarResolucionCambioNota: function (ttCambioNota, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var fechaSolicitud = new Date(ttCambioNota.fechaRegistro);
          var mesSolicitud = parseInt(fechaSolicitud.getMonth()) + 1;
          var nombreSalida = 'resolucion.pdf';
          var fechaResolucion = new Date(ttCambioNota.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaResolucion.getMonth()) + 1;
          var textoDireccion = "";
          var notas = ttCambioNota.notas;
          var rangos = ttCambioNota.rangos;
          var body = [
            [
              { text: 'Cantidad', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Correlativo inicial', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Correlativo final', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var total = 0;
          for (let i = 0; i < rangos.length; i++) {
            const element = rangos[i];
            total += element.cantidad;
            body.push(
              [
                { text: element.cantidad, style: 'medium', alignment: 'center' },
                { text: element.inicio, style: 'medium', alignment: 'center' },
                { text: element.fin, style: 'medium', alignment: 'center' }
              ]
            );
          }
          var tbRangos = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [80, 100, 100],
              body: body
            }
          };
          body = [
            [
              { text: 'Cantidad', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Correlativo inicial', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Correlativo final', style: 'tableHeaderSmall', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (let i = 0; i < notas.length; i++) {
            const element = notas[i];
            body.push(
              [
                { text: element.cantidad, style: 'medium', alignment: 'center' },
                { text: element.inicio, style: 'medium', alignment: 'center' },
                { text: element.fin, style: 'medium', alignment: 'center' }
              ]
            );
          }
          var tbNotas = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [80, 100, 100],
              body: body
            }
          };
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nRESOLUCIÓN PARA LA SUSTITUCIÓN DE NOTAS DE ENVÍO DE LICENCIA O CONCESIÓN FORESTAL ",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Resolución No. " + ttCambioNota.resolucion,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttCambioNota.tcSubregion.subregionDesc
                + ", CON SEDE EN EL MUNICIPIO DE " + ttCambioNota.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE "
                + ttCambioNota.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase() + ", " + strDia + " DIAS DEL MES DE "
                + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              text: 'Se tiene a la vista el expediente número ' + ttCambioNota.expediente + ' para resolver la sustitución de Notas de Envío de Bosque de Licencia o Concesión Forestal otorgadas a: ' + 
              ttCambioNota.tcSolicitante.personaDesc + ', según Licencia forestal o Plan Operativo Anual número: ' + ttCambioNota.licencia,
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Decreto Número 101-96 del Congreso de la República, Ley Forestal, establece que el Instituto Nacional de Bosques es una entidad estatal, autónoma, descentralizada, con personalidad jurídica, patrimonio propio e independencia administrativa; es el órgano de dirección y autoridad competente del Sector Público Agrícola, en materia Forestal.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Reglamento para el Transporte de Productos Forestales vigente, define en el Artículo 5, que la función de la nota de envío es respaldar el transporte de los productos forestales fuera de la finca, área de manejo, área de aprovechamiento, o lugar de transformación, siendo obligatoria exhibirla ante la autoridad competente que la requiera.',
              style: 'izquierda'
            }, '\n',
            {
              text: "CONSIDERANDO",
              style: 'centrar'
            }, '\n',
            {
              text: 'Que el Artículo 25 de la resolución de Junta Directiva de INAB, JD.04.15.2022 establece que, para el transporte de productos forestales, las Notas de Envío de Bosque emitidas antes de la vigencia del presente Reglamento, tendrán validez hasta el treinta y uno de diciembre del año dos mil veintitrés y las Notas de Envío de Bosque de Licencia o Concesión Forestal no utilizadas   en la fecha establecida y que se encuentren en poder del usuario del INAB, deben ser entregadas en las oficinas institucionales del INAB correspondientes, para sus sustitución sin costo adicional. Esta sustitución debe realizarse del uno al treinta uno de enero del dos mil veinticuatro.',
              style: 'izquierda'
            }, '\n',
            {
              text: "POR TANTO",
              style: 'centrar'
            }, '\n',
            {
              text: "Con base en lo anteriormente considerado y preceptuado en los Artículos: 28, 30, 43, 126, 153, 154 y 155 de la Constitución Política de la República de Guatemala; 3, 5, 6, 48, 49, 50 y 62 del Decreto Número 101-96 del Congreso de la República, Ley Forestal; 1, 2, 3 y 4 del Decreto Número 119-96 del Congreso de la República, Ley de lo Contencioso Administrativo; 1, 2, 3, 4, 5, 6, 15 y 25 del Reglamento para el Transporte de Productos Forestales y su Procedencia Lícita.",
              style: 'izquierda'
            }, '\n',
            {
              text: "RESUELVE",
              style: 'centrar'
            }, '\n',
            {
              type: 'upper-roman',
              ol: [
                {
                  text: 'Sustituir la cantidad de ' + total + ' Nota(s) de Envío de Bosque de Licencia o Concesión Forestal, autorizadas en licencia forestal o plan operativo anual número: '
                  + ttCambioNota.licencia + ' a nombre de ' + ttCambioNota.tcSolicitante.personaDesc + '. La sustitución de las Notas de Envío se efectúa sin costo adicional entregando a: ' 
                  + ttCambioNota.tcSolicitante.personaDesc + ' la cantidad de: ' + total + ' Notas de Envío de Bosque de Licencia o Concesión Forestal según el siguiente cuadro:', 
                  style: 'izquierda'
                }
              ]
            }, '\n', 
            {
              columns: [
                {
                  width: 90,
                  text: ''
                }, tbNotas
              ]
            }, '\n',
            {
              type: 'upper-roman',
              start: 2,
              ol: [
                {
                  text: 'Se anulan la cantidad de: ' + total + ' Notas de Envío de Bosque de Licencia o Concesión Forestal, entregadas por '
                  + ttCambioNota.tcSolicitante.personaDesc + '; las que quedan bajo el resguardo, custodia y responsabilidad de esta Dirección Subregional hasta su posterior traslado a la Dirección Regional correspondiente, comprendidas según el cuadro siguiente:', 
                  style: 'izquierda'
                }
              ]
            }, '\n', {
              columns: [
                {
                  width: 90,
                  text: ''
                }, tbRangos
              ]
            }, '\n',
            {
              type: 'upper-roman',
              start: 3,
              ol: [
                { text: 'La presente Resolución tiene vigencia inmediata.', style: 'izquierda' },
                { text: "Notifíquese a las partes.", style: 'izquierda' }
              ]
            }, '\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + ttCambioNota.tcPersonaResuelve.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttCambioNota.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        enmiendaSubregionalExento: function (ttTareaExento, usuarioDesc, dataEmpresa, sigla) {
          var nombreSalida = 'enmienda.pdf';
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var fechaFinalizacion = new Date(ttTareaExento.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var body = [];
          var i;
          var ttExento = ttTareaExento.ttExento;
          var solicitante = "";
          var correo = "";
          for (i = 0; i < ttExento.propietarios.length; i++) {
            item = ttExento.propietarios[i];
            if (i == 0) {
              solicitante = item.tcPersona.personaDesc;
              correo = item.tcPersona.correo;
            } else {
              solicitante += ", " + item.tcPersona.personaDesc;
              correo += ', ' + item.tcPersona.correo;
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            solicitante = ttExento.ttJuridicaExento.razonSocial;
            if (correo.length > 0) {
              correo += ', ' + ttExento.tcPersonaCrea.correo;
            } else {
              correo = ttExento.tcPersonaCrea.correo;
            }
          }
          for (i = 0; i < ttExento.representantes.length; i++) {
            item = ttExento.representantes[i];
            correo += ', ' + item.tcPersona.correo;
          }
          tmpData = ttTareaExento.observaciones;
        
          for (i = 0; i < tmpData.length; i++) {
            item = [
              { text: '', style: 'izquierda' },
              { text: (i + 1) + ".", style: 'izquierda' },
              { text: tmpData[i].descripcion, style: 'izquierda' }
            ];
            body.push(item);
          }
        
          tbEnmiendaSubregional = {};
          if (body.length > 0) {
            tbEnmiendaSubregional = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [20, 10, 450],
                body: body
              }, layout: 'noBorders'
            };
          }
          var titulo = 'Informe Trimestral ';
          if (ttTareaExento.tcTask.taskId == 24) {
            titulo = 'Carga Inicial';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: '',
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nOFICIO PARA REQUERIMIENTO DE INFORMACIÓN FALTANTE O ENMIENDAS",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Oficio No. " + ttTareaExento.codigo,
              style: 'derecha'
            },
            {
              text: ttExento.tcSubregion.tcMunicipio.municipioDesc + ", " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc + ", "
                + getMonth(mes) + " " + fechaFinalizacion.getDate() + " de " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: solicitante + "\nSolicitante (s) ",
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: "Por este medio estoy dando respuesta a presentación de " + titulo 
              + ", contenida en el expediente administrativo " + ttExento.expediente + " y código de registro " + ttExento.codigo,
              style: 'izquierda'
            }, '\n',
            {
              text: "De acuerdo a los análisis realizados, para continuar con el trámite de su expediente es necesario que presente lo siguiente:",
              style: 'izquierda'
            }, '\n',
            tbEnmiendaSubregional, '\n',
            {
              text: "Si transcurrido un período de seis meses a partir de la presente notificación, no se cumple con la entrega de enmiendas del expediente, el mismo se archivará de conformidad con la Ley de lo Contencioso Administrativo.",
              style: 'izquierda'
            }, '\n',
            {
              text: "Atentamente",
              style: 'izquierda'
            }, '\n\n\n\n',
            {
              text: sigla + ' ' + ttTareaExento.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            },
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            },
            {
              text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fecha.getDay()) + " " + fecha.getDate() + " de " 
              + getMonth(mes) + " de " + fecha.getFullYear(),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = '';//'Codigo: LI-RE-009\nVersión: 2';
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {
              margin: [50, 0, 0, 0],
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 7,
                  alignment: 'left'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 10,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 7,
                bold: true
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        providenciaActualizacionExento: function (tarea, strDia, strAnio, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = tarea.ttExento;
          var ttActualizacionExento = tarea.ttActualizacionExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'providencia.pdf';
          var fechaProvidencia = new Date(tarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaProvidencia.getMonth()) + 1;
          var textoDireccion = "";
          for (var i = 0; i < ttExento.fincas.length; i++) {
            finca = ttExento.fincas[i];
            if (i == 0) {
              textoDireccion = finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + ", municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            } else {
              textoDireccion = textoDireccion + ". Finca " + finca.tcFinca.fincaDesc + " ubicada en " + finca.tcFinca.direccion + " municipio de " + finca.tcFinca.tcMunicipio.municipioDesc + ", departamento de " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
            }
          }
          var textoInicio = "presentó";
          if (ttExento.propietarios.length > 1) {
            textoInicio = "presentaron";
          }
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "";
          var variosPropietarios = false;
          var i = 0;
          for (i = 0; i < conteo; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
            } else {
              variosPropietarios = true;
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
              }
            }
          }
          var paso = null;
          var firmaTecnico = {};
          if (tarea.tcPersonaTraslado != null) {
            paso = { text: "Trasládese el expediente administrativo al Técnico Forestal " + tarea.tcPersonaAsignada.personaDesc + ", solicitándole revisión del documento referido; así mismo indicar el procedimiento a seguir", style: 'izquierda' };
            firmaTecnico = {
              text: 'Fecha:__________ Firma: __________',
              style: 'small', alignment: 'right'
            };
          }
          var aTraves = '';
          var mandatarios = '', representantes = '';
          var variosRepresentantes = false, variosMandatarios = false;          
          conteo = ttExento.representantes.length;
          if (conteo > 0) {
            for (i = 0; i < conteo; i++) {
              if (ttExento.representantes[i].mandatario) {
                if (mandatarios.length > 0) {
                  variosMandatarios = true;
                  mandatarios += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  mandatarios = ttExento.representantes[i].tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + ttExento.representantes[i].tcPersona.personaDesc;
                } else {
                  representantes = ttExento.representantes[i].tcPersona.personaDesc;
                }
              }
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }          
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            variosPropietarios = false;
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
            textoInicio = "presentó";
          }
          if (mandatarios.length > 0) {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ' a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
              }
            } else {
              aTraves = propietarioNombre + ', a través de '
                + (variosMandatarios ? 'sus mandatarios ' : 'su mandatario ') + mandatarios + ', ';
            }
          } else {
            if (representantes.length > 0) {
              if (variosPropietarios) {
                aTraves = propietarioNombre + ', representados legalmente por ' + representantes + ', ';
              } else {
                aTraves = propietarioNombre + ', representado legalmente por ' + representantes + ', ';
              }
            } else {
              aTraves = propietarioNombre;
            }
          }
          aTraves += ' ' + textoInicio + ' ';
          var pase = "Pase a " + tarea.tcPersonaTraslado.personaDesc + ", para que verifique la solicitud presentada y emita dictamen técnico";
          if (ttActualizacionExento.tipoActualizacionId == 2) {
            pase = "Pase a " + tarea.tcPersonaTraslado.personaDesc + ", para que verifique la solicitud presentada, realice inspección de campo y emita dictamen técnico";
          }
          var ol = [
            { text: pase, style: 'izquierda' },
            { text: "Diligenciado vuelva a esta Dirección Subregional.", style: 'izquierda' }
          ];
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nPROVIDENCIA PARA TRASLADO DEL EXPEDIENTE",
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "EXPEDIENTE No.: " + ttExento.expediente,
              style: 'derecha'
            }, '\n',
            {
              text: "INSTITUTO NACIONAL DE BOSQUES, DIRECCIÓN SUBREGIONAL " + ttExento.tcSubregion.subregionDesc + ", CON SEDE EN EL MUNICIPIO DE "
                + ttExento.tcSubregion.tcMunicipio.municipioDesc.toUpperCase() + " DEL DEPARTAMENTO DE " + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc.toUpperCase()
                + ", " + strDia + " DIAS DEL MES DE " + getMonth(mes).toUpperCase() + " DE " + strAnio,
              style: 'izquierda'
            }, '\n',
            {
              columns: [
                {
                  width: 200,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 300,
                  text: [
                    {
                      text: 'ASUNTO: ', fontSize: 12, bold: true
                    },
                    {
                      text: aTraves + 'la solicitud de actualización de volumen del Registro No. ' + ttExento.codigo, style: 'izquierda'
                    }
                  ]
                }
              ]
            }, '\n',
            {
              text: "Providencia No.: " + tarea.codigo,
              style: 'izquierda', bold: true
            }, '\n',
            {
              stack: [
                {
                  type: 'upper-roman',
                  ol: ol
                }
              ]
            },
            '\nAtentamente,',
            '\n\n\n\n',
            {
              text: "f_____________________________________",
              style: 'centrar'
            },
            {
              text: sigla + " " + tarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            },
            {
              text: "Director Subregional " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }, '\n\n',
            {
              text: "cc, Archivo",
              style: 'small', alignment: 'left'
            },
            firmaTecnico,
            {
              text: '\n\nImpreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            },
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          ];
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              izquierdaBold: {
                fontSize: 11,
                alignment: 'justify',
                bold: true
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        dictamenSubregionalActualizacionExento: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          if (sigla == null) {
            sigla = '';
          }
          var ttActualizacionExento = ttTarea.ttActualizacionExento;
          var thActualizacionExento = ttActualizacionExento.thActualizacionExento;
          if (thActualizacionExento == null) {
            thActualizacionExento = {
              ventas: []
            }
          }
          var data = ttTarea.ttExento;
          var nombreSalida = 'actualizacion.pdf';
          var fecha = new Date();
          var fechaFinalizacion = ttTarea.fechaFinalizacion;
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var dia = fechaFinalizacion.getDate();
          var anio = fechaFinalizacion.getFullYear();
          var textoPago = "";
          var cero = {
            margin: [50, 20, 0, 0],
            columns: [
              {
                width: 80,
                text: '',
                style: 'izquierda'
              },
              {
                width: 350,
                text: 'INSTITUTO NACIONAL DE BOSQUES',
                style: 'centrar'
              }
            ]
          };
          var body = [];
          var entidad = {};
          var i = 0, hombres = 0, mujeres = 0;
          var item = {};
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
            body = [
              [
                { text: 'Tipo de entidad:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.tcTipoEntidad.tipoEntidadDesc, style: 'tableSubHeader', alignment: 'left' },
                { text: 'NIT:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nitEntidad, style: 'tableSubHeader', alignment: 'left' }
              ],
              [
                { text: 'Nombre o razón social:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.razonSocial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ],
              [
                { text: 'Nombre comercial:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: data.ttJuridicaExento.nombreComercial, style: 'tableSubHeader', alignment: 'left', colSpan: 3 }, {}, {}
              ]
            ];
            entidad = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [155, 120, 120, 100],
                body: body
              }
            }
          }
          body = [
            [
              { text: 'I. INFORMACIÓN GENERAL', style: 'tableSubHeader', colSpan: 5, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}
            ],
            [
              { text: '\nCódigo del RNF', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center', rowSpan: 2 },
              { text: '\n' + data.codigo, style: 'detalle', alignment: 'left', colSpan: 2, rowSpan: 2 }, {},
              { text: 'Área (ha)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: ttActualizacionExento.area, style: 'detalle', alignment: 'left' }
            ],
            [
              { text: '' },
              { text: '' },
              { text: '' },
              { text: 'Longitud (m)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: ttActualizacionExento.longitud, style: 'detalle', alignment: 'left' }
            ]
          ];
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 1) {
            body.push(
              [
                { text: 'Nombre completo', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Código Único de Identificación (CUI)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Comunidad Lingüística', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Pueblo de Pertenencia', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Estado civil', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            );
          }
          var dataPersona = data.propietarios;
          var propietarioNombre = '';
          var correo = '';
          for (i = 0; i < dataPersona.length; i++) {
            item = [
              { text: dataPersona[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.cui, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcIdioma.idiomaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcCultura.culturaDesc, style: 'detalle', alignment: 'left' },
              { text: dataPersona[i].tcPersona.tcEstadoCivil.estadoCivilDesc, style: 'detalle', alignment: 'left' }
            ];
            body.push(item);
            if (dataPersona[i].tcPersona.tcSexo.sexoId == 1) {
              mujeres++;
            } else {
              hombres++;
            }
            if (propietarioNombre.length > 0) {
              propietarioNombre += ', ' + dataPersona[i].tcPersona.personaDesc;
              correo += ', ' + dataPersona[i].tcPersona.correo;
            } else {
              propietarioNombre = dataPersona[i].tcPersona.personaDesc;
              correo = dataPersona[i].tcPersona.correo;
            }
          }
          if (propietarioNombre.length > 0) {
            if (propietarioNombre.indexOf(',') > 0) {
              propietarioNombre = propietarioNombre.substring(0, propietarioNombre.lastIndexOf(',')) + ' y ' + propietarioNombre.substring(propietarioNombre.lastIndexOf(',') + 1);
            }
          }
          if (data.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 1) {
            item = [
              { text: 'Hombres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: hombres, style: 'tableHeader', alignment: 'left' },
              { text: 'Mujeres: ', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
              { text: mujeres, style: 'tableHeader', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
          } else {
            propietarioNombre = data.ttJuridicaExento.razonSocial;
          }
          if (data.representantes.length > 0) {
            item = [
              { text: 'Representante legal / Mandatario', style: 'tableSubHeader', colSpan: 5, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}, {}, {}
            ];
            body.push(item);
            item = [
              { text: 'Nombre completo', style: 'tableSubHeader', colSpan: 2, fillColor: '#CCCCCC', alignment: 'center' }, {},
              { text: 'Código único de Identificación -CUI-', style: 'tableSubHeader', colSpan: 3, fillColor: '#CCCCCC', alignment: 'center' }, {}, {}
            ];
            body.push(item);
            for (var i = 0; i < data.representantes.length; i++) {
              item = [
                { text: data.representantes[i].tcPersona.personaDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                { text: data.representantes[i].tcPersona.cui, style: 'detalle', alignment: 'left', colSpan: 3 }, {}, {}
              ];
              body.push(item);
              if (correo.length > 0) {
                correo += ', ' + data.representantes[i].tcPersona.correo;
              } else {
                correo = data.representantes[i].tcPersona.correo;
              }
            }
          }
          var solicitante = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [150, 90, 85, 83, 80],
              body: body
            }
          }
          //datos de la finca en la solicitud
          body = [
            [
              { text: 'II. DATOS DE LA FINCA', style: 'tableSubHeader', colSpan: 6, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}, {}, {}, {}, {}
            ]
          ];
          var finca = null;
          var areaFinca = 0;
          var propietario = "";
          for (i = 0; i < data.fincas.length; i++) {
            if (data.fincas.length > 1) {
              body.push(
                [
                  { text: 'Finca No. ' + (i + 1), style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', colSpan: 6 }, {}, {}, {}, {}, {}
                ]
              );
            }
            finca = data.fincas[i];
            for (j = 0; j < finca.propietarios.length; j++) {
              if (j == 0) {
                propietario = finca.propietarios[j].tcPersona.personaDesc;
              } else {
                propietario += ", " + finca.propietarios[j].tcPersona.personaDesc;
              }
            }
            body.push(
              [
                { text: 'Nombre de la finca:', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: finca.tcFinca.fincaDesc, style: 'tableSubHeader', color: '#FFFFFF', alignment: 'left', fillColor: '#3A3C3A', colSpan: 2 }, {},
                { text: 'Propietario(s):', style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left' },
                { text: propietario, style: 'tableSubHeader', color: '#FFFFFF', fillColor: '#3A3C3A', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push([
              { text: 'Municipio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.municipioDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
              { text: 'Departamento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
            ]);
            body.push(
              [
                { text: 'Aldea/caserío/cantón:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.direccion, style: 'tableSubHeader', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
              ]
            );
            body.push(
              [
                { text: 'Coordenadas GTM X:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmX, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {},
                { text: 'Coordenadas GTM Y:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcFinca.gtmY, style: 'tableSubHeader', alignment: 'left', colSpan: 2 }, {}
              ]
            );
            body.push(
              [
                { text: 'Documento:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: finca.tcTipoPropiedad.tipoPropiedadDesc, style: 'tableSubHeader', colSpan: 5, alignment: 'left' }, {}, {}, {}, {}
              ]
            );
            switch (parseInt(finca.tcTipoPropiedad.tipoPropiedadId)) {
              case 1:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Número de escritura pública', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left', colSpan: 5 }, {}, {}, {}, {}
                ];
                break;
              case 2:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Nombre del notario', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.notario, style: 'detalle', alignment: 'left' },
                  { text: 'Lugar de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc + ', ' + finca.tcMunicipioEmite.tcDepartamento.departamentoDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
              case 3:
                item = [
                  { text: 'No. de Finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Folio:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.folio, style: 'detalle', alignment: 'left' },
                  { text: 'No. de Libro:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.libro, style: 'detalle', alignment: 'left' }
                ];
                body.push(item);
                item = [
                  { text: 'De:', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcLibro.libroDesc, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left', colSpan: 2 }, {}
                ];
                break;
              default:
                item = [
                  { text: 'Fecha de emisión', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: $filter('date')(new Date(finca.fechaEmision), 'dd/MM/yyyy'), style: 'detalle', alignment: 'left' },
                  { text: 'Numero de certificación', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.numeroDocumento, style: 'detalle', alignment: 'left' },
                  { text: 'Municipalidad', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: finca.tcMunicipioEmite.municipioDesc, style: 'detalle', alignment: 'left' }
                ];
                break;
            }
            body.push(item);
            item = [
              { text: 'Área total de la finca (Según documento)', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.areaDocumento, style: 'detalle', alignment: 'left', colSpan: 2 }, {},
              { text: 'Área total de la finca', style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
              { text: finca.tcFinca.area, style: 'detalle', alignment: 'left', colSpan: 2 }, {}
            ];
            body.push(item);
            areaFinca += parseFloat(finca.tcFinca.area);
          }
          var finca = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [90, 70, 75, 90, 75, 80],
              body: body
            }
          }
          body = [
            [
              { text: 'III. DATOS DE NOTIFICACIÓN', style: 'tableSubHeader', colSpan: 2, color: '#FFFFFF', fillColor: '#000000', alignment: 'center' }, {}
            ]
          ];
          for (i = 0; i < data.notificaciones.length; i++) {
            row = data.notificaciones[i];
            if (row.tcTipoNotificacion.tipoNotificacionId == 1 || row.tcTipoNotificacion.tipoNotificacionId == 2) {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc + ", municipio de " + row.tcMunicipio.municipioDesc + " departamento de " + row.tcMunicipio.tcDepartamento.departamentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            } else {
              item = [
                { text: row.tcTipoNotificacion.tipoNotificacionDesc, style: 'tableSubHeader', fillColor: '#CCCCCC', alignment: 'left' },
                { text: row.notificacionExentoDesc, style: 'tableSubHeader', alignment: 'left' }
              ];
            }
            if (row.tcTipoNotificacion.tipoNotificacionId == 5) {
              if (correo.length > 0) {
                correo += ', ' + row.notificacionExentoDesc;
              } else {
                correo = row.notificacionExentoDesc;
              }
            }
            body.push(item);
          }
          var tbNotificacion = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [200, 315],
              body: body
            }
          }
          var tbVolumen = {};
          var rodales = data.rodales;
          if (ttActualizacionExento.tipoActualizacionId != 1) {
            rodales = ttActualizacionExento.rodales;
          }
          if (rodales != null) {
            body = [
              [
                { text: 'Rodal / Ĺinea', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Área (ha) / Línea (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
                { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
              ]
            ];
            var filas = [];
            var tablaFila = {};
            for (var i = 0; i < rodales.length; i++) {
              var especies = rodales[i].especies;
              filas = [
                [
                  { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                  { text: 'Vol. / Ha. (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                  { text: rodales.enLinea == 0 ? 'Vol. / Rodal (m³)' : 'Vol. / Línea (m)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
                ]
              ];
              for (var j = 0; j < especies.length; j++) {
                var especie = especies[j];
                item = [
                  { text: especie.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                  { text: especie.volumenHectaria, style: 'izquierda', alignment: 'right' },
                  { text: especie.volumen, style: 'detalle', alignment: 'right' }
                ];
                filas.push(item);
              }
              tablaFila = {
                style: 'estiloTablaEstrecha',
                table: {
                  headerRows: 1,
                  widths: [190, 95, 95],
                  body: filas
                }, layout: 'noBorders'
              }
              item = [
                { text: rodales[i].correlativo, style: 'detalle', alignment: 'center' },
                { text: rodales[i].area, style: 'detalle', alignment: 'center' },
                tablaFila
              ];
              body.push(item);
            }
            tbVolumen = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [50, 50, 400],
                body: body
              }
            }
          }
          var tbEspecie = {};
          var especies = data.especies;
          if (especies != null) {
            body = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Volumen (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            ];
            for (var j = 0; j < especies.length; j++) {
              var especie = especies[j];
              item = [
                { text: especie.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                { text: especie.volumen, style: 'detalle', alignment: 'right' }
              ];
              body.push(item);
            }
            tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [310, 200],
                body: body
              }
            }
          }
          var resultado = '';
          if (ttTarea.aprobado == 1) {
            resultado = ' aprobada, según la actualización del registro con fecha ' 
            + $filter('date')(ttActualizacionExento.fechaActualizacion, 'dd/MM/yyyy') + ' y fecha de vencimiento: '
            + $filter('date')(ttActualizacionExento.fechaVencimiento, 'dd/MM/yyyy') + '. A continuación, le mostramos el resumen de la actualización realizada:';
          } else {
            resultado = ' denegada por las razones siguientes:';
          }
          var observaciones = ttTarea.observaciones;
          var tbObservaciones = {};
          if (observaciones.length > 0) {
            enterObservaciones = '\n';
            body = [];
            if (ttTarea.aprobado == 1) {
              body = [
                [
                  { text: 'Observaciones\n', style: 'detalle', bold: true, colSpan: 2 }, {},
                ]
              ];
            }
            for (let i = 0; i < observaciones.length; i++) {
              var row = observaciones[i];
              body.push(
                [
                  { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                  { text: row.observacion, style: 'detalle', alignment: 'left' }
                ]
              )
            }
            tbObservaciones = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [15, 485],
                body: body
              }, layout: 'noBorders'
            }
          }
          var titulo = 'Notificación No. ' + ttTarea.codigo;
          var tbVenta = {};
          var ventas = thActualizacionExento.ventas;
          if (ventas == null) {
            ventas = [];
          }
          var body = [
            [
              { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'No reportadas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '% reportado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Estado de la resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vencimiento', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            body.push(
              [
                { text: element.resolucion, style: 'detalle', alignment: 'left' },
                { text: element.requisito, style: 'detalle', alignment: 'center' },
                { text: element.cantidadNotas, style: 'detalle', alignment: 'center' },
                { text: element.porcentajeReportado, style: 'detalle', alignment: 'center' },
                { text: element.estadoId == 1 ? 'Vigente' : 'Vencida', style: 'detalle', alignment: 'center' },
                { text: element.fechaFinalizacion, style: 'detalle', alignment: 'center' }
              ]
            );
          }
          if (ventas.length > 0) {
            tbVenta = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [150, 100, 45, 50, 65, 55],
                body: body
              }
            }
          } else {
            tbVenta = {
              text: 'No existen resoluciones de venta/revalidación con notas de envío pendientes por reportar.',
              style: 'izquierda'
            }
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nMódulo Electrónico para la Autorización de Notas de Envío Exentas de Licencia"
                    + "\n" + titulo,
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Fecha: " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " del año " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr(s)./Sra(s).: " + propietarioNombre,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: 'Informamos que la solicitud de actualización de registro del Exento con código inscrita en el Registro Nacional Forestal con el número ' 
              + data.codigo + ', ha sido ' + resultado,
              style: 'izquierda'
            }, '\n'
          ];
          if (ttTarea.aprobado == 1) {
            content.push(solicitante);
            content.push(entidad);
            content.push(finca);
            content.push(tbNotificacion);
            content.push(
              {
                text: 'Resumen de volumetría rodal',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVolumen);
            content.push('\n');
            content.push(
              {
                text: 'Resumen de volumetría por especie',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbEspecie);
            content.push('\n');
            content.push(
              {
                text: 'Información sobre las notas de envío pendientes por reportar:',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVenta);
            content.push('\n');
            content.push(
              {
                text: 'El número de notas de envío y resoluciones autorizadas fueron verificadas en el Sistema de Manejo (Sistema de Información de autorizaciones de Notas de Envío Exentas de Licencia del INAB) y en los expedientes contenidos en los archivos de la subregión.',
                style: 'izquierda'
              }
            );
            content.push('\n');
            content.push(tbObservaciones);
          } else {
            content.push('\n');
            content.push(tbObservaciones);
          }
          content.push('\n');
          content.push('Atentamente,');
          content.push('\n\n\n\n')
          content.push({
            text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
            style: 'centrar'
          });
          content.push({
            text: "Director(a) subregional\nSubregión " + data.tcSubregion.subregionDesc + ', ' + data.tcSubregion.alias,
            style: 'centrar'
          });
          content.push('\n\n');
          content.push({
            text: 'Impreso por: ' + usuarioDesc,
            style: 'small', alignment: 'right'
          });
          content.push({
            text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
            style: 'small', alignment: 'right'
          });
          content.push({
            text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fechaFinalizacion.getDay()) + " " 
            + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " de " + fechaFinalizacion.getFullYear(),
            style: 'small', alignment: 'right'
          });
          var textoFooter = ""; //dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + '\nSitio web: ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [40, 60, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            }, 
            watermark: watermark,
            content: content,            
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrar: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
              },
              centrarBig: {
                fontSize: 14,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 10,
                alignment: 'left'
              },
              foot: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                margin: [50, 0, 0, 0]
              },
              derecha: {
                fontSize: 10,
                alignment: 'right'
              },
              datos: {
                fontSize: 10,
                bold: true,
                margin: [70, 5, 0, 0]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              estiloTabla: {
                margin: [0, 5, 0, 15]
              },
              estiloTablaEstrecha: {
                margin: [0, 1, 0, 1]
              },
              tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black'
              },
              tableSubHeader: {
                bold: true,
                fontSize: 9,
                color: 'black'
              },
              tableHeaderSmall: {
                bold: true,
                fontSize: 6,
                color: 'black',
                margin: [0, 0, 0, 0]
              },
              detalle: {
                fontSize: 8
              },
              small: {
                fontSize: 6
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        dictamenTecnicoActualizacionExento: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          var ttActualizacionExento = ttTarea.ttActualizacionExento;
          var thActualizacionExento = ttActualizacionExento.thActualizacionExento;
          var ttExento = ttTarea.ttExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var rodales = thActualizacionExento.rodales;
          var i;
          var nombreSalida = 'dictamen.pdf';
          var fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var conteo = ttExento.propietarios.length;
          var propietarioNombre = "", sinDpi = "";
          var i = 0;
          var dataPersona = ttExento.propietarios;
          for (i = 0; i < dataPersona.length; i++) {
            persona = ttExento.propietarios[i];
            if (i == 0) {
              propietarioNombre = persona.tcPersona.personaDesc;
              sinDpi = persona.tcPersona.personaDesc;
            } else {
              if (conteo == (i - 1)) {
                propietarioNombre = propietarioNombre + " y " + persona.tcPersona.personaDesc;
                sinDpi = sinDpi + " y " + persona.tcPersona.personaDesc;
              } else {
                propietarioNombre = propietarioNombre + ", " + persona.tcPersona.personaDesc;
                sinDpi = sinDpi + ", " + persona.tcPersona.personaDesc;
              }
            }
            propietarioNombre += ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación –CUI– '
              + persona.tcPersona.cui;
          }
          var mandatarios = '', representantes = '', mandatariosNombre = '', representantesNombre = '';
          var variosRepresentantes = false, variosMandatarios = false;
          if (ttExento.representantes.length > 0) {
            for (var i = 0; i < ttExento.representantes.length; i++) {
              persona = ttExento.representantes[i];
              if (persona.mandatario) {
                if (mandatarios.length > 0) {
                  mandatarios += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = mandatariosNombre + ", " + persona.tcPersona.personaDesc;
                  variosMandatarios = true;
                } else {
                  mandatarios = persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  mandatariosNombre = persona.tcPersona.personaDesc;
                }
              } else {
                if (representantes.length > 0) {
                  variosRepresentantes = true;
                  representantes += ', ' + persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = representantesNombre + ", " + persona.tcPersona.personaDesc;
                } else {
                  representantes = persona.tcPersona.personaDesc + ' quien se identifica con Documento Personal de Identificación –DPI– y Código Único de Identificación -CUI- ' + persona.tcPersona.cui;
                  representantesNombre = persona.tcPersona.personaDesc;
                }
              }
            }
          }
          if (representantes.length > 0) {
            if (variosRepresentantes) {
              representantes += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
            } else {
              representantes += '; extendido por el Registro Nacional de las Personas -RENAP-.';
            }
          }
          if (mandatarios.length > 0) {
            if (variosMandatarios) {
              mandatarios += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
            } else {
              mandatarios += '; extendido por el Registro Nacional de las Personas -RENAP-.';
            }
          }
          if (variosRepresentantes) {
            representantes = representantes.substring(0, representantes.lastIndexOf(',')) + ' y' + representantes.substring(representantes.lastIndexOf(',') + 1);
          }
          if (variosMandatarios) {
            mandatarios = mandatarios.substring(0, mandatarios.lastIndexOf(',')) + ' y' + mandatarios.substring(mandatarios.lastIndexOf(',') + 1);
          }
          if (dataPersona.length > 1) {
            propietarioNombre += '; extendidos por el Registro Nacional de las Personas -RENAP-.';
          } else {
            propietarioNombre += '; extendido por el Registro Nacional de las Personas -RENAP-.';
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            sinDpi = ttExento.ttJuridicaExento.razonSocial;
          }
          var paso = null;
          var aTraves = 'Por este medio informo sobre el volumen existente en la ' + ttExento.tcCategoriaExento.categoriaExentoDesc 
          + ' inscrita en el Registro Nacional Forestal con el número ' + ttExento.codigo + ', con registro a nombre de ' + sinDpi 
          + '; el volumen actualizado es el siguiente:';
          var textoInicial = '';
          if (mandatarios.length <= 0) {
            if (representantes.length > 0) {
              textoInicial = sinDpi + ' representado legalmente por ' + representantes;
            } else {
              textoInicial = propietarioNombre;
            }
          } else {
            if (representantes.length > 0) {
              if (variosMandatarios) {
                textoInicial = sinDpi + ' representado legalmente por ' + representantesNombre + ' A través de los mandatarios ' + mandatarios;
              } else {
                textoInicial = sinDpi + ' representado legalmente por ' + representantesNombre + ' A través del mandatario ' + mandatarios;
              }
            } else {
              if (variosMandatarios) {
                textoInicial = sinDpi + ' a través de los mandatarios ' + mandatarios;
              } else {
                textoInicial = sinDpi + ' a través del mandatario ' + mandatarios;
              }
            }
          }
          if (ttTarea.aprobado == 0) {
            aTraves = 'Por este medio le informo sobre la verificación del expediente e inspección de campo en solicitud de actualización de volumen para el registro número '
            + ttExento.codigo + ', realizada por ' + textoInicial;
          }
          var tbVenta = {};
          var ventas = thActualizacionExento.ventas;
          if (ventas == null) {
            ventas = [];
          }
          var body = [
            [
              { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'No reportadas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '% reportado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Estado de la resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vencimiento', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            body.push(
              [
                { text: element.resolucion, style: 'detalle', alignment: 'left' },
                { text: element.requisito, style: 'detalle', alignment: 'left' },
                { text: element.cantidadNotas, style: 'detalle', alignment: 'left' },
                { text: element.porcentajeReportado, style: 'detalle', alignment: 'left' },
                { text: element.estadoId == 1 ? 'Vigente' : 'Vencida', style: 'detalle', alignment: 'left' },
                { text: element.fechaFinalizacion, style: 'detalle', alignment: 'left' }
              ]
            );
          }
          if (ventas.length > 0) {
            tbVenta = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [150, 100, 45, 50, 45, 55],
                body: body
              }
            }
          } else {
            tbVenta = {
              text: 'No existen resoluciones de venta/revalidación con notas de envío pendientes por reportar.',
              style: 'izquierda'
            }
          }
          var rodales = thActualizacionExento.rodales;
          if (rodales == null) {
            rodales = [];
          }
          body = [
            [
              { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha.)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var filas = [];
          var tablaFila = {};
          for (var i = 0; i < rodales.length; i++) {
            var especies = rodales[i].especies;
            filas = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Vol. / Ha. (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                { text: 'Vol. / Rodal (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            ];
            for (var j = 0; j < especies.length; j++) {
              var especie = especies[j];
              item = [
                { text: especie.tcEspecie.nombreCientifico, style: 'izquierda', alignment: 'left' },
                { text: especie.volumenHectaria, style: 'izquierda', alignment: 'right' },
                { text: especie.volumen, style: 'izquierda', alignment: 'right' }
              ];
              filas.push(item);
            }
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [165, 95, 95],
                body: filas
              }, layout: 'noBorders'
            }
            item = [
              { text: rodales[i].correlativo, style: 'izquierda', alignment: 'center' },
              { text: rodales[i].area, style: 'izquierda', alignment: 'center' },
              tablaFila
            ];
            body.push(item);
          }
          tbVolumen = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [50, 50, 375],
              body: body
            }
          }

          var tbEspecie = {};
          var especies = thActualizacionExento.saldoDespues;
          if (especies != null) {
            body = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Volumen (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            ];
            for (var j = 0; j < especies.length; j++) {
              var especie = especies[j];
              item = [
                { text: especie.tcEspecie.nombreCientifico, style: 'izquierda', alignment: 'left' },
                { text: especie.volumen, style: 'izquierda', alignment: 'right' }
              ];
              body.push(item);
            }
            tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [280, 200],
                body: body
              }
            }
          }

          var content = [
            {
              width: 420,
              text: "\n\nDICTAMEN TÉCNICO",
              style: 'centrar'
            }, '\n',
            {
              text: "Dictamen técnico número: " + ttTarea.codigo,
              style: 'derecha'
            },
            {
              text: "Fecha: " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " del año " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: ttTarea.tcPersonaTraslado.personaDesc + "\nDirector Subregional "
                + ttExento.tcSubregion.subregionDesc + "\nINAB, " + ttExento.tcSubregion.tcMunicipio.municipioDesc + ", "
                + ttExento.tcSubregion.tcMunicipio.tcDepartamento.departamentoDesc,
              style: 'izquierda', bold: true
            }, '\n'
          ];
          content.push(
            {
              text: aTraves,
              style: 'izquierda'
            }
          );
          if (ttTarea.aprobado == 1) {
            content.push('\n');
            content.push(
              {
                text: 'Resumen de volumetría por rodal',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVolumen);
            content.push('\n');
            content.push(
              {
                text: 'Resumen de volumetría por especie',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbEspecie);
            content.push('\n');
            content.push(
              {
                text: 'Información sobre las notas de envío pendientes por reportar:',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVenta);
            content.push('\n');
            content.push(
              {
                text: 'El número de notas de envío y resoluciones autorizadas fueron verificadas en el Sistema de Manejo (Sistema de Información de autorizaciones de Notas de Envío Exentas de Licencia del INAB) y en los expedientes contenidos en los archivos de la subregión.',
                style: 'izquierda'
              }
            );
            content.push('\n');
          } else {
            content.push('\n');
            content.push(
              {
                text: 'Con base a la revisión y análisis del expediente en estudio y a la comprobación de campo realizada por el suscrito, se recomienda DENEGAR la solicitud de actualización de volumen presentada, por las razones siguientes:',
                style: 'izquierda'
              }
            );
            content.push('\n');
            var dataTmp = ttTarea.observaciones;
            var lista = [];
            for (i = 0; i < dataTmp.length; i++) {
              lista.push({ text: dataTmp[i].descripcion, style: 'izquierda' });
            }
            content.push(
              {
                ol: lista
              }
            );
          }
          content.push('\n');
          content.push(
            {
              text: 'Atentamente',
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              text: "f_____________________________________",
              style: 'centrar'
            }
          );
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Técnico forestal, subregión " + ttExento.tcSubregion.subregionDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        notificacionActualizacionExento: function (ttTarea, usuarioDesc, dataEmpresa, sigla) {
          var ttExento = ttTarea.ttExento;
          var ttActualizacionExento = ttTarea.ttActualizacionExento;
          var thActualizacionExento = ttActualizacionExento.thActualizacionExento;
          if (sigla == undefined || sigla == null) {
            sigla = "";
          }
          var i;
          var nombreSalida = 'notificacion.pdf';
          var fechaFinalizacion = new Date(ttTarea.fechaFinalizacion);
          var fecha = new Date();
          var mes = parseInt(fechaFinalizacion.getMonth()) + 1;
          var propietarioNombre = "";
          var i = 0;
          var correo = '';
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 1) {
            var propietarios = ttExento.propietarios;
            for (i = 0; i < propietarios.length; i++) {
              persona = propietarios[i];
              if (i == 0) {
                propietarioNombre = persona.tcPersona.personaDesc;
                correo = persona.tcPersona.correo;
              } else {
                propietarioNombre += ', ' + persona.tcPersona.personaDesc;
                correo = correo + ", " + persona.tcPersona.correo;
              }
            }
          }
          representantes = ttExento.representantes;          
          for (i = 0; i < representantes.length; i++) {
            persona = representantes[i];
            if (correo.length > 0) {
              correo = correo + ", " + persona.tcPersona.correo;
            } else {
              correo = persona.tcPersona.correo;
            }
          }
          notificaciones = ttExento.notificaciones;
          if (notificaciones == null) {
            notificaciones = [];
          }
          for (i = 0; i < notificaciones.length; i++) {
            notificacion = ttExento.notificaciones[i];
            if (notificacion.tcTipoNotificacion.tipoNotificacionId == 5) {
              if (correo.length > 0) {
                correo = correo + ", " + notificacion.notificacionExentoDesc;
              } else {
                correo = notificacion.notificacionExentoDesc;
              }
            }
          }
          var paso = null;
          var aTraves = 'Informamos que la solicitud de actualización de volumen del Exento con código inscrita en el Registro Nacional Forestal con el número '
            + ttExento.codigo + ' (' + ttExento.tipoRegistroDesc + '), ha sido ';
          if (ttTarea.aprobado == 1) {
            aTraves += 'aprobada. A continuación, le mostramos el resumen de la actualización realizada:';
          } else {
            aTraves += 'denegada por las razones siguientes:';
          }
          if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
            propietarioNombre = ttExento.ttJuridicaExento.razonSocial;
          }
          var tbVenta = {};
          var ventas = thActualizacionExento.ventas;
          if (ventas == null) {
            ventas = [];
          }
          var body = [
            [
              { text: 'Número de resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'No reportadas', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Cantidad', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: '% reportado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Estado de la resolución', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Vencimiento', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            body.push(
              [
                { text: element.resolucion, style: 'detalle', alignment: 'left' },
                { text: element.requisito, style: 'detalle', alignment: 'left' },
                { text: element.cantidadNotas, style: 'detalle', alignment: 'left' },
                { text: element.porcentajeReportado, style: 'detalle', alignment: 'left' },
                { text: element.estadoId == 1 ? 'Vigente' : 'Vencida', style: 'detalle', alignment: 'left' },
                { text: element.fechaFinalizacion, style: 'detalle', alignment: 'left' }
              ]
            );
          }
          if (ventas.length > 0) {
            tbVenta = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [150, 100, 45, 50, 45, 55],
                body: body
              }
            }
          } else {
            tbVenta = {
              text: 'No existen resoluciones de venta/revalidación con notas de envío pendientes por reportar.',
              style: 'izquierda'
            }
          }
          var rodales = thActualizacionExento.rodales;
          if (rodales == null) {
            rodales = [];
          }
          body = [
            [
              { text: 'Rodal', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Área (ha.)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' },
              { text: 'Volumen y especie actualizado', style: 'detalle', fillColor: '#CCCCCC', alignment: 'center' }
            ]
          ];
          var filas = [];
          var tablaFila = {};
          for (var i = 0; i < rodales.length; i++) {
            var especies = rodales[i].especies;
            filas = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Vol. / Ha. (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' },
                { text: 'Vol. / Rodal (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            ];
            for (var j = 0; j < especies.length; j++) {
              var especie = especies[j];
              item = [
                { text: especie.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                { text: especie.volumenHectaria, style: 'detalle', alignment: 'right' },
                { text: especie.volumen, style: 'detalle', alignment: 'right' }
              ];
              filas.push(item);
            }
            tablaFila = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [165, 95, 95],
                body: filas
              }, layout: 'noBorders'
            }
            item = [
              { text: rodales[i].correlativo, style: 'detalle', alignment: 'center' },
              { text: rodales[i].area, style: 'detalle', alignment: 'center' },
              tablaFila
            ];
            body.push(item);
          }
          tbVolumen = {
            style: 'estiloTablaEstrecha',
            table: {
              headerRows: 1,
              widths: [50, 50, 375],
              body: body
            }
          }
          var tbEspecie = {};
          var especies = thActualizacionExento.saldoDespues;
          if (especies != null) {
            body = [
              [
                { text: 'Especie', style: 'detalle', fillColor: '#CCCCCC', alignment: 'left' },
                { text: 'Volumen (m³)', style: 'detalle', fillColor: '#CCCCCC', alignment: 'right' }
              ]
            ];
            for (var j = 0; j < especies.length; j++) {
              var especie = especies[j];
              item = [
                { text: especie.tcEspecie.nombreCientifico, style: 'detalle', alignment: 'left' },
                { text: especie.volumen, style: 'detalle', alignment: 'right' }
              ];
              body.push(item);
            }
            tbEspecie = {
              style: 'estiloTablaEstrecha',
              table: {
                headerRows: 1,
                widths: [280, 200],
                body: body
              }
            }
          }
          var tbObservaciones = {};
          var observaciones = [];
          var tbRazones = {};
          var razones = [];
          if (ttTarea.observaciones == null) {
            ttTarea.observaciones = {
              observaciones: [],
              razones: []
            };
          }
          var observaciones = ttTarea.observaciones.observaciones;
          var enterObservaciones = {};
          if (observaciones.length > 0) {
            enterObservaciones = '\n';
            body = [
              [
                { text: 'Observaciones\n', style: 'detalle', bold: true, colSpan: 2 }, {},
              ]
            ];
            for (let i = 0; i < observaciones.length; i++) {
              var row = observaciones[i];
              body.push(
                [
                  { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                  { text: row.descripcion, style: 'detalle', alignment: 'left' }
                ]
              )
            }
            tbObservaciones = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [15, 485],
                body: body
              }, layout: 'noBorders'
            }
          }
          var razones = ttTarea.observaciones.razones;
          if (razones.length > 0) {
            body = [];
            for (let i = 0; i < razones.length; i++) {
              var row = razones[i];
              body.push(
                [
                  { text: (i + 1) + '.', style: 'detalle', alignment: 'left' },
                  { text: row.descripcion, style: 'detalle', alignment: 'left' }
                ]
              )
            }
            tbRazones = {
              style: 'estiloTabla',
              table: {
                headerRows: 1,
                widths: [15, 485],
                body: body
              }, layout: 'noBorders'
            }
          }
          if (ttTarea.codigo == null) {
            ttTarea.codigo = 'XX-XXXX';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  text: "",
                  style: 'izquierda'
                },
                {
                  width: 420,
                  text: "\n\nMódulo Electrónico para la Autorización de Notas de Envío Exentas de Licencia"
                    + "\nNotificación No. " + ttTarea.codigo,
                  style: 'centrar'
                }
              ]
            }, '\n',
            {
              text: "Fecha: " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " del año " + fechaFinalizacion.getFullYear(),
              style: 'derecha'
            }, '\n\n',
            {
              text: "Sr(s)./Sra(s).: " + propietarioNombre,
              style: 'izquierda', bold: true
            }, '\n',
            {
              text: aTraves,
              style: 'izquierda'
            }, '\n'
          ];
          if (ttTarea.aprobado == 1) {
            content.push(
              {
                text: 'Resumen de volumetría por rodal',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVolumen);
            content.push('\n');
            content.push(
              {
                text: 'Resumen de volumetría por especie',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbEspecie);
            content.push('\n');
            content.push(
              {
                text: 'Información sobre las notas de envío pendientes por reportar:',
                style: 'izquierda',
                bold: true
              }
            );
            content.push('\n');
            content.push(tbVenta);
            content.push('\n');
            content.push(
              {
                text: 'El número de notas de envío y resoluciones autorizadas fueron verificadas en el Sistema de Manejo (Sistema de Información de autorizaciones de Notas de Envío Exentas de Licencia del INAB) y en los expedientes contenidos en los archivos de la subregión.',
                style: 'izquierda'
              }
            );
          } else {
            content.push(tbRazones);
          }
          content.push(enterObservaciones);
          content.push(tbObservaciones);
          content.push('\n');
          content.push('Atentamente,');
          content.push('\n\n\n\n');
          content.push(
            {
              text: sigla + " " + ttTarea.tcPersonaAsignada.personaDesc,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: "Director(a) subregional\nSubregión " + ttExento.tcSubregion.subregionDesc + ', ' + ttExento.tcSubregion.alias,
              style: 'centrar'
            }
          );
          content.push(
            {
              text: 'Impreso por: ' + usuarioDesc,
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Fecha y hora: ' + $filter('date')(fecha, 'dd/MM/yyyy HH:mm'),
              style: 'small', alignment: 'right'
            }
          );
          content.push(
            {
              text: 'Notificado al (los) correo (s) electrónico (s): ' + correo + " el día " + getNameDayOfWeek(fechaFinalizacion.getDay()) + " " + fechaFinalizacion.getDate() + " de " + getMonth(fechaFinalizacion.getMonth() + 1) + " de " + fechaFinalizacion.getFullYear(),
              style: 'small', alignment: 'right'
            }
          );
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.3, bold: true, italics: false
            };
            gblEsPrevia = false;
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 110, 50, 50],
            footer: {},
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                bold: true,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              detalle: {
                fontSize: 9,
                bold: true
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        },
        notaEnvioExento: function (ttNotaInformeVenta, dataEmpresa) {
          var nombreSalida = 'nota.pdf';
          var ttInformeVenta = ttNotaInformeVenta.ttInformeVenta;
          var ttVentaExento = ttInformeVenta.ttVentaExento;
          var ttExento = ttVentaExento.ttExento;
          var fincas = ttExento.fincas;
          var fincaDesc = '', personaDesc = '';
          var tcFinca = fincas[0].tcFinca;
          for (let i = 0; i < fincas.length; i++) {
            const finca = fincas[i];
            if (i == 0) {
              fincaDesc = finca.tcFinca.fincaDesc;
            } else {
              fincaDesc += ', ' + finca.tcFinca.fincaDesc;
            }
          }
          if (ttExento.tcTipoPropietario.tipoPropiedadId == 2) {
            personaDesc = ttExento.ttJuridicaExento.razonSocial;
          } else {
            var propietarios = ttExento.propietarios;
            for (let i = 0; i < propietarios.length; i++) {
              const element = propietarios[i];
              if (i == 0) {
                personaDesc = element.tcPersona.personaDesc;
              } else {
                personaDesc += ', ' + element.tcPersona.personaDesc;
              }
            }
          }
          var body = [
            [
              { text: 'No.', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Tipo de producto', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Especie', style: 'centrarTabla', fillColor: '#CCCCCC' },
              { text: 'Volumen en metros cúbicos (m³)', style: 'centrarTabla', fillColor: '#CCCCCC' }
            ]
          ];
          var especies = ttNotaInformeVenta.especies;
          var total = 0;
          var hayFactor = false;
          var valorFactor = 0;
          if (especies.length > 0) {
            for (var i = 0; i < especies.length; i++) {
              var especie = especies[i];
              especie.volumenReportar = parseFloat(especie.volumenReportar).toFixed(4);
              especie.volumen = parseFloat(especie.volumen).toFixed(4);
              var volumen = especie.volumenReportar;
              if (especie.tcProductoTransporte.productoTransporteId == 2 || especie.tcProductoTransporte.productoTransporteId == 4) {
                volumen = especie.volumen;
              }
              var item = [
                { text: i + 1, style: 'centrarTabla' },
                { text: especie.tcProductoTransporte.productoTransporteDesc, style: 'detalleIzquierda' },
                { text: especie.tcEspecie.nombreCientifico, style: 'detalleIzquierda' },
                { text: volumen, style: 'detalleDerecha' }
              ];
              body.push(item);
              if (especie.tcProductoTransporte.productoTransporteId == 2 || especie.tcProductoTransporte.productoTransporteId == 4) {
                valorFactor = parseFloat(especie.volumenReportar) - parseFloat(especie.volumen);
                valorFactor = parseFloat(valorFactor).toFixed(4);
                item = [
                  { text: (especie.tcProductoTransporte.productoTransporteId == 2 ? ' Residuos o leña (Producto de la transformación de la madera aserrada)' 
                  : 'Pérdida (producto de la transformación del carbón)'), style: 'detalleIzquierda', italics: true, colSpan: 3 }, {}, {},
                  { text: valorFactor, style: 'detalleDerecha' }
                ];
                body.push(item);
                hayFactor = true;
              }
              total += parseFloat(especie.volumenReportar);
            }
            total = parseFloat(total).toFixed(4);
            var item = [
              { text: 'TOTAL', style: 'centrarTabla', fillColor: '#CCCCCC', colSpan: 3 }, {}, {},
              { text: total, style: 'detalleDerecha' }
            ];
            body.push(item);
          } else {
            for (var i = 0; i < 10; i++) {
              var item = [
                { text: i + 1, style: 'centrarTabla' },
                { text: '', style: 'detalleIzquierda' },
                { text: '', style: 'detalleIzquierda' },
                { text: '', style: 'detalleDerecha' }
              ];
              body.push(item);
            }
            var item = [
              { text: 'TOTAL', style: 'centrarTabla', fillColor: '#CCCCCC', colSpan: 3 }, {}, {},
              { text: '', style: 'detalleDerecha' }
            ];
            body.push(item);
          }
          tbVenta = {
            style: 'estiloTabla',
            table: {
              headerRows: 1,
              widths: [25, 150, 150, 135],
              body: body
            }
          }
          var textoFactor = {}, kEnter = {};
          if (hayFactor) {
            textoFactor = {
              text: [
                {
                  text: '* La sumatoria de volumen incluye el factor de transformación de carbón y/o madera aserrada',
                  style: 'izquierda',
                  italics: true
                }
              ]
            };
            kEnter = '\n';
          }
          var content = [
            {
              columns: [
                {
                  width: 80,
                  image: 'data:image/png;base64,' + dataEmpresa.logo,
                  style: 'izquierda'
                },
                {
                  width: 350,
                  text: [
                    {
                      text: "\nINSTITUTO NACIONAL DE BOSQUES", italics: true
                    },
                    {
                      text: '\nNOTA DE ENVÍO ELECTRÓNICO DE BOSQUE', bold: true
                    },
                    {
                      text: '\nEXENTO DE LICENCIA FORESTAL'
                    }
                  ],
                  style: 'centrar'
                },
                {
                  width: 80,
                  style: 'izquierda',
                  text: '',
                  fontSize: 8,
                  alignment: 'center'
                }
              ]
            }, '\n',
            {
              text: "No. Correlativo: " + ttNotaInformeVenta.numero,
              style: 'derecha',
              bold: true
            },
            {
              text: "No. Resolución: " + ttVentaExento.resolucion,
              style: 'derecha',
              bold: true
            }, '\n',
            {
              text: [
                {
                  text: 'Nombre del propietario: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: personaDesc,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              text: [
                {
                  text: 'Número de inscripción en el Registro Nacional Forestal: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttExento.codigo,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              text: [
                {
                  text: 'Nombre de la finca: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: fincaDesc,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              text: [
                {
                  text: 'Dirección (aldea, caserío, etc.): ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: tcFinca.direccion,
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            },
            {
              columns: [
                {
                  width: 250,
                  text: [
                    {
                      text: 'Municipio: ',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: tcFinca.tcMunicipio.municipioDesc,
                      style: 'izquierda'
                    }
                  ]
                },
                {
                  width: 175,
                  text: [
                    {
                      text: 'Departamento: ',
                      style: 'izquierda',
                      bold: true
                    },
                    {
                      text: tcFinca.tcMunicipio.tcDepartamento.departamentoDesc,
                      style: 'izquierda'
                    }
                  ]
                },
                {
                  width: '*',
                  text: '',
                  style: 'izquierda'
                }
              ]
            }, '\n',
            tbVenta,
            {
              text: [
                {
                  text: 'Volumen total a transportar (en letras): ',
                  style: 'izquierda'
                },
                {
                  text: ttNotaInformeVenta.textoNumero,
                  style: 'izquierda',
                  bold: true
                }
              ],
              style: 'izquierda'
            }, textoFactor, '\n',
          ];
          content.push(
            {
              text: [
                {
                  text: 'No. de placa del vehículo: ',
                  bold: true
                },
                {
                  text: ttNotaInformeVenta.placa
                }
              ],
              style: 'izquierda'
            }
          );
          let destino = '';
          if (ttNotaInformeVenta.tcMunicipio != null) {
            destino = ttNotaInformeVenta.destino + ', ' + ttNotaInformeVenta.tcMunicipio.municipioDesc + ', '
            + ttNotaInformeVenta.tcMunicipio.tcDepartamento.departamentoDesc;
          }
          content.push(
            {
              text: [
                {
                  text: 'Lugar de destino: ',
                  bold: true
                },
                {
                  text: destino
                }
              ],
              style: 'izquierda'
            }
          );
          content.push(
            {
              text: [
                {
                  text: 'Fecha de salida: ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttNotaInformeVenta.fechaNota == null ? '' : $filter('date')(new Date(ttNotaInformeVenta.fechaNota), 'dd/MM/yyyy'),
                  style: 'izquierda'
                },
                {
                  text: ' hora ',
                  style: 'izquierda',
                  bold: true
                },
                {
                  text: ttNotaInformeVenta.fechaNota == null ? '' : $filter('date')(new Date(ttNotaInformeVenta.fechaNota), 'HH:mm'),
                  style: 'izquierda'
                }
              ],
              style: 'izquierda'
            }
          );

          content.push(
            {
              text: [
                {
                  text: 'Fecha de recepción: ___/___/_______ hora ____:____',
                  bold: true
                }
              ],
              style: 'izquierda'
            }
          );
          content.push('\n\n\n\n');
          content.push(
            {
              columns: [
                {
                  width: 240,
                  text: [
                    {
                      text: '________________________________________________\nFirma del titular del aprovechamiento forestal o representante legal',
                      style: 'centrar',
                      bold: true
                    }
                  ]
                }, {
                  width: 20,
                  text: [
                    {
                      text: '',
                      style: 'izquierda',
                      bold: true
                    }
                  ]
                },
                {
                  width: 240,
                  text: [
                    {
                      text: '______________________________________________\nFirma y sello del destinatario',
                      style: 'centrar',
                      bold: true
                    }
                  ]
                },
                {
                  width: '*',
                  text: '',
                  style: 'izquierda'
                }
              ]
            }
          );
          content.push('\n');
          content.push(
            {
              qr: appSettings.urlBase + 'validaNotaExento/' + cryptText(appSettings.seed, ttNotaInformeVenta.notaInformeVentaId),
              fit: '100',
              style: 'small',
              alignment: 'center'
            }
          );
          var textoFooter = "";//dataEmpresa.empresaDesc + '\n' + dataEmpresa.direccion + ' - Teléfono (s): ' + dataEmpresa.telefono + '\n' + 'Email: ' + dataEmpresa.email + ' - ' + dataEmpresa.direccionWeb;
          var watermark = {};
          if (gblEsPrevia) {
            watermark = {
              text: 'Copia no válida', color: 'blue', opacity: 0.2, bold: true, italics: false
            };
            gblEsPrevia = false;
          } else {
            if (ttNotaInformeVenta.verificado && especies.length <= 0) {
              watermark = {
                text: 'Preimpresa', color: 'red', opacity: 0.2, bold: true, italics: false
              };
            }
          }
          var docDefinition = {
            pageSize: 'letter',
            pageOrientation: 'portrait',
            pageMargins: [60, 40, 50, 50],
            footer: {
              columns: [
                {
                  text: textoFooter,
                  bold: true,
                  fontSize: 11,
                  alignment: 'center'
                }
              ]
            },
            watermark: watermark,
            content: content,
            styles: {
              centrar: {
                fontSize: 11,
                alignment: 'center'
              },
              centrarTabla: {
                fontSize: 9,
                alignment: 'center',
                bold: true
              },
              izquierda: {
                fontSize: 11,
                alignment: 'justify'
              },
              derecha: {
                fontSize: 11,
                alignment: 'right'
              },
              detalleIzquierda: {
                fontSize: 8.5,
                alignment: 'justify'
              },
              detalleDerecha: {
                fontSize: 8.5,
                alignment: 'right'
              },
              small: {
                fontSize: 8,
                bold: true
              },
              estiloTabla: {
                margin: [0, 5, 0, 15],
                fontSize: 9
              }
            }
          };
          pdfMake.createPdf(docDefinition).download(nombreSalida);
        }
      };
    }]);
