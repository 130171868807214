angular.module('app.enmienda', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.enmienda', {
          abstract: true,
          url: 'enmienda',
          template: '<div ui-view></div>',
          resolve: {
          },
          controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
            function (  $scope, toastr, $timeout, gestionService, utilsService) {
              $scope.module = 'Enmienda';

            }]
        })
        .state('index.enmienda.list', {
          url: '/lista',
          templateUrl: 'app/enmienda/enmienda.list.tpl.html',
          resolve: {
            dataEnmienda: ['gestionService', 'authService', '$filter',
              function ( gestionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                return gestionService.getListaEnmienda(loginData.subregionId, 1);
              }]
          },
          controller: ['$scope', '$state', 'dataEnmienda', 'uiGridConstants', '$filter', 'gestionService', 'toastr', 'ngDialog', 'tareaService', 'utilsService',
            function (  $scope,   $state,   dataEnmienda, uiGridConstants, $filter, gestionService, toastr, ngDialog, tareaService, utilsService) {

              $scope.mostrarFiltro = false;

              var now = new Date();
              var begin = $scope.addDays(now, -90);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : 3
              }

              $scope.mostrarEnmienda = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.etiquetaEstado = function (estadoId) {
                if (estadoId == 2) {
                  return 'Recibido';
                }
                return 'Pendiente';
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' },
                { field:'ttGestion.expediente', name: 'expediente', displayName: 'Expediente' },
                { field:'ttGestion.tcPersonaCrea.personaDesc', name: 'elaborador', displayName: 'Propietario' },
                { field:'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha notificación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'ttGestion.area', name: 'area', displayName: 'Área', width: '7%' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                },
                { field:'fechaEnmienda', name: 'fechaEnmienda', displayName: 'Fecha aceptación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.verDocumentoEnmienda(row.entity)" title="Ver enmienda"> <i class="fa fa-ambulance"></i> </button> '
                    + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarEnmienda(row.entity.estadoId)" ng-click="grid.appScope.recibirEnmiendas(row.entity)" title="Recibir enmiendas"> <i class="fa fa-list-ol"></i> </button> '
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataEnmienda.data;

              $scope.recibirEnmiendas = function (item) {
                if (item.estadoId == 1) {
                  tareaService.get( item.tareaId ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var data = res.data[0];
                      data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                      $scope.row = data;
                      var dataEnmienda = data.ttSeguimientoTarea.enmienda;
                      var dataRequisito = [];
                      if (item.soloSubregional == 1) {
                        for (var i = 0; i < dataEnmienda.length; i++) {
                          if (dataEnmienda[i].enmienda == null) {
                            var row = {
                              descripcion: dataEnmienda[i].descripcion,
                              marcado: 0
                            };
                            dataRequisito.push(row);
                          } else {
                            for (var j = 0; j < dataEnmienda[i].enmienda.length; j++) {
                              var rowEnmienda = dataEnmienda[i].enmienda[j];
                              var row = {
                                descripcion: rowEnmienda.descripcion,
                                marcado: 0,
                                class: "btn-default btn-xs fa fa-square-o",
                                classNo: "btn-default btn-xs fa fa-square-o"
                              };
                              dataRequisito.push(row);
                            }
                          }
                        }
                      } else {                        
                        for (var i = 0; i < dataEnmienda.length; i++) {
                          if (dataEnmienda[i].codigo != null) {
                            for (var j = 0; j < dataEnmienda[i].enmienda.length; j++) {
                              var row = {
                                descripcion: dataEnmienda[i].enmienda[j].descripcion,
                                marcado: 0,
                                class: "btn-default btn-xs fa fa-square-o",
                                classNo: "btn-default btn-xs fa fa-square-o"
                              };
                              dataRequisito.push(row);
                            }
                          }
                        }
                      }
                      $scope.item = item;
                      $scope.dataRequisito = dataRequisito;
                      ngDialog.open({
                        template: 'app/gestion/enmienda.gestion.list.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr', 'tareaService',
                          function ($scope, $state, toastr, tareaService) {

                            $scope.cerrarVentana = function () {
                              ngDialog.close();
                            }

                            $scope.marcarRequisito = function ( item ) {
                              if (item.marcado == 1) {
                                item.marcado = 0;
                                item.class = 'btn-default btn-xs fa fa-square-o';
                              } else {
                                item.marcado = 1;
                                item.class = 'btn-success btn-xs fa fa-check-square-o';
                              }
                            }

                            $scope.aceptarRequisito = function () {
                              for (var i = 0; i < $scope.dataRequisito.length; i++) {
                                if ($scope.dataRequisito[i].marcado == 0) {
                                  toastr.error('Hay requisitos que no fueron marcados');
                                  return;
                                }
                              }

                              var item = angular.copy($scope.item);
                              var ttGestion = item.ttGestion;
                              item.ttGestion = {};
                              item.ttGestion = {
                                gestionId: ttGestion.gestionId,
                                tcTipoGestion: ttGestion.tcTipoGestion,
                                estadoId : ttGestion.estadoId,
                                tcPersonaCrea: ttGestion.tcPersonaCrea,
                                tcTipoBosque: ttGestion.tcTipoBosque,
                                tcElaborador: ttGestion.tcElaborador,
                                ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                              };
                              item.ttGestion.tcPersonaCrea.foto = null;
                              item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                              tareaService.recibirEnmiendas( item ).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  $scope.submitForm(true);
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });

                            }

                          }]
                      });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.verDocumentoEnmienda = function (item) {
                if (item.estadoId != 0) {
                  tareaService.get( item.tareaId ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var data = res.data[0];
                      data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                      if (data.tcTask.taskId == 5) {
                        utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      } else {
                        utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                } else {
                  toastr.error('La enmienda esta anulada');
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  gestionService.getListaEnmienda( $scope.loginData.subregionId, 1 ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }//submitForm

            }]
        })
    }
  ]
);
