angular.module('app.tipoespecie', [
  'ui.router',
  'toastr',
  'app.tipoespecie.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tipoespecie', {
          abstract: true,
          url: 'tipoespecie',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Tipo de entidad';
            }]
        })
        .state('index.tipoespecie.list', {
          url: '',
          templateUrl: 'app/tipoespecie/tipoespecie.list.tpl.html',
          resolve: {
            dataTipoEspecie: ['tipoespecieService',
              function ( tipoespecieService ){
                return tipoespecieService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataTipoEspecie',
            function (  $scope,   $state,   dataTipoEspecie) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tipoEspecieDesc', name: 'tipoespecieDesc', displayName: 'Tipo de documento' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataTipoEspecie.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ tipoEspecieId: row.tipoEspecieId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.tipoespecie.add', {
          url: '/add',
          templateUrl: 'app/tipoespecie/tipoespecie.add.tpl.html',
          resolve: {
            dataEstado: ['tipoespecieService',
              function ( tipoespecieService ){
                return tipoespecieService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tipoespecieService', 'dataEstado',
            function (  $scope,   $state,   toastr,   tipoespecieService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tipoespecieService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.tipoespecie.edit', {
          url: '/:tipoEspecieId/edit',
          templateUrl: 'app/tipoespecie/tipoespecie.add.tpl.html',
          resolve: {
            dataTipoEspecie: ['tipoespecieService', '$stateParams',
              function ( tipoespecieService, $stateParams ){
                return tipoespecieService.get( $stateParams.tipoEspecieId );
              }],
            dataEstado: ['tipoespecieService',
              function ( tipoespecieService ){
                return tipoespecieService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tipoespecieService', 'dataTipoEspecie', 'dataEstado',
            function (  $scope,   $state,   toastr,   tipoespecieService,   dataTipoEspecie, dataEstado) {
              $scope.data = dataTipoEspecie.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tipoespecieService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
