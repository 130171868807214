angular.module('app.resolucion', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.resolucion', {
          abstract: true,
          url: 'resolucion',
          template: '<div ui-view></div>',
          resolve: {
          },
          controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
            function (  $scope, toastr, $timeout, gestionService, utilsService) {
              $scope.module = 'Resolución';
            }]
        })
        .state('index.resolucion.list', {
          url: '',
          templateUrl: 'app/resolucion/resolucion.list.tpl.html',
          resolve: {
            dataSolicitud: ['gestionService', 'authService', '$filter',
              function ( gestionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var filtro = {
                  fechaIni : null,
                  fechaFin : null,
                  estadoId : 1,
                  elaboradorId : loginData.subregionId
                }
                return gestionService.getListaResolucion(filtro);
              }]
          },
          controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog',
            function (  $scope,   $state,   dataSolicitud, uiGridConstants, toastr, $filter, gestionService, utilsService, $timeout, ngDialog) {
              $scope.dataEstado = [
                {estadoId: 1, estadoDesc: 'Sin entregar'},
                {estadoId: 2, estadoDesc: 'Entregados'},
                {estadoId: 3, estadoDesc: 'Requisitos enviados'},
                {estadoId: -1, estadoDesc: 'Todos'}
              ];

              var now = new Date();
              var begin = new Date();
              begin.setDate(begin.getDate() - 60);

              $scope.data = {
                estadoId : 1,
                elaboradorId : $scope.loginData.subregionId,
                fechaIni: begin,
                fechaFin: now
              }

              $scope.descripcionEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Sin entregar";
                }
                if (estadoId == 3) {
                  return "Requisitos enviados";
                }
                return "Entregado";
              }

              $scope.mostrarEntrega = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' } ,
                { field:'ttGestion.tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                { field:'fechaEntrega', name: 'fechaEntrega', displayName: 'Fecha entrega', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '15%' },
                { field:'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                { name: 'Estado', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options">{{grid.appScope.descripcionEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '7%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarEntrega(row.entity.estadoId)" ng-click="grid.appScope.entregarResolucion(row.entity)" title="Marcar como entregado al solicitante"> <i class="fa fa-paper-plane"></i> </button> '
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataSolicitud.data;

              $scope.setEstado = function ( item ) {
                if (item.estadoId == 1) {
                  $scope.mostrarFecha = false;
                } else {
                  $scope.mostrarFecha = true;
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = null;
                  var fechaFin = null;
                  if ($scope.data.estadoId != 1) {
                    if ($scope.data.fechaFin != null) {
                      fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                    }
                    if ($scope.data.fechaIni) {
                      fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                    }
                  }
                  var filtro = {
                    fechaIni : fechaIni,
                    fechaFin : fechaFin,
                    estadoId : $scope.data.estadoId,
                    elaboradorId : $scope.loginData.subregionId
                  }
                  if ($scope.data.estadoId != 1) {
                    filtro.fechaIni = new Date(fechaIni);
                    filtro.fechaFin = new Date(fechaFin);
                  }
                  gestionService.getListaResolucion( filtro ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.entregarResolucion = function (item) {
                if (item.estadoId == 2) {
                  toastr.error('La resolución ya fue entregada');
                  return;
                }
                if (item.estadoId == 3) {
                  toastr.error('La resolución no puede ser entregada debido a que debe recibirse los requisitos');
                  return;
                }
                swal({
                  title: "Confirmación",
                  text: "¿Está segura (o) de marcar como: resolución entregada al solicitante? \nEsta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var ttGestion = item.ttGestion;
                    item.ttGestion = {};
                    item.ttGestion = {
                      gestionId: ttGestion.gestionId,
                      tcTipoGestion: ttGestion.tcTipoGestion,
                      estadoId : ttGestion.estadoId,
                      tcPersonaCrea: ttGestion.tcPersonaCrea,
                      tcTipoBosque: ttGestion.tcTipoBosque,
                      tcElaborador: ttGestion.tcElaborador,
                      ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                    };
                    item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                    gestionService.marcarEntregaResolucion( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        $scope.submitForm(true);
                        toastr.success(res.message);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

            }]
        })
    }
  ]
);
