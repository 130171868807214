angular.module('app.gestion', [
  'ui.router',
  'toastr',
  'app.gestion.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.gestion', {
            abstract: true,
            url: 'gestion',
            template: '<div ui-view></div>',
            resolve: {
              permisoEstado: ['authService',
                function (authService) {
                  return authService.loginPermission(2);
                }],
              dataEstado: ['gestionService',
                function (gestionService) {
                  return gestionService.listStatus();
                }]
            },
            controller: ['$scope', 'dataEstado', 'gestionService', 'utilsService', 'toastr', '$timeout',
              function ($scope, dataEstado, gestionService, utilsService, toastr, $timeout) {
                $scope.module = 'Gestion de Manejo';
                $scope.dataEstado = dataEstado.data;
                $scope.dataEstado.splice(0, 0, { estadoId: 0, estadoDesc: "Anulado" });
                $scope.dataEstado.splice(0, 0, { estadoId: -1, estadoDesc: "Todos" });
                $scope.removeStateToGo();
                $scope.globales.totalPaginas = 0;
                $scope.globales.totalPaginasPoa = 0;

                $scope.imprimirCronograma = function (data) {
                  $scope.globales.totalPaginas = utilsService.getLastPage();
                  if ($scope.globales.totalPaginas <= 0) {
                    toastr.error("Debe imprimir primero el plan luego el cronograma");
                  } else {
                    utilsService.generarCronograma($scope.dataEmpresa, data, $scope.globales.totalPaginas);
                  }
                }

                $scope.imprimirDocumento = function (gestionId) {
                  try {
                    gestionService.getConCronograma(gestionId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var dataResumen = data.rodal;
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                            }
                            if (dataResumen[i].especies[j].fuste != null) {
                              dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                            }
                          }
                        }
                        //generales
                        if (data.ttInventarioGestion == undefined || data.ttInventarioGestion == null) {
                          toastr.error("La información de inventario esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.ttPlanificacionGestion == undefined || data.ttPlanificacionGestion == null) {
                          toastr.error("La información de planificación esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.cronograma == undefined || data.cronograma == null) {
                          toastr.error("El cronograma esta incompleto, favor de revisar");
                          return;
                        }
                        try {
                          if (data.ttInventarioGestion.data != undefined && data.ttInventarioGestion.data != null) {
                            data.ttInventarioGestion.data = JSON.parse(data.ttInventarioGestion.data);
                          }
                          try {
                            data.ttInventarioGestion.brinzal = JSON.parse(data.ttInventarioGestion.brinzal);
                          } catch (e) {
                            data.ttInventarioGestion.brinzal = [];
                          }
                          try {
                            data.ttInventarioGestion.latizal = JSON.parse(data.ttInventarioGestion.latizal);
                          } catch (e) {
                            data.ttInventarioGestion.latizal = [];
                          }
                          data.cronograma = JSON.parse(data.cronograma);
                          toastr.info('Espere mientras se imprimen los documentos', 'Imprimiendo');
                          utilsService.generarPlan($scope.dataEmpresa, data);
                          $timeout(function () {
                            utilsService.generarSolicitudLicencia(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                            $scope.imprimirCronograma(data);
                          }, 3000);
                          if (data.ttResumenGestion.noTurnos > 1) {
                            $timeout(function () {
                              utilsService.generarPoa($scope.dataEmpresa, data, 1);
                              $timeout(function () {
                                $scope.globales.totalPaginasPoa = utilsService.getLastPagePoa();
                                var totalPaginas = $scope.globales.totalPaginasPoa;
                                utilsService.generarCronograma($scope.dataEmpresa, data, totalPaginas, 0, 1);
                              }, 3000);
                            }, 1000);
                          }
                        } catch (e) {
                          toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador. " + e.message);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } catch (e) {
                    toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador");
                  }
                }

              }]
          })
          .state('index.gestion.lista', {
            url: '',
            templateUrl: 'app/gestion/plan.list.tpl.html',
            resolve: {
              dataGestion: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 90);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: -1,
                    personaId: loginData.personaId,
                    elaboradorId: 0
                  }
                  return gestionService.listado(filtro);
                }],
              dataMotivo: ['motivomodificacionService', '$stateParams',
                function (motivomodificacionService, $stateParams) {
                  return motivomodificacionService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'dataGestion', 'uiGridConstants', 'toastr', '$filter', 'gestionService', 'tareaService',
              'utilsService', 'ngDialog', 'catalogosService', 'dataMotivo', 'tipogarantiaService', 'Upload', '$timeout',
              function ($scope, $state, dataGestion, uiGridConstants, toastr, $filter, gestionService, tareaService,
                utilsService, ngDialog, catalogosService, dataMotivo, tipogarantiaService, Upload, $timeout) {

                $scope.titulo = "Mis Gestiones";
                $scope.subtitulo = "Lista de gestiones realizadas";
                $scope.dataMotivoModificacion = dataMotivo.data;
                $scope.mostrarAgregar = true;
                $scope.mostrarEstado = true;
                var now = new Date();
                var begin = $scope.addDays(now, -90);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: -1,
                  personaId: $scope.loginData.personaId
                }

                $scope.mostrarAnular = function (estadoId) {
                  if (estadoId == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarImprimir = function (estadoId) {
                  if (estadoId > 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEnviar = function (id) {
                  if (id == 3) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAvance = function (estadoId) {
                  if (estadoId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEnmienda = function (estadoId) {
                  if (estadoId == 6) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarResolucion = function (pasoId) {
                  if (pasoId == 1 || pasoId == 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarRequisitos = function (pasoId) {
                  if (pasoId == 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarOtrasOpciones = function (estadoId) {
                  if (estadoId == 7) {
                    return true;
                  }
                  return false;
                }

                var dataEstado = $scope.dataEstado;
                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'nug', name: 'nug', displayName: 'NUG', width: '10%', align: "right" },
                  { field: 'tcElaborador.personaDesc', name: 'regente', displayName: 'Nombre del elaborador' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'fechaAceptacion', name: 'fechaAceptacion', displayName: 'Fecha aceptación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Tipo' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarAnular(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"> <i class="zmdi zmdi-edit"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarAnular(row.entity.estadoId)" ng-click="grid.appScope.anularSolicitud(row.entity)" title="Cancelar solicitud"> <i class="zmdi zmdi-close"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarImprimir(row.entity.estadoId)" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="text-info zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarImprimir(row.entity.estadoId)" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="text-primary fa fa-file-archive-o"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarEnviar(row.entity.estadoId)" ng-click="grid.appScope.enviarDocumento(row.entity)" title="Enviar solicitud al INAB"> <i class="fa fa-paper-plane-o"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAvance(row.entity.estadoId)" ng-click="grid.appScope.getAvance(row.entity)" title="Clic para ver el avance"> <i class="text-warning fa fa-list" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarEnmienda(row.entity.estadoId)" ng-click="grid.appScope.verEnmienda(row.entity)" title="Enmienda"> <i class="fa fa-medkit" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarResolucion(row.entity.pasoId)" ng-click="grid.appScope.imprimirResolucion(row.entity)" title="Imprimir resolución"> <i class="text-red fa fa-file-archive-o"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-show="grid.appScope.mostrarOtrasOpciones(row.entity.estadoId)" ng-click="grid.appScope.verMasOpciones(row.entity)" title="Clic para opciones sobre la gestión"> <i class="fa fa-cogs" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarRequisitos(row.entity.pasoId)" ng-click="grid.appScope.listarEnmiendasRequisitos(row.entity)" title="Listar enmiendas de requisitos"> <i class="text-danger fa fa-medkit"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarRequisitos(row.entity.pasoId)" ng-click="grid.appScope.subirRequisitos(row.entity)" title="Subir requisitos"> <i class="text-success fa fa-cloud-upload"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataGestion.data;

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                      function ($scope, $state, toastr, dataAnexo, gestionService) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                      }]
                  });
                }

                $scope.mostrarFiltroExpediente = true;
                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.expediente = null;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    gestionService.buscarPorExpediente($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.editRow = function (row) {
                  if (row.tcTipoGestion.tipoGestionId == 8) {
                    $state.go('^.editarplan', { gestionId: row.gestionId });
                  } else {
                    $state.go('^.editar', { gestionId: row.gestionId });
                  }
                }

                $scope.etiquetaEstado = function (estadoId) {
                  var estadoDesc = "Desconocido";
                  for (var i = 0; i < dataEstado.length; i++) {
                    if (dataEstado[i].estadoId == estadoId) {
                      estadoDesc = dataEstado[i].estadoDesc;
                      break;
                    }
                  }
                  return estadoDesc;
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId,
                      personaId: $scope.loginData.personaId
                    }
                    gestionService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.imprimirPlan = function (row) {
                  if (row.estadoId > 1) {
                    $scope.imprimirDocumento(row.gestionId);
                  } else {
                    if (row.estadoId < 1) {
                      toastr.error('No es posible imprimir planes anulados');
                    } else {
                      toastr.error('No se ha realizado el plan de manejo, no es posible continuar');
                    }
                  }
                }

                $scope.imprimirResolucion = function (row) {
                  gestionService.getResolucion(row.gestionId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      data.ttSeguimientoTarea.fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
                      data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                      data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                      data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                      var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDiaResolucion = $scope.numeroALetras(dia);
                      var strAnioResolucion = $scope.numeroALetras(anio, false);
                      utilsService.setEsPrevia(true);
                      if (data.tcTask.taskId == 6) {
                        utilsService.generarOpinionRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, null);
                      } else {
                        utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa);
                        if (data.aprobado == 1) {
                          tareaService.getGestionParaNotificacion(row.gestionId).then(function (resp) {
                            if (resp.status == "OK") {
                              var data = resp.data[0];
                              var esLicencia = true;
                              if (data.tcTask.taskId == 47) {
                                esLicencia = false;
                              }
                              if (data.ttPadre.aprobado == 1) {
                                if (esLicencia) {
                                  $timeout(function () {
                                    utilsService.setEsPrevia(true);
                                    utilsService.generarNotificacionLicencia(res.data[0], $scope.loginData.usuario, $scope.dataEmpresa);
                                  }, 1000);
                                } else {
                                  data.aprobado = 1;
                                  var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                  var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                  var strDiaResolucion = $scope.numeroALetras(dia);
                                  var strAnioResolucion = $scope.numeroALetras(anio, false);
                                  var esPoa = false;
                                  var noTurno = 1;
                                  utilsService.setEsPrevia(true);
                                  utilsService.generarOficioRequisitoPoa(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, null, esPoa, noTurno);
                                }
                                $timeout(function () {
                                  if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2) { //poliza de fianzas
                                    data.ttPadre.ttSeguimientoTarea.analisis = JSON.parse(data.ttPadre.ttSeguimientoTarea.analisis);
                                    data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                    var strVigencia = "";
                                    var analisis = data.ttPadre.ttSeguimientoTarea.analisis;
                                    var row = analisis[analisis.length - 1];
                                    var vigencia = row.vigencia;
                                    var strFecha = vigencia.substring(vigencia.indexOf('al') + 3);
                                    var parts = strFecha.split('/');
                                    var fin = new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
                                    var ini = new Date(data.ttPadre.ttSeguimientoTarea.fundamento.fechaIni);
                                    dia = $filter('date')(ini, 'dd');
                                    anio = $filter('date')(ini, 'yyyy');
                                    strVigencia = $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(ini.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                    dia = $filter('date')(fin, 'dd');
                                    anio = $filter('date')(fin, 'yyyy');
                                    strVigencia += " al " + $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(fin.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                    utilsService.setEsPrevia(true);
                                    utilsService.generarCartaPoliza(data, strVigencia, $scope.loginData.usuario, $scope.dataEmpresa);
                                  }
                                }, 2000);
                              }
                            } else {
                              toastr.error(resp.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                //solicitante
                $scope.verEnmienda = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/gestion/historial.enmienda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['gestionService',
                        function (gestionService) {
                          return gestionService.getEnmiendaHistorial(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'tareaService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, tareaService, $timeout) {
                        $scope.dataHistorial = dataHistorial.data;

                        $scope.getEstadoEnmienda = function (estadoId) {
                          if (estadoId == 1) {
                            return 'Activo';
                          }
                          if (estadoId == 2) {
                            return 'Realizado';
                          }
                          return 'Inactivo';
                        }

                        $scope.verDocumentoEnmienda = function (item) {
                          if (item.estadoId != 0) {
                            tareaService.get(item.tareaId).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                if (data.tcTask.taskId == 5) {
                                  utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error('La enmienda esta anulada');
                          }
                        }

                        $scope.verOficioEntregaEnmienda = function (item) {
                          if (item.estadoId != 0) {
                            tareaService.getOficioEnmienda(item.enmiendaId).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                var ttTarea = data.ttTarea;
                                ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                                if (ttTarea.tcTask.taskId == 5) {
                                  utilsService.generarOficioEntregaEnmiendaSubregional(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarOficioEntregaEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error('La enmienda esta anulada');
                          }
                        }

                      }]
                  });
                }

                $scope.getAvance = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/gestion/gestion.avance.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataTask: ['gestionService',
                        function (gestionService) {
                          return gestionService.getAvanceGestion(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataTask', 'tareaService', '$timeout', '$filter',
                      function ($scope, $state, toastr, dataTask, tareaService, $timeout, $filter) {
                        $scope.pasos = dataTask.data;
                        for (var i = 0; i < $scope.pasos.length; i++) {
                          var row = $scope.pasos[i];
                          if (row.estadoId == 2) {
                            row.fechaDescIni = 'Asignado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                            row.fechaDescFin = 'Finalizado';
                            row.color = 'success';
                            row.icono = 'glyphicon glyphicon-check';
                          } else {
                            row.fechaDescIni = 'Asignado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                            row.fechaDescFin = 'No se ha finalizado';
                            row.color = 'warning bounce-in';
                            row.icono = 'glyphicon glyphicon-time';
                          }
                          if (row.tcTask.permiteRecibir == 1) {
                            if (row.recibido == 0) {
                              if (row.tcTask.taskId == 3 || row.tcTask.taskId == 6) {
                                row.fechaDescIni = 'Enviado a la región desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                              } else {
                                row.fechaDescIni = 'Enviado a la subregión desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                              }
                            } else {
                              if (row.estadoId == 2) {
                                row.fechaDescIni = 'Enviado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                                row.fechaDescFin = 'Finalizado';
                                if (row.esEnmienda == 1) {
                                  row.fechaDescFin += ' (Enmienda)';
                                }
                              } else {
                                row.fechaDescIni = 'Enviado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                                row.fechaDescFin = 'No se ha finalizado';
                              }
                            }
                          }
                        }

                        $scope.enviarCorreo = function (item) {
                          $scope.paso = item;
                          ngDialog.open({
                            template: 'app/gestion/gestion.correo.tpl.html',
                            className: 'ngdialog-theme-default',
                            appendClassName: 'ngdialog-default',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueEdit',
                            name: 'dialogEdit',
                            resolve: {},
                            controller: ['$scope', '$state', 'toastr', 'tareaService', '$timeout', '$filter',
                              function ($scope, $state, toastr, tareaService, $timeout, $filter) {
                                $scope.mail = {};
                              }
                            ]
                          });
                        }

                      }]
                  });
                }

                $scope.paraTurnoLicencia = false;
                $scope.dataAnexo = [];

                $scope.setParaTurnoLicencia = function (valor) {
                  $scope.paraTurnoLicencia = valor;
                }

                $scope.setDataAnexo = function (data) {
                  $scope.dataAnexo = data;
                }

                $scope.verMasOpciones = function (item) {
                  $scope.setParaTurnoLicencia(false);
                  $scope.ttGestion = item;
                  $scope.dataElaborador = [];
                  $scope.dataRegente = [];
                  $scope.dataTipoFiador = [
                    { tipoFiadorId: 0, tipoFiadorDesc: 'Individual' },
                    { tipoFiadorId: 1, tipoFiadorDesc: 'Empresa' }
                  ];
                  ngDialog.open({
                    template: 'app/gestion/opciones.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataPoa: ['gestionService', '$stateParams',
                        function (gestionService, $stateParams) {
                          return gestionService.getUltimoPoaGestion(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataPoa',
                      function ($scope, $state, toastr, gestionService, dataPoa) {
                        if (dataPoa.status == "OK") {
                          $scope.dataPoa = dataPoa.data[0];
                          $scope.tituloOpcion = 'Último POA: ' + dataPoa.data[0].ttTarea.ttSeguimientoTarea.numeroResolucion;
                        } else {
                          if (dataPoa.status == 'warning') {
                            $scope.dataPoa = {};
                            $scope.tituloOpcion = 'Aún no tiene POA';
                          } else {
                            toastr.error(dataPoa.message);
                            $scope.dataPoa = null;
                            ngDialog.close();
                          }
                        }
                      }]
                  });
                }

                $scope.subirRequisitos = function (item) {
                  $scope.ttGestion = item;
                  ngDialog.open({
                    template: 'app/gestion/anexo.licencia.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueAnexo',
                    name: 'dialogAnexo',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getRequisitoParaLicencia(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                      function ($scope, $state, toastr, dataAnexo, gestionService) {
                        $scope.dataAnexo = [];
                        $scope.setParaTurnoLicencia(true);
                        if (dataAnexo.status == 1) {
                          $scope.dataAnexo = dataAnexo.data;
                          for (var i = 0; i < $scope.dataAnexo.length; i++) {
                            var row = $scope.dataAnexo[i];
                            if (row.anexoGestionId == 0) {
                              row.classButton = "btn btn-danger";
                              row.texto = "Agregar";
                            } else {
                              row.visibleAbrir = true;
                              row.classButton = "btn btn-primary";
                              row.texto = "Cambiar";
                            }
                          }
                          $scope.setDataAnexo($scope.dataAnexo);

                          $scope.enviarRequisitos = function () {
                            var anexos = $scope.dataAnexo;
                            for (var i = 0; i < anexos.length; i++) {
                              var row = anexos[i];
                              if (!row.visibleAbrir) {
                                swal('Anexos incompletos', 'Debe anexar todos los requisitos para poder continuar');
                                return;
                              }
                            }
                            swal({
                              title: "Confirmación",
                              text: "¿Está seguro (a) de enviar los requisitos de la solicitud de la licencia?",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                              function (isConfirm) {
                                if (isConfirm) {
                                  gestionService.enviarRequisitosTurno($scope.ttGestion.gestionId).then(function (res) {
                                    if (res.status == 1) {
                                      $scope.submitForm(true);
                                      $scope.cerrarVentana();
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              });
                          }
                        } else {
                          toastr.error(dataAnexo.message);
                        }

                      }]
                  });
                }

                $scope.dataRegente = [];
                $scope.dataPoa = null;
                $scope.dataElaborador = [];
                $scope.dataTipoGarantia = [];
                $scope.dataMes = [
                  { mesId: 1, mesDesc: '1' },
                  { mesId: 2, mesDesc: '2' },
                  { mesId: 3, mesDesc: '3' },
                  { mesId: 4, mesDesc: '4' },
                  { mesId: 5, mesDesc: '5' },
                  { mesId: 6, mesDesc: '6' },
                  { mesId: 7, mesDesc: '7' },
                  { mesId: 8, mesDesc: '8' },
                  { mesId: 9, mesDesc: '9' },
                  { mesId: 10, mesDesc: '10' },
                  { mesId: 11, mesDesc: '11' },
                  { mesId: 12, mesDesc: '12' }
                ];

                $scope.obtenerDatosElaborador = function () {
                  if ($scope.dataElaborador.length <= 0) {
                    catalogosService.getListaElaboradorPorArea($scope.ttGestion.area).then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataElaborador = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }


                $scope.obtenerDatosRegente = function () {
                  if ($scope.dataRegente.length <= 0) {
                    catalogosService.getListaRegentePorArea(parseFloat($scope.ttGestion.area)).then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataRegente = res.data;
                      } else {
                        $scope.dataRegente = [];
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      $scope.dataRegente = [];
                      toastr.error(error);
                    });
                  }
                }

                $scope.obtenerDatosElaborador = function () {
                  if ($scope.dataElaborador.length <= 0) {
                    catalogosService.getListaElaboradorPorArea($scope.ttGestion.area).then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataElaborador = res.data;
                      } else {
                        $scope.dataElaborador = [];
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      $scope.dataElaborador = [];
                      toastr.error(error);
                    });
                  }
                }

                $scope.obtenerDatosTipoGarantia = function () {
                  if ($scope.dataTipoGarantia.length <= 0) {
                    tipogarantiaService.listaActiva().then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataTipoGarantia = res.data;
                      } else {
                        $scope.dataTipoGarantia = [];
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      $scope.dataTipoGarantia = [];
                      toastr.error(error);
                    });
                  }
                }

                $scope.esParaAmpliacion = function (id) {
                  if (id == 1) {
                    $scope.mostrarAmpliacionTiempo = true;
                  } else {
                    $scope.mostrarAmpliacionVolumen = true;
                  }
                }

                $scope.obtenerDatosUltimoPoa = function (esAmpliacion) {
                  if (esAmpliacion == null) {
                    esAmpliacion = 0;
                  }
                  if ($scope.dataPoa != null) {
                    if ($scope.dataPoa.ttGestion == null) {
                      $scope.dataPoa.ttGestion = {
                        gestionId: 0
                      };
                    }
                  }
                  if ($scope.dataPoa == null || $scope.ttGestion.gestionId != $scope.dataPoa.ttGestion.gestionId) {
                    gestionService.getUltimoPoaGestion(parseFloat($scope.ttGestion.gestionId)).then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataPoa = res.data[0];
                        $scope.tituloOpcion = 'POA activo: ' + res.data[0].ttTarea.ttSeguimientoTarea.numeroResolucion;
                      } else {
                        if (res.status == 'warning') {
                          $scope.dataPoa = {};
                          $scope.tituloOpcion = 'Aún no tiene POA';
                        } else {
                          toastr.error(res.message);
                          $scope.dataPoa = null;
                        }
                      }
                    }, function (error) {
                      $scope.dataPoa = null;
                      toastr.error(error);
                    });
                  }
                }

                $scope.etiquetaEstadoPoa = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'Solicitado';
                    case 2: return 'Solicitado';
                    case 3: return 'Finalizado';
                    case 99: return 'Denegado';
                    default: return 'Elaborando';
                  }
                }

                $scope.mostrarAutorizacion = function (estadoId) {
                  if (estadoId == 3) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptionsPoa = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptionsPoa.enableFiltering = true;
                $scope.gridOptionsPoa.columnDefs = [
                  { field: 'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' },
                  { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'tcPersonaCrea', displayName: 'Solicitante' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'numero', name: 'numero', displayName: 'Número', width: '10%' },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstadoPoa(row.entity.estadoId)}}</div>'
                  },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.imprimirSolicitudPoa(row.entity)" title="Imprimir solicitud"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAutorizacion(row.entity.estadoId)"  ng-click="grid.appScope.imprimirAutorizacionPoa(row.entity)" title="Imprimir autorización"> <i class="fa fa-print"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptionsPoa.data = [];

                $scope.mostrarPanel = function (panel) {
                  $scope.data = {};
                  $scope.mostrarModificacionPlan = false;
                  $scope.mostrarOtroPoa = false;
                  $scope.mostrarAmpliacionTiempo = false;
                  $scope.mostrarCambioRegente = false;
                  $scope.mostrarCambioPersona = false;
                  switch (panel) {
                    case 1:
                      $scope.mostrarModificacionPlan = true;
                      break;
                    case 2:
                      $scope.setParaTurnoLicencia(false);
                      $scope.mostrarOtroPoa = true;
                      $scope.data.tcRegente = {};
                      gestionService.listaSolicitudPoaPorGestion($scope.ttGestion.gestionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.gridOptionsPoa.data = res.data;
                        } else {
                          $scope.gridOptionsPoa.data = [];
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        $scope.gridOptionsPoa.data = [];
                        toastr.error(error);
                      });
                      break;
                    case 3:
                      $scope.mostrarAmpliacionTiempo = true;
                      $scope.tituloOpcion = 'Ampliación de tiempo - Expediente: ' + $scope.ttGestion.expediente;
                      break;
                    case 4:
                      $scope.mostrarCambioRegente = true;
                      break;
                    case 5:
                      $scope.mostrarCambioPersona = true;
                      break;
                  }
                }

                $scope.cambiarRegente = function () {
                  $scope.obtenerDatosRegente();
                  $scope.mostrarPanel(4);
                }

                $scope.modificarPlan = function () {
                  $scope.obtenerDatosElaborador();
                  $scope.mostrarPanel(1);
                }

                $scope.dataAnexo = [];
                $scope.solicitarPoa = function () {
                  $scope.obtenerDatosRegente();
                  $scope.obtenerDatosTipoGarantia();
                  if ($scope.dataAnexo.length <= 0) {
                    gestionService.getListaRequisitoFiduciaria().then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataAnexo = res.data;
                        var dataRequisito = [];
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                  $scope.mostrarPanel(2);
                }

                $scope.setTipoGarantia = function (item) {
                  if (item.tipoGarantiaId == 1 || item.tipoGarantiaId == 7) {
                    $scope.mostrarRequisito = true;
                  } else {
                    $scope.mostrarRequisito = false;
                  }
                }

                $scope.setTipoFiador = function (item) {
                  var dataAnexo = [];
                  if (item.tipoFiadorId == 1) {
                    for (var i = 0; i < $scope.dataAnexo.length; i++) {
                      var row = $scope.dataAnexo[i];
                      if (row.tcGrupoRequisito.grupoRequisitoId == 12) {
                        dataAnexo.push({
                          tcTipoAnexo: row,
                          cargado: false,
                          texto: 'Agregar',
                          classButton: 'btn btn-danger',
                          obligatorio: true
                        });
                      }
                    }
                  } else {
                    for (var i = 0; i < $scope.dataAnexo.length; i++) {
                      var row = $scope.dataAnexo[i];
                      if (row.tcGrupoRequisito.grupoRequisitoId == 9) {
                        dataAnexo.push({
                          tcTipoAnexo: row,
                          cargado: false,
                          texto: 'Agregar',
                          classButton: 'btn btn-danger',
                          obligatorio: true
                        });
                      }
                    }
                  }
                  for (var i = 0; i < $scope.dataAnexo.length; i++) {
                    var row = $scope.dataAnexo[i];
                    if (row.tcGrupoRequisito.grupoRequisitoId == 13) {
                      dataAnexo.push({
                        tcTipoAnexo: row,
                        cargado: false,
                        texto: 'Agregar',
                        classButton: 'btn btn-danger',
                        obligatorio: row.tipoAnexoId == 38 ? true : false
                      });
                    }
                  }
                  $scope.data.anexo = dataAnexo;
                }

                $scope.abrirAnexo = function (anexoGestionId) {
                  gestionService.getAnexoGestion(anexoGestionId);
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      //send to database
                      var item = {
                        tcTipoAnexo: {
                          tipoAnexoId: tipoAnexoId
                        },
                        ruta: item.rutaArchivo,
                        nombre: item.nombre,
                        size: item.size,
                        extension: item.extension,
                        nombreOriginal: item.nombreOriginal
                      };
                      var tmp = $scope.ttGestion;
                      item.ttGestion = {
                        gestionId: tmp.gestionId,
                        tcTipoGestion: tmp.tcTipoGestion,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        tcTipoBosque: tmp.tcTipoBosque,
                        tcElaborador: tmp.tcElaborador,
                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                        personaAnulaId: $scope.loginData.personaId
                      };
                      if ($scope.paraTurnoLicencia) {
                        item.turno = 1;
                        gestionService.agregarAnexo(item).then(function (response) {
                          if (response.status == 'OK') {
                            item = response.data[0];
                            if (tipoAnexoId > 1) {
                              for (var i = 0; i < $scope.dataAnexo.length; i++) {
                                var row = $scope.dataAnexo[i];
                                if (row.tcTipoAnexo.tipoAnexoId == item.tcTipoAnexo.tipoAnexoId) {
                                  var tipoAnexoDesc = row.tcTipoAnexo.tipoAnexoDesc;
                                  row = item;
                                  row.tcTipoAnexo.tipoAnexoDesc = tipoAnexoDesc;
                                  row.cargado = true;
                                  row.texto = 'Cambiar';
                                  row.classButton = 'btn btn-primary';
                                  row.visibleAbrir = true;
                                  $scope.dataAnexo[i] = row;
                                  break;
                                }
                              }
                            }
                            toastr.success(response.message);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        for (var i = 0; i < $scope.data.anexo.length; i++) {
                          var row = $scope.data.anexo[i];
                          if (row.tcTipoAnexo.tipoAnexoId == item.tcTipoAnexo.tipoAnexoId) {
                            var tipoAnexoDesc = row.tcTipoAnexo.tipoAnexoDesc;
                            row = item;
                            row.tcTipoAnexo.tipoAnexoDesc = tipoAnexoDesc;
                            row.cargado = true;
                            row.texto = item.nombreOriginal;
                            row.classButton = 'btn btn-primary';
                            $scope.data.anexo[i] = row;
                            break;
                          }
                        }
                      }
                    } else {
                      toastr.error(resp.data.message);
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.ampliarTiempo = function () {
                  $scope.mostrarPanel(3);
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.submitFormOtroPoa = function (isValid) {
                  if (isValid) {
                    if ($scope.data.numero <= 1) {
                      toastr.error('El número de POA debe ser mayor a uno');
                      return;
                    }
                    if ($scope.data.anexo == null) {
                      $scope.data.anexo = [];
                    }
                    if ($scope.data.anexo.length > 0) {
                      for (var i = 0; i < $scope.data.anexo.length; i++) {
                        var row = $scope.data.anexo[i];
                        if (!row.cargado && row.obligatorio) {
                          toastr.error('Debe cargar todos los anexos obligatorios');
                          return;
                        }
                      }
                    }
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de realizar la solicitud de un nuevo POA?",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          var item = $scope.ttGestion;
                          var ttGestion = {
                            gestionId: item.gestionId,
                            tcTipoGestion: item.tcTipoGestion,
                            estadoId: item.estadoId,
                            tcPersonaCrea: item.tcPersonaCrea,
                            tcTipoBosque: item.tcTipoBosque,
                            tcElaborador: item.tcElaborador,
                            ttTipoPropietarioGestion: item.ttTipoPropietarioGestion,
                            personaAnulaId: $scope.loginData.personaId
                          }
                          var data = angular.copy($scope.data);
                          data.ttGestion = ttGestion;
                          gestionService.solicitarPoa(data).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success('Solicitud creada con éxito, favor de imprimir la solicitud y presentarlo a la subregión');
                              gestionService.obtenerSolicitudPoa(res.data[0].solicitudPoaId).then(function (res) {
                                if (res.status == "OK") {
                                  utilsService.generarSolicitudPoa(res.data[0], $scope.loginData.usuario, $scope.dataEmpresa);
                                  $scope.mostrarPanel(0);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                              gestionService.get(ttGestion.gestionId).then(function (res) {
                                if (res.status == "OK") {
                                  utilsService.generarPoa($scope.dataEmpresa, res.data[0], data.numero);
                                  $scope.mostrarPanel(0);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    toastr.error('Debe indicar los datos obligatorios');
                  }
                }

                $scope.imprimirSolicitudPoa = function (item) {
                  gestionService.obtenerSolicitudPoa(item.solicitudPoaId).then(function (res) {
                    if (res.status == "OK") {
                      utilsService.generarSolicitudPoa(res.data[0], $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                  gestionService.get(item.ttGestion.gestionId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      utilsService.generarPoa($scope.dataEmpresa, data, item.numero);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirAutorizacionPoa = function (item) {
                  tareaService.getGestionParaPoaTarea(item.ttGestion.gestionId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      utilsService.generarPoa($scope.dataEmpresa, data, item.numero);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.anularSolicitud = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de anular la solicitud: " + item.tcTipoGestion.tipoGestionDesc + " con un área de: " + item.area + "?",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Observaciones"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario indicar la razón del rechazo');
                        return false;
                      }
                      item.personaAnulaId = $scope.loginData.personaId;
                      item.observaciones = inputValue;
                      gestionService.anularSolicitud(item).then(function (res) {
                        if (res.status == "OK") {
                          toastr.success(res.message);
                          $scope.submitForm(true);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

                $scope.enviarDocumento = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro (a) de enviar la solicitud \n" + item.tcTipoGestion.tipoGestionDesc + "\ncon un área de: " + item.area + " a las oficinas del INAB? \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var ttGestion = {
                          gestionId: item.gestionId,
                          tcTipoGestion: item.tcTipoGestion,
                          estadoId: item.estadoId,
                          tcPersonaCrea: item.tcPersonaCrea,
                          tcTipoBosque: item.tcTipoBosque,
                          tcElaborador: item.tcElaborador,
                          ttTipoPropietarioGestion: item.ttTipoPropietarioGestion,
                          personaAnulaId: $scope.loginData.personaId
                        }
                        gestionService.enviarDocumento(ttGestion).then(function (res) {
                          if (res.status == "OK") {
                            $scope.submitForm(true);
                            $scope.tituloRequisito = res.message;
                            ngDialog.open({
                              template: 'app/gestion/plan.requisito.tpl.html',
                              className: 'ngdialog-theme-flat',
                              appendClassName: 'ngdialog-flat',
                              closeByDocument: false,
                              closeByEscape: true,
                              closeByNavigation: true,
                              scope: $scope,
                              id: 'uniqueEdit',
                              name: 'dialogEditar',
                              resolve: {},
                              controller: ['$scope', '$state', 'toastr', 'gestionService',
                                function ($scope, $state, toastr, gestionService) {
                                  $scope.dataRequisito = res.data;
                                }]
                            })
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.cambiarNotas = function () {
                  $state.go('^.cambiarnota');
                }

                $scope.listarEnmiendasRequisitos = function (item) {
                  $scope.ttGestion = item;
                  ngDialog.open({
                    template: 'app/gestion/historial.enmienda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['gestionService',
                        function (gestionService) {
                          return gestionService.listaEnmiendaRequisitosLicencia(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'tareaService', '$timeout', 'utilsService',
                      function ($scope, $state, toastr, dataHistorial, tareaService, $timeout, utilsService) {
                        $scope.dataHistorial = dataHistorial.data;
                        $scope.ttEnmieda = null;

                        $scope.verDocumentoEnmienda = function (item) {
                          gestionService.enmiendaRequisitosLicencia(item.tareaId).then(function (res) {
                            if (res.status == 1) {
                              var ttTarea = res.data;
                              ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                              utilsService.generarEnmiendaTurno(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.verOficioEntregaEnmienda = function (item) {
                          toastr.error('Esta opción no está disponible para este tipo de enmiendas');
                        }
                        
                      }]
                  });
                }

              }]
          })
          .state('index.gestion.nuevo', {
            url: '/nuevo',
            templateUrl: 'app/gestion/plan.add.tpl.html',
            resolve: {
              dataTipoGestion: ['tipogestionService', '$stateParams',
                function (tipogestionService, $stateParams) {
                  return tipogestionService.getPorModulo(1);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'catalogosService', 'dataTipoGestion',
              function ($scope, $state, toastr, gestionService, catalogosService, dataTipoGestion) {

                $scope.deManejo = ' de Manejo';
                $scope.dataRegente = [];
                $scope.dataTipoGestion = dataTipoGestion.data;
                $scope.mostrarProfesional = false;
                $scope.data = {
                  tcTipoGestion: {
                    tipoGestionId: 1
                  },
                  estadoId: 1,
                  tcPersonaCrea: {
                    personaId: $scope.loginData.personaId
                  },
                  tcTipoBosque: {
                    tipoBosqueId: 1
                  },
                  tcElaborador: {
                    personaId: 0,
                    nit: null
                  }
                };

                $scope.setElaborador = function (item) {
                  if (item.categoriaProfesion.trim().toLowerCase() == 'profesional') {
                    $scope.data.categoriaProfesionId = 2;
                  } else {
                    $scope.data.categoriaProfesionId = 1;
                  }
                }

                $scope.cargarElaboradores = function () {
                  catalogosService.getListaElaboradorPorArea($scope.data.area).then(function (res) {
                    if (res.status == "OK") {
                      $scope.dataRegente = res.data;
                      //aplicar filtros segun el area para lista de profesionales
                      $scope.mostrarProfesional = true;
                      $scope.crearRegistro = true;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.submitForm = function (isValid) {
                  $scope.dataRegente = [];
                  $scope.mostrarProfesional = false;
                  if ($scope.data.area == null) {
                    toastr.error('Por favor indique el área para aprovechar');
                    return;
                  }
                  if (parseFloat($scope.data.area) <= 0) {
                    toastr.error('Por favor indique un número válido');
                    return;
                  }
                  $scope.data.area = parseFloat($scope.data.area).toFixed(4);
                  if ($scope.dataRegente.length <= 0) {
                    if ($scope.loginData.perfilId == 3) {
                      swal({
                        title: "¿Está seguro que usted es el propietario de la finca?",
                        text: "Si no lo es, ingrese en el perfil del propietario y realice el mismo procedimiento",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.cargarElaboradores();
                          }
                        });
                    } else {
                      $scope.cargarElaboradores();
                    }
                  }
                  if (isValid && $scope.crearRegistro) {
                    gestionService.nuevo($scope.data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $state.go('^.lista');
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.gestion.nuevoplan', {
            url: '/nuevoplan',
            templateUrl: 'app/gestion/plan.add.tpl.html',
            resolve: {
              dataTipoGestion: ['tipogestionService', '$stateParams',
                function (tipogestionService, $stateParams) {
                  return tipogestionService.getPorModulo(3);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'catalogosService', 'dataTipoGestion',
              function ($scope, $state, toastr, gestionService, catalogosService, dataTipoGestion) {
                $scope.conFines = ' con fines sanitarios';
                $scope.dataRegente = [];
                $scope.dataTipoGestion = dataTipoGestion.data;
                $scope.mostrarProfesional = false;
                $scope.data = {
                  tcTipoGestion: {},
                  estadoId: 1,
                  tcPersonaCrea: {
                    personaId: $scope.loginData.personaId
                  },
                  tcTipoBosque: {
                    tipoBosqueId: 1
                  },
                  tcElaborador: {
                    personaId: 0,
                    nit: null
                  }
                };

                $scope.setElaborador = function (item) {
                  if (item.categoriaProfesion.trim().toLowerCase() == 'profesional') {
                    $scope.data.categoriaProfesionId = 2;
                  } else {
                    $scope.data.categoriaProfesionId = 1;
                  }
                }

                $scope.submitForm = function (isValid) {
                  $scope.dataRegente = [];
                  $scope.mostrarProfesional = false;
                  if ($scope.data.area == null) {
                    toastr.error('Por favor indique el área para aprovechar');
                  } else {
                    if (parseFloat($scope.data.area) <= 0) {
                      toastr.error('Por favor indique un número válido');
                    } else {
                      $scope.data.area = parseFloat($scope.data.area).toFixed(4);
                      if ($scope.dataRegente.length <= 0) {
                        catalogosService.getListaElaboradorPorArea($scope.data.area).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataRegente = res.data;
                            //aplicar filtros segun el area para lista de profesionales
                            $scope.mostrarProfesional = true;
                            $scope.crearRegistro = true;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                  }
                  if (isValid && $scope.crearRegistro) {
                    gestionService.nuevo($scope.data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $state.go('^.lista');
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }
              }]
          })
          .state('index.gestion.editar', {
            url: '/:gestionId/edit',
            templateUrl: 'app/gestion/plan.add.tpl.html',
            resolve: {
              dataGestion: ['gestionService', '$stateParams',
                function (gestionService, $stateParams) {
                  return gestionService.get($stateParams.gestionId);
                }],
              dataRegente: ['catalogosService', '$stateParams', 'dataGestion',
                function (catalogosService, $stateParams, dataGestion) {
                  var data = dataGestion.data[0];
                  return catalogosService.getListaElaboradorPorArea(data.area);
                }],
              dataTipoGestion: ['tipogestionService', '$stateParams',
                function (tipogestionService, $stateParams) {
                  return tipogestionService.getPorModulo(1);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataGestion', 'dataRegente', 'catalogosService', 'dataTipoGestion',
              function ($scope, $state, toastr, gestionService, dataGestion, dataRegente, catalogosService, dataTipoGestion) {
                $scope.data = dataGestion.data[0];
                $scope.areaOriginal = $scope.data.area;
                $scope.mostrarProfesional = true;
                $scope.crearRegistro = true;
                $scope.dataTipoGestion = dataTipoGestion.data;
                if ($scope.data.tcPersonaCrea.personaId != $scope.loginData.personaId) {
                  toastr.error('La gestión solo puede ser editada por el solictante', 'Acceso no autorizado');
                  $state.go('index.forbidden.show');
                  return;
                }
                $scope.dataRegente = dataRegente.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    if ($scope.data.area == null) {
                      toastr.error('Por favor indique el área para aprovechar');
                    } else {
                      if (parseFloat($scope.data.area) <= 0) {
                        toastr.error('Por favor indique un número válido');
                      } else {
                        if ($scope.areaOriginal != $scope.data.area) {
                          $scope.crearRegistro = false;
                          catalogosService.getListaElaboradorPorArea($scope.data.area).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataRegente = res.data;
                              //aplicar filtros segun el area para lista de profesionales
                              $scope.mostrarProfesional = true;
                              $scope.crearRegistro = true;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    }
                    if (isValid && $scope.crearRegistro) {
                      gestionService.edit($scope.data).then(function (res) {
                        if (res.status == "OK") {
                          toastr.success(res.message);
                          $state.go('^.lista');
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }
                }

              }]
          })
          .state('index.gestion.editarplan', {
            url: '/:gestionId/editplan',
            templateUrl: 'app/gestion/plan.add.tpl.html',
            resolve: {
              dataGestion: ['gestionService', '$stateParams',
                function (gestionService, $stateParams) {
                  return gestionService.get($stateParams.gestionId);
                }],
              dataRegente: ['catalogosService', '$stateParams', 'dataGestion',
                function (catalogosService, $stateParams, dataGestion) {
                  var data = dataGestion.data[0];
                  return catalogosService.getListaElaboradorPorArea(data.area);
                }],
              dataTipoGestion: ['tipogestionService', '$stateParams',
                function (tipogestionService, $stateParams) {
                  return tipogestionService.getPorModulo(3);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataGestion', 'dataRegente', 'catalogosService', 'dataTipoGestion',
              function ($scope, $state, toastr, gestionService, dataGestion, dataRegente, catalogosService, dataTipoGestion) {
                $scope.data = dataGestion.data[0];
                $scope.areaOriginal = $scope.data.area;
                $scope.mostrarProfesional = true;
                $scope.crearRegistro = true;
                $scope.dataTipoGestion = dataTipoGestion.data;
                if ($scope.data.tcPersonaCrea.personaId != $scope.loginData.personaId) {
                  toastr.error('La gestión solo puede ser editada por el solictante', 'Acceso no autorizado');
                  $state.go('index.forbidden.show');
                  return;
                }
                $scope.dataRegente = dataRegente.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    if ($scope.data.area == null) {
                      toastr.error('Por favor indique el área para aprovechar');
                    } else {
                      if (parseFloat($scope.data.area) <= 0) {
                        toastr.error('Por favor indique un número válido');
                      } else {
                        if ($scope.areaOriginal != $scope.data.area) {
                          $scope.crearRegistro = false;
                          catalogosService.getListaElaboradorPorArea($scope.data.area).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataRegente = res.data;
                              //aplicar filtros segun el area para lista de profesionales
                              $scope.mostrarProfesional = true;
                              $scope.crearRegistro = true;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    }
                    if (isValid && $scope.crearRegistro) {
                      gestionService.edit($scope.data).then(function (res) {
                        if (res.status == "OK") {
                          toastr.success(res.message);
                          $state.go('^.lista');
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }
                }

              }]
          })
          .state('index.gestion.solicitud', {
            url: '/solicitud',
            templateUrl: 'app/gestion/plan.list.tpl.html',
            resolve: {
              dataGestion: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 90);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 1,
                    personaId: 0,
                    elaboradorId: loginData.personaId
                  }
                  return gestionService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataGestion', 'uiGridConstants', '$filter', 'gestionService', 'toastr',
              function ($scope, $state, dataGestion, uiGridConstants, $filter, gestionService, toastr) {

                $scope.titulo = "Solicitudes (" + dataGestion.data.length + ")";
                $scope.subtitulo = "Lista de solicitudes para aceptar o rechazar";
                $scope.mostrarAgregar = true;
                $scope.mostrarEstado = false;

                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1
                }

                var dataEstado = $scope.dataEstado;
                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'nug', name: 'nug', displayName: 'NUG', width: '10%' },
                  { field: 'tcPersonaCrea.personaDesc', name: 'tcPersonaCrea', displayName: 'Solicitante' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Tipo' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.aceptarSolicitud(row.entity)" title="Aceptar solicitud"> <i class="zmdi zmdi-assignment-check"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.rechazarSolicitud(row.entity)" title="Rechazar solicitud"> <i class="zmdi zmdi-close"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataGestion.data;

                $scope.editRow = function (row) {
                  $state.go('^.editar', { gestionId: row.gestionId });
                }

                $scope.etiquetaEstado = function (estadoId) {
                  var estadoDesc = "Desconocido";
                  for (var i = 0; i < dataEstado.length; i++) {
                    if (dataEstado[i].estadoId == estadoId) {
                      estadoDesc = dataEstado[i].estadoDesc;
                      break;
                    }
                  }
                  return estadoDesc;
                }

                $scope.aceptarSolicitud = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de aceptar la solicitud de: " + item.tcPersonaCrea.personaDesc + " (" + item.tcTipoGestion.tipoGestionDesc + "\ncon un área de: " + item.area + " ha.?",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        item.personaAnulaId = $scope.loginData.personaId;
                        gestionService.aceptarSolicitud(item).then(function (res) {
                          if (res.status == "OK") {
                            toastr.success(res.message);
                            $scope.submitForm(true);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.rechazarSolicitud = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de rechazar la solicitud de: " + item.tcPersonaCrea.personaDesc + " (" + item.tcTipoGestion.tipoGestionDesc + ") con un área de: " + item.area + " ha.?",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Observaciones"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario indicar la razón del rechazo');
                        return false
                      }
                      item.personaAnulaId = $scope.loginData.personaId;
                      item.observaciones = inputValue;
                      gestionService.rechazarSolicitud(item).then(function (res) {
                        if (res.status == "OK") {
                          toastr.success(res.message);
                          $scope.submitForm(true);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId,
                      personaId: 0,
                      elaboradorId: $scope.loginData.personaId
                    }
                    gestionService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                        $scope.titulo = "Solicitudes (" + res.data.length + ")";
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }//submitForm

              }]
          })
          .state('index.gestion.aceptado', {
            url: '/aceptado',
            templateUrl: 'app/gestion/plan.list.tpl.html',
            resolve: {
              dataGestion: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 90);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 2,
                    personaId: 0,
                    elaboradorId: loginData.personaId
                  }
                  return gestionService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataGestion', 'uiGridConstants', '$filter', 'gestionService', 'toastr', 'ngDialog', '$base64',
              function ($scope, $state, dataGestion, uiGridConstants, $filter, gestionService, toastr, ngDialog, $base64) {

                $scope.titulo = "Solicitudes aceptadas (" + dataGestion.data.length + ")";
                $scope.subtitulo = "Lista de solicitudes aceptadas, sin iniciar la elaboración";
                $scope.mostrarAgregar = true;
                $scope.mostrarEstado = false;
                $scope.globales.fincaId = null;

                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2
                }

                var dataEstado = $scope.dataEstado;

                $scope.mostrarFinalizar = function (id) {
                  if (id == 10) {
                    return true;
                  } else {
                    return false;
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'nug', name: 'nug', displayName: 'NUG', width: '10%' },
                  { field: 'tcPersonaCrea.personaDesc', name: 'tcPersonaCrea', displayName: 'Solicitante' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Tipo' },
                  { field: 'fechaAceptacion', name: 'fechaAceptacion', displayName: 'Fecha aceptación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.iniciarSolicitud(row.entity)" title="Iniciar elaboración plan"> <i class="zmdi zmdi-edit"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.anularSolicitud(row.entity)" title="Anular la solicitud"> <i class="zmdi zmdi-close"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarFinalizar(row.entity.ultimoPanel)" ng-click="grid.appScope.finalizarElaboracion(row.entity)" title="Notificar al solicitante que su plan ha sido elaborado y proceder con la revisión o envío al INAB"> <i class="fa fa-check-square-o" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.modificarArea(row.entity)" title="Modificar área a intervenir"> <i class="fa fa-circle-o"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataGestion.data;

                $scope.editRow = function (row) {
                  $state.go('^.editar', { gestionId: row.gestionId });
                }

                $scope.imprimirPlan = function (row) {
                  if (row.estadoId > 1) {
                    $scope.imprimirDocumento(row.gestionId);
                  } else {
                    if (row.estadoId < 1) {
                      toastr.error('No es posible imprimir planes anulados');
                    } else {
                      toastr.error('No se ha realizado el plan de manejo, no es posible continuar');
                    }
                  }
                }

                $scope.etiquetaEstado = function (estadoId) {
                  var estadoDesc = "Desconocido";
                  for (var i = 0; i < dataEstado.length; i++) {
                    if (dataEstado[i].estadoId == estadoId) {
                      estadoDesc = dataEstado[i].estadoDesc;
                      break;
                    }
                  }
                  return estadoDesc;
                }

                $scope.iniciarSolicitud = function (item) {
                  if (item.tcPlanTipoGestion == null || item.tcPlanTipoGestion.planTipoGestionId == null) {
                    $scope.item = item;
                    ngDialog.open({
                      template: 'app/gestion/seleccionar.plan.tpl.html',
                      className: 'ngdialog-theme-plain',
                      appendClassName: 'ngdialog-custom',
                      closeByDocument: false,
                      closeByEscape: true,
                      closeByNavigation: true,
                      scope: $scope,
                      id: 'uniqueEdit',
                      name: 'dialogEditar',
                      resolve: {
                        dataPlanTipoGestion: ['plantipogestionService',
                          function (plantipogestionService) {
                            return plantipogestionService.getListaPlan(item.tcTipoGestion.tipoGestionId, item.area);
                          }],
                        dataTipoBosque: ['tipobosqueService',
                          function (tipobosqueService) {
                            return tipobosqueService.listaActiva();
                          }]
                      },
                      controller: ['$scope', '$state', 'toastr', 'dataPlanTipoGestion', 'gestionService', 'dataTipoBosque',
                        function ($scope, $state, toastr, dataPlanTipoGestion, gestionService, dataTipoBosque) {

                          $scope.dataPlanTipoGestion = dataPlanTipoGestion.data;
                          $scope.dataTipoBosqueCompleto = dataTipoBosque.data;
                          $scope.dataTipoBosque = dataTipoBosque.data;
                          $scope.item.tcPlanTipoGestion = {};
                          $scope.item.tcTipoBosque = {};

                          $scope.setPlanTipoGestion = function (item) {
                            $scope.item.tcPlanTipoGestion.planTipoGestionDesc = item.planTipoGestionDesc;
                            $scope.item.tcTipoBosque.tipoBosqueId = null;
                            var data = [];
                            if ($scope.item.tcTipoGestion.tipoGestionId != 1) {
                              data = $scope.dataTipoBosqueCompleto;
                              $scope.dataTipoBosque = data;
                            } else {
                              dataTipoBosque = $scope.dataTipoBosqueCompleto;
                              for (var i = 0; i < dataTipoBosque.length; i++) {
                                if (dataTipoBosque[i].ocultar != 1) {
                                  data.push(dataTipoBosque[i]);
                                }
                              }
                              $scope.dataTipoBosque = data;
                            }
                          }

                          $scope.setTipoBosque = function (item) {
                            $scope.item.tcTipoBosque.tipoBosqueDesc = item.tipoBosqueDesc;
                          }

                          $scope.submitFormPlan = function (isValid) {
                            if (isValid) {
                              var item = angular.copy($scope.item);
                              gestionService.agregarPlanSolicitud(item).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);

                                  $state.go('index.plan.realizar', { gestionId: $base64.encode(item.gestionId) });
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                              ngDialog.close();
                            }
                          }

                          $scope.cerrarVentana = function () {
                            ngDialog.close();
                          }

                        }]
                    })
                  } else {
                    $state.go('index.plan.realizar', { gestionId: $base64.encode(item.gestionId) });
                  }
                }

                $scope.anularSolicitud = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de anular la solicitud: " + item.tcTipoGestion.tipoGestionDesc + " con un área de: " + item.area + " ha.?",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Observaciones"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario indicar la razón del rechazo');
                        return false;
                      }
                      item.personaAnulaId = $scope.loginData.personaId;
                      item.observaciones = inputValue;
                      gestionService.anularSolicitud(item).then(function (res) {
                        if (res.status == "OK") {
                          toastr.success(res.message);
                          $scope.submitForm(true);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

                $scope.finalizarElaboracion = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro (a) de finalizar la elaboración de la solicitud: " + item.tipoGestionDesc + "\ncon un área de: " + item.area + " ha? \nSe generará una notificación al solicitante indicando que ya puede ser enviado al INAB \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var data = {
                          gestionId: item.gestionId,
                          personaCreaId: $scope.loginData.personaId,
                          usuarioModificaId: $scope.loginData.usuarioId,
                          area: item.area
                        }

                        gestionService.finalizarElaboracion(data).then(function (res) {
                          if (res.status == "OK") {
                            toastr.success(res.message);
                            $scope.submitForm(true);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId,
                      personaId: 0,
                      elaboradorId: $scope.loginData.personaId
                    }
                    gestionService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                        $scope.titulo = "Solicitudes (" + res.data.length + ")";
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }//submitForm

                $scope.modificarArea = function (item) {
                  if (item.estadoId == 2 || item.estadoId == 3 || item.estadoId == 4 || item.estadoId == 6) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro de cambiar el área de la solicitud de " + item.tcTipoGestion.tipoGestionDesc + "? El área actual es: " + item.area + " ha.\nIngrese el nuevo área",
                      type: "input",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Cambiar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true,
                      inputPlaceholder: "Área nueva"
                    },
                      function (inputValue) {
                        if (inputValue === false) return false;
                        if (inputValue === "") {
                          toastr.error('Es necesario indicar la nueva área');
                          return false;
                        }
                        if (!isNaN(parseFloat(inputValue.trim()))) {
                          item.personaAnulaId = $scope.loginData.personaId;
                          var area = item.area;
                          item.area = inputValue;
                          gestionService.actualizarArea(item).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success(res.message);
                              $scope.submitForm(true);
                            } else {
                              item.area = area;
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            item.area = area;
                            toastr.error(error);
                          });
                        } else {
                          toastr.error('Debe ser un número válido');
                        }
                      });
                  }
                }

              }]
          })
          .state('index.gestion.realizar', {
            url: '/:gestionId/realizar',
            templateUrl: 'app/gestion/plan.realizar.tpl.html',
            resolve: {
              dataGestion: ['gestionService', '$stateParams',
                function (gestionService, $stateParams) {
                  return gestionService.get($stateParams.gestionId);
                }],
              dataTipoPropietario: ['tipopropietarioService', '$stateParams',
                function (tipopropietarioService, $stateParams) {
                  return tipopropietarioService.listaActiva();
                }],
              dataTipoEntidad: ['tipoentidadService', '$stateParams',
                function (tipoentidadService, $stateParams) {
                  return tipoentidadService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataGestion', 'dataTipoPropietario', 'dataTipoEntidad',
              function ($scope, $state, toastr, gestionService, dataGestion, dataTipoPropietario, dataTipoEntidad) {
                $scope.panel = {
                  entidad: false,
                  solicitante: true,
                  notificacion: true,
                  finca: true,
                  openPropietario: true,
                  openSolicitante: false,
                  openNotificacion: false,
                  openFinca: false,
                  requeridoPropietario: false,
                  classGrupal: 'btn-default btn-sm glyphicon glyphicon-unchecked',
                  esGrupal: 0
                }
                $scope.dataTipoPropietario = dataTipoPropietario.data;
                $scope.dataTipoEntidad = dataTipoEntidad.data;
                $scope.data = dataGestion.data;
                $scope.data.usuarioModificaId = $scope.loginData.usuarioId;
                $scope.mostrarProfesional = true;

                /*panel propietario*/
                $scope.setTipoPropietario = function (item) {
                  $scope.panel.openSolicitante = false;
                  if (item.tipoPropietarioId == 2) {
                    $scope.panel.requeridoPropietario = true;
                  } else {
                    $scope.panel.requeridoPropietario = false;
                  }
                }

                $scope.submitFormEntidad = function (isValid) {
                  if (isValid) {
                    $scope.panel.solicitante = true;
                    $scope.panel.openSolicitante = true;
                  }
                }
                /*fin panel propietario*/

                /*panel solicitante*/
                $scope.setGrupal = function () {
                  if ($scope.panel.esGrupal == 0) {
                    $scope.panel.esGrupal = 1;
                    $scope.panel.classGrupal = 'btn-danger btn-sm glyphicon glyphicon-check';
                  } else {
                    $scope.panel.esGrupal = 0;
                    $scope.panel.classGrupal = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                  }
                }
                /*fin panel solicitante*/

              }]
          })
          .state('index.gestion.elaborado', {
            url: '/elaborado',
            templateUrl: 'app/gestion/plan.list.tpl.html',
            resolve: {
              dataGestion: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 90);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 3,
                    personaId: 0,
                    elaboradorId: loginData.personaId
                  }
                  return gestionService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataGestion', 'uiGridConstants', '$filter', 'gestionService', 'toastr', 'ngDialog', '$base64',
              function ($scope, $state, dataGestion, uiGridConstants, $filter, gestionService, toastr, ngDialog, $base64) {

                $scope.titulo = "Solicitudes elaboradas (" + dataGestion.data.length + ")";
                $scope.subtitulo = "Lista de solicitudes con planes elaborados";
                $scope.mostrarAgregar = false;
                $scope.mostrarEstado = false;

                var now = new Date();
                var begin = $scope.addDays(now, -90);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 3
                }

                $scope.mostrarEditar = function (estadoId) {
                  if ((estadoId > 1 && estadoId < 5) || estadoId == 6) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEnmienda = function (estadoId) {
                  if (estadoId == 6) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAvance = function (estadoId) {
                  if (estadoId == 5) {
                    return true;
                  }
                  return false;
                }

                var dataEstado = $scope.dataEstado;

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'nug', name: 'nug', displayName: 'NUG', width: '10%' },
                  { field: 'tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '13%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'expediente', name: 'expediente', displayName: 'Expediente', width: '13%' },
                  { field: 'tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Tipo' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"> <i class="zmdi zmdi-edit"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="row.entity.estadoId > 4 && row.entity.estadoId < 8" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.generarBoleta(row.entity)" title="Descargar boleta de inventario"> <i class="fa fa-database"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarAvance(row.entity.estadoId)" ng-click="grid.appScope.getAvance(row.entity)" title="Clic para ver el avance"> <i class="fa fa-list" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarEnmienda(row.entity.estadoId)" ng-click="grid.appScope.verEnmienda(row.entity)" title="Enmienda"> <i class="fa fa-medkit" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.modificarArea(row.entity)" title="Modificar área a intervenir"> <i class="fa fa-circle-o"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataGestion.data;

                $scope.imprimirPlan = function (row) {
                  if (row.estadoId > 1) {
                    $scope.imprimirDocumento(row.gestionId);
                  } else {
                    if (row.estadoId < 1) {
                      toastr.error('No es posible imprimir planes anulados');
                    } else {
                      toastr.error('No se ha realizado el plan de manejo, no es posible continuar');
                    }
                  }
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                      function ($scope, $state, toastr, dataAnexo, gestionService) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                      }]
                  });
                }

                $scope.generarBoleta = function (row) {
                  gestionService.getGestionData(row.gestionId).then(function (res) { //obtener datos originales del inventario
                    if (res.status == "OK") {
                      var data = res.data[0];
                      try {
                        var data = JSON.parse(data.data);
                        var createXLSLFormatObj = [];
                        /* XLS Head Columns */
                        var xlsHeader = ["TURNO", "RODAL", "AREA_RODAL", "PARCELA", "TAMANIO_PARCELA", "NO", "DAP", "ALTURA", "NOMBRE_CIENTIFICO", "VOLUMEN", "AREA_BASAL", "CALIDAD_FUSTE", "X", "Y"];
                        /* XLS Rows Data */
                        var xlsRows = [];
                        for (var i = 0; i < data.length; i++) {
                          var item = {
                            TURNO: data[i].TURNO,
                            RODAL: data[i].RODAL,
                            AREA_RODAL: data[i].AREA_RODAL,
                            PARCELA: data[i].PARCELA,
                            TAMANIO_PARCELA: data[i].TAMANIO_PARCELA,
                            NO: data[i].NO,
                            DAP: data[i].DAP,
                            ALTURA: data[i].ALTURA,
                            NOMBRE_CIENTIFICO: data[i].NOMBRE_CIENTIFICO,
                            VOLUMEN: data[i].VOLUMEN,
                            AREA_BASAL: data[i].AREA_BASAL,
                            CALIDAD_FUSTE: data[i].CALIDAD_FUSTE,
                            X: data[i].X,
                            Y: data[i].Y
                          }
                          xlsRows.push(item);
                        }
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(xlsRows, function (index, value) {
                          var innerRowData = [];
                          $("tbody").append('<tr><td>' + value.TURNO + '</td><td>' + value.RODAL + '</td><td>' + value.AREA_RODAL + '</td><td>' + value.PARCELA + '</td><td>' + value.TAMANIO_PARCELA + '</td><td>' + value.NO + '</td><td>' + value.DAP + '</td><td>' + value.ALTURA + '</td><td>' + value.NOMBRE_CIENTIFICO + '</td><td>' + value.VOLUMEN + '</td><td>' + value.AREA_BASAL + '</td><td>' + value.CALIDAD_FUSTE + '</td><td>' + value.X + '</td><td>' + value.Y + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });


                        /* File Name */
                        var filename = "boleta.xlsx";

                        /* Sheet Name */
                        var ws_name = "data";

                        if (typeof console !== 'undefined') console.log(new Date());
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

                        /* Add worksheet to workbook */
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);

                        /* Write workbook and Download */
                        if (typeof console !== 'undefined') console.log(new Date());
                        XLSX.writeFile(wb, filename);
                        if (typeof console !== 'undefined') console.log(new Date());

                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.modificarArea = function (item) {
                  if (item.estadoId == 2 || item.estadoId == 3 || item.estadoId == 4 || item.estadoId == 6) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro de cambiar el área de la solicitud de " + item.tcTipoGestion.tipoGestionDesc + "? El área actual es: " + item.area + " ha.\nIngrese el nuevo área",
                      type: "input",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Cambiar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true,
                      inputPlaceholder: "Área nueva"
                    },
                      function (inputValue) {
                        if (inputValue === false) return false;
                        if (inputValue === "") {
                          toastr.error('Es necesario indicar la nueva área');
                          return false;
                        }
                        if (!isNaN(parseFloat(inputValue.trim()))) {
                          item.personaAnulaId = $scope.loginData.personaId;
                          var area = angular.copy(item.area);
                          item.area = inputValue;
                          gestionService.actualizarArea(item).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success(res.message);
                              $scope.submitForm(true);
                            } else {
                              item.area = area;
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            item.area = area;
                            toastr.error(error);
                          });
                        } else {
                          toastr.error('Debe ser un número válido');
                        }
                      });
                  }
                }

                $scope.mostrarFiltroExpediente = true;
                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.expediente = null;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    gestionService.buscarPorExpediente($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.getAvance = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/gestion/gestion.avance.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataTask: ['gestionService',
                        function (gestionService) {
                          return gestionService.getAvanceGestion(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataTask', 'tareaService', '$timeout', '$filter',
                      function ($scope, $state, toastr, dataTask, tareaService, $timeout, $filter) {
                        $scope.pasos = dataTask.data;
                        for (var i = 0; i < $scope.pasos.length; i++) {
                          var row = $scope.pasos[i];
                          if (row.estadoId == 2) {
                            row.fechaDescIni = 'Asignado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                            row.fechaDescFin = 'Finalizado';
                            row.color = 'success';
                            row.icono = 'glyphicon glyphicon-check';
                          } else {
                            row.fechaDescIni = 'Asignado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                            row.fechaDescFin = 'No se ha finalizado';
                            row.color = 'warning bounce-in';
                            row.icono = 'glyphicon glyphicon-time';
                          }
                          if (row.tcTask.permiteRecibir == 1) {
                            if (row.recibido == 0) {
                              if (row.tcTask.taskId == 3 || row.tcTask.taskId == 6) {
                                row.fechaDescIni = 'Enviado a la región desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                              } else {
                                row.fechaDescIni = 'Enviado a la subregión desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                              }
                            } else {
                              if (row.estadoId == 2) {
                                row.fechaDescIni = 'Enviado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                                row.fechaDescFin = 'Finalizado';
                                if (row.esEnmienda == 1) {
                                  row.fechaDescFin += ' (Enmienda)';
                                }
                              } else {
                                row.fechaDescIni = 'Enviado desde: ' + $filter('date')(new Date(row.fechaRegistro), 'dd/MM/yyyy');
                                row.fechaDescFin = 'No se ha finalizado';
                              }
                            }
                          }
                        }

                        $scope.enviarCorreo = function (item) {
                          $scope.paso = item;
                          ngDialog.open({
                            template: 'app/gestion/gestion.correo.tpl.html',
                            className: 'ngdialog-theme-default',
                            appendClassName: 'ngdialog-default',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueEdit',
                            name: 'dialogEdit',
                            resolve: {},
                            controller: ['$scope', '$state', 'toastr', 'tareaService', '$timeout', '$filter',
                              function ($scope, $state, toastr, tareaService, $timeout, $filter) {
                                $scope.mail = {};
                              }
                            ]
                          });
                        }

                      }]
                  });
                }

                $scope.etiquetaEstado = function (estadoId) {
                  var estadoDesc = "Desconocido";
                  for (var i = 0; i < dataEstado.length; i++) {
                    if (dataEstado[i].estadoId == estadoId) {
                      estadoDesc = dataEstado[i].estadoDesc;
                      break;
                    }
                  }
                  return estadoDesc;
                }

                //elaborador
                $scope.verEnmienda = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/gestion/historial.enmienda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['gestionService',
                        function (gestionService) {
                          return gestionService.getEnmiendaHistorial(item.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'tareaService', '$timeout', 'utilsService',
                      function ($scope, $state, toastr, dataHistorial, tareaService, $timeout, utilsService) {
                        $scope.dataHistorial = dataHistorial.data;
                        $scope.ttEnmieda = null;
                        $scope.getEstadoEnmienda = function (estadoId) {
                          if (estadoId == 1) {
                            return 'Activo';
                          }
                          if (estadoId == 2) {
                            return 'Realizado';
                          }
                          return 'Inactivo';
                        }

                        $scope.agregarObservacionesEnmienda = function (numero, item) {
                          item.mostrarObservacionesEnmienda = true;
                        }

                        $scope.guardarObservacionesEnmieda = function (item) {
                          gestionService.agregarObservacionesEnmienda(item).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success(res.message);
                              item.mostrarObservacionesEnmienda = false;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cancelarObservacionesEnmieda = function (item) {
                          item.mostrarObservacionesEnmienda = false;
                        }

                        $scope.verDocumentoEnmienda = function (item) {
                          if (item.estadoId != 0) {
                            tareaService.get(item.tareaId).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                if (data.tcTask.taskId == 5) {
                                  utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error('La enmienda esta anulada');
                          }
                        }

                        $scope.verOficioEntregaEnmienda = function (item) {
                          if (item.estadoId != 0) {
                            tareaService.getOficioEnmienda(item.enmiendaId).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                var ttTarea = data.ttTarea;
                                ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                                if (ttTarea.tcTask.taskId == 5) {
                                  utilsService.generarOficioEntregaEnmiendaSubregional(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarOficioEntregaEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error('La enmienda esta anulada');
                          }
                        }
                      }]
                  });
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId,
                      personaId: 0,
                      elaboradorId: $scope.loginData.personaId
                    }
                    gestionService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                        $scope.titulo = "Solicitudes (" + res.data.length + ")";
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }//submitForm

                $scope.editRow = function (item) {
                  $state.go('index.plan.realizar', { gestionId: $base64.encode(item.gestionId) });
                }

              }]
          })
          .state('index.gestion.buscar', {
            url: '/buscar',
            templateUrl: 'app/gestion/gestion.buscar.tpl.html',
            resolve: {

            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'utilsService', '$timeout',
              function ($scope, $state, toastr, gestionService, utilsService, $timeout) {

                $scope.globales.ttInformeGestion = null;
                $scope.mostrarAgregar = false;
                $scope.esFinal = false;
                $scope.filtro = {
                  licencia: null,
                  personaId: $scope.loginData.personaId
                };

                $scope.data = {};


                $scope.nombreTrimestre = function (trimestre) {
                  switch (trimestre) {
                    case 1: return 'Primer trimestre';
                    case 2: return 'Segundo trimestre';
                    case 3: return 'Tercer trimestre';
                    case 4: return 'Cuarto trimestre';
                  }
                }

                $scope.etiquetaEstado = function (estadoId) {
                  switch (estadoId) {
                    case 0: return 'Anulado';
                    case 1: return 'Activo';
                    case 2: return 'Finalizado';
                  }
                }

                $scope.etiquetaEstadoTramite = function (estadoTramiteId) {
                  switch (estadoTramiteId) {
                    case 0: return 'Aún no se presentado al INAB';
                    case 1: return 'Enviado para su recepción';
                    case 2: return 'En trámite';
                    case 3: return 'Finalizado';
                  }
                }

                $scope.mostrarEditar = function (estadoTramiteId) {
                  if (estadoTramiteId <= 1) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = false;
                $scope.gridOptions.columnDefs = [
                  { field: 'turno', name: 'turno', displayName: 'Turno', width: '10%' },
                  {
                    name: 'trimestre', displayName: "Trimestre", field: 'trimestre', enableFiltering: false, width: '20%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.nombreTrimestre(row.entity.trimestre)}}</div>'
                  },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha registro', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'estadoId', displayName: "Estado elaboración", field: 'estadoId', enableFiltering: false, width: '20%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  {
                    name: 'estadoTramiteId', displayName: "Estado del trámite", field: 'estadoTramiteId', enableFiltering: false, width: '20%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstadoTramite(row.entity.estadoTramiteId)}}</div>'
                  },
                  { field: 'fechaElaboracion', name: 'fechaElaboracion', displayName: 'Fecha elaboración', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoTramiteId)" ng-click="grid.appScope.enviarInforme(row.entity)" title="Clic para enviar informe"> <i class="fa fa-send" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoTramiteId)" ng-click="grid.appScope.editarInforme(row.entity)" title="Clic para editar el informe"> <i class="fa fa-pencil" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirInforme(row.entity)" title="Clic para imprimir el informe"> <i class="fa fa-file-o" aria-hidden="true"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = [];

                $scope.dataTrimestre = [];

                $scope.dataTurno = [];

                $scope.enviarInforme = function (ttInformeGestion) {
                  if (ttInformeGestion.estadoTramiteId <= 0) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de enviar el informe al INAB? \nEsta acción no se podrá reversar",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          var item = angular.copy(ttInformeGestion);
                          delete item.ttGestion;
                          item.tcPersonaCrea.personaId = $scope.loginData.personaId;
                          gestionService.enviarInforme(item).then(function (res) {
                            if (res.status == "OK") {
                              ttInformeGestion.estadoId = 2;
                              ttInformeGestion.fechaElaboracion = new Date();
                              ttInformeGestion.estadoTramiteId = 1;
                              toastr.success(res.message);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  }
                }

                $scope.submitForm = function (isValid) {
                  $scope.mostrarDatos = false;
                  $scope.mostrarAgregar = false;
                  if (isValid) {
                    gestionService.buscarGestionParaInforme($scope.filtro).then(function (res) {
                      if (res.status == "OK") {
                        if (res.data.length > 0) {
                          var ttGestion = res.data[0];
                          $scope.gridOptions.data = ttGestion.informes;
                          $scope.ttGestion = ttGestion;
                          var turnos = ttGestion.ttResumenGestion.noTurnos;
                          var informes = ttGestion.informes;
                          var ultimoTurno = 1;
                          var i = 0;
                          if (informes == null) {
                            informes = [];
                          }
                          for (i = 0; i < informes.length; i++) {
                            if (informes[i].turno > ultimoTurno) {
                              ultimoTurno = informes[i].turno;
                            }
                          }
                          var ultimoTrimestre = 1;
                          var incluir = true;
                          for (i = 0; i < informes.length; i++) {
                            if (informes[i].turno == ultimoTurno) {
                              if (informes[i].trimestre > ultimoTrimestre) {
                                ultimoTrimestre = informes[i].trimestre;
                              }
                              if (informes[i].trimestre == ultimoTrimestre) {
                                incluir = false;
                              }
                            }
                          }
                          if (!incluir) {
                            ultimoTrimestre++;
                          }
                          if (ultimoTrimestre > 4) {
                            ultimoTurno++;
                            if (turnos > ultimoTurno) {
                              ultimoTrimestre = 1;
                            }
                          }
                          var dataTurno = [];
                          for (i = ultimoTurno; i <= turnos; i++) {
                            dataTurno.push({ turnoId: i, turnoDesc: i });
                          }
                          var dataTrimestre = [];
                          for (i = ultimoTrimestre; i <= 4; i++) {
                            trimestreDesc = '';
                            switch (i) {
                              case 1:
                                trimestreDesc = 'Primero';
                                break;
                              case 2:
                                trimestreDesc = 'Segundo';
                                break;
                              case 3:
                                trimestreDesc = 'Tercero';
                                break;
                              case 4:
                                trimestreDesc = 'Cuarto';
                                break;
                            }
                            dataTrimestre.push({ trimestreId: i, trimestreDesc: trimestreDesc });
                          }

                          $scope.dataTurno = dataTurno;
                          $scope.dataTrimestre = dataTrimestre;

                          $scope.mostrarDatos = true;
                        } else {
                          $scope.gridOptions.data = [];
                        }
                        toastr.success(res.message);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.editarInforme = function (item) {
                  if (item.estadoId == 2) {
                    swal({
                      title: "¿Está seguro (a) de editar el informe?",
                      text: "Ya se ha marcado como disponible para que llegue a la subregión y presentar el físico para el inicio del trámite",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          gestionService.obtenerDatosInforme(item.informeGestionId).then(function (res) {
                            if (res.status == "OK") {
                              item = res.data[0];
                              item.ttGestion = $scope.ttGestion;
                              item.licencia = $scope.filtro.licencia;
                              $scope.globales.ttInformeGestion = item;
                              $state.go('^.informe');
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    gestionService.obtenerDatosInforme(item.informeGestionId).then(function (res) {
                      if (res.status == "OK") {
                        item = res.data[0];
                        item.ttGestion = $scope.ttGestion;
                        item.licencia = $scope.filtro.licencia;
                        $scope.globales.ttInformeGestion = item;
                        $state.go('^.informe');
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.imprimirInforme = function (item) {
                  gestionService.obtenerDatosInforme(item.informeGestionId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      if (data.ttDescripcionInforme != null) {
                        if (data.ttDescripcionInforme.conclusiones != null) {
                          data.ttDescripcionInforme.conclusiones = JSON.parse(data.ttDescripcionInforme.conclusiones);
                          utilsService.generarInformeTrimestral(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          $timeout(function () {
                            utilsService.generarInformeNotasLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 1000);
                          $timeout(function () {
                            utilsService.generarInformeNotaAnuladaLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 2000);
                          $timeout(function () {
                            utilsService.generarInformeNotaExtraviadaLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 3000);
                        } else {
                          toastr.error('El informe no está completo, favor de verificar');
                        }
                      } else {
                        toastr.error('El informe no está completo, favor de verificar');
                      }
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.agregarInforme = function () {
                  $scope.mostrarAgregar = true;
                }

                $scope.setInformeFinal = function () {
                  if ($scope.esFinal) {
                    $scope.data.esFinal = 1;
                  } else {
                    $scope.data.esFinal = 0;
                  }
                }

                $scope.submitFormNuevo = function (isValid) {
                  if (isValid) {
                    var gestion = $scope.ttGestion;
                    ttGestion = {
                      gestionId: gestion.gestionId,
                      tcTipoGestion: gestion.tcTipoGestion,
                      estadoId: gestion.estadoId,
                      tcPersonaCrea: gestion.tcPersonaCrea,
                      tcTipoBosque: gestion.tcTipoBosque,
                      tcElaborador: gestion.tcElaborador,
                      ttTipoPropietarioGestion: gestion.ttTipoPropietarioGestion
                    };
                    var data = $scope.data;
                    var ttPoaGestion = {
                      ttGestion: gestion
                    }
                    var area = 0;
                    for (var i = 0; i < gestion.rodal.length; i++) {
                      if (parseInt(gestion.rodal[i].turno) == parseInt(data.turno)) {
                        area += parseFloat(gestion.rodal[i].area);
                      }
                    }
                    data.area = parseFloat(area).toFixed(2);
                    data.ttPoaGestion = ttPoaGestion;
                    if ($scope.ttGestion.ttResumenGestion.tcRegente != null) {
                      if ($scope.ttGestion.ttResumenGestion.tcRegente.personaId != $scope.loginData.personaId) {
                        toastr.error('Los informes solo pueden ser creados por el regente');
                        return;
                      }
                    } else {
                      if ($scope.ttGestion.tcPersonaCrea.personaId != $scope.loginData.personaId) {
                        toastr.error('Los informes solo pueden ser creados por el propietario o representante');
                        return;
                      }
                    }
                    data.tcPersonaCrea = {
                      personaId: $scope.loginData.personaId
                    }
                    gestionService.crearInforme(data).then(function (res) {
                      if (res.status == "OK") {
                        item = res.data[0];
                        item.ttGestion = $scope.ttGestion;
                        item.licencia = $scope.filtro.licencia;
                        item.especies = null;
                        $scope.globales.ttInformeGestion = item;
                        $state.go('^.informe');
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.gestion.informe', {
            url: '/informe',
            templateUrl: 'app/gestion/gestion.informe.tpl.html',
            resolve: {
              dataMunicipio: ['municipioService', '$stateParams',
                function (municipioService, $stateParams) {
                  return municipioService.getPorPais(1);
                }],
              dataProductoTransporte: ['productotransporteService', '$stateParams',
                function (productotransporteService, $stateParams) {
                  return productotransporteService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataMunicipio', 'dataProductoTransporte', 'authService', 'gestionService', 'utilsService', 'Upload', '$timeout',
              function ($scope, $state, toastr, dataMunicipio, dataProductoTransporte, authService, gestionService, utilsService, Upload, $timeout) {
                $scope.dataMunicipio = dataMunicipio.data;
                $scope.dataProductoTransporte = dataProductoTransporte.data;
                $scope.dataRendimiento = [
                  { factorRendimientoId: 0, factorRendimientoDesc: 'Usar los oficiales' },
                  { factorRendimientoId: 1, factorRendimientoDesc: 'Usar mi factor autorizado' }
                ];
                $scope.pagina = 1;
                $scope.totalPaginas = 1;

                $scope.dataTipoSierra = [
                  { tipoSierraId: 1, tipoSierraDesc: 'Banda' },
                  { tipoSierraId: 2, tipoSierraDesc: 'Circular' },
                  { tipoSierraId: 3, tipoSierraDesc: 'Motosierra' }
                ];

                $scope.dataSierra = [
                  { otraSierraId: 0, otraSierraDesc: 'Sin sierra secundaria' },
                  { otraSierraId: 1, otraSierraDesc: 'Con sierra secundaria' }
                ];

                $scope.nombreTrimestre = function (trimestre) {
                  switch (trimestre) {
                    case 1: return 'Primer trimestre';
                    case 2: return 'Segundo trimestre';
                    case 3: return 'Tercer trimestre';
                    case 4: return 'Cuarto trimestre';
                  }
                }

                $scope.regresarLista = function () {
                  $state.go('^.buscar');
                }

                $scope.tabVisible = {
                  cero: true,
                  uno: false,
                  dos: false,
                  tres: false
                }

                $scope.tabActive = {
                  cero: true,
                  uno: false,
                  dos: false,
                  tres: false
                }

                $scope.setPaginaMostar = function () {
                  var notas = $scope.ttInformeGestion.notas;
                  var totalPaginas = (notas.length / 10);
                  totalPaginas = Math.ceil(totalPaginas);
                  var notasMostrar = [];
                  if (totalPaginas >= $scope.pagina) {
                    var fin = $scope.pagina * 10;
                    var inicio = fin - 10;
                    if (fin > notas.length) {
                      fin = notas.length;
                    }
                    for (var i = inicio; i < fin; i++) {
                      if (notas[i].mostrarProducto == undefined) {
                        notas[i].mostrarProducto = false;
                        notas[i].mostrarMotivo = false;
                      }
                      if (notas[i].usoId == undefined) {
                        notas[i].usoId = 1;
                      }
                      notasMostrar.push(notas[i]);
                    }
                    $scope.textoMostrando = $scope.pagina + " de " + totalPaginas;
                    $scope.totalPaginas = totalPaginas;
                    $scope.notas = notasMostrar;
                  }
                }

                $scope.backpage = function () {
                  if ($scope.pagina > 1) {
                    $scope.pagina--;
                    $scope.setPaginaMostar();
                  }
                }

                $scope.nextpage = function () {
                  if ($scope.pagina < $scope.totalPaginas) {
                    $scope.pagina++;
                    var notas = $scope.notas;
                    for (var i = 0; i < notas.length; i++) {
                      if (notas[i].usoId == 1) {
                        if (notas[i].fechaNota == null || notas[i].tcMunicipio.municipioId == null || notas[i].destino == null || notas[i].especies.length <= 0) {
                          toastr.error('Para avanzar debe indicar el uso o los datos de cada nota');
                          return;
                        }
                      }
                    }
                    $scope.setPaginaMostar();
                  }
                }

                if ($scope.globales.ttInformeGestion == null) {
                  $state.go('^.buscar');
                } else {
                  $scope.ttInformeGestion = $scope.globales.ttInformeGestion;
                  if ($scope.ttInformeGestion.ttDescripcionInforme == undefined) {
                    $scope.ttInformeGestion.ttDescripcionInforme = {};
                  } else {
                    if ($scope.ttInformeGestion.ttDescripcionInforme.descripcionInformeId > 0) {
                      $scope.tabVisible.uno = true;
                      $scope.tabVisible.dos = true;
                      if ($scope.ttInformeGestion.ttDescripcionInforme.ordenamiento != null) {
                        $scope.tabActive.cuatro = true;
                        $scope.tabVisible.tres = true;
                        $scope.tabVisible.cuatro = true;
                      } else {
                        if ($scope.ttInformeGestion.tieneMovimiento == 1) {
                          $scope.tabActive.dos = true;
                        } else {
                          $scope.tabVisible.tres = true;
                          $scope.tabActive.tres = true;
                        }
                      }
                    }
                    if ($scope.ttInformeGestion.ttDescripcionInforme.conclusiones != null) {
                      $scope.ttInformeGestion.ttDescripcionInforme.conclusiones = JSON.parse($scope.ttInformeGestion.ttDescripcionInforme.conclusiones);
                    }
                  }
                  var rodal = $scope.ttInformeGestion.ttGestion.rodal;
                  var data = [];
                  for (var i = 0; i < rodal.length; i++) {
                    if (rodal[i].turno == $scope.ttInformeGestion.turno) {
                      data.push(rodal[i]);
                    }
                  }
                  if ($scope.ttInformeGestion.especies == null || $scope.ttInformeGestion.especies.length <= 0) {
                    var especies = [];
                    var catalogo = [];
                    var encontrado = false;
                    for (var i = 0; i < data.length; i++) {
                      row = data[i];
                      for (var j = 0; j < row.especies.length; j++) {
                        var item = {
                          tcEspecie: row.especies[j].tcEspecie,
                          saldoLenia: row.especies[j].lenia,
                          saldoTroza: row.especies[j].troza,
                          saldoVolumen: row.especies[j].volrExtraer,
                          volumen: 0,
                          troza: 0,
                          lenia: 0,
                          restoVolumen: 0,
                          restoTroza: 0,
                          restoLenia: 0
                        }
                        var tcEspecie = row.especies[j].tcEspecie;
                        encontrado = false;
                        for (var k = 0; k < catalogo.length; k++) {
                          if (catalogo[k].especieId == tcEspecie.especieId) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          catalogo.push(tcEspecie);
                        }
                        encontrado = false;
                        for (var k = 0; k < especies.length; k++) {
                          if (especies[k].tcEspecie.especieId == tcEspecie.especieId) {
                            especies[k].saldoTroza += parseFloat(row.especies[j].troza);
                            especies[k].saldoLenia += parseFloat(row.especies[j].lenia);
                            especies[k].saldoVolumen += parseFloat(row.especies[j].volrExtraer);
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          especies.push(item);
                        }
                      }
                    }
                    for (var i = 0; i < especies.length; i++) {
                      especies[i].saldoTroza = parseFloat(especies[i].saldoTroza).toFixed(2);
                      especies[i].saldoLenia = parseFloat(especies[i].saldoLenia).toFixed(2);
                      especies[i].saldoVolumen = parseFloat(especies[i].saldoVolumen).toFixed(2);
                    }
                    $scope.dataEspecie = catalogo;
                    $scope.ttInformeGestion.especies = especies;
                  } else {
                    var especies = $scope.ttInformeGestion.especies;
                    encontrado = false;
                    var catalogo = [];
                    for (var i = 0; i < especies.length; i++) {
                      var tcEspecie = especies[i].tcEspecie;
                      for (var k = 0; k < catalogo.length; k++) {
                        if (catalogo[k].especieId == tcEspecie.especieId) {
                          encontrado = true;
                          break;
                        }
                      }
                      if (!encontrado) {
                        catalogo.push(tcEspecie);
                      }
                    }
                    $scope.dataEspecie = catalogo;
                  }
                  var correlativoIni = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoIni);
                  var correlativoFin = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoFin);
                  var correlativoIni2 = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoIni2);
                  var correlativoFin2 = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoFin2);
                  var notas = [];
                  for (var i = correlativoIni; i <= correlativoFin; i++) {
                    var item = {
                      numero: i
                    }
                    notas.push(item);
                  }
                  if (correlativoIni != correlativoIni2) {
                    for (var i = correlativoIni2; i <= correlativoFin2; i++) {
                      var item = {
                        numero: i
                      }
                      notas.push(item);
                    }
                  }
                  if ($scope.ttInformeGestion.notas != null) {
                    var existe = $scope.ttInformeGestion.notas;
                    for (var i = 0; i < existe.length; i++) {
                      for (var j = 0; j < notas.length; j++) {
                        if (notas[j].numero == existe[i].numero) {
                          notas[j] = existe[i];
                          if (notas[j].fechaNota != null) {
                            var parts = notas[j].fechaNota.toString().split('-');
                            var mydate = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
                            notas[j].fechaNota = new Date(mydate);
                          }
                          if (existe[i].usoId == 1) {
                            existe[i].mostrarProducto = true;
                            existe[i].mostrarMotivo = false;
                          }
                          if (existe[i].usoId == 0) {
                            existe[i].motivoTitulo = "anulación";
                            existe[i].mostrarProducto = false;
                            existe[i].mostrarMotivo = true;
                          }
                          if (existe[i].usoId == 2) {
                            existe[i].motivoTitulo = "extravío";
                            existe[i].mostrarProducto = false;
                            existe[i].mostrarMotivo = true;
                          }
                        }
                      }
                    }
                  }
                  $scope.ttInformeGestion.notas = notas;
                  $scope.setPaginaMostar();

                }

                if ($scope.ttInformeGestion.tieneMovimiento == 1) {
                  $scope.tiene = true;
                } else {
                  $scope.tiene = false;
                }

                $scope.setTieneMovimiento = function () {
                  if ($scope.ttInformeGestion.tieneMovimiento == 1) {
                    $scope.ttInformeGestion.tieneMovimiento = 0;
                  } else {
                    $scope.ttInformeGestion.tieneMovimiento = 1;
                  }
                }

                $scope.setEspecieNota = function (item, nota, producto) {
                  producto.tcEspecie = item;
                }


                $scope.agregarProducto = function (nota) {
                  if (nota.especies == null) {
                    nota.especies = [];
                  }
                  nota.especies.push(
                    { volumenReportar: 0, volumen: 0 }
                  );
                  nota.usoId = 1;
                  nota.mostrarProducto = true;
                  nota.mostrarMotivo = false;
                }

                $scope.quitarProducto = function (nota, item) {
                  nota.especies.splice(nota.especies.indexOf(item), 1);
                  if (nota.especies.length <= 0) {
                    nota.mostrarProducto = false;
                    nota.mostrarMotivo = false;
                  }
                }

                $scope.setProductoTransportado = function (item, producto) {
                  producto.tcProductoTransporte = item;
                }

                $scope.setVolumenProducto = function (producto, nota) {
                  if (producto.tcProductoTransporte == null || producto.tcProductoTransporte.productoTransporteId == null) {
                    producto.volumen = null;
                    toastr.error('Debe indicar el producto a trasportar');
                    return;
                  }
                  producto.volumenReportar = producto.volumen;
                  var factor = 0;
                  if (producto.tcProductoTransporte.productoTransporteId == 2) {
                    if ($scope.ttInformeGestion.aplicaFactora == 1) {
                      factor = parseFloat($scope.ttInformeGestion.factorAserrada);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      if ($scope.ttInformeGestion.tipoSierraId == 1) {
                        if ($scope.ttInformeGestion.otraSierraId == 0) {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoConSierrab);
                        }
                      }
                      if ($scope.ttInformeGestion.tipoSierraId == 2) {
                        factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                      }
                      if ($scope.ttInformeGestion.tipoSierraId == 3) {
                        factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    }
                  }
                  if (producto.tcProductoTransporte.productoTransporteId == 4) {
                    if ($scope.ttInformeGestion.aplicaFactorc == 1) {
                      factor = parseFloat($scope.ttInformeGestion.factorCarbon);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    }
                  }
                }

                $scope.marcarAnulado = function (nota) {
                  nota.usoId = 0;
                  nota.motivoTitulo = "anulación";
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = true;
                  nota.especies = [];
                }

                $scope.marcarExtraviado = function (nota) {
                  nota.usoId = 2;
                  nota.motivoTitulo = "extravío";
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = true;
                  nota.especies = [];
                }

                $scope.marcarNoUsado = function (nota) {
                  nota.usoId = 3;
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = false;
                  nota.especies = [];
                }

                $scope.submitFormNotas = function (isValid) {
                  isValid = true;
                  var enviar = [];
                  for (var i = 0; i < $scope.ttInformeGestion.notas.length; i++) {
                    var nota = $scope.ttInformeGestion.notas[i];
                    if (nota.mostrarProducto != null) {
                      if (nota.mostrarProducto) {
                        if (nota.fechaNota == null || nota.destino == null) {
                          isValid = false;
                          break;
                        } else {
                          if (nota.tcMunicipio == null) {
                            isValid = false;
                            break;
                          } else {
                            if (nota.tcMunicipio.municipioId == null) {
                              isValid = false;
                              break;
                            }
                          }
                        }
                      }
                      if (nota.mostrarMotivo) {
                        if (nota.motivo == null) {
                          isValid = false;
                          break;
                        }
                        nota.especies = [];
                      }
                      if (nota.mostrarProducto || nota.mostrarMotivo) {
                        enviar.push(nota);
                      }
                    }
                  }
                  if (isValid) {
                    var data = angular.copy($scope.ttInformeGestion);
                    delete data.ttGestion;
                    delete data.ttPoaGestion;
                    delete data.especies;
                    delete data.tcPersonaCrea.foto;
                    if (data.ttDescripcionInforme != null) {
                      data.ttDescripcionInforme.conclusiones = JSON.stringify(data.ttDescripcionInforme.conclusiones);
                    }
                    data.notas = enviar;
                    gestionService.actualizarNotas(data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $scope.tabActive.uno = true;
                        $scope.tabVisible.uno = true;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    toastr.error('Asegúrese de rellenar los campos de las notas usadas');
                  }
                }

                $scope.setConclusiones = function () {
                  var notas = $scope.ttInformeGestion.notas;
                  var usados = 0, anulados = 0, extraviados = 0;
                  for (var i = 0; i < notas.length; i++) {
                    if (notas[i].mostrarMotivo != undefined && notas[i].mostrarProducto != undefined) {
                      if (notas[i].mostrarMotivo || notas[i].mostrarProducto) {
                        if (notas[i].usoId == 1) {
                          usados++;
                        }
                        if (notas[i].usoId == 0) {
                          anulados++;
                        }
                        if (notas[i].usoId == 2) {
                          extraviados++;
                        }
                      }
                    }
                  }
                  var total = $scope.total;
                  var conclusiones = null;
                  if ($scope.ttInformeGestion.ttDescripcionInforme.conclusiones != null) {
                    conclusiones = $scope.ttInformeGestion.ttDescripcionInforme.conclusiones;
                  }
                  $scope.ttInformeGestion.ttDescripcionInforme.conclusiones = [
                    { conclusion: 'El volumen de troza total transportado es de ' + total.volumen + ' m³', mostrarQuitar: false },
                    { conclusion: 'El volumen transportado de leña es de ' + total.lenia + ' m³', mostrarQuitar: false },
                    { conclusion: 'El saldo pendiente es de ' + total.restoTroza + ' m³ de troza y ' + total.restoLenia + ' m³ de leña que da un total de ' + total.restoVolumen + ' m³', mostrarQuitar: false },
                    { conclusion: 'Se llevan utilizadas ' + usados + ' notas de envío', mostrarQuitar: false }
                  ];
                  if (anulados > 0) {
                    $scope.ttInformeGestion.ttDescripcionInforme.conclusiones.push(
                      { conclusion: 'Se anularon ' + anulados + ' notas de envío', mostrarQuitar: false }
                    );
                  }
                  if (extraviados > 0) {
                    $scope.ttInformeGestion.ttDescripcionInforme.conclusiones.push(
                      { conclusion: 'Se extraviaron ' + extraviados + ' notas de envío', mostrarQuitar: false }
                    );
                  }

                  if (conclusiones != null) {
                    for (var i = 0; i < conclusiones.length; i++) {
                      var item = conclusiones[i];
                      if (item.mostrarQuitar) {
                        $scope.ttInformeGestion.ttDescripcionInforme.conclusiones.push(item);
                      }
                    }
                  }

                }

                $scope.onSelectGenerales = function () {
                  var especies = $scope.ttInformeGestion.especies;
                  var notas = $scope.ttInformeGestion.notas;
                  for (var i = 0; i < especies.length; i++) {
                    especies[i].lenia = 0;
                    especies[i].troza = 0;
                    especies[i].volumen = 0;
                    especies[i].volumen = 0;
                    especies[i].restoLenia = 0;
                    especies[i].restoTroza = 0;
                    especies[i].restoVolumen = 0;
                  }
                  for (var i = 0; i < especies.length; i++) {
                    for (var j = 0; j < notas.length; j++) {
                      if (notas[j].especies != null) {
                        for (var k = 0; k < notas[j].especies.length; k++) {
                          if (notas[j].especies[k].tcEspecie.especieId == especies[i].tcEspecie.especieId) {
                            if (notas[j].especies[k].tcProductoTransporte.productoTransporteId == 1
                              || notas[j].especies[k].tcProductoTransporte.productoTransporteId == 2) {
                              especies[i].troza = parseFloat(especies[i].troza) + parseFloat(notas[j].especies[k].volumenReportar);
                            } else {
                              if (notas[j].especies[k].tcProductoTransporte.productoTransporteId == 3
                                || notas[j].especies[k].tcProductoTransporte.productoTransporteId == 4) {
                                especies[i].lenia = parseFloat(especies[i].lenia) + parseFloat(notas[j].especies[k].volumenReportar);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  var total = {
                    saldoLenia: 0,
                    saldoTroza: 0,
                    saldoVolumen: 0,
                    lenia: 0,
                    troza: 0,
                    volumen: 0,
                    restoLenia: 0,
                    restoTroza: 0,
                    restoVolumen: 0
                  };
                  for (var i = 0; i < especies.length; i++) {
                    especies[i].lenia = parseFloat(especies[i].lenia).toFixed(2);
                    especies[i].troza = parseFloat(especies[i].troza).toFixed(2);
                    especies[i].volumen = parseFloat(especies[i].troza) + parseFloat(especies[i].lenia);
                    especies[i].volumen = parseFloat(especies[i].volumen).toFixed(2);
                    especies[i].restoLenia = parseFloat(especies[i].saldoLenia) - parseFloat(especies[i].lenia);
                    especies[i].restoLenia = parseFloat(especies[i].restoLenia).toFixed(2);
                    especies[i].restoTroza = parseFloat(especies[i].saldoTroza) - parseFloat(especies[i].troza);
                    especies[i].restoTroza = parseFloat(especies[i].restoTroza).toFixed(2);
                    especies[i].restoVolumen = parseFloat(especies[i].saldoVolumen) - parseFloat(especies[i].volumen);
                    especies[i].restoVolumen = parseFloat(especies[i].restoVolumen).toFixed(2);
                    total.saldoLenia += parseFloat(especies[i].saldoLenia);
                    total.saldoTroza += parseFloat(especies[i].saldoTroza);
                    total.saldoVolumen += parseFloat(especies[i].saldoVolumen);
                    total.lenia += parseFloat(especies[i].lenia);
                    total.troza += parseFloat(especies[i].troza);
                    total.volumen += parseFloat(especies[i].volumen);
                    total.restoLenia += parseFloat(especies[i].restoLenia);
                    total.restoTroza += parseFloat(especies[i].restoTroza);
                    total.restoVolumen += parseFloat(especies[i].restoVolumen);
                  }
                  total.saldoLenia = parseFloat(total.saldoLenia).toFixed(2);
                  total.saldoTroza = parseFloat(total.saldoTroza).toFixed(2);
                  total.saldoVolumen = parseFloat(total.saldoVolumen).toFixed(2);
                  total.lenia = parseFloat(total.lenia).toFixed(2);
                  total.troza = parseFloat(total.troza).toFixed(2);
                  total.volumen = parseFloat(total.volumen).toFixed(2);
                  total.restoLenia = parseFloat(total.restoLenia).toFixed(2);
                  total.restoTroza = parseFloat(total.restoTroza).toFixed(2);
                  total.restoVolumen = parseFloat(total.restoVolumen).toFixed(2);
                  $scope.total = total;
                  $scope.setConclusiones();
                }

                $scope.actualizarDatos = function (tab) {
                  var data = angular.copy($scope.ttInformeGestion);
                  delete data.ttGestion;
                  delete data.ttPoaGestion;
                  delete data.notas;
                  var especies = data.especies;
                  delete data.especies;
                  var item = data.ttDescripcionInforme;
                  delete data.ttDescripcionInforme;
                  delete data.anexos;
                  item.ttInformeGestion = data;
                  item.conclusiones = JSON.stringify(item.conclusiones);
                  gestionService.actualizarInforme(item).then(function (res) {
                    if (res.status == "OK") {
                      toastr.success(res.message);
                      if (tab == 2) {
                        data.especies = especies;
                        gestionService.actualizarEspeciesInforme(data).then(function (res) {
                          if (res.status == "OK") {
                            if ($scope.ttInformeGestion.tieneMovimiento == 1) {
                              $scope.tabActive.dos = true;
                              $scope.tabVisible.dos = true;
                            } else {
                              $scope.tabActive.tres = true;
                              $scope.tabVisible.tres = true;
                            }
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                      if (tab == 3) {
                        $scope.tabActive.tres = true;
                        $scope.tabVisible.tres = true;
                      }
                      if (tab == 4) {
                        $scope.tabActive.cuatro = true;
                        $scope.tabVisible.cuatro = true;
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.validarPanelTransporte = function (isValid) {
                  if (isValid) {
                    $scope.actualizarDatos(2);
                  }
                }

                $scope.validarPanelDetalle = function (isValid) {
                  if (isValid) {
                    $scope.actualizarDatos(3);
                  }
                }

                $scope.validarPanelConclusiones = function (isValid) {
                  if (isValid) {
                    $scope.actualizarDatos(4);
                  }
                }

                $scope.onSelectRecuperacion = function () {

                }

                $scope.onSelectConclusiones = function () {
                  if ($scope.total == undefined) {
                    $scope.onSelectGenerales();
                  }
                }

                $scope.agregarConclusion = function () {
                  $scope.ttInformeGestion.ttDescripcionInforme.conclusiones.push(
                    { descripcion: null, mostrarQuitar: true }
                  );
                }

                $scope.borrarConclusion = function (item) {
                  $scope.ttInformeGestion.ttDescripcionInforme.conclusiones.splice($scope.ttInformeGestion.ttDescripcionInforme.conclusiones.indexOf(item), 1);
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      //send to database
                      var item = {
                        ruta: item.rutaArchivo,
                        nombre: item.nombreOriginal,
                        size: item.size,
                        extension: item.extension
                      };
                      var data = angular.copy($scope.ttInformeGestion);
                      delete data.ttGestion;
                      delete data.ttPoaGestion;
                      delete data.notas;
                      delete data.especies;
                      delete data.ttDescripcionInforme;
                      item.ttInformeGestion = data;
                      gestionService.agregarAnexoInforme(item).then(function (response) {
                        if (response.status == 'OK') {
                          if ($scope.ttInformeGestion.anexos == null) {
                            $scope.ttInformeGestion.anexos = [];
                          }
                          $scope.ttInformeGestion.anexos.push(response.data[0]);
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.verAnexoInforme = function (item) {
                  gestionService.getAnexoInforme(item.anexoInformeId);
                }

                $scope.borrarAnexo = function (item) {
                  gestionService.quitarAnexoInforme(item.anexoInformeId).then(function (response) {
                    if (response.status == 'OK') {
                      $scope.ttInformeGestion.anexos.splice($scope.ttInformeGestion.anexos.indexOf(item), 1);
                      toastr.success(response.message);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirInforme = function () {
                  if ($scope.ttInformeGestion.ttPoaGestion.ttGestion.personas != null) {
                    utilsService.generarInformeTrimestral($scope.ttInformeGestion, $scope.loginData.usuario, $scope.dataEmpresa);
                    if ($scope.ttInformeGestion.tieneMovimiento == 1) {
                      $timeout(function () {
                        utilsService.generarInformeNotasLicencia($scope.ttInformeGestion, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 1000);
                      $timeout(function () {
                        utilsService.generarInformeNotaAnuladaLicencia($scope.ttInformeGestion, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 2000);
                      $timeout(function () {
                        utilsService.generarInformeNotaExtraviadaLicencia($scope.ttInformeGestion, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 3000);
                    }
                  } else {
                    swal('Actualización de información', 'Para poder imprimir el informe, es necesario actualizar. Haga clic en el menú sobre la opción informe trimestral y vuelva buscar la licencia');
                  }
                }

              }]
          })
          .state('index.gestion.cambiarnota', {
            url: '/cambiarnota',
            templateUrl: 'app/gestion/nota.cambiar.tpl.html',
            resolve: {
              dataSubregion: ['subregionService', '$stateParams',
                function (subregionService, $stateParams) {
                  return subregionService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'gestionService', 'dataSubregion',
              function ($scope, $state, toastr, gestionService, dataSubregion) {

                $scope.dataSubregion = dataSubregion.data;
                $scope.data = {
                  licencia: null
                }

                $scope.solicitud = {
                  tcSubregion: {}
                }

                $scope.rangos = [];

                $scope.ttPoaGestion = null;

                $scope.mostrarDatos = false;
                $scope.deshabilitar = true;

                $scope.submitFormBusqueda = function (isValid) {
                  $scope.mostrarDatos = false;
                  $scope.deshabilitar = true;
                  $scope.solicitud = {
                    tcSubregion: {},
                    ttPoaGestion: {},
                    rangos: []
                  }
                  $scope.rangos = [];
                  $scope.ttPoaGestion = null;
                  if (isValid) {
                    gestionService.buscarLicenciaParaDevolverNota($scope.data.licencia).then(function (res) {
                      if (res.status == 1) {
                        var ttPoaGestion = res.data;
                        var ttGestion = ttPoaGestion.ttGestion;
                        $scope.solicitud.tcSubregion.subregionId = ttGestion.tcSubregion.subregionId;
                        $scope.solicitud.ttPoaGestion.poaGestionId = ttPoaGestion.poaGestionId;
                        $scope.ttPoaGestion = ttPoaGestion;
                        $scope.solicitud.expediente = ttGestion.expediente;
                        $scope.mostrarDatos = true;
                      } else {
                        if (res.status == 2) {
                          toastr.warning('No se ha encontrado información relacionada, por favor complete el formulario para continuar');
                          $scope.mostrarDatos = true;
                          $scope.deshabilitar = false;
                          $scope.solicitud.ttPoaGestion = null;
                        } else {
                          toastr.error(res.message);
                        }
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.agregarRango = function () {
                  $scope.solicitud.rangos.push(
                    {
                      esRango: 0,
                      cantidad: 1
                    }
                  );
                }

                $scope.ponerFinal = function (detalle) {
                  if (detalle.inicio == null) {
                    toastr.error('Debe agregar un correlativo válido');
                    return;
                  }
                  if (detalle.cantidad == null) {
                    detalle.cantidad = 1;
                  }
                  if (detalle.cantidad < 1) {
                    detalle.cantidad = 1;
                  }
                  if (detalle.cantidad > 1) {
                    detalle.fin = parseInt(detalle.inicio) + parseInt(detalle.cantidad) - 1;
                  } else {
                    detalle.fin = detalle.inicio;
                  }
                  var ttPoaGestion = $scope.ttPoaGestion;
                  if (ttPoaGestion != null) {
                    var notas = ttPoaGestion.notas;
                    var found = false;
                    for (let i = 0; i < notas.length; i++) {
                      if (detalle.inicio >= notas[i].inicio && detalle.inicio <= notas[i].fin) {
                        if (detalle.fin >= notas[i].inicio && detalle.fin <= notas[i].fin) {
                          found = true;
                        }
                      }
                    }
                    if (!found) {
                      toastr.error('El rango o número de nota ingresado no pertenece a la resolución indicada');
                      detalle.inicio = null;
                      detalle.fin = null;
                    }
                  }
                }

                $scope.quitarDetalle = function (detalle) {
                  $scope.solicitud.rangos.splice($scope.solicitud.rangos.indexOf(detalle), 1);
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    if ($scope.solicitud.rangos.length <= 0) {
                      toastr.error('Debe indicar al menos un número de nota (Use el botón con el signo + para realizarlo)');
                      return;
                    }
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de solicitar el cambio de las notas?",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          gestionService.enviarSolicitudCambioNotas($scope.solicitud).then(function (res) {
                            if (res.status == 1) {
                              $scope.mostrarDatos = false;
                              $scope.deshabilitar = true;
                              $scope.solicitud = {
                                tcSubregion: {},
                                ttPoaGestion: {},
                                rangos: []
                              }
                              $scope.rangos = [];
                              $scope.ttPoaGestion = null;
                              $scope.data = {
                                licencia: null
                              }
                              toastr.success('Su solicitud se ha realizado correctamente, el número es: ' + res.data + '. Su trámite no iniciará si no presenta las notas físicas a la subregión');
                              $state.go('^.lista');
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  }
                }

                $scope.etiquetaEstado = function ( estadoId ) {
                  switch (estadoId) {
                    case 0: return 'Anulado';
                    case 1: return 'Enviado';
                    case 2: return 'En trámite';
                    case 3: return 'Finalizado';
                  }
                }

                $scope.gridLista = angular.copy($scope.gridOptionsSelection);
                $scope.gridLista.columnDefs = [
                  { field: 'cambioNotaId', name: 'cambioNotaId', displayName: 'No. Solicitud', align: "right" },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'cantidad', name: 'cantidad', displayName: 'Total solicitado'},
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'fechaIngreso', name: 'fechaIngreso', displayName: 'Fecha aceptación', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' }
                ];
                $scope.gridLista.data = [];
                $scope.cargarLista = false;
                $scope.mostrarLista = false;
                $scope.verSolicitudes = function () {
                  if (!$scope.cargarLista) {
                    gestionService.getListaSolicitudCambioNota().then(function (res) {
                      if (res.status == 1) {
                        $scope.gridLista.data = res.data;
                        $scope.mostrarLista = true;
                        $scope.cargarLista = true;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    $scope.mostrarLista = !$scope.mostrarLista;
                  }
                }

              }]
          })
      }
    ]
  );
