angular.module('app.metodosiembra', [
  'ui.router',
  'toastr',
  'app.metodosiembra.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.metodosiembra', {
          abstract: true,
          url: 'metodosiembra',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'MetodoSiembra';
            }]
        })
        .state('index.metodosiembra.list', {
          url: '',
          templateUrl: 'app/metodosiembra/metodosiembra.list.tpl.html',
          resolve: {
            dataMetodoSiembra: ['metodosiembraService',
              function ( metodosiembraService ){
                return metodosiembraService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataMetodoSiembra',
            function (  $scope,   $state,   dataMetodoSiembra) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'metodoSiembraDesc', name: 'metodoSiembraDesc', displayName: 'MetodoSiembra' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataMetodoSiembra.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ metodoSiembraId: row.metodoSiembraId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.metodosiembra.add', {
          url: '/add',
          templateUrl: 'app/metodosiembra/metodosiembra.add.tpl.html',
          resolve: {
            dataEstado: ['metodosiembraService',
              function ( metodosiembraService ){
                return metodosiembraService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'metodosiembraService', 'dataEstado',
            function (  $scope,   $state,   toastr,   metodosiembraService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  metodosiembraService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.metodosiembra.edit', {
          url: '/:metodoSiembraId/edit',
          templateUrl: 'app/metodosiembra/metodosiembra.add.tpl.html',
          resolve: {
            dataMetodoSiembra: ['metodosiembraService', '$stateParams',
              function ( metodosiembraService, $stateParams ){
                return metodosiembraService.get( $stateParams.metodoSiembraId );
              }],
            dataEstado: ['metodosiembraService',
              function ( metodosiembraService ){
                return metodosiembraService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'metodosiembraService', 'dataMetodoSiembra', 'dataEstado',
            function (  $scope,   $state,   toastr,   metodosiembraService,   dataMetodoSiembra, dataEstado) {
              $scope.data = dataMetodoSiembra.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  metodosiembraService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
