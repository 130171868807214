angular.module('app.tiporepresentacion', [
  'ui.router',
  'toastr',
  'app.tiporepresentacion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tiporepresentacion', {
          abstract: true,
          url: 'tiporepresentacion',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Documento de propiedad';
            }]
        })
        .state('index.tiporepresentacion.list', {
          url: '',
          templateUrl: 'app/tiporepresentacion/tiporepresentacion.list.tpl.html',
          resolve: {
            dataTipoRepresentacion: ['tiporepresentacionService',
              function ( tiporepresentacionService ){
                return tiporepresentacionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataTipoRepresentacion',
            function (  $scope,   $state,   dataTipoRepresentacion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tipoRepresentacionDesc', name: 'tipoRepresentacionDesc', displayName: 'Tipo de representación' },
                { field:'lugarInscripcion', name: 'lugarInscripcion', displayName: 'Inscrito en' },
                { field:'texto', name: 'texto', displayName: 'Documento' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataTipoRepresentacion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ tipoRepresentacionId: row.tipoRepresentacionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.tiporepresentacion.add', {
          url: '/add',
          templateUrl: 'app/tiporepresentacion/tiporepresentacion.add.tpl.html',
          resolve: {
            dataEstado: ['tiporepresentacionService',
              function ( tiporepresentacionService ){
                return tiporepresentacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tiporepresentacionService', 'dataEstado',
            function (  $scope,   $state,   toastr,   tiporepresentacionService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tiporepresentacionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.tiporepresentacion.edit', {
          url: '/:tipoRepresentacionId/edit',
          templateUrl: 'app/tiporepresentacion/tiporepresentacion.add.tpl.html',
          resolve: {
            dataTipoRepresentacion: ['tiporepresentacionService', '$stateParams',
              function ( tiporepresentacionService, $stateParams ){
                return tiporepresentacionService.get( $stateParams.tipoRepresentacionId );
              }],
            dataEstado: ['tiporepresentacionService',
              function ( tiporepresentacionService ){
                return tiporepresentacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tiporepresentacionService', 'dataTipoRepresentacion', 'dataEstado',
            function (  $scope,   $state,   toastr,   tiporepresentacionService,   dataTipoRepresentacion, dataEstado) {
              $scope.data = dataTipoRepresentacion.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tiporepresentacionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
