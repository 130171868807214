angular.module( 'app.directives', [
  'app.utilsService',
  'app.authService'
])

.directive( 'numbersOnly', [function () {
   return {
     require: 'ngModel',
     link: function (scope, element, attrs, modelCtrl) {
       modelCtrl.$parsers.push( function (inputValue) {
           if (inputValue == undefined) return '';
           var transformedInput = String(inputValue).replace(/[^0-9\.]/g, '' ); /*/^\d*\.?\d*$/*/
           if (transformedInput!=inputValue) {
              modelCtrl.$setViewValue(transformedInput);
              modelCtrl.$render();
           }
           return transformedInput;
       });
     }
   };
}])

.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.ngEnter);
                });
                event.preventDefault();
            }
        });
    };
})

.directive( 'lettersOnly', [function () {
  return {
   require: 'ngModel',
   link: function (scope, element, attrs, modelCtrl) {
     modelCtrl.$parsers.push( function (inputValue) {
      if (inputValue == undefined) return '';
      var transformedInput = inputValue.replace(/[^a-zA-Z\.\,\u0020\u00E1\u00E9\u00ED\u00F3\u00FA\u00D1\u00F1]/g, '' );
      if (transformedInput!=inputValue) {
        modelCtrl.$setViewValue(transformedInput);
        modelCtrl.$render();
      }

      return transformedInput;
     });
   }
  };
}])

.directive( 'disallowSpace', [function () {
   return {
     require: 'ngModel',
     link: function (scope, element, attrs, modelCtrl) {
       modelCtrl.$parsers.push( function (inputValue) {
           if (inputValue == undefined) return '';
           var transformedInput = inputValue.replace(/ /g, '');
           if (transformedInput!=inputValue) {
              modelCtrl.$setViewValue(transformedInput);
              modelCtrl.$render();
           }

           return transformedInput;
       });
     }
   };
}])

.directive( 'validateCero', [function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.validateCero = function (modelValue, viewValue) {
        var regex = /^[1-9][0-9]*/;

        if( regex.test(modelValue) ) {
          return true;
        } else {
          return false;
        }

      };
    }
  };
}])

.directive('focusOn', [function() {
   return function(scope, elem, attr) {
      scope.$on('focusOn', function(e, name) {
        if(name === attr.focusOn) {
          elem[0].focus();
        }
      });
   };
}])

.factory('focus', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
  return function(name) {
    $timeout(function (){
      $rootScope.$broadcast('focusOn', name);
    });
  }
}])

.directive('imgUpload', ['$rootScope', 'toastr', function (rootScope, toastr) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      var extensions = 'jpeg ,jpg, png, gif, bmp';
      var file = elem[0];
      elem.on('change', function () {
        file = elem[0].files[0];
        reader.readAsDataURL(elem[0].files[0]);
        var filename = elem[0].files[0].name;
        var extensionlist = filename.split('.');
        var extension = extensionlist[extensionlist.length - 1];
        if(extensions.indexOf(extension) == -1) {
          toastr.error("Archivo incorrecto , se permiten solo 'jpeg', 'jpg', 'png', 'gif', 'bmp'.");
          scope.file = null;
        } else {
          /*if (file.size > 300000) {
            toastr.error("La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña");
            scope.file = null;
            if (scope.usuario != undefined) {
              scope.usuario.foto = null;
            } else {
              scope.persona.foto = null;
            }
          } else {*/
          scope.file = elem[0].files[0];
          scope.foto = filename;
          //}
        }
      });

      var reader = new FileReader();
      reader.onload = function (e) {
        /*var img = document.createElement("img");
        var canvas = document.createElement("canvas");
        img.src = e.target.result;
        var width = 175;
        var height = 200;
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL(file.type);
        scope.image = dataurl;
        scope.usuario.foto = dataurl;*/
        /*if (e.total > 300000) {
          scope.validoFoto = false;
        } else {*/
        scope.validoFoto = true;
        //}
        scope.image = e.target.result;
        if (scope.usuario != undefined) {
          scope.usuario.foto = e.target.result;
        } else {
          scope.persona.foto = e.target.result;
        }
        scope.$apply();
      }
    }
  }
}])

.directive('image', function($q) {
    'use strict'
    var URL = window.URL || window.webkitURL;
    var getResizeArea = function () {
        var resizeAreaId = 'fileupload-resize-area';
        var resizeArea = document.getElementById(resizeAreaId);
        if (!resizeArea) {
            resizeArea = document.createElement('canvas');
            resizeArea.id = resizeAreaId;
            resizeArea.style.visibility = 'hidden';
            document.body.appendChild(resizeArea);
        }
        return resizeArea;
    }

    var resizeImage = function (origImage, options) {
        var maxHeight = options.resizeMaxHeight || 200;
        var maxWidth = options.resizeMaxWidth || 175;
        var quality = options.resizeQuality || 0.9;
        var type = options.resizeType || 'image/png';
        var canvas = getResizeArea();
        var height = origImage.height;
        var width = origImage.width;
        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round(height *= maxWidth / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round(width *= maxHeight / height);
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;

        //draw image on canvas
        var ctx = canvas.getContext("2d");
        ctx.drawImage(origImage, 0, 0, width, height);
        // get the data from canvas as 70% jpg (or specified type).
        return canvas.toDataURL(type, quality);
    };

    var createImage = function(url, callback) {
        var image = new Image();
        image.onload = function() {
            callback(image);
        };
        image.src = url;
    };

    var fileToDataURL = function (file) {
        var deferred = $q.defer();
        var reader = new FileReader();
        reader.onload = function (e) {
            deferred.resolve(e.target.result);
        };
        reader.readAsDataURL(file);
        return deferred.promise;
    };


    return {
        restrict: 'A',
        scope: {
            image: '=',
            resizeMaxHeight: '@?',
            resizeMaxWidth: '@?',
            resizeQuality: '@?',
            resizeType: '@?',
        },
        link: function postLink(scope, element, attrs, ctrl) {
            var doResizing = function(imageResult, callback) {
                createImage(imageResult.url, function(image) {
                    var dataURL = resizeImage(image, scope);
                    imageResult.resized = {
                        dataURL: dataURL,
                        type: dataURL.match(/:(.+\/.+);/)[1],
                    };
                    callback(imageResult);
                });
            };

            var applyScope = function(imageResult) {
                scope.$apply(function() {
                  if(attrs.multiple) {
                    scope.image.push(imageResult);
                    scope.usuario.foto = imageResult;
                  } else {
                    scope.image = imageResult;
                    scope.usuario.foto = imageResult;
                  }

                });
            };

            element.bind('change', function (evt) {
                //when multiple always return an array of images
                if(attrs.multiple)
                    scope.image = [];

                var files = evt.target.files;
                for(var i = 0; i < files.length; i++) {
                    //create a result object for each file in files
                    var imageResult = {
                        file: files[i],
                        url: URL.createObjectURL(files[i])
                    };

                    fileToDataURL(files[i]).then(function (dataURL) {
                        imageResult.dataURL = dataURL;
                    });

                    if(scope.resizeMaxHeight || scope.resizeMaxWidth) { //resize image
                        doResizing(imageResult, function(imageResult) {
                            applyScope(imageResult);
                        });
                    }
                    else { //no resizing
                        applyScope(imageResult);
                    }
                }
            });
        }
    };
})

.directive( 'validateYear', [function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.validateYear = function (modelValue, viewValue) {
        if( !modelValue || modelValue == '' || (modelValue <= new Date().getFullYear() && modelValue>1899 ) ) {
          return true;
        } else {
          return false;
        }

      };
    }
  };
}])

.directive( 'uibDatepickerPopup', ['dateFilter', 'uibDatepickerPopupConfig', function (dateFilter, uibDatepickerPopupConfig) {
    return {
        restrict: 'EAC',
        priority: 1,
        require: '^?ngModel',
        link: function (scope, element, attrs, ngModel) {
            // http://stackoverflow.com/questions/7556591/javascript-date-object-always-one-day-off
            // http://stackoverflow.com/questions/24198669/angular-bootsrap-datepicker-date-format-does-not-format-ng-model-value
            var dateFormat = attrs.uibDatepickerPopup || uibDatepickerPopupConfig.datepickerPopup;

            var setupDate = function (date) {
              if ( angular.isDate(date) ) {
                // se convierte a formato yyyy/MM/dd para que no reste un dia
                if ( dateFormat == 'dd/MM/yyyy' ) {
                  return new Date(dateFilter(date, 'yyyy/MM/dd' ));
                } else {
                  return new Date(dateFilter(date, 'yyyy/MM/dd HH:mm' ));
                }
              } else if ( date != '' ) {
                var temp = date.split( '-' );
                if ( temp.length > 1 ) {
                  date = temp[0] + '/' + temp[1] + '/' + temp[2];
                }
                return new Date( date );
              } else {
                return '';
              }
            }

            ngModel.$formatters.push( function (value) {
              // return dateFilter(value, dateFormat);
              // return dateFilter(new Date(value), dateFormat);
              // return value == '' ? value : dateFilter(new Date(value), dateFormat);
              return !value || value == '' ? value : new Date(setupDate(value));
            });

            ngModel.$validators.date = function (modelValue, viewValue) {

              var value = modelValue || viewValue;

              if (!attrs.ngRequired && !value) {
                return true;
              }

              if (angular.isNumber(value)) {
                value = new Date(value);
              }

              if (!value) {
                return true;
              }
              else if (angular.isDate(value) && !isNaN(value)) {

                if ( attrs.maxDate ) {
                  if ( setupDate( value ).getTime() <= setupDate( attrs.maxDate.substr(1,10) ).getTime() ) {
                    return true
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }

                return true;
              }
              else if (angular.isString(value)) {

                if ( angular.isDate(setupDate(value)) ) {
                  if ( attrs.maxDate ) {
                    if ( setupDate( value ).getTime() <= setupDate( attrs.maxDate.substr(1,10) ).getTime() ) {
                      return true
                    }
                  } else {
                    return true;
                  }
                }

                return false;

                // return angular.isDate(setupDate(value));
              }
              else {
                return false;
              }
            };

        }
    };
}])

.directive( 'ckEditor', [function () {
  return {
      require: '?ngModel',
      link: function (scope, elm, attrs, ngModel) {

        var ck = CKEDITOR.replace(elm[0]);

        ck.on( 'pasteState', function () {
          scope.$apply( function () {
            ngModel.$setViewValue(ck.getData());
          });
        });

        ngModel.$render = function (value) {
          ck.setData(ngModel.$modelValue);
        };
      }
  };
}])

.directive( 'fileInput', ['$parse', function ($parse) {
  return {
    restrict: 'A',
    link: function (scope, elm, attrs) {
      elm.bind( 'change', function () {
        $parse(attrs.fileInput).assign(scope, elm[0].files);
        scope.$apply();
      })
    }
  }
}])

.directive( 'uiGrid', ['$parse', function ($parse) {
  return {
    //restrict: 'A', // EAC, E (element)
    /*scope: {
      uiGrid: '='
    },*/
    link: function (scope, elm, attrs, ngModel) {
      var getHeight = function (gridOptions) {
        var totalRows = gridOptions.data.length;
        var height = 0;
        var headerHeight = 35;
        var footerHeight = 35;
        var scrollX = 15;
        var extraRowHeight = 30;
        height = ((totalRows >= gridOptions.paginationPageSizes[0] ? gridOptions.paginationPageSizes[0] : totalRows) * gridOptions.rowHeight + extraRowHeight + headerHeight + footerHeight + scrollX) + 'px';        
        return height
      };

      var parsed = $parse(attrs.uiGrid);

      scope.$watch( function () {
        return $parse(attrs.uiGrid)(scope).data;
      },function (value) {
        if (!value) {
          return ;
        }
        attrs.$set( 'style', 'width: 100%;height:' + getHeight( parsed(scope) ) );
      }, true);

      /*scope.$watch(attrs.uiGrid, function (value) {
        if (!value) {
          return ;
        }
        attrs.$set( 'style', 'width: 100%;height:' + getHeight(elm.isolateScope().uiGrid));
      });*/
    }
  }
}])

// timepickerPop

.factory( 'timepickerState', [function () {
  var pickers = [];
  return {
    addPicker: function (picker) {
      pickers.push(picker);
    },
    closeAll: function () {
      for (var i=0; i<pickers.length; i++) {
        pickers[i].close();
      }
    }
  };
}])

.directive("timeFormat", ['$filter', function ($filter) {
  return {
    restrict : 'A',
    require : 'ngModel',
    scope : {
      showMeridian : '=',
    },
    link : function (scope, element, attrs, ngModel) {
      var parseTime = function (viewValue) {

        if (!viewValue) {
          ngModel.$setValidity( 'time', true);
          return null;
        } else if (angular.isDate(viewValue) && !isNaN(viewValue)) {
          ngModel.$setValidity( 'time', true);
          return viewValue;
        } else if (angular.isString(viewValue)) {
          var timeRegex = /^(0?[0-9]|1[0-2]):[0-5][0-9] ?[a|p]m$/i;
          if (!scope.showMeridian) {
            timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
          }
          if (!timeRegex.test(viewValue)) {
            ngModel.$setValidity( 'time', false);
            return undefined;
          } else {
            ngModel.$setValidity( 'time', true);
            var date = new Date();
            var sp = viewValue.split(":");
            var apm = sp[1].match(/[a|p]m/i);
            if (apm) {
              sp[1] = sp[1].replace(/[a|p]m/i, '' );
              if (apm[0].toLowerCase() == 'pm' ) {
                sp[0] = sp[0] + 12;
              }
            }
            date.setHours(sp[0], sp[1]);
            return date;
          };
        } else {
          ngModel.$setValidity( 'time', false);
          return undefined;
        };
      };

      ngModel.$parsers.push(parseTime);

      var showTime = function ( data ) {
        parseTime( data );
        var timeFormat = (!scope.showMeridian) ? "HH:mm" : "hh:mm a";
        return $filter( 'date' )(data, timeFormat);
      };
      ngModel.$formatters.push(showTime);
      scope.$watch( 'showMeridian', function (value) {
        var myTime = ngModel.$modelValue;
        if (myTime) {
          element.val(showTime(myTime));
        }

      });
    }
  };
}])

.directive( 'timepickerPop', ['$document', 'timepickerState', function ($document, timepickerState) {
  return {
    restrict : 'E',
    transclude : false,
    scope : {
      inputTime : "=",
      showMeridian : "=",
      disabled : "="
    },
    controller : ['$scope', '$element', function ($scope, $element) {
      $scope.isOpen = false;

      $scope.disabledInt = angular.isUndefined($scope.disabled)? false : $scope.disabled;

      $scope.toggle = function () {
      if ($scope.isOpen) {
        $scope.close();
      } else {
        $scope.open();
      }
      };
    }],
    link : function (scope, element, attrs) {
      var picker = {
          open : function () {
            timepickerState.closeAll();
            scope.isOpen = true;
          },
          close: function () {
            scope.isOpen = false;
          }

      }
      timepickerState.addPicker(picker);

      scope.open = picker.open;
      scope.close = picker.close;

      scope.$watch("disabled", function (value) {
        scope.disabledInt = angular.isUndefined(scope.disabled)? false : scope.disabled;
      });

      /*scope.$watch("inputTime", function (value) {
        if (!scope.inputTime) {
          element.addClass( 'has-error' );
        } else {
          element.removeClass( 'has-error' );
        }
      });*/

      element.bind( 'click', function (event) {
        event.preventDefault();
        event.stopPropagation();
      });

      $document.bind( 'click', function (event) {
        scope.$apply( function () {
          scope.isOpen = false;
        });
      });

    },
    template : "<input type='text' class='form-control' ng-model='inputTime' ng-disabled='disabledInt' time-format show-meridian='showMeridian' data-toggle='dropdown' ng-focus='open()' />"
        + "  <div ng-class='{open:isOpen}'> "
        + "          <div class='dropdown-menu pull-right'> "
        + "            <uib-timepicker ng-model='inputTime' show-meridian='showMeridian'></uib-timepicker>"
        + "           </div> " + "  </div>"
  };
}])

.directive( 'csSelect', [function () {
    return {
        require: '^stTable',
        template: '<input type="checkbox"/>',
        scope: {
            row: '=csSelect'
        },
        link: function (scope, element, attr, ctrl) {

            element.bind( 'click ', function (evt) {
            //element.bind( 'change', function (evt) {
                scope.$apply( function () {
                    ctrl.select(scope.row, 'multiple' );
                });
            });

            scope.$watch( 'row.isSelected', function (newValue, oldValue) {
                if (newValue === true) {
                    element.parent().addClass( 'st-selected' );
                    element.find( 'input' ).attr( 'checked', true); //agregado
                } else {
                    element.parent().removeClass( 'st-selected' );
                    element.find( 'input' ).attr( 'checked',false); //false
                }
            });
        }
    };
}])

.directive( 'rowSelectAll', [function () {
    return {
    require: '^stTable',
    template: '<input type="checkbox">',
    scope: {
      all: '=rowSelectAll',
      selected: '='
    },
    link: function (scope, element, attr) {
      scope.isAllSelected = false;
      element.bind( 'click', function (evt) {
        scope.$apply( function () {
          scope.all.forEach( function (val) {
            val.isSelected = scope.isAllSelected;
          });
        });
      });

      scope.$watchCollection( 'selected', function (newVal) {
        var s = newVal.length;
        var a = scope.all.length;
        if ((s == a) && s > 0 && a > 0) {
          element.find( 'input' ).attr( 'checked', true);
          scope.isAllSelected = false;
        } else {
          element.find( 'input' ).attr( 'checked', false);
          scope.isAllSelected = true;
        }
            });
        }
    };
}])

.directive( 'menuTabset', ['utilsService', function (utilsService) {
  return {
    restrict : 'E',
    transclude : false,
    scope : {
      tabs : "="
    },
    controller : ['$scope', function ( $scope ) {
      $scope.goTab = utilsService.goTab;
      $scope.typeof = utilsService.typeof;
    }],
    link : function ( scope, element, attrs ) {
    },
    templateUrl : 'app/common/menuTabset.tpl.html'
  };
}])

.directive( 'btn', [function () {
  return {
    restrict: 'C',
    link: function (scope, element) {
      if(element.hasClass( 'btn-icon' ) || element.hasClass( 'btn-float' )) {
        Waves.attach(element, ['waves-circle']);
      } else if (element.hasClass( 'btn-light' )) {
        Waves.attach(element, ['waves-light']);
      } else {
        // Waves.attach(element);
        if (!element.hasClass( 'ui-select-toggle' )) {
          Waves.attach(element, ['waves-effect', 'waves-float']);
        }
      }
      Waves.init();
    }
  }
}])

.directive("ngFileSelect", function () {
  return {
    link: function ($scope, $elm, $attrs) {
      $elm.bind("change", function (e) {
        var file = (e.srcElement || e.target).files[0];
        /*var path = e.target.value;
        path = path.substring(0, 3);
        $scope.filePath = path + $scope.file.name;*/
        $scope.onFileSelect(file, e.target.id);
      })
    }
  }
})

//parse json to xlsx file, set data to grid
.directive("fileRead", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    scope: {
      opts: '='
    },
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            $scope.opts.data = [];
            var origenCalculoId = authService.getLocalData('origenCalculoId');
            var esVerificacion = authService.getLocalData('esVerificacion');
            var formaParcelaId = authService.getLocalData('formaParcelaId');
            var tipoInventarioId = authService.getLocalData('tipoInventarioId');
            var compensaPendienteId = authService.getLocalData('compensaPendienteId');
            var tipoGestionId = authService.getLocalData('tipoGestionId');
            if (tipoInventarioId == 1) {
              compensaPendienteId = null;
              formaParcelaId = null;
            }
            if (formaParcelaId == null) {
              formaParcelaId = 0;
            }
            if (compensaPendienteId == null) {
              compensaPendienteId = -1;
            }
            if (origenCalculoId == null) {
              toastr.error('Debe seleccionar la forma de cálculo');
              return;
            }
            if (esVerificacion == null) {
              esVerificacion = false;
            } else {
              if (esVerificacion == null) {
                esVerificacion = false;
              }
            }
            if (tipoInventarioId == null) {
              toastr.error('Debe seleccionar el tipo de inventario');
              return;
            } else {
              if (!esVerificacion) {
                if (tipoInventarioId != 1) {
                  if (formaParcelaId == 0) {
                    toastr.error('Debe seleccionar la forma de la parcela');
                    return;
                  } else {
                    if (formaParcelaId == 4 && compensaPendienteId == -1) {
                      toastr.error('Debe seleecionar si el instrumento compensa pendientes o no');
                      return;
                    }
                  }
                }
              }
            }
            var dataEspecie = authService.getLocalData('dataEspecie');
            var dataClaseDiametrica = authService.getLocalData('dataClaseDiametrica');
            if (dataClaseDiametrica == null) {
              dataClaseDiametrica = [];
            }
            //funcion que returna el valor de la propiedad del objeto json
            var valor = function ( json, columna ) {
              for (var key in json) {
                 if (key == columna) return json[key];
              }
              return null;
            }

            //funcion que realiza una operacion matematica a partir de una cadena
            var operacion = function (ecuacion) {
              return new Function('return ' + ecuacion)();
            }

            //devuelve la formula de la especie a partir del nombre científico
            var formula = function (json) {
              var __nombreCientifico = json.NOMBRE_CIENTIFICO.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
              var ncev = json.NOMBRE_CIENTIFICO.trim().toLowerCase();
              var lncev = ncev.length;
              var i;
              for (i = 0; i < dataEspecie.length; i++) {
                var _nombreCientifico = dataEspecie[i].nombreCientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                if (_nombreCientifico.localeCompare(__nombreCientifico) == 0) {
                  return angular.copy(dataEspecie[i]);
                }
                var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                if (ncev.localeCompare(nce) == 0) {
                  return angular.copy(dataEspecie[i]);
                }
              }
              //compare character per character
              while (ncev.indexOf(' ') > -1) {
                ncev = ncev.replace(' ', '_');
                ncev = ncev.replace('á', '_');
                ncev = ncev.replace('é', '_');
                ncev = ncev.replace('í', '_');
                ncev = ncev.replace('ó', '_');
                ncev = ncev.replace('ú', '_');
              }
              for (i = 0; i < dataEspecie.length; i++) {
                var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                var lnce = nce.length;
                if (lncev == lnce) {
                  while (nce.indexOf(' ') > -1) {
                    nce = nce.replace(' ', '_');
                    nce = nce.replace('á', '_');
                    nce = nce.replace('é', '_');
                    nce = nce.replace('í', '_');
                    nce = nce.replace('ó', '_');
                    nce = nce.replace('ú', '_');
                  }
                  var siEs = true;
                  for (var j = 0; j < lncev; j++) {
                    if (ncev[j] != nce[j]) {
                      siEs = false;
                      break;
                    }
                  }
                  if (siEs) {
                    return angular.copy(dataEspecie[i]);
                  }
                }
              }
              return null;
            }

            try {
              var data = evt.target.result;
              var workbook = XLSX.read(data, {type: 'binary'});
              var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
              var i = 0, j = 0;
              var rodales = [];
              /*
              *Si es verificacion primero hay que obtener los datos del inventario proporcionados por el elaborador
              */
              if (esVerificacion) {
                var dataOriginal = authService.getLocalData('dataOriginal');
                var tipoInventarioId = authService.getLocalData('tipoInventarioId');
                var j;
                var encontrado;
                var noEncontrados = [];
                var noCoinciden= [];
                var cantidadMostrar = 10;
                for (i = 0; i < data.length; i++) {
                  encontrado = false;
                  data[i].NO = parseInt(data[i].NO);
                  data[i].RODAL = parseInt(data[i].RODAL);
                  data[i].AREA_RODAL = parseFloat(data[i].AREA_RODAL).toFixed(4);
                  for (j = 0; j < dataOriginal.length; j++) {
                    dataOriginal[j].NO = parseInt(dataOriginal[j].NO);
                    dataOriginal[j].RODAL = parseInt(dataOriginal[j].RODAL);
                    if (isNaN(data[i].PARCELA) || isNaN(parseInt(data[i].PARCELA))) { //censo
                      if (data[i].RODAL == dataOriginal[j].RODAL && data[i].NO == dataOriginal[j].NO && isNaN(dataOriginal[j].PARCELA)) {
                        var _nombreCientifico = data[i].NOMBRE_CIENTIFICO_VERIFICADO.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                        var __nombreCientifico = dataOriginal[j].NOMBRE_CIENTIFICO.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                        if (_nombreCientifico == __nombreCientifico) {
                          data[i].TURNO = dataOriginal[j].TURNO;
                          data[i].AREA_RODAL = parseFloat(dataOriginal[j].AREA_RODAL).toFixed(4);
                          data[i].TAMANIO_PARCELA = dataOriginal[j].TAMANIO_PARCELA;
                          data[i].DAP = dataOriginal[j].DAP;
                          data[i].ALTURA = dataOriginal[j].ALTURA;
                          data[i].NOMBRE_CIENTIFICO = dataOriginal[j].NOMBRE_CIENTIFICO;
                          data[i].VOLUMEN = dataOriginal[j].VOLUMEN;
                          data[i].AREA_BASAL = dataOriginal[j].AREA_BASAL;
                        } else {
                          noCoinciden.push({no: data[i].NO, parcela: 0, rodal: data[i].RODAL, nombreCientifico: data[i].NOMBRE_CIENTIFICO_VERIFICADO, nombreCientifico2: dataOriginal[j].NOMBRE_CIENTIFICO});
                        }
                        encontrado = true;
                        break;
                      }
                    } else { //muestreo
                      if (data[i].TAMANIO_PARCELA == null || data[i].TAMANIO_PARCELA == '') {
                        data[i].TAMANIO_PARCELA = 0;
                      }
                      data[i].PARCELA = parseInt(data[i].PARCELA);
                      dataOriginal[j].PARCELA = parseInt(dataOriginal[j].PARCELA);
                      if (data[i].RODAL == dataOriginal[j].RODAL && data[i].PARCELA == dataOriginal[j].PARCELA && data[i].NO == dataOriginal[j].NO) {
                        if (data[i].NOMBRE_CIENTIFICO_VERIFICADO.trim().toLowerCase() == dataOriginal[j].NOMBRE_CIENTIFICO.trim().toLowerCase()) {
                          data[i].TURNO = dataOriginal[j].TURNO;
                          data[i].AREA_RODAL = parseFloat(dataOriginal[j].AREA_RODAL).toFixed(4);
                          data[i].TAMANIO_PARCELA = dataOriginal[j].TAMANIO_PARCELA;
                          data[i].DAP = dataOriginal[j].DAP;
                          data[i].ALTURA = dataOriginal[j].ALTURA;
                          data[i].NOMBRE_CIENTIFICO = dataOriginal[j].NOMBRE_CIENTIFICO;
                          data[i].VOLUMEN = dataOriginal[j].VOLUMEN;
                          data[i].AREA_BASAL = dataOriginal[j].AREA_BASAL;
                        } else {
                          noCoinciden.push({no: data[i].NO, parcela: data[i].PARCELA, rodal: data[i].RODAL, nombreCientifico: data[i].NOMBRE_CIENTIFICO_VERIFICADO, nombreCientifico2: dataOriginal[j].NOMBRE_CIENTIFICO});
                        }
                        encontrado = true;
                        break;
                      }
                    }
                  }
                  if (!encontrado) {
                    if (data[i].PARCELA == undefined || data[i].PARCELA == null) {
                      noEncontrados.push({no: data[i].NO, parcela: 0, rodal: data[i].RODAL, nombreCientifico: data[i].NOMBRE_CIENTIFICO_VERIFICADO});
                    } else {
                      noEncontrados.push({no: data[i].NO, parcela: data[i].PARCELA, rodal: data[i].RODAL, nombreCientifico: data[i].NOMBRE_CIENTIFICO_VERIFICADO});
                    }
                  }
                }
                //encontrado se utiliza para indicar si hubo errores y no continuar
                encontrado = false;
                var strMensaje = '';
                cantidadMostrar = 10;
                if (noCoinciden.length > 0) {
                  strMensaje = ' Los siguientes árboles, no coinciden las especies verificadas con las proporcionadas en el inventario por el elaborador: \n';
                  for (i = 0; i < noCoinciden.length; i++) {
                    strMensaje += ' Rodal: ' + noCoinciden[i].rodal + ', árbol: ' + noCoinciden[i].no + (noCoinciden[i].parcela == 0 ? '' :  ', parcela: ' + noCoinciden[i].parcela + ' \n') + ' ' + noCoinciden[i].nombreCientifico + ' diferente a ' + noCoinciden[i].nombreCientifico2 + '\n';
                    if (i >= cantidadMostrar) {
                      break;
                    }
                  }
                  encontrado = true;
                }
                if (noEncontrados.length > 0) {
                  strMensaje = ' Los siguientes árboles no fueron encontrados en el inventario proporcionado por el elaborador: \n';
                  for (i = 0; i < noEncontrados.length; i++) {
                    strMensaje += ' Rodal: ' + noEncontrados[i].rodal + ', árbol: ' + noEncontrados[i].no + (noEncontrados[i].parcela == 0 ? '' : ', parcela: ' + noEncontrados[i].parcela + '\n') + ' ' + noEncontrados[i].nombreCientifico + '\n';
                    if (i >= cantidadMostrar) {
                      break;
                    }
                  }
                  encontrado = true;
                }
                if (encontrado) { //si hay errores finalizar la ejecucion del proceso
                  swal('Problemas con la información', strMensaje);
                  $scope.opts.data = [];
                  return;
                }
              } else {
                if (formaParcelaId == 4) { //tamaño variable
                  for (i = 0; i < data.length; i++) {
                    data[i].AREA_RODAL = parseFloat(data[i].AREA_RODAL).toFixed(4);
                    data[i].TAMANIO_PARCELA = 10000;
                    var item = {};
                    var correlativo = data[i].RODAL;
                    encontrado = false;
                    for (j = 0; j < rodales.length; j++) {
                      if (correlativo == rodales[j].correlativo) {
                        item = rodales[j];
                        encontrado = true;
                        break;
                      }
                    }
                    if (encontrado) {
                      encontrado = false;
                      for (var j = 0; j < item.parcelas.length; j++) {
                        if (item.parcelas[j].parcela == data[i].PARCELA) {
                          encontrado = true;
                          break;
                        }
                      }
                      if (!encontrado) {
                        item.parcelas.push({parcela: data[i].PARCELA});
                      }
                    } else {
                      item = {
                        correlativo: correlativo,
                        parcelas: [
                          {parcela: data[i].PARCELA}
                        ]
                      };
                      rodales.push(item);
                    }
                  }
                }
              }

              $scope.opts.columnDefs = [];
              headerNames.forEach(function (h) {
                if (h == "NOMBRE_CIENTIFICO") {
                  $scope.opts.columnDefs.push({ field: h, width: '20%' });
                } else {
                  if (parseInt(origenCalculoId) == 2) {
                    if (h != "VOLUMEN" && h != "AREA_BASAL") {
                      $scope.opts.columnDefs.push({ field: h});
                    }
                  } else {
                    $scope.opts.columnDefs.push({ field: h});
                  }
                }
                i++;
              });
              if (parseInt(origenCalculoId) == 2) {
                if (formaParcelaId == 4) {
                  $scope.opts.columnDefs.push({ field: "DAP_MEDIO"});
                } else {
                  $scope.opts.columnDefs.push({ field: "VOLUMEN"});
                  $scope.opts.columnDefs.push({ field: "AREA_BASAL"});
                }
              }
              if (esVerificacion) {
                $scope.opts.columnDefs.push({ field: "VOLUMEN_VERIFICADO"});
                $scope.opts.columnDefs.push({ field: "AREA_BASAL_VERIFICADO"});
              }
              /*variable que almacena los datos de la ultima especie
                y evitar la busqueda por cada registro
              */
              var tmp = { nombreCientifico : "-----", ecuacion : null }
              var totalAfeccion = 0;
              var mensajeAlerta = '';
              for (i = 0; i < data.length; i++) {
                data[i].AREA_RODAL = parseFloat(data[i].AREA_RODAL).toFixed(4);
                var obj = data[i];
                obj.NOMBRE_CIENTIFICO = obj.NOMBRE_CIENTIFICO.trim();
                if (obj.NOMBRE_CIENTIFICO == undefined) {
                  swal('Problemas con la información', 'Revise que el archivo a subir contenga el formato adecuado');
                  $scope.opts.data = [];
                  return;
                }
                if (obj.DAP < 10) {
                  swal('Diámetro no válido para inventario', 'El árbol ' + obj.NO + ' en el rodal ' + obj.RODAL + ' contiene un diámetro menor a 10 cm.');
                  return;
                }
                if (obj.NO == undefined || isNaN(obj.NO)) {
                  swal('Correlativo no válido para inventario', 'Debe enumerar los árboles de cada rodal o parcela, asegúrese de no repetir la numeración en un rodal o parcela');
                  return;
                }
                obj.DAP = parseFloat(obj.DAP).toFixed(2);
                obj.ALTURA = parseFloat(obj.ALTURA).toFixed(2);
                if (isNaN(obj.ALTURA)) {
                  swal('Dato incorrecto', 'La altura del árbol ' + obj.NO + ' en el rodal ' + obj.RODAL + ' no es válido, favor de revisar.');
                  return;
                }
                obj.TURNO = parseInt(obj.TURNO);
                if (isNaN(obj.TURNO)) {
                  swal('Dato incorrecto', 'Los turnos deben ser numéricos.');
                  return;
                }
                obj.TURNO = parseInt(obj.TURNO);
                if (isNaN(obj.RODAL)) {
                  swal('Dato incorrecto', 'Los rodales deben ser numéricos.');
                  return;
                }
                if (obj.ALTURA < 5 || obj.ALTURA > 35) {
                  if (mensajeAlerta.length > 0) {
                    mensajeAlerta += ', No. ' + obj.NO + ' rodal ' + obj.RODAL;
                  } else {
                    mensajeAlerta = 'No. ' + obj.NO + ' rodal ' + obj.RODAL;
                  }
                  if (obj.PARCELA != null) {
                    mensajeAlerta += ' parcela ' + obj.PARCELA;
                  }
                }
                if (tipoGestionId == 2 || tipoGestionId == 8) {
                  if (obj.FASE_GORGOJO == null) {
                    obj.FASE_GORGOJO = 0;
                  }
                  if (obj.AFECCION_AGENTE == null) {
                    obj.AFECCION_AGENTE = -1;
                  }
                  if (obj.FASE_GORGOJO > 0 || obj.AFECCION_AGENTE > -1) {
                    totalAfeccion++;
                  }
                }
                if (formaParcelaId == 4 && tipoInventarioId != 1) { //tamaño variable y que no sea censo
                  //asignar marca de clase a cada registro
                  for (j = 0; j < dataClaseDiametrica.length; j++) {
                    var clase = dataClaseDiametrica[j];
                    if (obj.DAP >= clase.minimo && obj.DAP <= clase.maximo) {
                      obj.DAP_MEDIO = clase.marcaClase;
                      break;
                    }
                  }
                }
                obj.NO = parseInt(obj.NO);
                obj.RODAL = parseInt(obj.RODAL);
                if (obj.PARCELA != null) {
                  obj.PARCELA = parseInt(obj.PARCELA);
                  if (parseInt(obj.PARCELA) <= 0) {
                    swal('Problemas con la información', 'El número de parcela no debe ser menor a cero, si su inventario es censo use la boleta correspondiente y si es ambos en censo debe dejar en blanco');
                    $scope.opts.data = [];
                    return;
                  }
                  if (obj.TAMANIO_PARCELA == null) {
                    obj.TAMANIO_PARCELA = 0;
                  } else {
                    obj.TAMANIO_PARCELA = parseInt(obj.TAMANIO_PARCELA);
                    if (parseInt(obj.TAMANIO_PARCELA) <= 0) {
                      swal('Problemas con la información', 'El tamaño de la parcela no debe ser menor a cero, si su inventario es censo use la boleta correspondiente y si es ambos en censo debe dejar en blanco');
                      $scope.opts.data = [];
                      return;
                    }
                  }
                }
                if (obj.CALIDAD_FUSTE == undefined) {
                  obj.CALIDAD_FUSTE = 1;
                } else {
                  if (obj.CALIDAD_FUSTE == null) {
                    obj.CALIDAD_FUSTE = 1;
                  }
                }
                if (isNaN(obj.CALIDAD_FUSTE)) {
                  swal('Problemas con la información', 'Para la calidad de fuste, solo debe ingresar el código o no agregar información');
                  $scope.opts.data = [];
                  return;
                }
                //si la especie anterior es diferente a la del registro actual leido, se hace una nueva busqueda dentro del catalogo
                if (tmp.nombreCientifico.toLowerCase().localeCompare(obj.NOMBRE_CIENTIFICO.toLowerCase()) != 0) {
                  //se verifica que el correlativo no se repita en el rodal
                  var mismoNumero = 0;
                  let ultimoNumero = '-';
                  if (isNaN(obj.PARCELA) || isNaN(parseInt(obj.PARCELA))) {
                    for (j = 0; j < data.length; j++) {
                      var objValidar = data[j];
                      if (isNaN(objValidar.PARCELA) || isNaN(parseInt(objValidar.PARCELA))) {
                        if (obj.NO == objValidar.NO && obj.RODAL == objValidar.RODAL) {
                          mismoNumero++;
                          ultimoNumero = 'Rodal: ' + obj.RODAL + ' - No.: ' + obj.NO;
                        }
                        if (mismoNumero > 1) {
                          break;
                        }
                      }
                    }
                  } else {
                    if (tipoInventarioId == 1) { //si selecciono censo
                      swal('Datos inválidos', 'Ha seleccionado censo y en la boleta se ha detectado parcela(s), debe cambiar a muestreo (puede ser también ambas) o no llenar las columnas NO_PARCELA y TAMANIO_PARCELA. O utilizar la boleta de censo');
                      $scope.opts.data = [];
                      return;
                    }
                  }
                  if (mismoNumero > 1) {
                    swal('Correlativos de árboles inválidos', 'No se puede repetir el número de árbol (' + ultimoNumero + ') en el mismo rodal');
                    $scope.opts.data = [];
                    return;
                  }
                  tmp = formula(obj);
                  if (tmp == null) {
                    var strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + '. La especie ' + obj.NOMBRE_CIENTIFICO + ' no se encuentra registrada, favor de verificar el nombre en la opción "Catágolos -> Especie" o comuníquese al INAB';
                    if (obj.PARCELA != null) {
                      strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + ' y parcela ' + obj.PARCELA + '. La especie ' + obj.NOMBRE_CIENTIFICO + ' no se encuentra registrada, favor de verificar el nombre en la opción "Catágolos -> Especie" o comuníquese al INAB';
                    }
                    swal('Problemas con la información', strMensaje);
                    $scope.opts.data = [];
                    return;
                  }
                  if (parseInt(origenCalculoId) == 1 && tmp.aceptaFormula == 0) {
                    swal('Problemas con la información', 'La especie ' + tmp.nombreCientifico + ' tiene fórmula establecida, elija la opción de "Sistema realiza cálculos" para poder continuar');
                    $scope.opts.data = [];
                    return;
                  }
                  tmp.variable = JSON.parse(tmp.formula);
                  tmp.ecuacionOrig = tmp.ecuacion;
                  tmp.areaBasalOrig = "((DAP/100) * (DAP/100)) * 0.7854";
                }
                var value = 0;
                if (parseInt(origenCalculoId) == 2) {
                  //sistema realiza calculos
                  tmp.ecuacion = tmp.ecuacionOrig;
                  tmp.areaBasal = tmp.areaBasalOrig;
                  if (formaParcelaId == 4) {
                    for (var k = 0; k < 5; k++) {
                      tmp.ecuacion = tmp.ecuacion.replace('DAP', 'VAR_' + k);
                    }
                    for (var k = 0; k < 5; k++) {
                      tmp.ecuacion = tmp.ecuacion.replace('VAR_' + k, 'DAP_MEDIO');
                    }
                    if (compensaPendienteId == 1) {
                      data[i].AB_CORREGIDO = parseFloat(data[i].FAB).toFixed(4);
                    } else {
                      value = parseFloat(data[i].PORCENTAJE_PENDIENTE) / 100;
                      value = Math.atan(value);
                      value = Math.cos(value);
                      value = parseFloat(data[i].FAB) / value;
                      data[i].AB_CORREGIDO = parseFloat(value).toFixed(4);
                    }
                  }
                  for (j = 0; j < tmp.variable.length; j++) {
                    var variableOrg = tmp.variable[j].variable.toUpperCase();
                    var variable = variableOrg;
                    if (variableOrg == 'DAP' && formaParcelaId == 4) {
                      variable = 'DAP_MEDIO';
                    }
                    value = valor(obj, variable);
                    var valueOrg = valor(obj, variableOrg);
                    for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                      tmp.ecuacion = tmp.ecuacion.replace(variable, value);
                      tmp.areaBasal = tmp.areaBasal.replace(variableOrg, valueOrg);
                    }
                  }
                  try {
                    value = operacion(tmp.ecuacion);
                  } catch (e) {
                    swal('Problemas con la información', 'No es posible procesar el árbol número: ' + obj.NO + ', rodal: ' + obj.RODAL + (obj.PARCELA == null ? '' : ', parcela: ' + obj.PARCELA) + ', favor de verificar. La fórmula de la especie no está bien definida ' + e.message);
                    $scope.opts.data = [];
                    return;
                  }
                  if (formaParcelaId == 4) { //tamaño variable relascopia
                    data[i].TAMANIO_PARCELA = 10000;
                    data[i].VOLUMEN_IND = parseFloat(value).toFixed(4);
                    value = operacion(tmp.areaBasal);
                    value = data[i].AB_CORREGIDO / value;
                    data[i].ARBOLES_HA = parseFloat(value).toFixed(4);
                    value = data[i].VOLUMEN_IND * data[i].ARBOLES_HA;
                    data[i].VOL_HA = parseFloat(value).toFixed(4);
                    for (j = 0; j < rodales.length; j++) {
                      if (rodales[j].correlativo == data[i].RODAL) {
                        var size = rodales[j].parcelas.length;
                        value = (data[i].AREA_RODAL * data[i].ARBOLES_HA);
                        data[i].ARBOLES_RODAL = parseFloat(value).toFixed(4);
                        value = (data[i].AREA_RODAL * data[i].AB_CORREGIDO);
                        data[i].AB_RODAL = parseFloat(value).toFixed(4);
                        value = (data[i].AREA_RODAL * data[i].VOL_HA);
                        data[i].VOLUMEN_RODAL = parseFloat(value).toFixed(4);
                        value = data[i].ARBOLES_HA / size;
                        data[i].ARBOLES_HA_RES = parseFloat(value).toFixed(4);
                        value = data[i].AB_CORREGIDO / size;
                        data[i].AB_HA_RES = parseFloat(value).toFixed(4);
                        value = data[i].VOL_HA / size;
                        data[i].VOL_HA_RES = parseFloat(value).toFixed(4);
                        break;
                      }
                    }
                  } else {
                    data[i].VOLUMEN = parseFloat(value).toFixed(4);
                    value = operacion(tmp.areaBasal);
                    data[i].AREA_BASAL = parseFloat(value).toFixed(4);
                  }
                } else {
                  data[i].VOLUMEN = parseFloat(data[i].VOLUMEN).toFixed(4);
                  data[i].AREA_BASAL = parseFloat(data[i].AREA_BASAL).toFixed(4);
                }
                data[i].ESPECIE_ID = tmp.especieId;
                data[i].CODIGO = tmp.codigo;
                if (esVerificacion) {
                  obj.DAP_VERIFICADO = parseFloat(obj.DAP_VERIFICADO).toFixed(2);
                  obj.ALTURA_VERIFICADO = parseFloat(obj.ALTURA_VERIFICADO).toFixed(2);
                  if (parseInt(origenCalculoId) == 2) {
                    tmp.ecuacion = tmp.ecuacionOrig;
                    tmp.areaBasal = tmp.areaBasalOrig;
                    value = valor(obj, "DAP_VERIFICADO");
                    for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                      tmp.ecuacion = tmp.ecuacion.replace("DAP", value);
                      tmp.areaBasal = tmp.areaBasal.replace("DAP", value);
                    }
                    value = valor(obj, "ALTURA_VERIFICADO");
                    for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                      tmp.ecuacion = tmp.ecuacion.replace("ALTURA", value);
                    }
                    try {
                      value = operacion(tmp.ecuacion);
                      data[i].VOLUMEN_VERIFICADO = parseFloat(value).toFixed(4);
                      value = operacion(tmp.areaBasal);
                      data[i].AREA_BASAL_VERIFICADO = parseFloat(value).toFixed(4);
                    } catch (e) {
                      swal('Problemas con la información', 'No es posible procesar el árbol número: ' + obj.NO + ', favor de verificar. ' + e.message);
                      $scope.opts.data = [];
                      return;
                    }
                  } else {
                    data[i].VOLUMEN_VERIFICADO = parseFloat(data[i].VOLUMEN_VERIFICADO).toFixed(4);
                    data[i].AREA_BASAL_VERIFICADO = parseFloat(data[i].AREA_BASAL_VERIFICADO).toFixed(4);
                  }
                }
              }
              if (tipoGestionId == 2 || tipoGestionId == 8) {
                if (totalAfeccion <= 0) {
                  swal('Problemas con la información', 'Debe indicar la fase del gorgojo o la afección del agente donde corresponda');
                  $scope.opts.data = [];
                  return;
                }
              }
              $scope.opts.data = data;
              if (!esVerificacion) {
                if (mensajeAlerta.length > 0) {
                  swal('Las alturas de los siguientes números de árboles no son lógicos o proporcionales a su diámetro, favor de revisar', mensajeAlerta);
                } else {
                  swal('Archivo procesado con éxito', 'Si seleccionó que el sistema realiza cálculos y no es muestreo con parcelas de tamaño variable, verifique que el volumen y área basal estén correctamente');
                }
              }
            } catch (e) {
              toastr.error('Ocurrió un error al procesar: ' + e.message + '. Verifique que el formato sea el correcto, que las filas después de la información no contenta espacios en blanco');
            } finally {
              $elm.val(null);
            }
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}])
.directive("ngFileJson", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            var encontrarEspecie = function (json) {
              for (var i = 0; i < dataEspecie.length; i++) {
                if (dataEspecie[i].nombreCientifico.toLowerCase().localeCompare(json.NOMBRE_CIENTIFICO.toLowerCase()) == 0) {
                  return angular.copy(dataEspecie[i]);
                }
              }
              return null;
            }
            var data = evt.target.result;
            var workbook = XLSX.read(data, {type: 'binary'});
            var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
            var dataEspecie = authService.getLocalData('dataEspecie');
            var tmp = {
              nombreCientifico: "-----"
            };
            var i;
            for (i = 0; i < data.length; i++) {
              var obj = data[i];
              obj.AREA_RODAL = parseFloat(obj.AREA_RODAL).toFixed(4);
              obj.TAMANIO_PARCELA = parseFloat(obj.TAMANIO_PARCELA).toFixed(4);
              if (isNaN(obj.NO_RODAL)) {
                toastr.error('Correlativo inválido', 'El número de rodal es incorrecto');
                return;
              } else {
                if (parseInt(obj.NO_RODAL) < 1) {
                  toastr.error('Correlativo inválido', 'El número de rodal no puede ser menor a uno');
                }
              }
              //si la especie anterior es diferente a la del registro actual leido, se hace una nueva busqueda dentro del catalogo
              if (tmp.nombreCientifico.toLowerCase().localeCompare(obj.NOMBRE_CIENTIFICO.toLowerCase()) != 0) {
                tmp = encontrarEspecie(obj);
                if (tmp == null) {
                  toastr.error('Especie no encontrada', 'No es posible procesar la especie número: ' + obj.NO + ' de la parcela ' + obj.NO_PARCELA + ', rodal ' + obj.NO_RODAL + '. La especie indicada no se encuentra registrada, favor de verificar el nombre o comuníquese al INAB');
                  return;
                }
              }
              data[i].ESPECIE_ID = parseInt(tmp.especieId);
              data[i].CODIGO = tmp.codigo;
            }
            $scope.onFileRead(data, changeEvent.target.id);
            $elm.val(null);
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}])
.directive("onlyFileRead", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    scope: {
      opts: '='
    },
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            $scope.opts.data = [];
            var dataEspecie = authService.getLocalData('dataEspecie');
            //funcion que returna el valor de la propiedad del objeto json
            var valor = function ( json, columna ) {
              for (var key in json) {
                 if (key == columna) return json[key];
              }
              return null;
            }

            //funcion que realiza una operacion matematica a partir de una cadena
            var operacion = function (ecuacion) {
              return new Function('return ' + ecuacion)();
            }

            //devuelve la formula de la especie a partir del nombre científico
            var formula = function (json) {
              var __nombreCientifico = json.NOMBRE_CIENTIFICO.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
              for (var i = 0; i < dataEspecie.length; i++) {
                var _nombreCientifico = dataEspecie[i].nombreCientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                if (_nombreCientifico.localeCompare(__nombreCientifico) == 0) {
                  return angular.copy(dataEspecie[i]);
                }
              }
              return null;
            }

            try {
              var data = evt.target.result;
              var workbook = XLSX.read(data, {type: 'binary'});
              var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
              var i = 0;
              $scope.opts.columnDefs = [];
              headerNames.forEach(function (h) {
                if (h == "NOMBRE_CIENTIFICO") {
                  $scope.opts.columnDefs.push({ field: h, width: '20%' });
                } else {
                  $scope.opts.columnDefs.push({ field: h});
                }
                i++;
              });
              var tmp = { nombreCientifico : "-----", ecuacion : null }
              for (i = 0; i < data.length; i++) {
                var obj = data[i];
                obj.NOMBRE_CIENTIFICO = obj.NOMBRE_CIENTIFICO.trim();
                if (obj.NOMBRE_CIENTIFICO == undefined) {
                  swal('Problemas con la información', 'Revise que el archivo a subir contenga el formato adecuado');
                  $scope.opts.data = [];
                  return;
                }
                if (obj.DAP < 10) {
                  swal('Diámetro no válido para inventario', 'El árbol ' + obj.NO + ' en el rodal ' + obj.RODAL + ' contiene un diámetro menor a 10 cm.');
                  return;
                }
                if (obj.NO == undefined || isNaN(obj.NO)) {
                  swal('Correlativo no válido para inventario', 'Debe enumerar los árboles de cada rodal o parcela, asegúrese de no repetir la numeración en un rodal o parcela');
                  return;
                }
                obj.DAP = parseFloat(obj.DAP).toFixed(2);
                obj.ALTURA = parseFloat(obj.ALTURA).toFixed(2);
                obj.NO = parseInt(obj.NO);
                obj.RODAL = parseInt(obj.RODAL);
                if (obj.PARCELA != null) {
                  obj.PARCELA = parseInt(obj.PARCELA);
                  if (obj.TAMANIO_PARCELA == null) {
                    obj.TAMANIO_PARCELA = 0;
                  } else {
                    obj.TAMANIO_PARCELA = parseInt(obj.TAMANIO_PARCELA);
                  }
                }
                if (obj.CLASE == undefined || isNaN(obj.CLASE)) {
                  swal('Código de clase no válido para inventario', 'Debe indicar la clase de los árboles de cada rodal o parcela');
                  return;
                }
                //si la especie anterior es diferente a la del registro actual leido, se hace una nueva busqueda dentro del catalogo
                if (tmp.nombreCientifico.toLowerCase().localeCompare(obj.NOMBRE_CIENTIFICO.toLowerCase()) != 0) {
                  tmp = formula(obj);
                  if (tmp == null) {
                    var strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + '. La especie indicada no se encuentra registrada, favor de verificar el nombre o comuníquese al INAB';
                    if (obj.PARCELA != null) {
                      strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + ' y parcela ' + obj.PARCELA + '. La especie indicada no se encuentra registrada, favor de verificar el nombre o comuníquese al INAB';
                    }
                    swal('Problemas con la información', strMensaje);
                    $scope.opts.data = [];
                    return;
                  }
                }
                data[i].ESPECIE_ID = tmp.especieId;
                data[i].CODIGO = tmp.codigo;
              }
              $scope.opts.data = data;
              swal('Archivo procesado con éxito', 'Presione guardar para continuar');
            } catch (e) {
              toastr.error('Ocurrió un error al procesar: ' + e.message + '. Verifique que el formato sea el correcto');
            } finally {
              $elm.val(null);
            }
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}])
.directive("boletaExento", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    scope: {
      opts: '='
    },
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            toastr.info('Se ha iniciado la lectura del archivo, por favor espere a que finalice. No vuelva a seleccionar el archivo');
            $scope.opts.data = [];
            var tipoInventarioId = null;
            var dataEspecie = authService.getLocalData('dataEspecie');
            //funcion que returna el valor de la propiedad del objeto json
            var valor = function ( json, columna ) {
              for (var key in json) {
                 if (key == columna) return json[key];
              }
              return null;
            }

            //funcion que realiza una operacion matematica a partir de una cadena
            var operacion = function (ecuacion) {
              return new Function('return ' + ecuacion)();
            }

            //devuelve la formula de la especie a partir del nombre científico
            var formula = function (json) {
              for (var i = 0; i < dataEspecie.length; i++) {
                if (dataEspecie[i].nombreCientifico.trim().toLowerCase().localeCompare(json.NOMBRE_CIENTIFICO.toLowerCase()) == 0) {
                  return angular.copy(dataEspecie[i]);
                }
              }
              return null;
            }

            try {
              var data = evt.target.result;
              var workbook = XLSX.read(data, {type: 'binary'});
              var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
              var i = 0, j = 0;
              $scope.opts.columnDefs = [];
              let sistemaRealiza = true;
              let tituloEnlinea = false;
              headerNames.forEach(function (h) {
                if (h == "NOMBRE_CIENTIFICO") {
                  $scope.opts.columnDefs.push({ field: h, width: '20%' });
                } else {
                  if (h == "VOLUMEN") {
                    sistemaRealiza = false;
                  }
                  if (h == "EN_LINEA") {
                    tituloEnlinea = true;
                  }
                  $scope.opts.columnDefs.push({ field: h});
                }
                i++;
              });
              if (sistemaRealiza) {
                $scope.opts.columnDefs.push({ field: "VOLUMEN"});
                $scope.opts.columnDefs.push({ field: "AREA_BASAL"});
              }
              $scope.opts.columnDefs.push({ field: "CALCULADO"});
              if (!tituloEnlinea) {
                $scope.opts.columnDefs.push({ field: "EN_LINEA"});
              }
              /*variable que almacena los datos de la ultima especie
                y evitar la busqueda por cada registro
              */
              var tmp = { nombreCientifico : "-----", ecuacion : null }
              for (i = 0; i < data.length; i++) {
                var obj = data[i];
                if (obj.NOMBRE_CIENTIFICO == undefined || obj.NOMBRE_CIENTIFICO == null) {
                  swal('Problemas con la información', 'Revise que el archivo a subir contenga el formato adecuado, no se ha encontrado el nombre científico para la fila ' + (i + 1) + ', no es posible procesar');
                  $scope.opts.data = [];
                  return;
                }
                obj.NOMBRE_CIENTIFICO = obj.NOMBRE_CIENTIFICO.trim();
                if (obj.NO == undefined || isNaN(obj.NO)) {
                  swal('Correlativo no válido para inventario', 'Debe enumerar los árboles de cada rodal o parcela, asegúrese de no repetir la numeración en un rodal o parcela');
                  return;
                }
                obj.DAP = parseFloat(obj.DAP).toFixed(2);
                obj.ALTURA = parseFloat(obj.ALTURA).toFixed(2);
                if (isNaN(obj.ALTURA)) {
                  swal('Dato incorrecto', 'La altura del árbol ' + obj.NO + ' en el rodal ' + obj.RODAL + ' no es válido, favor de revisar.');
                  return;
                }
                obj.EN_LINEA = obj.EN_LINEA == null ? 0 : obj.EN_LINEA;
                obj.NO = parseInt(obj.NO);
                obj.RODAL = parseInt(obj.RODAL);
                if (obj.PARCELA != null) {
                  obj.PARCELA = parseInt(obj.PARCELA);
                  if (obj.TAMANIO_PARCELA == null) {
                    obj.TAMANIO_PARCELA = 0;
                  } else {
                    obj.TAMANIO_PARCELA = parseInt(obj.TAMANIO_PARCELA);
                  }
                  if (obj.EN_LINEA == 1) {
                    swal('La línea ' + obj.RODAL + ' no puede ser muestreo. Para las líneas solo está permitido censo');
                    return;
                  }
                }
                //si la especie anterior es diferente a la del registro actual leido, se hace una nueva busqueda dentro del catalogo
                if (tmp.nombreCientifico.toLowerCase().localeCompare(obj.NOMBRE_CIENTIFICO.toLowerCase()) != 0) {
                  tmp = formula(obj);
                  if (tmp == null) {
                    var strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + '. La especie indicada no se encuentra registrada, favor de verificar el nombre o comuníquese al INAB';
                    if (obj.PARCELA != null) {
                      strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + ' del rodal: ' + obj.RODAL + ' y parcela ' + obj.PARCELA + '. La especie indicada no se encuentra registrada, favor de verificar el nombre o comuníquese al INAB';
                    }
                    swal('Problemas con la información', strMensaje);
                    $scope.opts.data = [];
                    return;
                  }
                  tmp.variable = JSON.parse(tmp.formula);
                  tmp.ecuacionOrig = tmp.ecuacion;
                  tmp.areaBasalOrig = "((DAP/100) * (DAP/100)) * 0.7854";
                }
                var value = 0;
                tmp.ecuacion = tmp.ecuacionOrig;
                tmp.areaBasal = tmp.areaBasalOrig;
                for (j = 0; j < tmp.variable.length; j++) {
                  var variableOrg = tmp.variable[j].variable;
                  var variable = variableOrg;
                  value = valor(obj, variable);
                  var valueOrg = valor(obj, variableOrg);
                  for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                    tmp.ecuacion = tmp.ecuacion.replace(variable, value);
                    tmp.areaBasal = tmp.areaBasal.replace(variableOrg, valueOrg);
                  }
                }
                try {
                  if (sistemaRealiza) {
                    value = operacion(tmp.ecuacion);
                  }
                } catch (e) {
                  swal('Problemas con la información', 'No es posible procesar el árbol número: ' + obj.NO + ', favor de verificar. La fórmula de la especie no está bien definida ' + e.message);
                  $scope.opts.data = [];
                  return;
                }
                if (sistemaRealiza) {
                  data[i].VOLUMEN = parseFloat(value).toFixed(4);
                  value = operacion(tmp.areaBasal);
                  data[i].AREA_BASAL = parseFloat(value).toFixed(4);
                }
                data[i].ESPECIE_ID = tmp.especieId;
                data[i].CODIGO = tmp.codigo;
                data[i].CALCULADO = sistemaRealiza ? 0 : 1;
              }
              $scope.opts.data = data;
              toastr.success('Se ha finalizado la lectura del archivo del archivo');
            } catch (e) {
              toastr.error('Ocurrió un error al procesar: ' + e.message + '. Verifique que el formato sea el correcto');
            } finally {
              $elm.val(null);
            }
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}])
.directive("cargaExcel", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    scope: {
      opts: '='
    },
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            $scope.opts.data = [];
            try {
              var data = evt.target.result;
              var workbook = XLSX.read(data, {type: 'binary'});
              var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
              var i = 0, j = 0;
              $scope.opts.columnDefs = [];
              headerNames.forEach(function (h) {
                $scope.opts.columnDefs.push({ field: h});
              });
              for (i = 0; i < data.length; i++) {
                var obj = data[i];
                if (obj.GTM_X == undefined || isNaN(obj.GTM_X)) {
                  swal('Problemas con la información', 'Revise que el archivo a subir contenga el formato adecuado, no se ha encontrado la coordenada X');
                  $scope.opts.data = [];
                  return;
                }
                if (obj.GTM_Y == undefined || isNaN(obj.GTM_Y)) {
                  swal('Problemas con la información', 'Revise que el archivo a subir contenga el formato adecuado, no se ha encontrado la coordenada Y');
                  $scope.opts.data = [];
                  return;
                }
                if (obj.NO == undefined || isNaN(obj.NO)) {
                  swal('Correlativo no válido', 'Debe enumerar las coordenadas, asegúrese de no repetir la numeración');
                  return;
                }
              }
              $scope.opts.data = data;
            } catch (e) {
              toastr.error('Ocurrió un error al procesar: ' + e.message + '. Verifique que el formato sea el correcto');
            } finally {
              $elm.val(null);
            }
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}])
.directive("fileIlicita", ['authService', 'toastr', function ( authService, toastr ) {
  return {
    scope: {
      opts: '='
    },
    link: function ($scope, $elm, $attrs) {
      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function () {
            $scope.opts.data = [];
            try {
              var data = evt.target.result;
              var workbook = XLSX.read(data, {type: 'binary'});
              var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
              var i = 0, j = 0;
              var dataEspecie = authService.getLocalData('dataEspecieIlicita');
              if (dataEspecie == null) {
                swal('¡Ooops!', 'Es necesario indicar la región, favor de revisar', 'error');
                return;
              }
              //funcion que returna el valor de la propiedad del objeto json
              var valor = function ( json, columna ) {
                for (var key in json) {
                  if (key == columna) return json[key];
                }
                return null;
              }

              //funcion que realiza una operacion matematica a partir de una cadena
              var operacion = function (ecuacion) {
                return new Function('return ' + ecuacion)();
              }

              //devuelve la formula de la especie a partir del nombre científico
              var obtenerFormula = function (json) {
                var __nombreCientifico = json.nombre_cientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                var ncev = json.nombre_cientifico.trim().toLowerCase();
                var lncev = ncev.length;
                var i;
                for (i = 0; i < dataEspecie.length; i++) {
                  var _nombreCientifico = dataEspecie[i].nombreCientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                  if (_nombreCientifico.localeCompare(__nombreCientifico) == 0) {
                    return angular.copy(dataEspecie[i]);
                  }
                  var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                  if (ncev.localeCompare(nce) == 0) {
                    return angular.copy(dataEspecie[i]);
                  }
                }
                //compare character per character
                while (ncev.indexOf(' ') > -1) {
                  ncev = ncev.replace(' ', '_');
                  ncev = ncev.replace('á', '_');
                  ncev = ncev.replace('é', '_');
                  ncev = ncev.replace('í', '_');
                  ncev = ncev.replace('ó', '_');
                  ncev = ncev.replace('ú', '_');
                }
                for (i = 0; i < dataEspecie.length; i++) {
                  var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                  var lnce = nce.length;
                  if (lncev == lnce) {
                    while (nce.indexOf(' ') > -1) {
                      nce = nce.replace(' ', '_');
                      nce = nce.replace('á', '_');
                      nce = nce.replace('é', '_');
                      nce = nce.replace('í', '_');
                      nce = nce.replace('ó', '_');
                      nce = nce.replace('ú', '_');
                    }
                    var siEs = true;
                    for (var j = 0; j < lncev; j++) {
                      if (ncev[j] != nce[j]) {
                        siEs = false;
                        break;
                      }
                    }
                    if (siEs) {
                      return angular.copy(dataEspecie[i]);
                    }
                  }
                }
                return null;
              }
            
              $scope.opts.columnDefs = [];
              headerNames.forEach(function (h) {
                if (h == "nombre_cientifico") {
                  $scope.opts.columnDefs.push({ field: h, width: '20%' });
                } else {
                  $scope.opts.columnDefs.push({ field: h});
                }
                i++;
              });
              $scope.opts.columnDefs.push({ field: 'dap'});
              $scope.opts.columnDefs.push({ field: 'altura'});
              $scope.opts.columnDefs.push({ field: 'volumen'});
              var tmp = { nombreCientifico : "-----", ecuacion : null };
              var mensajeAlerta = '';
              for (i = 0; i < data.length; i++) {
                var obj = data[i];
                if (obj.no == undefined || isNaN(obj.no)) {
                  swal('Correlativo no válido', 'Debe enumerar los árboles, asegúrese de no repetir la numeración');
                  return;
                }
                if (obj.dat == undefined || isNaN(obj.dat)) {
                  swal('Problemas con la información', 'Debe indicar el diámetro a la altura del tocón (DAT) para el árbol ' + obj.NO);
                  $scope.opts.data = [];
                  return;
                }
                if (obj.nombre_cientifico == undefined || obj.nombre_cientifico == null) {
                  swal('Problemas con la información', 'Debe indicar el nómbre científico al árbol ' + obj.NO);
                  $scope.opts.data = [];
                  return;
                }
                if (tmp.nombreCientifico.toLowerCase().localeCompare(obj.nombre_cientifico.toLowerCase()) != 0) {
                  tmp = obtenerFormula(obj);
                  if (tmp == null) {
                    var strMensaje = 'No es posible procesar el árbol número: ' + obj.NO + '. La especie ' + obj.nombre_cientifico + ' no se encuentra registrada, favor de verificar el nombre en la opción "Catágolos -> Especie" o comuníquese al INAB';
                    swal('Problemas con la información', strMensaje);
                    $scope.opts.data = [];
                    return;
                  }
                }
                var formula = angular.copy(tmp.formula);
                var variable = JSON.parse(tmp.variable);
                for (var j = 0; j < 5; j++) {
                  for (var k = 0; k < variable.length; k++) {
                    var row = variable[k];
                    formula = formula.replace(row.variable, obj.dat);
                  }
                }
                var altura = new Function('return ' + formula)();
                obj.altura = parseFloat(altura).toFixed(2);
                formula = angular.copy(tmp.formulaDap);
                variable = JSON.parse(tmp.variableDap);
                for (var j = 0; j < 5; j++) {
                  for (var k = 0; k < variable.length; k++) {
                    var row = variable[k];
                    formula = formula.replace(row.variable, obj.dat);
                  }
                }
                var dap = new Function('return ' + formula)();
                obj.dap = parseFloat(dap).toFixed(2);
                formula = tmp.tcEspecie.ecuacion;
                var variables = JSON.parse(angular.copy(tmp.tcEspecie.formula))
                for (j = 0; j < variables.length; j++) {
                  variable = variables[j].variable;
                  value = valor(obj, variable.toLowerCase());
                  for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                    formula = formula.replace(variable, value);
                  }
                }
                var volumen = new Function('return ' + formula)();
                obj.volumen = parseFloat(volumen).toFixed(4);
                obj.especieId = tmp.especieId;
              }
              $scope.opts.data = data;
            } catch (e) {
              toastr.error('Ocurrió un error al procesar: ' + e.message + '. Verifique que el formato sea el correcto');
            } finally {
              $elm.val(null);
            }
          });
        };
        reader.readAsBinaryString(changeEvent.target.files[0]);
      });
    }
  }
}]);
