angular.module('app.consumo', [
    'ui.router',
    'toastr',
    'app.consumo.service'
])

    .config(
        ['$stateProvider', '$urlRouterProvider',
            function ($stateProvider, $urlRouterProvider) {
                $stateProvider
                    .state('index.consumo', {
                        abstract: true,
                        url: 'consumo',
                        template: '<div ui-view></div>',
                        resolve: {
                        },
                        controller: ['$scope', 'consumoService', 'utilsService', 'toastr',
                            function ($scope, consumoService, utilsService, toastr) {
                                $scope.module = 'Consumo Familiar';
                                $scope.imprimirSolicitud = function (row) {
                                    consumoService.get(row.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            var ttConsumo = res.data;
                                            utilsService.solicitudConsumoFamiliar(ttConsumo, $scope.loginData.usuario, $scope.dataEmpresa);
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.abrirAnexo = function (anexoConsumoId) {
                                    consumoService.getAnexoSolicitud(anexoConsumoId);
                                }

                            }]
                    })
                    .state('index.consumo.lista', {
                        url: '',
                        templateUrl: 'app/consumoFamiliar/consumo.list.tpl.html',
                        resolve: {
                            dataConsumoFamiliar: ['consumoService', 'authService', '$filter', 'tareaService',
                                function (consumoService, authService, $filter, tareaService) {
                                    var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                                    return consumoService.listaPorPersona(loginData.personaId, -1);
                                }]
                        },
                        controller: ['$scope', '$state', 'dataConsumoFamiliar', 'consumoService', '$filter', 'utilsService', 'tareaService', 'toastr',
                            'ngDialog', 'personaService', '$base64',
                            function ($scope, $state, dataConsumoFamiliar, consumoService, $filter, utilsService, tareaService, toastr,
                                ngDialog, personaService, $base64) {

                                $scope.etiquetaEstado = function (estadoId) {
                                    switch (estadoId) {
                                        case 0: return 'Anulado';
                                        case 1: return 'Elaborando';
                                        case 2: return 'Solicitado';
                                        case 3: return 'En trámite';
                                        case 4: return 'Finalizado';
                                    }
                                }

                                $scope.etiquetaAprobado = function (item) {
                                    if (item.estadoId == 4) {
                                        return item.aprobado ? 'Si' : 'No';
                                    }
                                    return '---';
                                }

                                $scope.habilitarEditar = function (estadoId) {
                                    if (estadoId == 1) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.habilitarSolicitud = function (row) {
                                    if (row.pasoId > 4) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.habilitarNotas = function (estadoId) {
                                    if (estadoId == 4) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptions.columnDefs = [
                                    { field: 'expediente', name: 'expediente', displayName: 'Expediente' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                                    { field: 'resolucion', name: 'resolucion', displayName: 'Resolución' },
                                    { name: 'aprobado', displayName: "Aprobado", field: 'aprobado', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaAprobado(row.entity)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarSolicitud(row.entity)" ng-click="grid.appScope.imprimirSolicitud(row.entity)" title="Imprimir solicitud"> <i class="zmdi zmdi-print text-info"></i> </button> '
                                            + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.habilitarEditar(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button>'
                                            + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarNotas(row.entity.estadoId)" ng-click="grid.appScope.imprimirResolucion(row.entity)" title="Imprimir resolución"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> </button>'
                                            + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarNotas(row.entity.estadoId)" ng-click="grid.appScope.solicitarNotas(row.entity)" title="Solicitar notas de envío"> <i class="fa fa-sticky-note-o text-red" aria-hidden="true"></i> </button>'
                                            + '</span></div>'
                                    }
                                ];

                                $scope.gridOptions.data = dataConsumoFamiliar.data;

                                $scope.data = {
                                    estadoId: -1,
                                    personaId: $scope.loginData.personaId
                                }

                                $scope.editRow = function (row) {
                                    $state.go('^.edit', { consumoId: $base64.encode(row.consumoId) });
                                }

                                $scope.agregarSolicitud = function () {
                                    swal({
                                        title: "Confirmación",
                                        text: "¿Está seguro (a) de agregar una nueva solicitud de Consumo Familiar?",
                                        icon: "warning",
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonClass: "btn-success",
                                        cancelButtonClass: "btn-danger",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                var item = {
                                                    tcPersonaCrea: {
                                                        personaId: $scope.loginData.personaId
                                                    }
                                                }
                                                consumoService.agregarPropio(item).then(function (res) {
                                                    if (res.status == 1) {
                                                        item = res.data;
                                                        $state.go('^.edit', { consumoId: $base64.encode(item.consumoId) });
                                                    } else {
                                                        toastr.error(res.message);
                                                    }
                                                });
                                            }
                                        });
                                }

                                $scope.enviarDocumento = function (item) {
                                    if (item.estadoId < 3) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de enviar la solicitud para el Consumo Familiar " + item.codigo + ", a las oficinas de la subregión " + item.tcSubregion.subregionDesc + " - " + item.tcSubregion.alias + " - INAB? \nEsta acción no se podrá reversar",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    consumoService.solicitarCargaInicial(item).then(function (res) {
                                                        if (res.status == "OK") {
                                                            item.estadoId = 3;
                                                            toastr.success('La solicitud fue enviada a la subregión ' + item.tcSubregion.subregionDesc + ' - ' + item.tcSubregion.alias + '. Pronto se comunicarán por éste medio sobre el trámite', 'Solicitud enviada con éxito');
                                                        } else {
                                                            toastr.error(res.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    }
                                }

                                $scope.imprimirResolucion = function (item) {
                                    tareaService.getResolucionConsumo(item.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            var ttTarea = res.data;
                                            var ttPadre = ttTarea.ttPadre;
                                            if (ttPadre.observaciones != null) {
                                                try {
                                                    ttPadre.observaciones = JSON.parse(ttPadre.observaciones);
                                                } catch (error) { }
                                            } else {
                                                ttPadre.observaciones = {};
                                            }
                                            ttTarea.ttPadre = ttPadre;
                                            var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                                            var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                                            var strDia = $scope.numeroALetras(dia, false);
                                            var strAnio = $scope.numeroALetras(anio, false);
                                            utilsService.resolucionConsumoFamiliar(ttTarea, strDia, strAnio, $scope.loginData.usuario, true, $scope.dataEmpresa, null);
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.solicitarNotas = function (item) {
                                    $scope.globales.ttConsumo = item;
                                    $state.go('^.solicitarnota', { consumoId: $base64.encode(item.consumoId) });
                                }

                            }]
                    })
                    .state('index.consumo.edit', {
                        url: '/:consumoId/edit',
                        templateUrl: 'app/consumoFamiliar/consumo.edit.tpl.html',
                        resolve: {
                            dataConsumo: ['consumoService', '$base64', '$stateParams',
                                function (consumoService, $base64, $stateParams) {
                                    return consumoService.get($base64.decode($stateParams.consumoId));
                                }],
                            dataEstado: ['consumoService',
                                function (consumoService) {
                                    return consumoService.listStatus();
                                }],
                            dataTipoPropiedad: ['tipopropiedadService',
                                function (tipopropiedadService) {
                                    return tipopropiedadService.listaActivaConsumo();
                                }],
                            dataPais: ['paisService',
                                function (paisService) {
                                    return paisService.listaActiva().then(function (data) {
                                        return data;
                                    });
                                }],
                            dataMunicipio: ['municipioService', 'dataConsumo',
                                function (municipioService, dataConsumo) {
                                    var data = dataConsumo.data.tcSolicitante;
                                    if (data.tcMunicipio != null) {
                                        return municipioService.listaPorDepartamento(data.tcMunicipio.tcDepartamento.departamentoId).then(function (data) {
                                            return data;
                                        });
                                    } else {
                                        return { data: [] }
                                    }
                                }],
                            dataDepartamento: ['departamentoService', 'dataConsumo',
                                function (departamentoService, dataConsumo) {
                                    var data = dataConsumo.data.tcSolicitante;
                                    if (data.tcMunicipio != null) {
                                        return departamentoService.listaPorPais(data.tcMunicipio.tcDepartamento.tcPais.paisId).then(function (data) {
                                            return data;
                                        });
                                    } else {
                                        return { data: [] }
                                    }
                                }],
                            dataTipoDocumento: ['tipodocumentoService',
                                function (tipodocumentoService) {
                                    return tipodocumentoService.listaActiva();
                                }],
                            dataOcupacion: ['ocupacionService',
                                function (ocupacionService) {
                                    return ocupacionService.listaActiva();
                                }],
                            dataCultura: ['culturaService',
                                function (culturaService) {
                                    return culturaService.listaActiva().then(function (data) {
                                        return data;
                                    });
                                }],
                            dataIdioma: ['idiomaService',
                                function (idiomaService) {
                                    return idiomaService.listaActiva().then(function (data) {
                                        return data;
                                    });
                                }],
                            dataEstadoCivil: ['estadocivilService',
                                function (estadocivilService) {
                                    return estadocivilService.listaActiva().then(function (data) {
                                        return data;
                                    });
                                }],
                            dataParentesco: ['parentescoService',
                                function (parentescoService) {
                                    return parentescoService.listaActiva().then(function (data) {
                                        return data;
                                    });
                                }],
                            dataLibro: ['libroService',
                                function (libroService) {
                                    return libroService.listaActiva();
                                }]
                        },
                        controller: ['$scope', '$state', 'toastr', 'dataConsumo', 'consumoService', 'dataPais', 'dataTipoDocumento', 'dataMunicipio',
                            'dataDepartamento', 'municipioService', 'departamentoService', 'personaService', 'dataEstado', 'dataTipoPropiedad',
                            'ngDialog', 'catalogosService', 'Upload', 'personaService', 'dataOcupacion', 'dataParentesco', 'dataLibro',
                            'dataCultura', 'dataIdioma', 'dataEstadoCivil', 'utilsService',
                            function ($scope, $state, toastr, dataConsumo, consumoService, dataPais, dataTipoDocumento, dataMunicipio,
                                dataDepartamento, municipioService, departamentoService, personaService, dataEstado, dataTipoPropiedad,
                                ngDialog, catalogosService, Upload, personaService, dataOcupacion, dataParentesco, dataLibro,
                                dataCultura, dataIdioma, dataEstadoCivil, utilsService) {

                                $scope.dataUsoProducto = [
                                    { usoProductoId: 1, usoProductoDesc: 'Combustible' },
                                    { usoProductoId: 2, usoProductoDesc: 'Construcción de vivienda' },
                                    { usoProductoId: 3, usoProductoDesc: 'Combustible y construcción' }
                                ];

                                $scope.dataProductoExtraer = [
                                    { productoId: 1, productoDesc: 'Troza' },
                                    { productoId: 2, productoDesc: 'Leña' },
                                    { productoId: 3, productoDesc: 'Troza y leña' }
                                ];

                                $scope.dataConsumo = {
                                    estadoId: 1,
                                    tcTipoPropietario: {},
                                    tcSolicitante: {},
                                    beneficiados: [],
                                    especiesSolicitadas: [],
                                    especies: [],
                                    productoId: 0,
                                    usoProductoId: 0
                                };

                                $scope.mostrarEnviar = false;
                                $scope.mostrarDireccion = false;

                                $scope.dataSexo = [
                                    { sexoId: 1, sexoDesc: 'Femenino' },
                                    { sexoId: 2, sexoDesc: 'Masculino' }
                                ];
                                $scope.dataTipoDocumento = dataTipoDocumento.data;
                                $scope.dataPais = dataPais.data;
                                $scope.dataMunicipio = dataMunicipio.data;
                                $scope.dataDepartamento = dataDepartamento.data;
                                $scope.dataOcupacion = dataOcupacion.data;
                                $scope.dataEstadoCivil = dataEstadoCivil.data;
                                $scope.dataCultura = dataCultura.data;
                                $scope.dataIdioma = dataIdioma.data;
                                $scope.dataConsumo = dataConsumo.data;
                                $scope.dataParentesco = dataParentesco.data;
                                $scope.dataLibro = dataLibro.data;
                                $scope.textoOpcion = 'Enviar';
                                if ($scope.dataConsumo.estadoId == 2) {
                                    $scope.textoOpcion = 'Recibir';
                                }
                                $scope.municipalidad = {
                                    tcDepartamento: {},
                                    tcMunicipio: {},
                                    tcPerfil: {},
                                    archivoNombre: 'Nombramiento o archivo que lo respalde'
                                };
                                if ($scope.dataConsumo.tcSolicitante.fechaNacimiento != null) {
                                    $scope.dataConsumo.tcSolicitante.fechaNacimiento = new Date($scope.dataConsumo.tcSolicitante.fechaNacimiento);
                                }
                                if ($scope.dataConsumo.productoId == 0) {
                                    $scope.dataConsumo.productoId = null;
                                }
                                if ($scope.dataConsumo.usoProductoId == 0) {
                                    $scope.dataConsumo.usoProductoId = null;
                                }
                                if ($scope.dataConsumo.especiesSolicitadas == null) {
                                    $scope.dataConsumo.especiesSolicitadas = [];
                                }
                                if ($scope.dataConsumo.especies == null) {
                                    $scope.dataConsumo.especies = [];
                                }
                                if ($scope.dataConsumo.anexos == null) {
                                    $scope.dataConsumo.anexos = [];
                                }
                                $scope.titulo = 'Solicitud de Consumo Familiar';
                                $scope.tabActive = {
                                    cero: false,
                                    uno: false,
                                    dos: false,
                                    tres: false,
                                    cuatro: false,
                                    cinco: false
                                }

                                $scope.tabShow = {
                                    cero: true,
                                    uno: true,
                                    dos: false,
                                    tres: false,
                                    cuatro: false,
                                    cinco: false
                                }

                                switch ($scope.dataConsumo.pasoId) {
                                    case 0:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = false;
                                        $scope.tabShow.dos = false;
                                        $scope.tabShow.tres = false;
                                        $scope.tabShow.cuatro = false;
                                        $scope.tabActive.cinco = false;
                                        $scope.tabActive.cero = true;
                                        break;
                                    case 1:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = true;
                                        $scope.tabShow.dos = false;
                                        $scope.tabShow.tres = false;
                                        $scope.tabShow.cuatro = false;
                                        $scope.tabActive.cinco = false;
                                        $scope.tabActive.uno = true;
                                        break;
                                    case 2:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = true;
                                        $scope.tabShow.dos = true;
                                        $scope.tabShow.tres = false;
                                        $scope.tabShow.cuatro = false;
                                        $scope.tabActive.cinco = false;
                                        $scope.tabActive.dos = true;
                                        break;
                                    case 3:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = true;
                                        $scope.tabShow.dos = true;
                                        $scope.tabShow.tres = true;
                                        $scope.tabShow.cuatro = false;
                                        $scope.tabActive.cinco = false;
                                        $scope.tabActive.tres = true;
                                        break;
                                    case 4:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = true;
                                        $scope.tabShow.dos = true;
                                        $scope.tabShow.tres = true;
                                        $scope.tabShow.cuatro = true;
                                        $scope.tabActive.cuatro = true;
                                        $scope.tabActive.cinco = false;
                                        break;
                                    case 5:
                                        $scope.tabShow.cero = true;
                                        $scope.tabShow.uno = true;
                                        $scope.tabShow.dos = true;
                                        $scope.tabShow.tres = true;
                                        $scope.tabShow.cuatro = true;
                                        $scope.tabShow.cinco = true;
                                        $scope.tabActive.cinco = true;
                                        break;
                                }

                                $scope.modificarSolicitante = false;

                                if ($scope.loginData.perfilId == 4 || $scope.loginData.perfilId == 28) {
                                    $scope.modificarSolicitante = true;
                                }

                                swal('Requisitos necesarios para realizar la solicitud', 'Escaner del documento de ampara la propiedad\nEscaner del Documento Personal de Identificación (DPI)', 'warning');

                                $scope.dataEstado = dataEstado.data;
                                $scope.dataTipoPropiedad = dataTipoPropiedad.data;

                                $scope.direccion = {
                                    paisId: $scope.dataConsumo.tcSolicitante.tcMunicipio.tcDepartamento.tcPais.paisId,
                                    departamentoId: $scope.dataConsumo.tcSolicitante.tcMunicipio.tcDepartamento.departamentoId
                                };

                                for (var i = 0; i < $scope.dataConsumo.anexos.length; i++) {
                                    var item = $scope.dataConsumo.anexos[i];
                                    if (item.nombre == null) {
                                        item.visibleAbrir = false;
                                        item.texto = 'Cargar';
                                        item.classButton = "btn btn-danger";
                                    } else {
                                        item.classButton = "btn btn-primary";
                                        item.visibleAbrir = true;
                                        item.texto = 'Cambiar';
                                    }
                                }

                                $scope.activarEnviar = function () {
                                    var mostrarEnviar = true;
                                    for (var i = 0; i < $scope.dataConsumo.anexos.length; i++) {
                                        var item = $scope.dataConsumo.anexos[i];
                                        if (!item.visibleAbrir) {
                                            mostrarEnviar = false;
                                            break;
                                        }
                                    }
                                    $scope.mostrarEnviar = mostrarEnviar;
                                }

                                $scope.activarEnviar();

                                $scope.cargarListaDepartamento = function (item, tipo) {
                                    $scope.direccion.departamentoId = null;
                                    $scope.dataConsumo.tcSolicitante.municipioId = null;
                                    departamentoService.listaPorPais(item.paisId).then(function (res) {
                                        if (res.status == "OK") {
                                            $scope.dataDepartamento = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.cargarListaMunicipio = function (item, tipo, row, clear) {
                                    if (clear == null) {
                                        clear = true;
                                    }
                                    if (tipo == null) {
                                        tipo = 1;
                                    }
                                    if (clear) {
                                        switch (tipo) {
                                            case 1:
                                                $scope.dataConsumo.tcSolicitante.tcMunicipio.municipioId = null;
                                                break;
                                            case 2:
                                                if (row.tcFinca == null) {
                                                    row.tcFinca = {}
                                                }
                                                if (row.tcFinca.tcMunicipio == null) {
                                                    row.tcFinca.tcMunicipio = {}
                                                }
                                                row.tcFinca.tcMunicipio.municipioId = null;
                                                break;
                                            case 3:
                                                if (row.tcMunicipioEmite == null) {
                                                    row.tcMunicipioEmite = {}
                                                }
                                                row.tcMunicipioEmite.municipioId = null;
                                                break;
                                            case 4:
                                                if (row.tcMunicipio == null) {
                                                    row.tcMunicipio = {}
                                                }
                                                row.tcMunicipio.municipioId = null;
                                                break;
                                        }
                                    }
                                    municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                        if (res.status == "OK") {
                                            switch (tipo) {
                                                case 1:
                                                    $scope.dataMunicipio = res.data;
                                                    break;
                                                case 2:
                                                    row.dataMunicipio = res.data;
                                                    $scope.dataConsumo.dataMunicipio = res.data;
                                                    break;
                                                case 3:
                                                    row.dataMunicipioEmite = res.data;
                                                    break;
                                                case 4:
                                                    row.dataMunicipio = res.data;
                                                    break;
                                            }
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.getTituloDocumento = function (finca) {
                                    if (finca == null) {
                                        return;
                                    }
                                    if (finca.tcTipoPropiedad != null) {
                                        if (finca.tcTipoPropiedad.tipoPropiedadId == 1) {
                                            return "Número de escritura";
                                        }
                                    }
                                    return "No. de finca";
                                }

                                if ($scope.dataConsumo.ttFincaConsumo != null) {
                                    if ($scope.dataConsumo.ttFincaConsumo.fechaEmision != null) {
                                        $scope.dataConsumo.ttFincaConsumo.fechaEmision = new Date($scope.dataConsumo.ttFincaConsumo.fechaEmision);
                                    }
                                    if ($scope.dataConsumo.ttFincaConsumo.tcMunicipioEmite != null) {
                                        if ($scope.dataConsumo.ttFincaConsumo.tcMunicipioEmite.municipioId > 0) {
                                            $scope.cargarListaMunicipio($scope.dataConsumo.ttFincaConsumo.tcMunicipioEmite.tcDepartamento, 3, $scope.dataConsumo.ttFincaConsumo, false)
                                        }
                                    }
                                    if ($scope.dataConsumo.ttFincaConsumo.tcFinca != null) {
                                        if ($scope.dataConsumo.ttFincaConsumo.tcFinca != null) {
                                            if ($scope.dataConsumo.ttFincaConsumo.tcFinca.tcMunicipio != null) {
                                                if ($scope.dataConsumo.ttFincaConsumo.tcFinca.tcMunicipio.municipioId > 0) {
                                                    $scope.dataConsumo.ttFincaConsumo.tcFinca.tcDepartamento = $scope.dataConsumo.ttFincaConsumo.tcFinca.tcMunicipio.tcDepartamento;
                                                    $scope.cargarListaMunicipio($scope.dataConsumo.ttFincaConsumo.tcFinca.tcMunicipio.tcDepartamento, 2, $scope.dataConsumo.ttFincaConsumo, false)
                                                }
                                            }
                                        }
                                    }
                                }

                                if ($scope.dataConsumo.notificaciones != null) {
                                    var notificaciones = $scope.dataConsumo.notificaciones;
                                    for (var i = 0; i < notificaciones.length; i++) {
                                        var notificacion = notificaciones[i];
                                        if (notificacion.tcTipoNotificacion.esDireccion == 1) {
                                            notificacion.tcDepartamento = notificacion.tcMunicipio.tcDepartamento;
                                            $scope.cargarListaMunicipio(notificacion.tcMunicipio.tcDepartamento, 4, notificacion, false);
                                        }
                                    }
                                }

                                $scope.submitFormDatos = function (isValid) {
                                    if (!$scope.modificarSolicitante) {
                                        return;
                                    }
                                    if (isValid) {
                                        var tcSolicitante = angular.copy($scope.dataConsumo.tcSolicitante);
                                        tcSolicitante.personaDesc = $scope.capitalizeText(tcSolicitante.personaDesc);
                                        tcSolicitante.direccion = $scope.capitalizeText(tcSolicitante.direccion);
                                        personaService.edit(tcSolicitante).then(function (response) {
                                            if (response.status == 'OK') {
                                                toastr.success(response.message);
                                            } else {
                                                toastr.error(response.message);
                                            }
                                        }, function (error) {
                                            toastr.error(error);
                                        });
                                    }
                                }

                                $scope.submitFormNotificacion = function (isValid) {
                                    if (isValid) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de guardar los datos de notificación(es)?",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    var ttConsumo = angular.copy($scope.dataConsumo);
                                                    if (ttConsumo.ttFincaConsumo != null) {
                                                        delete ttConsumo.ttFincaConsumo;
                                                    }
                                                    if (ttConsumo.especies != null) {
                                                        delete ttConsumo.especies;
                                                    }
                                                    if (ttConsumo.beneficiados != null) {
                                                        delete ttConsumo.beneficiados;
                                                    }
                                                    consumoService.agregarNotificaciones(ttConsumo).then(function (response) {
                                                        if (response.status == 1) {
                                                            toastr.success(response.message);
                                                            $scope.tabActive.dos = true;
                                                            $scope.tabShow.dos = true;
                                                        } else {
                                                            toastr.error(response.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    }
                                }

                                $scope.submitFormBeneficiado = function (isValid) {
                                    if (isValid) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de guardar los datos de beneficiado(s)?",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    var ttConsumo = angular.copy($scope.dataConsumo);
                                                    if (ttConsumo.ttFincaConsumo != null) {
                                                        delete ttConsumo.ttFincaConsumo;
                                                    }
                                                    if (ttConsumo.especies != null) {
                                                        delete ttConsumo.especies;
                                                    }
                                                    if (ttConsumo.notificaciones != null) {
                                                        delete ttConsumo.notificaciones;
                                                    }
                                                    consumoService.agregarBeneficiados(ttConsumo).then(function (response) {
                                                        if (response.status == 1) {
                                                            toastr.success(response.message);
                                                            $scope.tabActive.tres = true;
                                                            $scope.tabShow.tres = true;
                                                        } else {
                                                            toastr.error(response.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    }
                                }

                                $scope.setTipoPropiedadFinca = function ( item ) {
                                    $scope.tcTipoPropiedad = item;
                                }

                                $scope.submitFormFinca = function (isValid) {
                                    if (isValid) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de guardar los datos del terreno?",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    var ttConsumo = angular.copy($scope.dataConsumo);
                                                    if (ttConsumo.beneficiados != null) {
                                                        delete ttConsumo.beneficiados;
                                                    }
                                                    if (ttConsumo.especies != null) {
                                                        delete ttConsumo.especies;
                                                    }
                                                    if (ttConsumo.ttFincaConsumo.tcTipoPropiedad.tipoPropiedadId == 4) {
                                                        if (ttConsumo.ttFincaConsumo.tcFinca.tcMunicipio.municipioId != ttConsumo.ttFincaConsumo.tcMunicipioEmite.municipioId) {
                                                            toastr.error('El municipio del terreno no coincide con el documento de propiedad, no es posible continuar');
                                                            return;
                                                        }
                                                    }
                                                    consumoService.agregarFinca(ttConsumo).then(function (response) {
                                                        if (response.status == 1) {
                                                            toastr.success(response.message);
                                                            $scope.tabActive.cuatro = true;
                                                            $scope.tabShow.cuatro = true;
                                                        } else {
                                                            toastr.error(response.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    }
                                }

                                $scope.submitFormProducto = function (isValid) {
                                    if ($scope.dataConsumo.especiesSolicitadas.length <= 0) {
                                        swal('Problemas con la información', 'Debe de indicar al menos una especie');
                                        return;
                                    }
                                    if (isValid) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro(a) de guardar la información?",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    var ttConsumo = angular.copy($scope.dataConsumo);
                                                    if (ttConsumo.ttFincaConsumo != null) {
                                                        delete ttConsumo.ttFincaConsumo;
                                                    }
                                                    if (ttConsumo.beneficiados != null) {
                                                        delete ttConsumo.beneficiados;
                                                    }
                                                    consumoService.agregarProducto(ttConsumo).then(function (response) {
                                                        if (response.status == 1) {
                                                            toastr.success(response.message);
                                                            $scope.tabActive.cinco = true;
                                                            $scope.tabShow.cinco = true;
                                                            $scope.dataConsumo.pasoId = 5;
                                                        } else {
                                                            toastr.error(response.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    } else {
                                        toastr.error('Todos los campos son obligatorios');
                                    }
                                }

                                $scope.cancelarAgregar = function () {
                                    if ($scope.loginData.perfilId == 4 || $scope.loginData.perfilId == 28) {
                                        $state.go('^.recibir');
                                    } else {
                                        $state.go('^.lista');
                                    }
                                }

                                $scope.agregarBeneficiado = function () {
                                    if ($scope.dataConsumo.beneficiados == null) {
                                        $scope.dataConsumo.beneficiados = [];
                                    }
                                    $scope.dataConsumo.beneficiados.push({});
                                }

                                $scope.quitarBeneficiado = function (item) {
                                    $scope.dataConsumo.beneficiados.splice($scope.dataConsumo.beneficiados.indexOf(item), 1);
                                }

                                $scope.agregarEspecieSolicitud = function () {
                                    if ($scope.dataConsumo.especiesSolicitadas == null) {
                                        $scope.dataConsumo.especiesSolicitadas = [];
                                    }
                                    $scope.dataConsumo.especiesSolicitadas.push({});
                                }

                                $scope.quitarEspecieSolicitud = function (item) {
                                    $scope.dataConsumo.especiesSolicitadas.splice($scope.dataConsumo.especiesSolicitadas.indexOf(item), 1);
                                }

                                $scope.onFileSelect = function (file, requisitoId) {
                                    Upload.upload({
                                        url: appSettings.urlServiceBase + 'file/upload',
                                        method: 'POST',
                                        file: file,
                                        sendFieldsAs: 'form',
                                        fields: {
                                            data: []
                                        }
                                    }).then(function (resp) {
                                        var result = resp.data;
                                        if (result.status == 'OK') {
                                            var item = result.data[0];
                                            var anexos = $scope.dataConsumo.anexos;
                                            var ttAnexoConsumo = null;
                                            for (var i = 0; i < anexos.length; i++) {
                                                var anexo = anexos[i];
                                                if (anexo.tcRequisito.requisitoId == requisitoId) {
                                                    ttAnexoConsumo = angular.copy(anexo);
                                                    break;
                                                }
                                            }
                                            ttAnexoConsumo.ruta = item.rutaArchivo;
                                            ttAnexoConsumo.nombre = item.nombre;
                                            ttAnexoConsumo.size = item.size;
                                            ttAnexoConsumo.extension = item.extension;
                                            consumoService.agregarAnexoSolicitud(ttAnexoConsumo).then(function (response) {
                                                if (response.status == 1) {
                                                    toastr.success(response.message);
                                                    ttAnexoConsumo.classButton = "btn btn-primary";
                                                    ttAnexoConsumo.visibleAbrir = true;
                                                    ttAnexoConsumo.texto = 'Cambiar';
                                                    for (var i = 0; i < anexos.length; i++) {
                                                        var anexo = anexos[i];
                                                        if (ttAnexoConsumo.anexoConsumoId == anexo.anexoConsumoId) {
                                                            anexo = ttAnexoConsumo;
                                                            $scope.dataConsumo.anexos[i].classButton = "btn btn-primary";
                                                            $scope.dataConsumo.anexos[i].visibleAbrir = true;
                                                            $scope.dataConsumo.anexos[i].texto = 'Cambiar';
                                                            break;
                                                        }
                                                    }
                                                    $scope.dataConsumo.anexos = anexos;
                                                    $scope.activarEnviar();
                                                } else {
                                                    toastr.error(response.message);
                                                }
                                            }, function (error) {
                                                toastr.error(error);
                                            });
                                        } else {
                                            toastr.error(resp.data.message);
                                        }
                                    }, function (resp) {
                                        toastr.error('Error status: ' + resp.status);
                                    }, function (evt) {
                                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                        $scope.avance = progressPercentage;
                                    });
                                }

                                $scope.submitFormAgregar = function () {
                                    if ($scope.approved) {
                                        if ($scope.data.rutaArchivo == null) {
                                            toastr.error('Debe adjuntar el archivo de estimación volumétrica');
                                            return;
                                        }
                                    }
                                    if ($scope.data.propietarios.length > 0 || $scope.data.representantes.length > 0) {
                                        consumoService.add($scope.data).then(function (res) {
                                            if (res.status == "OK") {
                                                toastr.success(res.message);
                                                $state.go('^.listar');
                                            } else {
                                                toastr.error(res.message);
                                            }
                                        }, function (error) {
                                            toastr.error(error);
                                        });
                                    } else {
                                        toastr.error('Debe de existir al menos un propietario o representante legal, si no le aparece es probable que el problema esté en el registro');
                                    }
                                }

                                $scope.imprimirSolicitud = function () {
                                    consumoService.get($scope.dataConsumo.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            var ttConsumo = res.data;
                                            utilsService.solicitudConsumoFamiliar(ttConsumo, $scope.loginData.usuario, $scope.dataEmpresa);
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.enviarSolicitud = function () {
                                    var enviar = 'enviar';
                                    if ($scope.dataConsumo.estadoId == 2) {
                                        enviar = 'recibir';
                                    }
                                    swal({
                                        title: "Confirmación",
                                        text: '¿Está seguro(a) de ' + enviar + ' la solicitud?\nEsta acción no se podrá reversar',
                                        icon: "warning",
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonClass: "btn-success",
                                        cancelButtonClass: "btn-danger",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                var ttConsumo = angular.copy($scope.dataConsumo);
                                                consumoService.enviarSolicitud(ttConsumo.consumoId).then(function (response) {
                                                    if (response.status == 1) {
                                                        toastr.success(response.message);
                                                        if ($scope.loginData.perfilId == 4 || $scope.loginData.perfilId == 28) {
                                                            $state.go('^.recibir');
                                                        } else {
                                                            $state.go('^.lista');
                                                        }
                                                    } else {
                                                        toastr.error(response.message);
                                                    }
                                                }, function (error) {
                                                    toastr.error(error);
                                                });
                                            }
                                        });
                                }

                            }]
                    })
                    .state('index.consumo.recibir', {
                        url: '/recibir',
                        templateUrl: 'app/consumoFamiliar/consumo.list.tpl.html',
                        resolve: {
                            dataConsumoFamiliar: ['consumoService', '$filter',
                                function (consumoService, $filter) {
                                    var begin = new Date();
                                    var now = new Date();
                                    begin.setDate(now.getDate() - 30);
                                    var fechaIni = $filter('date')(begin, 'yyyy-MM-dd');
                                    var fechaFin = $filter('date')(now, 'yyyy-MM-dd');
                                    var estadoId = 1;
                                    return consumoService.listaPorEntidad(estadoId, fechaIni, fechaFin);
                                }
                            ],
                            dataDepartamento: ['departamentoService',
                                function (departamentoService) {
                                    return departamentoService.listaActiva();
                                }
                            ]
                        },
                        controller: ['$scope', '$state', 'dataConsumoFamiliar', 'consumoService', '$filter', 'utilsService', 'dataDepartamento', 'toastr',
                            'ngDialog', '$base64',
                            function ($scope, $state, dataConsumoFamiliar, consumoService, $filter, utilsService, dataDepartamento, toastr,
                                ngDialog, $base64) {

                                $scope.dataDepartamento = dataDepartamento.data;
                                $scope.mostrarNota = false;
                                if ($scope.loginData.perfilId == 4 || $scope.loginData.perfilId == 28
                                    || $scope.loginData.perfilId == 35) {
                                    $scope.mostrarNota = true;
                                }

                                $scope.dataEstado = [
                                    { estadoId: 1, estadoDesc: 'Elaborando' },
                                    { estadoId: 2, estadoDesc: 'Solicitado' },
                                    { estadoId: 3, estadoDesc: 'En trámite' },
                                    { estadoId: 4, estadoDesc: 'Finalizado' },
                                    { estadoId: -1, estadoDesc: 'Todos' }
                                ];

                                $scope.etiquetaEstado = function (estadoId) {
                                    switch (estadoId) {
                                        case -1: return 'Todos';
                                        case 1: return 'Elaborando';
                                        case 2: return 'Solicitado';
                                        case 3: return 'En trámite';
                                        case 4: return 'Finalizado';
                                    }
                                }

                                $scope.habilitarEditar = function (estadoId) {
                                    if (estadoId == 1 || estadoId == 2) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.habilitarSolicitud = function (row) {
                                    if (row.pasoId > 4) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.getTextoOpcion = function (estadoId) {
                                    if (estadoId == 1) {
                                        return 'Editar';
                                    }
                                    return 'Recibir';
                                }

                                $scope.mostrarCaratula = function (estadoId) {
                                    if (estadoId == 3 || estadoId == 4) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptions.columnDefs = [
                                    { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    { field: 'tcSolicitante.personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                                    { field: 'expediente', name: 'expediente', displayName: 'Expediente' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + ' <button type="button" class="btn btn-info btn-xs" ng-show="grid.appScope.habilitarSolicitud(row.entity)" ng-click="grid.appScope.imprimirSolicitud(row.entity)" title="Imprimir solicitud"> <i class="zmdi zmdi-print"></i> </button> '
                                            + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.habilitarSolicitud(row.entity)" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                                            + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarCaratula(row.entity.estadoId)" ng-click="grid.appScope.verCaratula(row.entity)" title="Ver caratula"> <i class="fa fa-file-pdf-o"></i> </button> '
                                            + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.habilitarEditar(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="{{grid.appScope.getTextoOpcion(row.entity.estadoId)}} solicitud">{{grid.appScope.getTextoOpcion(row.entity.estadoId)}}</button></span></div>'
                                    }
                                ];

                                $scope.gridOptions.data = dataConsumoFamiliar.data;

                                $scope.data = {
                                    estadoId: -1,
                                    personaId: $scope.loginData.personaId
                                }

                                $scope.editRow = function (row) {
                                    $state.go('^.edit', { consumoId: $base64.encode(row.consumoId) });
                                }

                                $scope.verAnexos = function (item) {
                                    ngDialog.open({
                                        template: 'app/consumoFamiliar/anexo.consumo.tpl.html',
                                        className: 'ngdialog-theme-flat',
                                        appendClassName: 'ngdialog-flat',
                                        closeByDocument: false,
                                        closeByEscape: true,
                                        closeByNavigation: true,
                                        scope: $scope,
                                        id: 'uniqueEdit',
                                        name: 'dialogEditar',
                                        resolve: {
                                            dataAnexo: ['consumoService',
                                                function (consumoService) {
                                                    return consumoService.getListaAnexoSolicitud(item.consumoId);
                                                }]
                                        },
                                        controller: ['$scope', '$state', 'toastr', 'dataAnexo',
                                            function ($scope, $state, toastr, dataAnexo) {

                                                $scope.dataAnexo = dataAnexo.data;

                                            }]
                                    });
                                }

                                var now = new Date();
                                var begin = $scope.addDays(now, -30);

                                $scope.mostrarFiltro = true;

                                $scope.data = {
                                    fechaIni: begin,
                                    fechaFin: now,
                                    estadoId: 1
                                }

                                $scope.submitFormFiltro = function () {
                                    var data = $scope.data;
                                    var fechaIni = $filter('date')(data.fechaIni, 'yyyy-MM-dd');
                                    var fechaFin = $filter('date')(data.fechaFin, 'yyyy-MM-dd');
                                    consumoService.listaPorEntidad(data.estadoId, fechaIni, fechaFin).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptions.data = res.data;
                                            if (res.data.length <= 0) {
                                                toastr.warning('No se encontraron registros');
                                            }
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.verCaratula = function (item) {
                                    consumoService.get(item.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            utilsService.constanciaRecepcionConsumo(res.data, $scope.loginData.usuario, $scope.dataEmpresa);
                                            utilsService.generarCaratulaConsumo(res.data, $scope.loginData.usuario, $scope.dataEmpresa);
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.agregarSolicitudNota = function () {
                                    $state.go('^.nota');
                                }

                                $scope.agregarSolicitud = function () {
                                    $scope.tituloPersona = "Agregar datos de la persona";
                                    $scope.oldCui = null;
                                    ngDialog.open({
                                        template: 'app/consumoFamiliar/consumo.add.tpl.html',
                                        className: 'ngdialog-theme-flat',
                                        appendClassName: 'ngdialog-flat',
                                        closeByDocument: false,
                                        closeByEscape: true,
                                        closeByNavigation: true,
                                        scope: $scope,
                                        id: 'uniqueEdit',
                                        name: 'dialogEditar',
                                        resolve: {
                                            dataOcupacion: ['ocupacionService',
                                                function (ocupacionService) {
                                                    return ocupacionService.listaActiva();
                                                }],
                                            dataIdioma: ['idiomaService', '$stateParams',
                                                function (idiomaService, $stateParams) {
                                                    return idiomaService.listaActiva();
                                                }],
                                            dataCultura: ['culturaService', '$stateParams',
                                                function (culturaService, $stateParams) {
                                                    return culturaService.listaActiva();
                                                }],
                                            dataEstadoCivil: ['estadocivilService', '$stateParams',
                                                function (estadocivilService, $stateParams) {
                                                    return estadocivilService.listaActiva();
                                                }]
                                        },
                                        controller: ['$scope', '$state', 'toastr', 'personaService', 'municipioService', '$filter', 'utilsService', 'dataOcupacion',
                                            'dataIdioma', 'dataCultura', 'dataEstadoCivil',
                                            function ($scope, $state, toastr, personaService, municipioService, $filter, utilsService, dataOcupacion,
                                                dataIdioma, dataCultura, dataEstadoCivil) {
                                                $scope.dataOcupacion = dataOcupacion.data;
                                                $scope.dataIdioma = dataIdioma.data;
                                                $scope.dataCultura = dataCultura.data;
                                                $scope.dataEstadoCivil = dataEstadoCivil.data;
                                                $scope.dataSexo = [
                                                    { sexoId: 1, sexoDesc: 'Femenino' },
                                                    { sexoId: 2, sexoDesc: 'Masculino' }
                                                ];

                                                $scope.dataPersona = {
                                                    estadoId: 1,
                                                    personaId: 0
                                                }

                                                $scope.direccion = {
                                                    paisId: 1
                                                }

                                                $scope.cargarListaMunicipio = function (item) {
                                                    $scope.dataPersona.municipioId = null;
                                                    municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                                        if (res.status == "OK") {
                                                            $scope.dataMunicipio = res.data;
                                                        } else {
                                                            toastr.error(res.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }

                                                $scope.buscarDPI = function () {
                                                    if ($scope.dataPersona.cui == null) {
                                                        return;
                                                    }
                                                    if ($scope.dataPersona.cui.length < 13) {
                                                        toastr.error('Error en información', 'Debe indicar un CUI (DPI) válido');
                                                        $scope.dataPersona.cui = null;
                                                        return;
                                                    }
                                                    if ($scope.oldCui == $scope.dataPersona.cui) {
                                                        return;
                                                    }
                                                    personaService.buscarCui($scope.dataPersona.cui).then(function (res) {
                                                        if (res.status == "OK") {
                                                            var data = res.data[0];
                                                            $scope.dataPersona.telefono = data.telefono;
                                                            $scope.dataPersona.correo = data.correo;
                                                            $scope.dataPersona.direccion = data.direccion;
                                                            $scope.dataPersona.personaDesc = data.personaDesc;
                                                            $scope.dataPersona.nit = data.nit;
                                                            $scope.direccion.departamentoId = data.tcMunicipio.tcDepartamento.departamentoId;
                                                            $scope.cargarListaMunicipio(data.tcMunicipio.tcDepartamento);
                                                            $scope.dataPersona.tcMunicipio = data.tcMunicipio;
                                                            $scope.dataPersona.fechaNacimiento = new Date(data.fechaNacimiento);
                                                            $scope.dataPersona.tcOcupacion = data.tcOcupacion;
                                                            $scope.dataPersona.tcCultura = data.tcCultura;
                                                            $scope.dataPersona.tcIdioma = data.tcIdioma;
                                                            $scope.dataPersona.tcEstadoCivil = data.tcEstadoCivil;
                                                            $scope.dataPersona.tcSexo = data.tcSexo;
                                                            $scope.oldCui = data.cui;
                                                            $scope.dataPersona.personaId = data.personaId;
                                                        } else {
                                                            $scope.dataPersona.personaId = 0;
                                                            toastr.error(res.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    }
                                                    );
                                                }

                                                $scope.submitFormPersona = function (isValid) {
                                                    if (isValid) {
                                                        var tcSolicitante = $scope.dataPersona;
                                                        if (tcSolicitante.correo != null) {
                                                            if (tcSolicitante.correo.length > 0) {
                                                                var dominio = tcSolicitante.correo.substring(tcSolicitante.correo.indexOf('@') + 1);
                                                                if (!tcSolicitante.correo.toLowerCase().includes('@') || !dominio.toLowerCase().includes('.')) {
                                                                    toastr.error('El correo no es válido, si no tiene correo deje el espacio en blanco');
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                        swal({
                                                            title: "Confirmación",
                                                            text: "¿Está seguro (a) de agregar la solicitud de " + tcSolicitante.personaDesc + "\nEsta acción no se podrá reversar",
                                                            icon: "warning",
                                                            type: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonClass: "btn-success",
                                                            cancelButtonClass: "btn-danger",
                                                            confirmButtonText: "Confirmar",
                                                            cancelButtonText: "Cancelar",
                                                            closeOnConfirm: true,
                                                            closeOnCancel: true
                                                        },
                                                            function (isConfirm) {
                                                                if (isConfirm) {
                                                                    if (tcSolicitante.personaId == null) {
                                                                        tcSolicitante.personaId = 0;
                                                                    }
                                                                    var item = {
                                                                        tcSolicitante: tcSolicitante
                                                                    }
                                                                    if (tcSolicitante.personaId > 0) {
                                                                        consumoService.agregarDesdeOficina(item).then(function (res) {
                                                                            if (res.status == 1) {
                                                                                item = res.data;
                                                                                $state.go('^.edit', { consumoId: $base64.encode(item.consumoId) });
                                                                            } else {
                                                                                toastr.error(res.message);
                                                                            }
                                                                        });
                                                                    } else {
                                                                        tcSolicitante.personaDesc = $scope.capitalizeText(tcSolicitante.personaDesc);
                                                                        tcSolicitante.direccion = $scope.capitalizeText(tcSolicitante.direccion);
                                                                        personaService.add(tcSolicitante).then(function (res) {
                                                                            if (res.status == 'OK') {
                                                                                var tcPersona = res.data[0];
                                                                                item.tcSolicitante.personaId = tcPersona.personaId;
                                                                                consumoService.agregarDesdeOficina(item).then(function (res) {
                                                                                    if (res.status == 1) {
                                                                                        item = res.data;
                                                                                        $state.go('^.edit', { consumoId: $base64.encode(item.consumoId) });
                                                                                    } else {
                                                                                        toastr.error(res.message);
                                                                                    }
                                                                                });
                                                                            } else {
                                                                                toastr.error(res.message);
                                                                            }
                                                                        }, function (error) {
                                                                            toastr.error(error);
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                    }
                                                }

                                                $scope.setIdioma = function (item) {
                                                    $scope.dataPersona.tcIdioma.idiomaDesc = item.idiomaDesc;
                                                }

                                                $scope.setSexo = function (item) {
                                                    $scope.dataPersona.tcSexo.sexoDesc = item.sexoDesc;
                                                }

                                                $scope.setEstadoCivil = function (item) {
                                                    $scope.dataPersona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                                                }

                                                $scope.setCultura = function (item) {
                                                    $scope.dataPersona.tcCultura.culturaDesc = item.culturaDesc;
                                                }

                                            }]
                                    })
                                }

                                $scope.enviarDocumento = function (item) {
                                    if (item.estadoId < 3) {
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de enviar la solicitud para el consumo familiar " + item.codigo + ", a las oficinas de la subregión " + item.tcSubregion.subregionDesc + " - " + item.tcSubregion.alias + " - INAB? \nEsta acción no se podrá reversar",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    consumoService.solicitarCargaInicial(item).then(function (res) {
                                                        if (res.status == "OK") {
                                                            item.estadoId = 3;
                                                            toastr.success('La solicitud fue enviada a la subregión ' + item.tcSubregion.subregionDesc + ' - ' + item.tcSubregion.alias + '. Pronto se comunicarán por éste medio sobre el trámite', 'Solicitud enviada con éxito');
                                                        } else {
                                                            toastr.error(res.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }
                                            });
                                    }
                                }

                            }]
                    })
                    .state('index.consumo.nota', {
                        url: '/nota',
                        templateUrl: 'app/consumoFamiliar/consumo.nota.tpl.html',
                        resolve: {
                            dataTipoTransporte: ['tipotransporteService',
                                function (tipotransporteService) {
                                    return tipotransporteService.listaActiva();
                                }
                            ],
                            dataProductoTransporte: ['productotransporteService',
                                function (productotransporteService) {
                                    return productotransporteService.listaActiva();
                                }
                            ]
                        },
                        controller: ['$scope', '$state', 'consumoService', '$filter', 'dataProductoTransporte', 'toastr',
                            'Upload', 'dataTipoTransporte', '$base64', 'municipioService',
                            function ($scope, $state, consumoService, $filter, dataProductoTransporte, toastr,
                                Upload, dataTipoTransporte, $base64, municipioService) {

                                $scope.dataTipoTransporte = dataTipoTransporte.data;

                                $scope.habilitarSolicitudNota = false;
                                $scope.gridOptionsNota = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptionsNota.columnDefs = [
                                    { field: 'tcSolicitante.personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                                    { field: 'expediente', name: 'expediente', displayName: 'Expediente' },
                                    { field: 'resolucion', name: 'resolucion', displayName: 'Resolución' },
                                    { field: 'volumenDisponible', name: 'volumenDisponible', displayName: 'Volumen disponible(m³)' },
                                    { field: 'ttConsumoInformacion.fechaVigencia', name: 'fechaVigencia', displayName: 'Vigencia', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.agregarSolicitudVenta(row.entity)" title="Solicitar notas de envío"> <i class="fa fa-file-text-o text-red"></i> </button> '
                                            + '</span></div>'
                                    }
                                ];
                                $scope.gridOptionsNota.paginationPageSize = 3;
                                $scope.gridOptionsNota.data = [];

                                $scope.etiquetaEstadoVenta = function (estadoId) {
                                    switch (estadoId) {
                                        case 1: return 'Solicitado';
                                        case 2: return 'En trámite';
                                        case 3: return 'Esperando pago';
                                        case 4: return 'Finalizado';
                                        case 99: return 'Denegado';
                                        default: return 'Inactivo';
                                    }
                                }

                                $scope.mostrarIngresar = function (estadoId) {
                                    if ($scope.loginData.perfilId != 4) {
                                        return false;
                                    }
                                    return estadoId == 1;
                                }

                                $scope.mostrarRecibirPago = function (estadoId) {
                                    if ($scope.loginData.perfilId != 4) {
                                        return false;
                                    }
                                    return estadoId == 3;
                                }

                                $scope.mostrarImprimirResolucion = function (estadoId) {
                                    return estadoId == 4 || estadoId == 99;
                                }

                                $scope.gridOptionsVenta = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptionsVenta.columnDefs = [
                                    { field: 'codigo', name: 'codigo', displayName: 'No. Solicitud' },
                                    { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    { field: 'volumen', name: 'volumen', displayName: 'Volumen (m³)' },
                                    { field: 'tcTipoTransporte.tipoTransporteDesc', name: 'tipoTransporteDesc', displayName: 'Tipo de transporte' },
                                    { field: 'notas', name: 'notas', displayName: 'Notas autorizadas' },
                                    { field: 'resolucion', name: 'resolucion', displayName: 'Resolución' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstadoVenta(row.entity.estadoId)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-show="grid.appScope.mostrarIngresar(row.entity.estadoId)" ng-click="grid.appScope.recibirSolicitudVenta(row.entity)" title="Recibir solicitud de notas de envío"> <i class="fa fa-download text-red"></i> </button> '
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-show="grid.appScope.mostrarRecibirPago(row.entity.estadoId)" ng-click="grid.appScope.habilitarSubirDocumentoPago(row.entity)" title="Recibir documento de pago de notas de envío"> <i class="fa fa-money text-info"></i> </button> '
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-show="grid.appScope.mostrarImprimirResolucion(row.entity.estadoId)" ng-click="grid.appScope.recibirSolicitudVenta(row.entity)" title="Imprimir resolución"> <i class="fa fa-file-pdf-o text-red"></i> </button> '
                                            + '</span></div>'
                                    }
                                ];
                                $scope.gridOptionsVenta.paginationPageSize = 3;
                                $scope.gridOptionsVenta.data = [];

                                $scope.dataPersona = {};

                                $scope.submitFormCui = function (isValid) {
                                    if (isValid) {
                                        consumoService.getByCui($scope.dataPersona.cui).then(function (res) {
                                            if (res.status == 1) {
                                                $scope.gridOptionsNota.data = res.data;
                                                if (res.data.length <= 0) {
                                                    toastr.warning('No se encontraron resoluciones activas con el código indicado');
                                                }
                                            } else {
                                                toastr.error(res.message);
                                            }
                                        }, function (error) {
                                            toastr.error(error);
                                        });
                                    }
                                }

                                $scope.cargarVentas = function (item) {
                                    consumoService.getListaVentaByConsumo(item.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptionsVenta.data = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.ttConsumo = {};
                                $scope.ttConsumoVenta = {};
                                $scope.agregarSolicitudVenta = function (item) {
                                    $scope.habilitarSolicitudNota = false;
                                    $scope.ttConsumo = {};
                                    $scope.ttConsumoVenta = {};
                                    $scope.gridOptionsVenta.data = [];
                                    $scope.mostrarAdjuntar = false;
                                    $scope.cargarVentas(item);
                                    if (item.volumenDisponible > 0) {
                                        $scope.habilitarSolicitudNota = true;
                                        consumoService.getByConsumoId(item.consumoId).then(function (res) {
                                            if (res.status == 1) {
                                                $scope.ttConsumo = res.data;
                                            } else {
                                                toastr.error(res.message);
                                            }
                                        }, function (error) {
                                            toastr.error(error);
                                        });
                                    } else {
                                        toastr.error('La resolución seleccionada no tiene volumen disponible para solicitar más notas, favor de revisar');
                                    }
                                }

                                $scope.submitFormVenta = function () {
                                    var ventas = $scope.gridOptionsVenta.data;
                                    var continuar = true;
                                    for (var i = 0; i < ventas.length; i++) {
                                        var row = ventas[i];
                                        if (row.estadoId == 1 || row.estadoId == 2 || row.estadoId == 3) {
                                            continuar = false;
                                        }
                                    }
                                    if (!continuar) {
                                        toastr.error('No es posible agregar una nueva solicitud debido a que actualmente tiene una, espere a que resuelvan para agregar otra');
                                        return;
                                    }
                                    var ttConsumoVenta = angular.copy($scope.ttConsumoVenta);
                                    if (ttConsumoVenta.volumen > parseFloat($scope.ttConsumo.volumenDisponible)) {
                                        toastr.error('No es posible solicitar más volumen del disponible');
                                        return;
                                    }
                                    swal({
                                        title: "Confirmación",
                                        text: "¿Está seguro (a) de agregar una nueva solicitud de Notas de Envío?",
                                        icon: "warning",
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonClass: "btn-success",
                                        cancelButtonClass: "btn-danger",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                var ttConsumo = angular.copy($scope.ttConsumo);
                                                delete ttConsumo.ttConsumoInformacion;
                                                var ttConsumoVenta = angular.copy($scope.ttConsumoVenta);
                                                ttConsumoVenta.ttConsumo = ttConsumo;
                                                consumoService.agregarSolicitudVentaConsumo(ttConsumoVenta).then(function (res) {
                                                    if (res.status == 1) {
                                                        $scope.ttConsumoVenta = {};
                                                        $scope.cargarVentas(ttConsumo);
                                                    } else {
                                                        toastr.error(res.message);
                                                    }
                                                });
                                            }
                                        });
                                }


                                $scope.recibirSolicitudVenta = function (item) {
                                    swal({
                                        title: "Esta acción no se puede reversar, es decir no se podrá regresar la tarea",
                                        text: "¿Está seguro (a) de recibir la solicitud de venta a nombre de " + item.ttConsumo.tcSolicitante.personaDesc + "?",
                                        icon: "warning",
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonClass: "btn-success",
                                        cancelButtonClass: "btn-danger",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                consumoService.recibirSolicitudVentaConsumo(item.consumoVentaId).then(function (res) {
                                                    if (res.status == 1) {
                                                        $scope.cargarVentas($scope.ttConsumo);
                                                    } else {
                                                        toastr.error(res.message);
                                                    }
                                                });
                                            }
                                        });
                                }

                                $scope.mostrarAgregarNota = false;
                                $scope.habilitarMostarAgregarNota = function () {
                                    var notas = $scope.ttConsumoVenta.correlativos;
                                    var autorizado = $scope.ttConsumoVenta.notas;
                                    var falta = autorizado - notas.length;
                                    if (falta > 0) {
                                        $scope.mostrarAgregarNota = true;
                                    } else {
                                        $scope.mostrarAgregarNota = false;
                                    }
                                }

                                $scope.mostrarQuitarNota = false;
                                $scope.habilitarMostrarQuitarNota = function () {
                                    var notas = $scope.ttConsumoVenta.correlativos;
                                    if (notas.length > 1) {
                                        $scope.mostrarQuitarNota = true;
                                    } else {
                                        $scope.mostrarQuitarNota = false;
                                    }
                                }

                                $scope.agregarNotaEnvio = function () {
                                    if ($scope.ttConsumoVenta.correlativos.length < $scope.ttConsumoVenta.notas) {
                                        $scope.ttConsumoVenta.correlativos.push(
                                            {
                                                fechaTransporte: new Date(),
                                                direccion: $scope.ttConsumo.ttConsumoInformacion.direccionDestino,
                                                numero: $scope.ttConsumoVenta.correlativos.length + 1,
                                                especies: [
                                                    {
                                                        tcEspecie: {},
                                                        troza: null,
                                                        lenia: null
                                                    }
                                                ]
                                            }
                                        );
                                        $scope.habilitarMostarAgregarNota();
                                        $scope.habilitarMostrarQuitarNota();
                                    }
                                }

                                $scope.quitarNotaEnvio = function () {
                                    if ($scope.ttConsumoVenta.correlativos.length > 1) {
                                        $scope.ttConsumoVenta.correlativos.splice($scope.ttConsumoVenta.correlativos.length - 1, 1);
                                        $scope.habilitarMostarAgregarNota();
                                        $scope.habilitarMostrarQuitarNota();
                                    }
                                }

                                $scope.nombreArchivo = 'Clic para buscar archivo';
                                $scope.colorBoton = 'btn-warning';
                                $scope.dataProductoTransporte = dataProductoTransporte.data;
                                $scope.habilitarSubirDocumentoPago = function (item) {
                                    item.correlativos = [];
                                    for (var i = 1; i <= item.notas; i++) {
                                        var row = {
                                            fechaTransporte: new Date(),
                                            direccion: $scope.ttConsumo.ttConsumoInformacion.direccionDestino,
                                            numero: i,
                                            tcMunicipio: {},
                                            especies: [
                                                {
                                                    tcEspecie: {},
                                                    troza: null,
                                                    lenia: null,
                                                    carbon: 0,
                                                    aserrada: 0
                                                }
                                            ]
                                        };
                                        item.correlativos.push(row);
                                    }
                                    $scope.ttConsumoVenta = item;
                                    $scope.ttConsumoVenta.rutaPago = null;
                                    $scope.mostrarAdjuntar = true;
                                    var especies = [];
                                    var especiesTmp = $scope.ttConsumo.especies;
                                    for (var i = 0; i < especiesTmp.length; i++) {
                                        var especie = especiesTmp[i];
                                        especies.push(especie.tcEspecie);
                                    }
                                    $scope.dataEspecie = especies;
                                    $scope.habilitarMostarAgregarNota();
                                    $scope.habilitarMostrarQuitarNota();
                                    municipioService.listaPorDepartamento($scope.ttConsumo.ttConsumoInformacion.tcMunicipioDestino.tcDepartamento.departamentoId).then(function (res) {
                                        if (res.status == "OK") {
                                            $scope.dataMunicipio = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.onFileSelect = function (file, tipoAnexoId) {
                                    Upload.upload({
                                        url: appSettings.urlServiceBase + 'file/upload',
                                        method: 'POST',
                                        file: file,
                                        sendFieldsAs: 'form',
                                        fields: {
                                            data: []
                                        }
                                    }).then(function (resp) {
                                        var result = resp.data;
                                        if (result.status == 'OK') {
                                            var item = result.data[0];
                                            $scope.ttConsumoVenta.rutaPago = item.rutaArchivo;
                                            $scope.nombreArchivo = item.nombreOriginal;
                                            $scope.colorBoton = 'btn-success';
                                        } else {
                                            toastr.error(resp.data.message)
                                        }
                                    }, function (resp) {
                                        toastr.error('Error status: ' + resp.status);
                                    }, function (evt) {
                                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                        $scope.avance = progressPercentage;
                                    });
                                }

                                $scope.agregarEspecieNota = function (nota) {
                                    nota.especies.push({
                                        carbon: 0,
                                        aserrada: 0
                                    });
                                }

                                $scope.quitarEspecieNota = function (nota, especie) {
                                    nota.especies.splice(nota.especies.indexOf(especie), 1);
                                }

                                $scope.setEspecieNota = function (item, nota, fila) {
                                    var veces = 0;
                                    for (var i = 0; i < nota.especies.length; i++) {
                                        var especie = nota.especies[i];
                                        if (especie.tcEspecie.especieId == item.especieId) {
                                            especie.tcEspecie.especieDesc = item.especieDesc;
                                            especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                                            veces++;
                                        }
                                    }
                                    if (veces > 1) {
                                        fila.tcEspecie = {};
                                        toastr.error('La especie seleccionada ya ha sido agregada, favor de verificar');
                                    }
                                }

                                //verificar si la suma del volumen es correcto
                                $scope.enviarDocumentoPago = function (isValid) {
                                    if ($scope.ttConsumoVenta.rutaPago == null) {
                                        toastr.error('Debe seleccionar el documento de pago');
                                        isValid = false;
                                        return;
                                    }
                                    if (isValid) {
                                        var ttConsumoVenta = $scope.ttConsumoVenta;
                                        var correlativos = ttConsumoVenta.correlativos;
                                        var volumen = 0;
                                        var factorCarbon = 0.65;
                                        var factorAserrada = 0.39;
                                        var dataProductoTransporte = $scope.dataTipoTransporte;
                                        for (var i = 0; i < dataProductoTransporte.length; i++) {
                                            var item = dataProductoTransporte[i];
                                            if (item.productoTransporteId == 2) {
                                                factorAserrada = item.rendimientoMotosierra;
                                            }
                                            if (item.productoTransporteId == 4) {
                                                factorCarbon = item.rendimientoMotosierra;
                                            }
                                        }
                                        for (var i = 0; i < correlativos.length; i++) {
                                            var nota = correlativos[i];
                                            var especies = nota.especies;
                                            if (especies.length <= 0) {
                                                toastr.error('Debe agregar al menos una especie a transportar');
                                                return;
                                            }
                                            for (var j = 0; j < especies.length; j++) {
                                                var especie = especies[j];
                                                especie.troza = parseFloat(especie.troza).toFixed(4);
                                                especie.lenia = parseFloat(especie.lenia).toFixed(4);
                                                especie.carbon = parseFloat(especie.carbon).toFixed(4);
                                                especie.aserrada = parseFloat(especie.aserrada).toFixed(4);
                                                var carbon = 0, aserrada = 0;
                                                if (factorCarbon > 0) {
                                                    carbon = parseFloat(especie.carbon) / factorCarbon;
                                                    carbon = parseFloat(carbon).toFixed(4);
                                                }
                                                if (factorAserrada > 0) {
                                                    aserrada = parseFloat(especie.aserrada) / factorAserrada;
                                                    aserrada = parseFloat(aserrada).toFixed(4);
                                                }
                                                var total = parseFloat(especie.troza) + parseFloat(especie.lenia) + parseFloat(carbon) + parseFloat(aserrada);
                                                total = parseFloat(total).toFixed(4);
                                                volumen += parseFloat(total);
                                            }
                                        }
                                        volumen = parseFloat(volumen).toFixed(4);
                                        var volumenAprobado = parseFloat(ttConsumoVenta.volumen).toFixed(4);
                                        if (correlativos.length == parseInt(ttConsumoVenta.notas)) {
                                            var diferencia = parseFloat(volumen) - parseFloat(volumenAprobado);
                                            diferencia = Math.abs(diferencia);
                                            if (diferencia > 0.01) {
                                                toastr.error('El detalle de volumen (' + volumen + ') por nota es diferente al autorizado (' + ttConsumoVenta.volumen
                                                    + '), si transporta madera aserrada el factor de transformación es '
                                                    + factorAserrada + ' y para carbón ' + factorCarbon + '. No es posible continuar');
                                                return;
                                            }
                                        }
                                        if (parseFloat(volumen) > parseFloat(volumenAprobado)) {
                                            toastr.error('La sumatoria de volumen (' + volumen + ')  de las notas debe ser menor o igual al aprobado(' + volumenAprobado + ')');
                                            return;
                                        }
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de envíar la información?\nEsta acción no se podrá anular luego de su envío",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    consumoService.agregarDocumentoPagoVenta(ttConsumoVenta).then(function (res) {
                                                        if (res.status == 1) {
                                                            $scope.ttConsumoVenta = {};
                                                            $scope.mostrarAdjuntar = false;
                                                            $scope.cargarVentas(ttConsumoVenta.ttConsumo);
                                                        } else {
                                                            toastr.error(res.message);
                                                        }
                                                    });
                                                }
                                            });
                                    } else {
                                        toastr.error('Todos los campos son obligatorios');
                                    }
                                }

                            }]
                    })
                    .state('index.consumo.solicitarnota', {
                        url: '/:consumoId/solicitarnota',
                        templateUrl: 'app/consumoFamiliar/consumo.solicitar.nota.tpl.html',
                        resolve: {
                            dataTipoTransporte: ['tipotransporteService',
                                function (tipotransporteService) {
                                    return tipotransporteService.listaActiva();
                                }
                            ],
                            dataVentas: ['consumoService', '$stateParams', '$base64',
                                function (consumoService, $stateParams, $base64) {
                                    return consumoService.getListaVentaByConsumo($base64.decode($stateParams.consumoId));
                                }
                            ],
                            dataConsumo: ['consumoService', '$stateParams', '$base64',
                                function (consumoService, $stateParams, $base64) {
                                    return consumoService.getByConsumoId($base64.decode($stateParams.consumoId));
                                }
                            ]
                        },
                        controller: ['$scope', '$state', 'consumoService', 'Upload', 'utilsService', 'toastr',
                            'Upload', 'dataTipoTransporte', '$base64', 'dataVentas', 'dataConsumo',
                            function ($scope, $state, consumoService, Upload, utilsService, toastr,
                                Upload, dataTipoTransporte, $base64, dataVentas, dataConsumo) {

                                $scope.dataTipoTransporte = dataTipoTransporte.data;

                                $scope.etiquetaEstadoVenta = function (estadoId) {
                                    switch (estadoId) {
                                        case 1: return 'Solicitado';
                                        case 2: return 'En trámite';
                                        case 3: return 'Esperando pago';
                                        case 4: return 'Finalizado';
                                        case 99: return 'Denegado';
                                        default: return 'Inactivo';
                                    }
                                }

                                $scope.mostrarDocumentoPago = function (estadoId) {
                                    return estadoId == 3;
                                }

                                $scope.mostrarResolucion = function (estadoId) {
                                    return estadoId == 4 || estadoId == 99;
                                }

                                $scope.gridOptionsVenta = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptionsVenta.columnDefs = [
                                    { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    { field: 'volumen', name: 'volumen', displayName: 'Volumen (m³)' },
                                    { field: 'notas', name: 'notas', displayName: 'Notas autorizadas' },
                                    { field: 'resolucion', name: 'resolucion', displayName: 'Resolución' },
                                    { field: 'tcTipoTransporte.tipoTransporteDesc', name: 'tipoTransporteDesc', displayName: 'Tipo de transporte' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstadoVenta(row.entity.estadoId)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-show="grid.appScope.mostrarDocumentoPago(row.entity.estadoId)" ng-click="grid.appScope.habilitarSubirDocumentoPago(row.entity)" title="Subir documento de pago"> <i class="fa fa-money text-success"></i> </button> '
                                            + ' <button type="button" class="btn btn-default btn-sm" ng-show="grid.appScope.mostrarResolucion(row.entity.estadoId)" ng-click="grid.appScope.imprimirResolucionVenta(row.entity)" title="Imprimir resolución y notas de envío"> <i class="fa fa-file-text-o text-red"></i> </button> '
                                            + '</span></div>'
                                    }
                                ];
                                $scope.gridOptionsVenta.paginationPageSize = 3;
                                $scope.gridOptionsVenta.data = [];
                                $scope.ttConsumo = {};
                                if (dataVentas.status == 1) {
                                    $scope.gridOptionsVenta.data = dataVentas.data;
                                } else {
                                    toastr.error(res.message);
                                }

                                if (dataConsumo.status == 1) {
                                    $scope.habilitarSolicitudNota = true;
                                    $scope.ttConsumo = dataConsumo.data;
                                } else {
                                    toastr.error(res.message);
                                }

                                $scope.cargarVentas = function (item) {
                                    consumoService.getListaVentaByConsumo(item.consumoId).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptionsVenta.data = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.ttConsumoVenta = {};

                                $scope.submitFormVenta = function () {
                                    var ventas = $scope.gridOptionsVenta.data;
                                    var continuar = true;
                                    for (var i = 0; i < ventas.length; i++) {
                                        var row = ventas[i];
                                        if (row.estadoId == 1 || row.estadoId == 2 || row.estadoId == 3) {
                                            continuar = false;
                                        }
                                    }
                                    if (!continuar) {
                                        toastr.error('No es posible agregar una nueva solicitud debido a que actualmente tiene una, espere a que resuelvan para agregar otra');
                                        return;
                                    }
                                    var ttConsumoVenta = angular.copy($scope.ttConsumoVenta);
                                    if (ttConsumoVenta.volumen > parseFloat($scope.ttConsumo.volumenDisponible)) {
                                        toastr.error('No es posible solicitar más volumen del disponible');
                                        return;
                                    }
                                    swal({
                                        title: "Confirmación",
                                        text: "¿Está seguro (a) de agregar una nueva solicitud de Notas de Envío?",
                                        icon: "warning",
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonClass: "btn-success",
                                        cancelButtonClass: "btn-danger",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar",
                                        closeOnConfirm: true,
                                        closeOnCancel: true
                                    },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                var ttConsumo = angular.copy($scope.ttConsumo);
                                                delete ttConsumo.ttConsumoInformacion;
                                                var ttConsumoVenta = angular.copy($scope.ttConsumoVenta);
                                                ttConsumoVenta.ttConsumo = ttConsumo;
                                                consumoService.agregarSolicitudVentaConsumo(ttConsumoVenta).then(function (res) {
                                                    if (res.status == 1) {
                                                        $scope.ttConsumoVenta = {};
                                                        $scope.cargarVentas(ttConsumo);
                                                    } else {
                                                        toastr.error(res.message);
                                                    }
                                                });
                                            }
                                        });

                                }

                                $scope.mostrarAgregarNota = false;
                                $scope.habilitarMostarAgregarNota = function () {
                                    var notas = $scope.ttConsumoVenta.correlativos;
                                    var autorizado = $scope.ttConsumoVenta.notas;
                                    var falta = autorizado - notas.length;
                                    if (falta > 0) {
                                        $scope.mostrarAgregarNota = true;
                                    } else {
                                        $scope.mostrarAgregarNota = false;
                                    }
                                }

                                $scope.mostrarQuitarNota = false;
                                $scope.habilitarMostrarQuitarNota = function () {
                                    var notas = $scope.ttConsumoVenta.correlativos;
                                    if (notas.length > 1) {
                                        $scope.mostrarQuitarNota = true;
                                    } else {
                                        $scope.mostrarQuitarNota = false;
                                    }
                                }

                                $scope.agregarNotaEnvio = function () {
                                    if ($scope.ttConsumoVenta.correlativos.length < $scope.ttConsumoVenta.notas) {
                                        $scope.ttConsumoVenta.correlativos.push(
                                            {
                                                fechaTransporte: new Date(),
                                                direccion: $scope.ttConsumo.ttConsumoInformacion.direccionDestino,
                                                numero: $scope.ttConsumoVenta.correlativos.length + 1,
                                                especies: [
                                                    {
                                                        tcEspecie: {},
                                                        troza: null,
                                                        lenia: null
                                                    }
                                                ]
                                            }
                                        );
                                        $scope.habilitarMostarAgregarNota();
                                        $scope.habilitarMostrarQuitarNota();
                                    }
                                }

                                $scope.quitarNotaEnvio = function () {
                                    if ($scope.ttConsumoVenta.correlativos.length > 1) {
                                        $scope.ttConsumoVenta.correlativos.splice($scope.ttConsumoVenta.correlativos.length - 1, 1);
                                        $scope.habilitarMostarAgregarNota();
                                        $scope.habilitarMostrarQuitarNota();
                                    }
                                }

                                $scope.habilitarSubirDocumentoPago = function (item) {
                                    if (item.correlativos == null) {
                                        item.correlativos = [];
                                    }
                                    for (var i = 1; i <= item.notas; i++) {
                                        var row = {
                                            fechaTransporte: new Date(),
                                            direccion: $scope.ttConsumo.ttConsumoInformacion.direccionDestino,
                                            numero: i,
                                            especies: [
                                                {
                                                    tcEspecie: {},
                                                    troza: null,
                                                    lenia: null
                                                }
                                            ]
                                        };
                                        item.correlativos.push(row);
                                    }
                                    $scope.ttConsumoVenta = item;
                                    $scope.ttConsumoVenta.rutaPago = null;
                                    $scope.mostrarAdjuntar = true;
                                    var especies = [];
                                    var especiesTmp = $scope.ttConsumo.especies;
                                    for (var i = 0; i < especiesTmp.length; i++) {
                                        var especie = especiesTmp[i];
                                        especies.push(especie.tcEspecie);
                                    }
                                    $scope.dataEspecie = especies;
                                    $scope.habilitarMostarAgregarNota();
                                    $scope.habilitarMostrarQuitarNota();
                                }

                                $scope.onFileSelect = function (file, tipoAnexoId) {
                                    Upload.upload({
                                        url: appSettings.urlServiceBase + 'file/upload',
                                        method: 'POST',
                                        file: file,
                                        sendFieldsAs: 'form',
                                        fields: {
                                            data: []
                                        }
                                    }).then(function (resp) {
                                        var result = resp.data;
                                        if (result.status == 'OK') {
                                            var item = result.data[0];
                                            $scope.ttConsumoVenta.rutaPago = item.rutaArchivo;
                                        } else {
                                            toastr.error(resp.data.message)
                                        }
                                    }, function (resp) {
                                        toastr.error('Error status: ' + resp.status);
                                    }, function (evt) {
                                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                        $scope.avance = progressPercentage;
                                    });
                                }

                                $scope.agregarEspecieNota = function (nota) {
                                    nota.especies.push({});
                                }

                                $scope.quitarEspecieNota = function (nota, especie) {
                                    nota.especies.splice(nota.especies.indexOf(especie), 1);
                                }

                                $scope.setEspecieNota = function (item, nota, fila) {
                                    var veces = 0;
                                    for (var i = 0; i < nota.especies.length; i++) {
                                        var especie = nota.especies[i];
                                        if (especie.tcEspecie.especieId == item.especieId) {
                                            especie.tcEspecie.especieDesc = item.especieDesc;
                                            especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                                            veces++;
                                        }
                                    }
                                    if (veces > 1) {
                                        fila.tcEspecie = {};
                                        toastr.error('La especie seleccionada ya ha sido agregada, favor de verificar');
                                    }
                                }

                                //verificar si la suma del volumen es correcto
                                $scope.enviarDocumentoPago = function (isValid) {
                                    if ($scope.ttConsumoVenta.rutaPago == null) {
                                        isValid = false;
                                    }
                                    if (isValid) {
                                        var ttConsumoVenta = $scope.ttConsumoVenta;
                                        var correlativos = ttConsumoVenta.correlativos;
                                        var volumen = 0;
                                        for (var i = 0; i < correlativos.length; i++) {
                                            var nota = correlativos[i];
                                            var especies = nota.especies;
                                            if (especies.length <= 0) {
                                                toastr.error('Debe agregar al menos una especie a transportar');
                                                return;
                                            }
                                            for (var j = 0; j < especies.length; j++) {
                                                var especie = especies[j];
                                                especie.troza = parseFloat(especie.troza).toFixed(4);
                                                especie.lenia = parseFloat(especie.lenia).toFixed(4);
                                                var total = parseFloat(especie.troza) + parseFloat(especie.lenia);
                                                total = parseFloat(total).toFixed(4);
                                                volumen += parseFloat(total);
                                            }
                                        }
                                        if (volumen > parseFloat(ttConsumoVenta.volumen)) {
                                            toastr.error('El detalle de volumen por nota es mayor al autorizado, no es posible continuar');
                                            return;
                                        }
                                        swal({
                                            title: "Confirmación",
                                            text: "¿Está seguro (a) de envíar la información?\nEsta acción no se podrá anular luego de su envío",
                                            icon: "warning",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-success",
                                            cancelButtonClass: "btn-danger",
                                            confirmButtonText: "Confirmar",
                                            cancelButtonText: "Cancelar",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    consumoService.agregarDocumentoPagoVenta(ttConsumoVenta).then(function (res) {
                                                        if (res.status == 1) {
                                                            $scope.ttConsumoVenta = {};
                                                            $scope.mostrarAdjuntar = false;
                                                            $scope.cargarVentas(ttConsumoVenta.ttConsumo);
                                                        } else {
                                                            toastr.error(res.message);
                                                        }
                                                    });
                                                }
                                            });
                                    } else {
                                        toastr.error('Todos los campos son obligatorios');
                                    }
                                }

                            }]
                    })
            }
        ]
    );