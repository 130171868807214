angular.module('app.categoriaexento', [
  'ui.router',
  'toastr',
  'app.categoriaexento.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.categoriaexento', {
          abstract: true,
          url: 'categoriaexento',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Categoria del exento';
            }]
        })
        .state('index.categoriaexento.list', {
          url: '',
          templateUrl: 'app/categoriaexento/categoriaexento.list.tpl.html',
          resolve: {
            dataCategoriaExento: ['categoriaexentoService',
              function ( categoriaexentoService ){
                return categoriaexentoService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCategoriaExento',
            function (  $scope,   $state,   dataCategoriaExento) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'categoriaExentoDesc', name: 'categoriaExentoDesc', displayName: 'Forma de la parcela' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCategoriaExento.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ categoriaExentoId: row.categoriaExentoId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.categoriaexento.add', {
          url: '/add',
          templateUrl: 'app/categoriaexento/categoriaexento.add.tpl.html',
          resolve: {
            dataEstado: ['categoriaexentoService',
              function ( categoriaexentoService ){
                return categoriaexentoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'categoriaexentoService', 'dataEstado',
            function (  $scope,   $state,   toastr,   categoriaexentoService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  categoriaexentoService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.categoriaexento.edit', {
          url: '/:categoriaExentoId/edit',
          templateUrl: 'app/categoriaexento/categoriaexento.add.tpl.html',
          resolve: {
            dataCategoriaExento: ['categoriaexentoService', '$stateParams',
              function ( categoriaexentoService, $stateParams ){
                return categoriaexentoService.get( $stateParams.categoriaExentoId );
              }],
            dataEstado: ['categoriaexentoService',
              function ( categoriaexentoService ){
                return categoriaexentoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'categoriaexentoService', 'dataCategoriaExento', 'dataEstado',
            function (  $scope,   $state,   toastr,   categoriaexentoService,   dataCategoriaExento, dataEstado) {
              $scope.data = dataCategoriaExento.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  categoriaexentoService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
