angular.module('app.persona', [
  'ui.router',
  'toastr',
  'app.persona.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.persona', {
          abstract: true,
          url: 'persona',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'País';
            }]
        })
        .state('index.persona.list', {
          url: '',
          templateUrl: 'app/persona/persona.list.tpl.html',
          resolve: {
            dataPersona: ['personaService',
              function ( personaService ){
                return personaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataPersona', 'personaService', 'toastr',
            function (  $scope,   $state,   dataPersona, personaService, toastr) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'personaDesc', name: 'personaDesc', displayName: 'Persona' },
                { field:'correo', name: 'correo', displayName: 'Correo' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button>'
                    + ' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.reenviarValidacion(row.entity)" title="Reenviar código de validación">Código</button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataPersona.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ personaId: row.personaId });
              }

              $scope.reenviarValidacion = function ( item ) {
                swal({
                  title: "¿Está seguro de enviar de nuevo el código de Confirmación a la persona " + item.personaDesc + "?",
                  text: "La notificación se enviará solo si la cuenta no se ha confirmado aún",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    item.tcUsuario.usuarioId = $scope.loginData.usuarioId;
                    personaService.reenviarValidacion( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.persona.add', {
          url: '/add',
          templateUrl: 'app/persona/persona.add.tpl.html',
          resolve: {
            dataEstado: ['personaService',
              function ( personaService ){
                return personaService.listStatus();
              }],
            dataOcupacion: ['ocupacionService',
              function ( ocupacionService ){
                return ocupacionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'personaService', 'dataEstado', 'dataOcupacion',
            function (  $scope,   $state,   toastr,   personaService, dataEstado, dataOcupacion) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  personaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.persona.edit', {
          url: '/:personaId/edit',
          templateUrl: 'app/persona/persona.add.tpl.html',
          resolve: {
            dataPersona: ['personaService', '$stateParams',
              function ( personaService, $stateParams ){
                return personaService.get( $stateParams.personaId );
              }],
            dataEstado: ['personaService',
              function ( personaService ){
                return personaService.listStatus();
              }],
            dataOcupacion: ['ocupacionService',
              function ( ocupacionService ){
                return ocupacionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'personaService', 'dataPersona', 'dataEstado', 'dataOcupacion',
            function (  $scope,   $state,   toastr,   personaService,   dataPersona, dataEstado, dataOcupacion) {
              $scope.data = dataPersona.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  personaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
