angular.module('app.costoEvitado', [
  'ui.router',
  'toastr',
  'app.costoEvitado.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.costoEvitado', {
          abstract: true,
          url: 'costoEvitado',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Costos evitados para avalúos';
            }]
        })
        .state('index.costoEvitado.list', {
          url: '',
          templateUrl: 'app/costoEvitado/costoEvitado.list.tpl.html',
          resolve: {
            dataCostoEvitado: ['costoEvitadoService',
              function ( costoEvitadoService ){
                return costoEvitadoService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCostoEvitado',
            function (  $scope,   $state,   dataCostoEvitado) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcTipoCosto.tipoCostoDesc', name: 'tipoCostoDesc', displayName: 'Tipo costo' },
                { field:'tcTipoBosque.tipoBosqueDesc', name: 'tipoBosqueDesc', displayName: 'Tipo bosque' },
                { field:'monto', name: 'monto', displayName: 'Monto (m³)' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCostoEvitado.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ costoEvitadoId: row.costoEvitadoId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.costoEvitado.add', {
          url: '/add',
          templateUrl: 'app/costoEvitado/costoEvitado.add.tpl.html',
          resolve: {
            dataEstado: ['costoEvitadoService',
              function ( costoEvitadoService ){
                return costoEvitadoService.listStatus();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }],
            dataTipoCosto: ['tipoCostoService',
              function ( tipoCostoService ){
                return tipoCostoService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'costoEvitadoService', 'dataEstado', 'dataTipoBosque', 'dataTipoCosto',
            function (  $scope,   $state,   toastr,   costoEvitadoService, dataEstado, dataTipoBosque, dataTipoCosto) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoBosque = dataTipoBosque.data;
              $scope.dataTipoCosto = dataTipoCosto.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  costoEvitadoService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.costoEvitado.edit', {
          url: '/:costoEvitadoId/edit',
          templateUrl: 'app/costoEvitado/costoEvitado.add.tpl.html',
          resolve: {
            dataCostoEvitado: ['costoEvitadoService', '$stateParams',
              function ( costoEvitadoService, $stateParams ){
                return costoEvitadoService.get( $stateParams.costoEvitadoId );
              }],
            dataEstado: ['costoEvitadoService',
              function ( costoEvitadoService ){
                return costoEvitadoService.listStatus();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }],
            dataTipoCosto: ['tipoCostoService',
              function ( tipoCostoService ){
                return tipoCostoService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'costoEvitadoService', 'dataCostoEvitado', 'dataEstado', 'dataTipoBosque', 'dataTipoCosto',
            function (  $scope,   $state,   toastr,   costoEvitadoService,   dataCostoEvitado, dataEstado, dataTipoBosque, dataTipoCosto) {
              $scope.data = dataCostoEvitado.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoBosque = dataTipoBosque.data;
              $scope.dataTipoCosto = dataTipoCosto.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  costoEvitadoService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
