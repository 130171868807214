angular.module('app.fuentesemillera', [
  'ui.router',
  'toastr',
  'app.fuentesemillera.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.fuentesemillera', {
          abstract: true,
          url: 'fuentesemillera',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope', 'municipioService', 'fincaService', 'toastr',
            function (  $scope, municipioService, fincaService, toastr ) {
              $scope.module = 'Fuente semillera';
              $scope.direccion = {};
              $scope.dataFuenteSemillera = {
                data: null,
                loteSemilla: null
              };
              $scope.dataLoteSemilla = {};
              $scope.cargarListaMunicipioFinca = function (item) {
                $scope.direccion.municipioId = null;
                municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                  if (res.status == "OK") {
                    $scope.dataMunicipioFinca = res.data;
                  } else {
                    toastr.error(res.message);
                  }
                }, function (error) {
                  toastr.error(error);
                });
              }

              $scope.setMunicipioFinca = function ( item ) {
                if (item) {
                  fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                    if (res.status == "OK") {
                      $scope.dataFinca = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }
              }

              $scope.data = {};

            }]
        })
        .state('index.fuentesemillera.list', {
          url: '',
          templateUrl: 'app/fuentesemillera/fuentesemillera.list.tpl.html',
          resolve: {
            dataFuenteSemillera: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataFuenteSemillera',
            function (  $scope,   $state,   dataFuenteSemillera) {

              $scope.mostrarLotes = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'fuenteSemilleraDesc', name: 'fuenteSemilleraDesc', displayName: 'Fuente semillera' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarLotes(row.entity.estadoId)" ng-click="grid.appScope.listarLotes(row.entity)" title="Listar cosechas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataFuenteSemillera.data;

              $scope.editRow = function ( row ) {
                $state.go('index.fuente.realizar',{ fuenteSemilleraId: row.fuenteSemilleraId });
              }

              $scope.listarLotes = function ( row ) {
                $scope.dataFuenteSemillera.data = row;
                $state.go('^.cosecha', { fuenteSemilleraId: row.fuenteSemilleraId });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.fuentesemillera.propio', {
          url: '',
          templateUrl: 'app/fuentesemillera/fuentesemillera.list.tpl.html',
          resolve: {
            dataFuenteSemillera: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataFuenteSemillera',
            function (  $scope,   $state,   dataFuenteSemillera) {

              $scope.mostrarLotes = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'fuenteSemilleraDesc', name: 'fuenteSemilleraDesc', displayName: 'Fuente semillera' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarLotes(row.entity.estadoId)" ng-click="grid.appScope.listarLotes(row.entity)" title="Listar cosechas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataFuenteSemillera.data;

              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ fuenteSemilleraId: row.fuenteSemilleraId });
              }

              $scope.listarLotes = function ( row ) {
                $scope.dataFuenteSemillera.data = row;
                $state.go('^.cosecha', { fuenteSemilleraId: row.fuenteSemilleraId });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.fuentesemillera.add', {
          url: '/add',
          templateUrl: 'app/fuentesemillera/fuentesemillera.add.tpl.html',
          resolve: {
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataTipoPropietario: ['tipopropietarioService', '$stateParams',
              function (tipopropietarioService, $stateParams) {
                return tipopropietarioService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataTipoPropietario', 'catalogosService', 'utilsService',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataTipoPropietario, catalogosService, utilsService ) {

              $scope.dataTipoPropietario = dataTipoPropietario.data;
              $scope.dataEstadoRegistro = [
                {estadoRegistroId: 1, estadoRegistroDesc: 'Ya tengo un número de registro'},
                {estadoRegistroId: 2, estadoRegistroDesc: 'Deseo registrar una fuente semillera nueva'}
              ];

              $scope.estadoRegistroId = 1;
              $scope.mostrarBusqueda = true;
              $scope.obligarComercializador = false;
              $scope.habilitarInfo = true;
              $scope.data = {

              };

              $scope.setTipoPropietario = function ( item ) {
                if (item.tipoPropietarioId == 2) {
                  $scope.obligarComercializador = true;
                } else {
                  $scope.obligarComercializador = false;
                }
              }

              $scope.setEstadoRegistro = function ( item ) {
                if (item.estadoRegistroId == 1) {
                  $scope.mostrarBusqueda = true;
                } else {
                  $scope.mostrarBusqueda = false;
                }
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.buscarDatosFuente = function () {
                catalogosService.getDatosRegistro( $scope.data.fuenteSemilleraDesc.trim() ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    var data = res.data[0];
                    toastr.success( res.message );
                  } else {
                    toastr.error('El código no se encuentra');
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.googleMapsUrl = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry&key=AIzaSyAKVYnbc_n_LGu93K2Ivdx6qfChtzpQX-U";

              $scope.testPoligono = function () {
                var coordenadas = [
                  {lat: 448231, lng: 1655671},
                  {lat: 448213, lng: 1655646},
                  {lat: 448186, lng: 1655726},
                  {lat: 448172, lng: 1655767},
                  {lat: 448193, lng: 1655786},
                  {lat: 448205, lng: 1655743},
                ];
                var nuevos = utilsService.gtmToLatLong(true, 'lat', 'lng', coordenadas);
                var texto = '';
                $scope.coordenadas = nuevos;
                var bounds = new google.maps.LatLngBounds();
                var minx = 0, miny = 0, maxx = 0, maxy = 0;
                for (var i = 0; i < nuevos.length; i++) {
                  var item = nuevos[i];
                  texto += '{ lat: ' + item.lat + ', lng: ' + item.lng + ' }, \n';
                  var otro = new google.maps.LatLng(item.lat, item.lng);
                  bounds.extend(otro);
                }

                $scope.centro = bounds.getCenter();
                $scope.centerX = $scope.centro.lat();
                $scope.centerY = $scope.centro.lng();
                $scope.nuevo = texto;
              }

              //$scope.testPoligono();

            }]
        })
        .state('index.fuentesemillera.agregarlo', {
          url: '/agregarlo',
          templateUrl: 'app/fuentesemillera/fuentesemillera.add.tpl.html',
          resolve: {
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }],
            dataTipoPropietario: ['tipopropietarioService', '$stateParams',
              function (tipopropietarioService, $stateParams) {
                return tipopropietarioService.listaActiva();
              }],
            dataLibro: ['libroService',
              function (libroService) {
                return libroService.listaActiva();
              }],
            dataTipoPropiedad: ['tipopropiedadService',
              function (tipopropiedadService) {
                return tipopropiedadService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataDepartamento', 'dataTipoPropietario', 'dataLibro', 'dataTipoPropiedad', 'ngDialog',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataDepartamento, dataTipoPropietario, dataLibro, dataTipoPropiedad, ngDialog ) {
              $scope.dataDepartamentoFinca = dataDepartamento.data;

              $scope.dataTipoPropiedad = dataTipoPropiedad.data;

              $scope.setPropietarios = function () {
                if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                  var data = [
                    {
                      personaId: 1,
                      personaDesc: $scope.data.ttTipoPropietarioGestion.razonSocial
                    }
                  ];
                  $scope.dataPersona = data;
                } else {
                  if ($scope.data.personas != undefined) {
                    if ($scope.data.personas.length > 0) {
                      var data = [];
                      for (var i = 0; i < $scope.data.personas.length; i++) {
                        if ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0)) {
                          data.push(angular.copy($scope.data.personas[i].tcPersona));
                        }
                      }
                      $scope.dataPersona = data;
                    }
                  }
                }
              }

              $scope.buscarPersona = function ( tipo ) {
                $scope.tipoPersona = tipo;
                var continuar = true;
                if (continuar) {
                  $scope.dataTipoBusqueda = [
                    { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                    { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                    { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                  ]
                  $scope.busqueda = {
                    tipoBusquedaId: 3,
                    tituloBusqueda: 'Ingrese el nombre de la persona',
                    placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                  }

                  ngDialog.open({
                    template: 'app/gestion/buscar.persona.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'personaService',
                      function ($scope, $state, toastr, personaService) {

                        $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                        $scope.gridPersona.columnDefs = [
                          { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                          { field: 'cui', name: 'cui', displayName: 'Identificación' },
                          { field: 'correo', name: 'correo', displayName: 'Correo' },
                          { field: 'nit', name: 'nit', displayName: 'NIT' },
                          {
                            name: 'Seleccionar', enableFiltering: false,
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                          }
                        ];
                        $scope.gridPersona.data = [];

                        $scope.setTipoBusqueda = function (item) {
                          switch (parseInt(item.tipoBusquedaId)) {
                            case 1:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                              $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                              break;
                            case 2:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                              $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                              break;
                            case 3:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                              $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                              break;
                            default:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                          }
                        }

                        $scope.keyupFiltro = function (event) {
                          if (event.keyCode == 13) {
                            $scope.submitBuscarPersona();
                          }
                        }

                        $scope.submitBuscarPersona = function () {
                          var isValid = true;
                          if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                            isValid = null;
                            toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                          }
                          if (isValid) {
                            personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                              if (response.status == 'OK') {
                                toastr.success(response.message);
                                $scope.gridPersona.data = response.data;
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                        $scope.setPersona = function (item) {
                          var soloRepresenta = 1;
                          if ($scope.tipoPersona == 0) {
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                ngDialog.close();
                                return;
                              }
                            }
                            soloRepresenta = 0;
                          } else {
                            for (var i = 0; i < $scope.data.representantes.length; i++) {
                              if ($scope.data.representantes[i].tcPersona.personaId == item.personaId) {
                                ngDialog.close();
                                return;
                              }
                            }
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                soloRepresenta = 0;
                                break;
                              }
                            }
                          }
                          if (item.tcCultura == null || item.tcIdioma == null || item.tcEstadoCivil == null || item.tcSexo == null) {
                            $scope.panel.editarPersona = true;
                            $scope.persona = item;
                            toastr.error('Es necesario completar información de la persona seleccionada, hágalo en la parte inferior de esta pantalla');
                          } else {
                            $scope.data.personas.push(item);
                          }
                        }

                        $scope.setIdioma = function (item) {

                        }

                        $scope.setSexo = function (item) {

                        }

                        $scope.setEstadoCivil = function (item) {

                        }

                        $scope.setCultura = function (item) {

                        }

                        $scope.submitEditarPersona = function (isValid) {
                          if (isValid) {
                            var persona = $scope.persona;
                            personaService.edit(persona).then(function (response) {
                              if (response.status == 'OK') {
                                var tmp = $scope.data;
                                var data = {
                                  personaGestionId: 0,
                                  tcPersona: persona,
                                  ttGestion: {
                                    gestionId: tmp.gestionId,
                                    tcTipoGestion: tmp.tcTipoGestion,
                                    estadoId : tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcTipoBosque: tmp.tcTipoBosque,
                                    tcElaborador: tmp.tcElaborador,
                                    ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                    personaAnulaId: $scope.loginData.personaId
                                  },
                                  representanteLegal: 0,
                                  soloRepresenta: 0
                                }
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  })
                }
              }

              $scope.setPropietarios = function () {
                if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                  var data = [
                    {
                      personaId: 1,
                      personaDesc: $scope.data.razonSocial
                    }
                  ];
                  $scope.dataPersona = data;
                } else {
                  if ($scope.data.personas != undefined) {
                    if ($scope.data.personas.length > 0) {
                      var data = [];
                      for (var i = 0; i < $scope.data.personas.length; i++) {
                        if ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0)) {
                          data.push(angular.copy($scope.data.personas[i].tcPersona));
                        }
                      }
                      $scope.dataPersona = data;
                    }
                  }
                }
              }

              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataLibro = dataLibro.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.dataTipoPropietario = dataTipoPropietario.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var rfn = $scope.data.rfn;
                  $scope.data = {
                    rfn: rfn,
                    personas: [{
                      personaGestionId: 19,
                      estadoId: 1,
                      fechaRegistro: "2020-05-28T02:34:55.000+0000",
                      tcPersona: {
                        personaId: 616,
                        personaDesc: "Melvin Rivaí Hernández Camposeco",
                        cui: 2405071341413,
                        fechaVencimiento: "2027-06-16T00:00:00.000+0000",
                        tcMunicipio: {
                          municipioId: 301,
                          municipioDesc: "Santa Cruz del Quiché",
                          estadoId: 1,
                          fechaRegistro: "2018-08-07T16:19:26.000+0000",
                          tcDepartamento: {
                            departamentoId: 21,
                            departamentoDesc: "Quiché",
                            estadoId: 1,
                            fechaRegistro: "2018-08-07T15:51:54.000+0000",
                            tcPais: {
                              paisId: 1,
                              paisDesc: "Guatemala",
                              estadoId: 1,
                              fechaRegistro: "2018-07-25T20:02:54.000+0000"
                            }
                          },
                          codigo: 1401
                        },
                        direccion: "0 Calle 3-39 Zona 6",
                        telefono: "58038694",
                        correo: "norberta.brito1981@gmail.com",
                        foto: null,
                        tcUsuario: null,
                        fechaNacimiento: "1981-06-07T00:00:00.000+0000",
                        tcCultura: {
                          culturaId: 3,
                          culturaDesc: "Maya",
                          estadoId: 1,
                          fechaRegistro: "2018-08-28T20:10:44.000+0000"
                        },
                        tcIdioma: {
                          idiomaId: 18,
                          idiomaDesc: "K’iche‘",
                          estadoId: 1,
                          fechaRegistro: "2018-10-18T14:53:00.000+0000"
                        },
                        tcEstadoCivil: {
                          estadoCivilId: 2,
                          estadoCivilDesc: "Soltero (a)",
                          estadoId: 1,
                          fechaRegistro: "2018-08-28T20:11:14.000+0000"
                        },
                        tcSexo: {
                          sexoId: 1,
                          sexoDesc: "Femenino",
                          estadoId: 1,
                          fechaRegistro: "2019-09-13T10:44:03.000+0000"
                        },
                        estadoId: 1,
                        fechaRegistro: "2020-05-27T21:08:01.000+0000",
                        confirmado: 1,
                        passwordTmp: null,
                        nit: "24050713",
                        fechaUltModif: null,
                        tcOcupacion: {
                          ocupacionId: 2,
                          ocupacionDesc: "Estudiante",
                          estadoId: 1,
                          fechaRegistro: "2019-08-01T16:03:04.000+0000"
                        },
                        sigla: null,
                        rfn: null,
                        rf: null,
                        tcUsuarioSubregion: null,
                        usuario: null
                      },
                      representanteLegal: 0,
                      soloRepresenta: 0
                    }],
                    representantes: [{
                      personaGestionId: 20,
                      estadoId: 1,
                      fechaRegistro: "2020-05-28T02:38:01.000+0000",
                      tcPersona: {
                        personaId: 520,
                        personaDesc: "Carlos Alberto López González",
                        cui: 2301523171401,
                        fechaVencimiento: "2026-03-29T05:00:00.000+0000",
                        tcMunicipio: {
                          municipioId: 307,
                          municipioDesc: "Chinique",
                          estadoId: 1,
                          fechaRegistro: "2018-08-07T16:19:27.000+0000",
                          tcDepartamento: {
                            departamentoId: 21,
                            departamentoDesc: "Quiché",
                            estadoId: 1,
                            fechaRegistro: "2018-08-07T15:51:54.000+0000",
                            tcPais: {
                              paisId: 1,
                              paisDesc: "Guatemala",
                              estadoId: 1,
                              fechaRegistro: "2018-07-25T20:02:54.000+0000"
                            }
                          },
                          codigo: 1403
                        },
                        direccion: "Zona 1",
                        telefono: "58038694",
                        correo: "carlosalberto.lopez1993@gmail.com",
                        foto: null,
                        tcUsuario: null,
                        fechaNacimiento: "1993-08-16T05:00:00.000+0000",
                        tcCultura: {
                          culturaId: 3,
                          culturaDesc: "Maya",
                          estadoId: 1,
                          fechaRegistro: "2018-08-28T20:10:44.000+0000"
                        },
                        tcIdioma: {
                          idiomaId: 18,
                          idiomaDesc: "K’iche‘",
                          estadoId: 1,
                          fechaRegistro: "2018-10-18T14:53:00.000+0000"
                        },
                        tcEstadoCivil: {
                          estadoCivilId: 2,
                          estadoCivilDesc: "Soltero (a)",
                          estadoId: 1,
                          fechaRegistro: "2018-08-28T20:11:14.000+0000"
                        },
                        tcSexo: {
                          sexoId: 2,
                          sexoDesc: "Masculino",
                          estadoId: 1,
                          fechaRegistro: "2019-09-13T10:44:03.000+0000"
                        },
                        estadoId: 1,
                        fechaRegistro: "2020-01-27T18:07:30.000+0000",
                        confirmado: 1,
                        passwordTmp: "QVg1aE83WjQreXBhcVJDMlR1ZVpUdz09",
                        nit: "23015231",
                        fechaUltModif: null,
                        tcOcupacion: {
                          ocupacionId: 2,
                          ocupacionDesc: "Estudiante",
                          estadoId: 1,
                          fechaRegistro: "2019-08-01T16:03:04.000+0000"
                        },
                        sigla: null,
                        rfn: null,
                        rf: null,
                        tcUsuarioSubregion: null,
                        usuario: null
                      },
                      representanteLegal: 1,
                      soloRepresenta: 1
                    }],
                    tcTipoPropietario: {
                      tipoPropietarioId: 1,
                      tipoPropietarioDesc: "Individual",
                      estadoId: 1,
                      fechaRegistro: "2018-09-06T14:29:57.000+0000"
                    },
                    notificaciones: [{
                      notificacionGestionId: 80,
                      fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                      tcTipoNotificacion: {
                        tipoNotificacionId: 1,
                        tipoNotificacionDesc: "Dirección domiciliar",
                        esDireccion: 1,
                        esObligatorio: 1,
                        type: "text",
                        maxlength: 500,
                        estadoId: 1,
                        fechaRegistro: "2018-09-10T12:53:19.000+0000"
                      },
                      tcMunicipio: {
                        municipioId: 301,
                        municipioDesc: "Santa Cruz del Quiché",
                        estadoId: 1,
                        fechaRegistro: "2018-08-07T16:19:26.000+0000",
                        tcDepartamento: {
                          departamentoId: 21,
                          departamentoDesc: "Quiché",
                          estadoId: 1,
                          fechaRegistro: "2018-08-07T15:51:54.000+0000",
                          tcPais: {
                            paisId: 1,
                            paisDesc: "Guatemala",
                            estadoId: 1,
                            fechaRegistro: "2018-07-25T20:02:54.000+0000"
                          }
                        },
                        codigo: 1401
                      },
                      estadoId: 1,
                      notificacionGestionDesc: "Aldea El Chajbal"
                    }, {
                      notificacionGestionId: 81,
                      fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                      tcTipoNotificacion: {
                        tipoNotificacionId: 3,
                        tipoNotificacionDesc: "Teléfono",
                        esDireccion: 0,
                        esObligatorio: 1,
                        type: "text",
                        maxlength: 8,
                        estadoId: 1,
                        fechaRegistro: "2018-09-10T12:54:35.000+0000"
                      },
                      tcMunicipio: null,
                      estadoId: 1,
                      notificacionGestionDesc: "51634020"
                    }, {
                      notificacionGestionId: 82,
                      fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                      tcTipoNotificacion: {
                        tipoNotificacionId: 4,
                        tipoNotificacionDesc: "Celular",
                        esDireccion: 0,
                        esObligatorio: 1,
                        type: "text",
                        maxlength: 8,
                        estadoId: 1,
                        fechaRegistro: "2018-09-10T12:54:44.000+0000"
                      },
                      tcMunicipio: null,
                      estadoId: 1,
                      notificacionGestionDesc: "58038694"
                    }, {
                      notificacionGestionId: 83,
                      fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                      tcTipoNotificacion: {
                        tipoNotificacionId: 5,
                        tipoNotificacionDesc: "Correo electrónico",
                        esDireccion: 0,
                        esObligatorio: 1,
                        type: "email",
                        maxlength: 100,
                        estadoId: 1,
                        fechaRegistro: "2018-09-10T12:54:52.000+0000"
                      },
                      tcMunicipio: null,
                      estadoId: 1,
                      notificacionGestionDesc: "melvinrivai@gmail.com"
                    }],
                    fincas: [{
                      fincaGestionId: 19,
                      estadoId: 1,
                      fechaRegistro: "2020-05-28T03:01:27.000+0000",
                      tcFinca: {
                        fincaId: 22,
                        fincaDesc: "Rústica",
                        direccion: "El Chajbal",
                        gtmX: 429903.0,
                        gtmY: 1667643.0,
                        norte: "Gregorio Bernardo Ponsio,  línea al medio",
                        sur: "Gregorio Bernardo Ponsio,  línea al medio",
                        este: "Finca matríz, línea al medio",
                        oeste: "Bernardo López, carretera de asfalto por medio.",
                        area: 0.13,
                        notario: null,
                        numeroDocumento: 27429,
                        folio: 230,
                        libro: "111",
                        tcMunicipio: {
                          municipioId: 301,
                          municipioDesc: "Santa Cruz del Quiché",
                          estadoId: 1,
                          fechaRegistro: "2018-08-07T16:19:26.000+0000",
                          tcDepartamento: {
                            departamentoId: 21,
                            departamentoDesc: "Quiché",
                            estadoId: 1,
                            fechaRegistro: "2018-08-07T15:51:54.000+0000",
                            tcPais: {
                              paisId: 1,
                              paisDesc: "Guatemala",
                              estadoId: 1,
                              fechaRegistro: "2018-07-25T20:02:54.000+0000"
                            }
                          },
                          codigo: 1401
                        },
                        tcMunicipioEmite: null,
                        tcTipoPropiedad: {
                          tipoPropiedadId: 3,
                          tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                          estadoId: 1,
                          fechaRegistro: "2018-09-17T13:28:38.000+0000",
                          esPropiedad: 0
                        },
                        estadoId: 1,
                        fechaRegistro: null,
                        tcLibro: {
                          libroId: 21,
                          libroDesc: "Quiché",
                          estadoId: 1,
                          fechaRegistro: "2020-01-03T21:31:02.000+0000"
                        }
                      },
                      fechaEmision: new Date('2020-05-07T06:00:00.000+0000'),
                      notario: null,
                      tcMunicipioEmite: null,
                      numeroDocumento: 27429,
                      folio: 230,
                      libro: "111",
                      tcTipoPropiedad: {
                        tipoPropiedadId: 3,
                        tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                        estadoId: 1,
                        fechaRegistro: "2018-09-17T13:28:38.000+0000",
                        esPropiedad: 0
                      },
                      tcLibro: {
                        libroId: 21,
                        libroDesc: "Quiché",
                        estadoId: 1,
                        fechaRegistro: "2020-01-03T21:31:02.000+0000"
                      },
                      propietarios: [{
                        propietarioFincaId: 0,
                        estadoId: 1,
                        fechaRegistro: "2020-09-10T19:02:10.413+0000",
                        tcPersona: {
                          personaId: 1,
                          personaDesc: null,
                          cui: 0,
                          fechaVencimiento: null,
                          tcMunicipio: null,
                          direccion: null,
                          telefono: null,
                          correo: null,
                          foto: null,
                          tcUsuario: null,
                          fechaNacimiento: null,
                          tcCultura: null,
                          tcIdioma: null,
                          tcEstadoCivil: null,
                          tcSexo: null,
                          estadoId: 1,
                          fechaRegistro: "2020-09-10T19:02:10.413+0000",
                          confirmado: 0,
                          passwordTmp: null,
                          nit: null,
                          fechaUltModif: null,
                          tcOcupacion: null,
                          sigla: null,
                          rfn: null,
                          rf: null,
                          tcUsuarioSubregion: null,
                          usuario: null
                        }
                      }]
                    }]
                  };
                }
                $scope.setPropietarios();
              }

              $scope.setLibroFinca = function (item, finca) {
                finca.tcLibro = item;
                finca.tcMunicipio = null;
              }

              $scope.setDepartamentoFinca = function (item, finca, limpiar) {
                if (limpiar == undefined) {
                  limpiar = true;
                }
                if (limpiar) {
                  finca.tcMunicipio = null;
                }
                if (finca.tcTipoPropiedad != null) {
                  if (finca.tcTipoPropiedad.tipoPropiedadId != 3) {
                    finca.tcLibro = null;
                    municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        finca.dataMunicipio = res.data;
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                }
              }

              //cargar municipio de las fincas ya cargadas
              if ($scope.data.fincas != null) {
                var encontrado = false;
                for (var i = 0; i < $scope.data.fincas.length; i++) {
                  if ($scope.data.fincas[i].tcMunicipioEmite != null) {
                    $scope.setDepartamentoFinca({departamentoId : $scope.data.fincas[i].tcMunicipioEmite.tcDepartamento.departamentoId}, $scope.data.fincas[i], false);
                  } else {
                    $scope.data.fincas[i].tcMunicipioEmite = {
                      municipioId : null,
                      tcDepartamento: {
                        departamentoId: null
                      }
                    }
                  }
                  if ($scope.data.fincas[i].propietarios == null) {
                    $scope.data.fincas[i].propietarios = [];
                  }
                  $scope.data.fincas[i].personas = [];
                  for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                    var tcPersona = $scope.data.fincas[i].propietarios[j].tcPersona;
                    $scope.data.fincas[i].personas.push(tcPersona);
                  }
                  $scope.data.fincas[i].fechaEmision = new Date($scope.data.fincas[i].fechaEmision);
                }
              }

              $scope.agregarPersona = function (tipo) {
                $scope.tipoPersona = tipo;
                $scope.tituloPersona = "Agregar datos de la persona";
                delete $scope.persona;
                ngDialog.open({
                  template: 'app/persona/persona.add.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataOcupacion: ['ocupacionService',
                      function ( ocupacionService ){
                        return ocupacionService.listaActiva();
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload', 'dataOcupacion',
                    function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload, dataOcupacion) {
                      $scope.dataOcupacion = dataOcupacion.data;
                      $scope.persona = {
                        estadoId: 1
                      }
                      $scope.direccion = {
                        paisId: 1
                      }

                      $scope.cargarListaDepartamento = function (item) {
                        $scope.direccion.departamentoId = null;
                        $scope.persona.municipioId = null;
                        departamentoService.listaPorPais(item.paisId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataDepartamento = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }

                      $scope.cargarListaMunicipio = function (item) {
                        $scope.persona.municipioId = null;
                        municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataMunicipio = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }

                      $scope.onFileSelect = function (file, tipoAnexoId) {
                        Upload.upload({
                          url: appSettings.urlServiceBase + 'file/base64/imagen',
                          method: 'POST',
                          file: file,
                          sendFieldsAs: 'form',
                          fields: {
                            data: []
                          }
                        }).then(function (resp) {
                          var data = resp.data;
                          if (data.status == 'OK') {
                            var obj = data.data[0];
                            $scope.image = obj.data;
                            $scope.persona.foto = obj.data;
                          } else {
                            toastr.error(resp.data.message)
                          }
                        }, function (resp) {
                          toastr.error('Error status: ' + resp.status);
                        }, function (evt) {
                          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                          $scope.avance = progressPercentage;
                        });
                      }

                      $scope.submitFormPersona = function (isValid) {
                        if (isValid) {
                          if ($scope.persona.foto == null) {
                            /*isValid = false;
                            toastr.error('Debe indicar una foto');*/
                            $scope.validoFoto = 1;
                          }
                          if ($scope.validoFoto == 0 && isValid) {
                            isValid = false;
                            toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                          }
                        }
                        if (isValid) {
                          var item = $scope.persona;
                          personaService.add(item).then(function (res) {
                            if (res.status == 'OK') {
                              item = res.data[0];
                              var soloRepresenta = 1;
                              if ($scope.tipoPersona == 0) {
                                soloRepresenta = 0;
                              } else {
                                for (var i = 0; i < $scope.data.personas.length; i++) {
                                  if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                    soloRepresenta = 0;
                                    break;
                                  }
                                }
                              }
                              var tmp = $scope.data;
                              var data = {
                                personaGestionId: 0,
                                tcPersona: item,
                                ttGestion: {
                                  gestionId: tmp.gestionId,
                                  tcTipoGestion: tmp.tcTipoGestion,
                                  estadoId : tmp.estadoId,
                                  tcPersonaCrea: tmp.tcPersonaCrea,
                                  tcTipoBosque: tmp.tcTipoBosque,
                                  tcElaborador: tmp.tcElaborador,
                                  ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                  personaAnulaId: $scope.loginData.personaId
                                },
                                representanteLegal: $scope.tipoPersona,
                                soloRepresenta: soloRepresenta
                              }
                              gestionService.agregarPersona(data).then(function (response) {
                                if (response.status == 'OK') {
                                  registro = response.data[0];
                                  persona = registro.tcPersona;
                                  registro.tcPersona = item;
                                  if (parseInt($scope.tipoPersona) == 1) {
                                    $scope.data.representantes.push(registro);
                                  } else {
                                    item.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                    $scope.data.personas.push(registro);
                                    $scope.setPropietarios();
                                  }
                                  toastr.success(res.message);
                                } else {
                                  toastr.error(response.message);
                                }
                                ngDialog.close();
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }

                      $scope.setIdioma = function (item) {
                        $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                      }

                      $scope.setSexo = function (item) {
                        $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                      }

                      $scope.setEstadoCivil = function (item) {
                        $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                      }

                      $scope.setCultura = function (item) {
                        $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                      }

                    }]
                })
              }

            }]
        })
        .state('index.fuentesemillera.edit', {
          url: '/:fuenteSemilleraId/edit',
          templateUrl: 'app/fuentesemillera/fuentesemillera.add.tpl.html',
          resolve: {
            dataFuenteSemillera: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.get( $stateParams.fuenteSemilleraId );
              }],
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataFuenteSemillera', 'dataEstado', 'dataDepartamento',
            function (  $scope,   $state,   toastr,   fuentesemilleraService,   dataFuenteSemillera, dataEstado, dataDepartamento ) {
              $scope.dataDepartamentoFinca = dataDepartamento.data;
              $scope.data = dataFuenteSemillera.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.cargarListaMunicipioFinca($scope.data.tcFinca.tcMunicipio.tcDepartamento);
              $scope.setMunicipioFinca($scope.data.tcFinca.tcMunicipio);
              $scope.direccion.departamentoId = $scope.data.tcFinca.tcMunicipio.tcDepartamento.departamentoId;
              $scope.direccion.municipioId = $scope.data.tcFinca.tcMunicipio.municipioId;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.setFinca = function ( item ) {
                $scope.data.tcFinca = item;
              }

            }]
        })
        .state('index.fuentesemillera.cosecha', {
          url: '/:fuenteSemilleraId/cosecha',
          templateUrl: 'app/fuentesemillera/fuentesemillera.cosecha.tpl.html',
          resolve: {
            dataCosecha: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getListaCosecha($stateParams.fuenteSemilleraId);
              }]
          },
          controller: ['$scope', '$state', 'dataCosecha',
            function (  $scope,   $state,   dataCosecha) {

              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.mostrarLotes = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'codigo', name: 'codigo', displayName: 'Código del lote' },
                { field:'anio', name: 'anio', displayName: 'Año' },
                { field:'tcEspecie.nombreCientifico', name: 'nombreCientifico', displayName: 'Especie' },
                { field:'cantidad', name: 'cantidad', displayName: 'Cantidad (Kg)' },
                { field:'existencia', name: 'existencia', displayName: 'Saldo (Kg)' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.listarVentas(row.entity)" title="Listar ventas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataCosecha.data;

              $scope.editRow = function ( row ) {
                $state.go('^.editcosecha',{ loteSemillaId: row.loteSemillaId });
              }

              $scope.listarVentas = function ( row ) {
                $scope.dataFuenteSemillera.loteSemilla = row;
                $state.go('^.ventasemilla', { loteSemillaId: row.loteSemillaId });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.fuentesemillera.addcosecha', {
          url: '/addcosecha',
          templateUrl: 'app/fuentesemillera/fuentesemillera.semilla.tpl.html',
          resolve: {
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.listaActiva();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataEspecie', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataEspecie, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataEspecie = dataEspecie.data;
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = {
                tcEspecie: {},
                tcFuenteSemillera: $scope.dataFuenteSemillera.data,
                tcComercializador: {}
              };

              $scope.setEspecie = function ( item ) {
                $scope.data.tcEspecie = item;
              }

              $scope.setComercializador = function ( item ) {
                $scope.data.tcComercializador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.data.fechaAutorizacion = new Date();
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addLoteSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.fuentesemillera.editcosecha', {
          url: '/:loteSemillaId/editcosecha',
          templateUrl: 'app/fuentesemillera/fuentesemillera.semilla.tpl.html',
          resolve: {
            dataLoteSemilla: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getLoteSemilla($stateParams.loteSemillaId);
              }],
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.listaActiva();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataEspecie', 'dataLoteSemilla', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataEspecie, dataLoteSemilla, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataEspecie = dataEspecie.data;
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = dataLoteSemilla.data[0];
              console.log($scope.data);
              $scope.data.tcFuenteSemillera = $scope.dataFuenteSemillera.data;

              $scope.setEspecie = function ( item ) {
                $scope.data.tcEspecie = item;
              }

              $scope.setComercializador = function ( item ) {
                $scope.data.tcComercializador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addLoteSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.fuentesemillera.ventasemilla', {
          url: '/:loteSemillaId/ventasemilla',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventasemilla.list.tpl.html',
          resolve: {
            dataVenta: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getListaVentaSemilla($stateParams.loteSemillaId);
              }]
          },
          controller: ['$scope', '$state', 'dataVenta', 'utilsService',
            function (  $scope,   $state,   dataVenta, utilsService) {

              if ($scope.dataFuenteSemillera.loteSemilla == null) {
                $state.go( '^.list' );
              }

              $scope.mostrarEdicion = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.mostrarVentas = function ( paraPlanta ) {
                if (paraPlanta == 1) {
                  return true;
                }
                return false;
              }

              $scope.regresarListaLoteSemilla = function () {
                var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'codigoCompleto', name: 'codigoCompleto', displayName: 'Código del venta' },
                { field:'tcComprador.comercializadorDesc', name: 'comercializadorDesc', displayName: 'Comprador' },
                { field:'cantidad', name: 'cantidad', displayName: 'Cantidad (Kg)' },
                { name: 'paraPlanta', displayName: "Para plantas", field: 'paraPlanta', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaPlanta(row.entity.paraPlanta)}}</div>' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarEdicion(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarVentas(row.entity.paraPlanta)" ng-click="grid.appScope.listarVentas(row.entity)" title="Listar venta de plantas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirNota(row.entity)" title="Imprimir nota"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataVenta.data;

              $scope.editRow = function ( row ) {
                $state.go('^.editventasemilla', { ventaSemillaId: row.ventaSemillaId });
              }

              $scope.listarVentas = function ( row ) {
                $state.go('^.ventaplanta', { ventaSemillaId: row.ventaSemillaId });
              }

              $scope.imprimirNota = function (item) {
                var strCantidad = $scope.numeroALetras(item.cantidad);
                item.tcLoteSemilla = $scope.dataFuenteSemillera.loteSemilla;
                item.tcLoteSemilla.tcFuenteSemillera = $scope.dataFuenteSemillera.data;
                utilsService.generarNotaControlSemilla(item, strCantidad.toLowerCase(), $scope.loginData.usuarioDesc, $scope.dataEmpresa);
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                switch (estadoId) {
                  case 1: return "Activo";
                  case 2: return "Finalizado";
                  default: return "Inactivo";
                }
              }

              $scope.etiquetaPlanta = function ( paraPlanta ) {
                if (paraPlanta == 1) {
                  return 'Si';
                }
                return 'No';
              }

            }]
        })
        .state('index.fuentesemillera.addventasemilla', {
          url: '/addventasemilla',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventasemilla.tpl.html',
          resolve: {
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = {
                tcComprador: {},
                tcLoteSemilla: $scope.dataFuenteSemillera.loteSemilla
              };

              $scope.setComprador = function ( item ) {
                $scope.data.tcComprador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var loteSemillaId = $scope.dataFuenteSemillera.loteSemilla.loteSemillaId;
                $state.go('^.ventasemilla', { loteSemillaId: loteSemillaId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.dataParaPlanta = [
                {paraPlantaId: 0, paraPlantaDesc: 'No'},
                {paraPlantaId: 1, paraPlantaDesc: 'Si'}
              ];
              $scope.data.estadoId = 1;
              $scope.data.fechaEmision = new Date();
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addVentaSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.fuentesemillera.editventasemilla', {
          url: '/:ventaSemillaId/editventasemilla',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventasemilla.tpl.html',
          resolve: {
            dataLoteSemilla: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getLoteSemilla($stateParams.loteSemillaId);
              }],
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.listaActiva();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataEspecie', 'dataLoteSemilla', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataEspecie, dataLoteSemilla, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataEspecie = dataEspecie.data;
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = dataLoteSemilla.data[0];
              $scope.data.tcFuenteSemillera = $scope.dataFuenteSemillera.data;

              $scope.setEspecie = function ( item ) {
                $scope.data.tcEspecie = item;
              }

              $scope.setComercializador = function ( item ) {
                $scope.data.tcComercializador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addLoteSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.fuentesemillera.ventaplanta', {
          url: '/:ventaSemillaId/ventaplanta',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventaplanta.list.tpl.html',
          resolve: {
            dataLotePlanta: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getLotesPlantaPorVentaSemilla($stateParams.ventaSemillaId);
              }],
            dataVenta: ['fuentesemilleraService', '$stateParams', 'dataLotePlanta',
              function ( fuentesemilleraService, $stateParams, dataLotePlanta ){
                var data = dataLotePlanta.data;
                if (data.length > 0) {
                  var lotePlanta = data[0];
                  return fuentesemilleraService.getListaVentaPlanta(lotePlanta.lotePlantaId);
                } else {
                  return fuentesemilleraService.getListaVentaPlantaVacio();
                }
              }]
          },
          controller: ['$scope', '$state', 'dataVenta', 'dataLotePlanta', '$stateParams',
            function (  $scope,   $state,   dataVenta, dataLotePlanta, $stateParams) {

              $scope.dataLotePlanta = [];

              if (dataLotePlanta.data.length > 0) {
                $scope.dataLotePlanta = dataLotePlanta.data;
              }

              $scope.mostrarEdicion = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.mostrarVentas = function ( paraPlanta ) {
                if (paraPlanta == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'codigoCompleto', name: 'codigoCompleto', displayName: 'Código del venta' },
                { field:'tcComprador.comercializadorDesc', name: 'comercializadorDesc', displayName: 'Comprador' },
                { field:'cantidad', name: 'cantidad', displayName: 'Cantidad (Kg)' },
                { name: 'paraPlanta', displayName: "Para plantas", field: 'paraPlanta', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaPlanta(row.entity.paraPlanta)}}</div>' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.mostrarEdicion(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.mostrarVentas(row.entity.paraPlanta)" ng-click="grid.appScope.listarVentas(row.entity)" title="Listar venta de plantas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataVenta.data;

              $scope.editRow = function ( row ) {
                $state.go('^.editventasemilla', { ventaSemillaId: row.ventaSemillaId });
              }

              $scope.listarVentas = function ( row ) {
                $state.go('^.ventaplanta', { ventaSemillaId: row.ventaSemillaId });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                switch (estadoId) {
                  case 1: return "Activo";
                  case 2: return "Finalizado";
                  default: return "Inactivo";
                }
              }

              $scope.etiquetaPlanta = function ( paraPlanta ) {
                if (paraPlanta == 1) {
                  return 'Si';
                }
                return 'No';
              }

            }]
        })
        .state('index.fuentesemillera.addventaplanta', {
          url: '/addventaplanta',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventaplanta.tpl.html',
          resolve: {
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = {
                tcComprador: {},
                tcLoteSemilla: $scope.dataFuenteSemillera.loteSemilla
              };

              $scope.setComprador = function ( item ) {
                $scope.data.tcComprador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var loteSemillaId = $scope.dataFuenteSemillera.loteSemilla.loteSemillaId;
                $state.go('^.ventasemilla', { loteSemillaId: loteSemillaId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.dataParaPlanta = [
                {paraPlantaId: 0, paraPlantaDesc: 'No'},
                {paraPlantaId: 1, paraPlantaDesc: 'Si'}
              ];
              $scope.data.estadoId = 1;
              $scope.data.fechaEmision = new Date();
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addVentaSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.fuentesemillera.editventaplanta', {
          url: '/:ventaSemillaId/editventaplanta',
          templateUrl: 'app/fuentesemillera/fuentesemillera.ventasplanta.tpl.html',
          resolve: {
            dataLoteSemilla: ['fuentesemilleraService', '$stateParams',
              function ( fuentesemilleraService, $stateParams ){
                return fuentesemilleraService.getLoteSemilla($stateParams.loteSemillaId);
              }],
            dataEstado: ['fuentesemilleraService',
              function ( fuentesemilleraService ){
                return fuentesemilleraService.listStatus();
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.listaActiva();
              }],
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'dataEstado', 'dataEspecie', 'dataLoteSemilla', 'dataComercializador',
            function (  $scope,   $state,   toastr,   fuentesemilleraService, dataEstado, dataEspecie, dataLoteSemilla, dataComercializador ) {
              if ($scope.dataFuenteSemillera.data == null) {
                $state.go( '^.list' );
              }
              $scope.dataEspecie = dataEspecie.data;
              $scope.dataComercializador = dataComercializador.data;
              $scope.data = dataLoteSemilla.data[0];
              $scope.data.tcFuenteSemillera = $scope.dataFuenteSemillera.data;

              $scope.setEspecie = function ( item ) {
                $scope.data.tcEspecie = item;
              }

              $scope.setComercializador = function ( item ) {
                $scope.data.tcComercializador = item;
              }

              $scope.cancelarLoteSemilla = function () {
                var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  fuentesemilleraService.addLoteSemilla( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var fuenteSemilleraId = $scope.dataFuenteSemillera.data.fuenteSemilleraId;
                      $state.go('^.cosecha', { fuenteSemilleraId: fuenteSemilleraId });
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
