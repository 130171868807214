angular.module('app.tiponotificacion', [
  'ui.router',
  'toastr',
  'app.tiponotificacion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tiponotificacion', {
          abstract: true,
          url: 'tiponotificacion',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Tipo de notificación';
            }]
        })
        .state('index.tiponotificacion.list', {
          url: '',
          templateUrl: 'app/tiponotificacion/tiponotificacion.list.tpl.html',
          resolve: {
            dataTipoNotificacion: ['tiponotificacionService',
              function ( tiponotificacionService ){
                return tiponotificacionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataTipoNotificacion',
            function (  $scope,   $state,   dataTipoNotificacion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tipoNotificacionDesc', name: 'tipoNotificacionDesc', displayName: 'Tipo de documento' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataTipoNotificacion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ tipoNotificacionId: row.tipoNotificacionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.tiponotificacion.add', {
          url: '/add',
          templateUrl: 'app/tiponotificacion/tiponotificacion.add.tpl.html',
          resolve: {
            dataEstado: ['tiponotificacionService',
              function ( tiponotificacionService ){
                return tiponotificacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tiponotificacionService', 'dataEstado',
            function (  $scope,   $state,   toastr,   tiponotificacionService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tiponotificacionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.tiponotificacion.edit', {
          url: '/:tipoNotificacionId/edit',
          templateUrl: 'app/tiponotificacion/tiponotificacion.add.tpl.html',
          resolve: {
            dataTipoNotificacion: ['tiponotificacionService', '$stateParams',
              function ( tiponotificacionService, $stateParams ){
                return tiponotificacionService.get( $stateParams.tipoNotificacionId );
              }],
            dataEstado: ['tiponotificacionService',
              function ( tiponotificacionService ){
                return tiponotificacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tiponotificacionService', 'dataTipoNotificacion', 'dataEstado',
            function (  $scope,   $state,   toastr,   tiponotificacionService,   dataTipoNotificacion, dataEstado) {
              $scope.data = dataTipoNotificacion.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tiponotificacionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
