angular.module('app.perfilmanual', [
  'ui.router',
  'toastr',
  'app.perfilmanual.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.perfilmanual', {
          abstract: true,
          url: 'perfilmanual',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Guía de usuario y formatos';

            }]
        })
        .state('index.perfilmanual.list', {
          url: '',
          templateUrl: 'app/perfilmanual/perfilmanual.list.tpl.html',
          resolve: {
            dataManual: ['manualService',
              function ( manualService ){
                return manualService.listaActiva();
              }],
            dataPerfil: ['perfilService',
              function ( perfilService ){
                return perfilService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'dataPerfil', 'perfilmanualService', 'dataManual', 'toastr', 'manualService', '$window',
            function (  $scope,   $state, dataPerfil, perfilmanualService, dataManual, toastr, manualService, $window) {
              $scope.dataPerfil = dataPerfil.data;
              $scope.dataManual = dataManual.data;
              $scope.mostrarAgregar = false;

              $scope.data = {
                estadoId: 1
              }

              $scope.showIcon = function ( estadoId, id ) {
                if (estadoId == id) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcManual.manualDesc', name: 'manualDesc', displayName: 'Guia' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: "10%", cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: "10%",
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.showIcon(row.entity.estadoId, 1)" ng-click="grid.appScope.editRow(row.entity, 0)" title="Deshabilitar"><i class="fa fa-power-off" aria-hidden="true"></i></button>'
                    +' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.showIcon(row.entity.estadoId, 0)" ng-click="grid.appScope.editRow(row.entity, 1)" title="Habilitar"><i class="fa fa-lightbulb-o" aria-hidden="true"></i></button>'
                    +' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.abrirManual(row.entity)" title="Ver guia"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = [];

              $scope.abrirManual = function (item) {
                if (item.tcManual.tipoArchivoId == 2) {
                  $window.open(item.tcManual.rutaArchivo, '_blank');
                } else {
                  manualService.getFile( item.tcManual.manualId );
                }
              }

              $scope.setPerfil = function ( item ) {
                if (item == null) {
                  return;
                }
                perfilmanualService.listaByPerfil( item.perfilId ) .then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.gridOptions.data = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.agregarManual = function () {
                $scope.mostrarAgregar = true;
              }

              $scope.submitForm = function ( isValid ) {
                if (isValid) {
                  perfilmanualService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.data.tcManual.manualId = null;
                      $scope.mostrarAgregar = false;
                      $scope.setPerfil($scope.data.tcPerfil);
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.editRow = function ( row ) {
                if (row.estadoId == 1) {
                  row.estadoId = 0;
                } else {
                  row.estadoId = 1;
                }
                perfilmanualService.edit(row).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.data.tcManual.manualId = null;
                    $scope.mostrarAgregar = false;
                    $scope.setPerfil($scope.data.tcPerfil);
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.perfilmanual.add', {
          url: '/add',
          templateUrl: 'app/perfilmanual/perfilmanual.add.tpl.html',
          resolve: {
            dataEstado: ['perfilmanualService',
              function ( perfilmanualService ){
                return perfilmanualService.listStatus();
              }],
            dataTipoGarantia: ['tipogarantiaService',
              function ( tipogarantiaService ){
                return tipogarantiaService.listaActiva();
              }],
            dataTipoMonto: ['tipomontoService',
              function ( tipomontoService ){
                return tipomontoService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'perfilmanualService', 'dataEstado', 'dataTipoGarantia', 'dataTipoMonto',
            function (  $scope,   $state,   toastr,   perfilmanualService, dataEstado, dataTipoGarantia, dataTipoMonto) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoGarantia = dataTipoGarantia.data;
              $scope.dataTipoMonto = dataTipoMonto.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  perfilmanualService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.perfilmanual.edit', {
          url: '/:perfilManualId/edit',
          templateUrl: 'app/perfilmanual/perfilmanual.add.tpl.html',
          resolve: {
            dataPerfilManual: ['perfilmanualService', '$stateParams',
              function ( perfilmanualService, $stateParams ){
                return perfilmanualService.get( $stateParams.perfilManualId );
              }],
            dataEstado: ['perfilmanualService',
              function ( perfilmanualService ){
                return perfilmanualService.listStatus();
              }],
            dataTipoGarantia: ['tipogarantiaService',
              function ( tipogarantiaService ){
                return tipogarantiaService.listaActiva();
              }],
            dataTipoMonto: ['tipomontoService',
              function ( tipomontoService ){
                return tipomontoService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'perfilmanualService', 'dataTipoMonto', 'dataEstado',
          'dataRegion', 'dataPais', 'dataDepartamento', 'dataMunicipio', 'dataSubRegion',
            function ( $scope, $state, toastr, perfilmanualService, dataTipoMonto, dataEstado,
            dataRegion, dataPais, dataDepartamento, dataMunicipio, dataSubRegion) {
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataPais = dataPais.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.dataSubRegion = dataSubRegion.data;
              $scope.dataMunicipio = dataMunicipio.data;
              $scope.data = dataTipoMonto.data;
              $scope.variable = {
                paisId : dataTipoMonto.data.paisId,
                departamentoId : dataTipoMonto.data.departamentoId,
                regionId : dataTipoMonto.data.regionId
              }
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  delete $scope.data.subregionDesc;
                  delete $scope.data.regionId;
                  delete $scope.data.regionDesc;
                  delete $scope.data.municipioDesc;
                  delete $scope.data.departamentoId;
                  delete $scope.data.departamentoDesc;
                  delete $scope.data.paisId;
                  perfilmanualService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
