angular.module('app.lesionEconomica', [
  'ui.router',
  'toastr',
  'app.lesionEconomica.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.lesionEconomica', {
          abstract: true,
          url: 'lesionEconomica',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Lesión económica para avalúos';
            }]
        })
        .state('index.lesionEconomica.list', {
          url: '',
          templateUrl: 'app/lesionEconomica/lesionEconomica.list.tpl.html',
          resolve: {
            dataLesionEconomica: ['lesionEconomicaService',
              function ( lesionEconomicaService ){
                return lesionEconomicaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataLesionEconomica',
            function (  $scope,   $state,   dataLesionEconomica) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcTipoBosque.tipoBosqueDesc', name: 'tipoBosqueDesc', displayName: 'Tipo bosque' },
                { field:'monto', name: 'monto', displayName: 'Monto (ha)' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataLesionEconomica.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ lesionEconomicaId: row.lesionEconomicaId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.lesionEconomica.add', {
          url: '/add',
          templateUrl: 'app/lesionEconomica/lesionEconomica.add.tpl.html',
          resolve: {
            dataEstado: ['lesionEconomicaService',
              function ( lesionEconomicaService ){
                return lesionEconomicaService.listStatus();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'lesionEconomicaService', 'dataEstado', 'dataTipoBosque',
            function (  $scope,   $state,   toastr,   lesionEconomicaService, dataEstado, dataTipoBosque) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoBosque = dataTipoBosque.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  lesionEconomicaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.lesionEconomica.edit', {
          url: '/:lesionEconomicaId/edit',
          templateUrl: 'app/lesionEconomica/lesionEconomica.add.tpl.html',
          resolve: {
            dataLesionEconomica: ['lesionEconomicaService', '$stateParams',
              function ( lesionEconomicaService, $stateParams ){
                return lesionEconomicaService.get( $stateParams.lesionEconomicaId );
              }],
            dataEstado: ['lesionEconomicaService',
              function ( lesionEconomicaService ){
                return lesionEconomicaService.listStatus();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'lesionEconomicaService', 'dataLesionEconomica', 'dataEstado', 'dataTipoBosque',
            function (  $scope,   $state,   toastr,   lesionEconomicaService,   dataLesionEconomica, dataEstado, dataTipoBosque) {
              $scope.data = dataLesionEconomica.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoBosque = dataTipoBosque.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  lesionEconomicaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
