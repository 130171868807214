angular.module('app.solicitud', [
  'ui.router',
  'toastr',
  'app.solicitud.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.solicitud', {
            abstract: true,
            url: 'solicitud',
            template: '<div ui-view></div>',
            resolve: {
            },
            controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService', 'exentoService',
              function ($scope, toastr, $timeout, gestionService, utilsService, exentoService) {
                $scope.module = 'Solicitud';
                $scope.globales.totalPaginas = 0;

                $scope.imprimirPlan = function (row) {
                  var gestionId = row.ttGestion.gestionId;
                  try {
                    gestionService.getConCronograma(gestionId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var dataResumen = data.rodal;
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                            }
                            if (dataResumen[i].especies[j].fuste != null) {
                              dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                            }
                          }
                        }
                        //generales
                        if (data.ttInventarioGestion == undefined || data.ttInventarioGestion == null) {
                          toastr.error("La información de inventario esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.ttPlanificacionGestion == undefined || data.ttPlanificacionGestion == null) {
                          toastr.error("La información de planificación esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.cronograma == undefined || data.cronograma == null) {
                          toastr.error("El cronograma esta incompleto, favor de revisar");
                          return;
                        }
                        try {
                          if (data.ttInventarioGestion.data != undefined && data.ttInventarioGestion.data != null) {
                            data.ttInventarioGestion.data = JSON.parse(data.ttInventarioGestion.data);
                          }
                          try {
                            data.ttInventarioGestion.brinzal = JSON.parse(data.ttInventarioGestion.brinzal);
                          } catch (e) {
                            data.ttInventarioGestion.brinzal = [];
                          }
                          try {
                            data.ttInventarioGestion.latizal = JSON.parse(data.ttInventarioGestion.latizal);
                          } catch (e) {
                            data.ttInventarioGestion.latizal = [];
                          }
                          data.cronograma = JSON.parse(data.cronograma);
                          toastr.info('Espere mientras se imprimen los documentos', 'Imprimiendo');
                          utilsService.generarPlan($scope.dataEmpresa, data);
                          $timeout(function () {
                            utilsService.generarSolicitudLicencia(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                            $scope.imprimirCronograma(data);
                          }, 3000);
                          if (data.ttResumenGestion.noTurnos > 1) {
                            $timeout(function () {
                              utilsService.generarPoa($scope.dataEmpresa, data, 1);
                              $timeout(function () {
                                $scope.globales.totalPaginasPoa = utilsService.getLastPagePoa();
                                var totalPaginas = $scope.globales.totalPaginasPoa;
                                utilsService.generarCronograma($scope.dataEmpresa, data, totalPaginas, 0, 1);
                              }, 3000);
                            }, 1000);
                          }
                        } catch (e) {
                          toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador. " + e.message);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } catch (e) {
                    toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador");
                  }
                }

                $scope.imprimirCronograma = function (data) {
                  $scope.globales.totalPaginas = utilsService.getLastPage();
                  if ($scope.globales.totalPaginas <= 0) {
                    toastr.error("Debe imprimir primero el plan luego el cronograma");
                  } else {
                    utilsService.generarCronograma($scope.dataEmpresa, data, $scope.globales.totalPaginas);
                  }
                }

                $scope.imprimirTareaExento = function (item, sigla, exentoId) {
                  switch (item.tcTask.taskId) {
                    case 37:
                      if (exentoId == null) {
                        toastr.warning('Este tarea no produce ninguna documento');
                        return;
                      }
                      exentoService.get(exentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          utilsService.generarSolicitudExento(data, $scope.dataEmpresa);
                          utilsService.generarCaratulaTramiteExento(data, 1, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 38:
                      toastr.warning('Este tarea no produce ninguna documento');
                      break;
                    case 39:
                      toastr.warning('Este tarea no produce ninguna documento');
                      break;
                    case 22:
                      tareaService.getDatosTareaExento(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.estadoId == 20) {
                            swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                          } else {
                            var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 23:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var ventas = data.ttExento.ventas;
                          for (var i = 0; i < ventas.length; i++) {
                            var saldos = ventas[i].saldos;
                            for (var j = 0; j < saldos.length; j++) {
                              saldos[j].lenia = saldos[j].ttSaldoCargaVenta.lenia;
                              saldos[j].troza = saldos[j].ttSaldoCargaVenta.troza;
                              saldos[j].total = saldos[j].ttSaldoCargaVenta.volumen;
                            }
                          }
                          utilsService.generarInformeCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 24:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var especies = data.ttExento.especies;
                          for (var i = 0; i < especies.length; i++) {
                            especies[i].saldo = especies[i].ttEspecieSaldoCarga.saldo;
                            especies[i].reserva = especies[i].ttEspecieSaldoCarga.reserva;
                            especies[i].volumen = especies[i].ttEspecieSaldoCarga.volumen;
                          }
                          if (data.observaciones != null) {
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) {

                            }
                          }
                          utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 26:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaVentaNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 27:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.observaciones = JSON.parse(data.observaciones);
                          utilsService.generarInformeVentaNota(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 28:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.aprobado == 0) {
                            var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDiaAdmision = $scope.numeroALetras(dia, false);
                            var strAnioAdmision = $scope.numeroALetras(anio, false);
                            utilsService.generarResolucionDenegacionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          } else {
                            utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 29:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio, false);
                          dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDiaFinalizacion = $scope.numeroALetras(dia, false);
                          var strAnioFinalizacion = $scope.numeroALetras(anio, false);
                          if (data.observaciones == null) {
                            data.observaciones = '';
                          }
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) {
                            data.observaciones = {
                              resolucionRevalidacion: data.observaciones
                            }
                          }
                          utilsService.generarResolucionVenta(data, strDia, strAnio, strDiaFinalizacion, strAnioFinalizacion, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          utilsService.generarCedulaResolucionVenta(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 30:
                      tareaService.obtenerTareaProvidenciaInformeVenta(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio);
                          utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, null);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 31:
                      tareaService.getTareaInformeVenta(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.esEnmienda == 1) {
                            swal('Enmienda solicitada, motivo: ' + data.observaciones);
                          } else {
                            data.ttInformeVenta.conclusiones = JSON.parse(data.ttInformeVenta.conclusiones);
                            data.observaciones = JSON.parse(data.observaciones);
                            utilsService.generarDictamenInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, null);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 32:
                      tareaService.getTareaInformeVentaNotificacion(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.ttPadre.aprobado == 0) {
                            data.ttPadre.observaciones = JSON.parse(data.ttPadre.observaciones);
                          }
                          utilsService.generarNotificacionInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    default:

                  }
                }

              }]
          })
          .state('index.solicitud.list', {
            url: '',
            templateUrl: 'app/solicitud/solicitud.list.tpl.html',
            resolve: {
              dataSolicitud: ['solicitudService', 'authService', '$filter',
                function (solicitudService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 1,
                    personaId: loginData.subregionId
                  }
                  return solicitudService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'solicitudService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
              function ($scope, $state, dataSolicitud, uiGridConstants, toastr, $filter, solicitudService, utilsService, gestionService, $timeout, ngDialog) {
                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1,
                  usuarioId: $scope.loginData.usuarioId,
                  subregionId: $scope.loginData.subregionId,
                  perfilId: $scope.loginData.perfilId
                }

                $scope.mostrarCambioNota = true;

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%', align: "right" },
                  { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'solicitante', displayName: 'Solicitante' },
                  { field: 'ttGestion.fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'ttGestion.area', name: 'area', displayName: 'Área', width: '5%' },
                  { field: 'ttGestion.tcPlanTipoGestion.planTipoGestionDesc', name: 'planTipoGestionDesc', displayName: 'Tipo' },
                  { field: 'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.recibirSolicitud(row.entity)" title="Recibir solicitudd para iniciar su trámite"> <i class="fa fa-download"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.generarEnmienda(row.entity)" title="Marcar como expediente incompleto y generar notificación de completación"> <i class="fa fa-file-pdf-o"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                      + '</span></div>'
                  }
                ];

                $scope.gridOptions.data = dataSolicitud.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 1,
                      personaId: $scope.loginData.subregionId
                    }
                    solicitudService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.recibirSolicitud = function (item) {
                  $scope.item = item;
                  ngDialog.open({
                    template: 'app/solicitud/requisito.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataRequisito: ['gestionService',
                        function (gestionService) {
                          return gestionService.getListaRequisito(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataRequisito', 'gestionService',
                      function ($scope, $state, toastr, dataRequisito, gestionService) {
                        $scope.mostrarVistaPrevia = false;
                        $scope.mostrarNoAplica = false;
                        $scope.mostrarPersona = true;
                        $scope.titulo = "Marque los requisitos que incluye el expediente";
                        var dataObligatorio = [];
                        var dataOpcional = [];
                        var item = null;
                        for (var i = 0; i < dataRequisito.data.length; i++) {
                          item = dataRequisito.data[i];
                          item.marcado = 0;
                          item.visible = true;
                          item.class = "btn-default btn-xs fa fa-square-o";
                          item.classNo = "btn-default btn-xs fa fa-square-o";
                          if (item.obligatorio == 1) {
                            dataObligatorio.push(item);
                          } else {
                            dataOpcional.push(item);
                          }
                        }
                        $scope.dataRequisito = dataObligatorio;
                        $scope.dataOtroRequisito = dataOpcional;

                        $scope.marcarRequisito = function (item) {
                          if (item.marcado == 1) {
                            item.marcado = 0;
                            item.class = 'btn-default btn-xs fa fa-square-o';
                          } else {
                            item.marcado = 1;
                            item.class = 'btn-success btn-xs fa fa-check-square-o';
                          }
                        }

                        $scope.marcarNoAplica = function (item) {
                          if (parseInt(item.obligatorio) == 1) {
                            item.obligatorio = 0;
                            item.classNo = 'btn-danger btn-xs fa fa-check-square-o';
                          } else {
                            item.obligatorio = 1;
                            item.classNo = 'btn-default btn-xs fa fa-square-o';
                          }
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.aceptarRequisito = function () {
                          var data = $scope.dataRequisito;
                          var continuar = true;
                          if ($scope.nombreEntregaDesc == null) {
                            continuar = false;
                          } else {
                            if ($scope.nombreEntregaDesc.length <= 0) {
                              continuar = false;
                            }
                          }
                          var dataRequisito = [];
                          if (continuar) {
                            var i;
                            for (i = 0; i < data.length; i++) {
                              if (data[i].obligatorio == 1 && data[i].marcado == 0 && data[i].visible == true) {
                                continuar = false;
                                break;
                              } else {
                                if (data[i].marcado == 1) {
                                  dataRequisito.push({ requisitoId: data[i].requisitoId, requisitoDesc: data[i].requisitoDesc });
                                }
                              }
                            }
                            for (i = 0; i < $scope.dataOtroRequisito.length; i++) {
                              if ($scope.dataOtroRequisito[i].marcado == 1) {
                                dataRequisito.push({ requisitoId: $scope.dataOtroRequisito[i].requisitoId, requisitoDesc: $scope.dataOtroRequisito[i].requisitoDesc });
                              }
                            }
                          }
                          if (continuar) {
                            var tmp = $scope.item.ttGestion;
                            var data = {
                              solicitudId: $scope.item.solicitudId,
                              ttGestion: {
                                gestionId: tmp.gestionId,
                                tcTipoGestion: tmp.tcTipoGestion,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: tmp.tcPersonaCrea,
                                tcTipoBosque: tmp.tcTipoBosque,
                                tcElaborador: tmp.tcElaborador,
                                ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                personaAnulaId: $scope.loginData.personaId,
                                expediente: $scope.numeroExpediente,
                              },
                              personaEntregaDesc: $scope.nombreEntregaDesc,
                              folios: $scope.folios,
                              requisito: dataRequisito
                            }
                            gestionService.recibirSolicitud(data).then(function (res) {
                              if (res.status == "OK") {
                                gestionService.getDatosRecepcion($scope.item.solicitudId).then(function (res) {
                                  if (res.status == "OK") {
                                    utilsService.generarConstanciaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                                    $timeout(function () {
                                      utilsService.generarCaratulaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                                      $state.go("index.tarea.list");
                                    }, 3000);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                                $scope.submitForm(true);
                                ngDialog.close();
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error("Todos los requisitos indicados con asterísco son obligatorios");
                          }
                        }

                      }]
                  });
                }

                $scope.generarEnmienda = function (item) {
                  $scope.item = item;
                  ngDialog.open({
                    template: 'app/solicitud/requisito.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataRequisito: ['gestionService',
                        function (gestionService) {
                          return gestionService.getListaRequisito(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataRequisito', 'gestionService',
                      function ($scope, $state, toastr, dataRequisito, gestionService) {
                        $scope.mostrarVistaPrevia = true;
                        $scope.mostrarNoAplica = false;
                        $scope.mostrarPersona = false;
                        $scope.mostrarAdicional = true;
                        $scope.titulo = "Marque los requisitos que debe completar el solicitante";
                        var dataObligatorio = [];
                        var dataOpcional = [];
                        var item = null;
                        for (var i = 0; i < dataRequisito.data.length; i++) {
                          item = dataRequisito.data[i];
                          item.marcado = 0;
                          item.visible = true;
                          item.class = "btn-default btn-xs fa fa-square-o";
                          item.classNo = "btn-default btn-xs fa fa-square-o";
                          if (item.obligatorio == 1) {
                            dataObligatorio.push(item);
                          } else {
                            dataOpcional.push(item);
                          }
                        }
                        $scope.dataRequisito = dataObligatorio;
                        $scope.dataOtroRequisito = dataOpcional;
                        $scope.dataAdicional = [];

                        $scope.marcarRequisito = function (item) {
                          if (item.marcado == 1) {
                            item.marcado = 0;
                            item.class = 'btn-default btn-xs fa fa-square-o';
                          } else {
                            item.marcado = 1;
                            item.class = 'btn-success btn-xs fa fa-check-square-o';
                          }
                        }

                        $scope.agregarRequisito = function () {
                          $scope.dataAdicional.push({ correlativo: null, descripcion: null });
                        }

                        $scope.borrarRequisito = function (item) {
                          $scope.dataAdicional.splice($scope.dataAdicional.indexOf(item), 1);
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.vistaPrevia = function () {
                          var data = angular.copy($scope.dataRequisito);
                          var continuar = false;
                          var i;
                          for (i = 0; i < $scope.dataOtroRequisito.length; i++) {
                            if ($scope.dataOtroRequisito[i].marcado == 1) {
                              data.push($scope.dataOtroRequisito[i]);
                            }
                          }
                          for (i = 0; i < $scope.dataAdicional.length; i++) {
                            if ($scope.dataAdicional[i].requisitoDesc == null || $scope.dataAdicional[i].requisitoDesc.length <= 0) {
                              toastr.error('Debe indicar el texto en los requisitos adicionales', 'Datos imcompletos');
                              return;
                            }
                            $scope.dataAdicional[i].marcado = 1;
                            data.push($scope.dataAdicional[i]);
                          }
                          var item = angular.copy($scope.item);
                          utilsService.generarNotificacionCompletacion(data, item, $scope.loginData.subregionDesc, $scope.item.ttGestion.nug, $scope.item.ttGestion.tcPersonaCrea.personaDesc,
                            $scope.loginData.personaDesc, $scope.dataEmpresa);
                        }

                        $scope.aceptarRequisito = function () {
                          var data = angular.copy($scope.dataRequisito);
                          var continuar = false;
                          var i;
                          for (i = 0; i < $scope.dataOtroRequisito.length; i++) {
                            if ($scope.dataOtroRequisito[i].marcado == 1) {
                              data.push($scope.dataOtroRequisito[i]);
                            }
                          }
                          for (i = 0; i < $scope.dataAdicional.length; i++) {
                            if ($scope.dataAdicional[i].requisitoDesc == null || $scope.dataAdicional[i].requisitoDesc.length <= 0) {
                              toastr.error('Debe indicar el texto en los requisitos adicionales', 'Datos imcompletos');
                              return;
                            }
                            $scope.dataAdicional[i].marcado = 1;
                            data.push($scope.dataAdicional[i]);
                          }
                          var item = angular.copy($scope.item);
                          item.incompleto = JSON.stringify(data);
                          delete item.ttGestion;
                          solicitudService.marcarEnmienda(item).then(function (res) {
                            if (res.status == "OK") {
                              utilsService.generarNotificacionCompletacion(data, item, $scope.loginData.subregionDesc, $scope.item.ttGestion.nug, $scope.item.ttGestion.tcPersonaCrea.personaDesc,
                                $scope.loginData.personaDesc, $scope.dataEmpresa);
                              ngDialog.close();
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                      }]
                  });
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                      function ($scope, $state, toastr, dataAnexo, gestionService) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                      }]
                  });
                }

                $scope.etiquetaEstado = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'Enviado';
                    case 2: return 'En trámite';
                    case 3: return 'Finalizado';
                    default: return 'Anulado';
                  }
                }

                $scope.verSolicitudes = function () {
                  ngDialog.open({
                    template: 'app/solicitud/nota.list.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataSolicitud: ['gestionService',
                        function (gestionService) {
                          return gestionService.listaSolicitudCambioNotaSubregion(1);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataSolicitud', 'gestionService', 'notaenvioService',
                      function ($scope, $state, toastr, dataSolicitud, gestionService, notaenvioService) {

                        $scope.solicitud = {};
                        $scope.deshabilitar = false;
                        $scope.textoBoton = 'Recibir';

                        $scope.gridLista = angular.copy($scope.gridOptionsSelection);
                        $scope.gridLista.columnDefs = [
                          { field: 'cambioNotaId', name: 'cambioNotaId', displayName: 'No. Solicitud', width: '10%', align: "right" },
                          { field: 'tcSolicitante.personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                          { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', type: 'date', width: '15%', cellFilter: 'date:\'dd/MM/yyyy\'' },
                          { field: 'cantidad', name: 'cantidad', displayName: 'Total solicitado', width: '10%' },
                          {
                            name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '15%',
                            cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                          },
                          {
                            name: 'Opciones', enableFiltering: false, width: '8%',
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.recibirSolicitudCambioNota(row.entity)" title="Recibir solicitud"> <i class="fa fa-download"></i> </button> '
                              + ' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.anularSolicitudCambioNota(row.entity)" title="Anular solicitud cambio de notas"> <i class="fa fa-close"></i> </button> '
                              + '</span></div>'
                          }
                        ];
                        $scope.gridLista.data = [];
                        if (dataSolicitud.status == 1) {
                          $scope.gridLista.data = dataSolicitud.data;
                        } else {
                          toastr.error(dataSolicitud.message);
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.recibirSolicitudCambioNota = function (row) {
                          notaenvioService.getCorrelativoNota($scope.loginData.subregionId, 1).then(function (response) {
                            if (response.status == 'OK') {
                              var notas = response.data;
                              gestionService.getSolicitudCambioNota(row.cambioNotaId).then(function (res) {
                                if (res.status == 1) {
                                  $scope.solicitud = res.data;
                                  $scope.mostrarDatos = true;
                                  var rangos = $scope.solicitud.rangos;
                                  var falta = 0;
                                  for (var i = 0; i < rangos.length; i++) {
                                    falta += rangos[i].cantidad;
                                  }
                                  var nuevas = [];
                                  for (var i = 0; i < notas.length; i++) {
                                    row = notas[i];
                                    var cantidad = row.saldo;
                                    if (falta > cantidad) {
                                      nuevas.push({ inicio: row.siguiente, fin: row.ultimo, cantidad: cantidad });
                                      falta -= cantidad;
                                    } else {
                                      nuevas.push({ inicio: row.siguiente, fin: (row.siguiente + falta) - 1, cantidad: falta });
                                      falta = 0;
                                    }
                                    if (falta <= 0) {
                                      break;
                                    }
                                  }
                                  $scope.solicitud.notas = nuevas;
                                  if (falta > 0) {
                                    swal('La cantidad disponible de notas de envío no es suficiente, favor de revisar');
                                  }
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.submitFormSolicitud = function (isConfirm) {
                          if (isConfirm) {
                            swal({
                              title: "Confirmación",
                              text: "¿Está seguro (a) de recibir la solicitud? \nEsta acción no se podrá reversar (no hay regreso de tarea), asegúrese de revisar los correlativos y resolución de venta sean correctos",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                              function (isConfirm) {
                                if (isConfirm) {
                                  var ttCambioNota = angular.copy($scope.solicitud);
                                  delete ttCambioNota.rangos;
                                  gestionService.recibirSolicitudCambioNota(ttCambioNota).then(function (res) {
                                    if (res.status == 1) {
                                      $scope.cerrarVentana();
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              });
                          }
                        }

                        $scope.cancelarRecepcion = function () {
                          $scope.solicitud = {};
                          $scope.mostrarDatos = false;
                        }

                        $scope.anularSolicitudCambioNota = function (row) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro (a) de anular la solicitud? \nEsta acción no se podrá reversar (no hay regreso de tarea)",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                gestionService.anularSolicitudCambioNota(row.cambioNotaId).then(function (res) {
                                  if (res.status == 1) {
                                    $scope.cerrarVentana();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            });
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.solicitud.aceptado', {
            url: '/aceptado',
            templateUrl: 'app/solicitud/solicitud.list.tpl.html',
            resolve: {
              dataSolicitud: ['solicitudService', 'authService', '$filter',
                function (solicitudService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 2,
                    personaId: loginData.subregionId
                  }
                  return solicitudService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'solicitudService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
              function ($scope, $state, dataSolicitud, uiGridConstants, toastr, $filter, solicitudService, utilsService, gestionService, $timeout, ngDialog) {
                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2,
                  usuarioId: $scope.loginData.usuarioId,
                  subregionId: $scope.loginData.subregionId,
                  perfilId: $scope.loginData.perfilId
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%', align: "right" },
                  { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'solicitante', displayName: 'Solicitante' },
                  { field: 'ttGestion.fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'ttGestion.area', name: 'area', displayName: 'Área', width: '5%' },
                  { field: 'ttGestion.tcPlanTipoGestion.planTipoGestionDesc', name: 'planTipoGestionDesc', displayName: 'Tipo' },
                  { field: 'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirRecepcion(row.entity)" title="Imprimir constancia de recepción"> <i class="fa fa-file-pdf-o"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataSolicitud.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 2,
                      personaId: $scope.loginData.subregionId
                    }
                    solicitudService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.imprimirRecepcion = function (item) {
                  gestionService.getDatosRecepcion(item.solicitudId).then(function (res) {
                    if (res.status == "OK") {
                      utilsService.generarConstanciaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                      $timeout(function () {
                        utilsService.generarCaratulaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 3000);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                      function ($scope, $state, toastr, dataAnexo, gestionService) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.solicitud.suspendido', {
            url: '/suspendido',
            templateUrl: 'app/solicitud/suspendido.list.tpl.html',
            resolve: {
              dataSolicitud: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    personaId: loginData.personaId,
                    elaboradorId: loginData.subregionId
                  }
                  return gestionService.getListaSuspension(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
              function ($scope, $state, dataSolicitud, uiGridConstants, toastr, $filter, utilsService, gestionService, $timeout, ngDialog) {
                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  personaId: $scope.loginData.personaId,
                  elaboradorId: $scope.loginData.subregionId
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%', align: "right" },
                  { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'solicitante', displayName: 'Solicitante' },
                  { field: 'ttGestion.area', name: 'area', displayName: 'Área', width: '5%' },
                  { field: 'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha registro', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataSolicitud.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      personaId: $scope.loginData.personaId,
                      elaboradorId: $scope.loginData.subregionId
                    }
                    gestionService.getListaSuspension(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.solicitud.suspension', {
            url: '/suspension',
            templateUrl: 'app/solicitud/solicitud.suspender.tpl.html',
            resolve: {
            },
            controller: ['$scope', '$state', 'uiGridConstants', 'toastr', '$filter', 'solicitudService', 'utilsService', 'gestionService',
              '$timeout', 'ngDialog', 'Upload', 'tareaService',
              function ($scope, $state, uiGridConstants, toastr, $filter, solicitudService, utilsService, gestionService,
                $timeout, ngDialog, Upload, tareaService) {
                var now = new Date();
                var begin = $scope.addDays(now, -60);
                $scope.ttGestion = null;

                $scope.filtro = {
                  tipoBusquedaId: $scope.loginData.subregionId
                };

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'nug', name: 'nug', displayName: 'NUG', width: '10%', align: "right" },
                  { field: 'tcPersonaCrea.personaDesc', name: 'solicitante', displayName: 'Propietario / Representante' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                  { field: 'expediente', name: 'expediente', displayName: 'Expediente' },
                  { field: 'tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.seleccionarGestion(row.entity)" title="Seleccionar gestión"> <i class="fa fa-hand-o-up text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirDocumento(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = [];
                $scope.imprimirDocumento = function (item) {
                  var row = {
                    ttGestion: item
                  }
                  $scope.imprimirPlan(row);
                }

                $scope.submitFormNug = function (isValid) {
                  if (isValid) {
                    var filtro = $scope.filtro;
                    gestionService.buscarGestionPorInvolucrado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        $scope.mostrarDatos = false;
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      $scope.mostrarDatos = false;
                      toastr.error(error);
                    });
                  }
                }

                $scope.data = {
                  estadoId: 1,
                  tcPersonaCrea: {
                    personaId: $scope.loginData.personaId
                  },
                  opositores: [],
                  anexos: [],
                  fechaIngreso: new Date()
                }

                $scope.submitFormSuspension = function (isValid) {
                  if (isValid) {
                    if ($scope.ttGestion == null) {
                      toastr.error('No es posible continuar, por favor seleccione de nuevo la gestión que desea suspender');
                      return;
                    }
                    var data = $scope.data;
                    if (data.anexos.length <= 0) {
                      toastr.error('Debe al menos agregar la copia digital del memorial presentado');
                      return;
                    }
                    if (data.opositores.length <= 0) {
                      toastr.error('Debe al menos agregar ana persona que presenta oposición');
                      return;
                    }
                    data.ttGestion = $scope.ttGestion;
                    tareaService.agregarSolicitudSuspension(data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $state.go('^.suspendido');
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.agregarOpositor = function () {
                  $scope.data.opositores.push(
                    {
                      estadoId: 1,
                      tcOpositor: {
                        cui: null,
                        opositorDesc: null,
                        telefono: null,
                        correo: null
                      }
                    }
                  );
                }

                $scope.borrarOpositor = function (item) {
                  $scope.data.opositores.splice($scope.data.opositores.indexOf(item), 1);
                }

                $scope.seleccionarGestion = function (item) {
                  $scope.ttGestion = item;
                  $scope.mostrarDatos = true;
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      //send to database
                      var item = {
                        ruta: item.rutaArchivo,
                        nombre: item.nombreOriginal,
                        size: item.size,
                        extension: item.extension
                      };
                      $scope.data.anexos.push(item);
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.borrarAnexo = function (item) {
                  $scope.data.anexos.splice($scope.data.anexos.indexOf(item), 1);
                }

              }]
          })
          .state('index.solicitud.exento', {
            url: '/exento',
            templateUrl: 'app/solicitud/exento.list.tpl.html',
            resolve: {
              dataSolicitud: ['exentoService', 'authService', '$filter',
                function (exentoService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 3,
                    elaboradorId: loginData.subregionId
                  }
                  return exentoService.getListadoSubregion(filtro);
                }],
              dataTipoExento: ['tipoexentoService',
                function (tipoexentoService) {
                  return tipoexentoService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'exentoService', 'utilsService',
              'gestionService', '$timeout', 'ngDialog', 'tareaService', 'dataTipoExento',
              function ($scope, $state, dataSolicitud, uiGridConstants, toastr, $filter, exentoService, utilsService,
                gestionService, $timeout, ngDialog, tareaService, dataTipoExento) {
                $scope.titulo = 'Solicitudes de carga inicial de exentos de licencia forestal';
                $scope.dataEstado = [
                  { estadoId: -1, estadoDesc: 'Todos' },
                  { estadoId: 3, estadoDesc: 'Solicitado' },
                  { estadoId: 4, estadoDesc: 'En trámite' },
                  { estadoId: 5, estadoDesc: 'Autorizado' },
                  { estadoId: 6, estadoDesc: 'Finalizado' }
                ];
                $scope.dataTipoExento = dataTipoExento.data;
                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 3,
                  elaboradorId: $scope.loginData.subregionId
                }

                $scope.descripcionEstado = function (estadoId) {
                  switch (estadoId) {
                    case 1: return "Activo";
                    case 2: return "Elaborado";
                    case 3: return "Solicitado";
                    case 4: return "En trámite";
                    case 5: return "Autorizado";
                    case 6: return "Finalizado";
                    default: return "Inactivo";
                  }
                }

                $scope.descripcionTarea = function (estadoId) {
                  if (estadoId < 4) {
                    return 'Ubicar el expediente físico y trasladar al subregional';
                  } else {
                    return 'La tarea ya fue realizada';
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                  { field: 'tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante', width: '30%' },
                  { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'Tarea', enableFiltering: false, width: '30%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options">{{grid.appScope.descripcionTarea(row.entity.estadoId)}}</div>'
                  },
                  {
                    name: 'Estado', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options">{{grid.appScope.descripcionEstado(row.entity.estadoId)}}</div>'
                  },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 3" ng-click="grid.appScope.ingresarSolicitud(row.entity)" title="Ingresar solicitud"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs text-red" ng-show="row.entity.estadoId == 3" ng-click="grid.appScope.cancelarSolicitud(row.entity)" title="Cancelar solicitud y habilitar la edición al usuario"><i class="fa fa-close" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 1" ng-click="grid.appScope.imprimirFormato(row.entity)" title="Imprimir solicitud"><i class="fa fa-file-pdf-o text-red" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-show="row.entity.estadoId > 3" ng-click="grid.appScope.verHistorialExento(row.entity)" title="Ver historial de documentos test"> <i class="fa fa-list-ol"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataSolicitud.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId,
                      elaboradorId: $scope.loginData.subregionId
                    }
                    exentoService.getListadoSubregion(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.verHistorialExento = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getHistorialExento(item.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                        if (dataHistorial.status == 'OK') {
                          var tmp = dataHistorial.data[0];
                          $scope.dataTarea = tmp.tareas;
                          $scope.dataVenta = tmp.ventas;
                          $scope.dataInforme = tmp.informes;
                        }

                        $scope.verDocumentoTareaExento = function (item) {
                          $scope.imprimirTareaExento(item, null, $scope.row.exentoId);
                        }

                        $scope.verSolicitudVenta = function (item) {
                          if (item.fechaRecepcion == null) {
                            toastr.error('Esta solicitud/resolución se agregó en la carga inicial, no se puede imprimir');
                            return;
                          }
                          $scope.imprimirSolicitudVenta(item);
                        }

                        $scope.verInformeVenta = function (item) {
                          if (item.fechaElaboracion == null) {
                            toastr.error('Los informes agregados por el (la) técnico (a) en carga inicial no se pueden imprimir');
                            return;
                          }
                          $scope.imprimirInformeTrimestral(item);
                        }

                      }]
                  });
                }

                $scope.cancelarSolicitud = function (row) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro (a) de cancelar la solicitud y habilitar al usuario la edición del mismo? \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var ttExento = {
                          exentoId: row.exentoId,
                          tcTipoGestion: row.tcTipoGestion,
                          estadoId: row.estadoId,
                          tcPersonaCrea: row.tcPersonaCrea
                        };
                        exentoService.anularSolicitudCargaExento(row.exentoId, ttExento).then(function (res) {
                          if (res.status == "OK") {
                            $scope.submitForm(true);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.imprimirFormato = function (item) {
                  exentoService.get(item.exentoId).then(function (res) {
                    if (res.status == "OK") {
                      utilsService.generarSolicitudExento(res.data[0], $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.ingresarSolicitud = function (item) {
                  $scope.item = item;
                  $scope.item.tcSubtipoExento = {
                    subtipoExentoId: null
                  }
                  ngDialog.open({
                    template: 'app/solicitud/carga.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'subtipoexentoService', 'tareaService',
                      function ($scope, $state, toastr, subtipoexentoService, tareaService) {

                        $scope.titulo = "Indique el expediente donde obra la inscripción del RNF " + item.codigo
                          + ", luego presione aceptar para iniciar la gestión de carga inicial";

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.setTipoExento = function (item) {
                          subtipoexentoService.getByTipoExento(item.tipoExentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataSubtipoExento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.setSubtipoExento = function (item) {
                          $scope.item.tcSubtipoExento = item;
                        }

                        $scope.aceptarSolicitudCargaInicial = function (isValid) {
                          if (isValid) {
                            var item = $scope.item;
                            tareaService.recibirExento(item).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success('La caratula la puede imprimir en el historial de tareas realizadas');
                                ngDialog.close();
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.solicitud.venta', {
            url: '/venta',
            templateUrl: 'app/solicitud/exento.list.tpl.html',
            resolve: {
              dataSolicitud: ['exentoService', 'authService', '$filter',
                function (exentoService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 1,
                    elaboradorId: loginData.subregionId
                  }
                  return exentoService.getSolicitudVentaSubregion(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'exentoService', 'utilsService', 'gestionService', '$timeout', 'ngDialog', 'tareaService',
              function ($scope, $state, dataSolicitud, uiGridConstants, toastr, $filter, exentoService, utilsService, gestionService, $timeout, ngDialog, tareaService) {
                $scope.titulo = 'Solicitudes de ventas de notas de envío de exentos de licencia forestal';
                $scope.dataEstado = [
                  { estadoId: -1, estadoDesc: 'Todos' },
                  { estadoId: 1, estadoDesc: 'Solicitado' },
                  { estadoId: 2, estadoDesc: 'En trámite' },
                  { estadoId: 3, estadoDesc: 'Esperando pago' },
                  { estadoId: 4, estadoDesc: 'Autorizado' },
                  { estadoId: 5, estadoDesc: 'Finalizado' },
                  { estadoId: 20, estadoDesc: 'Suspendido' },
                  { estadoId: 99, estadoDesc: 'Denegado' },
                ];
                var now = new Date();
                var begin = $scope.addDays(now, -60);

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1
                }

                $scope.descripcionEstado = function (estadoId, rutaBoleta) {
                  if (rutaBoleta == null || rutaBoleta == undefined && estadoId != 3) {
                    switch (estadoId) {
                      case 1: return 'Solicitado';
                      case 2: return 'En trámite';
                      case 3: return 'Esperando pago';
                      case 4: return 'Autorizado';
                      case 5: return 'Finalizado';
                      case 20: return 'Suspendido';
                      case 99: return 'Denegado';
                      default: return "Inactivo";
                    }

                  } else {
                    switch (estadoId) {
                      case 3: return 'Pago realizado';
                    }
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttExento.codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                  { field: 'ttExento.tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                  { field: 'fechaRegistro', name: 'fechaIngreso', displayName: 'Fecha ingreso', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'Estado', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options">{{grid.appScope.descripcionEstado(row.entity.estadoId,row.entity.rutaBoleta)}}</div>'
                  },
                  {
                    name: 'Opciones', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 1" ng-click="grid.appScope.ingresarSolicitud(row.entity)" title="Recibir solicitud"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 1" ng-click="grid.appScope.anularSolicitud(row.entity)" title="Anular solicitud"><i class="fa fa-close text-danger" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 3" ng-click="grid.appScope.aceptarDocumentoPago(row.entity)" title="Aceptar el documento de pago"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 1" ng-click="grid.appScope.imprimirConstancia(row.entity)" title="Imprimir constancia de ingreso"><i class="fa fa-file-pdf-o text-blue" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="(row.entity.estadoId == 3 || row.entity.estadoId == 4 || row.entity.estadoId == 5) && row.entity.rutaBoleta != null" ng-click="grid.appScope.imprimirBoletaPago(row.entity)" title="Imprimir documento de pago"><i class="fa fa-money text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 3 && row.entity.rutaBoleta != null" ng-click="grid.appScope.solicitarNuevaBoletaPago(row.entity)" title="Solicitar cargar de nuevo el documento de pago"><i class="fa fa-refresh text-primary" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirSolicitud(row.entity)" title="Imprimir solicitud"><i class="fa fa-file-pdf-o text-red" aria-hidden="true"></i></button>'
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataSolicitud.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: $scope.data.estadoId
                    }
                    exentoService.getSolicitudVentaSubregion(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.imprimirBoletaPago = function (item) {
                  exentoService.getDocumentoPago(item.ventaExentoId);
                }

                $scope.imprimirSolicitud = function (item) {
                  exentoService.getDatosVenta(item.ventaExentoId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.anularSolicitud = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de anular esta solicitud y notificar al usuario por qué? Esta acción no se podrá reversar.\n\nIngrese el motivo de la anulación",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Motivo de anulación"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario el motivo ya que se le notificará al usuario');
                        return false;
                      }
                      item.requisito = inputValue;
                      exentoService.anularSolicitudVenta(item).then(function (res) {
                        if (res.status == "OK") {
                          $scope.submitForm(true);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

                $scope.imprimirConstancia = function (item) {
                  exentoService.getDatosVenta(item.ventaExentoId).then(function (res) {
                    if (res.status == "OK") {
                      var ttExento = res.data[0];
                      var data = ttExento.ventas[0];
                      ttExento.ventas = null;
                      data.ttExento = ttExento;
                      data.requisito = JSON.parse(data.requisito);
                      utilsService.generarConstanciaRecepcionExento(data, false, $scope.loginData.usuario, $scope.dataEmpresa);
                      utilsService.generarCaratulaTramiteExento(data, 2, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.solicitarNuevaBoletaPago = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "Está por borrar el documento cargado y solicitar una nueva ¿Está segura(o) de continuar? \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        exentoService.solicitarNuevaBoletaPago(item).then(function (res) {
                          if (res.status == "OK") {
                            toastr.success(res.message);
                            $scope.submitForm(true);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.aceptarDocumentoPago = function (item) {
                  $scope.ttVentaExento = item;
                  $scope.ttVentaExento.nuevaCantidad = 0;
                  $scope.ttVentaExento.totalNotas = $scope.ttVentaExento.autorizadoNotas;
                  $scope.textoComplemento = '';
                  $scope.tituloCorrelativo = 'Código RNF: ' + item.ttExento.codigo + '. Total de NE autorizado: ' + item.totalNotas;
                  if (item.paraRevalidar == 1) {
                    $scope.tituloCorrelativo = 'Código RNF: ' + item.ttExento.codigo;
                    $scope.textoComplemento = '(*Revalidación)';
                  }
                  ngDialog.open({
                    template: 'app/solicitud/correlativo.venta.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataNotas: ['notaenvioService',
                        function (notaenvioService) {
                          return notaenvioService.getCorrelativoNota($scope.loginData.subregionId, 2);
                        }],
                      dataRangoActivo: ['tareaService',
                        function (tareaService) {
                          return tareaService.getRavalidacionNotas(item.ttExento.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'tareaService', 'dataNotas', 'dataRangoActivo',
                      function ($scope, $state, toastr, tareaService, dataNotas, dataRangoActivo) {
                        var dataRango = dataRangoActivo.data;
                        $scope.dataRango = dataRangoActivo.data;
                        $scope.sinFactura = false;
                        if (dataRango.length <= 0) {
                          toastr.warning('No hay resoluciones de ventas activas para revalidar notas');
                        }
                        var dataNotas = dataNotas.data;
                        if (dataNotas == null || dataNotas.length <= 0) {
                          swal('No hay correlativos disponibles para la subregión, favor de revisar');
                        } else {
                          var row = dataNotas[0];
                          var dataRango = $scope.dataRango;
                          var totalRevalidada = 0;
                          var rangos = [];
                          for (var i = 0; i < dataRango.length; i++) {
                            totalRevalidada += dataRango[i].cantidad;
                            rangos.push({ inicio: dataRango[i].inicio, fin: dataRango[i].fin, cantidad: dataRango[i].cantidad, esRevalidacion: 1 });
                          }
                          var falta = item.autorizadoNotas;
                          if (falta <= 0) {
                            $scope.sinFactura = true;
                          }
                          if (item.paraRevalidar == 0 && falta > 0) {
                            for (var i = 0; i < dataNotas.length; i++) {
                              row = dataNotas[i];
                              var cantidad = row.saldo;
                              if (falta > cantidad) {
                                rangos.push({ inicio: row.siguiente, fin: row.ultimo, cantidad: cantidad });
                                falta -= cantidad;
                              } else {
                                rangos.push({ inicio: row.siguiente, fin: (row.siguiente + falta) - 1, cantidad: falta });
                                falta = 0;
                              }
                              if (falta <= 0) {
                                break;
                              }
                            }
                            if (falta > 0) {
                              swal('La cantidad disponible de notas de envío no es suficiente, favor de revisar');
                            }
                          }
                          $scope.ttVentaExento.rangos = rangos;
                        }
                        $scope.setPrimerRango = function () {
                          $scope.ttVentaExento.fin = Number($scope.ttVentaExento.inicio) + parseInt($scope.ttVentaExento.autorizadoNotas);
                        }

                        $scope.setSegundoRango = function () {
                          $scope.ttVentaExento.fin2 = Number($scope.ttVentaExento.inicio2) + parseInt($scope.ttVentaExento.nuevaCantidad);
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.submitFormCorrelativo = function (isValid) {
                          if (isValid) {
                            swal({
                              title: "No hay regreso de tarea",
                              text: "Está por aceptar el documento de pago cargado y generar una tarea al director subregional para la emisión de la resolución ¿Está segura(o) de continuar? \nEsta acción no se podrá reversar.",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                              function (isConfirm) {
                                if (isConfirm) {
                                  var item = angular.copy($scope.ttVentaExento);
                                  tareaService.aceptarDocumentoPago(item).then(function (res) {
                                    if (res.status == "OK") {
                                      toastr.success(res.message);
                                      ngDialog.close();
                                      $scope.submitForm(true);
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              });
                          }
                        }

                      }]
                  });
                }

                $scope.ingresarSolicitud = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/solicitud/venta.nota.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'subtipoexentoService', 'tareaService',
                      function ($scope, $state, toastr, subtipoexentoService, tareaService) {

                        $scope.titulo = "Inscripción RNF " + item.ttExento.codigo
                          + ", complete los datos para admitir la solicitud de venta de Notas de Envío";

                        $scope.row.requisito = [
                          {
                            requisitoDesc: 'Solicitud de venta de notas de envío',
                            marcado: 0
                          }
                        ];

                        $scope.requisito = [];

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.agregarRequisitoVenta = function () {
                          $scope.requisito.push({ requisitoDesc: null });
                        }

                        $scope.borrarRequisitoVenta = function (item) {
                          $scope.requisito.splice($scope.requisito.indexOf(item), 1);
                        }

                        $scope.imprimirRequisitoFaltante = function () {
                          var data = angular.copy($scope.row);
                          var requisito = $scope.requisito;
                          var dataRequisito = [];
                          for (var i = 0; i < data.requisito.length; i++) {
                            if (data.requisito[i].marcado == 1) {
                              dataRequisito.push(data.requisito[i]);
                            }
                          }
                          for (var i = 0; i < requisito.length; i++) {
                            dataRequisito.push(requisito[i]);
                          }
                          if (dataRequisito.length <= 0) {
                            toastr.error('Debe seleccionar o agregar requisitos para generar el documento');
                            return;
                          }
                          data.requisito = dataRequisito;
                          utilsService.generarCompletacionExento(data, $scope.dataEmpresa, $scope.loginData.usuarioDesc, false);
                          ngDialog.close();
                        }

                        $scope.aceptarSolicitudVentaNota = function (isValid) {
                          if (isValid) {
                            var item = angular.copy($scope.row);
                            var requisito = $scope.requisito;
                            for (var i = 0; i < requisito.length; i++) {
                              item.requisito.push(requisito[i]);
                            }
                            item.requisito = JSON.stringify(item.requisito);
                            tareaService.recibirSolicitudVenta(item).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success(res.message);
                                ngDialog.close();
                                try {
                                  $scope.imprimirConstancia(item);
                                } catch (error) {
                                }
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          } else {
                            toastr.error('Todos los campos y requisitos son obligatorios');
                          }
                        }

                      }]
                  });
                }

              }]
          })
      }
    ]
  );
