angular.module('app.informe', [
  'ui.router',
  'toastr'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.informe', {
            abstract: true,
            url: 'informe',
            template: '<div ui-view></div>',
            resolve: {
            },
            controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
              function ($scope, toastr, $timeout, gestionService, utilsService) {
                $scope.module = 'Informe';
              }]
          })
          .state('index.informe.list', {
            url: '',
            templateUrl: 'app/informe/informe.list.tpl.html',
            resolve: {
              dataInforme: ['gestionService', 'authService', '$filter',
                function (gestionService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  return gestionService.getListaInformeSubregion(loginData.personaId);
                }]
            },
            controller: ['$scope', '$state', 'dataInforme', 'uiGridConstants', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog', 'tareaService',
              function ($scope, $state, dataInforme, uiGridConstants, toastr, $filter, gestionService, utilsService, $timeout, ngDialog, tareaService) {

                $scope.dataEstado = [
                  { estadoId: 2, estadoDesc: 'Solicitado' },
                  { estadoId: 3, estadoDesc: 'Recibido' }
                ];
                var now = new Date();
                var begin = $scope.addDays(now, -7);
                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2
                };
                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'turno', name: 'turno', displayName: 'Turno', width: '5%' },
                  { field: 'trimestre', name: 'trimestre', displayName: 'Trimestre', width: '8%' },
                  { field: 'tcPersonaCrea.personaDesc', name: 'personaDesc', displayName: 'Solicitante / Regente' },
                  { field: 'fechaElaboracion', name: 'fechaElaboracion', displayName: 'Fecha elaboración', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'ttPoaGestion.ttGestion.area', name: 'area', displayName: 'Área', width: '5%' },
                  { field: 'ttPoaGestion.ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '15%' },
                  { field: 'ttPoaGestion.ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '5%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.recibirInforme(row.entity)" title="Clic para recibir informe"> <i class="fa fa-check-circle-o" aria-hidden="true"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataInforme.data;

                $scope.cargarDatos = function () {
                  gestionService.getListaInformeSubregion($scope.loginData.personaId).then(function (res) {
                    if (res.status == "OK") {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.recibirInforme = function (item) {
                  if (item.estadoId == 2 && item.estadoTramiteId == 1) {
                    gestionService.obtenerDatosInforme(item.informeGestionId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        $scope.row = item;
                        var notas = data.notas;
                        var usados = 0, anulados = 0, extraviados = 0;
                        for (var i = 0; i < notas.length; i++) {
                          if (notas[i].usoId == 1) {
                            usados++;
                          }
                          if (notas[i].usoId == 0) {
                            anulados++;
                          }
                          if (notas[i].usoId == 2) {
                            extraviados++;
                          }
                        }

                        var dataRequisito = [
                          {
                            requisitoDesc: 'Informe',
                            marcado: 0,
                            class: "btn-default btn-xs fa fa-square-o",
                            classNo: "btn-default btn-xs fa fa-square-o"
                          }
                        ];
                        if (usados > 0) {
                          dataRequisito.push(
                            {
                              requisitoDesc: 'Duplicados o triplicados de notas de envío utilizadas (Cantidad: ' + usados + ')',
                              marcado: 0,
                              class: "btn-default btn-xs fa fa-square-o",
                              classNo: "btn-default btn-xs fa fa-square-o"
                            }
                          );
                        }
                        if (anulados > 0) {
                          dataRequisito.push(
                            {
                              requisitoDesc: 'Original y copia de notas de envío anuladas (Cantidad: ' + anulados + ')',
                              marcado: 0,
                              class: "btn-default btn-xs fa fa-square-o",
                              classNo: "btn-default btn-xs fa fa-square-o"
                            }
                          );
                        }
                        if (extraviados > 0) {
                          dataRequisito.push(
                            {
                              requisitoDesc: 'Denuncia de notas de envío extraviadas (Cantidad: ' + extraviados + ')',
                              marcado: 0,
                              class: "btn-default btn-xs fa fa-square-o",
                              classNo: "btn-default btn-xs fa fa-square-o"
                            }
                          );
                        }
                        dataRequisito.push(
                          {
                            requisitoDesc: 'Anexos',
                            marcado: 0,
                            class: "btn-default btn-xs fa fa-square-o",
                            classNo: "btn-default btn-xs fa fa-square-o"
                          }
                        );
                        $scope.dataRequisito = dataRequisito;
                        ngDialog.open({
                          template: 'app/informe/informe.requisito.tpl.html',
                          className: 'ngdialog-theme-flat',
                          appendClassName: 'ngdialog-flat',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueEdit',
                          name: 'dialogEditar',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr', 'tareaService',
                            function ($scope, $state, toastr, tareaService) {

                              $scope.cerrarVentana = function () {
                                ngDialog.close();
                              }

                              $scope.marcarRequisito = function (item) {
                                if (item.marcado == 1) {
                                  item.marcado = 0;
                                  item.class = 'btn-default btn-xs fa fa-square-o';
                                } else {
                                  item.marcado = 1;
                                  item.class = 'btn-success btn-xs fa fa-check-square-o';
                                }
                              }

                              $scope.aceptarRequisito = function () {
                                for (var i = 0; i < $scope.dataRequisito.length; i++) {
                                  if ($scope.dataRequisito[i].marcado == 0) {
                                    swal('Hay requisitos que no fueron marcados, debe seleccionar todos', 'Tome en cuenta que al seleccionar está indicando que el (los) requisito (s) viene (s) completo (s)');
                                    return;
                                  }
                                }
                                var item = angular.copy($scope.row);
                                item.tcPersonaCrea.foto = null;
                                item.tcPersonaCrea.personaId = $scope.loginData.personaId;
                                tareaService.recibirInforme(item).then(function (res) {
                                  if (res.status == "OK") {
                                    $scope.cargarDatos();
                                    ngDialog.close();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });

                              }

                            }]
                        });
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.informe.recibir', {
            url: '/add',
            templateUrl: 'app/informe/informe.recibir.tpl.html',
            resolve: {

            },
            controller: ['$scope', '$state', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog', 'tareaService',
              function ($scope, $state, toastr, $filter, gestionService, utilsService, $timeout, ngDialog, tareaService) {
                $scope.mostrarRequisito = false;
                $scope.mostrarVista = false;
                $scope.data = {
                  subregionId: $scope.loginData.subregionId,
                  numero: null,
                  anio: null
                }
                $scope.classCompletacion = 'btn btn-default fa fa-square-o';
                $scope.esCompletacion = false;
                $scope.visibleRecibir = true;

                $scope.submitForm = function (isValid) {
                  $scope.mostrarRequisito = false;
                  if (isValid) {
                    gestionService.encontrarGestion($scope.data).then(function (resp) {
                      if (resp.status == "OK") {
                        $scope.gestion = resp.data[0];
                        gestionService.getRequisitoInforme().then(function (res) {
                          if (res.status == "OK") {
                            $scope.mostrarRequisito = true;
                            var data = res.data;
                            for (var i = 0; i < data.length; i++) {
                              data[i].marcado = 0;
                              data[i].class = "btn-default btn-xs fa fa-square-o";
                              data[i].classNo = "btn-default btn-xs fa fa-square-o";
                            }
                            $scope.dataRequisito = data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.marcarRequisito = function (item) {
                  if (item.marcado == 1) {
                    item.marcado = 0;
                    item.class = 'btn-default btn-xs fa fa-square-o';
                  } else {
                    item.marcado = 1;
                    item.class = 'btn-success btn-xs fa fa-check-square-o';
                  }
                }

                $scope.recibirInforme = function (item) {
                  var texto = "¿Está segura (o) de recibir los requisitos del POA y notificar al director subregional? \nEsta acción no se podrá reversar";
                  for (var i = 0; i < $scope.dataRequisito.length; i++) {
                    if ($scope.dataRequisito[i].marcado == 0) {
                      toastr.error('Todos los requisitos mostrados son requeridos');
                      return;
                    }
                  }
                  swal({
                    title: "Confirmación",
                    text: texto,
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var item = {};
                        var ttGestion = angular.copy($scope.gestion);
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        item.ttGestion.tcPersonaCrea.foto = null;
                        item.ttGestion.tcElaborador.foto = null;
                        item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                        item.requisito = $scope.dataRequisito;
                        item.tcPersonaIngresa = item.ttGestion.tcPersonaCrea;
                        item.tcPersonaIngresa.personaId = $scope.loginData.personaId;
                        tareaService.recibirInforme(item).then(function (res) {
                          if (res.status == "OK") {
                            toastr.success(res.message);
                            $state.go('^.list');
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

              }]
          })
          .state('index.informe.exento', {
            url: '/exento',
            templateUrl: 'app/informe/informe.list.tpl.html',
            resolve: {
              dataInforme: ['exentoService', 'authService', '$filter',
                function (exentoService, authService, $filter) {
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 7);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 2
                  }
                  return exentoService.getListaInformeSubregion(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataInforme', 'uiGridConstants', 'toastr', '$filter', 'exentoService', 'utilsService', 'gestionService', '$timeout', 'ngDialog', 'tareaService',
              function ($scope, $state, dataInforme, uiGridConstants, toastr, $filter, exentoService, utilsService, gestionService, $timeout, ngDialog, tareaService) {

                $scope.dataEstado = [
                  { estadoId: 2, estadoDesc: 'Solicitado' },
                  { estadoId: 3, estadoDesc: 'Recibido' }
                ];
                var now = new Date();
                var begin = $scope.addDays(now, -7);
                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2
                };

                $scope.mostrarImprimir = function (estadoId) {
                  if (estadoId == 2 || estadoId == 5) {
                    return false;
                  }
                  return true;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'tcPersonaCrea.personaDesc', name: 'personaDesc', displayName: 'Solicitante / Representante' },
                  { field: 'fechaElaboracion', name: 'fechaElaboracion', displayName: 'Fecha elaboración', width: '15%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'ttVentaExento.resolucion', name: 'resolucion', displayName: 'Resolución' },
                  { field: 'ttVentaExento.ttExento.codigo', name: 'codigo', displayName: 'RNF' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="!grid.appScope.mostrarImprimir(row.entity.estadoId)" ng-click="grid.appScope.recibirInforme(row.entity)" title="Clic para recibir informe"> <i class="fa fa-check-circle-o" aria-hidden="true"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarImprimir(row.entity.estadoId)" ng-click="grid.appScope.imprimirConstancia(row.entity)" title="Clic imprimir constancia"> <i class="fa fa-file-pdf-o text-red" aria-hidden="true"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataInforme.data;

                $scope.cargarDatos = function () {
                  var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: $scope.data.estadoId
                  }
                  exentoService.getListaInformeSubregion(filtro).then(function (res) {
                    if (res.status == "OK") {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirConstancia = function (item) {
                  exentoService.getInformeVenta(item.informeVentaId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      data.requisito = JSON.parse(data.requisito);
                      utilsService.generarConstanciaRecepcionExento(data, true, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.recibirInforme = function (item) {
                  if (item.estadoId == 2 || item.estadoId == 5) {
                    exentoService.getInformeVenta(item.informeVentaId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        $scope.row = item;
                        var notas = data.notas;
                        var usados = 0, anulados = 0, extraviados = 0;
                        for (var i = 0; i < notas.length; i++) {
                          if (notas[i].usoId == 1) {
                            usados++;
                          }
                          if (notas[i].usoId == 0) {
                            anulados++;
                          }
                          if (notas[i].usoId == 2) {
                            extraviados++;
                          }
                        }

                        var dataRequisito = [];
                        $scope.dataRequisito = dataRequisito;
                        ngDialog.open({
                          template: 'app/informe/informe.requisito.tpl.html',
                          className: 'ngdialog-theme-flat',
                          appendClassName: 'ngdialog-flat',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueEdit',
                          name: 'dialogEditar',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr', 'tareaService',
                            function ($scope, $state, toastr, tareaService) {

                              if ($scope.row.estadoId == 5) {
                                $scope.ocultarFolio = true;
                              }

                              $scope.row.requisito = dataRequisito;
                              $scope.requisito = [];

                              $scope.cerrarVentana = function () {
                                ngDialog.close();
                              }

                              $scope.agregarRequisitoInforme = function () {
                                $scope.requisito.push({ requisitoDesc: null });
                              }

                              $scope.borrarRequisitoInforme = function (item) {
                                $scope.requisito.splice($scope.requisito.indexOf(item), 1);
                              }

                              $scope.imprimirRequisitoFaltante = function () {
                                swal({
                                  title: "Confirmación",
                                  text: '¿Está seguro de anular la solicitud y habilitar la edición del mismo? \n Esta acción no se podrá reversar',
                                  icon: "warning",
                                  type: "warning",
                                  showCancelButton: true,
                                  confirmButtonClass: "btn-success",
                                  cancelButtonClass: "btn-danger",
                                  confirmButtonText: "Confirmar",
                                  cancelButtonText: "Cancelar",
                                  closeOnConfirm: true,
                                  closeOnCancel: true
                                },
                                  function (isConfirm) {
                                    if (isConfirm) {
                                      var data = angular.copy($scope.row);
                                      var requisito = $scope.requisito;
                                      var dataRequisito = [];
                                      for (var i = 0; i < data.requisito.length; i++) {
                                        if (data.requisito[i].marcado == 1) {
                                          dataRequisito.push(data.requisito[i]);
                                        }
                                      }
                                      for (var i = 0; i < requisito.length; i++) {
                                        dataRequisito.push(requisito[i]);
                                      }
                                      if (dataRequisito.length <= 0) {
                                        toastr.error('Debe seleccionar o agregar requisitos para generar el documento');
                                        return;
                                      }
                                      var ttInformeVenta = angular.copy(item);
                                      delete ttInformeVenta.requisito;
                                      exentoService.marcarInformeComoIncompleto(ttInformeVenta).then(function (res) {
                                        if (res.status == 1) {
                                          data.requisito = dataRequisito;
                                          utilsService.generarCompletacionExento(data, $scope.dataEmpresa, $scope.loginData.usuarioDesc, true);
                                          $scope.cargarDatos();
                                          ngDialog.close();
                                        } else {
                                          toastr.error(res.message);
                                        }
                                      }, function (error) {
                                        toastr.error(error);
                                      });
                                    }
                                  });
                              }

                              $scope.aceptarRequisito = function () {
                                var requisitos = [];
                                for (var i = 0; i < $scope.row.requisito.length; i++) {
                                  if ($scope.row.requisito[i].marcado == 0) {
                                    swal('Hay requisitos que no fueron marcados, debe seleccionar todos', 'Tome en cuenta que al seleccionar está indicando que el (los) requisito (s) viene (s) completo (s)');
                                    return;
                                  }
                                }
                                var item = angular.copy($scope.row);
                                var requisito = $scope.requisito;
                                for (var i = 0; i < requisito.length; i++) {
                                  item.requisito.push(requisito[i]);
                                }
                                item.requisito = JSON.stringify(item.requisito);
                                tareaService.recibirInformeExento(item).then(function (res) {
                                  if (res.status == "OK") {
                                    $scope.cargarDatos();
                                    ngDialog.close();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });

                              }

                            }]
                        });
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
      }
    ]
  );
