angular.module('app.avaluo', [
    'ui.router',
    'toastr',
    'app.avaluo.service'
])

    .config(
        ['$stateProvider', '$urlRouterProvider',
            function ($stateProvider, $urlRouterProvider) {
                $stateProvider
                    .state('index.avaluo', {
                        abstract: true,
                        url: 'avaluo',
                        template: '<div ui-view></div>',
                        resolve: {
                        },
                        controller: ['$scope', 'municipioService', 'fincaService', 'toastr',
                            function ($scope, municipioService, fincaService, toastr) {
                                $scope.module = 'Avalúo';
                                $scope.setDepartamento = function (item, tipo) {
                                    if (tipo == 1) {
                                        $scope.dataMunicipio = [];
                                    } else {
                                        $scope.dataMunicipioDependencia = [];
                                    }
                                    if (item != undefined && item != null) {
                                        municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                            if (res.status == "OK") {
                                                if (tipo == 1) {
                                                    $scope.dataMunicipio = res.data;
                                                } else {
                                                    $scope.dataMunicipioDependencia = res.data;
                                                }
                                            } else {
                                                toastr.error(res.message);
                                            }
                                        }, function (error) {
                                            toastr.error(error);
                                        });
                                    }
                                }
                            }]
                    })
                    .state('index.avaluo.lista', {
                        url: '',
                        templateUrl: 'app/avaluo/avaluo.list.tpl.html',
                        resolve: {
                            dataAvaluo: ['avaluoService', 'authService', '$filter', 'tareaService',
                                function (avaluoService, authService, $filter, tareaService) {
                                    var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                                    return avaluoService.listaPorPersona(loginData.personaId, -1);
                                }],
                            dataInstitucion: ['institucionService',
                                function (institucionService) {
                                    return institucionService.listaActiva();
                                }],
                            dataDependencia: ['personaService',
                                function (personaService) {
                                    return personaService.getDependenciasByPersona();
                                }],
                            dataDepartamento: ['departamentoService',
                                function (departamentoService) {
                                    return departamentoService.listaActiva();
                                }]
                        },
                        controller: ['$scope', '$state', 'dataAvaluo', 'avaluoService', '$filter', 'utilsService', 'tareaService', 'toastr',
                            'ngDialog', 'Upload', 'dataInstitucion', 'dataDependencia', 'dataDepartamento',
                            function ($scope, $state, dataAvaluo, avaluoService, $filter, utilsService, tareaService, toastr,
                                ngDialog, Upload, dataInstitucion, dataDependencia, dataDepartamento) {

                                $scope.dataDependencia = dataDependencia.data;
                                $scope.dataInstitucion = dataInstitucion.data;
                                $scope.dataDepartamento = dataDepartamento.data;

                                $scope.dataEstado = [
                                    {estadoId: 0, estadoDesc: 'Anulado'},
                                    {estadoId: 1, estadoDesc: 'Solicitado'},
                                    {estadoId: 2, estadoDesc: 'En trámite'},
                                    {estadoId: 3, estadoDesc: 'Finalizado'},
                                    {estadoId: -1, estadoDesc: 'Todos'}
                                ];

                                var now = new Date();
                                var begin = $scope.addDays(now, -30);

                                $scope.mostrarFiltro = true;

                                $scope.data = {
                                    fechaIni: begin,
                                    fechaFin: now,
                                    estadoId: 1,
                                    subregionId: $scope.loginData.subregionId
                                }

                                $scope.etiquetaEstado = function (estadoId) {
                                    switch (estadoId) {
                                        case 0: return 'Anulado';
                                        case 1: return 'Solicitado';
                                        case 2: return 'En trámite';
                                        case 3: return 'Finalizado';
                                    }
                                }

                                $scope.habilitarEdicion = function (estadoId) {
                                    if (estadoId == 1) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptions.columnDefs = [
                                    { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    { field: 'direccion', name: 'direccion', displayName: 'Dirección' },
                                    { field: 'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                                    { field: 'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                                    { field: 'personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false, width: '8%',
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + '<button type="button" class="btn btn-default btn-md" ng-show="grid.appScope.habilitarEdicion(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o text-primary fa-lg" aria-hidden="true"></i></button>'
                                            + '<button type="button" class="btn btn-default btn-md" ng-show="grid.appScope.habilitarEdicion(row.entity.estadoId)" ng-click="grid.appScope.disableRow(row.entity)" title="Anular solicitud"><i class="fa fa-close text-red fa-lg" aria-hidden="true"></i></button>'
                                            + '</span></div>'
                                    }
                                ];

                                $scope.gridOptions.data = dataAvaluo.data;

                                $scope.editRow = function (row) {

                                }

                                $scope.submitFormFiltro = function () {
                                    avaluoService.listaPorPersona($scope.loginData.personaId, -1).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptions.data = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.onFileSelect = function (file) {
                                    var url = 'file/upload';
                                    Upload.upload({
                                        url: appSettings.urlServiceBase + url,
                                        method: 'POST',
                                        file: file,
                                        sendFieldsAs: 'form',
                                        fields: {
                                            data: []
                                        }
                                    }).then(function (resp) {
                                        var data = resp.data;
                                        if (data.status == 'OK') {
                                            var obj = data.data[0];
                                            $scope.dataAvaluo.archivoNombre = obj.nombreOriginal;
                                            $scope.dataAvaluo.size = obj.size;
                                            $scope.dataAvaluo.extension = obj.extension;
                                            $scope.dataAvaluo.rutaArchivo = obj.rutaArchivo;
                                        } else {
                                            toastr.error(resp.data.message)
                                        }
                                    }, function (resp) {
                                        toastr.error('Error status: ' + resp.status);
                                    }, function (evt) {
                                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                        $scope.avance = progressPercentage;
                                    });
                                }

                                $scope.agregarSolicitud = function () {
                                    var dependencias = $scope.dataDependencia;
                                    $scope.dataAvaluo = {
                                        detalle: [],
                                        oficioOrigen: null,
                                        tcMunicipio: {}
                                    };
                                    if (dependencias.length > 0) {
                                        var row = dependencias[0];
                                        $scope.dataAvaluo.tcInstitucionDependencia = {
                                            institucionDependenciaId: row.institucionDependenciaId
                                        };
                                        $scope.dataAvaluo.tcInstitucion = {
                                            institucionId: row.tcInstitucion.institucionId
                                        };
                                    }
                                    $scope.mostrarInstitucion = false;
                                    $scope.mostrarDepartamento = true;
                                    $scope.requerirDocumento = true;
                                    ngDialog.open({
                                        template: 'app/avaluo/avaluo.add.tpl.html',
                                        className: 'ngdialog-theme-flat',
                                        appendClassName: 'ngdialog-flat',
                                        closeByDocument: false,
                                        closeByEscape: true,
                                        closeByNavigation: true,
                                        scope: $scope,
                                        id: 'uniqueEdit',
                                        name: 'dialogEditar',
                                        resolve: {
                                        },
                                        controller: ['$scope', '$state', 'toastr', 'tareaService',
                                            function ($scope, $state, toastr, tareaService) {

                                                $scope.cerrarVentana = function () {
                                                    ngDialog.close();
                                                }

                                                $scope.submitFormAvaluo = function ( isValid ) {

                                                    if (!isValid) {
                                                        swal('Rellene todos los campos obligatorios');
                                                        return;
                                                    }

                                                    if ($scope.dataAvaluo.rutaArchivo == null) {
                                                        swal('Es necesario agregar el documento de respaldo firmado y sellado');
                                                        return;
                                                    }
                                                    var detalle = $scope.dataAvaluo.detalle;
                                                    for (var i = 0; i < detalle.length; i++) {
                                                        var row = detalle[i];
                                                        if (row.descripcion == null || row.descripcion.length <= 0) {
                                                            swal('Todos los campos son obligatorios, favor de revisar');
                                                            return;
                                                        }
                                                    }
                                                    swal({
                                                        title: "Confirmación",
                                                        text: '¿Está seguro de enviar la solicitud? \n Esta acción no se podrá reversar',
                                                        icon: "warning",
                                                        type: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonClass: "btn-success",
                                                        cancelButtonClass: "btn-danger",
                                                        confirmButtonText: "Confirmar",
                                                        cancelButtonText: "Cancelar",
                                                        closeOnConfirm: true,
                                                        closeOnCancel: true
                                                    },
                                                        function (isConfirm) {
                                                            if (isConfirm) {
                                                                var data = angular.copy($scope.dataAvaluo);
                                                                if (data.detalle.length > 0) {
                                                                    data.detalle = JSON.stringify(data.detalle);
                                                                } else {
                                                                    data.detalle = null;
                                                                }
                                                                avaluoService.agregarSolicitud(data).then(function (res) {
                                                                    if (res.status == 1) {
                                                                        ngDialog.close();
                                                                        $scope.submitFormFiltro();
                                                                    } else {
                                                                        toastr.error(res.message);
                                                                    }
                                                                }, function (error) {
                                                                    toastr.error(error);
                                                                });
                                                            }
                                                        });

                                                }

                                                $scope.agregarDetalleAvaluo = function () {
                                                    $scope.dataAvaluo.detalle.push({ descripcion: null });
                                                }

                                                $scope.quitarDetalle = function (item) {
                                                    $scope.dataAvaluo.detalle.splice($scope.dataAvaluo.detalle.indexOf(item), 1);
                                                }

                                                $scope.setInstitucionDependencia = function (item) {

                                                }

                                            }]
                                    });
                                }

                            }]
                    })
                    .state('index.avaluo.recibir', {
                        url: '/recibir',
                        templateUrl: 'app/avaluo/avaluo.list.tpl.html',
                        resolve: {
                            dataAvaluo: ['avaluoService', 'authService', '$filter',
                                function (avaluoService, authService, $filter) {
                                    var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                                    var begin = new Date();
                                    var now = new Date();
                                    begin.setDate(now.getDate() - 30);
                                    var fechaIni = $filter('date')(begin, 'yyyy-MM-dd');
                                    var fechaFin = $filter('date')(now, 'yyyy-MM-dd');
                                    return avaluoService.listaPorSubregion(loginData.subregionId, 1, fechaIni, fechaFin);
                                }],
                            dataDepartamento: ['departamentoService',
                                function (departamentoService) {
                                    return departamentoService.listaActiva();
                                }],
                            dataMunicipio: ['subregionmunicipioService', 'authService',
                                function (subregionmunicipioService, authService) {
                                    var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                                    return subregionmunicipioService.getMunicipiosBySubregion(loginData.subregionId);
                                }],
                            dataInstitucion: ['institucionService', 'authService',
                                function (institucionService, authService) {
                                    return institucionService.listaActiva();
                                }]
                        },
                        controller: ['$scope', 'utilsService', 'dataAvaluo', 'avaluoService', '$filter', 'utilsService', 'tareaService', 'toastr',
                            'ngDialog', 'Upload', 'dataDepartamento', 'dataMunicipio', 'dataInstitucion', 'institucionDependenciaService',
                            function ($scope, utilsService, dataAvaluo, avaluoService, $filter, utilsService, tareaService, toastr,
                                ngDialog, Upload, dataDepartamento, dataMunicipio, dataInstitucion, institucionDependenciaService) {

                                $scope.dataDepartamento = dataDepartamento.data;
                                $scope.dataMunicipio = dataMunicipio.data;
                                $scope.dataInstitucion = dataInstitucion.data;
                                $scope.dataInstitucion.unshift({institucionId: 0, institucionDesc: 'Instituto Nacional de Bosques'});
                                $scope.dataEstado = [
                                    {estadoId: 0, estadoDesc: 'Anulado'},
                                    {estadoId: 1, estadoDesc: 'Solicitado'},
                                    {estadoId: 2, estadoDesc: 'En trámite'},
                                    {estadoId: 3, estadoDesc: 'Finalizado'},
                                    {estadoId: -1, estadoDesc: 'Todos'}
                                ];

                                var now = new Date();
                                var begin = $scope.addDays(now, -30);

                                $scope.mostrarFiltro = true;

                                $scope.data = {
                                    fechaIni: begin,
                                    fechaFin: now,
                                    estadoId: 1,
                                    subregionId: $scope.loginData.subregionId
                                }

                                $scope.etiquetaEstado = function (estadoId) {
                                    switch (estadoId) {
                                        case 0: return 'Anulado';
                                        case 1: return 'Solicitado';
                                        case 2: return 'En trámite';
                                        case 3: return 'Finalizado';
                                    }
                                }

                                $scope.habilitarRecepcion = function (estadoId) {
                                    if (estadoId == 1) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.mostrarConstancia = function ( estadoId ) {
                                    if (estadoId == 2 || estadoId == 3) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.mostrarAgregarDetalle = function ( estadoId ) {
                                    if (estadoId == 2) {
                                        return true;
                                    }
                                    return false;
                                }

                                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                                $scope.gridOptions.columnDefs = [
                                    { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                                    { field: 'direccion', name: 'direccion', displayName: 'Dirección' },
                                    { field: 'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                                    { field: 'expediente', name: 'expediente', displayName: 'Expediente' },
                                    { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                                    {
                                        name: 'Opciones', enableFiltering: false,
                                        cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                            + '<button type="button" class="btn btn-default btn-md" ng-show="grid.appScope.habilitarRecepcion(row.entity.estadoId)" ng-click="grid.appScope.agregarSolicitud(row.entity)" title="Recibir"><i class="fa fa-check text-primary fa-lg" aria-hidden="true"></i></button>'
                                            + '<button type="button" class="btn btn-default btn-md" ng-show="grid.appScope.habilitarRecepcion(row.entity.estadoId)" ng-click="grid.appScope.disableRow(row.entity)" title="Anular solicitud"><i class="fa fa-close text-red fa-lg" aria-hidden="true"></i></button>'
                                            + '<button type="button" class="btn btn-info btn-xs" ng-show="grid.appScope.mostrarConstancia(row.entity.estadoId)" ng-click="grid.appScope.imprimirConstanciaRecepcionAvaluo(row.entity)" title="Imprimir constancia de recepción"> <i class="zmdi zmdi-print"></i> </button> '
                                            + '<button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAgregarDetalle(row.entity.estadoId)" ng-click="grid.appScope.agregarSolicitud(row.entity)" title="Editar detalle de solicitud"> <i class="fa fa-bomb text-red"></i> </button> '
                                            + '</span></div>'
                                    }
                                ];

                                $scope.disableRow = function ( row ) {
                                    
                                }

                                $scope.gridOptions.data = dataAvaluo.data;

                                $scope.imprimirConstanciaRecepcionAvaluo = function ( item ) {
                                    utilsService.generarConstanciaRecepcionAvaluo(item, $scope.loginData.usuario, $scope.dataEmpresa);
                                }

                                $scope.submitFormFiltro = function () {
                                    var data = $scope.data;
                                    var fechaIni = $filter('date')(data.fechaIni, 'yyyy-MM-dd');
                                    var fechaFin = $filter('date')(data.fechaFin, 'yyyy-MM-dd');
                                    avaluoService.listaPorSubregion($scope.loginData.subregionId, data.estadoId, fechaIni, fechaFin).then(function (res) {
                                        if (res.status == 1) {
                                            $scope.gridOptions.data = res.data;
                                        } else {
                                            toastr.error(res.message);
                                        }
                                    }, function (error) {
                                        toastr.error(error);
                                    });
                                }

                                $scope.onFileSelect = function (file) {
                                    var url = 'file/upload';
                                    Upload.upload({
                                        url: appSettings.urlServiceBase + url,
                                        method: 'POST',
                                        file: file,
                                        sendFieldsAs: 'form',
                                        fields: {
                                            data: []
                                        }
                                    }).then(function (resp) {
                                        var data = resp.data;
                                        if (data.status == 'OK') {
                                            var obj = data.data[0];
                                            $scope.dataAvaluo.archivoNombre = obj.nombreOriginal;
                                            $scope.dataAvaluo.size = obj.size;
                                            $scope.dataAvaluo.extension = obj.extension;
                                            $scope.dataAvaluo.rutaArchivo = obj.rutaArchivo;
                                        } else {
                                            toastr.error(resp.data.message)
                                        }
                                    }, function (resp) {
                                        toastr.error('Error status: ' + resp.status);
                                    }, function (evt) {
                                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                        $scope.avance = progressPercentage;
                                    });
                                }                                

                                $scope.agregarSolicitud = function ( row ) {
                                    var item = angular.copy(row);
                                    var now = new Date();
                                    var anio = now.getFullYear();
                                    if (item == null) {
                                        $scope.dataAvaluo = {
                                            avaluoId: 0,
                                            detalle: [],
                                            oficioOrigen: null,
                                            tcMunicipio: {}
                                        };
                                        $scope.requerirDocumento = true;
                                    } else {
                                        $scope.dataAvaluo = item;
                                        $scope.dataAvaluo.detalle = JSON.parse(item.detalle);
                                        if (item.estadoId != 2) {
                                            $scope.dataAvaluo.correlativo = null;
                                            $scope.dataAvaluo.folios = null;
                                            $scope.dataAvaluo.anio = anio;
                                        }
                                    }
                                    $scope.mostrarInstitucion = true;
                                    $scope.mostrarDepartamento = false;
                                    $scope.esSecretaria = true;
                                    ngDialog.open({
                                        template: 'app/avaluo/avaluo.add.tpl.html',
                                        className: 'ngdialog-theme-flat',
                                        appendClassName: 'ngdialog-flat',
                                        closeByDocument: false,
                                        closeByEscape: true,
                                        closeByNavigation: true,
                                        scope: $scope,
                                        id: 'uniqueEdit',
                                        name: 'dialogEditar',
                                        resolve: {
                                        },
                                        controller: ['$scope', '$state', 'toastr', 'tareaService',
                                            function ($scope, $state, toastr, tareaService) {
                                                
                                                $scope.mostrarMunicipioDependencia = true;
                                                $scope.mostrarDependencia = true;

                                                $scope.setInstitucion = function ( item ) {                                                    
                                                    if (item.institucionId == 0) {
                                                        $scope.mostrarMunicipioDependencia = false;
                                                        $scope.mostrarDependencia = false;
                                                    } else {
                                                        $scope.mostrarMunicipioDependencia = true;
                                                        $scope.mostrarDependencia = true;
                                                    }
                                                }
                
                                                $scope.setMunicipioDependencia = function (item) {
                                                    if ($scope.dataAvaluo.tcInstitucion == null) {
                                                        toastr.error('Debe seleccionar el tipo de entidad');
                                                        return;
                                                    }
                                                    if ($scope.dataAvaluo.tcInstitucion.institucionId == null) {
                                                        toastr.error('Debe seleccionar el tipo de entidad');
                                                        return;
                                                    }                                                    
                                                    institucionDependenciaService.getByInstitucionAndMunicipio($scope.dataAvaluo.tcInstitucion.institucionId, item.municipioId).then(function (res) {
                                                        if (res.status == 1) {
                                                            $scope.dataDependencia = res.data;
                                                            $scope.dataAvaluo.tcMunicipioDependencia.municipioDesc = item.municipioDesc;
                                                        } else {
                                                            $scope.dataAvaluo.tcMunicipioDependencia.municipioId = null;
                                                            $scope.dataAvaluo.tcMunicipioDependencia.municipioDesc = null;
                                                            toastr.error(res.message);
                                                        }
                                                    }, function (error) {
                                                        toastr.error(error);
                                                    });
                                                }

                                                if ($scope.dataAvaluo.avaluoId > 0) {
                                                    $scope.setInstitucion(item.tcInstitucionDependencia.tcInstitucion);
                                                    $scope.dataAvaluo.tcInstitucion = {
                                                        institucionId: item.tcInstitucionDependencia.tcInstitucion.institucionId
                                                    };
                                                    $scope.departamentoId = item.tcMunicipio.tcDepartamento.departamentoId;
                                                    $scope.requerirDocumento = false;
                                                    $scope.departamentoDependenciaId = $scope.dataAvaluo.tcMunicipioDependencia.tcDepartamento.departamentoId;
                                                    $scope.setDepartamento($scope.dataAvaluo.tcMunicipioDependencia.tcDepartamento, 2);
                                                    $scope.setMunicipioDependencia($scope.dataAvaluo.tcMunicipioDependencia);
                                                    if ($scope.dataAvaluo.detalle == null) {
                                                        $scope.dataAvaluo.detalle = [];
                                                    }
                                                }

                                                $scope.verDocumentoRespaldo = function () {
                                                    if ($scope.dataAvaluo.avaluoId > 0) {
                                                        tareaService.getOficioAvaluo($scope.dataAvaluo.avaluoId);
                                                    }
                                                }

                                                $scope.submitFormAvaluo = function ( isValid ) {                                                    
                                                    if (!isValid) {
                                                        swal('Rellene todos los campos obligatorios');
                                                        return;
                                                    }
                                                    if ($scope.dataAvaluo.rutaArchivo == null && $scope.requerirDocumento) {
                                                        swal('Es necesario agregar el documento de respaldo firmado y sellado');
                                                        return;
                                                    }
                                                    var detalle = $scope.dataAvaluo.detalle;
                                                    for (var i = 0; i < detalle.length; i++) {
                                                        var row = detalle[i];
                                                        if (row.descripcion == null || row.descripcion.length <= 0) {
                                                            swal('Todos los campos son obligatorios, favor de revisar');
                                                            return;
                                                        }
                                                    }

                                                    swal({
                                                        title: "Confirmación",
                                                        text: '¿Está seguro de enviar la solicitud? \n Esta acción no se podrá reversar',
                                                        icon: "warning",
                                                        type: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonClass: "btn-success",
                                                        cancelButtonClass: "btn-danger",
                                                        confirmButtonText: "Confirmar",
                                                        cancelButtonText: "Cancelar",
                                                        closeOnConfirm: true,
                                                        closeOnCancel: true
                                                    },
                                                        function (isConfirm) {
                                                            if (isConfirm) {
                                                                var data = angular.copy($scope.dataAvaluo);
                                                                if (data.detalle.length > 0) {
                                                                    data.detalle = JSON.stringify(data.detalle);
                                                                } else {
                                                                    data.detalle = null;
                                                                }
                                                                if ($scope.dataAvaluo.avaluoId > 0) {
                                                                    if ($scope.dataAvaluo.estadoId == 2) { //si es actualizar detalle
                                                                        avaluoService.actualizarDetalleAvaluo(data).then(function (res) {
                                                                            if (res.status == 1) {
                                                                                ngDialog.close();
                                                                                $scope.submitFormFiltro();
                                                                            } else {
                                                                                toastr.error(res.message);
                                                                            }
                                                                        }, function (error) {
                                                                            toastr.error(error);
                                                                        });
                                                                    } else {
                                                                        avaluoService.recibirSolicitudSubregion(data).then(function (res) {
                                                                            if (res.status == 1) {
                                                                                ngDialog.close();
                                                                                $scope.submitFormFiltro();
                                                                            } else {
                                                                                toastr.error(res.message);
                                                                            }
                                                                        }, function (error) {
                                                                            toastr.error(error);
                                                                        });
                                                                    }
                                                                } else {
                                                                    avaluoService.agregarSolicitudSubregion(data).then(function (res) {
                                                                        if (res.status == 1) {
                                                                            ngDialog.close();
                                                                            $scope.submitFormFiltro();
                                                                        } else {
                                                                            toastr.error(res.message);
                                                                        }
                                                                    }, function (error) {
                                                                        toastr.error(error);
                                                                    });
                                                                }
                                                            }
                                                        });

                                                }

                                                $scope.setMunicipio = function name( item ) {
                                                    $scope.departamentoId = item.tcDepartamento.departamentoId;
                                                }

                                                $scope.agregarDetalleAvaluo = function () {
                                                    $scope.dataAvaluo.detalle.push({ descripcion: null });
                                                }

                                                $scope.quitarDetalle = function (item) {
                                                    $scope.dataAvaluo.detalle.splice($scope.dataAvaluo.detalle.indexOf(item), 1);
                                                }                                                

                                            }]
                                    });
                                }

                            }]
                    })
            }
        ]
    );