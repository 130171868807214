angular.module( 'app.cefe', [
  'ui.router',
  'toastr',
  'app.authService'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        //////////////
        // Login //
        //////////////
        .state( 'cefe', {

          url: '/cefe',

          templateUrl: 'app/cefe/cefe.tpl.html',
          resolve: {
          },
          controller: ['$scope', '$state', '$timeout', 'toastr', 'authService', '$base64', 'ngDialog', 'manualService', 'deviceDetector', 'utilsService',
            function (  $scope,   $state,   $timeout,   toastr,   authService,  $base64, ngDialog, manualService, deviceDetector, utilsService) {
              var version = authService.getLocalData('appVersionManejo');
              if (version != appSettings.appVersion) {
                authService.saveLocalData('appVersionManejo', appSettings.appVersion);
                $state.reload();
              }
              $scope.deviceDetector = deviceDetector;
              if ($scope.deviceDetector.raw.browser.ie) {
                swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome en sus más recientes versiones');
              }

              $scope.total = 0;
              $scope.malo = false;
              $scope.regular = false;
              $scope.bueno = false;
              $scope.titulo = 'Ponderación de valores para Bosque Latifoliado, Bosque Nuboso, Bosque Seco, Bosque Mixto (coníferas y latifoliadas) y Bosque de Coníferas';
              $scope.esMangle = false;

              var data = [
                {correlativo: 1, descripcion: 'Regeneración Natural', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 5},
                {correlativo: 2, descripcion: 'Familias forestales representativas', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                {correlativo: 3, descripcion: 'Abundancia relativa de familias representativas', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                {correlativo: 4, descripcion: 'Especies de flora amenazada o vulnerable', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                {correlativo: 5, descripcion: 'Cobertura de copas/área basal (bosque seco)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                {correlativo: 6, descripcion: 'Condiciones del entorno (condiciones externas del área)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                {correlativo: 7, descripcion: 'Condiciones del entorno (condiciones internas del área)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                {correlativo: 8, descripcion: 'Caminos, senderos y canales', importancia: 1, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                {correlativo: 9, descripcion: 'Incendios forestales', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 5},
                {correlativo: 10, descripcion: 'Flora asociada', importancia: 2, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                {correlativo: 11, descripcion: 'Fauna silvestre', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 6},
                {correlativo: 12, descripcion: 'Número de estratos', importancia: 1, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                {correlativo: 13, descripcion: 'Clases diamétricas', importancia: 1, ponderada: 7.14, campo: 0, estado: 0, valor: 5}
              ];

              $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 0,
                format: 'dd/MM/yyyy',
                formatDateTime: 'dd/MM/yyyy HH:mm',
                showMeridian: false
              };
              
              $scope.openedDates = {};

              // funcion que muestra el datepicker
              $scope.openDate = function($event, field) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedDates[field] = true;
              };

              $scope.calcularTotal = function () {
                var valor = 0;
                for (var i = 0; i < $scope.dataValor.length; i++) {
                  var row = $scope.dataValor[i];
                  valor += parseFloat(row.estado);
                }
                valor = parseFloat(valor).toFixed(2);
                $scope.total = valor;
                if (valor < 70.91) {
                  $scope.malo = false;
                  $scope.regular = true;
                  $scope.bueno = false;
                }
                if (valor > 70.90) {
                  $scope.malo = false;
                  $scope.regular = false;
                  $scope.bueno = true;
                }
              }

              $scope.setMangle = function () {
                $scope.esMangle = !$scope.esMangle;
                if ($scope.esMangle) {
                  $scope.titulo = 'Ponderación de valores para Manglar';
                  $scope.dataValor = [
                    {correlativo: 1, descripcion: 'Regeneración Natural', importancia: 1, ponderada: 3.33, campo: 0, estado: 0, valor: 5},
                    {correlativo: 2, descripcion: 'Familias Forestales representativas', importancia: 3, ponderada: 10, campo: 0, estado: 0, valor: 5},
                    {correlativo: 3, descripcion: 'Abundancia relativa de familias representativas', importancia: 3, ponderada: 10, campo: 0, estado: 0, valor: 5},
                    {correlativo: 4, descripcion: 'Especies de flora amenazada o vulnerable', importancia: 3, ponderada: 10, campo: 0, estado: 0, valor: 5},
                    {correlativo: 5, descripcion: 'Cobertura de copas/Área Basal (Bosque seco)', importancia: 2, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 6, descripcion: 'Condiciones del entorno (condiciones externas)', importancia: 2, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 7, descripcion: 'Condiciones del entorno (condiciones internas del área)', importancia: 2, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 8, descripcion: 'Caminos, senderos y canales', importancia: 1, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 9, descripcion: 'Incendios forestales', importancia: 1, ponderada: 3.33, campo: 0, estado: 0, valor: 5},
                    {correlativo: 10, descripcion: 'Flora asociada', importancia: 2, ponderada: 10, campo: 0, estado: 0, valor: 5},
                    {correlativo: 11, descripcion: 'Fauna silvestre', importancia: 1, ponderada: 3.33, campo: 0, estado: 0, valor: 6},
                    {correlativo: 12, descripcion: 'Número de estratos', importancia: 1, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 13, descripcion: 'Clases diamétricas', importancia: 1, ponderada: 6.67, campo: 0, estado: 0, valor: 5},
                    {correlativo: 14, descripcion: 'Inundación', importancia: 1, ponderada: 10, campo: 0, estado: 0, valor: 5}
                  ];
                } else {
                  $scope.titulo = 'Ponderación de valores para Bosque Latifoliado, Bosque Nuboso, Bosque Seco, Bosque de Coníferas y Bosque Mixto (coníferas y latifoliadas)';
                  $scope.dataValor = [
                    {correlativo: 1, descripcion: 'Regeneración Natural', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 5},
                    {correlativo: 2, descripcion: 'Familias forestales representativas', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                    {correlativo: 3, descripcion: 'Abundancia relativa de familias representativas', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                    {correlativo: 4, descripcion: 'Especies de flora amenazada o vulnerable', importancia: 3, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                    {correlativo: 5, descripcion: 'Cobertura de copas/área basal (bosque seco)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                    {correlativo: 6, descripcion: 'Condiciones del entorno (condiciones externas del área)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                    {correlativo: 7, descripcion: 'Condiciones del entorno (condiciones internas del área)', importancia: 2, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                    {correlativo: 8, descripcion: 'Caminos, senderos y canales', importancia: 1, ponderada: 7.14, campo: 0, estado: 0, valor: 5},
                    {correlativo: 9, descripcion: 'Incendios forestales', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 5},
                    {correlativo: 10, descripcion: 'Flora asociada', importancia: 2, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                    {correlativo: 11, descripcion: 'Fauna silvestre', importancia: 1, ponderada: 3.57, campo: 0, estado: 0, valor: 6},
                    {correlativo: 12, descripcion: 'Número de estratos', importancia: 1, ponderada: 10.71, campo: 0, estado: 0, valor: 5},
                    {correlativo: 13, descripcion: 'Clases diamétricas', importancia: 1, ponderada: 7.14, campo: 0, estado: 0, valor: 5}
                  ];
                }
                $scope.calcularTotal();
              }

              $scope.descargarFicha = function ( id ) {
                authService.getFile( id );
              }

              $scope.setPorcentajeClase = function ( item ) {
                if (parseFloat(item.campo) < 0  || parseFloat(item.campo) > parseInt(item.valor)) {
                  swal('Los valores válidos para esta variable estan entre: 0 - ' + parseFloat(item.valor));
                  item.campo = 0;
                  item.estado = 0;
                  $scope.total = 0;
                  return;
                }
                var valor = (parseFloat(item.ponderada) * parseFloat(item.campo)) / parseInt(item.valor);
                item.estado = parseFloat(valor).toFixed(2);
                $scope.calcularTotal();
              }

              $scope.dataValor = data;

              $scope.cefeData = {
                usuario : null,
                claveUsuario : null,
                estadoId : 1,
                sistemaId : appSettings.sistemaId
              }

              $scope.data = {};

              $scope.imprimirResultado = function () {
                utilsService.openWindow( '#printable', $scope, 'Resultado' );
              }

              $scope.imprimirFormato = function ( isValid ) {
                if (isValid) {
                  var estado = 0;
                  if ($scope.malo) {
                    estado = 0;
                  }
                  if ($scope.regular) {
                    estado = 1;
                  }
                  if ($scope.bueno) {
                    estado = 2;
                  }
                  utilsService.generarFormularioCefe($scope.dataValor, $scope.data, $scope.total, estado, authService.getFoto());
                } else {
                  toastr.error('Por favor complete la información');
                }
              }

           }] //end controller
        })
    }
  ]
);
