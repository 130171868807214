angular.module('app.notaenvio.service', [

])

.factory('notaenvioService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    getCuenta: function (filtro) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/cuenta', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    getListaSolicitud: function (filtro) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/solicitud', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : -1, estadoDesc : 'Todos'});
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      data.push({estadoId : 2, estadoDesc : 'Finalizado'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    listaEstadoSolicitud: function () {
      var data = [];
      data.push({estadoId : -1, estadoDesc : 'Todos'});
      data.push({estadoId : 0, estadoDesc : 'Anulado'});
      data.push({estadoId : 1, estadoDesc : 'Solicitado'});
      data.push({estadoId : 2, estadoDesc : 'Autorizado'});
      data.push({estadoId : 99, estadoDesc : 'Rechazado'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    listaEstadoSolicitudParaAutorizar: function () {
      var data = [];
      data.push({estadoId : 1, estadoDesc : 'Solicitado'});
      data.push({estadoId : 2, estadoDesc : 'Autorizado'});
      data.push({estadoId : 99, estadoDesc : 'Rechazado'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    addSolicitud: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/solicitud/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/edit', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    addMove: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/agregar/movimiento', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    anularSolicitud: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/anular', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    cancelarSolicitud: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/solicitud/cancelar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    rechazarSolicitud: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/solicitud/rechazar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    autorizarSolicitud: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/solicitud/' + data.ttSolicitudNe.solicitudNeId + '/autorizar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    autorizarDespacho: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/solicitud/' + data.solicitudNeId + '/autorizar/despacho', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getCuentaNe : function( cuentaNeId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/cuenta/' + cuentaNeId).then(function ( res ) {
         return res.data;
       });
    },
    getSolicitud : function( solicitudNeId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/solicitud/' + solicitudNeId).then(function ( res ) {
         return res.data;
       });
    },
    getListaSolicitudAutorizar : function( filtro ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/autorizar/solicitud', filtro).then(function ( res ) {
         return res.data;
       });
    },
    addInventario: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/inventario/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getInventarioActivo: function (filtro) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/detalle/cuenta/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    getSolicitudAutorizada : function( solicitudNeId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/solicitud/' + solicitudNeId + '/autorizada').then(function ( res ) {
         return res.data;
       });
    },
    obtenerSaldos: function (filtro) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/obtener/saldos', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    getMovimientoNota: function (filtro) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/movimiento', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    buscarCorrelativoInicial : function( inicio ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/buscar/inicio/' + inicio).then(function ( res ) {
         return res.data;
       });
    },
    getReservaNota: function (filtro) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/reserva', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    getLibroNota : function( regionId, anio, tipoNotaEnvioId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/libro/nota/' + regionId + '/' + anio + '/' + tipoNotaEnvioId).then(function ( res ) {
         return res.data;
       });
    },
    getLibroNotaSubregion : function( subregionId, anio, tipoNotaEnvioId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/libro/nota/' + subregionId + '/' + anio + '/' + tipoNotaEnvioId + '/subregion').then(function ( res ) {
         return res.data;
       });
    },
    getDatosLibro : function( corteLibroNotaId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/libro/' + corteLibroNotaId + '/datos').then(function ( res ) {
         return res.data;
       });
    },
    eleminarUltimoMovimiento : function(tipoNotaEnvioId) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/anular/ultimo/movimiento/' + tipoNotaEnvioId).then(function ( res ) {
         return res.data;
       });
    },
    getListaLibroNota : function( anio, mes ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/libro/todos/' + anio + '/' + mes).then(function ( res ) {
         return res.data;
       });
    },
    getListaCompraNota : function( personaId, tipoNotaEnvioId, anios ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/historial/' + personaId + '/' + tipoNotaEnvioId + '/' + anios).then(function ( res ) {
         return res.data;
       });
    },
    anularReservaNota: function (reservaCorrelativoId, item) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/anular/reserva/' + reservaCorrelativoId, item ).then(function ( res ) {
         return res.data;
       });
    },
    finalizarReservaNota: function (reservaCorrelativoId, item) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/finalizar/reserva', item ).then(function ( res ) {
         return res.data;
       });
    },
    anularNotas: function (data) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/anular/notas', data ).then(function ( res ) {
         return res.data;
       });
    },
    refrescarCorte: function (data) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/actualizar/corte', data ).then(function ( res ) {
         return res.data;
       });
    },
    getListaNotaAnulada: function( data ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/lista/anulada', data).then(function ( res ) {
         return res.data;
       });
    },
    getDetalleAnulacion : function( notaAnuladaId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/detalle/anulacion/' + notaAnuladaId).then(function ( res ) {
         return res.data;
       });
    },
    reorganizarSaldos : function( mes, anio, subregionId, tipoNotaEnvioId, inicio ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/corregir/correlativo/' + mes + '/' + anio + '/' + subregionId + '/' + tipoNotaEnvioId + '/' + inicio).then(function ( res ) {
         return res.data;
       });
    },
    getCorrelativoNota : function( subregionId, tipoNotaEnvioId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/correlativo/' + subregionId + '/' + tipoNotaEnvioId).then(function ( res ) {
         return res.data;
       });
    },
    getReporteVentaNota : function( anio, mes, tipoNotaEnvioId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/reporte/' + mes + '/' + anio  + '/' + tipoNotaEnvioId, {responseType: 'arraybuffer'})
       .then(function (data) {
         if (data.status == 200) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         } else {
           toastr.error(data);
         }
       });
    },
    getInformacionNota : function( tipoNotaEnvioId, numero ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/datos/nota/' + tipoNotaEnvioId + '/' + numero).then(function ( res ) {
         return res.data;
       });
    },
    getReporteNotaByFecha : function( tipoNotaEnvioId, regionId, subregionId, fechaIni, fechaFin, tipoReporteId ) {
      var params = {
        tipoNotaEnvioId: tipoNotaEnvioId,
        regionId: regionId,
        subregionId: subregionId,
        fechaIni: fechaIni,
        fechaFin: fechaFin,
        tipoReporteId: tipoReporteId
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'notaenvio/reporte/notaEnvio',  config).then(function ( res ) {
         return res.data;
       });
    },
    getDataMovimientoNota : function( movimientoNotaId ) {
      return $http.get( appSettings.urlServiceBase + 'notaenvio/movimiento/' + movimientoNotaId).then(function ( res ) {
         return res.data;
       });
    },
    updMovimientoNota: function (data) {
      return $http.put( appSettings.urlServiceBase + 'notaenvio/movimiento', data ).then(function ( res ) {
         return res.data;
       });
    },
    devolverNotas: function (detalle) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/devolver/notas', detalle ).then(function ( res ) {
         return res.data;
       });
    },
    asignarUsuarioLibro: function ( corteLibroNotaId ) {
      return $http.post( appSettings.urlServiceBase + 'notaenvio/asignar/usuario/corte/' + corteLibroNotaId ).then(function ( res ) {
         return res.data;
       });
    },
    getReporteDistribucionExistencia : function(regionId, subregionId, tipoNotaEnvioId, nivel, fechaIni, fechaFin ) {
      var params = {
        tipoNotaEnvioId: tipoNotaEnvioId,
        regionId: regionId,
        subregionId: subregionId,
        fechaIni: fechaIni,
        fechaFin: fechaFin,
        nivel: nivel
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'notaenvio/reporte/distribucion/notaEnvio',  config).then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
