/////////////////////////////
// Parametros del sistema //
/////////////////////////////
var appSettings = {
  
  //Producción
  
  isDevelopment: false,
  urlServiceBase: 'https://manejoforestal.inab.gob.gt:8443/manejo/',
  urlBase: 'https://manejoforestal.inab.gob.gt/#!/',
  urlBaseRegistro: 'https://manejoforestal.inab.gob.gt:8443/bridge/',
  

  
  
  //urlServiceBase: 'https://manejocapa.inab.gob.gt:8443/capa/',
  

  //Capa
/*
  urlServiceBase: 'https://manejocapa.inab.gob.gt:8443/manejo-capa/',
  urlBase: 'https://manejocapa.inab.gob.gt/interno/#!/',
  urlBaseRegistro : 'https://manejocapa.inab.gob.gt:8443/bridge-rnf-capa/',
  //urlBaseRegistro : 'https://manejocapa.inab.gob.gt:8443/bridge-capa/',
    isDevelopment: true,
*/

//Debuggear local
/*
urlServiceBase: 'http://localhost:8080/',
  urlBase: 'http://localhost:8080/#!/',
  //urlBaseRegistro : 'https://manejocapa.inab.gob.gt:8443/bridge-capa/',
  urlBaseRegistro : 'https://manejocapa.inab.gob.gt:8443/bridge-rnf-capa/',
 // urlBaseRegistro : 'https://manejocapa.inab.gob.gt:8443/bridge-rnf-localhost/',
  urlServiceBase: 'http://localhost:8080/',
  isDevelopment: true,
  */
 
  //  urlBase: 'https://manejocapa.inab.gob.gt:8443/#!/',
  

  timeOuttoastrNotifications: '15000',
  paginationPageSizes: [10, 15, 20, 25, 50],
  paginationMinPageSizes: [10, 15, 25, 50],
  paginationPageSize: 10,
  smartTable: { itemsByPage: 5, displayedPages: 5 },
  appVersion: 'VM001A054',
  sistemaId: 3,
  areaMinimaDistribucion: 15, //tambien se encuentra en utils-service
  densidad: 1111,
  extraccionMinima: 70,
  montoFondo: 20900,
  montoCompromiso: 19000,
  seed: 'none',
  porcentajeMaximo: 10,
  documentAttempts: 5
};

/////////////////////////////
// Inicializacion de modulo//
// principal del sistema   //
/////////////////////////////
angular.module('app', [
  'templates-app',
  'ngSanitize',
  'ui.bootstrap',
  'ui.grid',
  'ui.grid.pagination',
  'ui.grid.selection',
  'ui.grid.edit',
  'ui.grid.autoResize',
  'ui.grid.grouping',
  'ui.grid.treeView',
  'ui.grid.exporter',
  'ui.router',
  'ui.select',
  'ui.calendar',
  'mwl.calendar',
  'LocalStorageModule',
  'chieffancypants.loadingBar',
  'toastr',
  'ngDialog',
  'ui.mask',
  'ui.bootstrap.datetimepicker',
  'base64',
  'ngFileUpload',
  'angular-timeline',
  'vcRecaptcha',
  'uiSwitch',
  'ng.deviceDetector',
  'ngMap',
  'chart.js',
  'textAngular',
  'smart-table',
  'app.utilsService',
  'app.authService',
  'app.catalogosService',
  'app.authInterceptorService',
  'app.directives',
  'app.forbidden',
  'app.login',
  'app.cefe',
  'app.cefeCalc',
  'app.404',
  'app.clave',
  'app.empresa',
  'app.pais',
  'app.actividad',
  'app.departamento',
  'app.municipio',
  'app.finca',
  'app.tipodocumento',
  'app.tipoentidad',
  'app.tipopropietario',
  'app.tipopropiedad',
  'app.tiponotificacion',
  'app.criterioproteccion',
  'app.clasedesarrollo',
  'app.tratamientosilvicultural',
  'app.valortipomadera',
  'app.sistemarepoblacion',
  'app.sistemacorta',
  'app.tipogarantia',
  'app.origencalculo',
  'app.tipoinventario',
  'app.tipomuestreo',
  'app.tipoespecie',
  'app.tipomadera',
  'app.formaparcela',
  'app.criteriocorta',
  'app.metodosiembra',
  'app.especie',
  'app.usofinca',
  'app.persona',
  'app.personafinca',
  'app.tipocorrelativo',
  'app.correlativo',
  'app.idioma',
  'app.cultura',
  'app.estadocivil',
  'app.region',
  'app.unidadmedida',
  'app.producto',
  'app.subregion',
  'app.subregionmunicipio',
  'app.tipomonto',
  'app.montogarantia',
  'app.plantipogestion',
  'app.gestion',
  'app.plan',
  'app.solicitud',
  'app.tarea',
  'app.realizar',
  'app.tstudent',
  'app.version',
  'app.requisito',
  'app.resolucion',
  'app.poa',
  'app.tipobosque',
  'mgcrea.ngStrap',
  'app.calidadfuste',
  'app.ocupacion',
  'app.tiporepresentacion',
  'app.manual',
  'app.perfilmanual',
  'app.notaenvio',
  'app.tiponotaenvio',
  'app.libro',
  'app.clasediametrica',
  'app.usuariosubregion',
  'app.fuentesemillera',
  'app.fuente',
  'app.comercializador',
  'app.motivoanulacionnota',
  'app.exento',
  'app.productotransporte',
  'app.tipogestion',
  'app.informe',
  'app.vivero',
  'app.motivomodificacion',
  'app.tipotransporte',
  'app.categoriaexento',
  'app.tipoexento',
  'app.subtipoexento',
  'app.menu',
  'app.perfil',
  'app.sistema',
  'app.usuarioperfilsistema',
  'app.menuperfilsistema',
  'app.enmienda',
  'app.seguimiento',
  'app.usuario',
  'app.reporte',
  'app.actividadforestal',
  'app.tipocobertura',
  'app.afianzadora',
  'app.errortarea',
  'app.consulta',
  'app.especieTalaIlicita',
  'app.institucion',
  'app.consumo',
  'app.parentesco',
  'app.institucionDependencia',
  'app.avaluo',
  'app.tipoQueja',
  'app.motivoQueja',
  'app.queja',
  'app.escenarioAvaluo',
  'app.cargaMaderable',
  'app.tipoCosto',
  'app.costoEvitado',
  'app.lesionEconomica',
  'app.puesto',
  'app.convenioMunicipal',
  'app.tipoCostoConsumo',
  'app.holiday'
])

  .filter('dateFilter', ['$filter', function ($filter) {
    return function (input) {
      if (!input) {
        return '';
      } else {
        return $filter('date')(new Date(input), 'dd/MM/yyyy');
      }
    };
  }])

  .filter('propsFilter', [function () {
    return function (items, props) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function (item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];            
            var text = props[prop].toLowerCase();
            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }])

  .run(
    ['$rootScope', '$state', '$stateParams', 'appSettings', 'toastr', 'ngDialog',
      function ($rootScope, $state, $stateParams, appSettings, toastr, ngDialog) {

        // add references to $state and $stateParams to the $rootScope
        // For example <li ng-class="{ active: $state.includes( 'contacts.list' ) }"> will set the <li>
        // to active whenever 'contacts.list' or one of its decendents is active.
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
          // prevenir accion por default
          event.preventDefault();
          // cerrar todos los dialogos abiertos si hay
          ngDialog.close();

          if (error && (error.status == 401 || error.status == 400)) {
            $state.go('login');
          } else {
            if (error && error.status == 403) {
              $state.go('index.forbidden.show');
            } else {
              location.href = '#/';
            }
          }
        });
      }
    ]
  )

  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptorService');
    $httpProvider.defaults.timeout = 5000;
  }])

  .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.spinnerTemplate = '<div class="spinner"> Cargando... <div id="loading-bar-spinner"><div class="preloader pl-lg"> <svg class="pl-circular" viewBox="50 50 100 100"><circle class="plc-path" cx="100" cy="100" r="40"></circle></svg> </div></div> </div>';
    //cfpLoadingBarProvider.spinnerTemplate = '<div><span class="fa fa-spinner">Custom Loading Message...</div>';
  }])

  .config(['ngDialogProvider', function (ngDialogProvider) {
    ngDialogProvider.setDefaults({
      className: 'ngdialog-theme-flat',
      showClose: true,
      closeByDocument: true,
      closeByEscape: true,
      cache: true,
      overlay: true
    });
  }])

  .config(['toastrConfig', function (toastrConfig) {
    angular.extend(toastrConfig, {
      closeButton: true,
      autoDismiss: false,
      timeOut: 10000,
      containerId: 'toast-container',
      maxOpened: 0,
      newestOnTop: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      preventOpenDuplicates: false,
      target: 'body',
      bodyOutputType: 'trustedHtml'
    });
  }])

  .config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(false);
  }])

  .config(['ChartJsProvider', function (ChartJsProvider) {
    ChartJsProvider.setOptions({ colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
  }])

  /* .config( function (calendarConfig) {
    calendarConfig.showTimesOnWeekView = true; //Make the week view more like the day view, with the caveat that event end times are ignored.
  })*/

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {

        angular.lowercase = angular.$$lowercase;

        var authenticated = ['$location', '$q', 'authService', function ($location, $q, authService) {
          var deferred = $q.defer();
          if (authService.isLoggedIn()) {
            deferred.resolve();
          } else {
            deferred.reject({ status: 401 });
          }
          return deferred.promise;
        }];

        /////////////////////////////
        // Redirects and Otherwise //
        /////////////////////////////

        $urlRouterProvider

          // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
          .otherwise('/');


        //////////////////////////
        // State Configurations //
        //////////////////////////

        // Use $stateProvider to configure your states.
        $stateProvider

          //////////
          // Home //
          //////////

          .state("index", {

            abstract: true,

            url: "/",

            templateUrl: 'app/index.tpl.html',

            resolve: {
              // descomentar para que tenga seguridad la ruta
              authenticated: authenticated
            },

            controller: ['$scope', 'toastr', 'appSettings', 'utilsService', 'authService', '$state', 'empresaService',
              'especieService', 'deviceDetector', 'tareaService', 'ngDialog',
              function ($scope, toastr, appSettings, utilsService, authService, $state, empresaService,
                especieService, deviceDetector, tareaService, ngDialog) {

                var tcVersion = authService.getLocalData('appVersionManejo');
                if (tcVersion.versionDesc == null) {
                  authService.logOut();
                  window.location.reload();
                }
                if (tcVersion.versionDesc != appSettings.appVersion) {
                  authService.saveLocalData('appVersionManejo', appSettings.appVersion);
                  window.location.reload();
                }
                $scope.loginData = authService.getLocalData('loginData' + appSettings.sistemaId);

                $scope.mostrarAnuncio = function () {
                  ngDialog.open({
                    template: 'app/home/anuncio.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {},
                    controller: ['$scope', '$state', 'toastr',
                        function ($scope, $state, toastr) {

                        }]
                });
                }
                if (tcVersion.mostrarHasta != null) {
                  var now = new Date();
                  if (tcVersion.mostrarHasta == null) {
                    tcVersion.mostrarHasta = new Date();
                  }
                  var mostrarHasta = new Date(tcVersion.mostrarHasta);
                  if (now < mostrarHasta) {
                    var titulo = tcVersion.titulo;
                    if (titulo == null) {
                      titulo = 'Actualización: ' + tcVersion.versionDesc;
                    }
                    $scope.tituloAnuncio = titulo;
                    $scope.detalleAnuncio = tcVersion.detalle;
                    if (tcVersion.perfil == null) {
                      $scope.mostrarAnuncio();
                    } else {
                      var perfilId = $scope.loginData.perfilId;
                      var lista = tcVersion.perfil.split(',');
                      for (var i = 0; i < lista.length; i++) {
                        if (parseInt(lista[i].trim()) == perfilId) {
                          $scope.mostrarAnuncio();
                          break;
                        }
                      }
                    }
                  }
                }
                $scope.deviceDetector = deviceDetector;
                if ($scope.deviceDetector.raw.browser.ie) {
                  swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome en sus más recientes versiones');
                }

                $scope.globales = {
                  version: appSettings.appVersion,
                  year: (new Date()).getFullYear(),
                  accion: 0, //0 = nada, 1 = crear plan, 2 = modificar plan
                  stateToGo: "",
                  paramStateToGo: "",
                  fincaId: null,
                  parsearInventario: true,
                  parsearPlanificacion: true,
                  totalPaginas: 0,
                  ttGestion: null,
                  esExento: false
                }

                $scope.mostrarTelefonoAyuda = false;
                $scope.dataModuloAyuda = [
                  {moduloId: 1, moduloDesc: 'Consumos Familiares Municipales', telefono: '(+502) 23214615', correo: 'fortalecimiento.forestal@inab.gob.gt'},
                  {moduloId: 2, moduloDesc: 'Exentos de Licencia de Aprovechamiento Forestal', telefono: '(+502) 23214534', correo: 'soporte.exentos@inab.gob.gt'},
                  {moduloId: 3, moduloDesc: 'Licencias de Aprovechamiento Forestal', telefono: '(+502) 23214534', correo: 'licencias.manejo@inab.gob.gt'},
                  {moduloId: 4, moduloDesc: 'Consumos Familiares y Notas de Envío', telefono: '(+502) 23214534', correo: 'consumos.familiares@inab.gob.gt'},
                ];

                $scope.habilitarTelefonoAyuda = function () {
                  $scope.mostrarTelefonoAyuda = !$scope.mostrarTelefonoAyuda;
                }

                $scope.setModuloAyuda = function ( item ) {
                  $scope.moduloAyuda = item;
                }

                //obtener catalogos generales para el sistema
                empresaService.get(1).then(function (res) {
                  if (res.status == "OK") {
                    $scope.dataEmpresa = res.data[0];
                  } else {
                    toastr.error("Error al obtener datos generales: " + res.message + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                  }
                }, function (error) {
                  toastr.error("Error al obtener datos generales: " + error + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                });

                if ($scope.loginData.perfilId == 4 || $scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 6
                  || $scope.loginData.perfilId == 7 || $scope.loginData.perfilId == 8) {
                  //obtener tareas
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.dataTipoQueja = [];
                $scope.dataRegionQueja = [];

                $scope.agregarComentario = function () {
                  var tmpTipoQueja = $scope.dataTipoQueja;
                  var tmpRegionQueja = $scope.dataRegionQueja;
                  $scope.listarOpcionesQueja = false;
                  $scope.agregarComentarioQueja = false;
                  ngDialog.open({
                    template: 'app/home/opinion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEnmienda',
                    name: 'uniqueEnmienda',
                    resolve: {
                      dataTipoQueja: ['tipoQuejaService', '$stateParams',
                        function (tipoQuejaService, $stateParams) {
                          if (tmpTipoQueja.length > 0) {
                            return {
                              status: 1,
                              message: 'Datos cargados',
                              data: tmpTipoQueja
                            };
                          } else {
                            return tipoQuejaService.listaActiva();
                          }
                        }],
                      dataRegionQueja: ['regionService', '$stateParams',
                        function (regionService, $stateParams) {
                          if (tmpRegionQueja.length > 0) {
                            return {
                              status: 1,
                              message: 'Datos cargados',
                              data: tmpRegionQueja
                            };
                          } else {
                            return regionService.listaActiva();
                          }
                        }]
                    },
                    controller: ['$scope', 'dataTipoQueja', 'toastr', 'motivoQuejaService', 'dataRegionQueja', 'subregionService', 'gestionService',
                      function ($scope, dataTipoQueja, toastr, motivoQuejaService, dataRegionQueja, subregionService, gestionService) {

                        $scope.dataQueja = {
                          detalle: []
                        };
                        $scope.dataTipoQueja = dataTipoQueja.data;
                        $scope.dataMotivoQueja = [];
                        $scope.dataRegionQueja = dataRegionQueja.data;
                        $scope.dataSubregionQueja = [];

                        $scope.setRegionQueja = function (item) {
                          subregionService.listaPorRegion(item.regionId).then(function (res) {
                            if (res.status == 'OK') {
                              $scope.dataSubregionQueja = res.data;
                            } else {
                              toastr.error("Error al obtener datos: " + res.message);
                            }
                          }, function (error) {
                            toastr.error("Error al obtener datos generales: " + error + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                          });
                        }

                        $scope.setTipoQueja = function (item) {
                          $scope.agregarComentarioQueja = false;
                          if (item.listar == 1) {
                            motivoQuejaService.getByTipoQuejaId(item.tipoQuejaId).then(function (res) {
                              if (res.status == 1) {
                                var dataMotivoQueja = res.data;
                                dataMotivoQueja.push({
                                  motivoQuejaId: -1,
                                  motivoQuejaDesc: 'Otro motivo de la queja'
                                });
                                $scope.dataMotivoQueja = dataMotivoQueja;
                                for (var i = 0; i < dataMotivoQueja.length; i++) {
                                  item = dataMotivoQueja[i];
                                  item.marcado = 0;
                                  item.class = "btn-default btn-xs fa fa-square-o";
                                  item.classNo = "btn-default btn-xs fa fa-square-o";
                                }
                                $scope.listarOpcionesQueja = true;
                              } else {
                                toastr.error("Error al obtener datos: " + res.message);
                              }
                            }, function (error) {
                              toastr.error("Error al obtener datos generales: " + error + " \n Cierre sesión y vuelva a iniciar, si el problema persiste comuníquese con el administrador del sistema");
                            });
                          } else {
                            $scope.dataMotivoQueja = [];
                            $scope.listarOpcionesQueja = false;
                            $scope.agregarComentarioQueja = true;
                          }
                        }

                        $scope.marcarQueja = function (item) {
                          if (item.marcado == 1) {
                            item.marcado = 0;
                            item.class = 'btn-default btn-xs fa fa-square-o';
                            if (item.motivoQuejaId == -1) {
                              $scope.agregarComentarioQueja = false;
                            }
                          } else {
                            item.marcado = 1;
                            item.class = 'btn-success btn-xs fa fa-check-square-o';
                            if (item.motivoQuejaId == -1) {
                              $scope.agregarComentarioQueja = true;
                            }
                          }
                        }

                        $scope.agregarComentarioSubmit = function (isValid) {
                          if (isValid) {
                            var hayComentario = false;
                            var detalle = [];
                            if ($scope.dataMotivoQueja.length > 0) {
                              var tmp = $scope.dataMotivoQueja;
                              var hayMarcado = false;
                              for (var i = 0; i < $scope.dataMotivoQueja.length; i++) {
                                var item = $scope.dataMotivoQueja[i];
                                if (item.marcado == 1) {
                                  hayMarcado = true;
                                  if (item.motivoQuejaId == -1) {
                                    hayComentario = true;
                                  } else {
                                    detalle.push({
                                      tcMotivoQueja: item
                                    });
                                  }
                                }
                              }
                              if (!hayMarcado) {
                                toastr.error('Debe marcar al menos un motivo');
                                return;
                              }
                            } else {
                              hayComentario = true;
                            }
                            swal({
                              title: "Confirmación",
                              text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                              function (isConfirm) {
                                if (isConfirm) {
                                  var data = angular.copy($scope.dataQueja);
                                  if (!hayComentario) {
                                    data.quejaDesc = null;
                                  }
                                  data.detalle = detalle;
                                  gestionService.setQueja(data).then(function (res) {
                                    if (res.status == 1) {
                                      toastr.success(res.message);
                                      $scope.cerrarVentana();
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              });
                          }
                        }

                      }]
                  })
                }

                authService.removeLocalData('dataEspecie');
                authService.removeLocalData('tokenGuest');
                //Fin obtener catalogos generales
                authService.removeLocalData('origenCalculoId');
                authService.removeLocalData('esVerificacion');
                authService.removeLocalData('listaActualizacionId');

                $scope.removeStateToGo = function () {
                  authService.removeLocalData('stateToGo');
                  authService.removeLocalData('paramStateToGo');
                  authService.removeLocalData('fincaRfn');
                  authService.removeLocalData('esExento');
                  $scope.globales.stateToGo = "";
                  $scope.globales.paramStateToGo = "";
                }

                $scope.setStateToGo = function (url, paramStateToGo, esExento, esActualizacionExento) {
                  if (esExento == null) {
                    esExento = false;
                  }
                  if (esActualizacionExento == null) {
                    esActualizacionExento = false;
                  }
                  authService.saveLocalData('stateToGo', url);
                  authService.saveLocalData('paramStateToGo', paramStateToGo);
                  authService.saveLocalData('esExento', esExento);
                  authService.saveLocalData('esActualizacionExento', esActualizacionExento);
                  $scope.globales.stateToGo = url;
                  $scope.globales.paramStateToGo = paramStateToGo;
                  $scope.globales.esExento = esExento;
                  $scope.globales.esActualizacionExento = esActualizacionExento;
                }

                $scope.getStateToGo = function () {
                  if ($scope.globales.stateToGo.length <= 0) {
                    $scope.globales.stateToGo = authService.getLocalData('stateToGo');
                    $scope.globales.paramStateToGo = authService.getLocalData('paramStateToGo');
                    $scope.globales.esExento = authService.getLocalData('esExento');
                    if ($scope.globales.stateToGo == undefined || $scope.globales.stateToGo == null) {
                      $scope.globales.stateToGo = "";
                    }
                    if ($scope.globales.paramStateToGo == undefined || $scope.globales.paramStateToGo == null) {
                      $scope.globales.paramStateToGo = "";
                    }
                    if ($scope.globales.esExento == undefined || $scope.globales.esExento == null) {
                      $scope.globales.esExento = false;
                    }
                  }
                  return $scope.globales.stateToGo;
                }

                // inicializar la plantilla AdminLTE
                _initAdminLTETemplate();
                /*Create menu*/
                var dataMenu = authService.getLocalData('menu' + appSettings.sistemaId);
                $scope.dataMenu = dataMenu;
                if (dataMenu.length > 0) {
                  $scope.sistema = dataMenu[0].tcSistema;
                } else {
                  $scope.sistema = {};
                }
                $scope.contarHijos = function (id) {
                  var conteo = 0;
                  for (var i = 0; i < dataMenu.length; i++) {
                    if (id == dataMenu[i].codigoPantalla) {
                      conteo = dataMenu[i].hijos.length;
                      break;
                    }
                  }
                  return conteo;
                }

                $scope.ocultarMenu = function (grupoId) {
                  var lista = []; //guarda los codigos de pantalla
                  switch (grupoId) {
                    case 1:
                      lista.push({ id: 1 });
                      break;
                    case 2:
                      lista.push({ id: 2 });
                      lista.push({ id: 200 });
                      lista.push({ id: 210 });
                      lista.push({ id: 220 });
                      lista.push({ id: 230 });
                      lista.push({ id: 240 });
                      lista.push({ id: 250 });
                      break;
                    case 3:
                      lista.push({ id: 3 });
                      lista.push({ id: 4 });
                      lista.push({ id: 5 });
                      lista.push({ id: 6 });
                      lista.push({ id: 7 });
                      lista.push({ id: 8 });
                      lista.push({ id: 9 });
                      lista.push({ id: 10 });
                      break;
                    case 99:
                      lista.push({ id: 99 });
                      break;
                    case 100:
                      lista.push({ id: 100 });
                      break;
                    case 500:
                      lista.push({ id: 500 });
                      break;
                    case 520:
                      lista.push({ id: 520 });
                      break;
                    case 600:
                      lista.push({ id: 600 });
                      break;
                    default:
                      lista.push({ id: 0 });
                  }
                  var ocultar = true;
                  for (var i = 0; i < dataMenu.length; i++) {
                    for (var j = 0; j < lista.length; j++) {
                      if (lista[j].id == dataMenu[i].codigoPantalla) {
                        ocultar = false;
                        break;
                      }
                    }
                    if (!ocultar) {
                      break;
                    }
                  }
                  return ocultar;
                }

                $scope.getNombreMenu = function (id) {
                  var strNombre = 'Menu ' + id;
                  for (var i = 0; i < dataMenu.length; i++) {
                    if (id == dataMenu[i].codigoPantalla) {
                      strNombre = dataMenu[i].tcMenu.menuDesc;
                      break;
                    }
                  }
                  return strNombre;
                }

                $scope.getURLMenu = function (id) {
                  var strUrl = '';
                  for (var i = 0; i < dataMenu.length; i++) {
                    if (id == dataMenu[i].codigoPantalla) {
                      strUrl = dataMenu[i].urlMenu;
                      break
                    }
                  }
                  return strUrl
                }

                $scope.getIconoMenu = function (id) {
                  var strNombre = 'Menu ' + id;
                  for (var i = 0; i < dataMenu.length; i++) {
                    if (id == dataMenu[i].codigoPantalla) {
                      strNombre = dataMenu[i].tcMenu.icono;
                      break;
                    }
                  }
                  return strNombre;
                }

                $scope.getDataMenu = function (id) {
                  var hijos = [];
                  for (var i = 0; i < dataMenu.length; i++) {
                    if (id == parseInt(dataMenu[i].codigoPantalla)) {
                      hijos = dataMenu[i].hijos;
                      break;
                    }
                  }
                  return hijos;
                }

                // dateOptions
                $scope.dateOptions = {
                  formatYear: 'yy',
                  startingDay: 0,
                  format: 'dd/MM/yyyy',
                  formatDateTime: 'dd/MM/yyyy HH:mm',
                  showMeridian: false
                };

                $scope.showDate = function (value) {
                  return new Date(value);
                }

                // objeto fechas que contiene todas las fechas de los forms
                $scope.openedDates = {};

                // funcion que muestra el datepicker
                $scope.openDate = function ($event, field) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  $scope.openedDates[field] = true;
                };

                $scope.smartTable = appSettings.smartTable;

                // grid
                $scope.gridOptions = {
                  enableRowSelection: true,
                  multiSelect: true,
                  enableSelectAll: true,
                  enableRowHeaderSelection: false,
                  selectionRowHeaderWidth: 35,
                  groupingRowHeaderWidth: 50,
                  paginationPageSizes: appSettings.paginationPageSizes,
                  paginationPageSize: appSettings.paginationPageSize,
                  enableFiltering: true,
                  rowHeight: 50,
                  data: []
                };

                $scope.gridOptionsSelection = {
                  enableRowSelection: true,
                  multiSelect: false,
                  enableSelectAll: false,
                  enableRowHeaderSelection: false,
                  paginationPageSizes: appSettings.paginationPageSizes,
                  paginationPageSize: appSettings.paginationPageSize,
                  enableFiltering: true,
                  rowHeight: 50,
                  data: []
                };

                $scope.goTab = function (event) {
                  event.preventDefault();
                };

                $scope.typeof = function (value, type) {
                  return typeof value === type
                };

                $scope.logout = function (isValid) {
                  authService.logOut();
                  $state.go('login');
                };

                $scope.removeGuestToken = function () {
                  authService.removeLocalData('tokenGuest');
                }

                $scope.addDays = function (date, days) {
                  var result = new Date(date);
                  result.setDate(result.getDate() + days);
                  return result;
                }

                /*************************************************************/
                // NumeroALetras
                // @author   Rodolfo Carmona
                /*************************************************************/
                $scope.Unidades = function (num, isMoney) {
                  if (isMoney == null) {
                    isMoney = true;
                  }
                  switch (num) {
                    case 1:
                      if (isMoney) return "UN";
                      else return "UNO";
                    case 2: return "DOS";
                    case 3: return "TRES";
                    case 4: return "CUATRO";
                    case 5: return "CINCO";
                    case 6: return "SEIS";
                    case 7: return "SIETE";
                    case 8: return "OCHO";
                    case 9: return "NUEVE";
                  }

                  return "";
                }

                $scope.Decenas = function (num, isMoney) {

                  decena = Math.floor(num / 10);
                  unidad = num - (decena * 10);

                  switch (decena) {
                    case 1:
                      switch (unidad) {
                        case 0: return "DIEZ";
                        case 1: return "ONCE";
                        case 2: return "DOCE";
                        case 3: return "TRECE";
                        case 4: return "CATORCE";
                        case 5: return "QUINCE";
                        default: return "DIECI" + $scope.Unidades(unidad, isMoney);
                      }
                    case 2:
                      switch (unidad) {
                        case 0: return "VEINTE";
                        default: return "VEINTI" + $scope.Unidades(unidad, isMoney);
                      }
                    case 3: return $scope.DecenasY("TREINTA", unidad, isMoney);
                    case 4: return $scope.DecenasY("CUARENTA", unidad, isMoney);
                    case 5: return $scope.DecenasY("CINCUENTA", unidad, isMoney);
                    case 6: return $scope.DecenasY("SESENTA", unidad, isMoney);
                    case 7: return $scope.DecenasY("SETENTA", unidad, isMoney);
                    case 8: return $scope.DecenasY("OCHENTA", unidad, isMoney);
                    case 9: return $scope.DecenasY("NOVENTA", unidad, isMoney);
                    case 0: return $scope.Unidades(unidad, isMoney);
                  }
                }//Unidades()

                $scope.DecenasY = function (strSin, numUnidades, isMoney) {
                  if (numUnidades > 0)
                    return strSin + " Y " + $scope.Unidades(numUnidades, isMoney)

                  return strSin;
                }//DecenasY()

                $scope.Centenas = function (num, isMoney) {

                  centenas = Math.floor(num / 100);
                  decenas = num - (centenas * 100);

                  switch (centenas) {
                    case 1:
                      if (decenas > 0)
                        return "CIENTO " + $scope.Decenas(decenas, isMoney);
                      return "CIEN";
                    case 2: return "DOSCIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 3: return "TRESCIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 4: return "CUATROCIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 5: return "QUINIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 6: return "SEISCIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 7: return "SETECIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 8: return "OCHOCIENTOS " + $scope.Decenas(decenas, isMoney);
                    case 9: return "NOVECIENTOS " + $scope.Decenas(decenas, isMoney);
                  }

                  return $scope.Decenas(decenas, isMoney);
                }//Centenas()

                $scope.Seccion = function (num, divisor, strSingular, strPlural, isMoney) {
                  if (isMoney == null) {
                    isMoney = true;
                  }
                  cientos = Math.floor(num / divisor)
                  resto = num - (cientos * divisor)

                  letras = "";

                  if (cientos > 0)
                    if (cientos > 1)
                      letras = $scope.Centenas(cientos, isMoney) + " " + strPlural;
                    else
                      letras = strSingular;

                  if (resto > 0)
                    letras += "";

                  return letras;
                }//Seccion()

                $scope.Miles = function (num, isMoney) {
                  divisor = 1000;
                  cientos = Math.floor(num / divisor)
                  resto = num - (cientos * divisor)

                  strMiles = $scope.Seccion(num, divisor, "UN MIL", "MIL", isMoney);
                  strCentenas = $scope.Centenas(resto, isMoney);

                  if (strMiles == "")
                    return strCentenas;

                  return strMiles + " " + strCentenas;

                  //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
                }//Miles()

                $scope.Millones = function (num, isMoney) {
                  divisor = 1000000;
                  cientos = Math.floor(num / divisor)
                  resto = num - (cientos * divisor)

                  strMillones = $scope.Seccion(num, divisor, "UN MILLON", "MILLONES", isMoney);
                  strMiles = $scope.Miles(resto, isMoney);

                  if (strMillones == "")
                    return strMiles;

                  return strMillones + " " + strMiles;

                  //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
                }//Millones()

                $scope.numeroALetras = function (num, isMoney) {
                  if (isMoney == null) {
                    isMoney = true;
                  }
                  if (num == 0) {
                    return 'CERO';
                  }
                  var data = {
                    numero: num,
                    enteros: Math.floor(num),
                    centavos: 0,
                    letrasCentavos: "",
                    letrasMonedaPlural: "QUETZALES",
                    letrasMonedaSingular: "QUETZAL"
                  };
                  return $scope.Millones(data.enteros, isMoney);
                }//NumeroALetras()

                $scope.getMonthName = function (month) {
                  switch (month) {
                    case 1: return "enero";
                    case 2: return "febrero";
                    case 3: return "marzo";
                    case 4: return "abril";
                    case 5: return "mayo";
                    case 6: return "junio";
                    case 7: return "julio";
                    case 8: return "agosto";
                    case 9: return "septiembre";
                    case 10: return "octubre";
                    case 11: return "noviembre";
                    case 12: return "diciembre";
                    default: return "undefined";
                  }
                }

                $scope.quitarDuplicados = function (correlativos) {
                  var sinDuplicar = [];
                  for (var i = 0; i < correlativos.length; i++) {
                    correlativos[i] = parseInt(correlativos[i]);
                  }
                  for (var i = 0; i < correlativos.length; i++) {
                    var encontrado = false;
                    for (var j = 0; j < sinDuplicar.length; j++) {
                      if (correlativos[i] == sinDuplicar[j]) {
                        encontrado = true;
                        break;
                      }
                    }
                    if (!encontrado) {
                      sinDuplicar.push(parseInt(correlativos[i]));
                    }
                  }
                  return sinDuplicar;
                }

                $scope.ordenarBurbuja = function (correlativos) {
                  for (var i = 0; i < correlativos.length; i++) {
                    for (var j = 0; j < correlativos.length - i; j++) {
                      if (correlativos[j] > correlativos[j + 1]) {
                        var aux = parseInt(correlativos[j]);
                        correlativos[j] = parseInt(correlativos[j + 1]);
                        correlativos[j + 1] = aux;
                      }
                    }
                  }
                  return correlativos;
                }

                $scope.submitBusquedaGeneral = function (isValid) {
                  if (isValid) {
                    if ($scope.loginData.perfilId == 18 || $scope.loginData.perfilId == 1
                      || $scope.loginData.perfilId == 9 || $scope.loginData.perfilId == 16
                      || $scope.loginData.perfilId == 20 || $scope.loginData.perfilId == 8
                      || $scope.loginData.perfilId == 36) {
                      tareaService.buscarExpediente($scope.filter.trim()).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataHistorial = res.data;
                          if (res.data.length) {
                            $scope.row = $scope.dataHistorial[0].ttGestion;
                          }
                          $scope.datasetOverride = [{
                            fill: true,
                            backgroundColor: [
                              "#339E66",
                              "#36A2EB",
                              "#FFCE56",
                              "#ED402A",
                              "#1E90FF",
                              '#FFA500'
                            ]
                          }];
                          for (var i = 0; i < $scope.dataHistorial.length; i++) {
                            var row = $scope.dataHistorial[i];
                            var tiempos = row.tiempos;
                            var cantidad = 0;
                            for (var j = 0; j < tiempos.length; j++) {
                              if (tiempos[j].identificador == 5) {
                                cantidad = tiempos[j].cantidad;
                                break;
                              }
                            }
                            var item = [];
                            var labels = [];
                            row.bad = false;
                            for (var j = 0; j < tiempos.length; j++) {
                              item.push(tiempos[j].cantidad);
                              labels.push(tiempos[j].descripcion);
                              if (tiempos[j].identificador == 4 && tiempos[j].cantidad > 0) {
                                row.bad = true;
                              }
                            }
                            row.chart = [
                              item
                            ];
                            row.series = ['Total'];
                            row.labels = labels;
                          }
                          ngDialog.open({
                            template: 'app/common/historial.gestion.tpl.html',
                            className: 'ngdialog-theme-flat',
                            appendClassName: 'ngdialog-flat',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueEdit',
                            name: 'dialogEditar',
                            resolve: {

                            },
                            controller: ['$scope', '$state', 'toastr',
                              function ($scope, $state, toastr) {

                              }]
                          })
                        } else {
                          swal(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    if ($scope.loginData.perfilId == 18 || $scope.loginData.perfilId == 1) {
                      toastr.error('Debe indicar el número de expediente para obtener información');
                    }
                  }
                }
                var ignore = ['de', 'la', 'del', 'el', 'los', 'las', 'que', 'a', 'en', 's.a.', 'sa', 's.a'];
                $scope.capitalizeText = function (text) {
                  var capitalizedText = '';
                  var words = text.toLowerCase().split(' ');
                  for (var i = 0; i < words.length; i++) {
                    var found = false;
                    for (var j = 0; j < ignore.length; j++) {
                      if (ignore[j] == words[i]) {
                        found = true;
                        break;
                      }
                    }
                    if (found) {
                      capitalizedText += words[i] + ' ';
                    } else {
                      capitalizedText += words[i].charAt(0).toUpperCase() + words[i].slice(1) + ' ';
                    }
                  }
                  return capitalizedText.trim();
                }

              }]
          })
          .state("index.home", {

            url: "",

            views: {
              '': {
                templateUrl: 'app/home/home.tpl.html',
                resolve: {

                },
                controller: ['$scope', 'toastr', '$state', 'authService',
                  function ($scope, toastr, $state, authService) {
                  }
                ]
              },
              'hint@index': {
                template: ''
              }
            }

          })

      }
    ]
  )

  .constant('appSettings', appSettings);
