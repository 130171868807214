angular.module('app.holiday', [
  'ui.router',
  'toastr',
  'app.holiday.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.holiday', {
          abstract: true,
          url: 'holiday',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Asueto y/o festividad';
            }]
        })
        .state('index.holiday.list', {
          url: '',
          templateUrl: 'app/holiday/holiday.list.tpl.html',
          resolve: {
            dataHoliday: ['holidayService',
              function ( holidayService ){
                let now = new Date();
                return holidayService.listaByYear(now.getFullYear());
              }]
          },
          controller: ['$scope', '$state', 'dataHoliday', 'holidayService', 'toastr',
            function (  $scope,   $state,   dataHoliday, holidayService, toastr) {
              let now = new Date();
              $scope.data = {
                year: now.getFullYear()
              }
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'holidayDesc', name: 'holidayDesc', displayName: 'Nombre' },
                { field: 'date', name: 'date', displayName: 'Fecha', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'', width: '10%' },
                { field:'year', name: 'year', displayName: 'Año' },
                { name: 'enable', displayName: "Estado", field: 'enable', enableFiltering: false, cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.enable)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataHoliday.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ holidayId: row.holidayId });
              }
              $scope.etiquetaEstado = function ( enable ) {
                if (enable == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  holidayService.listaByYear( $scope.data.year ).then( function ( res ) {
                    if ( res.status == 1 ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

            }]
        })
        .state('index.holiday.add', {
          url: '/add',
          templateUrl: 'app/holiday/holiday.add.tpl.html',
          resolve: {
            dataEstado: ['holidayService',
              function ( holidayService ){
                return holidayService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'holidayService', 'dataEstado',
            function (  $scope,   $state,   toastr,   holidayService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.enable = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  holidayService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1 ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.holiday.edit', {
          url: '/:holidayId/edit',
          templateUrl: 'app/holiday/holiday.add.tpl.html',
          resolve: {
            dataHoliday: ['holidayService', '$stateParams',
              function ( holidayService, $stateParams ){
                return holidayService.get( $stateParams.holidayId );
              }],
            dataEstado: ['holidayService',
              function ( holidayService ){
                return holidayService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'holidayService', 'dataHoliday', 'dataEstado',
            function (  $scope,   $state,   toastr,   holidayService,   dataHoliday, dataEstado) {
              $scope.data = dataHoliday.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  holidayService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1 ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
