angular.module('app.clasediametrica', [
  'ui.router',
  'toastr',
  'app.clasediametrica.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.clasediametrica', {
          abstract: true,
          url: 'clasediametrica',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Clase diamétrica';
            }]
        })
        .state('index.clasediametrica.list', {
          url: '',
          templateUrl: 'app/clasediametrica/clasediametrica.list.tpl.html',
          resolve: {
            dataClaseDesarrollo: ['clasediametricaService',
              function ( clasediametricaService ){
                return clasediametricaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataClaseDesarrollo',
            function (  $scope,   $state,   dataClaseDesarrollo) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'claseDiametricaDesc', name: 'claseDiametricaDesc', displayName: 'Clase diamétrica' },
                { field:'minimo', name: 'minimo', displayName: 'Mínimo' },
                { field:'marcaClase', name: 'marcaClase', displayName: 'Marca de clase' },
                { field:'maximo', name: 'maximo', displayName: 'Máximo' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataClaseDesarrollo.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ claseDiametricaId: row.claseDiametricaId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.clasediametrica.add', {
          url: '/add',
          templateUrl: 'app/clasediametrica/clasediametrica.add.tpl.html',
          resolve: {
            dataEstado: ['clasediametricaService',
              function ( clasediametricaService ){
                return clasediametricaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'clasediametricaService', 'dataEstado',
            function (  $scope,   $state,   toastr,   clasediametricaService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;

              $scope.setEtiqueta = function () {
                if ($scope.data.minimo != null) {
                  $scope.data.maximo = parseFloat($scope.data.minimo) + 4.99;
                  $scope.data.maximo = parseFloat($scope.data.maximo).toFixed(2);
                  $scope.data.claseDiametricaDesc = $scope.data.minimo + ' - ' + $scope.data.maximo;
                  $scope.data.marcaClase = parseFloat($scope.data.minimo) + 2.49;
                  $scope.data.marcaClase = parseFloat($scope.data.marcaClase).toFixed(2);
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  clasediametricaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.clasediametrica.edit', {
          url: '/:claseDiametricaId/edit',
          templateUrl: 'app/clasediametrica/clasediametrica.add.tpl.html',
          resolve: {
            dataClaseDesarrollo: ['clasediametricaService', '$stateParams',
              function ( clasediametricaService, $stateParams ){
                return clasediametricaService.get( $stateParams.claseDiametricaId );
              }],
            dataEstado: ['clasediametricaService',
              function ( clasediametricaService ){
                return clasediametricaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'clasediametricaService', 'dataClaseDesarrollo', 'dataEstado',
            function (  $scope,   $state,   toastr,   clasediametricaService,   dataClaseDesarrollo, dataEstado) {
              $scope.data = dataClaseDesarrollo.data[0];
              $scope.dataEstado = dataEstado.data;

              $scope.setEtiqueta = function () {
                if ($scope.data.minimo != null) {
                  $scope.data.maximo = parseFloat($scope.data.minimo) + 4.99;
                  $scope.data.maximo = parseFloat($scope.data.maximo).toFixed(2);
                  $scope.data.claseDiametricaDesc = $scope.data.minimo + ' - ' + $scope.data.maximo;
                  $scope.data.marcaClase = parseFloat($scope.data.minimo) + 2.49;
                  $scope.data.marcaClase = parseFloat($scope.data.marcaClase).toFixed(2);
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  clasediametricaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
