angular.module('app.institucionDependencia', [
  'ui.router',
  'toastr',
  'app.institucionDependencia.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.institucionDependencia', {
          abstract: true,
          url: 'institucionDependencia',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope', 'municipioService',
            function (  $scope, municipioService) {
              $scope.module = 'Dependencia por institución';
              $scope.setDepartamento = function ( item ) {
                $scope.dataMunicipio = [];
                if (item != undefined && item != null) {
                  municipioService.listaPorDepartamento( item.departamentoId ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.dataMunicipio = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.institucionDependencia.list', {
          url: '',
          templateUrl: 'app/institucionDependencia/institucionDependencia.list.tpl.html',
          resolve: {
            dataInstitucionDependencia: ['institucionDependenciaService',
              function ( institucionDependenciaService ){
                return institucionDependenciaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataInstitucionDependencia',
            function (  $scope,   $state,   dataInstitucionDependencia) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'institucionDependenciaDesc', name: 'institucionDependenciaDesc', displayName: 'Nombre' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataInstitucionDependencia.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ institucionDependenciaId: row.institucionDependenciaId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.institucionDependencia.add', {
          url: '/add',
          templateUrl: 'app/institucionDependencia/institucionDependencia.add.tpl.html',
          resolve: {
            dataEstado: ['institucionDependenciaService',
              function ( institucionDependenciaService ){
                return institucionDependenciaService.listStatus();
              }],
            dataInstitucion: ['institucionService',
            function ( institucionService ){
              return institucionService.listaActiva();
            }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'dataInstitucion', 'dataEstado', 'institucionDependenciaService', 'dataDepartamento',
            function (  $scope,   $state,   toastr,   dataInstitucion, dataEstado, institucionDependenciaService, dataDepartamento) {
              $scope.data = {
                tcMunicipio: {
                  municipioId: null
                }
              };
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.dataInstitucion = dataInstitucion.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  institucionDependenciaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1 ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.setInstitucion = function ( item ) {
                if (item.institucionId == 1) {
                  $scope.tituloDependencia = "";
                }
              }


            }]
        })
        .state('index.institucionDependencia.edit', {
          url: '/:institucionDependenciaId/edit',
          templateUrl: 'app/institucionDependencia/institucionDependencia.add.tpl.html',
          resolve: {
            dataInstitucionDependencia: ['institucionDependenciaService', '$stateParams',
              function ( institucionDependenciaService, $stateParams ){
                return institucionDependenciaService.get( $stateParams.institucionDependenciaId );
              }],
            dataEstado: ['institucionDependenciaService',
              function ( institucionDependenciaService ){
                return institucionDependenciaService.listStatus();
              }],
            dataInstitucion: ['institucionService',
            function ( institucionService ){
              return institucionService.listaActiva();
            }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'institucionDependenciaService', 'dataInstitucionDependencia', 'dataEstado', 'dataInstitucion', 'dataDepartamento', 
            function (  $scope,   $state,   toastr,   institucionDependenciaService,   dataInstitucionDependencia, dataEstado, dataInstitucion, dataDepartamento) {
              $scope.data = dataInstitucionDependencia.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataInstitucion = dataInstitucion.data;
              $scope.dataDepartamento = dataDepartamento.data;

              if ($scope.data.tcMunicipio != null) {
                var tcMunicipio = angular.copy($scope.data.tcMunicipio);
                $scope.setDepartamento($scope.data.tcMunicipio.tcDepartamento);
                $scope.departamentoId = $scope.data.tcMunicipio.tcDepartamento.departamentoId;
                $scope.data.tcMunicipio = tcMunicipio;
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  institucionDependenciaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1 ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
