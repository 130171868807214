angular.module('app.usuariosubregion', [
  'ui.router',
  'toastr',
  'app.usuariosubregion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.usuariosubregion', {
          abstract: true,
          url: 'usuariosubregion',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(2);
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataPersona: ['personaService',
              function ( personaService ){
                return personaService.listaActivaSinFoto();
              }],
            dataPerfil: ['perfilService',
              function ( perfilService ){
                return perfilService.listaActiva();
              }]
          },
          controller: ['$scope', 'departamentoService', 'municipioService', 'subregionService', 'dataRegion', 'dataPersona', 'dataPerfil',
            function (  $scope, departamentoService, municipioService, subregionService, dataRegion, dataPersona, dataPerfil ) {
              $scope.module = 'Usuario por subregión';
              $scope.variable = {
                data: null
              };
              $scope.dataRegion = dataRegion.data;
              $scope.dataPersona = dataPersona.data;
              $scope.dataPerfil = dataPerfil.data;

              $scope.cargarListaDepartamento = function (item) {
                $scope.variable.departamentoId = null;
                $scope.data.municipioId = null;
                departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataDepartamento = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaMunicipio = function (item) {
                $scope.data.municipioId = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaSubregion = function (item) {
                $scope.data.subregionId = null;
                subregionService.listaPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataSubRegion = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

            }]
        })
        .state('index.usuariosubregion.list', {
          url: '',
          templateUrl: 'app/usuariosubregion/usuariosubregion.list.tpl.html',
          resolve: {

          },
          controller: ['$scope', '$state', 'subregionService', 'usuariosubregionService',
            function (  $scope,   $state, subregionService, usuariosubregionService ) {

              $scope.setRegion = function (item) {
                subregionService.listaPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataSubregion = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
                usuariosubregionService.getPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.gridOptions.data = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcSubregion.tcRegion.regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                { field:'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '10%' },
                { field:'tcSubregion.tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '15%' },
                { field:'tcPerfil.perfilDesc', name: 'perfilDesc', displayName: 'Perfil', width: '10%' },
                { field:'tcPersona.personaDesc', name: 'personaDesc', displayName: 'Usuario' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '10%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = [];

              $scope.editRow = function ( row ) {
                $scope.variable.data = row;
                $state.go('^.edit', { usuarioSubregionId: row.usuarioSubregionId });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.usuariosubregion.add', {
          url: '/add',
          templateUrl: 'app/usuariosubregion/usuariosubregion.add.tpl.html',
          resolve: {
            dataEstado: ['usuariosubregionService',
              function ( usuariosubregionService ){
                return usuariosubregionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuariosubregionService', 'dataEstado', 'subregionService',
            function (  $scope,   $state,   toastr,   usuariosubregionService, dataEstado, subregionService) {
              $scope.data = {
                tcSubregion: {
                  subregionId: null
                },
                tcPersona: {
                  personaId: null
                }
              };

              $scope.setRegion = function (item) {
                subregionService.listaPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataSubregion = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setSubregion = function ( item ) {
                $scope.data.tcSubregion = item;
              }

              $scope.setPersona = function ( item ) {
                $scope.data.tcPersona = item;
              }

              $scope.setPerfil = function ( item ) {
                $scope.data.tcPerfil = item;
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuariosubregionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.usuariosubregion.edit', {
          url: '/:usuarioSubregionId/edit',
          templateUrl: 'app/usuariosubregion/usuariosubregion.add.tpl.html',
          resolve: {
            dataEstado: ['usuariosubregionService',
              function ( usuariosubregionService ){
                return usuariosubregionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuariosubregionService', 'subregionService', 'dataEstado',
            function ( $scope, $state, toastr, usuariosubregionService, subregionService, dataEstado) {
              $scope.data = $scope.variable.data;
              $scope.dataEstado = dataEstado.data;
              if ($scope.data.usuarioSubregionId == undefined || $scope.data.usuarioSubregionId == null) {
                $state.go( '^.list' );
                return;
              }

              $scope.setSubregion = function ( item ) {
                $scope.data.tcSubregion = item;
              }

              $scope.setPersona = function ( item ) {
                $scope.data.tcPersona = item;
              }

              $scope.setPerfil = function ( item ) {
                $scope.data.tcPerfil = item;
              }

              $scope.setRegion = function (item) {
                subregionService.listaPorRegion( item.regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataSubregion = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setRegion($scope.data.tcSubregion.tcRegion);

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuariosubregionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
