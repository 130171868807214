angular.module('app.persona.service', [

])

.factory('personaService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'persona/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/' + data.personaId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( personaId ) {
      return $http.get( appSettings.urlServiceBase + 'persona/' + personaId).then(function ( res ) {
         return res.data;
       });
    },
    registrar: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'persona/registrar', data )
       .then(function ( res ) {
         return res.data;
       });
    },
    getUsuario: function ( usuarioId ) {
      return $http.get( appSettings.urlServiceBase + 'persona/usuario/' + usuarioId ).then(function ( res ) {
         return res.data;
       });
    },
    listaRegente: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/regente/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    busquedaFiltro: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'persona/busqueda', data )
       .then(function ( res ) {
         return res.data;
       });
    },
    reenviarValidacion: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'persona/reenviar/validacion', data )
       .then(function ( res ) {
         return res.data;
       });
    },
    listaActivaSinFoto: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/lista/activa/nofoto' ).then(function ( res ) {
         return res.data;
       });
    },
    buscarUsuario: function ( usuario ) {
      return $http.get( appSettings.urlServiceBase + 'persona/buscar/usuario/' + usuario ).then(function ( res ) {
         return res.data;
       });
    },
    editPersona: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/actualizar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    quitarPerfil: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/quitar/perfil', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarPerfil: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/agregar/perfil', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    quitarSubregion: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/quitar/subregion', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarSubregion: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/agregar/subregion', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    reiniciarContrasenia: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/usuario/reinicio/clave', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    buscarCui: function ( cui ) {
      return $http.get( appSettings.urlServiceBase + 'persona/buscar/cui/' + cui ).then(function ( res ) {
         return res.data;
       });
    },
    crearUsuario: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/crear/usuario', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    solicitarCambioPerfil: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'persona/solicitar/cambio/perfil', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    datosPersonales: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/token/user' ).then(function ( res ) {
         return res.data;
       });
    },
    getListaSolicitudPerfil: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/lista/solicitud/perfil' ).then(function ( res ) {
         return res.data;
       });
    },
    getDocumentoSolicitudPerfil : function( solicitudPerfilId ) {
      $http.get(appSettings.urlServiceBase + 'persona/solicitud/perfil/' + solicitudPerfilId, {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    autorizarCambioPerfil: function ( solicitudPerfilId ) {
      return $http.put( appSettings.urlServiceBase + 'persona/solicitud/perfil/' + solicitudPerfilId + '/autorizar' ).
       then(function ( res ) {
         return res.data;
       });
    },
    denegarCambioPerfil: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'persona/solicitud/perfil/denegar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getDependenciasByPersona: function () {
      return $http.get( appSettings.urlServiceBase + 'persona/dependencia/institucion' ).then(function ( res ) {
         return res.data;
       });
    },
    getByMunicipalidad: function ( municipalidadId ) {
      return $http.get( appSettings.urlServiceBase + 'persona/municipalidad/' + municipalidadId ).then(function ( res ) {
         return res.data;
       });
    },
    setDefaultMunicipalidad: function ( personaMunicipalidadId ) {
      return $http.put( appSettings.urlServiceBase + 'persona/como/principal/' + personaMunicipalidadId ).
       then(function ( res ) {
         return res.data;
       });
    },
    quitarPersonaDeMunicipalidad: function ( personaMunicipalidadId ) {
      return $http.put( appSettings.urlServiceBase + 'persona/' + personaMunicipalidadId + '/quitar/municipio').
       then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
