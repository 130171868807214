angular.module('app.comercializador', [
  'ui.router',
  'toastr',
  'app.comercializador.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.comercializador', {
          abstract: true,
          url: 'comercializador',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Vendedor / Comprador';
            }]
        })
        .state('index.comercializador.list', {
          url: '',
          templateUrl: 'app/comercializador/comercializador.list.tpl.html',
          resolve: {
            dataComercializador: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataComercializador',
            function (  $scope,   $state,   dataComercializador) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'comercializadorDesc', name: 'comercializadorDesc', displayName: 'Comercializador' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataComercializador.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ comercializadorId: row.comercializadorId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.comercializador.add', {
          url: '/add',
          templateUrl: 'app/comercializador/comercializador.add.tpl.html',
          resolve: {
            dataEstado: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'comercializadorService', 'dataEstado',
            function (  $scope,   $state,   toastr,   comercializadorService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  comercializadorService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.comercializador.edit', {
          url: '/:comercializadorId/edit',
          templateUrl: 'app/comercializador/comercializador.add.tpl.html',
          resolve: {
            dataComercializador: ['comercializadorService', '$stateParams',
              function ( comercializadorService, $stateParams ){
                return comercializadorService.get( $stateParams.comercializadorId );
              }],
            dataEstado: ['comercializadorService',
              function ( comercializadorService ){
                return comercializadorService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'comercializadorService', 'dataComercializador', 'dataEstado',
            function (  $scope,   $state,   toastr,   comercializadorService,   dataComercializador, dataEstado) {
              $scope.data = dataComercializador.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  comercializadorService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
