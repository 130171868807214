angular.module('app.especie', [
  'ui.router',
  'toastr',
  'app.especie.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.especie', {
          abstract: true,
          url: 'especie',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Especie';
            }]
        })
        .state('index.especie.list', {
          url: '',
          templateUrl: 'app/especie/especie.list.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1);
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataEspecie',
            function (  $scope,   $state,   dataEspecie) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'especieDesc', name: 'especieDesc', displayName: 'Especie', width: '15%' },
                { field:'nombreCientifico', name: 'nombreCientifico', displayName: 'Nombre científico' },
                { field:'codigo', name: 'codigo', displayName: 'Código', width: '10%' },
                { field:'sinonimo', name: 'sinonimo', displayName: 'Sinónimos' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '8%', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '8%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataEspecie.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ especieId: row.especieId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.especie.add', {
          url: '/add',
          templateUrl: 'app/especie/especie.add.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1);
              }],
            dataEstado: ['especieService',
              function ( especieService ){
                return especieService.listStatus();
              }],
            dataTipoEspecie: ['tipoespecieService',
              function ( tipoespecieService ){
                return tipoespecieService.listaActiva();
              }],
            dataTipoMadera: ['tipomaderaService',
              function ( tipomaderaService ){
                return tipomaderaService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'especieService', 'dataEstado', 'dataTipoEspecie', 'dataTipoMadera',
            function (  $scope,   $state,   toastr,   especieService, dataEstado, dataTipoEspecie, dataTipoMadera) {
              $scope.data = {
                tcTipoEspecie: {
                  tipoEspecieId: null
                },
                formula: '[{"variable":"DAP"},{"variable":"ALTURA"}]'
              };
              $scope.agregar = true;
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoEspecie = dataTipoEspecie.data;
              $scope.dataTipoMadera = dataTipoMadera.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  especieService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      if ($scope.loginData.perfilId == 16
                      || $scope.loginData.perfilId == 20
                      || $scope.loginData.perfilId == 21) {
                        $scope.agregar = true;
                        $state.go( '^.lista' );
                      } else {
                        $state.go( '^.list' );
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.especie.edit', {
          url: '/:especieId/edit',
          templateUrl: 'app/especie/especie.add.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1);
              }],
            dataEspecie: ['especieService', '$stateParams',
              function ( especieService, $stateParams ){
                return especieService.get( $stateParams.especieId );
              }],
            dataEstado: ['especieService',
              function ( especieService ){
                return especieService.listStatus();
              }],
            dataTipoEspecie: ['tipoespecieService',
              function ( tipoespecieService ){
                return tipoespecieService.listaActiva();
              }],
            dataTipoMadera: ['tipomaderaService',
              function ( tipomaderaService ){
                return tipomaderaService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'especieService', 'dataEspecie', 'dataEstado', 'dataTipoEspecie', 'dataTipoMadera',
            function (  $scope,   $state,   toastr,   especieService,   dataEspecie, dataEstado, dataTipoEspecie, dataTipoMadera) {
              $scope.data = dataEspecie.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoEspecie = dataTipoEspecie.data;
              $scope.dataTipoMadera = dataTipoMadera.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  especieService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.especie.lista', {
          url: '/lista',
          templateUrl: 'app/especie/especie.list.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(101);
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataEspecie',
            function (  $scope,   $state,   dataEspecie) {

              if ($scope.loginData.perfilId == 16
              || $scope.loginData.perfilId == 20
              || $scope.loginData.perfilId == 21
              || $scope.loginData.perfilId == 36) {
                $scope.agregar = true;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'especieDesc', name: 'especieDesc', displayName: 'Nombre común', width: '20%' },
                { field:'tcTipoEspecie.tipoEspecieDesc', name: 'tipoEspecieDesc', displayName: 'Tipo', width: '10%' },
                { field:'nombreCientifico', name: 'nombreCientifico', displayName: 'Nombre científico', width: '20%' },
                { field:'ecuacion', name: 'ecuacion', displayName: 'Fórmula', width: '20%' },
                { field:'sinonimo', name: 'sinonimo', displayName: 'Sinónimo' }
              ];
              $scope.gridOptions.data = dataEspecie.data;
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
    }
  ]
);
