angular.module('app.tiporepresentacion.service', [

])

.factory('tiporepresentacionService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'tiporepresentacion/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'tiporepresentacion/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'tiporepresentacion/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'tiporepresentacion/' + data.tipoRepresentacionId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( tipoRepresentacionId ) {
      return $http.get( appSettings.urlServiceBase + 'tiporepresentacion/' + tipoRepresentacionId).then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
