angular.module('app.personafinca', [
  'ui.router',
  'toastr',
  'app.finca.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.personafinca', {
          abstract: true,
          url: 'personafinca',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Mis fincas registradas';
            }]
        })
        .state('index.personafinca.list', {
          url: '',
          templateUrl: 'app/personafinca/personafinca.list.tpl.html',
          resolve: {
            dataPersonaFinca: ['fincaService', 'authService',
              function ( fincaService, authService ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                return fincaService.getListaFincaPersona(loginData.personaId);
              }],
            dataDepartamento: ['departamentoService', 'authService',
              function ( departamentoService, authService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'dataPersonaFinca', 'ngDialog', 'municipioService', '$base64', 'dataDepartamento',
            function (  $scope,   $state,   dataPersonaFinca, ngDialog, municipioService, $base64, dataDepartamento) {

              $scope.dataDepartamentoFinca = dataDepartamento.data;

              $scope.mostrarBusqueda = function () {
                if ($scope.loginData.perfilId == 2) {
                  return true;
                }
                return false;
              }

              $scope.gridOptionsFinca = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptionsFinca.columnDefs = [
                { field:'tcFinca.fincaDesc', name: 'fincaDesc', displayName: 'Finca' },
                { field:'tcFinca.tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                { field:'tcFinca.tcMunicipio.tcDepartamento.departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento' },
                { field:'tcFinca.direccion', name: 'direccion', displayName: 'Aldea/caserío/cantón' },
                { field:'tcFinca.gtmX', name: 'gtmX', displayName: 'GTM X' },
                { field:'tcFinca.gtmY', name: 'gtmY', displayName: 'GTM Y' },
                { field:'tcFinca.tcTipoPropiedad.tipoPropiedadDesc', name: 'tipoPropiedadDesc', displayName: 'Documento' },
                { field:'tcFinca.numeroDocumento', name: 'numeroDocumento', displayName: 'No.' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +' <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </button> '
                    + '</span></div>'
                }
              ];
              $scope.gridOptionsFinca.data = dataPersonaFinca.data;

              $scope.selectRow = function ( row ) {
                $scope.globales.fincaId = row.tcFinca.fincaId;
                $state.go($scope.globales.stateToGo, {gestionId : $scope.globales.paramStateToGo});
              }

              $scope.agregarRegistroFinca = function () {
                $scope.setStateToGo('index.personafinca.list', "");
                $state.go('index.finca.add');
              }

              $scope.editRow = function ( row ) {
                $scope.setStateToGo('index.personafinca.list', "");
                $state.go('index.finca.edit', { fincaId: $base64.encode(row.tcFinca.fincaId) });
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }

              $scope.buscarFinca = function () {
                ngDialog.open({
                  template: 'app/finca/finca.list.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {

                  },
                  controller: ['$scope', '$state', 'toastr', 'fincaService',
                    function ($scope, $state, toastr, fincaService) {
                      $scope.gridOptionsFinca = angular.copy($scope.gridOptionsSelection);
                      $scope.gridOptionsFinca.columnDefs = [
                        { field: 'fincaDesc', name: 'fincaDesc', displayName: 'Finca' },
                        { field: 'direccion', name: 'direccion', displayName: 'Aldea/caserío/cantón' },
                        { field: 'gtmX', name: 'gtmX', displayName: 'GTM X', width: '8%' },
                        { field: 'gtmY', name: 'gtmY', displayName: 'GTM Y', width: '8%' },
                        { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                        { field:'tcTipoPropiedad.tipoPropiedadDesc', name: 'tipoPropiedadDesc', displayName: 'Documento' },
                        { field:'numeroDocumento', name: 'numeroDocumento', displayName: 'No.', width: '5%' },
                        { field:'folio', name: 'folio', displayName: 'Folio', width: '5%' },
                        { field:'libro', name: 'libro', displayName: 'Libro', width: '5%' },
                        {
                          name: 'Opciones', enableFiltering: false, width: '8%',
                          cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                            + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.selectRowFinca(row.entity)" title="Seleccionar"> <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </button></span></div>'
                        }
                      ];
                      $scope.gridOptionsFinca.data = $scope.dataFinca;

                      $scope.direccion = {
                        departamentoId: null,
                        municipioId: null
                      }

                      $scope.cargarListaMunicipioFinca = function (item) {
                        $scope.direccion.municipioId = null;
                        municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataMunicipioFinca = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }

                      $scope.setMunicipioFinca = function ( item ) {
                        if (item) {
                          fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.gridOptionsFinca.data = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }

                    }]
                })
              }

            }]
        })
    }
  ]
);
