angular.module('app.manual.service', [

])

.factory('manualService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'manual/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'manual/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    listaTipo: function () {
      var data = [];
      data.push({tipoArchivoId : 1, tipoArchivoDesc : 'Documento de texto'});
      data.push({tipoArchivoId : 2, tipoArchivoDesc : 'Video'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'manual/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'manual/' + data.manualId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( manualId ) {
      return $http.get( appSettings.urlServiceBase + 'manual/' + manualId).then(function ( res ) {
         return res.data;
       });
    },
    getFile : function( manualId ) {
      $http.get(appSettings.urlServiceBase + 'manual/' + manualId + '/archivo', {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    }
  }

}]);
