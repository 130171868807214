angular.module('app.tratamientosilvicultural', [
  'ui.router',
  'toastr',
  'app.tratamientosilvicultural.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tratamientosilvicultural', {
          abstract: true,
          url: 'tratamientosilvicultural',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Clase de desarrollo';
            }]
        })
        .state('index.tratamientosilvicultural.list', {
          url: '',
          templateUrl: 'app/tratamientosilvicultural/tratamientosilvicultural.list.tpl.html',
          resolve: {
            dataTratamientoSilvicultural: ['tratamientosilviculturalService',
              function ( tratamientosilviculturalService ){
                return tratamientosilviculturalService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataTratamientoSilvicultural',
            function (  $scope,   $state,   dataTratamientoSilvicultural) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tratamientoSilviculturalDesc', name: 'tratamientoSilviculturalDesc', displayName: 'Clase de desarrollo' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataTratamientoSilvicultural.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ tratamientoSilviculturalId: row.tratamientoSilviculturalId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.tratamientosilvicultural.add', {
          url: '/add',
          templateUrl: 'app/tratamientosilvicultural/tratamientosilvicultural.add.tpl.html',
          resolve: {
            dataEstado: ['tratamientosilviculturalService',
              function ( tratamientosilviculturalService ){
                return tratamientosilviculturalService.listStatus();
              }],
            dataTipoGestion: ['tipogestionService',
              function ( tipogestionService ){
                return tipogestionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tratamientosilviculturalService', 'dataEstado', 'dataTipoGestion',
            function (  $scope,   $state,   toastr,   tratamientosilviculturalService, dataEstado, dataTipoGestion) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoGestion = dataTipoGestion.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tratamientosilviculturalService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.tratamientosilvicultural.edit', {
          url: '/:tratamientoSilviculturalId/edit',
          templateUrl: 'app/tratamientosilvicultural/tratamientosilvicultural.add.tpl.html',
          resolve: {
            dataTratamientoSilvicultural: ['tratamientosilviculturalService', '$stateParams',
              function ( tratamientosilviculturalService, $stateParams ){
                return tratamientosilviculturalService.get( $stateParams.tratamientoSilviculturalId );
              }],
            dataEstado: ['tratamientosilviculturalService',
              function ( tratamientosilviculturalService ){
                return tratamientosilviculturalService.listStatus();
              }],
            dataTipoGestion: ['tipogestionService',
              function ( tipogestionService ){
                return tipogestionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tratamientosilviculturalService', 'dataTratamientoSilvicultural', 'dataEstado', 'dataTipoGestion',
            function (  $scope,   $state,   toastr,   tratamientosilviculturalService,   dataTratamientoSilvicultural, dataEstado, dataTipoGestion) {
              $scope.data = dataTratamientoSilvicultural.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.dataTipoGestion = dataTipoGestion.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tratamientosilviculturalService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
