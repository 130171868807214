angular.module('app.resolucion.service', [

])

  .factory('resolucionService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'resolucion/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEntrega: function (data) {
        return $http.put(appSettings.urlServiceBase + 'resolucion/marcar/entrega', data).
          then(function (res) {
            return res.data;
          });
      },
      listaEstado: function () {
        var data = [];
        data.push({estadoId : -1, estadoDesc : 'Todos'});
        data.push({estadoId : 1, estadoDesc : 'Sin entregar'});
        data.push({estadoId : 2, estadoDesc : 'Entregado'});
        var res = {
          status : 'OK',
          message : 'Datos cargados',
          data : data
        };
        return res;
      }
    }

  }]);
