angular.module('app.tstudent', [
  'ui.router',
  'toastr',
  'app.tstudent.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tstudent', {
          abstract: true,
          url: 'tstudent',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Tipo de entidad';
            }]
        })
        .state('index.tstudent.list', {
          url: '',
          templateUrl: 'app/tstudent/tstudent.list.tpl.html',
          resolve: {
            dataTStudent: ['tstudentService',
              function ( tstudentService ){
                return tstudentService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataTStudent',
            function (  $scope,   $state,   dataTStudent) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'k', name: 'k', displayName: 'G' },
                { field:'value', name: 'value', displayName: 'Valor' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataTStudent.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ tStudentId: row.tStudentId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.tstudent.add', {
          url: '/add',
          templateUrl: 'app/tstudent/tstudent.add.tpl.html',
          resolve: {
            dataEstado: ['tstudentService',
              function ( tstudentService ){
                return tstudentService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tstudentService', 'dataEstado',
            function (  $scope,   $state,   toastr,   tstudentService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tstudentService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.tstudent.edit', {
          url: '/:tStudentId/edit',
          templateUrl: 'app/tstudent/tstudent.add.tpl.html',
          resolve: {
            dataTStudent: ['tstudentService', '$stateParams',
              function ( tstudentService, $stateParams ){
                return tstudentService.get( $stateParams.tStudentId );
              }],
            dataEstado: ['tstudentService',
              function ( tstudentService ){
                return tstudentService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'tstudentService', 'dataTStudent', 'dataEstado',
            function (  $scope,   $state,   toastr,   tstudentService,   dataTStudent, dataEstado) {
              $scope.data = dataTStudent.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  tstudentService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
