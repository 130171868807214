angular.module('app.avaluo.service', [

])

.factory('avaluoService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    listaPorPersonaFecha: function ( filtro ) {
      return $http.post( appSettings.urlServiceBase + 'avaluo/persona/lista', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    misAvaluos: function () {
      return $http.get( appSettings.urlServiceBase + 'avaluo/propio').then(function ( res ) {
        return res.data;
      });
    },
    listaPorPersona: function ( personaId, estadoId ) {
      return $http.get( appSettings.urlServiceBase + 'avaluo/propio').then(function ( res ) {
        return res.data;
      });
    },
    listaPorSubregion: function ( subregionId, estadoId, fechaIni, fechaFin ) {
      var params = {
        subregionId: subregionId,
        estadoId: estadoId,
        fechaIni: fechaIni,
        fechaFin: fechaFin
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'avaluo/por/subregion',  config).then(function ( res ) {
        return res.data;
      });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    agregarSolicitud: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'avaluo/solicitar', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'avaluo/add/oficina', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'avaluo/' + data.avaluoId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( avaluoId ) {
      return $http.get( appSettings.urlServiceBase + 'avaluo/' + avaluoId).then(function ( res ) {
         return res.data;
       });
    },
    agregarSolicitudSubregion: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'avaluo/agregar/subregion', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    recibirSolicitudSubregion: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'avaluo/subregion/recibir', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    actualizarDetalleAvaluo: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'avaluo/actualizar/detalle', data ).
       then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
