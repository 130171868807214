angular.module('app.cargaMaderable', [
  'ui.router',
  'toastr',
  'app.cargaMaderable.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.cargaMaderable', {
          abstract: true,
          url: 'cargaMaderable',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Carga Maderable';
            }]
        })
        .state('index.cargaMaderable.list', {
          url: '',
          templateUrl: 'app/cargaMaderable/cargaMaderable.list.tpl.html',
          resolve: {
            dataCargaMaderable: ['cargaMaderableService',
              function ( cargaMaderableService ){
                return cargaMaderableService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCargaMaderable',
            function (  $scope,   $state,   dataCargaMaderable) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcRegion.regionDesc', name: 'regionDesc', displayName: 'Región' },
                { field:'tcTipoBosque.tipoBosqueDesc', name: 'tipoBosqueDesc', displayName: 'Tipo bosque' },
                { field:'volumen', name: 'volumen', displayName: 'Volumen (m³/ha)' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '5%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCargaMaderable.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ cargaMaderableId: row.cargaMaderableId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.cargaMaderable.add', {
          url: '/add',
          templateUrl: 'app/cargaMaderable/cargaMaderable.add.tpl.html',
          resolve: {
            dataEstado: ['cargaMaderableService',
              function ( cargaMaderableService ){
                return cargaMaderableService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'cargaMaderableService', 'dataEstado', 'dataRegion', 'dataTipoBosque',
            function (  $scope,   $state,   toastr,   cargaMaderableService, dataEstado, dataRegion, dataTipoBosque) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.dataRegion = dataRegion.data;
              $scope.dataTipoBosque = dataTipoBosque.data;

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  cargaMaderableService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.cargaMaderable.edit', {
          url: '/:cargaMaderableId/edit',
          templateUrl: 'app/cargaMaderable/cargaMaderable.add.tpl.html',
          resolve: {
            dataCargaMaderable: ['cargaMaderableService', '$stateParams',
              function ( cargaMaderableService, $stateParams ){
                return cargaMaderableService.get( $stateParams.cargaMaderableId );
              }],
            dataEstado: ['cargaMaderableService',
              function ( cargaMaderableService ){
                return cargaMaderableService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataTipoBosque: ['tipobosqueService',
              function ( tipobosqueService ){
                return tipobosqueService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'cargaMaderableService', 'dataCargaMaderable', 'dataEstado', 'dataRegion', 'dataTipoBosque',
            function (  $scope,   $state,   toastr,   cargaMaderableService,   dataCargaMaderable, dataEstado, dataRegion, dataTipoBosque) {
              $scope.data = dataCargaMaderable.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataTipoBosque = dataTipoBosque.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  cargaMaderableService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
