angular.module('app.criterioproteccion', [
  'ui.router',
  'toastr',
  'app.criterioproteccion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.criterioproteccion', {
          abstract: true,
          url: 'criterioproteccion',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Criterio de protección';
            }]
        })
        .state('index.criterioproteccion.list', {
          url: '',
          templateUrl: 'app/criterioproteccion/criterioproteccion.list.tpl.html',
          resolve: {
            dataCriterioProteccion: ['criterioproteccionService',
              function ( criterioproteccionService ){
                return criterioproteccionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCriterioProteccion',
            function (  $scope,   $state,   dataCriterioProteccion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'criterioProteccionDesc', name: 'criterioProteccionDesc', displayName: 'Criterio de protección' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCriterioProteccion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ criterioProteccionId: row.criterioProteccionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.criterioproteccion.add', {
          url: '/add',
          templateUrl: 'app/criterioproteccion/criterioproteccion.add.tpl.html',
          resolve: {
            dataEstado: ['criterioproteccionService',
              function ( criterioproteccionService ){
                return criterioproteccionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'criterioproteccionService', 'dataEstado',
            function (  $scope,   $state,   toastr,   criterioproteccionService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  criterioproteccionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.criterioproteccion.edit', {
          url: '/:criterioProteccionId/edit',
          templateUrl: 'app/criterioproteccion/criterioproteccion.add.tpl.html',
          resolve: {
            dataCriterioProteccion: ['criterioproteccionService', '$stateParams',
              function ( criterioproteccionService, $stateParams ){
                return criterioproteccionService.get( $stateParams.criterioProteccionId );
              }],
            dataEstado: ['criterioproteccionService',
              function ( criterioproteccionService ){
                return criterioproteccionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'criterioproteccionService', 'dataCriterioProteccion', 'dataEstado',
            function (  $scope,   $state,   toastr,   criterioproteccionService,   dataCriterioProteccion, dataEstado) {
              $scope.data = dataCriterioProteccion.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  criterioproteccionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
