angular.module('app.correlativo', [
  'ui.router',
  'toastr',
  'app.correlativo.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.correlativo', {
          abstract: true,
          url: 'correlativo',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Correlativo';
            }]
        })
        .state('index.correlativo.list', {
          url: '',
          templateUrl: 'app/correlativo/correlativo.list.tpl.html',
          resolve: {
            dataCorrelativo: ['correlativoService',
              function ( correlativoService ){
                return correlativoService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataCorrelativo',
            function (  $scope,   $state,   dataCorrelativo) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'correlativoDesc', name: 'correlativoDesc', displayName: 'Correlativo' },
                { field:'tcTipoCorrelativo.tipoCorrelativoDesc', name: 'tipoCorrelativoDesc', displayName: 'Tipo de correlativo' },
                { field:'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                { field:'anio', name: 'anio', displayName: 'Año' },
                { field:'correlativo', name: 'correlativo', displayName: 'Valor actual' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataCorrelativo.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ correlativoId: row.correlativoId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.correlativo.add', {
          url: '/add',
          templateUrl: 'app/correlativo/correlativo.add.tpl.html',
          resolve: {
            dataEstado: ['correlativoService',
              function ( correlativoService ){
                return correlativoService.listStatus();
              }],
            dataTipoCorrelativo: ['tipocorrelativoService',
              function ( tipocorrelativoService ){
                return tipocorrelativoService.listaActiva();
              }],
            dataSubregion: ['subregionService',
              function ( subregionService ){
                return subregionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'correlativoService', 'dataEstado', 'dataTipoCorrelativo', 'dataSubregion',
            function (  $scope,   $state,   toastr,   correlativoService, dataEstado, dataTipoCorrelativo, dataSubregion) {
              $scope.dataTipoCorrelativo = dataTipoCorrelativo.data;
              $scope.dataSubregion = dataSubregion.data;

              $scope.data = {
                estadoId : 1,
                correlativo : 0,
                inicio : 0,
                tcTipoCorrelativo: {
                  tipoCorrelativoId: null
                },
                tcSubregion: {
                  subregionId: null
                }
              };
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  correlativoService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.correlativo.edit', {
          url: '/:correlativoId/edit',
          templateUrl: 'app/correlativo/correlativo.add.tpl.html',
          resolve: {
            dataCorrelativo: ['correlativoService', '$stateParams',
              function ( correlativoService, $stateParams ){
                return correlativoService.get( $stateParams.correlativoId );
              }],
            dataEstado: ['correlativoService',
              function ( correlativoService ){
                return correlativoService.listStatus();
              }],
            dataTipoCorrelativo: ['tipocorrelativoService',
              function ( tipocorrelativoService ){
                return tipocorrelativoService.listaActiva();
              }],
            dataSubregion: ['subregionService',
              function ( subregionService ){
                return subregionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'correlativoService', 'dataCorrelativo', 'dataEstado', 'dataTipoCorrelativo', 'dataSubregion',
            function (  $scope,   $state,   toastr,   correlativoService,   dataCorrelativo, dataEstado, dataTipoCorrelativo, dataSubregion) {
              $scope.data = dataCorrelativo.data[0];
              $scope.dataTipoCorrelativo = dataTipoCorrelativo.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataSubregion = dataSubregion.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  correlativoService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
