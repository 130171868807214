angular.module('app.subregion', [
  'ui.router',
  'toastr',
  'app.subregion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.subregion', {
          abstract: true,
          url: 'subregion',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Subregión';
            }]
        })
        .state('index.subregion.list', {
          url: '',
          templateUrl: 'app/subregion/subregion.list.tpl.html',
          resolve: {
            dataSubregion: ['subregionService',
              function ( subregionService ){
                return subregionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataSubregion',
            function (  $scope,   $state,   dataSubregion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcRegion.regionDesc', name: 'regionDesc', displayName: 'Región' },
                { field:'subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                { field:'codigo', name: 'codigo', displayName: 'Código expediente' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataSubregion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ subregionId: row.subregionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.subregion.add', {
          url: '/add',
          templateUrl: 'app/subregion/subregion.add.tpl.html',
          resolve: {
            dataEstado: ['subregionService',
              function ( subregionService ){
                return subregionService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'subregionService', 'dataEstado', 'dataRegion', 'dataDepartamento', 'municipioService',
            function (  $scope,   $state,   toastr,   subregionService, dataEstado, dataRegion, dataDepartamento, municipioService) {
              $scope.data = {
                tcRegion: {
                  regionId: null
                },
                tcMunicipio: {
                  municipioId: null
                }
              };
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.data.estadoId = 1;

              $scope.setDepartamento = function ( item ) {
                $scope.dataMunicipio = [];
                $scope.data.tcMunicipio.municipioId = null;
                if (item != undefined && item != null) {
                  municipioService.listaPorDepartamento( item.departamentoId ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.dataMunicipio = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  subregionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.subregion.edit', {
          url: '/:subregionId/edit',
          templateUrl: 'app/subregion/subregion.add.tpl.html',
          resolve: {
            dataSubregion: ['subregionService', '$stateParams',
              function ( subregionService, $stateParams ){
                return subregionService.get( $stateParams.subregionId );
              }],
            dataEstado: ['subregionService',
              function ( subregionService ){
                return subregionService.listStatus();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'subregionService', 'dataSubregion', 'dataEstado', 'dataRegion', 'dataDepartamento', 'municipioService',
            function (  $scope,   $state,   toastr,   subregionService,   dataSubregion, dataEstado, dataRegion, dataDepartamento, municipioService) {
              $scope.data = dataSubregion.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataDepartamento = dataDepartamento.data;

              $scope.setDepartamento = function ( item ) {
                $scope.dataMunicipio = [];
                $scope.data.tcMunicipio.municipioId = null;
                if (item != undefined && item != null) {
                  municipioService.listaPorDepartamento( item.departamentoId ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.dataMunicipio = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              if ($scope.data.tcMunicipio != null) {
                var tcMunicipio = angular.copy($scope.data.tcMunicipio);
                $scope.setDepartamento($scope.data.tcMunicipio.tcDepartamento);
                $scope.departamentoId = $scope.data.tcMunicipio.tcDepartamento.departamentoId;
                $scope.data.tcMunicipio = tcMunicipio;
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  subregionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
