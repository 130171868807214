angular.module( 'app.catalogosService', [
  'LocalStorageModule',
  'app.authService',
  'app.utilsService'
])

.factory( 'catalogosService', ['$http', '$q', 'appSettings', 'localStorageService', 'authService', 'utilsService',  function ($http, $q, appSettings, localStorageService, authService, utilsService) {

  return {
    getListaElaboradorPorArea: function ( area ) {
      return $http.get( appSettings.urlBaseRegistro + 'regente/lista/' + area + '/categoria' ).then(function ( res ) {
         return res.data;
       });
    },
    getListaElaborador: function () {
      return $http.get( appSettings.urlBaseRegistro + 'regente/elaborador/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    getListaRegentePorArea: function ( area ) {
      if (area <= 100) {
        return $http.get( appSettings.urlBaseRegistro + 'regente/todos/lista' ).then(function ( res ) {
           return res.data;
         });
      } else {
        return $http.get( appSettings.urlBaseRegistro + 'regente/profesional/lista' ).then(function ( res ) {
           return res.data;
         });
      }
    },
    getDatosRegente: function ( nit, tipo ) {
      return $http.get( appSettings.urlBaseRegistro + 'regente/' + nit + '/' + tipo + '/categoria' ).then(function ( res ) {
         return res.data;
       });
    },
    constantes: function () {
      return localStorageService.get( 'constantes' );
    },
    setConstantes: function ( data ) {
      localStorageService.set( 'constantes', data );
    },
    getDatosRegistro: function ( codigo ) {
      return $http.get( appSettings.urlBaseRegistro + 'registro/codigo/' + codigo + '/datos' ).then(function ( res ) {
         return res.data;
       });
    },
    getAreaPoligono: function ( poligono ) {
      return $http.post( appSettings.urlServiceBase + 'poligono/area', poligono ).then(function ( res ) {
         return res.data;
       });
    },
    getResolucionVenta : function( busquedaId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/resolucion/' + busquedaId, {responseType: 'arraybuffer'})
       .then(function (data) {
         if (data.status == 200) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         } else {
           toastr.error(data);
         }
       });
    },
    getResolucionVentaConsumo : function( busquedaId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/resolucion/' + busquedaId, {responseType: 'arraybuffer'})
       .then(function (data) {
         if (data.status == 200) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         } else {
           toastr.error(data);
         }
       });
    },
    getNotaEnvioExento : function( busquedaId ) {
      return $http.get( appSettings.urlServiceBase + 'exento/venta/nota/' + busquedaId, {responseType: 'arraybuffer'})
       .then(function (data) {
         if (data.status == 200) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         } else {
           toastr.error(data);
         }
       });
    },
    getLongitudLinea: function ( poligono ) {
      return $http.post( appSettings.urlServiceBase + 'poligono/longitud', poligono ).then(function ( res ) {
         return res.data;
       });
    }
  }
}]);
