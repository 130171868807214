angular.module('app.poa', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.poa', {
          abstract: true,
          url: 'poa',
          template: '<div ui-view></div>',
          resolve: {
          },
          controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
            function (  $scope, toastr, $timeout, gestionService, utilsService) {
              $scope.module = 'POA';
            }]
        })
        .state('index.poa.list', {
          url: '',
          templateUrl: 'app/poa/poa.list.tpl.html',
          resolve: {
            dataSolicitud: ['gestionService', 'authService', '$filter',
              function ( gestionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 60);
                var fechaIni = $filter( 'date' )(begin, 'yyyy-MM-dd 00:00:00' );
                var fechaFin = $filter( 'date' )(now, 'yyyy-MM-dd 23:59:59' );
                var filtro = {
                  fechaIni : new Date(fechaIni),
                  fechaFin : new Date(fechaFin),
                  estadoId : -1,
                  elaboradorId : loginData.subregionId
                }
                return gestionService.getListadoPoa(filtro);
              }]
          },
          controller: ['$scope', '$state', 'dataSolicitud', 'uiGridConstants', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog',
            function (  $scope,   $state,   dataSolicitud, uiGridConstants, toastr, $filter, gestionService, utilsService, $timeout, ngDialog ) {
              $scope.dataEstado = [
                {estadoId: -1, estadoDesc: 'Todos'},
                {estadoId: 1, estadoDesc: 'Solicitado'},
                {estadoId: 2, estadoDesc: 'Finalizado'}
              ];
              var now = new Date();
              var begin = $scope.addDays(now, -60);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : -1,
                elaboradorId : $scope.loginData.subregionId
              }

              $scope.descripcionEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return 'Solicitado';
                }
                return 'Finalizado';
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' } ,
                { field:'ttGestion.tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                { field:'fechaIngreso', name: 'fechaIngreso', displayName: 'Fecha ingreso', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'ttGestion.area', name: 'area', displayName: 'Área', width: '5%' },
                { field:'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '15%' },
                { name: 'Estado', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options">{{grid.appScope.descripcionEstado(row.entity.estadoId)}}</div>' },
                { field:'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' }
              ];
              $scope.gridOptions.data = dataSolicitud.data;

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                  var fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                  var filtro = {
                    fechaIni : new Date(fechaIni),
                    fechaFin : new Date(fechaFin),
                    estadoId : $scope.data.estadoId,
                    elaboradorId : $scope.loginData.subregionId
                  }
                  gestionService.getListadoPoa( filtro ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

            }]
        })
        .state('index.poa.recibir', {
          url: '/add',
          templateUrl: 'app/poa/poa.recibir.tpl.html',
          resolve: {

          },
          controller: ['$scope', '$state', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog', 'tareaService',
            function (  $scope, $state, toastr, $filter, gestionService, utilsService, $timeout, ngDialog, tareaService ) {
              $scope.mostrarRequisito = false;
              $scope.mostrarVista = false;
              $scope.data = {
                subregionId : $scope.loginData.subregionId,
                numero : null,
                anio : null
              }
              $scope.noPoa = 1;
              $scope.classCompletacion = 'btn btn-default fa fa-square-o';
              $scope.esCompletacion = false;
              $scope.visibleRecibir = true;

              $scope.submitForm = function ( isValid ) {
                $scope.mostrarRequisito = false;
                if ( isValid ) {
                  gestionService.encontrarGestion( $scope.data ) .then( function ( resp ) {
                    if ( resp.status == "OK" ) {
                      $scope.gestion = resp.data[0];
                      gestionService.getRequisitoPoa($scope.gestion.gestionId) .then( function ( res ) {
                        if ( res.status == "OK" ) {
                          $scope.mostrarRequisito = true;
                          var data = res.data;
                          for (var i = 0; i < data.length; i++) {
                            data[i].marcado = 0;
                            data[i].class = "btn-default btn-xs fa fa-square-o";
                            data[i].classNo = "btn-default btn-xs fa fa-square-o";
                          }
                          $scope.dataRequisito = data;
                        } else {
                          toastr.error( res.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    } else {
                      toastr.error( resp.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.marcarRequisito = function ( item ) {
                if (item.marcado == 1) {
                  item.marcado = 0;
                  item.class = 'btn-default btn-xs fa fa-square-o';
                } else {
                  item.marcado = 1;
                  item.class = 'btn-success btn-xs fa fa-check-square-o';
                }
              }

              $scope.recibirPoa = function (item) {
                var texto = "¿Está segura (o) de recibir los requisitos del POA y notificar al director Subregional? \nEsta acción no se podrá reversar";
                for (var i = 0; i < $scope.dataRequisito.length; i++) {
                  if ($scope.dataRequisito[i].marcado == 0) {
                    toastr.error('Todos los requisitos mostrados son requeridos');
                    return;
                  }
                }
                if ($scope.noPoa == null) {
                  toastr.error('Debe indicar el número de POA a tramitar');
                  return;
                }
                swal({
                  title: "Confirmación",
                  text: texto,
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var item = {};
                    var ttGestion = angular.copy($scope.gestion);
                    item.ttGestion = {};
                    item.ttGestion = {
                      gestionId: ttGestion.gestionId,
                      tcTipoGestion: ttGestion.tcTipoGestion,
                      estadoId : ttGestion.estadoId,
                      tcPersonaCrea: ttGestion.tcPersonaCrea,
                      tcTipoBosque: ttGestion.tcTipoBosque,
                      tcElaborador: ttGestion.tcElaborador,
                      ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                    };
                    item.ttGestion.tcPersonaCrea.foto = null;
                    item.ttGestion.tcElaborador.foto = null;
                    item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                    item.requisito = $scope.dataRequisito;
                    item.tcPersonaIngresa = item.ttGestion.tcPersonaCrea;
                    item.tcPersonaIngresa.personaId = $scope.loginData.personaId;
                    item.noPoa = $scope.noPoa;
                    tareaService.recibirPoa( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success(res.message);
                        $state.go('^.list');
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

            }]
        })
        .state('index.poa.solicitud', {
          url: '/solicitud',
          templateUrl: 'app/poa/poa.solicitud.tpl.html',
          resolve: {
          },
          controller: ['$scope', '$state', 'toastr', '$filter', 'gestionService', 'utilsService', '$timeout', 'ngDialog', 'tareaService',
            function (  $scope, $state, toastr, $filter, gestionService, utilsService, $timeout, ngDialog, tareaService ) {
              $scope.mostrarRequisito = false;
              $scope.mostrarVista = false;
              $scope.classCompletacion = 'btn btn-default fa fa-square-o';
              $scope.esCompletacion = false;
              $scope.visibleRecibir = true;

              $scope.mostrarRecibir = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
              $scope.gridOptions.columnDefs = [
                { field: 'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '20%' },
                { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'personaDesc', displayName: 'Propietario/Representante' },
                { field: 'numero', name: 'numero', displayName: 'No. POA', width: '10%' },
                { field: 'tcRegente.personaDesc', name: 'regente', displayName: 'Regente' },
                { field:'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha recepción', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                {
                  name: 'Seleccionar', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarRecibir(row.entity.estadoId)" ng-click="grid.appScope.marcarParaRecibir(row.entity)" title="Recibir solcitud de POA">Recibir</button></span></div>'
                }
              ];
              $scope.gridOptions.data = [];

              $scope.marcarParaRecibir = function ( item ) {
                $scope.item = item;
                gestionService.getRequisitoSolicitudPoa(item.solicitudPoaId) .then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.mostrarRequisito = true;
                    var data = res.data;
                    for (var i = 0; i < data.length; i++) {
                      data[i].marcado = 0;
                      data[i].class = "btn-default btn-xs fa fa-square-o";
                      data[i].classNo = "btn-default btn-xs fa fa-square-o";
                    }
                    $scope.dataRequisito = data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.submitForm = function ( isValid ) {
                $scope.mostrarRequisito = false;
                if ( isValid ) {
                  gestionService.listaSolicitudPoa( $scope.licencia ) .then( function ( resp ) {
                    if ( resp.status == "OK" ) {
                      $scope.gridOptions.data = resp.data
                    } else {
                      toastr.error( resp.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.marcarRequisito = function ( item ) {
                if (item.marcado == 1) {
                  item.marcado = 0;
                  item.class = 'btn-default btn-xs fa fa-square-o';
                } else {
                  item.marcado = 1;
                  item.class = 'btn-success btn-xs fa fa-check-square-o';
                }
              }

              $scope.recibirPoa = function () {
                var item = $scope.item;
                var texto = "¿Está segura (o) de recibir los requisitos de la solicitud del POA (" + item.numero + ") y notificar al director Subregional? \nEsta acción no se podrá reversar";
                for (var i = 0; i < $scope.dataRequisito.length; i++) {
                  var row = $scope.dataRequisito[i];
                  if (row.marcado == 0 && row.tcGrupoRequisito.obligatorio == 1) {
                    toastr.error('Todos los requisitos mostrados son requeridos');
                    return;
                  }
                }
                swal({
                  title: "Confirmación",
                  text: texto,
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var item = angular.copy($scope.item);
                    var ttGestion = angular.copy($scope.item.ttGestion);
                    item.ttGestion = {};
                    item.ttGestion = {
                      gestionId: ttGestion.gestionId,
                      tcTipoGestion: ttGestion.tcTipoGestion,
                      estadoId : ttGestion.estadoId,
                      tcPersonaCrea: ttGestion.tcPersonaCrea,
                      tcTipoBosque: ttGestion.tcTipoBosque,
                      tcElaborador: ttGestion.tcElaborador,
                      ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                    };
                    item.ttGestion.tcPersonaCrea.foto = null;
                    item.ttGestion.tcElaborador.foto = null;
                    item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                    item.tcPersonaIngresa = item.ttGestion.tcPersonaCrea;
                    item.tcPersonaIngresa.personaId = $scope.loginData.personaId;
                    tareaService.recibirSolicitudPoa( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success(res.message);
                        $scope.mostrarRequisito = false;
                        $scope.submitForm(true);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

            }]
        })
    }
  ]
);
