angular.module('app.sistema', [
  'ui.router',
  'toastr',
  'app.sistema.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.sistema', {
          abstract: true,
          url: 'sistema',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1004);
              }]
          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Sistema';
            }]
        })
        .state('index.sistema.list', {
          url: '',
          templateUrl: 'app/sistema/sistema.list.tpl.html',
          resolve: {
            dataSistema: ['sistemaService',
              function ( sistemaService ){
                return sistemaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataSistema',
            function (  $scope,   $state,   dataSistema) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'sistemaDesc', name: 'sistemaDesc', displayName: 'Sistema' },
                { field:'urlSistema', name: 'urlSistema', displayName: 'Dirección web' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataSistema.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ sistemaId: row.sistemaId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.sistema.add', {
          url: '/add',
          templateUrl: 'app/sistema/sistema.add.tpl.html',
          resolve: {
            dataEstado: ['sistemaService',
              function ( sistemaService ){
                return sistemaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'sistemaService', 'dataEstado',
            function (  $scope,   $state,   toastr,   sistemaService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  sistemaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.sistema.edit', {
          url: '/:sistemaId/edit',
          templateUrl: 'app/sistema/sistema.add.tpl.html',
          resolve: {
            dataSistema: ['sistemaService', '$stateParams',
              function ( sistemaService, $stateParams ){
                return sistemaService.get( $stateParams.sistemaId );
              }],
            dataEstado: ['sistemaService',
              function ( sistemaService ){
                return sistemaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'sistemaService', 'dataSistema', 'dataEstado',
            function (  $scope,   $state,   toastr,   sistemaService,   dataSistema, dataEstado) {
              $scope.data = dataSistema.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  sistemaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
