angular.module('app.convenioMunicipal', [
  'ui.router',
  'toastr',
  'app.convenioMunicipal.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.convenioMunicipal', {
          abstract: true,
          url: 'convenioMunicipal',
          template: '<div ui-view></div>',
          resolve: {
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', 'dataDepartamento',
            function (  $scope, dataDepartamento ) {
              $scope.module = 'Suscripciones Municipales';

              $scope.dataTipoConvenio = [
                {tipoConvenioId: 1, tipoConvenioDesc: 'Carta de Entendimiento para la Gestión Descentralizada de Aprovechamiento Forestal de Consumo Familiar'},
                {tipoConvenioId: 2, tipoConvenioDesc: 'Convenio de Cooperación para la Gestión Descentralizada de Aprovechamiento Forestal de Consumo Familiar'}
              ];
              
              $scope.dataDepartamento = dataDepartamento.data;

            }]
        })
        .state('index.convenioMunicipal.list', {
          url: '',
          templateUrl: 'app/convenioMunicipal/convenioMunicipal.list.tpl.html',
          resolve: {
            dataConvenioMunicipal: ['convenioMunicipalService',
              function ( convenioMunicipalService ){
                return convenioMunicipalService.getByDepartamento(1);
              }]
          },
          controller: ['$scope', '$state', 'dataConvenioMunicipal', 'convenioMunicipalService',
            function (  $scope,   $state,   dataConvenioMunicipal, convenioMunicipalService) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                { field:'tipoConvenioDesc', name: 'tipoConvenioDesc', displayName: 'Tipo' },
                { field:'fechaSuscripcion', name: 'fechaSuscripcion', displayName: 'Fecha suscripción', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '10%', 
                cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataConvenioMunicipal.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ convenioMunicipalId: row.convenioMunicipalId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }

              $scope.setDepartamento = function ( item ) {
                convenioMunicipalService.getByDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == 1 ) {
                    $scope.gridOptions.data = res.data;
                  } else {
                    $scope.gridOptions.data = [];
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  $scope.gridOptions.data = [];
                  toastr.error( error );
                });
              }

            }]
        })
        .state('index.convenioMunicipal.add', {
          url: '/add',
          templateUrl: 'app/convenioMunicipal/convenioMunicipal.add.tpl.html',
          resolve: {
            dataEstado: ['convenioMunicipalService',
              function ( convenioMunicipalService ){
                return convenioMunicipalService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'convenioMunicipalService', 'dataEstado', 'municipioService', 'Upload',
            function (  $scope,   $state,   toastr,   convenioMunicipalService, dataEstado, municipioService, Upload) {
              $scope.data = {
                tcMunicipio: {}
              };
              $scope.dataEstado = dataEstado.data;
              $scope.dataMunicipio = [];
              $scope.data.estadoId = 1;

              $scope.cargarListaMunicipio = function (item) {
                $scope.data.tcMunicipio.municipioId = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.onFileSelect = function (file, tipoAnexoId) {                
                Upload.upload({
                  url: appSettings.urlServiceBase + 'file/upload',
                  method: 'POST',
                  file: file,
                  sendFieldsAs: 'form',
                  fields: {
                    data: []
                  }
                }).then(function (resp) {
                  var data = resp.data;
                  if (data.status == 'OK') {
                    var obj = data.data[0];
                    $scope.data.archivoNombre = obj.nombreOriginal;
                    $scope.data.size = obj.size;
                    $scope.data.extension = obj.extension;
                    $scope.data.rutaArchivo = obj.rutaArchivo;
                  } else {
                    toastr.error(resp.data.message)
                  }
                }, function (resp) {
                  toastr.error('Error status: ' + resp.status);
                }, function (evt) {
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                  $scope.avance = progressPercentage;
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  if ($scope.data.rutaArchivo == null) {
                    toastr.error('Debe agregar el archivo de suscripción');
                    return;
                  }
                  convenioMunicipalService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.convenioMunicipal.edit', {
          url: '/:convenioMunicipalId/edit',
          templateUrl: 'app/convenioMunicipal/convenioMunicipal.add.tpl.html',
          resolve: {
            dataConvenioMunicipal: ['convenioMunicipalService', '$stateParams',
              function ( convenioMunicipalService, $stateParams ){
                return convenioMunicipalService.get( $stateParams.convenioMunicipalId );
              }],
            dataEstado: ['convenioMunicipalService',
              function ( convenioMunicipalService ){
                return convenioMunicipalService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'convenioMunicipalService', 'dataConvenioMunicipal', 'dataEstado', 'municipioService', 'Upload',
            function (  $scope,   $state,   toastr,   convenioMunicipalService,   dataConvenioMunicipal, dataEstado, municipioService, Upload) {
              $scope.data = dataConvenioMunicipal.data;
              $scope.dataEstado = dataEstado.data;

              $scope.cargarListaMunicipio = function (item, actualizar) {
                if (actualizar) {
                  $scope.data.tcMunicipio.municipioId = null;
                }
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              if (dataConvenioMunicipal.status == 1) {
                $scope.cargarListaMunicipio($scope.data.tcMunicipio.tcDepartamento, false);
                $scope.data.tcDepartamento = $scope.data.tcMunicipio.tcDepartamento;
              }

              $scope.onFileSelect = function (file, tipoAnexoId) {                
                Upload.upload({
                  url: appSettings.urlServiceBase + 'file/upload',
                  method: 'POST',
                  file: file,
                  sendFieldsAs: 'form',
                  fields: {
                    data: []
                  }
                }).then(function (resp) {
                  var data = resp.data;
                  if (data.status == 'OK') {
                    var obj = data.data[0];
                    $scope.data.archivoNombre = obj.nombreOriginal;
                    $scope.data.size = obj.size;
                    $scope.data.extension = obj.extension;
                    $scope.data.rutaArchivo = obj.rutaArchivo;
                  } else {
                    toastr.error(resp.data.message)
                  }
                }, function (resp) {
                  toastr.error('Error status: ' + resp.status);
                }, function (evt) {
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                  $scope.avance = progressPercentage;
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  convenioMunicipalService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == 1) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
