angular.module('app.origencalculo', [
  'ui.router',
  'toastr',
  'app.origencalculo.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.origencalculo', {
          abstract: true,
          url: 'origencalculo',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Clase de desarrollo';
            }]
        })
        .state('index.origencalculo.list', {
          url: '',
          templateUrl: 'app/origencalculo/origencalculo.list.tpl.html',
          resolve: {
            dataOrigenCalculo: ['origencalculoService',
              function ( origencalculoService ){
                return origencalculoService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataOrigenCalculo',
            function (  $scope,   $state,   dataOrigenCalculo) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'origenCalculoDesc', name: 'origenCalculoDesc', displayName: 'Clase de desarrollo' },
                { field:'codigoClase', name: 'codigoClase', displayName: 'Código' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataOrigenCalculo.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ origenCalculoId: row.origenCalculoId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.origencalculo.add', {
          url: '/add',
          templateUrl: 'app/origencalculo/origencalculo.add.tpl.html',
          resolve: {
            dataEstado: ['origencalculoService',
              function ( origencalculoService ){
                return origencalculoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'origencalculoService', 'dataEstado',
            function (  $scope,   $state,   toastr,   origencalculoService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  origencalculoService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.origencalculo.edit', {
          url: '/:origenCalculoId/edit',
          templateUrl: 'app/origencalculo/origencalculo.add.tpl.html',
          resolve: {
            dataOrigenCalculo: ['origencalculoService', '$stateParams',
              function ( origencalculoService, $stateParams ){
                return origencalculoService.get( $stateParams.origenCalculoId );
              }],
            dataEstado: ['origencalculoService',
              function ( origencalculoService ){
                return origencalculoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'origencalculoService', 'dataOrigenCalculo', 'dataEstado',
            function (  $scope,   $state,   toastr,   origencalculoService,   dataOrigenCalculo, dataEstado) {
              $scope.data = dataOrigenCalculo.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  origencalculoService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
